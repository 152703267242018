import { RewardsOfferType } from '@/domains/enums'
import { FormikValues } from 'formik'

const pocsListInfoArrangement = (
	formValues: FormikValues,
	concatCampaignIds: string[],
	concatPocIds: string[],
): string => {
	return formValues.multiplePocsFileConverted.map((item: { accountId: string; points: string; campaignId: string }) => {
		concatCampaignIds.push(item.campaignId)
		concatPocIds.push(item.accountId)

		return {
			pocId: item.accountId,
			points: item.points,
		}
	})
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const auditLogRewardsOfferData = (formValues: FormikValues, transparencyId = ''): any => {
	const concatCampaignIds: string[] = []
	const concatPocIds: string[] = []

	const arrangeRewardsOfferMetadataObj = (): { [key: string]: string } => {
		if (formValues.rewardsOfferType === RewardsOfferType.MultiplePoc) {
			const pocsList = pocsListInfoArrangement(formValues, concatCampaignIds, concatPocIds)
			return {
				campaignId: concatCampaignIds.join(),
				description: formValues.description,
				fileName: formValues.multiplePocsFile[0].name,
				fileSize: `${formValues.multiplePocsFile[0].size.toString()}`,
				pocLists: JSON.stringify(pocsList),
				transparencyId,
			}
		}

		return {
			originalPocId: formValues.pocId,
			pointsAdjustment: formValues.points,
			campaignId: formValues.campaignId,
			description: formValues.description,
		}
	}

	return {
		rewardsOfferMetadataObj: arrangeRewardsOfferMetadataObj(),
		pocs: concatPocIds.join(),
	}
}
