import Item from '@/domains/Item'
import ItemResponse, { ItemErrorResponse } from '@/domains/Item/ItemResponse'
import VariantsType from '@/domains/Item/variantsType'
import { hasElements, splitIntoListOfList } from '@/utils/array'
import axios from '@/utils/axiosInstance'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { useLogService as logService } from 'admin-portal-shared-services'
import { AxiosError } from 'axios'

const endPoint = `/v1/items/`
const multivendorEndPoint = `/rewards-admin-service/v1/items`
const log = logService()
const MAX_ELEMENTS_REQUEST = 100

type loadItemProps = {
	vendorItemIds: Array<string>
	isMultivendor?: boolean
	variantsType?: VariantsType
}

function load(sku: string): Promise<Item> {
	return axios
		.get<Item>(`${endPoint}${sku}`)
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			log.error(`Error endpoint: ${endPoint}`, error)
			throw error
		})
}

function reduceTheResult(result: Array<ItemResponse>): ItemResponse {
	const base = {
		items: [],
	}
	return result.reduce((prevValue: ItemResponse, currValue: ItemResponse) => {
		return {
			items: [...prevValue.items, ...currValue.items],
		} as ItemResponse
	}, base)
}

function callLoadAll({ vendorItemIds, isMultivendor, variantsType }: loadItemProps): Promise<ItemResponse> {
	const itemParams = { sku: vendorItemIds.join(',') }

	const itemMultiVendorParams = {
		vendorId: getDefaultVendorIdOfCurrentZone(),
		searchIds: vendorItemIds.join(','),
		variantsType,
		pageSize: MAX_ELEMENTS_REQUEST,
	}

	return axios
		.get<ItemResponse>(isMultivendor ? multivendorEndPoint : endPoint, {
			params: isMultivendor ? itemMultiVendorParams : itemParams,
		})
		.then((response) => {
			return response.data
		})
		.catch((error: AxiosError<ItemErrorResponse>) => {
			log.error(`Error endpoint: ${isMultivendor ? multivendorEndPoint : endPoint}`, error)
			if (error && error.response?.status === 404) {
				return {
					items: [],
				}
			}
			throw error
		})
}

async function loadAll({ vendorItemIds, isMultivendor, variantsType }: loadItemProps): Promise<ItemResponse> {
	if (!hasElements(vendorItemIds)) {
		return callLoadAll({ vendorItemIds, isMultivendor, variantsType })
	}
	const requests = splitIntoListOfList<string>(vendorItemIds, MAX_ELEMENTS_REQUEST).map((list) =>
		callLoadAll({ vendorItemIds: list, isMultivendor, variantsType }),
	)
	const result = await Promise.all(requests)
	return reduceTheResult(result)
}

function loadAllItems({ vendorItemIds, variantsType }: loadItemProps): Promise<ItemResponse> {
	return loadAll({ vendorItemIds, isMultivendor: true, variantsType })
}

export { load, loadAll, loadAllItems }
