import { createStyles, makeStyles } from '@material-ui/core/styles'

const NavigationTabsStyle = makeStyles(() =>
	createStyles({
		activeTab: {
			transform: 'translateY(2px)',
		},
	}),
)

export default NavigationTabsStyle
