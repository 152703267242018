import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		modalAlertWrapper: {
			'& [role="dialog"]': {
				width: '80%',
				maxWidth: '815px',

				'& > div:first-child': {
					height: 'auto',
					paddingRight: '32px',
				},
			},
		},
		title: {
			fontSize: '32px',
			fontWeight: 600,
			fontFamily: 'Barlow',
			letterSpacing: '0',
			lineHeight: '40px',
		},
		dialogAction: {
			padding: '0px',
		},
		submitButton: {
			marginLeft: '8px',
		},
		dialogContent: {
			padding: '0px',
		},
	}),
)

export default styles
