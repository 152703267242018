import React from 'react'
import { AlertDialog } from '@/components/core'

type Props = {
	showSubmitDialog: boolean
	handleCancel: () => void
	handleSubmit: () => void
	title: string
	message: string
}

const ComboSubmitDialogView: React.FC<Props> = ({
	showSubmitDialog,
	handleCancel,
	handleSubmit,
	title,
	message,
}: Props) => {
	return (
		<AlertDialog title={title} show={showSubmitDialog} handleCancel={handleCancel} handleConfirm={handleSubmit}>
			{message}
		</AlertDialog>
	)
}

export default React.memo(ComboSubmitDialogView)
