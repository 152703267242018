import { ChallengeStore } from '@/stores/challengeCreation'
import { useStoreMap } from 'effector-react'
import { getStatus } from '@/utils/challengeStatus'
import { ChallengeStatus } from '@/domains/enums'

const useIsActiveChallengeForEditing = (): boolean => {
	const isActiveChallenge = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => {
			if (!state.challengeForEditing) {
				return false
			}
			return getStatus(state.challengeForEditing) === ChallengeStatus.Active
		},
	})

	return isActiveChallenge
}

export default useIsActiveChallengeForEditing
