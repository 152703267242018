import * as RedeemablesService from '@/services/redeemables/RedeemablesService'
import { ComboFormDataMultiple } from '@/stores'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { hasElements } from '@/utils/array'
import { RedeemableBulkData } from '@/domains/redeemable/RedeemableBulkData'

export const createFileName = (
	redeemableCombos: Array<{
		vendorComboId: string
	}>,
): string => {
	const size = redeemableCombos.length
	if (size <= 2) {
		return redeemableCombos.map((item) => item.vendorComboId).join(', ')
	}
	return `${createFileName(redeemableCombos.slice(0, 2))}, (+${size - 2})`
}

export const createBulkRequestData = (comboFormData: ComboFormDataMultiple): RedeemableBulkData => {
	const vendorId = getDefaultVendorIdOfCurrentZone()
	const redeemableCombos = comboFormData.formData.redeemables.map(({ redeemableId, points, vendorComboId }) => ({
		vendorComboId: (vendorComboId ?? redeemableId)!,
		points: parseInt(points ?? '0', 10),
	}))

	return {
		redeemableCombos,
		vendorId,
		quantity: redeemableCombos.length,
		fileName: comboFormData.fileName ?? createFileName(redeemableCombos),
	}
}

export async function execute(comboFormData: ComboFormDataMultiple): Promise<void> {
	if (!hasElements(comboFormData.formData.redeemables)) {
		throw new Error('No redeemables to update')
	}
	const comboRequestData = createBulkRequestData(comboFormData)
	return RedeemablesService.insertComboBulk(comboRequestData)
}
