export const hasElements = <T>(array?: Array<T>): boolean => {
	if (!array) {
		return false
	}
	return !!array?.length
}

export const hasMoreElementsThan = <T>(array: Array<T>, size: number): boolean => {
	if (!hasElements(array) || size < 0) {
		return false
	}
	return array.length > size
}

export const removeDuplicates = <T>(array: Array<T>): Array<T> => [...new Set(array)]

export const toLowerCase = (array: Array<string>): Array<string> => array.map((item) => item.toLowerCase())

export const toUpperCase = (array: Array<string>): Array<string> => array.map((item) => item.toUpperCase())

export const splitIntoListOfList = <TItem>(prevList: Array<TItem>, elementSize: number): Array<Array<TItem>> => {
	if (!hasElements(prevList)) {
		return []
	}
	const maxSize = prevList.length
	if (elementSize >= maxSize) {
		return [[...prevList]]
	}
	const finalList = []
	let end = 0
	const count = Math.ceil(maxSize / elementSize)
	for (let index = 0; index < count; index += 1) {
		const start = end
		end = (index + 1) * elementSize
		const itemList = prevList.slice(start, end)
		finalList.push(itemList)
	}
	return finalList
}
