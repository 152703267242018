import { Paper } from '@/components/core'
import React from 'react'
import TablePageTemplate, { Props } from '../TablePageTemplate'
import useStyles from './styles'

const TablePagePaperTemplate: React.FC<Props> = ({ children, ...props }: Props) => {
	const classes = useStyles()

	return (
		<TablePageTemplate {...props}>
			<Paper className={classes.container}>{children}</Paper>
		</TablePageTemplate>
	)
}

export default TablePagePaperTemplate
