import { FailureReason, ScreenName } from '@/domains'
import { RewardsOfferType, TransactionType } from '@/domains/enums'
import { TransactionStore } from '@/stores'
import { TrackErrorToSegmentFunction } from 'components/templates/old/SingleFormTemplate'
import { useStoreMap } from 'effector-react'
import { useTranslation } from 'react-i18next'

export const useTrackFormValidationErrors = (): { sendTrackingProps: TrackErrorToSegmentFunction } => {
	const { t } = useTranslation()

	const { rewardsOfferType, transactionType } = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => ({
			transactionType: state.transactionType,
			rewardsOfferType: state.rewardsOfferType,
		}),
	})

	const sendTrackingProps: TrackErrorToSegmentFunction = (fieldName, error) => {
		let trackingProps
		let trackingPropsFields
		let trackingPropsScreenName
		if (transactionType === TransactionType.Refund) {
			trackingPropsScreenName = ScreenName.RefundCreatePointsTransaction
			trackingPropsFields = [
				['orderId', FailureReason.RefundEmptyOrderId],
				['id', FailureReason.RefundEmptyComboId],
				['quantity', FailureReason.RefundEmptyQuantity],
				['points', FailureReason.EmptyPoints],
			]
		} else if (transactionType === TransactionType.RewardsOffer && rewardsOfferType === RewardsOfferType.SinglePoc) {
			trackingPropsScreenName = ScreenName.RewardsOffer
			trackingPropsFields = [
				['pocId', FailureReason.RewardsOfferEmptyPocId],
				['points', FailureReason.RewardsOfferEmptyPoints],
				['description', FailureReason.RewardsOfferEmptyDescription],
			]
		}

		if (
			trackingPropsFields &&
			(error === t('ERROR_MESSAGE.EMPTY_FIELD') || Object.values(error[0])[0] === t('ERROR_MESSAGE.EMPTY_FIELD'))
		) {
			const [, failureReason] = trackingPropsFields.find(([key]) => {
				if (Array.isArray(error)) {
					return error.find((el) => Object.keys(el)[0] === key)
				}
				return key === fieldName
			}) || [fieldName, undefined]
			if (failureReason) {
				trackingProps = { screenName: trackingPropsScreenName as string, failureReason }
			}
		}

		return trackingProps
	}

	return { sendTrackingProps }
}
