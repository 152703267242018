import { Challenge, ChallengeFiles, ChallengeFilter } from '@/domains'
import { ChallengeMode } from '@/domains/enums'
import {
	ChallengeDetailsFormData,
	ChallengeFilterFormData,
	ChallengeSetupFormData,
	ChallengeState,
} from '@/stores/challengeCreation'
import { isNotPurchaseOrVolume } from '@/utils/challenge'
import { removeVendorIdsFromFilters } from '@/utils/challengeFilterUtil'
import { formatToDateAndForceUTC0, formatToISODate, startOfDay, endOfDay, isSameDateAndTime } from '@/utils/date'
import { getFileFromUrl } from '@/utils/fileHelper'

const challengeSetupFormDataToChallengeFiles = (formData: ChallengeSetupFormData): ChallengeFiles => {
	const { goodPhoto, badPhoto, image } = formData
	return {
		imageFile: image!.file,
		badPhotoFile: badPhoto?.file,
		goodPhotoFile: goodPhoto?.file,
	}
}

const parseChallengeDate = (date: string | undefined, type: 'start' | 'end'): string => {
	return formatToISODate(date ?? '')
		.split('T')[0]
		.concat(type === 'start' ? 'T00:00:00.000Z' : 'T23:59:59.999Z')
}

const parseChallengeFilterFormData = (challengeFilterFormData: ChallengeFilterFormData): ChallengeFilter => {
	const challengeFilter: ChallengeFilter = {}

	challengeFilterFormData.filters.forEach((filterObject) => {
		const filter = filterObject.filter as 'groupIds'
		challengeFilter[filter] = filterObject.value
	})

	return challengeFilter
}

const challengeStateValuesToChallenge = (state: ChallengeState): Challenge => {
	const { challengeSetupFormData, challengeDetailsFormData, challengeFilterFormData } = state

	const challengeDetailsFields = {
		points: challengeDetailsFormData!.points,
		quantityMin: !challengeFilterFormData?.individualTarget ? challengeDetailsFormData?.quantity : 1,
		currencyMin: !challengeFilterFormData?.individualTarget ? challengeDetailsFormData?.currencyMin : 1,
		skus: challengeDetailsFormData?.skus,
		items: challengeDetailsFormData?.items,
	}

	return {
		type: 'EXECUTION_PTC',
		mode: ChallengeMode.Published,
		id: challengeSetupFormData!.id!,
		campaignId: challengeSetupFormData?.campaignId,
		title: challengeSetupFormData!.title!,
		description: challengeSetupFormData!.description!,
		executionMethod: challengeSetupFormData!.executionMethod,
		image: challengeSetupFormData!.image!.url,
		goodPhotoSample: challengeSetupFormData?.goodPhoto?.url,
		badPhotoSample: challengeSetupFormData?.badPhoto?.url,
		filter: !challengeFilterFormData?.filters ? {} : parseChallengeFilterFormData(challengeFilterFormData),
		...challengeDetailsFields,
		startDate: new Date(parseChallengeDate(challengeFilterFormData!.startDate, 'start')),
		endDate: new Date(parseChallengeDate(challengeFilterFormData!.endDate, 'end')),
		individualTarget: challengeFilterFormData?.individualTarget,
		challengeGoal: '',
		translations: challengeSetupFormData?.translations,
		visionId: challengeSetupFormData?.visionId,
	}
}

const challengeToChallengeSetupFormData = (challenge: Challenge): ChallengeSetupFormData => {
	const translates = challenge.translations?.map((translate) => ({
		[`title-${translate.languageId}`]: translate.title,
		[`description-${translate.languageId}`]: translate.description,
		[`image-${translate.languageId}`]: {
			url: translate.image,
			file: getFileFromUrl((translate.image as unknown as string) || ''),
		},
		[`goodPhotoSample-${translate.languageId}`]: {
			url: translate.goodPhotoSample,
			file: getFileFromUrl((translate.goodPhotoSample as unknown as string) || ''),
		},
		[`badPhotoSample-${translate.languageId}`]: {
			url: translate.badPhotoSample,
			file: getFileFromUrl((translate.badPhotoSample as unknown as string) || ''),
		},
	}))

	const formData: ChallengeSetupFormData = {
		id: challenge.id,
		campaignId: challenge.campaignId,
		title: challenge.title,
		executionMethod: challenge.executionMethod,
		description: challenge.description,
		translations: challenge.translations,
		...translates?.reduce((acc, translate) => ({ ...acc, ...translate }), {}),
		image: { url: challenge.image ?? '', file: getFileFromUrl(challenge.image) },
		visionId: challenge.visionId,
	}

	if (challenge.goodPhotoSample) {
		formData.goodPhoto = { url: challenge.goodPhotoSample, file: getFileFromUrl(challenge.goodPhotoSample) }
	}

	if (challenge.badPhotoSample) {
		formData.badPhoto = { url: challenge.badPhotoSample, file: getFileFromUrl(challenge.badPhotoSample) }
	}
	return formData
}

const challengeToChallengeFilterFormData = (challenge: Challenge): ChallengeFilterFormData => {
	const filters = Object.entries(challenge.filter)
		.map(([key, value]) => ({ filter: key, value }))
		.filter((filter) => filter.value?.length)

	const formData: ChallengeFilterFormData = {
		filters,
		individualTarget: challenge.individualTarget,
		// dates must use local timezone (no "Z" in the end)
		startDate: new Date(challenge.startDate).toISOString().split('T')[0].concat('T00:00:00.000'),
		endDate: new Date(challenge.endDate).toISOString().split('T')[0].concat('T23:59:59.999'),
	}

	if (!removeVendorIdsFromFilters(formData.filters).length) {
		formData.filters.push({ filter: '', value: [] })
	}

	return formData
}

const challengeToChallengeDetailsFormData = (challenge: Challenge): ChallengeDetailsFormData => {
	if (isNotPurchaseOrVolume(challenge)) {
		return {
			points: challenge.points,
		}
	}

	const skus = challenge.items?.map((item) => ({
		...item,
		sku: item.vendorItemId,
	}))

	const formData: ChallengeDetailsFormData = {
		points: challenge.points,
		quantity: challenge.quantityMin,
		items: challenge.items,
		skus,
	}

	return formData
}

const challengeSetupFormDataToChallenge = (
	challengeSetupFormData: ChallengeSetupFormData,
	baseChallenge: Challenge,
): Challenge => {
	return {
		...baseChallenge,
		...challengeSetupFormData,
		image: challengeSetupFormData.image?.url,
		goodPhotoSample: challengeSetupFormData.goodPhoto?.url,
		badPhotoSample: challengeSetupFormData.badPhoto?.url,
	}
}

const challengeFilterFormDataToChallenge = (
	challengeFilterFormData: ChallengeFilterFormData,
	baseChallenge: Challenge,
): Challenge => {
	const result = {
		...baseChallenge,
		filter: !challengeFilterFormData?.filters ? {} : parseChallengeFilterFormData(challengeFilterFormData),
	}
	if (!isSameDateAndTime(baseChallenge.startDate, challengeFilterFormData.startDate!)) {
		const date = startOfDay(challengeFilterFormData.startDate!)
		result.startDate = formatToDateAndForceUTC0(date)
	}
	if (!isSameDateAndTime(baseChallenge.endDate, challengeFilterFormData.endDate!)) {
		const date = endOfDay(challengeFilterFormData.endDate!)
		result.endDate = formatToDateAndForceUTC0(date)
	}
	return result
}

const challengeDetailsFormDataToChallenge = (
	challengeDetailsFormData: ChallengeDetailsFormData,
	baseChallenge: Challenge,
): Challenge => {
	return {
		...baseChallenge,
		points: challengeDetailsFormData.points,
		quantityMin: challengeDetailsFormData.quantity,
		skus: challengeDetailsFormData?.skus,
		items: challengeDetailsFormData?.items,
	}
}

export {
	challengeStateValuesToChallenge,
	challengeSetupFormDataToChallengeFiles,
	challengeToChallengeFilterFormData,
	challengeToChallengeDetailsFormData,
	challengeToChallengeSetupFormData,
	parseChallengeDate,
	challengeSetupFormDataToChallenge,
	challengeFilterFormDataToChallenge,
	challengeDetailsFormDataToChallenge,
}
