import { isToggleEnabled } from '@/hooks'
import { DEV_MULTI_VENDOR_EARNING_RULES } from '@/utils/featureToggle'
import { updateRule, putItemRule } from '@/services/program/ProgramService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import SkuForm from '@/domains/sku/SkuForm'

async function execute(programId: string, ruleId: string, sku: SkuForm): Promise<boolean> {
	try {
		if (isToggleEnabled(DEV_MULTI_VENDOR_EARNING_RULES)) {
			const vendorId = getDefaultVendorIdOfCurrentZone()
			await putItemRule(programId, ruleId, sku.itemId, { vendorItemId: sku.id, vendorId })
		}
		await updateRule(programId, ruleId, { skus: [sku.id] })
		return true
	} catch {
		return false
	}
}

export { execute }
