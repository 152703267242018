import { CardSelect } from '@/components/core'
import { ScreenName } from '@/domains'
import { ICardOption } from '@/domains/option'
import { SKUProcess } from '@/domains/sku/SkuStep'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { RulesEvents, RulesStore } from '@/stores'
import { useStoreMap } from 'effector-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const StepProcess: React.FC = () => {
	const userInfo = useGetUserInfo()
	const { t } = useTranslation()
	const process = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => state.editSkuStepData.process ?? null,
	})
	const classes = useStyles()

	const options = useMemo<Array<ICardOption>>(
		() => [
			{
				label: t('rules:STEPS.PROCESS.SINGLE.LABEL'),
				subLabel: t('rules:STEPS.PROCESS.SINGLE.SUBLABEL'),
				value: SKUProcess.SINGLE_SKU,
			},
			{
				label: t('rules:STEPS.PROCESS.MULTIPLE.LABEL'),
				subLabel: t('rules:STEPS.PROCESS.MULTIPLE.SUBLABEL'),
				value: SKUProcess.MULTIPLE_SKU,
			},
		],
		[t],
	)

	const handleSelection = (value: string) => {
		const splitedButtonValue = value.split('_')
		const formatedButtonName = `${splitedButtonValue[0][0]}${splitedButtonValue[0]
			.slice(1, splitedButtonValue[0].length)
			.toLowerCase()} ${splitedButtonValue[1]}`

		AnalyticsService.events.radioButtonInteraction({
			radio_button_name: formatedButtonName,
			radio_button_label: formatedButtonName,
			form_name: ScreenName.EarningRulesSkuEditStep2,
			screen_name: ScreenName.EarningRulesSkuEditStep2,
			...userInfo,
		})
		RulesEvents.setPropInEditSkuStepData({
			process: value,
		})
	}

	return (
		<div className={classes.container}>
			<CardSelect options={options} value={process} onSelect={handleSelection} />
		</div>
	)
}

export default StepProcess
