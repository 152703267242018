import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const ChallengeFormFilterStyle = makeStyles((theme: Theme) =>
	createStyles({
		addButton: {
			marginTop: '0',
		},
		filterBoxLine: {
			margin: '24px 0 16px',
			border: '1px solid',
			borderColor: '#DFDEDE',
		},
		textHelper: {
			position: 'relative',
			top: '-8px',
		},
		label: {
			fontFamily: 'Barlow',
			fontSize: '20px',
			fontWeight: 600,
			lineHeight: '24px',
			letterSpacing: 0,
			transition: 'color ease 0.3s',
			'& label.error': {
				color: theme.palette.error.main,
			},
			'& label.disabled': {
				color: theme.palette.text.disabled,
			},
		},
	}),
)

export default ChallengeFormFilterStyle
