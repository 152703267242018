import TransparencyHistory from 'domains/transparency/TransparencyHistory'
import { load } from 'services/transparency/TransparencyService'
import { convertAll } from '@/converters/transaction/TransparencyToTransparencyHistory'
import { TransparencyType } from '@/domains/enums'

async function execute(type: TransparencyType = TransparencyType.RewardsOfferMultiple): Promise<TransparencyHistory[]> {
	return load(type)
		.then((logs) => {
			return convertAll(logs.content)
		})
		.catch((error) => {
			throw error
		})
}

export { execute }
