import { Image, Paragraph } from '@hexa-ui/components'
import { InputCurrency } from '@/components/hook-form/InputCurrency'
import { ScreenName } from '@/domains'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { useIsEditingAllowed } from '@/routes/zoneConfiguration/hooks/useIsEditingAllowed'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { $pricePerPointConfigurationStore } from '@/stores'
import { format, getSymbol } from '@/utils/currencyHelper'
import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { DEFAULT_IMAGE_FALLBACK_URL } from '@/utils/constants'
import { RedeemableItemCellStyle } from './RedeemableItemCellStyle'

type RedeemableItemProps = {
	isVariant?: boolean
}

export const RedeemableItemCell = ({
	image,
	id,
	itemName,
	fullPackageDescription,
	fullContainerDescription,
	vendorItemId,
	pricePerPoint,
	isVariant,
}: RedeemableItem & RedeemableItemProps) => {
	const { pricePerPoint: defaultPricePerPointValue } = useStore($pricePerPointConfigurationStore)
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()
	const { isRedeemableCPPPermission } = useIsEditingAllowed()
	const {
		control,
		setValue,
		formState: { errors },
		reset,
	} = useFormContext()
	const userInfo = useGetUserInfo()
	const defaultPricePerPoint = useFormatCurrency(defaultPricePerPointValue, '')
	const { t } = useTranslation()
	const css = RedeemableItemCellStyle()

	useEffect(() => {
		const errorMessage = t(errors.pricePerPoint?.message)

		if (!id && errorMessage) {
			AnalyticsService.events.error({
				form_name: '',
				screen_name: 'Create Redeemable',
				failure_reason: errorMessage,
				...userInfo,
			})
		}

		if (id && errorMessage) {
			sendAnalyticsServiceEventError({ screenName: ScreenName.EditRedeemablePPP, failureReason: errorMessage })
		}
	}, [errors.pricePerPoint?.message, sendAnalyticsServiceEventError, userInfo, t, id])

	useEffect(() => {
		reset()
		const initialValue = pricePerPoint ? format(pricePerPoint) : undefined
		setValue('pricePerPoint', initialValue, {
			shouldDirty: false,
		})
		return () => reset()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div
			data-testid="redeemable-item-cell"
			className={classNames(css.container, {
				[css.mainVariantContainer]: !isVariant,
			})}
		>
			<div className={css.productWrapper} data-testid="redeemable-item-product">
				<div>
					<Image
						src={image ?? DEFAULT_IMAGE_FALLBACK_URL}
						variant="fit"
						aspectRatio={1}
						width="80px"
						data-testid="redeemable-item-product-image"
					/>
				</div>
				<div>
					<Paragraph size="small" weight="semibold" data-testid="redeemable-item-product-name">
						{itemName}
					</Paragraph>
					<ItemDescription
						fullPackageDescription={fullPackageDescription}
						fullContainerDescription={fullContainerDescription}
					/>
					<Paragraph size="small" data-testid="redeemable-item-product-sku">
						{vendorItemId}
					</Paragraph>
				</div>
			</div>
			<div className={css.inputWrapper} data-testid="ppp-editor-input-wrapper">
				<Controller
					control={control}
					name={!isVariant ? 'pricePerPoint' : `pricePerPoint-${id}`}
					render={({ field }) => {
						const value = field?.value?.replace(/[^0-9]/g, '')
						return (
							<InputCurrency
								{...field}
								id="input-ppp"
								required
								placeholder={t('redeemable:CREATE.ENTER_CPP')}
								hint={`${t('redeemable:CREATE.DEFAULT_CPP')}: ${defaultPricePerPoint}`}
								disabled={!isRedeemableCPPPermission || isVariant}
								data-testid="ppp-editor-input"
								label={t('redeemable:CREATE.PRICE_PER_POINT_ACRONYM')}
								errorText={t(errors.pricePerPoint?.message)}
								prefix={value ? undefined : getSymbol()}
							/>
						)
					}}
				/>
			</div>
		</div>
	)
}

type ItemDescriptionProps = {
	fullContainerDescription?: string
	fullPackageDescription?: string
}

export const ItemDescription = ({ fullPackageDescription, fullContainerDescription }: ItemDescriptionProps) => {
	return (
		<Paragraph size="small" data-testid="redeemable-item-product-description">
			{fullContainerDescription} - {fullPackageDescription}
		</Paragraph>
	)
}
