import React from 'react'
import { InputText } from '@/components/form'
import { useTranslation } from 'react-i18next'
import { Grid, Grow, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { Controller, DeepMap, FieldError, useFormContext } from 'react-hook-form'
import { ComboFormFieldArray } from '@/domains'
import useStyles from './style'
import SingleComboId from './SingleComboId'

type Props = {
	index: number
	disableRemoveButton?: boolean
	handleRemoveItem: (id: number) => void
	handleValidation: (value: string, name: string) => Promise<boolean>
}

const SingleComboFields: React.FC<Props> = ({
	index,
	disableRemoveButton,
	handleValidation,
	handleRemoveItem,
}: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { control, trigger, formState } = useFormContext()

	const revalidateAllDuplicated = () => {
		const { combos } = formState.errors as DeepMap<{ combos: ComboFormFieldArray }, FieldError>
		if (combos) {
			combos.forEach((element, fieldIndex: number) => {
				if (element?.comboId?.message && element.comboId.type === 'unique') {
					trigger(`combos[${fieldIndex}].comboId`)
				}
			})
		}
	}

	const handleRemoveClick = () => {
		if (!disableRemoveButton) {
			handleRemoveItem(index)
			revalidateAllDuplicated()
		}
	}

	return (
		<Grow in>
			<Grid
				container
				spacing={2}
				justifyContent="center"
				alignContent="center"
				id="fields-box"
				className={classes.boxContainer}
			>
				<Grid item xs={7} className={classes.row}>
					<SingleComboId index={index} handleValidation={handleValidation} />
				</Grid>
				<Grid item xs={5} className={classes.row}>
					<Controller
						name={`combos[${index}].points`}
						defaultValue=""
						control={control}
						render={({ field, fieldState: { error } }) => (
							<InputText
								{...field}
								id={`points${index}`}
								label={t('combo:DT_COMBOS.POINTS')}
								errorText={error?.message}
								type="number"
							/>
						)}
					/>
					<div className={classes.removeContainer}>
						<Typography
							id="remove-link"
							variant="body2"
							color="primary"
							className={clsx({
								[classes.removeButton]: !disableRemoveButton,
								[classes.inactiveButton]: disableRemoveButton,
							})}
							onClick={handleRemoveClick}
						>
							{t('combo:DT_COMBOS.REMOVE_BUTTON')}
						</Typography>
					</div>
				</Grid>
			</Grid>
		</Grow>
	)
}

export default SingleComboFields
