import React from 'react'
import { Paper } from '@/components/core'
import { Grid, Box } from '@material-ui/core'
import useStyles from './styles'

type Props = {
	children: React.ReactNode
}

const Section: React.FC<Props> = ({ children }) => {
	const classes = useStyles()

	return (
		<Paper className={classes.container}>
			<Grid container>
				<Box className={classes.boxItem}>{children}</Box>
			</Grid>
		</Paper>
	)
}

export default Section
