import { useCallback } from 'react'
import { ComboEvents } from '@/stores'
import { ComboType, RedeemableStatus } from '@/domains/enums'
import { Redeemable } from '@/domains'
import { LoadRedeemableByStatusUseCase, LoadRedeemableUseCase } from '@/usecase'
import { useIsMountedRef } from '../mountedRef/useIsMountedRef'

type RedeemableList = Array<Redeemable<ComboType>>

export interface LoadRedeemablesAndCombos {
	loadRedeemablesAndCombos: (programId: string) => Promise<RedeemableList>
	loadRedeemableByStatus: (status: Array<RedeemableStatus>) => Promise<void>
}

const useLoadRedeemablesAndCombos = (): LoadRedeemablesAndCombos => {
	const isMountedRef = useIsMountedRef()

	const loadRedeemablesAndCombos = useCallback(async (programId: string): Promise<RedeemableList> => {
		try {
			const result = await LoadRedeemableUseCase.execute([programId])
			const redeemables = Object.values(result.redeemablesMap)
			ComboEvents.setCombos({ ...result, showError: false })
			return redeemables
		} catch {
			ComboEvents.showErrorInCombosData()
			return []
		}
	}, [])

	const loadRedeemableByStatus = useCallback(
		async (status: Array<RedeemableStatus>) => {
			try {
				const result = await LoadRedeemableByStatusUseCase.execute(status)
				if (isMountedRef.current) {
					ComboEvents.setCombos({ ...result, showError: false })
				}
			} catch (error) {
				ComboEvents.showErrorInCombosData()
				throw error
			}
		},
		[isMountedRef],
	)

	return {
		loadRedeemablesAndCombos,
		loadRedeemableByStatus,
	}
}

export default useLoadRedeemablesAndCombos
