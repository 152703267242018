import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react'
import { SkuModel } from '@dlb-shared-business-components/sku-selector/dist/declarations/src/lib/domain/skus.types'

type SkusContextType = {
	selectedSkus: SkuModel[]
	setSelectedSkus: React.Dispatch<React.SetStateAction<SkuModel[]>>
}

const SkusContext = createContext<SkusContextType | undefined>(undefined)

export const SkusProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [selectedSkus, setSelectedSkus] = useState<SkuModel[]>([])

	const value = useMemo(() => ({ selectedSkus, setSelectedSkus }), [selectedSkus, setSelectedSkus])

	return <SkusContext.Provider value={value}>{children}</SkusContext.Provider>
}

export const useSkus = (): SkusContextType => {
	const context = useContext(SkusContext)
	if (!context) {
		throw new Error('useSkus must be used within a SkusProvider')
	}
	return context
}
