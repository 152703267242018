import React from 'react'
import { Select } from '@hexa-ui/components'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { LOCALES } from './constants'

export function getMonthNames(langCode: string): JSX.Element[] {
	const selectedLocale = LOCALES[langCode] ?? enUS
	if (!selectedLocale) {
		throw new Error('Invalid language or language not supported')
	}

	const monthNames = []

	for (let month = 1; month < 13; month += 1) {
		const date = new Date(2021, month - 1, 1)
		const monthName = format(date, 'MMMM', { locale: selectedLocale })
		const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1)
		monthNames.push(
			<Select.Option key={month} value={month.toString()}>
				{capitalizedMonthName}
			</Select.Option>,
		)
	}

	return monthNames
}
