import React, { ReactElement } from 'react'
import { Alert } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import styles from './styles'
import { TableStyle } from '../model/TableStyle'

type Props = {
	message?: string
	style?: TableStyle
}

const TableEmptyMessage = ({ message, style }: Props): ReactElement => {
	const classes = styles({ ...style })

	return (
		<Alert
			icon={style?.emptyMessage?.showIcon ? undefined : false}
			variant="standard"
			color="info"
			classes={{ standardInfo: classes.standardInfo, icon: classes.icon }}
			severity="info"
		>
			<Typography>{message}</Typography>
		</Alert>
	)
}

export default TableEmptyMessage
