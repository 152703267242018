import React from 'react'
import { AlertDialog } from '@/components/core'
import { useTranslation } from 'react-i18next'

type Props = {
	showSubmitDialog: boolean
	handleCancel: () => void
	handleSubmit: () => void
}

const ChallengeSubmitDialogView: React.FC<Props> = ({ showSubmitDialog, handleCancel, handleSubmit }: Props) => {
	const { t } = useTranslation()

	return (
		<AlertDialog
			title={t('challenge:DIALOG.CONFIRMATION_HEADER')}
			show={showSubmitDialog}
			handleCancel={handleCancel}
			handleConfirm={handleSubmit}
		>
			{t('challenge:DIALOG.CONFIRMATION_MESSAGE')}
		</AlertDialog>
	)
}

export default React.memo(ChallengeSubmitDialogView)
