import { createStyles, makeStyles } from '@material-ui/core/styles'

const EditRedeemableItemStyle = makeStyles(() =>
	createStyles({
		container: {},
		descriptionWrapper: {
			paddingBottom: '16px',
		},
		itemCellWrapper: {
			backgroundColor: 'var(--colors-neutral20)',
			borderRadius: '8px',
		},
		actionsWrapper: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: '24px',
		},
	}),
)

export default EditRedeemableItemStyle
