import EmptyState from '@/components/icons/EmptyState'
import { Heading, Table } from '@hexa-ui/components'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Trash2 } from '@admin-portal-shared-components/icons'
import { SkuModel } from '@dlb-shared-business-components/sku-selector/dist/declarations/src/lib/domain/skus.types'
import Styled from './RewardsOfferTable.style'
import { SKUWithoutImage } from '../SKUWithoutImage/SKUWithoutImage'

type RewardsOfferTableProps = {
	selectedSkus: SkuModel[]
	onSkuRemove: (sku: string) => void
}

const RewardsOfferTable: React.FC<RewardsOfferTableProps> = ({ selectedSkus, onSkuRemove }) => {
	const { t } = useTranslation()

	const skuMapped = useMemo<SkuModel[]>(() => {
		return selectedSkus.map((item) => ({
			value: item.value,
			description: item.description,
			type: 'SKU',
			icon: item.icon,
			manufacturer_id: item.manufacturer_id,
			sub_description: item.sub_description,
		}))
	}, [selectedSkus])

	const ProductRender = useCallback(
		(props: SkuModel) => (
			<Styled.ProductWrapper>
				{props?.icon ? (
					<Styled.Image src={props?.icon} alt={props.description} />
				) : (
					<Styled.ImagePlaceholder data-testid="SKU-placeholder-img">
						<SKUWithoutImage />
					</Styled.ImagePlaceholder>
				)}
				<Heading size="H5">{props.description}</Heading>
			</Styled.ProductWrapper>
		),
		[],
	)

	const RemoveRender = useCallback(
		(props: SkuModel) => (
			<Styled.SkuRemove data-testid="rewards-offer-remove-item">
				<Trash2 size="medium" onClick={() => onSkuRemove?.(props.value)} />
			</Styled.SkuRemove>
		),
		[onSkuRemove],
	)

	const dataColums = [
		{
			Header: t('transaction:REWARDS_OFFER.NAME'),
			accessor: 'description',
			disableSortBy: true,
			style: {
				width: '50%',
			},
			Cell: ({ row: { original } }: { row: { original: SkuModel } }) => ProductRender(original),
		},
		{
			Header: t('transaction:REWARDS_OFFER.SKU_CODE'),
			accessor: 'value',
			style: {
				width: '40%',
			},
		},
		{
			Header: t('transaction:REWARDS_OFFER.ACTION'),
			accessor: 'action',
			Cell: ({ row: { original } }: { row: { original: SkuModel } }) => RemoveRender(original),
			style: {
				width: '10%',
			},
		},
	]

	return (
		<Styled.EmptyTableContainer data-testid="rewards-offer-empty-table">
			<Table
				columns={dataColums}
				data={skuMapped}
				tableHeight={skuMapped.length > 0 ? 'fit-content' : '237px'}
				emptyMessage={
					<Styled.EmptyMessage>
						<EmptyState />
						<Styled.EmptyMessageText>{t('transaction:REWARDS_OFFER.NO_SKU_SELECTED')}</Styled.EmptyMessageText>
					</Styled.EmptyMessage>
				}
			/>
		</Styled.EmptyTableContainer>
	)
}

export default RewardsOfferTable
