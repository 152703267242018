import { Grid, Tooltip } from '@hexa-ui/components'
import { Dot, Info } from '@hexa-ui/icons'
import React from 'react'
import styles from './styles'

export type BadgeStatusProps = {
	children: React.ReactNode
	type: BadgeStatusType
	infoMessage?: string
}

export enum BadgeStatusType {
	info = 'info',
	success = 'success',
	error = 'error',
	warning = 'warning',
	disabled = 'disabled',
}

export const BadgeStatus = ({ children, type, infoMessage }: BadgeStatusProps) => {
	const css = styles()

	return (
		<Grid.Item className={`${css.container}`}>
			<span data-testid="badge-status-indicator">
				<Dot size="tiny" className={`${css.indicator} ${type}`} />
			</span>

			<span className={`${css.label} ${type}`} data-testid="badge-status-label">
				{children}
			</span>

			{infoMessage && (
				<span data-testid="badge-status-info-icon">
					<Tooltip text={infoMessage}>
						<Info size="medium" className={css.infoIcon} />
					</Tooltip>
				</span>
			)}
		</Grid.Item>
	)
}
