import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		title: {
			fontSize: '20px',
			fontWeight: 500,
			letterSpacing: '0',
			lineHeight: '24px',
		},
		container: {
			marginTop: '32px',
		},
		gridContainer: {
			width: '100%',
			minHeight: '16px',
			gap: '16px',
			display: 'grid',
			gridTemplateColumns: 'auto auto',
		},
		paper: {
			borderRadius: '24px',
			boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.16)',

			'& .membership-MuiOutlinedInput-root': {
				borderRadius: '8px',
			},
		},
	}),
)

export default styles
