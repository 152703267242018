/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { Chip, CircularProgress, TextField } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'
import {
	Autocomplete,
	AutocompleteProps,
	GetTagProps,
	RenderInputParams,
	AutocompleteChangeReason,
	AutocompleteChangeDetails,
	FilterOptionsState,
} from '@material-ui/lab'

import InputContainer, { InputContainerProps } from '@/components/form/InputContainer'
import { removeDuplicates } from '@/utils/array'
import styles from './styles'

export interface AutoCompleteOptions {
	value: string | number
	label: string
	selectable: boolean
}

export interface AutoCompleteAction {
	handleSelect: () => void
	label: string
	position?: 'first' | 'last'
}

export type InputAutoCompleteProps = {
	name?: string
	action?: AutoCompleteAction
	options: AutoCompleteOptions[]
	isLoading?: boolean
	autoFocus?: boolean
	fixedValues?: string[]
	value?: AutoCompleteOptions[]
	onChange?: (
		event: React.ChangeEvent<any>,
		newValues: AutoCompleteOptions[],
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<AutoCompleteOptions>,
	) => void
} & Omit<AutocompleteProps<AutoCompleteOptions>, 'renderInput'> &
	InputContainerProps

const InputAutoComplete: React.FC<InputAutoCompleteProps> = ({
	id,
	name,
	label,
	optionalLabel,
	errorText,
	helperText,
	disabled,
	className,
	action,
	options,
	isLoading,
	autoFocus,
	fixedValues,
	...rest
}) => {
	const classes = styles()
	const fixedOptions = options.filter((option) => fixedValues?.includes(option.value as string))

	const handleChange = (
		event: React.ChangeEvent<any>,
		newValues: AutoCompleteOptions[],
		reason: AutocompleteChangeReason,
		details?: AutocompleteChangeDetails<AutoCompleteOptions> | undefined,
	): void => {
		const isValidNewValues = newValues && newValues.length > 0

		if (action && isValidNewValues && newValues.find((el) => el.selectable === false)) {
			// if selected option is an action
			action.handleSelect()
			return
		}

		if (fixedValues?.length) {
			if (rest.onChange) {
				const allValues = removeDuplicates([...fixedOptions, ...newValues])
				rest.onChange(event, allValues, reason, details)
			}
		} else if (rest.onChange) {
			rest.onChange(event, newValues, reason, details)
		}
	}

	const filterOptions = (
		autoCompleteOptions: AutoCompleteOptions[],
		state: FilterOptionsState<AutoCompleteOptions>,
	): AutoCompleteOptions[] => {
		if (state.inputValue) {
			return autoCompleteOptions.filter((option) => {
				const isActionOption = option.label === action?.label
				const match = option.label.toLowerCase().includes(state.inputValue.toLowerCase())
				return isActionOption || match
			})
		}
		return autoCompleteOptions
	}

	const renderTags = (value: AutoCompleteOptions[], getTagProps: GetTagProps) => {
		return value.map((option, index) =>
			fixedValues?.includes(`${option.value}`) && fixedValues.length ? (
				<Chip
					key={`chip-${option.value} `}
					label={option.label}
					classes={{
						root: classes.chip,
						label: classes.chipLabel,
						deleteIcon: classes.deleteIcon,
					}}
				/>
			) : (
				<Chip
					key={`chip-${option.value} `}
					label={option.label}
					{...getTagProps({ index })}
					deleteIcon={<ClearIcon data-testid={`delete-filter-values-${index}`} />}
					classes={{
						root: classes.chip,
						label: classes.chipLabel,
						deleteIcon: classes.deleteIcon,
					}}
				/>
			),
		)
	}

	const renderInput = (params: RenderInputParams): ReactElement => (
		<TextField
			{...params}
			autoFocus={autoFocus}
			name={name}
			fullWidth
			variant="outlined"
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
						{isLoading ? <CircularProgress color="inherit" size={20} /> : null}
						{params.InputProps.endAdornment}
					</>
				),
			}}
		/>
	)

	const getOptions = (): AutoCompleteOptions[] => {
		if (!action) {
			return options
		}

		const actionOption = { value: 0, label: action.label, selectable: false }
		return action.position === 'first' ? [actionOption, ...options] : [...options, actionOption]
	}

	return (
		<InputContainer
			id={id}
			label={label}
			optionalLabel={optionalLabel}
			errorText={errorText}
			helperText={helperText}
			disabled={disabled}
			className={className}
		>
			<Autocomplete
				multiple
				id={id}
				classes={{
					root: classes.root,
					tag: classes.chip,
					endAdornment: classes.endAdornment,
				}}
				size="small"
				renderTags={renderTags}
				options={getOptions()}
				getOptionLabel={(option): string => option.label}
				filterOptions={filterOptions}
				loading={isLoading}
				disabled={disabled}
				renderInput={(params): ReactElement => renderInput(params)}
				{...rest}
				onChange={handleChange}
			/>
		</InputContainer>
	)
}

export default InputAutoComplete
