import React from 'react'
import { FileUploader } from '@/components/form/FileUpload/redesignUpload'
import { InputController, TextAreaController } from '@/components/core'
import { AcceptableTypes } from '@/components/formik/FileUpload'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RedesignUploadButton } from '@/components/form/FileUpload/FileUploadButton/redesignUploadButton'
import { Container, ControllerDiv } from '../styles'

type Props = {
	handleChangeFile: (event: React.ChangeEvent<HTMLInputElement>, field: string) => void
	handleRemoveImage: (field: string, files: File) => void
	isTakePhotoExecutionMethod?: boolean
	index?: number
	language?: string
}

const ChallengeFormSetupInputs: React.FC<Props> = ({
	handleChangeFile,
	handleRemoveImage,
	isTakePhotoExecutionMethod,
	index,
	language,
}) => {
	const { t } = useTranslation()
	const { control, getValues } = useFormContext()
	const isMultiTabs = !!language && !!index && index > 0

	return (
		<Container>
			<InputController
				label={t('challenge:SETUP_STEP.CHALLENGE_TITLE')}
				name={isMultiTabs ? `title-${language}` : 'title'}
				control={control}
				isMultiTab={isMultiTabs}
				multiTabText={getValues().title}
			/>
			<TextAreaController
				label={t('challenge:SETUP_STEP.CHALLENGE_DESCRIPTION')}
				name={isMultiTabs ? `description-${language}` : 'description'}
				control={control}
				isMultiTab={isMultiTabs}
				multiTabText={getValues().description}
			/>

			<ControllerDiv>
				<Controller
					control={control}
					name={isMultiTabs ? `image-${language}` : 'image'}
					render={({ field: { name, value }, fieldState: { error } }) => (
						<FileUploader
							name={name}
							currentValue={value}
							description={t('challenge:SETUP_STEP.IMAGE_SUPPORTED_MAX_1MB')}
							message={t('challenge:SETUP_STEP.IMAGE_SUPPORTED_TYPE')}
							buttonIntl={t('BUTTON.UPLOAD')}
							accept=".png,.jpg,.jpeg"
							label={t('challenge:SETUP_STEP.IMAGE_URL')}
							type="image"
							onUpload={handleChangeFile}
							onRemove={handleRemoveImage}
							error={error && { message: error.message as unknown as string }}
						/>
					)}
				/>
			</ControllerDiv>

			{isTakePhotoExecutionMethod &&
				['goodPhoto', 'badPhoto'].map((typePhoto) => (
					<ControllerDiv key={typePhoto}>
						<Controller
							control={control}
							name={isMultiTabs ? `${typePhoto}-${language}` : `${typePhoto}`}
							render={({ field: { name }, fieldState: { error } }) => (
								<RedesignUploadButton
									name={name}
									label={
										typePhoto === 'goodPhoto'
											? t('challenge:SETUP_STEP.GOOD_PHOTO_URL')
											: t('challenge:SETUP_STEP.BAD_PHOTO_URL')
									}
									description={t('challenge:SETUP_STEP.IMAGE_SUPPORTED_MAX_1MB')}
									uploadText={t('BUTTON.UPLOAD')}
									onRemove={handleRemoveImage}
									onChange={handleChangeFile}
									error={error?.message}
									acceptTypes={AcceptableTypes.Images}
								/>
							)}
						/>
					</ControllerDiv>
				))}
		</Container>
	)
}

export default ChallengeFormSetupInputs
