import { parse, ParseStepResult } from 'papaparse'

export const parseCSVFile = <TData>(file: File): Promise<ParseStepResult<TData>> => {
	return new Promise((resolve) => {
		parse(file, {
			header: true,
			skipEmptyLines: true,
			complete: (results) => {
				resolve(results as ParseStepResult<unknown> as ParseStepResult<TData>)
			},
		})
	})
}
