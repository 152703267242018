import { ICardOption } from '@/domains/option'
import React from 'react'
import CardOption from './CardOption'
import useStyles from './styles'

type Props = {
	options: Array<ICardOption>
	value?: string | null
	onSelect: (value: string) => void
}

const CardSelect: React.FC<Props> = ({ options, value, onSelect }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			{options.map((option) => (
				<div key={option.value} className={classes.option}>
					<CardOption option={option} selected={option.value === value} onSelect={onSelect} />
				</div>
			))}
		</div>
	)
}

export default CardSelect
