/* eslint-disable camelcase */
import { FormikValues } from 'formik'
import { BulkPointsTransactionCreated, ManualRefundTransactionCreated, PointsTransactionCreated } from '../analytics'
import OrderWithRedeemables from '../domains/OrderWithRedeemables'
import { TransactionType } from '../domains/enums'
import { OrderWithCombo } from '../domains/index'
import { UserDataResult } from '../hooks/getUserInfo/useGetUserInfo'
import { getTransactionInfo } from '../routes/transaction/components/TransactionForm/hooks/useGetTransactionInfo'
import { OrderWithCombosOrRedeemables } from '../stores/transaction/TransactionState'
import { formatToISODate } from './date'
import { getUserCountry, getUserEmail } from './tokenB2CHelper'

const dateTransaction = new Date().toString()

interface SkuItem {
	goal_input_id: string
}

type NormalizedCombosData = {
	refund_combo_id: string
	refund_combo_points: number | null
	refund_combo_quantity: number
}

const getSkus = (skus?: SkuItem[]): { sku: string }[] | null => {
	return skus?.map((skuItem: SkuItem) => ({ sku: skuItem.goal_input_id })) || null
}

const getProductCategory = (productCategory?: string[]): { dimension: string }[] | null => {
	return productCategory?.map((category: string) => ({ dimension: category })) || null
}

const getTransactionCategory = (transactionType: TransactionType, category?: string | null): string | null => {
	return (transactionType !== TransactionType.RewardsOffer && category) || null
}

const calculatePointsAdjustment = (items: { points: string }[]): number => {
	return items.reduce((total: number, item: { points: string }) => {
		return total + Number(item.points)
	}, 0)
}

export const createDataBulk = (
	transactionType: TransactionType,
	formValues: FormikValues,
): BulkPointsTransactionCreated => {
	const dataBulkPoints = {
		transaction_type: transactionType,
		transaction_date: formatToISODate(dateTransaction),
		user: getUserEmail(),
		zone: getUserCountry(),
		description: formValues.description,
		file_name: formValues.multiplePocsFile[0].name,
		file_size: formValues.multiplePocsFile[0].size.toString(),
		poc_list: formValues.multiplePocsFileConverted.length,
		submitted_id: '',
		campaign_id: null,
		points_adjustment: calculatePointsAdjustment(formValues.multiplePocsFileConverted),
		transaction_category: getTransactionCategory(transactionType, formValues.category),
		reference_month: formValues.referenceMonth || null,
		reference_year: formValues.referenceYear || null,
		reference_category: null,
		menu_category: formValues.category || null,
		skus: getSkus(formValues.skus),
		product_category: getProductCategory(formValues.productCategory),
	}

	return dataBulkPoints
}
export const createDataToPoints = (
	transactionType: TransactionType,
	formValues: FormikValues,
	userInfo: UserDataResult,
	rewardSelected?: string | null,
): PointsTransactionCreated => {
	const dataPoints = {
		transaction_type: transactionType,
		transaction_category: getTransactionCategory(transactionType, formValues.category),
		points_adjustment: formValues.points || null,
		original_poc_id: formValues.pocId || formValues.pocIdFrom,
		transfered_poc_id: formValues.pocIdTo || null,
		transaction_date: formatToISODate(dateTransaction),
		campaign_id: formValues.campaignId || null,
		description: formValues.description || formValues.reason,
		menu_category: formValues.category || null,
		reference_month: formValues.referenceMonth || null,
		reference_year: formValues.referenceYear || null,
		combos: null,
		file_name: '',
		file_size: '',
		order_date: null,
		order_id: '',
		order_status: '',
		poc_lists: [],
		points_transaction_description: '',
		target_poc_id: '',
		transparency_id: '',
		skus: getSkus(formValues.skus),
		product_category: getProductCategory(formValues.productCategory),
		screen_name: 'Points Management - Create Points Transaction',
		...userInfo,
		poc_amount: rewardSelected ?? formValues.removalOfferType ?? null,
		removal_reason: formValues.removeReason || null,
	}
	return dataPoints
}

export const createDataRefund = (
	transactionType: TransactionType,
	selectedOrder: OrderWithCombosOrRedeemables,
): ManualRefundTransactionCreated => {
	const { order, placementDate, accountId, status } = getTransactionInfo(selectedOrder)

	const orderDate = placementDate.toString() || ''
	const dataRefund = {
		transaction_type: transactionType,
		order_date: orderDate,
		transaction_date: formatToISODate(dateTransaction),
		order_status: status,
		order_id: order?.orderId ?? null,
		original_poc_id: accountId,
		user: '',
		zone: getUserCountry(),
		combos: getNormalizedCombosForData(selectedOrder),
	}

	return dataRefund
}

const getNormalizedCombosForData = (selectedOrder: OrderWithCombosOrRedeemables): NormalizedCombosData[] => {
	const orderCombos = (selectedOrder as OrderWithCombo).combos
	const orderRedeemables = (selectedOrder as OrderWithRedeemables).redeemables

	if (orderCombos && !orderRedeemables) {
		return orderCombos.map((combo) => {
			return {
				refund_combo_id: combo.comboId,
				refund_combo_points: combo.points ? combo.points * combo.quantity : null,
				refund_combo_quantity: combo.quantity,
			}
		})
	}

	return orderRedeemables.map((redeemable) => {
		return {
			refund_combo_id: redeemable.id,
			refund_combo_points: redeemable.points * redeemable.refundableQuantity,
			refund_combo_quantity: redeemable.refundableQuantity,
		}
	})
}
