import { SettingDefaultConfiguration } from '@/domains/settings'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useAdminAuditLog, { IAuditEntities, IAuditOperations } from '@/hooks/useAdminAuditLog'
import { useCallback } from 'react'

const useAuditLogZoneConfiguration = (): {
	sendAuditLogZoneConfiguration: (formData: SettingDefaultConfiguration) => void
} => {
	const userInfo = useGetUserInfo()
	const { auditLogInstance } = useAdminAuditLog()

	const sendAuditLogZoneConfiguration = useCallback(
		(formData: SettingDefaultConfiguration) => {
			auditLogInstance({
				metadata: {
					initial_balance: `${formData.initialBalance}`,
					earn_limit: `${formData.earnLimit}`,
					redeem_limit: `${formData.redeemLimit}`,
					tier: userInfo.tier ?? '',
					vendor: userInfo.vendor ?? '',
				},
				entity: IAuditEntities.CONFIGURATION,
				entityId: userInfo.vendor ?? '',
				operation: IAuditOperations.UPDATE,
			})
		},
		[auditLogInstance, userInfo.tier, userInfo.vendor],
	)

	return { sendAuditLogZoneConfiguration }
}

export default useAuditLogZoneConfiguration
