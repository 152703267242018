import { createStyles, makeStyles } from '@material-ui/core/styles'

const EditRedeemableItemStyle = makeStyles(() =>
	createStyles({
		modalAlertWrapper: {
			'& [role="dialog"]': {
				maxWidth: '640px',
				width: '80%',

				'& > div:first-child': {
					height: 'auto',
					marginBottom: 0,

					'& > div:first-child': {
						padding: '24px',
						paddingBottom: 0,
					},

					'& h1': {
						fontSize: '24px',
						lineHeight: '1.2em',
					},
				},

				'& > div:last-of-type': {
					padding: '24px',
					paddingTop: 0,
				},
			},
		},

		descriptionWrapper: {
			marginBottom: '16px',
		},
		chipsWrapper: {
			marginBottom: '32px',
		},
		itemCellWrapper: {
			backgroundColor: 'var(--colors-neutral20)',
			borderRadius: '8px',
		},
		actionsWrapper: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: '16px',
		},
		container: {
			paddingTop: '16px',

			'& [role="dialog"]': {
				width: '80%',
				maxWidth: '640px',
			},

			'& > [role="dialog"] > div:first-child': {
				marginBottom: '8px',
			},
		},
		cancelButton: {
			display: 'block',
			marginRight: '16px',
		},
		errorMessage: {
			width: '100%',
			marginTop: 'var(--space-2)',
		},
		warningMessage: {
			width: '100%',
		},
	}),
)

export default EditRedeemableItemStyle
