import React from 'react'
import { Radio } from '@hexa-ui/components'
import { FormHelperText } from '@material-ui/core'

import styles from '../../../routes/challenge/componentsRedesign/ChallengeFormFilter/ChallengeFormFilterStyle'

export type RadioItemHexa = {
	id: string
	label: string
	sublabel: string
	value: string
	checked: boolean
}

type Props = {
	radioItems: RadioItemHexa[]
	handleRadioChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
	flexDisplay?: boolean
}

const HexaRadioGroup: React.FC<Props> = ({ radioItems, handleRadioChange, flexDisplay = false }) => {
	const classes = styles()

	return (
		<Radio.Root
			aria-labelledby="challange-radio-buttons-group-label"
			name="radio-buttons-group"
			style={flexDisplay ? { display: 'flex', gap: '24px' } : {}}
		>
			{radioItems.map(({ id, label, value, sublabel, checked }) => (
				<div key={id} style={flexDisplay ? { display: 'flex', flexDirection: 'column' } : {}}>
					<Radio.Item
						id={id}
						label={label}
						value={value}
						checked={checked}
						onClick={(ev) => handleRadioChange(ev as unknown as React.ChangeEvent<HTMLInputElement>)}
					/>
					<FormHelperText className={classes.textHelper}>{sublabel}</FormHelperText>
				</div>
			))}
		</Radio.Root>
	)
}

export default HexaRadioGroup
