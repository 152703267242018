import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		title: {
			display: 'flex',
			justifyContent: 'space-between',
			marginRight: '-32px',
			height: '110px',
			'& > h1': {
				fontFamily: 'Barlow',
				fontSize: '32px',
				maxWidth: '350px',
				lineHeight: '40px',
			},
		},
		closeButton: {
			width: '40px',
			height: '40px',
			backgroundColor: '#F2F2F2',
			borderRadius: '20px',
		},
		footer: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: '16px',
		},
	}),
)

export default styles
