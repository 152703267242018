import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsTableSorted {
	sortCriteria: string
	sortType: string
	tableName: string
	screenName: string
}

export const useAnalyticsServiceEventTableSorted = (): {
	sendAnalyticsServiceTableSorted: (recordData: AnalyticsTableSorted) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceTableSorted = useCallback(
		({ sortCriteria, sortType, tableName, screenName }: AnalyticsTableSorted) => {
			AnalyticsService.events.tableSorted({
				sort_criteria: sortCriteria,
				sort_type: sortType,
				table_name: tableName,
				screen_name: screenName,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceTableSorted }
}
