import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		containerItem: {
			marginTop: '16px',
		},
		itemTitle: {
			marginBottom: '4px',
		},
		tableStatus: {
			'& svg': {
				display: 'none',
			},
		},
		rejectionReason: {
			marginBottom: '4px',
			color: theme.palette.error.dark,
		},
	}),
)

const gridContainerStyles = {
	rowGap: '1rem',
	margin: '1rem 0',
}

export { useStyles, gridContainerStyles }
