import * as RedeemablesService from '@/services/redeemablesV2/RedeemablesService'
import { useLogService as logService } from 'admin-portal-shared-services'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { tokenData } from '../../../server/utils/token'

async function execute(pricePerPoint: number, defaultCpp: number): Promise<void> {
	const log = logService()
	const vendorId = getDefaultVendorIdOfCurrentZone()

	try {
		const requestData = {
			pricePerPoint: +pricePerPoint,
			vendorId,
			defaultCpp,
			userId: tokenData.sub,
		}
		await RedeemablesService.updateDefaultCpp(requestData)
	} catch (error) {
		log.error('FAILED TO UPDATE DEFAULT CPP: ', error)
		throw error
	}
}

export { execute }
