import { TransactionHistoryResult } from '@/domains'
import * as TransactionService from '@/services/transaction/TransactionService'
import transactionToTransactionHistory from '@/converters/transaction/TransactionToTransactionHistory'
import * as TranslateAccountIdUseCase from '@/usecase/account/TranslateAccountIdUseCase'
import AccountValidationResult from 'domains/enums/account/AccountValidationResult'

const PAGE_SIZE = 900000

export async function execute(pocId: string): Promise<TransactionHistoryResult> {
	const { accountId, validationResult } = await TranslateAccountIdUseCase.findValidAccountId(pocId)

	if (validationResult !== AccountValidationResult.OK) {
		return {
			data: [],
			validationResult,
		}
	}

	const transactions = await TransactionService.getTransactions(accountId!, undefined, {
		page: 0,
		pageSize: PAGE_SIZE,
	})

	const data = transactionToTransactionHistory(transactions)

	return {
		data,
		validationResult: AccountValidationResult.OK,
	}
}
