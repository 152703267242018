import { useLogService as logService } from 'admin-portal-shared-services'

import Program from 'domains/program/Program'
import ProgramComboRequest from 'domains/program/ProgramComboRequest'
import ProgramProjection from 'domains/enums/program/ProgramProjection'
import ProgramRulePatchRequest from 'domains/program/ProgramRulePatchRequest'
import axios from '@/utils/axiosInstance'
import ProgramPatchRequest from '@/domains/program/ProgramPatchRequest'
import {
	LoadRedeemableRequest,
	PagedResponse,
	ProgramRedeemableRequest,
	ProgramRulePutItemRequest,
	Redeemable,
} from '@/domains'
import { AxiosResponse } from 'axios'
import { hasElements } from '@/utils/array'

const endPoint = `/v1/rewards-service/programs`
const log = logService()

const sortPrograms = (programs?: Array<Program>): Array<Program> => {
	if (!hasElements(programs)) {
		return []
	}
	return [...programs!].sort((programA, programB) => {
		return programA.name.localeCompare(programB.name)
	})
}

/**
 * Get Programs related to the Country
 */
async function load(projection?: ProgramProjection): Promise<Array<Program>> {
	const response = await axios.get<Array<Program>>(`${endPoint}`, { params: { projection } }).catch((error) => {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	})
	return sortPrograms(response.data)
}

/**
 * Get Redeemables related to the Country
 */
async function loadRedeemable(params: LoadRedeemableRequest): Promise<PagedResponse<Redeemable>> {
	return axios
		.get<PagedResponse<Redeemable>>(`${endPoint}/redeemables`, {
			params: {
				...params,
				programIds: params.programIds.join(),
				redeemableIds: params.redeemableIds ? params.redeemableIds.join() : undefined,
			},
		})
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${endPoint}`, error)
			throw error
		})
}

/**
 * Updates an existing program
 *
 * @param programId Program ID
 * @param redeemLimit New Program Redeem Limit
 */
async function update(programId: string, programPatchRequest: ProgramPatchRequest): Promise<Program> {
	const response = await axios.patch<Program>(`${endPoint}/${programId}`, programPatchRequest).catch((error) => {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	})

	return response.data
}

/**
 * Updates program combos
 *
 * @param programId Program ID
 * @param combos Program Combos set
 */
async function updateCombos(programId: string, combos: Array<ProgramComboRequest>): Promise<void> {
	const baseUpsertEndpoint = `${endPoint}/${programId}/combos`
	const promises = combos.map(({ comboId, ...body }) => axios.put<void>(`${baseUpsertEndpoint}/${comboId}`, body))

	try {
		await Promise.all(promises)
	} catch (error) {
		log.error(`Error endpoint: ${baseUpsertEndpoint}/comboId`, error)
		throw error
	}
}

async function bulkCombosUpdate(
	programId: string,
	programRedeemableRequest: ProgramRedeemableRequest,
): Promise<AxiosResponse<void>> {
	const endPointBulkUpdate = `/v1/loyalty-business-relay/programs/${programId}/redeemables`

	try {
		const result = await axios.post<void>(endPointBulkUpdate, programRedeemableRequest)
		return result
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

async function bulkCombosDelete(programId: string, redeemableIds: string[]): Promise<void> {
	const endPointBulkDelete = `/v1/loyalty-business-relay/programs/${programId}/redeemables:batch-delete`

	try {
		await axios.post<void>(endPointBulkDelete, { redeemableIds })
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

/**
 * Updates Program's Rule
 *
 * @param programId Program ID
 * @param ruleId Rule Id to be updated
 * @param rule Program Rule Request object
 */
async function updateRule(programId: string, ruleId: string, rule: ProgramRulePatchRequest): Promise<void> {
	const updateRuleEndpoint = `${endPoint}/${programId}/rules/${ruleId}`
	try {
		await axios.patch<void>(updateRuleEndpoint, rule)
	} catch (error) {
		log.error(`Error endpoint: ${updateRuleEndpoint}`, error)
		throw error
	}
}

/**
 * Put item in Program's Rule
 *
 * @param programId Program ID
 * @param ruleId Rule Id where it will be added
 * @param item Program Rule Put Item Request object
 * @param itemId Item ID
 */
async function putItemRule(
	programId: string,
	ruleId: string,
	itemId: string,
	item: ProgramRulePutItemRequest,
): Promise<void> {
	const putItemInRuleEndpoint = `${endPoint}/${programId}/rules/${ruleId}/items/${itemId}`
	try {
		await axios.put<void>(putItemInRuleEndpoint, item)
	} catch (error) {
		log.error(`Error endpoint: ${putItemInRuleEndpoint}`, error)
		throw error
	}
}

/**
 * Delete comboId related on ProgramId
 * @param programId	Program's Id
 * @param comboId		Combo's Id to be deleted
 */
async function deleteComboFromProgram(programId: string, comboId: string): Promise<void> {
	const endPointDelete = `${endPoint}/${programId}/combos/${comboId}`
	return axios
		.delete<void>(endPointDelete)
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${endPointDelete}`, error)
			throw error
		})
}

/**
 * Delete sku from Rule related to the Program
 * @param programId	Program's Id
 * @param ruleId		Rule's Id related to the Program
 * @param skuId			Sku's Id to be deleted from Rule
 */
async function deleteSkuFromRuleProgram(programId: string, ruleId: string, skuId: string): Promise<void> {
	const endPointDelete = `${endPoint}/${programId}/rules/${ruleId}/skus/${skuId}`
	return axios
		.delete<void>(endPointDelete)
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${endPointDelete}`, error)
			throw error
		})
}

/**
 * Delete item from Rule related to the Program
 * @param programId Program's Id
 * @param ruleId Rule's Id related to the Program
 * @param itemId Item's Id to be deleted from Rule
 */
async function deleteItemFromRuleProgram(programId: string, ruleId: string, itemId: string): Promise<void> {
	const endPointDelete = `${endPoint}/${programId}/rules/${ruleId}/items/${itemId}`
	return axios
		.delete<void>(endPointDelete)
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${endPointDelete}`, error)
			throw error
		})
}

export {
	load,
	update,
	updateCombos,
	deleteComboFromProgram,
	deleteSkuFromRuleProgram,
	deleteItemFromRuleProgram,
	updateRule,
	bulkCombosUpdate,
	bulkCombosDelete,
	loadRedeemable,
	putItemRule,
}
