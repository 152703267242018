import InputTextWithDelayedValidation from '@/components/form/InputTextWithDelayedValidation'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ChallengeDetailsFormData, ChallengeEvents, SkuBox } from '@/stores/challengeCreation'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
	index: number
	disabled?: boolean
	handleSkuValidation: (value: string, name: string) => Promise<boolean>
	defaultValue?: SkuBox | null
}

const SkuInputField: React.FC<Props> = ({ index, disabled, handleSkuValidation, defaultValue }: Props) => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const { formState } = useFormContext<ChallengeDetailsFormData>()
	const currentSku = formState.errors.skus ? formState.errors.skus[index] : undefined

	const onValidating = async (eventValue: string) => {
		await handleSkuValidation(eventValue, `skus.${index}.sku`)
	}

	useEffect(() => {
		if (currentSku && currentSku.sku?.type === 'required') {
			AnalyticsService.events.error({
				failure_reason: currentSku.sku?.message,
				form_name: 'Challenge Creation - 3rd Step',
				screen_name: 'Challenge Creation - 3rd Step',
				...userInfo,
			})
		}
	}, [currentSku, userInfo])

	return (
		<InputTextWithDelayedValidation
			id={`skuId${index}`}
			name={`skus.${index}.sku`}
			label={t('challenge:DETAILS_STEP.SKUS.ID_FIELD_LABEL')}
			disabled={disabled}
			onValidating={onValidating}
			defaultValue={defaultValue?.sku ?? ''}
			onLoading={ChallengeEvents.setIsValidatingSku}
			placeholder={t('challenge:DETAILS_STEP.CHALLENGE_GOAL.SKU_INPUT_PLACEHOLDER')}
			isNewDSM
		/>
	)
}

export default SkuInputField
