import { createStyles, makeStyles } from '@material-ui/core/styles'

const DeleteSkuIconStyle = makeStyles(() =>
	createStyles({
		deleteSkuIcon: {
			paddingRight: '64px',
			width: 'auto',

			'& svg': {
				padding: '4px',
				fill: '#000000',
			},
		},
	}),
)

export default DeleteSkuIconStyle
