import { useCallback } from 'react'
import useLoadRedeemablesAndCombos from '@/hooks/combo/useLoadRedeemablesAndCombos'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { ComboEvents, SettingsConfigurationEvents } from '@/stores'
import { LoadDefaultConfigurationUseCase, LoadProgramUseCase } from '@/usecase'
import {
	isToggleConfigByTier,
	isToggleMultivendorRedeemablesByVendorEnabled,
} from '@/utils/featureToggle/featureToggleHelper'

export interface LoadCombosSettingsResult {
	loadCombosSettings: () => Promise<void>
}

export const useLoadCombosSettings = (): LoadCombosSettingsResult => {
	const { loadRedeemablesAndCombos } = useLoadRedeemablesAndCombos()
	const { selectedTierKey } = useCurrentTier()
	const isSettingConfigByTier = isToggleConfigByTier()

	const loadProgramData = useCallback(async () => {
		const program = await LoadProgramUseCase.execute()
		if (!program) {
			throw new Error('No program!')
		}
		ComboEvents.setProgramDataForCombo(program)
		if (!isToggleMultivendorRedeemablesByVendorEnabled()) {
			await loadRedeemablesAndCombos(program.id)
		}
	}, [loadRedeemablesAndCombos])

	const loadDefaultConfiguration = useCallback(async () => {
		const settings = await LoadDefaultConfigurationUseCase.execute(selectedTierKey!)
		if (!settings) {
			throw new Error('No settings!')
		}
		SettingsConfigurationEvents.setSettingsData(settings)
		if (!isToggleMultivendorRedeemablesByVendorEnabled()) {
			await loadRedeemablesAndCombos(settings.settingId!)
		}
	}, [loadRedeemablesAndCombos, selectedTierKey])

	const loadData = useCallback(async (): Promise<void> => {
		try {
			ComboEvents.setCombosPageReady(false)
			if (isSettingConfigByTier) {
				await loadDefaultConfiguration()
			} else {
				await loadProgramData()
			}
		} catch {
			ComboEvents.showErrorInCombosData()
		} finally {
			ComboEvents.setCombosPageReady(true)
		}
	}, [loadProgramData, loadDefaultConfiguration, isSettingConfigByTier])

	return {
		loadCombosSettings: loadData,
	}
}
