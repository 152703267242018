import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			gap: '8px',
			alignItems: 'center',
			padding: 0,
			margin: 0,

			'& [data-testid="tooltip"]': {
				display: 'flex',
			},
		},
		label: {
			color: 'var(--colors-interfaceLabelPrimary)',

			'&.info': {
				color: 'var(--colors-interfaceLabelDisabled)',
			},
		},
		indicator: {
			width: '8px',
			height: 'auto',

			'&.info': {
				color: 'var(--colors-semanticInfoBasis)',
			},
			'&.success': {
				color: 'var(--colors-semanticSuccessBasis)',
			},
			'&.error': {
				color: 'var(--colors-semanticErrorBasis)',
			},
			'&.warning': {
				color: 'var(--colors-semanticWarningBasis)',
			},
			'&.disabled': {
				color: 'var(--colors-neutral40',
			},
		},
		infoIcon: {
			color: 'var(--colors-neutral50',
		},
	}),
)

export default styles
