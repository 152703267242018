import { SettingsLevel, SettingsType } from '@/domains/enums/settings'
import { OfferCategory } from '@/domains/offerCategory/offerCategory'
import { SettingsOfferCategoryConfig } from '@/domains/settings'
import { LoadDefaultConfigParams, loadSettings } from '@/services/settings/SettingsService'
import { DEFAULT_TIER_FOR_PPP } from '@/utils/constants'

export const execute = async (): Promise<OfferCategory[]> => {
	const params: LoadDefaultConfigParams = {
		levels: SettingsLevel.ZONE,
		settingIds: '',
		tiers: [DEFAULT_TIER_FOR_PPP],
		types: SettingsType.OFFER_CATEGORIES,
	}

	const response = await loadSettings<SettingsOfferCategoryConfig>(params)
	const result = response[0]?.offerCategories || []
	return result
}
