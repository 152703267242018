import * as ItemService from '@/services/item/ItemService'
import { Item } from '@/domains'
import agingGroupValidate from '@/utils/agingGroupValidate'
import VariantsType from '@/domains/Item/variantsType'

async function execute(sku: string, variantsType?: VariantsType): Promise<Item | null> {
	try {
		const data = await ItemService.loadAllItems({ vendorItemIds: [sku], variantsType })
		if (data?.items?.length) {
			const agingValidate = agingGroupValidate(data.items[0].agingGroup)
			if (!agingValidate) return null
		}
		return data.items[0]
	} catch (error) {
		return null
	}
}

async function executeList(skus: Array<string>, variantsType?: VariantsType): Promise<Array<Item | null>> {
	try {
		const data = await ItemService.loadAllItems({ vendorItemIds: skus, variantsType })
		if (data?.items?.length) {
			const skuValid = data.items.map((sku) => {
				const agingValidate = agingGroupValidate(sku.agingGroup)
				if (!agingValidate) return null
				return sku
			})
			return skuValid
		}
		return []
	} catch (error) {
		return []
	}
}

export { execute, executeList }
