enum AudienceType {
	SINGLE = 'SINGLE',
	MULTIPLE = 'MULTIPLE',
}

export const audienceList: { [key in AudienceType]: string } = {
	[AudienceType.SINGLE]: 'Single POC',
	[AudienceType.MULTIPLE]: 'Multiple POCs',
}

export const columns = [
	{
		Header: 'transaction:LOG:TRANSACTION_ID',
		accessor: 'id',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:TRANSACTION_TYPE',
		accessor: 'type',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:TARGET',
		accessor: 'totalAccounts',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:REQUESTED_ON',
		accessor: 'createdAt',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:STATUS',
		accessor: 'status',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:ACTIONS',
		accessor: 'actions',
		disableSortBy: true,
	},
]
