import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FileMultiUploader, TextLink, Alert } from '@hexa-ui/components'
import { Field, useFormikContext } from 'formik'
import * as Papa from 'papaparse'
import { MAX_SIZE_FILE_5MB } from '@/utils/constants'
import { ValidationError } from 'yup'
import { fileValidationCsv } from '@/utils/validationObjects'
import { CSVUploaderWrapper, DownloadIcon, DownloadLinkWrapper } from './CsvUploader.styles'
import { handleCsvParseResults } from './CsvUploaderUtils'

type PointsRemovalFormData = {
	accounts: string
}

export const CsvUploader = () => {
	const { t } = useTranslation()
	const [fileUploaded, setFileUploaded] = useState<boolean>(false)
	const [customError, setCustomError] = useState<string | null>(null)
	const DEFAULT_ERROR_MESSAGE = t('ERROR_MESSAGE.UNSUPPORTED_CSV_POINTS_REMOVAL_MULTIPLE')

	const { setFieldValue, setFieldError, setFieldTouched } = useFormikContext<PointsRemovalFormData>()

	const fileValidationSchema = fileValidationCsv(
		{
			required: t('ERROR_MESSAGE.UNSUPPORTED_CSV_POINTS_REMOVAL_MULTIPLE'),
			fileSize: t('ERROR_MESSAGE.MAX_SIZE_5MB'),
			fileType: t('ERROR_MESSAGE.UNSUPPORTED_CSV_POINTS_REMOVAL_MULTIPLE'),
		},
		MAX_SIZE_FILE_5MB,
	)

	const validateFile = (files: File[]) => {
		try {
			fileValidationSchema.validateSync(files)
			setFieldError('accounts', '')
			return true
		} catch (e) {
			const error = e as ValidationError

			setFieldErrorFormState(error.errors[0])
			return false
		}
	}

	const setFieldErrorFormState = (message: string) => {
		setFieldError('accounts', message)
		setCustomError(message)
	}

	const handleComplete = (results: Papa.ParseResult<unknown>, fileName: string) => {
		const isValid = handleCsvParseResults(results)

		if (!isValid) {
			setFieldErrorFormState(DEFAULT_ERROR_MESSAGE)
			return
		}

		setFieldValue('accounts', results.data, true)
		setFieldValue('fileName', fileName, true)
		setFieldTouched('accounts', true, true)
		setCustomError(null)
	}

	const handleCustomCsvValidator = (file: File) => {
		Papa.parse(file, {
			worker: false,
			header: true,
			skipEmptyLines: true,
			delimiter: ';',
			complete: (results) => handleComplete(results, file.name),
		})
	}

	const downloadCSVTemplate = () => {
		const csvContent = 'poc_id;points'
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
		const url = URL.createObjectURL(blob)
		const link = document.createElement('a')
		link.setAttribute('href', url)
		link.setAttribute('download', 'template_rewards.csv')
		link.style.visibility = 'hidden'
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	const handleCustomValidator = (file: File) => {
		if (file) {
			const nameProcessed = file.type.split('/')
			const fileExtension = `.${nameProcessed[nameProcessed.length - 1]}`
			const fileExtensionAllowed = ['.csv'].includes(fileExtension)
			if (!fileExtensionAllowed) {
				setFieldErrorFormState(DEFAULT_ERROR_MESSAGE)
				return []
			}

			const isValid = validateFile([file])
			if (!isValid) {
				return []
			}

			handleCustomCsvValidator(file)
		}
		return []
	}

	const handleOnClean = () => {
		setFieldValue('accounts', undefined, true)
		setFieldErrorFormState(DEFAULT_ERROR_MESSAGE)
	}

	return (
		<Field name="accounts">
			{(): ReactElement => {
				return (
					<CSVUploaderWrapper>
						<DownloadLinkWrapper data-testid="download-template">
							<DownloadIcon size="medium" />
							<TextLink colorOption="blue" hasUnderline={false} size="small" onClick={downloadCSVTemplate}>
								{t('transaction:POINTS_REMOVAL.CSV_DOWNLOAD_TEMPLATE')}
							</TextLink>
						</DownloadLinkWrapper>
						{fileUploaded && customError && (
							<Alert type="error" message={customError} style={{ width: '100%', marginBottom: 16 }} />
						)}
						<FileMultiUploader
							accept=".csv"
							inputId="multiple-files"
							maxFileSize={50000000}
							feedbackMessage="FEEDBACK_MESSAGE"
							feedbackTimeout={5000}
							hasPreviewButton={false}
							message={t('transaction:POINTS_REMOVAL.CSV_MESSAGE')}
							inputLabel={t('transaction:POINTS_REMOVAL.CSV_LABEL')}
							onClean={handleOnClean}
							title={t('transaction:POINTS_REMOVAL.CSV_TITLE')}
							onChange={() => setTimeout(() => setFileUploaded(true), 100)}
							customFileValidator={handleCustomValidator}
						/>
					</CSVUploaderWrapper>
				)
			}}
		</Field>
	)
}
