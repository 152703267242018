import useAdminAuditLog, { IAuditEntities, IAuditOperations } from '@/hooks/useAdminAuditLog'
import { useCallback } from 'react'

const useSendAuditLogRewardsPermission = (): {
	sendAuditLogRewardsPermission: (pocId: string, eligibility: boolean) => void
} => {
	const { auditLogInstance } = useAdminAuditLog()

	const sendAuditLogRewardsPermission = useCallback(
		(pocId: string, eligibility: boolean) => {
			auditLogInstance({
				metadata: {
					eligibility: eligibility ? 'unblocked' : 'blocked',
				},
				entity: IAuditEntities.ELIGIBILITY,
				entityId: pocId,
				operation: IAuditOperations.UPDATE,
			})
		},
		[auditLogInstance],
	)

	return { sendAuditLogRewardsPermission }
}

export default useSendAuditLogRewardsPermission
