import { ComboDetailed, OrderWithCombo, Transaction } from '@/domains'
import { ValidationError } from '@/domains/ValidationError'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import ComboRefundStatus from 'domains/enums/combo/ComboRefundStatus'
import { load as getOrder } from 'services/order/OrderService'
import { load as getTransaction } from 'services/transaction/TransactionService'
import * as LoadComboUseCase from '../combo/LoadComboUseCase'

export enum ValidationErrorType {
	invalidOrderIdMessage = 'ERROR_MESSAGE.INVALID_ORDER_ID',
	noRefundableFoundMessage = 'ERROR_MESSAGE.NO_REFUNDABLE_FOUND',
}

const mapRefundedQtyByComboId = (transactions: Transaction[]): Map<string, number> => {
	return transactions.reduce((map, transaction) => {
		if (transaction.transactionType === 'REFUND' && transaction.combos) {
			transaction.combos.forEach(({ comboId, quantity }) => {
				const partialQty = map.get(comboId) ?? 0
				map.set(comboId, partialQty + quantity)
			})
		}
		return map
	}, new Map<string, number>())
}

const getComboInfo = (
	comboId: string,
	mapCombos: Map<string, { title: string; vendorComboId: string }>,
): { name: string; vendorComboId: string } => {
	const combo = mapCombos.get(comboId)
	if (combo) return { name: combo.title, vendorComboId: combo.vendorComboId }

	return { name: '', vendorComboId: '' }
}

const fillCombosName = async (combos: ComboDetailed[]): Promise<ComboDetailed[]> => {
	const fullCombos = await LoadComboUseCase.executeByComboIds(combos.map((c) => c.comboId))
	const comboMap = new Map<string, { title: string; vendorComboId: string }>(
		fullCombos.map((c) => [c.id, { title: c.title, vendorComboId: c.vendorComboId ?? '' }]),
	)

	return combos.map((combo) => ({ ...combo, ...getComboInfo(combo.comboId, comboMap) }))
}

const getStatus = (refundedQty: number, quantityAllowed: number): ComboRefundStatus | undefined => {
	if (refundedQty) {
		return quantityAllowed <= 0 ? ComboRefundStatus.FullyRefunded : ComboRefundStatus.PartialRefunded
	}
	return undefined
}

async function execute(orderId: string): Promise<OrderWithCombo> {
	const vendor = getDefaultVendorIdOfCurrentZone()
	const order = await getOrder(orderId, vendor)

	if (!order) {
		throw new ValidationError(ValidationErrorType.invalidOrderIdMessage)
	}

	const transactions = await getTransaction(order.accountId, ['REDEMPTION', 'REFUND'], orderId)
	const redemption = transactions.find((transaction) => transaction.transactionType === 'REDEMPTION')

	let combos: ComboDetailed[] = []
	if (redemption?.combos) {
		const refundedQtyByComboId = mapRefundedQtyByComboId(transactions)

		redemption.combos.forEach((combo) => {
			if (!combo.points) {
				throw new ValidationError(ValidationErrorType.noRefundableFoundMessage)
			}

			const refundedQty = refundedQtyByComboId.get(combo.comboId) ?? 0
			const quantityAllowed = combo.quantity - refundedQty

			combos.push({
				...combo,
				quantity: quantityAllowed,
				maxQuantity: combo.quantity,
				status: getStatus(refundedQty, quantityAllowed),
				name: '',
				vendorComboId: '',
			})
		})
	}

	if (!combos.length) {
		throw new ValidationError(ValidationErrorType.noRefundableFoundMessage)
	}

	combos = await fillCombosName(combos)

	return {
		orderId,
		order,
		accountId: order.accountId,
		combos,
	}
}

export { execute }
