import { IStep } from '@/domains/step'
import React from 'react'
import Step from './Step'
import useStyle from './styles'

type Props = {
	steps: Array<IStep>
	activeStepIndex: number
}

const Stepper: React.FC<Props> = ({ steps, activeStepIndex }) => {
	const classes = useStyle()
	const activeStep = steps[activeStepIndex]

	if (!activeStep) {
		return null
	}
	return (
		<div className={classes.root}>
			<div className={classes.steps} style={{ width: '700px' }}>
				{steps.map((stepData, index) => (
					<Step
						key={stepData.key}
						stepData={stepData}
						stepIndex={index}
						activeStepIndex={activeStepIndex}
						maxSteps={steps.length}
					/>
				))}
			</div>
			<div className={classes.content}>{activeStep.render()}</div>
		</div>
	)
}

export default Stepper
