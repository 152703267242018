import challenge from './translations/challenge.json'
import combo from './translations/combo.json'
import redeemable from './translations/redeemable.json'
import common from './translations/common.json'
import rules from './translations/rules.json'
import transaction from './translations/transaction.json'
import history from './translations/history.json'
import program from './translations/program.json'
import permission from './translations/permission.json'
import settings from './translations/settings.json'

const resources = {
	common,
	challenge,
	combo,
	redeemable,
	rules,
	transaction,
	history,
	program,
	permission,
	settings,
} as const

export default resources
