import Program from 'domains/program/Program'
import { ComboFormType } from '@/domains/enums'
import { Redeemable, RedeemableRequest } from '@/domains'
import { RedeemablesMap } from '@/domains/redeemable/Redeemable'
import { RedeemableSingleRequest } from '@/domains/redeemable/RedeemableRequest'

export interface ProgramCombo {
	comboId: string
	points: number | string
}

export interface ComboFormData<T = ProgramCombo> {
	combos?: T[]
}

export interface RedeemableEditPoints {
	redeemableId: string
	points: number
}
export interface RedeemableFormData {
	redeemables: RedeemableRequest[]
}
export interface RedeemableSingleFormData {
	redeemables: RedeemableSingleRequest[]
}

interface ComboFileValidation {
	isValidating: boolean
	errorMessage?: string | null
}

export interface CombosData {
	redeemablesMap: RedeemablesMap
	showError: boolean
}

export interface ComboFormDataMultiple {
	fileName?: string
	formData: RedeemableFormData
}

export interface ComboFormDataSingle {
	fileName?: string
	formData: RedeemableSingleFormData
}

export interface ComboState {
	formData: ComboFormData | null
	combosData: CombosData
	programData: Program | null
	showEditComboDialog: boolean
	showSubmitDialog: boolean
	showRemoveComboDialog: boolean
	showEditComboQuantityDialog: boolean
	activeCombo?: ProgramCombo
	activeRedeemable?: Redeemable[]
	rowIndexToDelete?: number[]
	isValidatingComboId: boolean
	formType?: ComboFormType
	fileValidation: ComboFileValidation
	formDataMultiple: ComboFormDataMultiple | null
	selectedTransparencyId: string | null
	isLoading: boolean
	isCombosPageReady: boolean
}

export const comboInitialState: ComboState = {
	programData: null,
	showEditComboDialog: false,
	showRemoveComboDialog: false,
	showEditComboQuantityDialog: false,
	rowIndexToDelete: undefined,
	activeCombo: undefined,
	activeRedeemable: undefined,
	isValidatingComboId: false,
	formType: undefined,
	showSubmitDialog: false,
	formData: null,
	combosData: {
		redeemablesMap: {},
		showError: false,
	},
	fileValidation: {
		isValidating: false,
		errorMessage: null,
	},
	formDataMultiple: null,
	selectedTransparencyId: null,
	isLoading: false,
	isCombosPageReady: false,
}

export default ComboState
