import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DEFAULT_DATE_LOCALE_CODE } from './constants'

dayjs.extend(utc)

/**
 * Format date to String
 * @param date [Date]: Date to be formatted
 * @param t [Function]: i18n
 */
export const formatDate = (date: Date, format: string): string => {
	return dayjs(date).utc().locale(DEFAULT_DATE_LOCALE_CODE).format(format)
}

export const formatToISODate = (date: string): string => {
	const dateObject = new Date(date)
	if (!Number.isNaN(dateObject.getTime())) {
		return new Date(dateObject.getTime()).toISOString()
	}
	return date
}

export const formatToDateAndForceUTC0 = (date: Date | string): Date => dayjs(date).utc(true).toDate()

export const startOfDay = (date: Date | string): Date => dayjs(date).startOf('day').toDate()

export const endOfDay = (date: Date | string): Date => dayjs(date).endOf('day').toDate()

export const isSameDateAndTime = (dateA: Date | string, dateB: Date | string): boolean =>
	dayjs(dateA).isSame(dateB, 'minute')

/**
 * Given a date set the time to 00:00:00.000000Z
 * @param date
 * @returns
 */
export const formatStartDate = (date?: Date): Date | undefined => {
	return date ? new Date(`${date.toISOString().split('T').shift()}T00:00:00.000000Z`) : undefined
}

/**
 * Given a date set the time to 23:59:59.999999Z
 * @param date
 * @returns
 */
export const formatEndDate = (date?: Date): Date | undefined => {
	return date ? new Date(`${date.toISOString().split('T').shift()}T23:59:59.999999Z`) : undefined
}
