import React from 'react'
import MultipleComboView from './MultipleComboView'

type MultipleComboProps = {
	onSelectedFile: (components: JSX.Element[] | null) => void
}

const MultipleCombo: React.FC<MultipleComboProps> = ({ onSelectedFile }) => {
	return <MultipleComboView onSelectedFile={onSelectedFile} />
}
export default MultipleCombo
