import { AccountGroup, Group } from '@/domains'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ChallengeEvents } from '@/stores/challengeCreation'
import { UpsertAccountGroup } from '@/usecase'
import { useCallback } from 'react'

export interface CreateAccountGroupResult {
	createAccountGroup: (accountGroupData: AccountGroup) => Promise<Group>
}

export interface CreateAccountGroupParams {
	individualTarget: boolean
	challengeId: string | undefined
}

export const useCreateAccountGroup = ({
	individualTarget,
	challengeId,
}: CreateAccountGroupParams): CreateAccountGroupResult => {
	const userInfo = useGetUserInfo()
	const upsertData = useCallback(
		(accountGroupData: AccountGroup) => {
			return UpsertAccountGroup.executeByTarget({
				...accountGroupData,
				individualTarget,
				challengeId,
			})
		},
		[individualTarget, challengeId],
	)

	const createAccountGroup = useCallback(
		async (accountGroupData: AccountGroup): Promise<Group> => {
			const response = await upsertData(accountGroupData)
			if (!response.success && response.error) {
				AnalyticsService.events.error({
					screen_name: 'Add Group Modal',
					failure_reason: `Service response: ${response.status} - ${response.error?.message}`,
					form_name: 'Challenge Creation - Step 2 - Filter',
					...userInfo,
				})
				throw response
			}
			if (response.success && response?.data?.groupId) {
				const { groupId } = response.data
				ChallengeEvents.setChallengeGroupName(accountGroupData.groupName)
				return {
					id: groupId,
					name: accountGroupData.groupName,
					description: accountGroupData.groupDescription ?? '',
				}
			}
			throw new Error('No groupId')
		},
		[upsertData, userInfo],
	)

	return {
		createAccountGroup,
	}
}
