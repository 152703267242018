import { ButtonInteraction, ModalName, ScreenName } from '@/domains/analytics'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { TransactionStore, TransactionEvents } from '@/stores'
import { TransparencyTransaction } from '@/domains'
import * as FileHelper from 'utils/fileHelper'
import * as DateHelper from 'utils/date'
import { useStoreMap } from 'effector-react'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import FailedPocsDialogView from './FailedPocsDialogView'

dayjs.extend(utc)

const FailedPocsDialog: React.FC = () => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const [data, setData] = React.useState<TransparencyTransaction[]>([])
	const activityLogDialog = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => state.activityLogDialog,
	})

	const extraColumns = ['accountId', 'campaignId', 'points', 'error']
	const headers = ['POC_ID', 'CAMPAIGN_ID', 'POINTS', 'ERROR']
	const handleDownload = () => {
		AnalyticsService.events.buttonClicked({
			button_label: null,
			button_name: ButtonInteraction.DownloadCsv,
			screen_name: ScreenName.ReviewIdsModal,
			...userInfo,
		})
		const fileCsvString = FileHelper.unParse(data, extraColumns, headers)
		const fileDate = dayjs().utcOffset(0, true).toDate()
		const dateNowString = DateHelper.formatDate(fileDate, t('FORMAT_DATE.LONG_FILE'))
		FileHelper.saveAs(fileCsvString, `${activityLogDialog.id}-${dateNowString}.csv`, { type: FileHelper.TEXT_CSV_TYPE })
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleCancel = (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => {
		if (reason) {
			AnalyticsService.events.modalExited({
				modal_name: ModalName.ReviewIds,
				screen_name: ScreenName.TransactionLog,
			})
		} else {
			AnalyticsService.events.buttonClicked({
				button_label: null,
				button_name: ButtonInteraction.Close,
				screen_name: ScreenName.ReviewIdsModal,
				...userInfo,
			})
		}
		TransactionEvents.hideActivityLogDialog()
	}

	const getFailedPocsErrorMessage = useCallback(
		(failedPocs: TransparencyTransaction[]): TransparencyTransaction[] => {
			return failedPocs.map((p) => {
				const convertedPoints = p.points ? parseInt(p.points, 10) : null
				const isNotNull = convertedPoints !== null
				switch (p.errorType) {
					case 400:
						if (isNotNull && convertedPoints === 0) {
							p.error = t('transaction:REWARDS_OFFER.ACTIVITY_LOG.ERROR.ZERO_POINTS')
						} else if (isNotNull && convertedPoints <= 0) {
							p.error = t('transaction:REWARDS_OFFER.ACTIVITY_LOG.ERROR.NEGATIVE_POINTS')
						} else if (isNotNull && parseFloat(p.points)) {
							p.error = t('transaction:REWARDS_OFFER.ACTIVITY_LOG.ERROR.COMMAS_OR_DOTS_POINTS')
						} else p.error = t('transaction:REWARDS_OFFER.ACTIVITY_LOG.ERROR.DEFAULT')
						break
					case 404:
						p.error = t('transaction:REWARDS_OFFER.ACTIVITY_LOG.ERROR.INVALID_POC')
						break
					default:
						p.error = t('transaction:REWARDS_OFFER.ACTIVITY_LOG.ERROR.UNDEFINED')
				}
				return p
			})
		},
		[t],
	)

	useEffect(() => {
		const failedPocs = getFailedPocsErrorMessage(activityLogDialog.failedPocs)
		setData(failedPocs)
	}, [activityLogDialog, getFailedPocsErrorMessage])

	return (
		<FailedPocsDialogView
			show={activityLogDialog.show}
			failedPocs={data}
			description={activityLogDialog.description}
			handleDownload={handleDownload}
			handleCancel={handleCancel}
		/>
	)
}

export default FailedPocsDialog
