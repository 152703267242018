import { makeStyles, createStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		header: {
			marginTop: '16px',
			marginBottom: '43px',
		},
		buttonsBar: {
			marginTop: '16px',
			display: 'flex',
			justifyContent: 'flex-end',
		},
	}),
)

export default styles
