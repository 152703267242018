import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles({
	subContainer: {
		padding: 0,
	},
	descriptionContainer: {
		paddingTop: '32px',
	},
	label: {
		boxShadow: 'inset 0 -1px 0 0 #DFDEDE',
		padding: '8px 0',
	},
	quantityLabel: {
		maxWidth: '72px',
	},
})

export default styles
