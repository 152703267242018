import {
	PointsRemovalTransaction as PointsRemoval,
	PointsRemovalTransactionResponse as PointsRemovalResponse,
	PointsTransferTransaction as PointsTransfer,
	PointsTransferTransactionResponse as PointsTransferResponse,
	RefundTransaction,
	RemovalForServiceUtilityTransaction as RemovalForServiceUtility,
	RewardsOffer,
	RewardsOfferMultiple,
	RewardsOfferTransaction,
	Transaction,
	TransactionResponse,
} from '@/domains'
import { TransactionContent, TransactionType, TransactionsAdminService } from '@/domains/ITransaction'
import AddPointsTransaction from '@/domains/transaction/AddPointsTransaction'
import RemovalForServiceUtilityResponse from '@/domains/transaction/RemovalForServiceUtilityResponse'
import axios from '@/utils/axiosInstance'
import { API_URL } from '@/utils/constants'
import { useLogService as logService } from 'admin-portal-shared-services'
import { endPointBase, endPointsRewardsAdminService, endPointsRewardsAdminServiceV2 } from './Endpoints'

const log = logService()

async function load(
	accountId: string,
	type: TransactionType[],
	orderId?: string,
	pagination?: { page?: number; pageSize?: number },
): Promise<Transaction[]> {
	const typeQuery = type.map((t) => `type=${t}`).join('&')
	const config = orderId || pagination ? { params: { orderId, ...pagination } } : undefined
	let endPoint = `${endPointBase}/${accountId}/transactions`

	if (typeQuery) {
		endPoint = `${endPoint}?${typeQuery}`
	}

	try {
		const response = await axios.get<Array<Transaction>>(endPoint, config)
		return response.data
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.error(`Error endpoint: ${endPoint}`, error)

		if (error?.response?.status === 404) {
			return []
		}

		throw error
	}
}

async function refund({
	accountId,
	refundTransaction,
	isToggleRefundRedeemableItemsEnabled,
}: {
	accountId: string
	refundTransaction: RefundTransaction
	isToggleRefundRedeemableItemsEnabled: boolean
}): Promise<TransactionResponse> {
	const accountIdFormated = accountId.trim()

	const endPoint = isToggleRefundRedeemableItemsEnabled
		? API_URL.POST_REFUND.replace(':accountId', accountIdFormated)
		: `${endPointBase}/${accountIdFormated}/transaction/refund`

	try {
		const response = await axios.post<TransactionResponse>(endPoint, refundTransaction)
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

async function rewardsOffers(
	accountId: string,
	rewardsOfferTransaction: RewardsOfferTransaction,
): Promise<TransactionResponse> {
	const accountIdFormated = accountId.trim()
	const endPoint = `${endPointBase}/${accountIdFormated}/transaction/rewards-offer`

	try {
		const response = await axios.post<TransactionResponse>(endPoint, rewardsOfferTransaction)

		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

async function rewardsOffersMultiple(
	transparencyId: string,
	rewardsOfferMultipleTransaction: RewardsOfferMultiple,
): Promise<string> {
	const endPoint = `${endPointsRewardsAdminService}transactions/rewards-offer`
	try {
		await axios.post<RewardsOffer>(endPoint, rewardsOfferMultipleTransaction)
		return transparencyId
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

async function pointsTransfer(
	fromAccountId: string,
	toAccountId: string,
	description?: string,
): Promise<PointsTransferResponse[]> {
	const pointsTransferBody = {
		fromAccountId,
		toAccountId,
		description: description ?? undefined,
	} as PointsTransfer
	const endPoint = `${endPointBase}/transaction/points-transfer`

	try {
		const response = await axios.post<PointsTransferResponse[]>(endPoint, pointsTransferBody)
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

async function pointsRemoval(accountId: string, requestBody: PointsRemoval): Promise<PointsRemovalResponse> {
	const accountIdFormated = accountId.trim()
	const endPoint = `${endPointBase}/${accountIdFormated}/transaction/points-removal`
	try {
		const response = await axios.post<PointsRemovalResponse>(endPoint, requestBody)
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

async function removalForServiceUtility(
	accountId: string,
	requestBody: RemovalForServiceUtility,
): Promise<RemovalForServiceUtilityResponse> {
	const accountIdFormated = accountId.trim()
	const endPoint = `${endPointBase}/${accountIdFormated}/transaction/service-utility`
	try {
		const response = await axios.post<RemovalForServiceUtilityResponse>(endPoint, requestBody)
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

async function getTransactions(
	accountId: string,
	orderId?: string,
	pagination?: { page?: number; pageSize?: number },
): Promise<TransactionContent[]> {
	const config = { params: { orderId, ...pagination } }
	const endPointRewardsAdminService = `${endPointsRewardsAdminService}transactions/${accountId}`

	try {
		const response = await axios.get<TransactionsAdminService>(endPointRewardsAdminService, config)
		return response.data.content
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.error(`Error endpoint: ${endPointRewardsAdminService}`, error)

		if (error?.response?.status === 404) {
			return []
		}

		throw error
	}
}

async function addPoints(addPointsTransaction: AddPointsTransaction, fileName?: string): Promise<any> {
	const endPoint = `${endPointsRewardsAdminServiceV2}transactions/rewards-offer`
	try {
		const response = await axios.post<any>(endPoint, { ...addPointsTransaction, fileName })
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

export {
	addPoints,
	getTransactions,
	load,
	pointsRemoval,
	pointsTransfer,
	refund,
	removalForServiceUtility,
	rewardsOffers,
	rewardsOffersMultiple,
}
