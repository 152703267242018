import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		container: {
			width: 'fit-content',
			display: 'flex',
			gap: '8px',
			alignItems: 'center',
			padding: '4px 12px',
			margin: 0,

			border: 'var(--borderWidths-hairline) solid',
			borderRadius: 'var(--radii-full)',

			'&.success': {
				borderColor: 'var(--colors-semanticSuccessBasis)',
			},

			'& [data-testid="tooltip"]': {
				display: 'flex',
			},
		},
		label: {
			'&.success': {
				color: 'var(--colors-semanticSuccessText)',
			},
		},
		indicator: {
			width: '4px',
			height: 'auto',

			display: 'flex',
			alignItems: 'center',

			'&.success': {
				color: 'var(--colors-semanticSuccessText)',
			},
		},
	}),
)

export default styles
