import React from 'react'
import { useTranslation } from 'react-i18next'
import { ChallengeDetailsFormData, ChallengeEvents } from '@/stores/challengeCreation'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, FormLabel } from '@material-ui/core'
import HexaRadioGroup from '@/components/hexa-form/RadioGroup/HexaRadioGroup'

import { ChallengeExecutionMethod } from '@/domains/enums'
import useChallengeTrackingPlan from '@/hooks/tracking/useChallengeTrackingPlan'
import ChallengeGoalAmount from './ChallengeGoalAmount/ChallengeGoalAmount'
import styles from '../ChallengeFormDetailsStyle'

const ChallengeFormDetailsChallengeGoal: React.FC = () => {
	const { t } = useTranslation()
	const classes = styles()
	const { control, getValues, setValue, trigger, clearErrors } = useFormContext<ChallengeDetailsFormData>()
	const selectedAmount = getValues()?.challengeGoal
	const { triggerChallengeGoal } = useChallengeTrackingPlan()

	const radioItems = [
		{
			id: 'Amount-of-units',
			label: t('challenge:DETAILS_STEP.CHALLENGE_GOAL.AMOUNT_OF_UNITS'),
			sublabel: t('challenge:DETAILS_STEP.CHALLENGE_GOAL.AMOUNT_OF_UNITS_SUBLABEL'),
			value: 'AmountOfUnits',
			checked: selectedAmount === 'AmountOfUnits',
		},
		{
			id: 'Amount-of-money',
			label: t('challenge:DETAILS_STEP.CHALLENGE_GOAL.AMOUNT_OF_MONEY'),
			sublabel: t('challenge:DETAILS_STEP.CHALLENGE_GOAL.AMOUNT_OF_MONEY_SUBLABEL'),
			value: 'AmountOfMoney',
			checked: selectedAmount === 'AmountOfMoney',
		},
	]

	const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const isAmountOfMoney = event.target.value === 'AmountOfMoney'
		if (isAmountOfMoney) {
			ChallengeEvents.setExecutionMethod(ChallengeExecutionMethod.SpendBased)
			setValue('quantity', undefined)
			clearErrors('quantity')
		} else {
			ChallengeEvents.setExecutionMethod(ChallengeExecutionMethod.VolumeFixed)
			setValue('currencyMin', undefined)
			clearErrors('currencyMin')
		}
		setValue('challengeGoal', event.target.value)
		triggerChallengeGoal(event.target.value)
		await trigger('challengeGoal')
	}

	return (
		<Controller
			control={control}
			name="challengeGoal"
			render={() => (
				<FormControl style={{ marginTop: '24px', width: '100%' }}>
					<FormLabel id="challange-radio-buttons-group-label">
						<div className={classes.label}>{t('challenge:DETAILS_STEP.CHALLENGE_GOAL.TITLE')}</div>
					</FormLabel>
					<HexaRadioGroup radioItems={radioItems} handleRadioChange={handleRadioChange} />
					{!!selectedAmount && <ChallengeGoalAmount selectedAmount={selectedAmount} />}
				</FormControl>
			)}
		/>
	)
}

export default ChallengeFormDetailsChallengeGoal
