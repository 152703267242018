import { PagedResponse } from '@/domains'
import Redeemable, { RedeemableV2 } from '@/domains/redeemable/Redeemable'

export const convertToRedeemables = <T>(content: Array<RedeemableV2<T>>): Array<Redeemable<T>> => {
	return content.map((item: RedeemableV2<T>) => {
		const itemCopy: Partial<RedeemableV2<T>> = { ...item }
		delete itemCopy.id
		delete itemCopy.vendorId
		delete itemCopy.title
		return {
			...itemCopy,
			redeemableId: item.id,
			name: item.name ?? item.title,
		} as Redeemable<T>
	})
}

export const addAttributeName = <T>(data: PagedResponse<RedeemableV2<T>>): PagedResponse<RedeemableV2<T>> => {
	return {
		...data,
		content: data.content.map((item) => ({
			...item,
			name: item.name ?? item.title,
		})),
	}
}
