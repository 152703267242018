import RewardsOfferTransaction, { RewardsOfferTransactionV2 } from '@/domains/transaction/RewardsOfferTransaction'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { FormikValues } from 'formik'

export const transactionFormValuesToRewardsOffer = (values: FormikValues): RewardsOfferTransaction => {
	return {
		points: values.points,
		campaignId: values.campaignId,
		description: values.description,
		category: values.category,
		referenceMonth: values.referenceMonth,
		referenceYear: values.referenceYear,
		singlePOC: values.singlePOC,
		productCategory: Array.isArray(values.productCategory) ? values.productCategory : [values.productCategory],
	} as RewardsOfferTransaction
}

export const transactionFormValuesToRewardsOfferV2 = (values: FormikValues): RewardsOfferTransactionV2 => {
	const transformedItems =
		values.skus?.map(
			(item: {
				icon: string
				description: string
				sub_description: string
				value: string
				goal_input_id: string
				vendorId: string
			}) => ({
				brand: item?.sub_description ?? '',
				name: item?.description ?? '',
				imageUrl: item?.icon ?? '',
				sku: item?.value ?? '',
				goalInputId: item?.goal_input_id ?? '',
				vendorId: item?.vendorId ?? '',
			}),
		) || []

	return {
		description: values.description ?? '',
		category: values.category ?? '',
		referenceMonth: values.referenceMonth ?? '',
		referenceYear: values.referenceYear ?? '',
		singlePOC: values.singlePOC ?? false,
		vendorId: getDefaultVendorIdOfCurrentZone(),
		productCategory: Array.isArray(values.productCategory) ? values.productCategory : [values.productCategory ?? ''],
		accounts: [
			{
				vendorAccountId: values.vendorAccountId ?? '',
				points: values.points !== null && values.points !== undefined ? String(values.points) : '0',
				campaignId: values.campaignId ?? '',
			},
		],
		items: transformedItems.length > 0 ? transformedItems : undefined,
	} as unknown as RewardsOfferTransactionV2
}

export default transactionFormValuesToRewardsOffer
