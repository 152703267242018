import React, { RefObject, MutableRefObject } from 'react'
import { TableBodyRef } from '../TableBody'
import { TableHeaderCheckboxRef } from '../TableHeaderCheckbox'
import { TableRowSelectionActionsRef } from '../TableRowSelectionActions'

interface RowSelectionHook {
	tableBodyRef: RefObject<TableBodyRef>
	headerCheckboxRef: MutableRefObject<TableHeaderCheckboxRef | null>
	actionsRef: RefObject<TableRowSelectionActionsRef>
	clearSelection: () => void
	toggleRow: () => void
	toggleHeader: () => void
	getSelectedRows: () => number[]
}

const useRowSelection = (): RowSelectionHook => {
	const tableBodyRef = React.useRef<TableBodyRef>(null)
	const headerCheckboxRef = React.useRef<TableHeaderCheckboxRef | null>(null)
	const actionsRef = React.useRef<TableRowSelectionActionsRef>(null)

	const updateHeaderCheckbox = () => {
		const rowsCount = tableBodyRef.current!.getRowsCount()
		const selectedCount = tableBodyRef.current!.getSelectedRows().length
		if (selectedCount === 0) {
			headerCheckboxRef.current!.setChecked(false)
		} else if (selectedCount === rowsCount) {
			headerCheckboxRef.current!.setChecked(true)
		} else {
			headerCheckboxRef.current!.setIndeterminate(true)
		}
	}

	const clearSelection = () => {
		tableBodyRef.current!.clearSelection()
		headerCheckboxRef.current!.setIndeterminate(false)
		actionsRef.current!.updateCounter(0)
	}

	const toggleRow = () => {
		const selectedCount = tableBodyRef.current!.getSelectedRows().length
		actionsRef.current!.updateCounter(selectedCount)
		updateHeaderCheckbox()
	}

	const toggleHeader = () => {
		const rowsCount = tableBodyRef.current!.getRowsCount()
		const selectedCount = tableBodyRef.current!.getSelectedRows().length
		if (selectedCount >= 1 && selectedCount < rowsCount) {
			tableBodyRef.current!.clearSelection()
			actionsRef.current!.updateCounter(0)
		} else {
			tableBodyRef.current!.toggleAll()
			actionsRef.current!.updateCounter(selectedCount === 0 ? rowsCount : 0)
		}
	}

	const getSelectedRows = () => {
		return tableBodyRef.current!.getSelectedRows()
	}

	return {
		tableBodyRef,
		headerCheckboxRef,
		actionsRef,
		clearSelection,
		toggleRow,
		toggleHeader,
		getSelectedRows,
	}
}

export default useRowSelection
