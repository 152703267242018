import { useFeatureToggleService as featureToggleService } from 'admin-portal-shared-services'
import * as FeatureToggle from 'utils/featureToggle'

export function getApiHost(): string | undefined {
	return ''
}

export function getMockApiHost(): string {
	return 'https://b2b-webapp-mobile-gateway-gb-dev.azurewebsites.net'
}

export function setPortalConfig(): void {
	localStorage.setItem(
		'portal-config',
		JSON.stringify({
			ENV: 'dev',
		}),
	)
}

export function setTogglesMock(toggles: Record<string, boolean | string> = {}): void {
	const getParams = (featureToggle: string): [string, boolean | string] => [
		featureToggle,
		toggles[featureToggle] ?? true,
	]
	const ftService = featureToggleService()
	ftService.mockFeatureToggle(FeatureToggle.CHALLENGES_MODULE, true)
	ftService.mockFeatureToggle(FeatureToggle.PHOTO_CHALLENGES, true)
	ftService.mockFeatureToggle(FeatureToggle.MARK_COMPLETE_CHALLENGES, true)
	ftService.mockFeatureToggle(FeatureToggle.PURCHASE_CHALLENGES, true)
	ftService.mockFeatureToggle(FeatureToggle.PURCHASE_CHALLENGES_MULTIPLE, true)
	ftService.mockFeatureToggle(FeatureToggle.DUPLICATE_CHALLENGE, true)
	ftService.mockFeatureToggle(FeatureToggle.REWARDS_PERMISSION_MODULE, true)
	ftService.mockFeatureToggle(FeatureToggle.VOLUME_CHALLENGES, true)
	ftService.mockFeatureToggle(FeatureToggle.CANCEL_CHALLENGES, true)
	ftService.mockFeatureToggle(FeatureToggle.TARGET_CHALLENGE_VOLUME_FIXED, true)
	ftService.mockFeatureToggle(FeatureToggle.CHALLENGE_LOAD_GROUP_NAME, true)
	ftService.mockFeatureToggle(FeatureToggle.SPEND_BASED_CHALLENGE, true)

	ftService.mockFeatureToggle(FeatureToggle.POINTS_MANAGEMENT_MODULE, true)
	ftService.mockFeatureToggle(FeatureToggle.CREATE_REFUND, true)
	ftService.mockFeatureToggle(FeatureToggle.CREATE_REWARDS_OFFER, true)
	ftService.mockFeatureToggle(FeatureToggle.CREATE_POINTS_TRANSFER, true)
	ftService.mockFeatureToggle(FeatureToggle.CREATE_POINTS_REMOVAL, true)
	ftService.mockFeatureToggle(FeatureToggle.CREATE_REMOVAL_FOR_SERVICE_UTILITY, true)

	ftService.mockFeatureToggle(FeatureToggle.ELECTRICITY_BILL, true)
	ftService.mockFeatureToggle(FeatureToggle.INTERNET_BILL, true)
	ftService.mockFeatureToggle(FeatureToggle.MOBILE_PHONE_BILL, true)
	ftService.mockFeatureToggle(FeatureToggle.WATER_BILL, true)
	ftService.mockFeatureToggle(FeatureToggle.AWNING_SERVICE, true)
	ftService.mockFeatureToggle(FeatureToggle.CABLE_TV_BILL, true)
	ftService.mockFeatureToggle(FeatureToggle.CONCERT_TICKET_SERVICE, true)
	ftService.mockFeatureToggle(FeatureToggle.COUNTER_INSTALLATION, true)
	ftService.mockFeatureToggle(FeatureToggle.FACADE_SERVICE, true)
	ftService.mockFeatureToggle(FeatureToggle.PAINTING_SERVICE, true)
	ftService.mockFeatureToggle(FeatureToggle.RECHARGE_SERVICE, true)
	ftService.mockFeatureToggle(FeatureToggle.VOUCHER_SERVICE, true)

	ftService.mockFeatureToggle(FeatureToggle.COMBOS_MANAGEMENT_MODULE, true)
	ftService.mockFeatureToggle(FeatureToggle.COMBOS_BULK_UPDATE, true)
	ftService.mockFeatureToggle(...getParams(FeatureToggle.REDEEMABLE_ITEMS_MODULE))

	ftService.mockFeatureToggle(FeatureToggle.EARNING_RULES_MODULE, true)
	ftService.mockFeatureToggle(...getParams(FeatureToggle.TRANSACTION_HISTORY_MODULE))
	ftService.mockFeatureToggle(...getParams(FeatureToggle.PROGRAM_CONFIGURATION_MODULE))
	ftService.mockFeatureToggle(FeatureToggle.MULTIPLE_ZONES_LOGIN, true)

	ftService.mockFeatureToggle(FeatureToggle.SELECT_PROGRAM, true)
	ftService.mockFeatureToggle(FeatureToggle.SELECT_TIERS, false)

	ftService.mockFeatureToggle(FeatureToggle.DEV_MULTIVENDOR_VALIDATE_ACCOUNT, true)
	ftService.mockFeatureToggle(...getParams(FeatureToggle.DEV_MULTIVENDOR_REDEEMABLES_BY_VENDOR))
	ftService.mockFeatureToggle(...getParams(FeatureToggle.DEV_MULTIVENDOR_EARNING_RULES_BY_VENDOR))

	ftService.mockFeatureToggle(FeatureToggle.DEV_MULTI_VENDOR_EARNING_RULES, true)
	ftService.mockFeatureToggle(FeatureToggle.DEV_MULTI_VENDOR_COMBOS, true)
	ftService.mockFeatureToggle(FeatureToggle.DEV_COMBOS_BULK_SELECTION, true)
	ftService.mockFeatureToggle(FeatureToggle.DEV_EDIT_AND_REMOVE_MULTIPLES_COMBOS, true)
	ftService.mockFeatureToggle(FeatureToggle.DEV_MULTIVENDOR_REWARDSOFFER_BULK, true)
	ftService.mockFeatureToggle(...getParams(FeatureToggle.CONFIG_INITIAL_BALANCE_REDEEM_LIMIT_BY_TIER))
	ftService.mockFeatureToggle(FeatureToggle.SETTINGS_CONFIG_BY_ZONE, false)
	ftService.mockFeatureToggle(FeatureToggle.COMBOS_3PD_INTEGRATION, false)

	ftService.mockFeatureToggle(FeatureToggle.CREATE_EARNING_RULES, true)
	ftService.mockFeatureToggle(FeatureToggle.REDEEMABLE_SYNC_3PD, true)
	ftService.mockFeatureToggle(FeatureToggle.NEW_TRANSACTION_LOGS, true)
	ftService.mockFeatureToggle(FeatureToggle.NEW_REDEEMABLE_LOGS, false)
}
