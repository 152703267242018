import { ChallengeExecutionMethod } from '@/domains/enums'
import * as featureToggle from '@/utils/featureToggle'
import { isToggleEnabled } from '..'

type AllowedExecutionMethods = {
	[key: string]: boolean
}

type Props = {
	executionMethod?: ChallengeExecutionMethod
} | null

export const useIsTargetForChallengeEnabled = (props: Props): boolean => {
	const { executionMethod } = props || {}
	const allowedExecutionMethods: AllowedExecutionMethods = {
		[ChallengeExecutionMethod.VolumeFixed]: isToggleEnabled(featureToggle.TARGET_CHALLENGE_VOLUME_FIXED),
	}

	return !!(executionMethod && allowedExecutionMethods[executionMethod])
}
