import { css, styled } from '@hexa-ui/theme'

export const Container = styled('div', {
	display: 'flex',
	flex: 1,
	flexDirection: 'column',
})

export const ContentInput = styled('div', {
	display: 'flex',
	flex: 1,
	justifyContent: 'space-between',
	alignContent: 'center',
	alignItems: 'center',
	minWidth: '410px',
	maxWidth: 'auto',
})

export const ContentInputChild = styled('div', {
	alignSelf: 'flex-end',
	display: 'flex',
	flex: 1,
	width: 'auto',
	minWidth: '200px',
	maxWidth: 'auto',
})

export const ControllerDiv = styled('div', {
	width: '100%',
	marginTop: '24px',
})

export const childDivInput = css({
	'#firstInput ~ #secondInput': { marginLeft: '15px' },
})

export const MultiTabHint = styled('div', {
	display: 'flex',
	marginTop: '$2',
	fontSize: '12px',
	lineHeight: '18px',
	borderLeft: '2px solid #DBDADA',
	overflowWrap: 'break-word',
	paddingLeft: '4px',
	color: '#757575',
})
