import { RouteInstance } from '@/domains/route/RouteInstance'
import { useActiveRouteSettings } from '@/hooks/activeRoute/useActiveRouteSettings'
import { useHideTierInfo } from '@/hooks/tier/useHideTierInfo'
import { useAppHeader } from 'admin-portal-shared-services'
import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import SelectVendor from './components/SelectVendor'
import SelectZone from './components/SelectZone'
import useStyle from './styles'

type AppHeaderProps = {
	authorizedRoutes: Array<RouteInstance>
}

const AppHeader: React.FC<AppHeaderProps> = ({ authorizedRoutes }) => {
	const css = useStyle()
	const [, setAppHeaderConfig] = useAppHeader()
	const { pageTitle, breadcrumbConfig } = useActiveRouteSettings(authorizedRoutes)

	useEffect(() => {
		setAppHeaderConfig({
			pageTitle,
			vendorSelect: false,
			countrySelect: false,
			breadcrumbConfig,
		})
	}, [setAppHeaderConfig, breadcrumbConfig, pageTitle])

	const shouldHideTierInfo = useHideTierInfo()

	const mainContainerClasses = useMemo(() => {
		const classes = shouldHideTierInfo ? [css.hexaDSMcontainer] : []
		return classes
	}, [css.hexaDSMcontainer, shouldHideTierInfo])

	return (
		<div className={classNames(css.container, mainContainerClasses)}>
			<div className={css.group}>
				<div className={css.item}>
					<SelectVendor />
				</div>
				<div className={css.item}>
					<SelectZone />
				</div>
			</div>
		</div>
	)
}

export default AppHeader
