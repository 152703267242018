export interface EditSKUStepData {
	action?: string
	process?: string
	skus?: boolean
}

export enum SKUAction {
	ADD_SKU = 'ADD_SKU',
	MOVE_SKU = 'MOVE_SKU',
	REMOVE_SKU = 'REMOVE_SKU',
}

export enum SKUProcess {
	SINGLE_SKU = 'SINGLE_SKU',
	MULTIPLE_SKU = 'MULTIPLE_SKU',
}
