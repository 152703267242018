import { EditRedeemableItemRequest } from '@/domains'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import * as RedeemablesService from '@/services/redeemablesV2/RedeemablesService'
import { EditRedeemableItemStoreEvents, RedeemableItemsStoreEvents } from '@/stores'
import { useLogService as logService } from 'admin-portal-shared-services'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { tokenData } from '../../../server/utils/token'

interface EditRedeemableItem extends EditRedeemableItemRequest {
	vendorId: string
	defaultCpp: number
	userId: string
	redeemableId: string
	productName: string
}

async function execute(item: RedeemableItem, defaultCpp: number): Promise<void> {
	const log = logService()
	const vendorId = getDefaultVendorIdOfCurrentZone()
	EditRedeemableItemStoreEvents.isPublishing()

	try {
		const { pricePerPoint = 0 } = item

		const requestData: EditRedeemableItem = {
			pricePerPoint: +pricePerPoint,
			vendorId,
			defaultCpp,
			userId: tokenData.sub,
			redeemableId: item.vendorItemId,
			productName: item.itemName,
		}
		await RedeemablesService.editRedeemableItem(requestData)
		EditRedeemableItemStoreEvents.isPublishSuccess()
		RedeemableItemsStoreEvents.reloadPage()
		EditRedeemableItemStoreEvents.reset()
	} catch (error) {
		log.error('FAILED TO EDIT REDEEMABLE ITEM: ', error)
		EditRedeemableItemStoreEvents.hasError(true)
		throw error
	}
}

export { execute }
