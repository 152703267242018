import { ChallengeStatusPill, Tooltip } from '@/components/core'
import CopyButton from '@/components/core/CopyButton'
import { ChallengeStatus, EditDialog } from '@/domains/enums'
import { ToggleEditDialogFunction } from '@/routes/challenges/model/EditChallengeDialog'
import ChallengeHistoryDetail from '@/routes/challenges/model/new/ChallengeHistoryDetail'
import { isPhoto } from '@/utils/challenge'
import { Box, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useStoreMap } from 'effector-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ChallengeHistoryStore from 'stores/challengeHistory/ChallengeHistoryStore'
import parentStyles from '../ChallengeHistoryDetailStyle'
import GoodBadPhoto, { BAD_IMG_COLOR, GOOD_IMG_COLOR } from '../GoodBadPhoto'
import styles from './styles'

type Props = {
	toggleEditDialog: ToggleEditDialogFunction
	challenge: ChallengeHistoryDetail
}

const DataSectionSetup: React.FC<Props> = ({ toggleEditDialog, challenge }) => {
	const parentClasses = parentStyles()
	const classes = styles()
	const { t } = useTranslation()
	const challengeCancelled = useStoreMap({
		store: ChallengeHistoryStore,
		keys: [],
		fn: (state) => state.challengeCancelled,
	})

	const renderEdit = () => {
		const enableEdit = ChallengeStatus.Created === challenge.status || ChallengeStatus.Active === challenge.status
		return enableEdit && !challengeCancelled ? (
			<Typography
				id="step1-edit-btn"
				data-testid="step1-edit-btn"
				className={parentClasses.editLink}
				onClick={(): void => toggleEditDialog(false, EditDialog.Setup)}
			>
				{t('EDIT')}
			</Typography>
		) : undefined
	}

	const getChallengeStatus = (status: ChallengeStatus, cancelled: boolean): ChallengeStatus => {
		return cancelled ? ChallengeStatus.Cancelled : status
	}

	return (
		<Box className={classes.subContainer}>
			<Grid container className={classes.dataContainer}>
				<Grid item xs={11}>
					<Grid container spacing={2}>
						<Grid item>
							<ChallengeStatusPill status={getChallengeStatus(challenge.status, challengeCancelled)} />
						</Grid>
						<Grid item>
							<Typography id="challenge-type-value" className={classes.bodyGrey}>
								{challenge.executionMethodAsString}
							</Typography>
						</Grid>
						<Grid item>
							<Typography id="challenge-id" className={classes.bodyGrey}>
								{`${t('challenge:HISTORY_DETAIL_REDESIGN.ID')} ${challenge.id}`}
							</Typography>
						</Grid>
						<>
							<Grid item style={{ paddingRight: '0' }}>
								<Tooltip
									title={challenge.campaignId ?? ''}
									disabled={challenge.campaignId ? challenge.campaignId.length <= 30 : true}
								>
									<Typography id="campaign-id" className={classes.campaignIdLabel}>
										{`${t('challenge:HISTORY_DETAIL_REDESIGN.CAMPAIGN_ID')} ${
											challenge.campaignId ? challenge.campaignId : '-'
										}`}
									</Typography>
								</Tooltip>
							</Grid>
							<Grid item style={{ paddingLeft: '0' }}>
								{challenge.campaignId && <CopyButton copyText={challenge.campaignId} challenge={challenge} />}
							</Grid>
						</>
					</Grid>
				</Grid>
				<Grid item xs={1}>
					{renderEdit()}
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={5}>
					<Box className={classes.titleAndDescription}>
						<Typography id="challenge-title-setup" variant="h3">
							{challenge.title}
						</Typography>
						<Typography
							id="challenge-description-setup"
							data-testid="challenge-description"
							className={classes.challengeDescription}
						>
							{challenge.description}
						</Typography>
					</Box>
				</Grid>
				<Grid item container xs={7} className={classes.imageContainer}>
					<Grid container wrap="nowrap">
						<Grid item>
							<Box paddingRight={2}>
								<img className={classes.image} src={challenge.image} alt="Challenge" />
							</Box>
						</Grid>
						{isPhoto(challenge) && (
							<GoodBadPhoto>
								<img
									className={clsx(classes.image, classes.goodBadImage)}
									data-testid="good-photo"
									src={challenge.goodPhotoSample}
									alt="Good sample"
									style={{ borderColor: GOOD_IMG_COLOR }}
								/>
								<img
									className={clsx(classes.image, classes.goodBadImage)}
									data-testid="bad-photo"
									src={challenge.badPhotoSample}
									alt="Bad sample"
									style={{ borderColor: BAD_IMG_COLOR }}
								/>
							</GoodBadPhoto>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default DataSectionSetup
