import React, { useCallback } from 'react'
import { Alert, Button, Dialog, Heading, SearchField, EmptyState as HexaEmptyState } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import {
	$createRedeemableItemStore,
	CreateRedeemableItemState,
	CreateRedeemableItemStoreEvents,
} from '@/stores/redeemableItems/CreateRedeemableItemStore'
import { useStore } from 'effector-react'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { CriticalPricePerPointConfigurationStoreEvents } from '@/stores/redeemableItems/CriticalPricePerPointStoreV2'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { useAnalyticsServiceEventRedeemableCreated } from '@/hooks/analyticsService/useAnalyticsServiceEventRedeemableCreated'
import { useFormContext } from 'react-hook-form'
import { CreateRedeemableItemUseCase } from '@/usecase'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import { ButtonInteraction, ScreenName } from '@/domains'
import { unformat } from '@/utils/currencyHelper'
import { MODAL_CHANGE_DELAY } from '@/utils/constants'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useAnalyticsServiceEventTableSearched } from '@/hooks/analyticsService/useAnalyticsServiceEventTableSearched'
import useAuditLogRedeemables from '../../hooks/useAuditLogRedeemables'
import { EmptyState } from './EmptyState/EmptyState'
import useStyles from './AddRedeemableDialogStyle'
import { RedeemableItemCell } from '../RedeemableItem/RedeemableItemCell'
import { ItemCellLoader } from '../CreateRedeemableItem/content/Loader/ItemCellLoader'
import AddRedeemableItemFormProvider from './Form/AddRedeemableItemFormProvider'

interface AddRedeemableDialogProps {
	isOpened: boolean
	trigger?: React.ReactNode
}

export const AddRedeemableDialog: React.FC<AddRedeemableDialogProps> = ({ isOpened, trigger }) => {
	const css = useStyles()
	const { t } = useTranslation()

	const state = useStore($createRedeemableItemStore)
	const userInfo = useGetUserInfo()
	const { sendAnalyticsServiceTableSearched } = useAnalyticsServiceEventTableSearched()

	const minCpp = useFormatCurrency(state.item?.cppLimits?.min)
	const maxCpp = useFormatCurrency(state.item?.cppLimits?.max)

	const onDialogOpenChangeHandler = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_label: ButtonInteraction.Cancel,
			button_name: ButtonInteraction.Cancel,
			screen_name: ScreenName.CreateRedeemable,
			...userInfo,
		})

		return isOpened ? CreateRedeemableItemStoreEvents.reset() : CreateRedeemableItemStoreEvents.onOpen()
	}, [isOpened, userInfo])

	const Title = <Heading size="H3">{t('redeemable:ADD.TITLE')}</Heading>

	const shouldRenderEmptyState = !state.isSearching && !state.item && state.atInitialState
	const shouldRenderNotFound = !state.isSearching && !state.item && !state.atInitialState

	const handleSearch = useCallback(
		(value: string, event: React.FormEvent) => {
			event.preventDefault()
			CreateRedeemableItemUseCase.onSearch(value)
			sendAnalyticsServiceTableSearched({
				searchQuery: value,
				contentType: 'Create Redeemable',
				tableName: ' Create Redeemable - SKU',
				screenName: ScreenName.CreateRedeemable,
			})
		},
		[sendAnalyticsServiceTableSearched],
	)

	return (
		<div className={css.container} data-testid="add-redeemable-dialog">
			<AddRedeemableItemFormProvider>
				<Dialog.Root
					closeButton
					actions={<Actions {...state} />}
					title={Title}
					trigger={trigger}
					open={isOpened}
					onOpenChange={onDialogOpenChangeHandler}
				>
					<p>{t('redeemable:ADD.DESCRIPTION')}</p>
					<SearchField.Root onSearch={handleSearch} placeholder={t('redeemable:SEARCH_PLACEHOLDER')} />

					<div className={css.emptyStateWrapper}>
						{shouldRenderEmptyState && (
							<div data-testid="add-redeemable-dialog-empty-state">
								<EmptyState />
							</div>
						)}

						{shouldRenderNotFound && (
							<div className={css.noResultWrapper}>
								<HexaEmptyState.SectionLevel
									description={t('redeemable:CREATE.ITEM_NOT_FOUND.DESCRIPTION')}
									ilustrationName="beesAlert"
								/>
							</div>
						)}
					</div>

					<div className={css.wrapperItem}>
						{state.isSearching && <ItemCellLoader />}

						{!state.isSearching && state.item && <RedeemableItemCell {...state.item} />}
					</div>

					{!state.isSearching && state.item && (
						<Alert
							message={t('redeemable:ADD.COST_PER_POINT_RANGE', { minCpp, maxCpp })}
							type="warning"
							className={css.warning}
						/>
					)}

					<Alert
						className={css.alert}
						type="error"
						message={t('ERROR_MESSAGE.GENERIC_ERROR')}
						style={{ display: state.errorOnPublish || state.errorOnItems ? 'flex' : 'none' }}
					/>
				</Dialog.Root>
			</AddRedeemableItemFormProvider>
		</div>
	)
}

const Actions = ({ item, errorOnPublish, isPublishing, variants }: CreateRedeemableItemState) => {
	const css = useStyles()
	const { t } = useTranslation()

	const { sendAuditLogRedeemables } = useAuditLogRedeemables()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()
	const { sendAnalyticsServiceRedeemableCreated } = useAnalyticsServiceEventRedeemableCreated()

	const {
		handleSubmit,
		formState: { isDirty, isValid },
	} = useFormContext()

	const invalidPricePerPoint = isDirty && !isValid

	const onSubmit = useCallback(
		async (pricePerPoint: number) => {
			try {
				await CreateRedeemableItemUseCase.publish(
					{ redeemables: [item as RedeemableItem, ...variants], pricePerPoint },
					true,
				)
				if (item) {
					sendAuditLogRedeemables({ pricePerPoint, SKU: item.vendorItemId, operation: IAuditOperations.INSERT })
					sendAnalyticsServiceRedeemableCreated({
						pricePerPoint,
						SKU: item?.vendorItemId,
						skuName: item.itemName,
						screenName: ScreenName.CreateRedeemable,
					})
				}
			} catch (error) {
				if (error instanceof Error) {
					sendAnalyticsServiceEventError({
						failureReason: error.message,
						screenName: ScreenName.CreateRedeemable,
					})
				}
			}
		},
		[item, variants, sendAuditLogRedeemables, sendAnalyticsServiceRedeemableCreated, sendAnalyticsServiceEventError],
	)

	const buttonAction = useCallback(
		({ pricePerPoint }: { pricePerPoint: string }) => {
			const unformattedPricePerPoint: number = unformat(pricePerPoint)
			if (item) CreateRedeemableItemStoreEvents.setItem({ ...item, pricePerPoint: unformattedPricePerPoint })

			setTimeout(
				() =>
					CriticalPricePerPointConfigurationStoreEvents.onOpen({
						submit: () => {
							onSubmit(unformattedPricePerPoint)
						},
						close: () => CreateRedeemableItemStoreEvents.onClose(),
						skuPricePerPoint: unformattedPricePerPoint,
					}),
				MODAL_CHANGE_DELAY,
			)
		},
		[item, onSubmit],
	)

	return (
		<div className={css.actionContainer}>
			<Dialog.Close>
				<Button data-testid="add-redeemable-dialog-cancel-button" size="medium" variant="secondary">
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="add-redeemable-dialog-publish-button"
				size="medium"
				variant="primary"
				disabled={!item || invalidPricePerPoint}
				onClick={handleSubmit(buttonAction)}
				isLoading={isPublishing}
			>
				{errorOnPublish ? t('BUTTON.TRY_AGAIN') : t('BUTTON.ADD')}
			</Button>
		</div>
	)
}
