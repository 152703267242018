import { countryConfig, MAX_DECIMAL_SIZE } from './constants'

export function formatCurrency(value: number, locale = countryConfig.fallback.locale): string {
	const countryCode = locale.replace('_', '-').split('-')[1]
	const { currency } = countryConfig[countryCode] ?? countryConfig.fallback

	try {
		const formater = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency,
			maximumSignificantDigits: MAX_DECIMAL_SIZE,
		})

		return formater.format(value)
	} catch {
		return 'ERROR'
	}
}
