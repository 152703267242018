import { useAnalyticsService, useAuthenticationService } from 'admin-portal-shared-services'
import { useCallback } from 'react'

const useAnalyticsServiceMembership = (): {
	load: (segmentKey: string) => void
	identify: () => void
} => {
	const analyticsService = useAnalyticsService()
	const authenticationService = useAuthenticationService()

	const load = useCallback(
		(segmentKey: string) => {
			analyticsService.load(segmentKey)
		},
		[analyticsService],
	)

	const identify = useCallback(() => {
		const traits = {
			zone: authenticationService.getCountryB2C(),
		}
		analyticsService.identify(traits)
	}, [analyticsService, authenticationService])

	return {
		load,
		identify,
	}
}

export default useAnalyticsServiceMembership
