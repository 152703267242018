import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStoreMap } from 'effector-react'
import { ChallengeStore, ChallengeEvents, ChallengeFilterFormData } from '@/stores/challengeCreation'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { BASE_ROUTE, CHALLENGE_BASE_ROUTE } from '@/utils/constants'
import useIsTargetForCreationEnabled from '@/hooks/challenge/useIsTargetForCreationEnabled'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import ChallengeFormFilterView from './ChallengeFormFilterView'
import { ChallengeLeavingGuard } from '../ChallengeLeavingGuard/ChallengeLeavingGuard'
import ChallengeFormFilterProvider from './ChallengeFormFilterProvider'

const ChallengeFormFilter: React.FC = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const isTargetEnabled = useIsTargetForCreationEnabled()
	const useGetInfo = useGetUserInfo()

	const { challengeFilterFormData, challengeSetupFormData } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => ({
			challengeFilterFormData: state.challengeFilterFormData,
			challengeSetupFormData: state.challengeSetupFormData ?? null,
		}),
	})

	const handleFormSubmit = (formData: ChallengeFilterFormData) => {
		if (!challengeSetupFormData) {
			ChallengeEvents.showPageLevelNotification({
				type: 'previousStep',
				message: t('ERROR_MESSAGE.PAGE_LEVEL_ERROR_CHALLENGE.MESSAGE_CONTINUE'),
			})
			return
		}

		ChallengeEvents.setChallengeFilterFormData(formData)
		AnalyticsService.events.buttonClicked({
			button_name: 'Continue',
			button_label: 'Continue',
			screen_name: 'Challenge Creation - 2nd Step',
			...useGetInfo,
		})
		history.push(`${CHALLENGE_BASE_ROUTE}/details`)
	}

	const handleClickBackButton = () => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Back',
			button_label: 'Back',
			screen_name: 'Challenge Creation - 2nd Step',
			...useGetInfo,
		})
		history.push(`${CHALLENGE_BASE_ROUTE}/setup`)
	}

	useEffect(() => {
		ChallengeEvents.setCurrentStep({ stepIndex: 1 })
	}, [t])

	return (
		<ChallengeFormFilterProvider>
			<ChallengeLeavingGuard whiteList={[`${BASE_ROUTE}/challenge/setup`, `${BASE_ROUTE}/challenge/details`]} />
			<ChallengeFormFilterView
				onFormSubmit={handleFormSubmit}
				onClickBackButton={handleClickBackButton}
				challengeFilterFormData={challengeFilterFormData}
				isTargetEnabled={isTargetEnabled}
			/>
		</ChallengeFormFilterProvider>
	)
}

export default ChallengeFormFilter
