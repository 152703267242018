import { useMemo } from 'react'
import { ServiceUtilityCategory } from '@/domains/enums'
import * as featureToggle from '@/utils/featureToggle'
import { useTranslation } from 'react-i18next'

function useCategoryForServiceUtilityList() {
	const { t } = useTranslation()

	return useMemo(
		() => [
			{
				value: ServiceUtilityCategory.electricityBill,
				label: t('common:SERVICE_CATEGORY.ELECTRICITY_BILL'),
				toggle: featureToggle.ELECTRICITY_BILL,
			},
			{
				value: ServiceUtilityCategory.internetBill,
				label: t('common:SERVICE_CATEGORY.INTERNET_BILL'),
				toggle: featureToggle.INTERNET_BILL,
			},
			{
				value: ServiceUtilityCategory.mobilePhoneBill,
				label: t('common:SERVICE_CATEGORY.MOBILE_PHONE_BILL'),
				toggle: featureToggle.MOBILE_PHONE_BILL,
			},
			{
				value: ServiceUtilityCategory.waterBill,
				label: t('common:SERVICE_CATEGORY.WATER_BILL'),
				toggle: featureToggle.WATER_BILL,
			},
			{
				value: ServiceUtilityCategory.awningService,
				label: t('common:SERVICE_CATEGORY.AWNING_SERVICE'),
				toggle: featureToggle.AWNING_SERVICE,
			},
			{
				value: ServiceUtilityCategory.cableTvbill,
				label: t('common:SERVICE_CATEGORY.CABLE_TV_BILL'),
				toggle: featureToggle.CABLE_TV_BILL,
			},
			{
				value: ServiceUtilityCategory.concertTicketService,
				label: t('common:SERVICE_CATEGORY.CONCERT_TICKET_SERVICE'),
				toggle: featureToggle.CONCERT_TICKET_SERVICE,
			},
			{
				value: ServiceUtilityCategory.counterInstallation,
				label: t('common:SERVICE_CATEGORY.COUNTER_INSTALLATION'),
				toggle: featureToggle.COUNTER_INSTALLATION,
			},
			{
				value: ServiceUtilityCategory.facadeSewrvice,
				label: t('common:SERVICE_CATEGORY.FACADE_SERVICE'),
				toggle: featureToggle.FACADE_SERVICE,
			},
			{
				value: ServiceUtilityCategory.paintingService,
				label: t('common:SERVICE_CATEGORY.PAINTING_SERVICE'),
				toggle: featureToggle.PAINTING_SERVICE,
			},
			{
				value: ServiceUtilityCategory.rechargeService,
				label: t('common:SERVICE_CATEGORY.RECHARGE_SERVICE'),
				toggle: featureToggle.RECHARGE_SERVICE,
			},
			{
				value: ServiceUtilityCategory.voucherService,
				label: t('common:SERVICE_CATEGORY.VOUCHER_SERVICE'),
				toggle: featureToggle.VOUCHER_SERVICE,
			},
		],
		[t],
	)
}

export default useCategoryForServiceUtilityList
