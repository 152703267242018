import { createStore } from 'effector'
import * as TransactionEvents from './TransactionEvents'
import TransactionState from './TransactionState'

export const transactionInitialState: TransactionState = {
	selectedOrder: null,
	pageLevelNotificationType: null,
	showError: false,
	transactionType: null,
	isSubmitVisible: false,
	isSubmitEnabled: false,
	rewardsOfferType: null,
	resetNewValues: null,
	resetForm: false,
	activityLogDialog: {
		show: false,
		failedPocs: [],
		id: '',
		description: '',
	},
	confirmationMessage: '',
	originAccountBalance: '',
	pointsRemoval: {
		currentPointsBalance: '',
		newPointsBalance: '',
	},
	category: undefined,
	referenceMonth: undefined,
	referenceYear: undefined,
	removalForServiceUtility: {
		currentPointsBalance: '',
		newPointsBalance: '',
	},
	accountIdTranslationMap: {},
}

const TransactionStore = createStore(transactionInitialState)
	.on(TransactionEvents.selectOrder, (state, selectedOrder) => ({
		...state,
		selectedOrder,
	}))
	.on(TransactionEvents.showNotificationForTransaction, (state, pageLevelNotificationType) => ({
		...state,
		pageLevelNotificationType,
	}))
	.on(TransactionEvents.setShowErrorForTransaction, (state, showError) => ({
		...state,
		showError,
	}))
	.on(TransactionEvents.setIsSubmitVisible, (state, isSubmitVisible) => ({
		...state,
		isSubmitVisible,
	}))
	.on(TransactionEvents.setIsSubmitEnabled, (state, isSubmitEnabled) => ({
		...state,
		isSubmitEnabled,
	}))
	.on(TransactionEvents.setTransactionType, (state, transactionType) => ({
		...state,
		transactionType,
	}))
	.on(TransactionEvents.setRewardsOfferType, (state, rewardsOfferType) => ({
		...state,
		rewardsOfferType,
	}))
	.on(TransactionEvents.resetForm, (state, resetNewValues) => {
		return {
			...state,
			transactionType: resetNewValues.transactionType,
			rewardsOfferType: resetNewValues.rewardsOfferType,
			category: resetNewValues.category,
			referenceMonth: resetNewValues.referenceMonth,
			referenceYear: resetNewValues.referenceYear,
			showError: false,
			resetForm: true,
			resetNewValues,
			selectedOrder: null,
		}
	})
	.on(TransactionEvents.showActivityLogDialog, (state, transactionData) => ({
		...state,
		activityLogDialog: {
			show: true,
			failedPocs: transactionData.failedPocs,
			description: transactionData.description,
			id: transactionData.id,
		},
	}))
	.on(TransactionEvents.hideActivityLogDialog, (state) => ({
		...state,
		activityLogDialog: { show: false, failedPocs: state.activityLogDialog.failedPocs, description: '', id: '' },
	}))
	.on(TransactionEvents.setConfirmationMessage, (state, confirmationMessage) => ({
		...state,
		confirmationMessage,
	}))
	.on(TransactionEvents.setOriginAccountBalance, (state, originAccountBalance) => ({
		...state,
		originAccountBalance,
	}))
	.on(TransactionEvents.setPointsRemoval, (state, pointsRemoval) => ({
		...state,
		pointsRemoval: {
			...state.pointsRemoval,
			...pointsRemoval,
		},
	}))
	.on(TransactionEvents.setRemovalForServiceUtility, (state, pointsRemovalForServiceUtility) => ({
		...state,
		removalForServiceUtility: {
			...state.removalForServiceUtility,
			...pointsRemovalForServiceUtility,
		},
	}))
	.on(TransactionEvents.addMapToAccountIdTranslationMap, (state, map) => ({
		...state,
		accountIdTranslationMap: {
			...state.accountIdTranslationMap,
			...map,
		},
	}))
	.reset(TransactionEvents.resetForTransaction)

export default TransactionStore
