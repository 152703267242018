import React from 'react'
import useStyles from '../../styles'

type Props = {
	children: React.ReactNode
	hasContainerBox?: boolean
	id: string
}

const ContainerBox: React.FC<Props> = ({ children, hasContainerBox, id }: Props) => {
	const classes = useStyles()
	return hasContainerBox ? (
		<div className={classes.box} data-testid={`container-box-${id}`}>
			{children}
		</div>
	) : (
		<> {children}</>
	)
}

export default ContainerBox
