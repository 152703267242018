import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		container: {
			position: 'absolute',
			right: 'var(--space-4)',
			top: '-108px',
		},
		hexaDSMcontainer: {
			top: '-114px',
		},
		group: {
			display: 'flex',
			flexDirection: 'row-reverse',
			paddingRight: '24px',
		},
		item: {
			paddingLeft: '1rem',
		},
	}),
)

export default styles
