import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ChallengeDetailsFormData, ChallengeEvents, ChallengeState, ChallengeStore } from '@/stores/challengeCreation'
import { ButtonInteraction, ScreenName } from '@/domains'
import { useStoreMap } from 'effector-react'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { BASE_ROUTE, CHALLENGE_BASE_ROUTE } from '@/utils/constants'
import { challengeSkusToItems } from '@/converters'
import { isPurchaseOrVolume } from '@/utils/challenge'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import ChallengeFormDetailsView from './ChallengeFormDetailsView'
import { ChallengeLeavingGuard } from '../ChallengeLeavingGuard/ChallengeLeavingGuard'
import ChallengeFormDetailsProvider from './ChallengeFormDetailsProvider'

type Props = {
	isEditing?: boolean
}

const ChallengeFormDetails: React.FC<Props> = ({ isEditing }) => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const history = useHistory()
	const { challengeSetupFormData, challengeDetailsFormData, successfullySubmitForm } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state: ChallengeState) => ({
			challengeSetupFormData: state.challengeSetupFormData,
			challengeDetailsFormData: state.challengeDetailsFormData,
			successfullySubmitForm: state.successfullySubmitForm,
			isIndividualTarget: !!state.challengeFilterFormData?.individualTarget,
		}),
	})

	const handleClickBackButton = (formData: ChallengeDetailsFormData) => {
		AnalyticsService.events.buttonClicked({
			button_label: 'Back',
			button_name: 'Back',
			screen_name: 'Challenge Creation - 3rd Step',
			...userInfo,
		})
		ChallengeEvents.setChallengeDetailsFormData(formData)
		history.push(`${CHALLENGE_BASE_ROUTE}/filter`)
	}

	useEffect(() => {
		ChallengeEvents.setCurrentStep({ stepIndex: 2 })
	}, [])

	const handleFormSubmit = (formData: ChallengeDetailsFormData) => {
		/* istanbul ignore else */
		if (!challengeSetupFormData) {
			ChallengeEvents.showPageLevelNotification({
				type: 'previousStep',
				message: t('ERROR_MESSAGE.PAGE_LEVEL_ERROR_CHALLENGE.MESSAGE'),
			})
			AnalyticsService.events.error({
				form_name: 'Challenge Creation - 3rd Step',
				screen_name: 'Challenge Creation - 3rd Step',
				failure_reason: t('ERROR_MESSAGE.PAGE_LEVEL_ERROR_CHALLENGE.MESSAGE'),
				...userInfo,
			})
			return
		}
		const executionMethod = challengeSetupFormData?.executionMethod
		if (executionMethod && isPurchaseOrVolume({ executionMethod })) {
			const { items, skus } = challengeSkusToItems(formData.skus!, formData.items)
			formData.items = items
			formData.skus = skus
		}
		ChallengeEvents.setChallengeDetailsFormData(formData)
		ChallengeEvents.setShowSubmitDialog(true)
		AnalyticsService.events.buttonClicked({
			button_name: ButtonInteraction.Submit,
			screen_name: ScreenName.ChallengeDetails,
			button_label: ButtonInteraction.Submit,
			...userInfo,
		})
	}

	const whiteListedRoutes = useMemo(() => {
		const routes = [`${BASE_ROUTE}/challenge/filter`]
		if (!challengeSetupFormData) {
			routes.push(`${BASE_ROUTE}/challenge/setup`)
		}
		if (successfullySubmitForm) {
			routes.push(`${BASE_ROUTE}/challenges`)
		}
		return routes
	}, [challengeSetupFormData, successfullySubmitForm])

	return (
		<ChallengeFormDetailsProvider>
			<ChallengeLeavingGuard whiteList={whiteListedRoutes} />
			<ChallengeFormDetailsView
				onFormSubmit={handleFormSubmit}
				onClickBackButton={handleClickBackButton}
				isEditing={isEditing}
				challengeDetailsFormData={challengeDetailsFormData}
			/>
		</ChallengeFormDetailsProvider>
	)
}

export default ChallengeFormDetails
