import { deleteRedeemable } from '@/services/redeemables/RedeemablesService'
import { RedeemableItemsStoreEvents } from '@/stores'
import { useLogService as logService } from 'admin-portal-shared-services'

async function execute(id: string): Promise<void> {
	const log = logService()

	try {
		await deleteRedeemable(id)
		RedeemableItemsStoreEvents.reloadPage()
	} catch (error) {
		log.error('Error deleting redeemableId: ', id, ' \nError detail: ', error)
		throw error
	}
}

export { execute }
