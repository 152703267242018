import React, { ChangeEvent } from 'react'
import { Field, FieldProps } from 'formik'
import { RadioGroup, FormControlLabel, Radio, Box } from '@material-ui/core'
import InputLabel from 'components/formik/InputLabel'
import { RadioGroupOptions } from './model/RadioGroupOptions'
import useLocalStyles from './styles'
import FieldErrorMessage from '../FieldErrorMessage'
import ContainerBox from './ContainerBox'

type Props = {
	id: string
	defaultValue?: string
	name: string
	label?: string
	options: Array<RadioGroupOptions>
	disabled?: boolean
	showError?: boolean
	hasContainerBox?: boolean
	onChange?: (event: ChangeEvent<{ name?: string; value: string }>) => void
}

const InputRadioGroup: React.FC<Props> = ({
	id,
	defaultValue = '',
	name,
	label,
	options,
	disabled,
	showError,
	hasContainerBox,
	onChange,
}) => {
	const inputRadioGroupClasses = useLocalStyles()

	const radioOptions = options.map((item, key) => (
		<FormControlLabel
			key={`radio-button-value-${id}-${key + 1}`}
			id={`radio-button-value-${id}`}
			label={item.label}
			value={item.value}
			control={<Radio color="primary" classes={{ root: inputRadioGroupClasses.root }} />}
			color="primary"
		/>
	))

	return (
		<Field name={name} disabled={disabled}>
			{(fieldProps: FieldProps) => {
				const shouldShowError =
					!!fieldProps.meta.error && (showError || (showError === undefined && fieldProps.form.submitCount > 0))
				return (
					<ContainerBox id={id} hasContainerBox={hasContainerBox}>
						{label && (
							<InputLabel
								label={label}
								htmlFor={id}
								error={shouldShowError}
								disabled={disabled}
								bottomSpacing={false}
							/>
						)}
						{shouldShowError && (
							<Box mt={1}>
								<FieldErrorMessage id={id} message={fieldProps.meta.error} />
							</Box>
						)}
						<RadioGroup
							id={id}
							name={name}
							value={fieldProps.field.value ?? defaultValue}
							onChange={(event: ChangeEvent<{ name?: string; value: string }>): void => {
								fieldProps.field.onChange(event)
								if (onChange) onChange(event)
							}}
							onBlur={fieldProps.field.onBlur}
						>
							{radioOptions}
						</RadioGroup>
					</ContainerBox>
				)
			}}
		</Field>
	)
}

InputRadioGroup.defaultProps = {
	showError: undefined,
	disabled: false,
	hasContainerBox: true,
}

export default InputRadioGroup
