import React, { useEffect, useMemo } from 'react'
import { ButtonInteraction, Redeemable, ScreenName } from '@/domains'
import { ProgramCombo } from '@/domains/program'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ComboEvents, PageLevelEvents } from '@/stores'
import useIsCombosBulkUpdateEnabled from '@/hooks/combo/useIsCombosBulkUpdateEnabled'
import { RedeemableStatus } from '@/domains/enums'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useAnalyticsServiceEventPageViewed } from '@/hooks/analyticsService/useAnalyticsServiceEventPageViewed'
import ComboUnpublishedTabView from './ComboUnpublishedTabView'
import { useCombos } from '../../hooks/useCombos'
import { useDownloadCombos } from '../../hooks/useDownloadCombos'

type ComboUnpublishedTabProps = {
	isEnabledEditAndRemoveMultiplesCombos?: boolean
}

const ComboUnpublishedTab: React.FC<ComboUnpublishedTabProps> = ({ isEnabledEditAndRemoveMultiplesCombos = false }) => {
	const isCombosBulkUpdateEnabled = useIsCombosBulkUpdateEnabled()
	const userInfo = useGetUserInfo()
	const pageSize = 50
	const { combos, isLoading, showErrorCombos } = useCombos(RedeemableStatus.UNPUBLISHED)
	const { downloadCombos } = useDownloadCombos(combos, RedeemableStatus.UNPUBLISHED)
	const { sendAnalyticsServiceEventPageViewed } = useAnalyticsServiceEventPageViewed()

	useEffect(() => {
		sendAnalyticsServiceEventPageViewed(ScreenName.DTComboManagementUnpublishedTab)
	}, [sendAnalyticsServiceEventPageViewed])

	const handleRemoveCombo = (selectedCombo: Redeemable | ProgramCombo, rowIndex: number) => {
		AnalyticsService.events.buttonClicked({
			button_label: null,
			button_name: ButtonInteraction.Remove,
			screen_name: ScreenName.DTComboManagement,
			...userInfo,
		})
		PageLevelEvents.hideNotification()
		if (isCombosBulkUpdateEnabled) {
			ComboEvents.setActiveRedeemable({ activeRedeemable: [selectedCombo as Redeemable], rowIndexToDelete: [rowIndex] })
		} else {
			ComboEvents.setActiveCombo({ activeCombo: selectedCombo as ProgramCombo, rowIndexToDelete: rowIndex })
		}
		ComboEvents.setShowRemoveComboDialog(true)
	}

	const handleRemoveMultipleCombos = (selectedCombos: Redeemable[], rowIndexes: number[]) => {
		PageLevelEvents.hideNotification()
		ComboEvents.setActiveRedeemable({ activeRedeemable: selectedCombos, rowIndexToDelete: rowIndexes })
		ComboEvents.setShowRemoveComboDialog(true)
	}

	const handleEditMultipleCombo = (selectedCombo: Redeemable[]) => {
		PageLevelEvents.hideNotification()
		ComboEvents.setActiveRedeemable({ activeRedeemable: selectedCombo })
		ComboEvents.setShowEditComboDialog(true)
	}

	const handleEditCombo = (selectedCombo: Redeemable | ProgramCombo) => {
		AnalyticsService.events.buttonClicked({
			button_label: null,
			button_name: ButtonInteraction.Edit,
			screen_name: ScreenName.DTComboManagement,
			...userInfo,
		})
		PageLevelEvents.hideNotification()
		if (isCombosBulkUpdateEnabled) {
			ComboEvents.setActiveRedeemable({ activeRedeemable: [selectedCombo] as Redeemable[] })
		} else {
			ComboEvents.setActiveCombo({ activeCombo: selectedCombo as ProgramCombo })
		}
		ComboEvents.setShowEditComboDialog(true)
	}

	const handleDownloadCsvFile = () => {
		if (!isLoading && !showErrorCombos && combos?.length > 0) {
			downloadCombos()
		}
	}

	const isDisabledDownloadButton = useMemo(
		() => isLoading || showErrorCombos || combos.length <= 0,
		[isLoading, showErrorCombos, combos],
	)

	return (
		<ComboUnpublishedTabView
			isLoading={isLoading}
			onRemoveCombo={handleRemoveCombo}
			onRemoveMultipleCombos={handleRemoveMultipleCombos}
			onClickDownloadCsv={handleDownloadCsvFile}
			onEditCombo={handleEditCombo}
			onEditMultiplesRedeemables={handleEditMultipleCombo}
			isDisabledDownloadButton={isDisabledDownloadButton}
			isEnabledEditAndRemoveMultiplesCombos={isEnabledEditAndRemoveMultiplesCombos}
			pageSize={pageSize}
			showError={showErrorCombos}
			combos={combos}
		/>
	)
}

export default ComboUnpublishedTab
