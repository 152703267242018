import { useMemo } from 'react'
import { RouteInstance, RouteListItem } from '@/domains/route/RouteInstance'
import { useLocation } from 'react-router-dom'
import { matchRoutePath } from '@/utils/routesUtil'

export interface ActiveRouteResult {
	activeRouteInstance?: RouteInstance
	activeRouteListItem?: RouteListItem
}

const useActiveRoute = (authorizedRoutes: Array<RouteInstance>): ActiveRouteResult => {
	const location = useLocation()

	const activeRouteInstance = useMemo<RouteInstance | undefined>(
		() => authorizedRoutes.find((route) => route.isActive(location.pathname)),
		[authorizedRoutes, location.pathname],
	)

	const activeRouteListItem = useMemo<RouteListItem | undefined>(
		() =>
			activeRouteInstance?.routeList.find((route) => {
				const match = matchRoutePath(location.pathname, route)
				return !!match?.isExact
			}),
		[activeRouteInstance?.routeList, location.pathname],
	)

	return {
		activeRouteInstance,
		activeRouteListItem,
	}
}

export default useActiveRoute
