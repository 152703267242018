import { makeStyles, Theme } from '@material-ui/core/styles'

const circleBg = 'black'
const borderColor = '#b7b7b7'
const line = `solid 1px ${borderColor}`

export type StyleParams = {
	circleSize: number
	numberSize: number
	labelSize: number
	subLabelSize: number
}

const styles = makeStyles<Theme, StyleParams>(() => ({
	step: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
		'&:not(:first-child)::before': {
			top: ({ circleSize }): string => `${circleSize / 2}px`,
			left: 0,
			width: '50%',
			content: '""',
			position: 'absolute',
			borderBottom: line,
		},
		'&:not(:last-child)::after': {
			top: ({ circleSize }): string => `${circleSize / 2}px`,
			left: '50%',
			width: '50%',
			content: '""',
			position: 'absolute',
			borderBottom: line,
		},
	},
	circle: {
		width: ({ circleSize }): string => `${circleSize}px`,
		height: ({ circleSize }): string => `${circleSize}px`,
		backgroundColor: 'white',
		color: borderColor,
		border: `solid 1px ${borderColor}`,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
	},
	done: {
		backgroundColor: circleBg,
		color: 'white',
	},
	current: {
		border: `solid 1px ${circleBg}`,
		color: 'black',
	},
	number: {
		fontFamily: 'Barlow',
		fontSize: ({ numberSize }): string => `${numberSize}px`,
		fontWeight: 600,
		letterSpacing: 0,
		lineHeight: '20px',
		textAlign: 'center',
	},
	label: {
		paddingTop: '20px',
		paddingBottom: '8px',
		fontSize: ({ labelSize }): string => `${labelSize}px`,
		color: 'black',
		fontFamily: 'Barlow',
		fontWeight: 600,
		letterSpacing: 0,
	},
	sublabel: {
		fontSize: ({ subLabelSize }): string => `${subLabelSize}px`,
		color: 'rgba(0,0,0,0.9)',
		fontFamily: 'Work Sans',
		letterSpacing: '0.1px',
		lineHeight: '20px',
	},
}))

export default styles
