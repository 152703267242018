import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

export const useAnalyticsServiceEventPageViewed = (): {
	sendAnalyticsServiceEventPageViewed: (screenName: string) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceEventPageViewed = useCallback(
		(screenName: string) => {
			AnalyticsService.events.pageViewed({
				screen_name: screenName,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceEventPageViewed }
}
