import { createStyles, makeStyles } from '@material-ui/core/styles'

const criticalPricePerPointStyle = makeStyles(() =>
	createStyles({
		container: {
			'& [role="dialog"]': {
				width: '80%',
				maxWidth: '420px',
			},

			'& > [role="dialog"] > div:first-child': {
				marginBottom: '8px',
			},
		},
		alert: {
			display: 'flex',
			width: 'auto',
			marginTop: 'var(--space-2)',
		},
		description: {
			color: 'var(--colors-interfaceLabelPrimary)',
			fontSize: '16px',
			fontWeight: 400,
			lineHeight: 'var(--lineHeights-5)',
		},
		buttonsContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: '16px',
		},
		contentContainer: {
			display: 'flex',
			backgroundColor: 'var(--colors-brandAccentNeutralBackground)',
			borderRadius: '8px',
			margin: '16px 0px',
			width: 'inherit',
			height: '64px',
			justifyContent: 'center',
			gap: '20px',
		},
		divider: {
			width: '2px',
			height: '50%',
			alignSelf: 'center',
		},
		pricePerPointContainer: {
			position: 'relative',
			width: '160px',
			margin: '12px 0px',
			'& > p': {
				textAlign: 'center',
				fontSize: '14px',
				fontWeight: 500,
				letterSpacing: '0.1px',
			},
		},
	}),
)

export default criticalPricePerPointStyle
