import { ComboFormData } from '@/stores/combo/ComboState'
import { ComboFormType } from 'domains/combo/form/ComboFormType'
import Program from 'domains/program/Program'

const comboFormValuesToProgram = (programId: string, values: ComboFormType | ComboFormData): Program => {
	return {
		id: programId,
		combos: values.combos!.map((combo) => ({ comboId: combo.comboId || '', points: combo.points ?? 0 })),
	} as Program
}

export default comboFormValuesToProgram
