export const createIdMapFromArray = <TValue>(key: keyof TValue, list: Array<TValue>): Record<string, TValue> => {
	const first = {} as Record<string, TValue>
	return list.reduce(
		(prevMap, item) => ({
			...prevMap,
			[`${item[key]}`]: item,
		}),
		first,
	)
}
