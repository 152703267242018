import SkuForm from '@/domains/sku/SkuForm'
import useGetUserInfo from '@/hooks/useInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { RulesEvents, RulesStore } from '@/stores'
import { ValidateEarningRuleSkuUseCase } from '@/usecase'
import { useStoreMap } from 'effector-react'
import React, { useState } from 'react'
import StepSingleSkuComponentView from './StepSingleSkuComponentView'

const StepSingleSkuComponent: React.FC = () => {
	const skuItem = useStoreMap({ store: RulesStore, keys: [], fn: (state) => state.singleSku })
	const userInfo = useGetUserInfo()
	const [itemNotFound, setItemNotFound] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [searchText, setSearchText] = useState<string | undefined>()

	const handleSearch = async () => {
		const skuId = searchText

		AnalyticsService.events.tableSearched({
			search_query: skuId,
			content_type: 'SKU ID',
			table_name: 'Earning Rules - SKUs - Single',
			screen_name: 'Earning Rules - SKUs - Edit - Add - Single - Step 3',
			...userInfo,
		})

		setIsLoading(true)

		if (skuId && skuId !== '') {
			const result = await ValidateEarningRuleSkuUseCase.execute(skuId)
			if (result) {
				setItemNotFound(false)
				RulesEvents.addSingleSku(result.sku)
				setIsLoading(false)
				return
			}
			setItemNotFound(true)
			RulesEvents.addSingleSku({} as SkuForm)
			setIsLoading(false)
			return
		}
		RulesEvents.addSingleSku({} as SkuForm)
		setIsLoading(false)
	}

	const handleClear = () => {
		RulesEvents.addSingleSku({} as SkuForm)
		setItemNotFound(false)
	}

	return (
		<StepSingleSkuComponentView
			handleSearch={handleSearch}
			handleClear={handleClear}
			skuItem={skuItem}
			itemNotFound={itemNotFound}
			isLoading={isLoading}
			setSearchText={setSearchText}
		/>
	)
}

export default StepSingleSkuComponent
