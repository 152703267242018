import { createEvent } from 'effector'
import Rule from '@/domains/rule/Rule'
import SkuForm from '@/domains/sku/SkuForm'
import { EditSKUStepData } from '@/domains/sku/SkuStep'

export const loadRules = createEvent<{ programId: string; rules: Rule[] }>('loadRules')
export const setRuleId = createEvent<string>('setRuleId')
export const setCategoryId = createEvent<string>('setCategoryId')
export const setSku = createEvent<{ sku: SkuForm | null; rowIndexToDelete?: number }>('setSkuId')

//* Change Rule Modal */
export const showChangeRuleDialog = createEvent('showChangeRuleDialog')
export const hideChangeRuleDialog = createEvent('hideChangeRuleDialog')

//* Remove SKU Modal */
export const showRemoveSkuDialog = createEvent('showRemoveSkuDialog')
export const hideRemoveSkuDialog = createEvent('hideRemoveSkuDialog')

//* Add Sku Rule Dialog */
export const showAddSkuRuleDialog = createEvent('showAddSkuRuleDialog')
export const hideAddSkuRuleDialog = createEvent('hideAddSkuRuleDialog')

export const addSku = createEvent<SkuForm>('addSku')
export const removeSku = createEvent<string>('removeSku')
export const setActiveSkuList = createEvent<Array<SkuForm>>('setActiveSkuList')
export const setPropInEditSkuStepData = createEvent<Partial<EditSKUStepData>>('setPropInEditSkuStepData')
export const setSkuUploadFile = createEvent<FileList | undefined>('setSkuUploadFile')
export const addSingleSku = createEvent<SkuForm>('addSingleSku')

export const resetRulesStore = createEvent('reset')
