import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { BASE_ROUTE } from 'utils/constants'
import ProgramConfiguration from './components/ProgramConfigurationForm/ProgramConfiguration'

const ProgramConfigurationRoute: React.FC = () => {
	return (
		<Switch>
			<Route exact path={`${BASE_ROUTE}/program`}>
				<ProgramConfiguration />
			</Route>
		</Switch>
	)
}

export default ProgramConfigurationRoute
