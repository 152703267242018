import React from 'react'
import { useParams } from 'react-router-dom'
import RuleDescriptionView from './RuleDescriptionView'
import { useRule } from '../../../../hooks/useRule'

const RuleDescription = () => {
	const { ruleId } = useParams<{ ruleId: string }>()
	const rule = useRule(ruleId)

	return (
		<RuleDescriptionView
			name={rule?.name}
			amount={rule?.currencyAmount}
			points={rule?.points}
			description={rule?.description}
			rule={rule}
		/>
	)
}

export default RuleDescription
