import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { CHALLENGE_BASE_ROUTE } from '@/utils/constants'
import { Paper, PageLevelNotification } from '@/components/core'
import { Typography } from '@material-ui/core'
import { ChallengePageLevelNotification } from '@/stores/challengeCreation'
import { useTranslation } from 'react-i18next'
import useStyles from './FormCardStyle'

export type FormCardProps = {
	children: ReactNode
	pageLevelNotification: ChallengePageLevelNotification
}

const FormCardView: React.FC<FormCardProps> = ({ children, pageLevelNotification }) => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<Paper>
			{(pageLevelNotification.type === 'error' || pageLevelNotification.type === 'previousStep') && (
				<PageLevelNotification type="error">
					<Typography component="span" className={classes.pageLevelErrorMessage} id="page-level-error-message">
						{pageLevelNotification.message}
					</Typography>
					{pageLevelNotification.type === 'previousStep' && (
						<Link
							to={`${CHALLENGE_BASE_ROUTE}/setup`}
							className={classes.pageLevelErrorLink}
							id="page-level-error-link"
						>
							{t(`STEP_1`)}
						</Link>
					)}
				</PageLevelNotification>
			)}
			{children}
		</Paper>
	)
}

export default React.memo(FormCardView)
