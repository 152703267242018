import * as colors from '@material-ui/core/colors'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'

export const appColors = {
	white: '#FFFFFF',
	black: '#000000',
	gray: {
		light: '#D8D8D8',
		main: '#C0C3C8',
		dark: '#707372',
	},
}

const palette: PaletteOptions = {
	type: 'light',
	primary: {
		main: appColors.black,
	},
	secondary: {
		main: '#FFFF00',
	},
	info: {
		main: '#4D6EDB',
		light: '#F0ECFC',
	},
	error: {
		dark: '#E02B27',
		main: colors.red[600],
		light: '#FBE9E9',
	},
	text: {
		primary: appColors.black,
		secondary: appColors.gray.dark,
		disabled: appColors.gray.dark,
	},
	background: {
		default: '#FAFAFA',
		paper: appColors.white,
	},
	action: {
		active: appColors.black,
	},
	common: {
		white: appColors.white,
		black: appColors.black,
	},
	divider: appColors.gray.main,
	success: {
		dark: '#006400',
		main: colors.green[600],
		light: '#E6F2EB',
	},
}

export default palette
