import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const FormCardStyle = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			whiteSpace: 'nowrap',
			lineHeight: '24px',
			paddingBottom: '8px',
		},
		pageLevelErrorMessage: {
			fontSize: '14px',
			lineHeight: '20px',
			position: 'relative',
			paddingLeft: '4px',
		},
		pageLevelErrorLink: {
			fontSize: '14px',
			fontWeight: 500,
			letterSpacing: 0,
			lineHeight: '20px',
			paddingLeft: '4px',
			color: theme.palette.info.main,
			cursor: 'pointer',
			textDecoration: 'underline',
			position: 'relative',
			'&:hover': {
				filter: 'brightness(60%)',
			},
		},
	}),
)

export default FormCardStyle
