import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			flexGrow: 1,
			padding: theme.spacing(4),
			marginLeft: 0,
			'& #page-environment-info ~ div .breadcrumb-link, & #page-environment-info ~ .breadcrumb-link': {
				marginTop: 'var(--space-4)',
			},
		},
		contentNewLayout: {
			paddingLeft: '6.5rem',
			paddingTop: '0px',
			marginTop: 'calc(var(--space-2) * -1)',
		},
	}),
)

export default styles
