import { Group } from '@/domains'
import AccountGroup, { AccountGroupCSVList, AccountGroupFormData, AccountGroupParsedItem } from '@/domains/AccountGroup'
import { GroupPurpose } from '@/domains/enums'
import { hasElements } from '@/utils/array'
import { hasText } from '@/utils/string'
import { ErrorOption } from 'react-hook-form'

export const convertToAccountsData = (data: AccountGroupCSVList): Array<AccountGroupParsedItem> => {
	// @TO-DO put logic here to check money or quantity
	const keys: string[] = []
	let isMoney = false
	Object.keys(data[0]).forEach((key: string) => {
		if (key.toUpperCase() === 'POC_ID') {
			keys[0] = key
		}
		if (key.toUpperCase() === 'POINTS') {
			keys[1] = key
		}
		if (key.toUpperCase() === 'QUANTITY') {
			keys[2] = key
		}
		if (key.toUpperCase() === 'MONEY') {
			keys[2] = key
			isMoney = true
		}
	})

	const [POC_ID, POINTS, QuantityOrMoney] = keys
	const accounts = data.map((item) => {
		const itemMap: Record<string, string> = {
			...item,
		}
		const account = {
			accountId: itemMap[POC_ID],
			points: itemMap[POINTS],
		}
		Object.assign(
			account,
			isMoney ? { currencyMin: itemMap[QuantityOrMoney] } : { quantityMin: itemMap[QuantityOrMoney] },
		)

		return account
	})
	return accounts
}

export const removeEmptyColumns = (fields: Array<string> | undefined): Array<string> => {
	if (!fields) {
		return []
	}
	return fields.filter((text) => !!text)
}

const toUpperCaseColumns = (fields: Array<string>): Array<string> => fields.map((field) => field.toUpperCase())

const verifyFileIndividualTarget = (fields: Array<string>): boolean => {
	if (fields.length !== 3) {
		return false
	}
	// @TO-DO put here the logic to verify if is quantity or currency
	const [pocId, quantityOrMoney, points] = fields
	return pocId === 'POC_ID' && points === 'POINTS' && (quantityOrMoney === 'QUANTITY' || quantityOrMoney === 'MONEY')
}

const verifyFileGeneral = (fields: Array<string>): boolean => {
	if (fields.length !== 1) {
		return false
	}
	const [pocId] = fields
	return pocId === 'POC_ID'
}

export const isFieldsValid = (fieldsParam?: Array<string>, isIndividualTarget?: boolean): boolean => {
	const fields = toUpperCaseColumns(removeEmptyColumns(fieldsParam))
	if (isIndividualTarget) {
		return verifyFileIndividualTarget(fields)
	}
	return verifyFileGeneral(fields)
}

export const createAccountGroupData = (formData: AccountGroupFormData): AccountGroup => ({
	groupName: formData.groupName,
	purpose: GroupPurpose.ChallengeFilter,
	file: (formData.file as unknown as Array<File>)[0],
})

export const isAccountsDataValid = (accounts: Array<AccountGroupParsedItem>, isIndividualTarget?: boolean): boolean => {
	if (!hasElements(accounts)) {
		return false
	}
	return accounts.every((item) => {
		if (!hasText(item.accountId)) {
			return false
		}
		if (isIndividualTarget) {
			return hasText(item.points) && (hasText(item.quantityMin) || hasText(item.currencyMin))
		}
		return true
	})
}

export const getGoalBased = (fieldsParam?: Array<string>): string | undefined => {
	const fields = toUpperCaseColumns(removeEmptyColumns(fieldsParam))
	return fields.find((value) => value === 'QUANTITY' || value === 'MONEY')
}

export const verifyCsvBased = (groupOptionsMap: Record<string, Group>, fieldsParam?: Array<string>): boolean => {
	const fields = toUpperCaseColumns(removeEmptyColumns(fieldsParam))
	if (Object.values(groupOptionsMap)[0]) {
		const { goal } = Object.values(groupOptionsMap)[0]
		if (goal) return fields.includes(goal)
	}
	return true
}

type errorFileProps = {
	err: string
	setError: (
		name: string,
		error: ErrorOption,
		options?:
			| {
					shouldFocus: boolean
			  }
			| undefined,
	) => void
	errorMessageMap: {
		INVALID_GOAL: string
		INVALID_INDIVIDUAL_TARGET: string
		INVALID_FILE: string
	}
	isIndividualTarget?: boolean
}

export const setErrorFile = ({ err, setError, errorMessageMap, isIndividualTarget }: errorFileProps): void => {
	if (err === 'Invalid Goal') {
		setError('file', {
			type: 'manual',
			message: errorMessageMap.INVALID_GOAL,
		})
		return
	}
	if (isIndividualTarget) {
		setError('file', { type: 'manual', message: errorMessageMap.INVALID_INDIVIDUAL_TARGET })
		return
	}

	if (!isIndividualTarget) {
		setError('file', { type: 'manual', message: errorMessageMap.INVALID_FILE })
	}
}
