import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { getChallengeFilterName } from '@/lists/analytics/AnalyticsChallengeFilterList'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ChallengeFilterFormData } from '@/stores/challengeCreation'
import { InputChip } from '@/components/form'
import { ChallengeFormFilterOption } from '@/domains'
import styles from './FilterBoxStyle'
import useChallengeSetupFormDataId from '../../hooks/useChallengeSetupFormDataId'

type Props = {
	index: number
	defaultValue: ChallengeFormFilterOption
	disabled: boolean
	isRemoveLinkDisabled: boolean
}

const FilterBoxFilterValueInput: React.FC<Props> = ({ index, defaultValue, disabled, isRemoveLinkDisabled }: Props) => {
	const challengeId = useChallengeSetupFormDataId()
	const { t } = useTranslation()
	const classes = styles()

	const { control, watch, setValue } = useFormContext<ChallengeFilterFormData>()
	const selectedFilter = watch(`filters.${index}.filter` as const)

	const handleInputChipAdd = (chip: string, chipArray: Array<string>): void => {
		AnalyticsService.events.filterValueAdded({
			challenge_id: challengeId,
			challenge_filter_name: getChallengeFilterName(selectedFilter),
			value_added: chip,
		})
		setValue(`filters.${index}.value` as const, [...chipArray, chip], { shouldDirty: true })
	}

	const handleInputChipDelete = (chipIndex: number, chipArray: Array<string>): void => {
		const newChipArray = chipArray.filter((_, currentChipIndex: number) => {
			return currentChipIndex !== chipIndex
		})
		setValue(`filters.${index}.value` as const, newChipArray, { shouldDirty: true })
	}

	return (
		<Controller
			name={`filters.${index}.value` as const}
			control={control}
			defaultValue={defaultValue.value}
			render={({ field: { value } }) => (
				<InputChip
					id={`filter-values-${index}`}
					label={t('challenge:FILTERS_STEP.FILTER_VALUES')}
					helperText={t('challenge:FILTERS_STEP.FILTER_INSTRUCTION')}
					blurBehavior="add"
					disabled={disabled || isRemoveLinkDisabled}
					className={classes.input}
					onAdd={(chip) => handleInputChipAdd(chip, value)}
					onDelete={(_, chipIndex) => handleInputChipDelete(chipIndex, value)}
					value={value}
				/>
			)}
		/>
	)
}

export default FilterBoxFilterValueInput
