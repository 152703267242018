import InputText, { InputTextProps } from '@/components/form/InputText'
import { Input } from '@/components/form/InputText/redesignInput'
import { hasText } from '@/utils/string'
import { CircularProgress } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const VALIDATION_DELAY_MS = 600

type ValidationHandler = (value: string) => Promise<void>

type Props = InputTextProps & {
	onValidating: ValidationHandler
	delayMs?: number
	name: string
	onLoading?: (loading: boolean) => void
	defaultValue?: string
	isNewDSM?: boolean
	placeholder?: string
}

const InputTextWithDelayedValidation: React.FC<Props> = ({
	onValidating,
	onLoading,
	name,
	delayMs = VALIDATION_DELAY_MS,
	isNewDSM,
	...props
}: Props) => {
	const { control, trigger, setValue } = useFormContext()
	const timeoutRef = useRef<number>()
	const [validating, setValidating] = useState<boolean>(false)
	const eventHandlersMemoizedRef = useRef({
		loadingHandler: onLoading,
		validatingHandler: onValidating,
	})
	const mostRecentValueRef = useRef<string>(props.defaultValue ?? '')

	useEffect(() => {
		eventHandlersMemoizedRef.current = {
			loadingHandler: onLoading,
			validatingHandler: onValidating,
		}
	})

	const setLoading = useCallback((flag: boolean) => {
		const { loadingHandler } = eventHandlersMemoizedRef.current
		setValidating(flag)
		if (loadingHandler) {
			loadingHandler(flag)
		}
	}, [])

	const getValue = useCallback((): string => mostRecentValueRef.current, [])

	const triggerValidation = useCallback(async () => {
		const { validatingHandler } = eventHandlersMemoizedRef.current
		const value = getValue()
		if (hasText(value)) {
			await validatingHandler(value)
		}
	}, [getValue])

	const applyRecentValue = useCallback(async () => {
		setValue(name, getValue(), { shouldValidate: false })
	}, [getValue, name, setValue])

	const applyChanges = useCallback(
		(value: string) => {
			mostRecentValueRef.current = value.trim()
			clearTimeout(timeoutRef.current)
			setLoading(true)
			timeoutRef.current = window.setTimeout(async () => {
				await triggerValidation()
				applyRecentValue()
				setLoading(false)
			}, delayMs)
		},
		[setLoading, applyRecentValue, triggerValidation, delayMs],
	)

	const inputProps = useMemo(
		() => ({
			endAdornment: validating && <CircularProgress color="inherit" size={20} />,
		}),
		[validating],
	)

	const renderInput = ({ field: { value, onChange, ...rest }, fieldState: { error } }) => {
		const handleChange = (event, ...args) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore - The hexa does not recognizes .value from input
			applyChanges(event.target.value)
			return onChange(event, ...args)
		}

		const handleBlur = (event) => {
			if (!hasText(event.target.value)) {
				trigger(name).catch(() => undefined)
			}
		}

		return (
			<Input
				{...rest}
				id={props.id}
				label={props.label}
				errorText={error?.message}
				hasError={!!error?.message}
				disabled={props.disabled}
				value={value || ''}
				onChange={handleChange}
				onBlur={handleBlur}
				placeholder={props.placeholder}
			/>
		)
	}

	const redesignInput = useMemo(() => {
		return <Controller name={name} control={control} defaultValue={props.defaultValue ?? ''} render={renderInput} />
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		applyChanges,
		control,
		name,
		props.defaultValue,
		props.disabled,
		props.id,
		props.label,
		props.placeholder,
		trigger,
	])

	const defaultInput = useMemo(() => {
		return (
			<Controller
				name={name}
				control={control}
				defaultValue={props.defaultValue ?? ''}
				render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => {
					return (
						<InputText
							{...rest}
							id={props.id}
							label={props.label}
							errorText={error?.message}
							disabled={props.disabled}
							value={value || ''}
							onChange={(event, ...args) => {
								applyChanges(event.target.value)
								return onChange(event, ...args)
							}}
							InputProps={inputProps}
							onBlur={(event) => {
								if (!hasText(event.target.value)) {
									trigger(name)
								}
							}}
						/>
					)
				}}
			/>
		)
	}, [applyChanges, control, inputProps, name, props.defaultValue, props.disabled, props.id, props.label, trigger])

	return <>{isNewDSM ? redesignInput : defaultInput}</>
}

export default InputTextWithDelayedValidation
