import useCurrentVendor from '@/hooks/vendor/useCurrentVendor'
import { useCurrentZoneName } from '@/hooks/zone/useCurrentZoneName'
import { Chip } from '@hexa-ui/components'
import React from 'react'

import SelectedZoneAndVendorChipsStyle from './SelectedZoneAndVendorChipsStyle'

export const SelectedZoneAndVendorChips = () => {
	const selectedZoneName = useCurrentZoneName()
	const currentVendor = useCurrentVendor()

	const css = SelectedZoneAndVendorChipsStyle()

	return (
		<div className={css.chipsWrapper} data-testid="selected-zone-vendor-chips">
			<ZVChip label={selectedZoneName} />
			<ZVChip label={currentVendor?.vendorName} />
		</div>
	)
}

const ZVChip = ({ label }: { label?: string | null }) => {
	if (!label) {
		return null
	}

	return (
		<Chip.Root selected>
			<Chip.Label size="small">{label}</Chip.Label>
		</Chip.Root>
	)
}
