import { RewardsProgram, ScreenName } from '@/domains'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { PageLevelEvents, RewardsPermissionEvents, RewardsPermissionStore } from '@/stores'
import { UpdateRewardsPermissionUseCase } from '@/usecase'
import { useStoreMap } from 'effector-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useSendAuditLogRewardsPermission from '../../hooks/useSendAuditLogRewardsPermission'
import EditRewardsPermissionView from './EditRewardsPermissionView'

const EditRewardsPermissionDialog: React.FC = () => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const { sendAuditLogRewardsPermission } = useSendAuditLogRewardsPermission()
	const { updateDialog, rewardsData } = useStoreMap({
		store: RewardsPermissionStore,
		keys: [],
		fn: (state) => ({
			updateDialog: state.updateDialog,
			rewardsData: state.rewardsData,
		}),
	})

	const handleCancel = () => {
		RewardsPermissionEvents.setUpdateDialog({ ...updateDialog, isShowing: false })
	}

	const findIndexOfRewardsPermission = (accountId: string | undefined) => {
		if (!accountId) {
			return -1
		}
		return rewardsData.findIndex((rewardsPerm) => rewardsPerm.accountId === accountId)
	}

	const updateRewardsDataItem = (rewardsDataIndex: number, rewardsPerm: RewardsProgram) => {
		const data = [...rewardsData.slice(0, rewardsDataIndex), rewardsPerm, ...rewardsData.slice(rewardsDataIndex + 1)]
		RewardsPermissionEvents.setRewardsData(data)
	}

	const handleSubmit = () => {
		UpdateRewardsPermissionUseCase.execute(updateDialog.accountId!, updateDialog.enablePermission!)
			.then(() => {
				PageLevelEvents.showNotification({
					notificationType: 'success',
					message: t('permission:NOTIFICATION.EDIT.SUCCESS'),
				})

				const index = findIndexOfRewardsPermission(updateDialog.accountId)
				if (index !== -1) {
					const prevValue = rewardsData[index]
					const rewardsPerm = {
						...prevValue,
						permission: {
							...prevValue.permission,
							enabled: !!updateDialog.enablePermission,
							updatedAt: new Date().toISOString(),
						},
					}
					updateRewardsDataItem(index, rewardsPerm)
				}

				AnalyticsService.events.checkboxInteraction({
					checkbox_label: 'Permission',
					checkbox_name: 'Permission',
					is_checked: updateDialog.enablePermission! ? 'True' : 'False',
					screen_name: ScreenName.RewardsPermission,
					...userInfo,
				})
				sendAuditLogRewardsPermission(updateDialog.accountId!, updateDialog.enablePermission!)
			})
			.catch(() => {
				PageLevelEvents.showNotification({
					notificationType: 'error',
					message: t('permission:NOTIFICATION.EDIT.ERROR'),
				})
			})
			.finally(() => {
				RewardsPermissionEvents.setUpdateDialog({ ...updateDialog, isShowing: false })
			})
	}

	return (
		<EditRewardsPermissionView
			showEditDialog={updateDialog.isShowing}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
			enabled={updateDialog.enablePermission!}
		/>
	)
}

export default EditRewardsPermissionDialog
