import ChallengeFormSetupInputs from '@/routes/challenge/componentsRedesign/ChallengeFormSetup/ChallengeFormSetupInputs/ChallengeFormSetupInputs'
import { ChallengeEvents } from '@/stores/challengeCreation'
import { Tabs } from '@hexa-ui/components'
import { AlertOctagon } from '@hexa-ui/icons'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
	handleChangeFile: (event: React.ChangeEvent<HTMLInputElement>, field: string) => void
	handleRemoveImage: (field: string, files: File) => void
	supportedLanguages: string[]
	isTakePhotoExecutionMethod?: boolean
}

const TabsChallenge = ({
	handleChangeFile,
	handleRemoveImage,
	supportedLanguages,
	isTakePhotoExecutionMethod,
}: Props) => {
	const { t } = useTranslation()
	const { watch, getValues } = useFormContext()

	const [activeTab, setActiveTab] = useState(`tab-${supportedLanguages[0]}`)

	useEffect(() => {
		watch((value) => {
			const result = supportedLanguages.filter((lang) => value[`title-${lang}`] || value[`description-${lang}`])
			ChallengeEvents.setOptionalLanguagesUsed(result)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch])

	const verifyFormIncomplete = (language: string) => {
		const title = !!getValues(`title-${language}`)
		const description = !!getValues(`description-${language}`)
		if ((!title && description) || (title && !description)) {
			return true
		}
		return false
	}

	return (
		<Tabs.Root defaultValue={`tab-${supportedLanguages[0]}`}>
			<Tabs.List>
				{supportedLanguages.map((language: string, index: number) => {
					const incompleteRequeridField = index > 0 && verifyFormIncomplete(language)
					const isActive = activeTab === `tab-${language}`
					const hasAttention = incompleteRequeridField && !isActive
					return (
						<Tabs.Trigger
							key={language}
							value={`tab-${language}`}
							onClick={() => setActiveTab(`tab-${language}`)}
							data-testid={`language-${language}`}
						>
							{t(`common:LANGUAGES.${language}`)} {index > 0 && t('common:OPTIONAL')}
							{hasAttention && <AlertOctagon size="large" style={{ color: 'red', marginLeft: '4px' }} />}
						</Tabs.Trigger>
					)
				})}
			</Tabs.List>
			{supportedLanguages.map((language, index) => (
				<Tabs.Content key={language} value={`tab-${language}`} style={{ padding: '0' }}>
					<ChallengeFormSetupInputs
						handleChangeFile={handleChangeFile}
						handleRemoveImage={handleRemoveImage}
						isTakePhotoExecutionMethod={isTakePhotoExecutionMethod}
						index={index}
						language={language}
					/>
				</Tabs.Content>
			))}
		</Tabs.Root>
	)
}

export default TabsChallenge
