import { createStyles, makeStyles } from '@material-ui/core/styles'

const failedPocsDialogStyle = makeStyles(() =>
	createStyles({
		dialog: {
			maxWidth: '800px',
			margin: 'auto auto',
		},
		infoContainer: {
			marginTop: '16px',
			marginBottom: '24px',
			'& svg': {
				marginTop: '2px',
			},
		},
		infoText: {
			fontSize: '14px',
			margin: '0px',
			lineHeight: '20px',
			position: 'relative',
			padding: '0px 12px 0px 8px',
		},
	}),
)

export default failedPocsDialogStyle
