import { ComboStore, SettingsConfigurationStore } from '@/stores'
import { useStoreMap } from 'effector-react'

const useSettingsData = () => {
	const { programData } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			programData: state.programData,
		}),
	})

	const { settingsData } = useStoreMap({
		store: SettingsConfigurationStore,
		keys: [],
		fn: (state) => ({
			settingsData: state.settingsData,
		}),
	})

	return { programData, settingsData }
}

export default useSettingsData
