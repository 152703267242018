import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { FormHelperText } from '@material-ui/core'

import FieldErrorMessage from '@/components/form/FieldErrorMessage'
import InputLabel from '@/components/form/InputLabel'
import styles from './styles'

export type InputContainerProps = {
	id: string
	label?: string
	optionalLabel?: string
	helperText?: string
	errorText?: string
	disabled?: boolean
	className?: string
	disableGutters?: boolean
	disableTopGutter?: boolean
	infoIconTooltip?: boolean
	customMessageTooltip?: string
	maxLength?: number
	valueLength?: number
	hintText?: string
}

type InputContainerElementProps = InputContainerProps & {
	children: ReactNode
}

const InputContainer: React.FC<InputContainerElementProps> = ({
	id,
	label,
	optionalLabel,
	errorText,
	helperText,
	disabled,
	className,
	disableGutters,
	disableTopGutter,
	children,
	infoIconTooltip,
	customMessageTooltip,
	maxLength,
	valueLength = 0,
	hintText,
}) => {
	const classes = styles({ disableGutters, disableTopGutter })

	const hasError = !!errorText

	return (
		<div className={clsx(classes.box, className)} style={{ width: '100%' }}>
			{label && (
				<InputLabel
					label={label}
					htmlFor={id}
					error={hasError}
					disabled={disabled}
					optionalLabel={optionalLabel}
					infoIconTooltip={infoIconTooltip}
					customMessageTooltip={customMessageTooltip}
					bottomSpacing
				/>
			)}
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{hasError && <FieldErrorMessage id={id} message={errorText} />}
			{hintText && <FormHelperText>{hintText}</FormHelperText>}
			{children}
			{maxLength && (
				<FormHelperText
					id="counter-character"
					style={{ textAlign: 'right', paddingTop: '2px' }}
				>{`${valueLength}/${maxLength}`}</FormHelperText>
			)}
		</div>
	)
}

export default InputContainer
