import PointsTransferResponse from 'domains/transaction/PointsTransferTransactionResponse'
import * as TransactionService from 'services/transaction/TransactionService'

async function execute(
	fromAccountId: string,
	toAccountId: string,
	description?: string,
): Promise<PointsTransferResponse[]> {
	return TransactionService.pointsTransfer(fromAccountId, toAccountId, description)
}

export { execute }
