import { TransactionStore } from '@/stores'
import { useStore } from 'effector-react'
import { useCallback } from 'react'
import { isToggleEnabled } from '@/hooks'
import * as FeatureToggle from 'utils/featureToggle'

export type FormValuesMap = Record<string, string | undefined>

export type TransactionFormTranslateAccountIdsResult = (formValues: FormValuesMap) => FormValuesMap

const idKeys = ['pocId', 'pocIdFrom', 'pocIdTo']

const useTransactionFormTranslateAccountIds = (): TransactionFormTranslateAccountIdsResult => {
	const { accountIdTranslationMap } = useStore(TransactionStore)

	const translateAccountIds = useCallback(
		(formValues: FormValuesMap): FormValuesMap => {
			const formValuesCopy = { ...formValues }
			if (isToggleEnabled(FeatureToggle.DEV_MULTIVENDOR_VALIDATE_ACCOUNT)) {
				idKeys.forEach((key: string) => {
					const id = formValuesCopy[key]
					if (id) {
						formValuesCopy[key] = accountIdTranslationMap[id]
					}
				})
			}
			return formValuesCopy
		},
		[accountIdTranslationMap],
	)

	return translateAccountIds
}

export default useTransactionFormTranslateAccountIds
