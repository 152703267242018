import { usePermissionService } from 'admin-portal-shared-services'
import MembershipFeatures, { features } from 'domains/authorization/MembershipFeatures'
import PermissionLevel from 'domains/enums/authorization/PermissionLevelEnum'
import { useCallback, useMemo } from 'react'

const SEPARATOR = '.'
const SCOPE_PREFIX = 'Membership'

export type Permission = {
	feature: MembershipFeatures
	permissionLevel: PermissionLevel
}

const stringToPermissionLevelValue = (permissionLevel: string) => {
	switch (permissionLevel) {
		case 'Read':
			return PermissionLevel.Read.valueOf()
		case 'Write':
			return PermissionLevel.Write.valueOf()
		case 'Delete':
			return PermissionLevel.Delete.valueOf()
		default:
			return -1
	}
}

const parseScopes = (scopes: string[]) => {
	const userPermissions: Array<{ feature: MembershipFeatures; permissionLevel: number }> = []
	scopes.forEach((scope) => {
		const [prefix, feature, permissionLevel] = scope.split(SEPARATOR)
		const isMembership = prefix === SCOPE_PREFIX
		const isValidFeature = Object.keys(features).includes(feature)
		const isValidPermissionLevel = Object.keys(PermissionLevel).includes(permissionLevel)

		if (isMembership && isValidFeature && isValidPermissionLevel) {
			userPermissions.push({
				feature: feature as MembershipFeatures,
				permissionLevel: stringToPermissionLevelValue(permissionLevel),
			})
		}
	})
	return userPermissions
}

type AuthorizationHook = {
	isFeatureAuthorized: (featureToggle: boolean, requiredPermission?: Permission) => boolean
}

const useAuthorization = (): AuthorizationHook => {
	const { getScopesFromJwt } = usePermissionService()
	const userScopes = getScopesFromJwt()
	const userPermissions = useMemo(() => parseScopes(userScopes), [userScopes])

	const isFeatureAuthorized = useCallback(
		(
			featureToggle: boolean,
			requiredPermission: Permission = { feature: 'All', permissionLevel: PermissionLevel.Delete },
		): boolean => {
			if (!featureToggle) {
				return false
			}
			const permissionFound = userPermissions.find((userPermission) => {
				const isValidFeature = userPermission.feature === requiredPermission.feature || userPermission.feature === 'All'
				return isValidFeature && userPermission.permissionLevel >= requiredPermission.permissionLevel
			})
			return !!permissionFound
		},
		[userPermissions],
	)

	return {
		isFeatureAuthorized,
	}
}

export default useAuthorization
