import { usePricePerPointConfiguration } from '@/routes/redeemableItems/hooks/usePricePerPointConfiguration'
import { PricePerPointConfiguration } from '@/stores'
import { fieldCurrencyValidationRequired } from '@/utils/validationObjects'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const pricePerPointSchema = Yup.object().shape({
	pricePerPoint: fieldCurrencyValidationRequired(),
})

const PricePerPointEditorFormProvider: React.FC = ({ children }) => {
	const { data } = usePricePerPointConfiguration()

	const formMethods = useForm<PricePerPointConfiguration>({
		resolver: yupResolver(pricePerPointSchema),
		mode: 'onChange',
		shouldFocusError: true,
		defaultValues: {
			pricePerPoint: data?.pricePerPoint ?? undefined,
		},
	})

	return (
		<FormProvider {...formMethods}>
			<form>{children}</form>
		</FormProvider>
	)
}

export default PricePerPointEditorFormProvider
