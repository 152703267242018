import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react'

type RewardsTypeSelectedContext = {
	rewardSelected: string
	setRewardSelected: React.Dispatch<React.SetStateAction<string>>
}

const RewardsTypeSelectedContext = createContext<RewardsTypeSelectedContext | undefined>(undefined)

export const SelectedRewardTypeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [rewardSelected, setRewardSelected] = useState<string>('')

	const value = useMemo(() => ({ rewardSelected, setRewardSelected }), [rewardSelected, setRewardSelected])

	return <RewardsTypeSelectedContext.Provider value={value}>{children}</RewardsTypeSelectedContext.Provider>
}

export const useSelectedReward = (): RewardsTypeSelectedContext => {
	const context = useContext(RewardsTypeSelectedContext)
	if (!context) {
		throw new Error('useSelectedReward must be used within a SelectedRewardTypeProvider')
	}
	return context
}
