import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		searchBox: {
			width: '400px',
		},
	}),
)

export default styles
