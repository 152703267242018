import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { BASE_ROUTE } from '@/utils/constants'
import { hasElements } from '@/utils/array'
import { RouteInstance } from '@/domains/route/RouteInstance'

type Props = {
	authorizedRoutes: Array<RouteInstance>
}

const HOME_ROUTE_ID = 'challenge-history'

const InitialRedirect: React.FC<Props> = ({ authorizedRoutes }): JSX.Element | null => {
	const homeRoute = useMemo(() => {
		if (!hasElements<RouteInstance>(authorizedRoutes)) {
			return null
		}
		return authorizedRoutes.find((item) => item.id === HOME_ROUTE_ID) ?? authorizedRoutes[0]
	}, [authorizedRoutes])

	if (homeRoute) {
		return <Redirect exact key={`${homeRoute.id}-redirect`} from={BASE_ROUTE} to={homeRoute.to} />
	}
	return null
}

export default InitialRedirect
