import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import RemoveIcon from '../../../icons/RemoveIcon'
import styles from './styles'

type Props = {
	name: string
	onRemove: () => void
	disabled?: boolean
}

const FileUploadSpan: React.FC<Props> = ({ name, onRemove, disabled }: Props) => {
	const { t } = useTranslation()
	const classes = styles({ disabled: !!disabled })
	const theme = useTheme()

	return (
		<Grid container direction="row" className={classes.grid}>
			<Grid item component="span" className={classes.name}>
				{name}
			</Grid>
			<Grid item component="span" className={disabled ? classes.disabledRemoveButton : classes.removeButton}>
				<IconButton
					component="span"
					onClick={onRemove}
					className={classes.removeButtonIconButton}
					aria-label={t('REMOVE')}
				>
					<RemoveIcon fill={disabled ? theme.palette.text.disabled : undefined} />
				</IconButton>
			</Grid>
		</Grid>
	)
}

export default FileUploadSpan
