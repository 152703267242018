import { ChallengeStore } from '@/stores/challengeCreation'
import { useStoreMap } from 'effector-react'

export const useIsAccountGroupModalVisible = (): boolean => {
	return useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.showAccountGroupModal,
	})
}
