import React from 'react'
import { useStoreMap } from 'effector-react'
import { ChallengeStore, ChallengeEvents, ChallengeSetupFormData, Account } from '@/stores/challengeCreation'
import { ChallengeUseCase } from '@/usecase'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ButtonInteraction, ScreenName } from '@/domains'
import { BASE_ROUTE } from '@/utils/constants'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChallengeConverters } from '@/converters'
import getAccountsByIndividualTarget from '@/utils/getAccountsByIndividualTarget'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useChallengeTrackingPlan from '@/hooks/tracking/useChallengeTrackingPlan'
import { generateChallengeData } from '@/hooks/useAuditLogInstance'
import useAdminAuditLog from '@/hooks/useAdminAuditLog'
import useIndividualAccountGroup from '../../hooks/useIndividualAccountGroup'
import ChallengeSubmitDialogView from './ChallengeSubmitDialogView'

const ChallengeSubmitDialog: React.FC = () => {
	const history = useHistory()
	const userInfo = useGetUserInfo()
	const { t } = useTranslation()
	const { challengeCreatedTrackingPlan } = useChallengeTrackingPlan()
	const individualAccountGroup = useIndividualAccountGroup()
	const { auditLogInstance } = useAdminAuditLog()

	const challengeState = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state,
	})

	const { showSubmitDialog, challengeSetupFormData } = challengeState

	const handleCancel = React.useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_name: ButtonInteraction.Cancel,
			screen_name: ScreenName.CreateChallengeConfirmationModal,
			button_label: ButtonInteraction.Cancel,
			...userInfo,
		})
		ChallengeEvents.setShowSubmitDialog(false)
	}, [userInfo])

	const handleSubmit = () => {
		const challenge = ChallengeConverters.challengeStateValuesToChallenge(challengeState)
		const files = ChallengeConverters.challengeSetupFormDataToChallengeFiles(
			challengeState.challengeSetupFormData as ChallengeSetupFormData,
		)
		const accountsIndividualTarget: Account[] = getAccountsByIndividualTarget(
			individualAccountGroup,
			challenge.filter.groupIds,
		)

		const vendorId = getDefaultVendorIdOfCurrentZone()
		challenge.filter.vendorIds = [vendorId]

		ChallengeUseCase.execute({ challenge, files, accountsIndividualTarget, analyticsProps: userInfo })
			.then((response) => {
				ChallengeEvents.reset()
				ChallengeEvents.setSuccessfullySubmitForm(true)
				ChallengeEvents.showPageLevelNotification({
					type: 'success',
					message: t('challenge:CHALLENGE_CREATED_MESSAGE'),
				})

				const auditMetaData = generateChallengeData({
					formData: response!,
					individualTargetAccounts: accountsIndividualTarget,
					groupName: challengeSetupFormData?.groupName,
				})
				auditLogInstance(auditMetaData)
				challengeCreatedTrackingPlan(response?.id)
				history.push(`${BASE_ROUTE}/challenges`)
			})
			.catch(() => {
				ChallengeEvents.showPageLevelNotification({ type: 'error', message: t('ERROR_MESSAGE.CHALLENGE_SUBMIT_FAIL') })
				ChallengeEvents.setShowSubmitDialog(false)
				ChallengeEvents.setSuccessfullySubmitForm(false)
			})
	}

	return (
		<ChallengeSubmitDialogView
			showSubmitDialog={showSubmitDialog}
			handleCancel={handleCancel}
			handleSubmit={handleSubmit}
		/>
	)
}

export default ChallengeSubmitDialog
