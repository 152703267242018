import { OutlinedInput, OutlinedInputProps } from '@material-ui/core'
import React, { useRef } from 'react'
import FieldErrorMessage from 'components/formik/FieldErrorMessage'
import useStyles from './styles'
import EndAdornment from './EndAdornment'

export type SearchInputProps = {
	id: string
	name: string
	placeholder?: string
	onSearch: (text: string) => void
	isSearching: boolean
	hideIcon?: boolean
	errorText?: string
	value?: string
} & OutlinedInputProps

const SearchInput: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, SearchInputProps> = (props, ref) => {
	const { id, name, placeholder, onSearch, isSearching, errorText, onBlur, hideIcon, ...rest } = props
	const classes = useStyles()
	const prevQueryTextRef = useRef<string>()
	const hasError = !!errorText

	const callSearch = (text: string) => {
		if (text !== prevQueryTextRef.current) {
			prevQueryTextRef.current = text
			onSearch(text)
		}
	}

	const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		if (onBlur) {
			onBlur(event)
		}
		callSearch(event.target.value)
	}

	const handlePress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			callSearch(`${rest.value}`)
		}
	}

	const handleIconClick = () => {
		callSearch(`${rest.value}`)
	}

	return (
		<div className={classes.box}>
			{hasError && <FieldErrorMessage id={id} message={errorText} />}
			<OutlinedInput
				{...rest}
				id={id}
				name={name}
				placeholder={placeholder}
				fullWidth
				multiline={false}
				endAdornment={
					!hideIcon ? <EndAdornment isSearching={isSearching} id={id} onClick={handleIconClick} /> : undefined
				}
				onBlur={handleOnBlur}
				className={classes.searchInput}
				inputProps={{ className: classes.input }}
				inputRef={ref}
				error={hasError}
				onKeyPress={handlePress}
			/>
		</div>
	)
}

export default React.forwardRef(SearchInput)
