import { Button } from '@hexa-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ChallengeFormFilterStyle'

type Props = {
	onClick: () => void
	disabled: boolean
}

const ChallengeFormFilterAddButton: React.FC<Props> = ({ onClick, disabled }: Props) => {
	const { t } = useTranslation()
	const classes = styles()

	return (
		<Button
			id="add-filter-btn"
			type="button"
			variant="secondary"
			onClick={onClick}
			disabled={disabled}
			className={classes.addButton}
		>
			{t('BUTTON.ADD_FILTER')}
		</Button>
	)
}

export default ChallengeFormFilterAddButton
