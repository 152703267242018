import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Container } from '@material-ui/core'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { AcceptableTypes, FileUpload, InputText } from '@/components/form'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'

type Props = {
	challengeId?: string | null
}

const AccountGroupFormView: React.FC<Props> = ({ challengeId }: Props) => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()

	const { control, setValue, getValues, clearErrors, trigger } = useFormContext()

	const setFileValue = (fileValue: FileList | undefined) => {
		setValue('file', fileValue, { shouldValidate: true })
	}

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFileValue(event.currentTarget.files as FileList)
		const file = (event.currentTarget.files as FileList)[0]
		AnalyticsService.events.groupUploaded({
			group_name: getValues().groupName || null,
			file_name: file.name,
			file_size: file.size.toString(),
			challenge_id: challengeId,
		})
	}

	const handleOnClick = () => {
		AnalyticsService.events.buttonClicked({
			button_name: 'UPLOAD A CSV FILE',
			screen_name: 'Add Group Modal',
			button_label: null,
			...userInfo,
		})
	}

	const handleKeyDown = (e: { key: string; preventDefault: () => void }) => {
		if (e.key === 'Enter') e.preventDefault()
	}

	useEffect(() => {
		setValue('groupName', '')
		clearErrors()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Container style={{ padding: '0px' }}>
				<Controller
					control={control}
					name="groupName"
					defaultValue=""
					render={({ field, fieldState: { error } }) => (
						<InputText
							id="group-name"
							label={t('challenge:FILTERS_STEP.GROUP.GROUP_NAME')}
							placeholder={t('challenge:FILTERS_STEP.GROUP.INSERT_NAME_HERE')}
							autoFocus
							errorText={error?.message}
							onKeyDown={handleKeyDown}
							{...field}
						/>
					)}
				/>
			</Container>
			<Controller
				control={control}
				name="file"
				render={({ field: { name }, fieldState: { error } }) => (
					<FileUpload
						redesignUploadButton
						name={name}
						trigger={trigger}
						id="upload-csv-button"
						multiple={false}
						buttonText={t('BUTTON.ADD_FILE')}
						fileType={AcceptableTypes.CSV}
						buttonType="primary"
						onChange={handleFileUpload}
						onClick={handleOnClick}
						errorText={error?.message}
						onRemove={() => setFileValue(undefined)}
					/>
				)}
			/>
		</>
	)
}

export default AccountGroupFormView
