import { GlobalStore } from '@/stores'
import { useStoreMap } from 'effector-react'

const useCurrentZone = (): string | null => {
	return useStoreMap({
		store: GlobalStore,
		keys: [],
		fn: (state) => state.selectedZone,
	})
}

export default useCurrentZone
