import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import palette from '@/theme/palette'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		BackDrop: {
			zIndex: theme.zIndex.drawer + 999,
			color: palette.text?.primary,
		},
	}),
)

export default styles
