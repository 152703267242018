import * as yup from 'yup'
import { fieldPointsValidation } from '@/utils/validationObjects'
import { ChallengeExecutionMethod } from '@/domains/enums'
import '@/utils/yupCustomMethods'
import i18n from '@/i18n'
import { isToggleVolumeFixedSingle } from '@/utils/featureToggle/featureToggleHelper'

const quantityValidation = (isRequired = false): yup.MixedSchema | yup.NumberSchema =>
	!isRequired
		? yup.mixed().notRequired()
		: yup
				.number()
				.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
				.min(1, i18n.t('ERROR_MESSAGE.SKU_QUANTITY_INVALID'))
				.typeError(i18n.t('ERROR_MESSAGE.SKU_QUANTITY_INVALID'))
				.integer(i18n.t('ERROR_MESSAGE.INVALID_NUMBER_VALUE_TYPE'))

const skusValidation = (
	isVolumeFixed: boolean,
	isSpendBased: boolean,
	isSingleVolumeFixed: boolean,
): yup.NotRequiredArraySchema<unknown> => {
	const isQuantityRequired = !isVolumeFixed && !isSpendBased && !isSingleVolumeFixed
	return yup.array().of(
		yup
			.object()
			.shape({
				sku: yup.string().required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD')),
				quantity: quantityValidation(isQuantityRequired),
				errorsInput: yup.object().shape({
					isDuplicate: yup.boolean(),
					isNotExist: yup.boolean().oneOf([false], i18n.t('ERROR_MESSAGE.NO_SKU_FOUND_BY_ID')),
				}),
			})
			.uniqueProperty('sku', i18n.t('ERROR_MESSAGE.NO_DUPLICATED_SKU')),
	)
}

const currencuMinValidation = (
	isIndividualTarget: boolean | undefined,
	isRequired = false,
): yup.MixedSchema | yup.NumberSchema => {
	return isRequired && isIndividualTarget
		? yup
				.number()
				.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
				.min(1, i18n.t('ERROR_MESSAGE.SKU_QUANTITY_INVALID'))
				.typeError(i18n.t('ERROR_MESSAGE.SKU_QUANTITY_INVALID'))
				.integer(i18n.t('ERROR_MESSAGE.INVALID_NUMBER_VALUE_TYPE'))
		: yup.mixed().notRequired()
}

// const challengeGoalValidation = (isRequired = false): yup.MixedSchema | yup.StringSchema =>
// 	!isRequired ? yup.mixed().notRequired() : yup.string().required()

export const getChallengeDetailsValidationObject = ({
	executionMethod,
	isEditing = false,
	isIndividualTarget = false,
}: {
	executionMethod: ChallengeExecutionMethod
	isEditing?: boolean
	isIndividualTarget?: boolean
}): yup.ObjectSchema => {
	const isVolumeFixed = executionMethod === ChallengeExecutionMethod.VolumeFixed
	const isSingleVolumeFixed = executionMethod === ChallengeExecutionMethod.SingleVolumeFixed
	const isSpendBased = executionMethod === ChallengeExecutionMethod.SpendBased

	const commonValidations = {
		skus: skusValidation(isVolumeFixed, isSpendBased, isSingleVolumeFixed),
	}
	const baseValidations = {
		...(isIndividualTarget
			? {}
			: {
					points: fieldPointsValidation(),
					quantity: quantityValidation(isVolumeFixed),
					currencyMin: currencuMinValidation(isSpendBased, !isIndividualTarget),
					purchaseType:
						isVolumeFixed && !isEditing && isToggleVolumeFixedSingle() ? yup.string().required() : yup.string(),
				}),
	}

	const methodsRequiringSkus = [
		ChallengeExecutionMethod.Purchase,
		ChallengeExecutionMethod.PurchaseMultiple,
		ChallengeExecutionMethod.VolumeFixed,
		ChallengeExecutionMethod.SingleVolumeFixed,
		ChallengeExecutionMethod.SpendBased,
	]

	return yup.object().shape({
		...baseValidations,
		...(methodsRequiringSkus.includes(executionMethod) ? commonValidations : {}),
	})
}
