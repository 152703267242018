enum ServiceUtilityCategoryEnum {
	electricityBill = 'ELECTRICITY_BILL',
	internetBill = 'INTERNET_BILL',
	mobilePhoneBill = 'MOBILE_PHONE_BILL',
	waterBill = 'WATER_BILL',
	awningService = 'AWNING_SERVICE',
	cableTvbill = 'CABLE_TV_BILL',
	concertTicketService = 'CONCERT_TICKET_SERVICE',
	counterInstallation = 'COUNTER_INSTALLATION',
	facadeSewrvice = 'FACADE_SERVICE',
	paintingService = 'PAINTING_SERVICE',
	rechargeService = 'RECHARGE_SERVICE',
	voucherService = 'VOUCHER_SERVICE',
}

export default ServiceUtilityCategoryEnum
