import { SettingsLevel } from '@/domains/enums/settings'
import { ChangeConfigParams, updateConfiguration } from '@/services/settings/SettingsService'
import { AxiosResponse } from 'axios'

interface Params {
	formValues: {
		initialBalance: number
	}
	selectedTierKey: string
	selectedZone: string
}

export async function execute({ formValues, selectedTierKey, selectedZone }: Params): Promise<AxiosResponse> {
	const body = {
		initialBalance: formValues.initialBalance,
	}

	const level = SettingsLevel.ZONE
	const params: ChangeConfigParams = {
		settingsId: selectedZone,
		level,
		tiers: [selectedTierKey],
	}
	return updateConfiguration(params, body)
}
