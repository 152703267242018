import { useSetupTestEnv } from '@/hooks'
import React from 'react'

type Props = {
	children: React.ReactNode
}

const TestEnvProvider: React.FC<Props> = ({ children }: Props) => {
	const { isLoading } = useSetupTestEnv()

	return isLoading ? null : <>{children}</>
}

export default TestEnvProvider
