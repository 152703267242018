import ProgramCombo from 'domains/program/ProgramCombo'
import { useStoreMap } from 'effector-react'
import React from 'react'
import { ComboEvents, ComboStore, PageLevelEvents } from '@/stores'
import * as UpdateProgramCombosUseCase from 'usecase/program/UpdateProgramCombosUseCase'
import comboFormValuesToProgram from 'converters/program/ComboFormValuesToProgram'
import AnalyticsService from 'services/analytics/AnalyticsService'
import RecordType from 'domains/analytics/RecordType'
import ScreenName from 'domains/analytics/ScreenName'
import ButtonInteraction from 'domains/analytics/ButtonInteraction'
import { useTranslation } from 'react-i18next'
import EditComboFormInputs from 'routes/combos/models/EditComboFormInputs'
import { ComboFormType } from 'domains/combo/form/ComboFormType'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import EditComboDialogView from './EditComboDialogView'

const EditComboDialog: React.FC = () => {
	const { t } = useTranslation()
	const { show, combo } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			show: state.showEditComboDialog,
			combo: state.activeCombo || ({} as ProgramCombo),
		}),
	})
	const userInfo = useGetUserInfo()

	const programData = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => state.programData,
	})

	const handleCancel = (buttonClicked: boolean) => {
		if (buttonClicked) {
			AnalyticsService.events.buttonClicked({
				button_label: null,
				button_name: ButtonInteraction.Cancel,
				screen_name: ScreenName.EditDTComboModal,
				...userInfo,
			})
		}
		ComboEvents.setShowEditComboDialog(false)
		ComboEvents.setActiveCombo({ activeCombo: undefined })
	}

	const handleSubmit = (values: EditComboFormInputs) => {
		const formValues = {
			combos: [
				{
					comboId: combo.comboId,
					...values,
				},
			],
		} as ComboFormType

		if (programData) {
			UpdateProgramCombosUseCase.execute(comboFormValuesToProgram(programData.id, formValues))
				.then((programReturn) => {
					if (programReturn) {
						PageLevelEvents.showNotification({
							route: '/membership/combos',
							notificationType: 'success',
							message: t('combo:NOTIFICATION.EDIT.SUCCESS'),
						})
						ComboEvents.setComboPoints({ comboId: combo.comboId, comboPoints: values.points })
						AnalyticsService.events.recordUpdated({
							record_type: RecordType.DTCombo,
							record_id: combo.comboId,
							record_additional_info: `Points: ${values.points}`,
							form_name: null,
							...userInfo,
						})
					} else {
						PageLevelEvents.showNotification({
							route: '/membership/combos',
							notificationType: 'error',
							message: t('combo:NOTIFICATION.EDIT.ERROR'),
						})
					}
				})
				.finally(() => {
					ComboEvents.setActiveCombo({ activeCombo: undefined })
					ComboEvents.setShowEditComboDialog(false)
				})
		}
	}

	return (
		<EditComboDialogView show={show} combo={combo as ProgramCombo} onCancel={handleCancel} onSubmit={handleSubmit} />
	)
}

export default EditComboDialog
