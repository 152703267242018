import ChallengePatchResponse from 'domains/challenge/patch/ChallengePatchResponse'
import ChallengeMode from 'domains/enums/ChallengeMode'
import * as ChallengeService from 'services/challenge/ChallengeService'
import { verifyChallengeAcceptance } from 'usecase/rewards/VerifyEnrollmentUseCase'

/**
 * Patch challenge mode to Cancelled and returns updated challenge if it was not accepted by any POC
 * otherwise returns null
 * @param challengeId challenge id
 */
async function execute(challengeId: string): Promise<ChallengePatchResponse | null> {
	return verifyChallengeAcceptance(challengeId).then((hasPocEnrolled) => {
		if (!hasPocEnrolled) {
			return ChallengeService.patchChallenge({ id: challengeId, mode: ChallengeMode.Cancelled })
		}
		return null
	})
}

export { execute }
