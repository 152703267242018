import { useStoreMap } from 'effector-react'
import { ChallengeStore } from '@/stores/challengeCreation'
import { useIsTargetForChallengeEnabled } from '@/hooks/challenge/useIsTargetForChallengeEnabled'

const useIsTargetForCreationEnabled = (): boolean => {
	const { challengeSetupFormData } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => ({
			challengeSetupFormData: state.challengeSetupFormData || null,
		}),
	})
	return useIsTargetForChallengeEnabled(challengeSetupFormData)
}

export default useIsTargetForCreationEnabled
