import React from 'react'
import CreateRuleActionsView from './CreateRuleActionsView'

type Props = {
	disabled: boolean
	onCancel: () => void
	onSubmit: () => void
}

const CreateRuleActions: React.FC<Props> = ({ disabled, onCancel, onSubmit }: Props) => {
	return <CreateRuleActionsView onSubmit={onSubmit} onCancel={onCancel} disabled={disabled} />
}

export default CreateRuleActions
