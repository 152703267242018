import React from 'react'
import {
	FormattedTransaction,
	TranslatedTransaction,
} from '@/routes/transactions/components/TransactionLogV2/TransactionLog.types'
import { IconButton } from '@hexa-ui/components'
import { EyeOn } from '@hexa-ui/icons'
import { formatDate } from '@/utils/date'
import TransactionStatusBadge from '@/components/core/TransactionStatusBadge'

const DATE_FORMAT = 'MMMM D, YYYY - HH:mm'

function getTransactionData(data: TranslatedTransaction[]): FormattedTransaction[] {
	return data
		.map((item) => {
			try {
				return {
					actions: <IconButton size={'small'} variant={'tertiary'} icon={() => <EyeOn fr={1} />} />,
					totalAccounts: item.totalAccounts,
					createdAt: formatDate(new Date(item.createdAt), DATE_FORMAT),
					id: item.id.toUpperCase(),
					status: <TransactionStatusBadge type={item.status} />,
					type: item.type,
				} as FormattedTransaction
			} catch (error) {
				console.error('Error transforming transaction data:', error)
				return null
			}
		})
		.filter((item) => item !== null) as FormattedTransaction[]
}

export default getTransactionData
