import React from 'react'
import RulesProvider from './providers/RulesProvider'
import RulesListRedesign from './RulesRedesign/Pages/RuleListRedesign/RulesListRedesign'

const RulesRoute: React.FC = () => {
	return (
		<RulesProvider>
			<RulesListRedesign />
		</RulesProvider>
	)
}

export default RulesRoute
