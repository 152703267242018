import Response from 'services/models/Response'
import { AccountGroupTarget } from '@/domains/AccountGroup'
import * as AccountGroupRewardsAdminService from '@/services/accountGroup/AccountGroupRewardsAdminService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import * as GlobalUseCase from '../global/GlobalUseCase'

export async function executeByTarget(accountGroupTarget: AccountGroupTarget): Promise<Response<{ groupId: string }>> {
	GlobalUseCase.load()
	const vendorId = getDefaultVendorIdOfCurrentZone()
	try {
		if (accountGroupTarget.individualTarget && !accountGroupTarget.challengeId) {
			throw new Error('Challenge id is required for individualTarget!')
		}
		return await AccountGroupRewardsAdminService.upsert({
			...accountGroupTarget,
			vendorId,
		})
	} finally {
		GlobalUseCase.loadDone()
	}
}
