import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import { Location } from 'history'
import AlertDialog from '../AlertDialog'

interface Props {
	when?: boolean
	navigate: (path: string) => void
	whiteList: string[]
	alertTitle: string
	alertMessage: string
}
const RouteLeavingGuard: React.FC<Props> = ({ when, navigate, whiteList, alertTitle, alertMessage }: Props) => {
	const [showDialog, setShowDialog] = useState(false)
	const [lastLocation, setLastLocation] = useState<Location | null>(null)
	const [confirmedNavigation, setConfirmedNavigation] = useState(false)

	const shouldBlockNavigation = (url: string): boolean => {
		if (!whiteList.includes(url)) {
			return true
		}
		return false
	}

	const handleCancel = (): void => {
		setShowDialog(false)
	}
	const handleBlockedNavigation = (nextLocation: Location): boolean => {
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation.pathname)) {
			setShowDialog(true)
			setLastLocation(nextLocation)
			return false
		}
		return true
	}
	const handleConfirm = (): void => {
		setShowDialog(false)
		setConfirmedNavigation(true)
	}

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.pathname)
		}
	}, [confirmedNavigation, lastLocation, navigate])

	return (
		<>
			<Prompt when={when} message={handleBlockedNavigation} />
			<AlertDialog title={alertTitle} show={showDialog} handleCancel={handleCancel} handleConfirm={handleConfirm}>
				{alertMessage}
			</AlertDialog>
		</>
	)
}
RouteLeavingGuard.defaultProps = {
	when: false,
}
export default RouteLeavingGuard
