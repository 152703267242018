import axios from '@/utils/axiosInstance'
import { useLogService as logService } from 'admin-portal-shared-services'
import { TransactionResponseV2 } from '@/domains/transaction/TransactionResponse'

const log = logService()
const PATH = '/v1/transactions'

async function getTransactionService(transactionId: string): Promise<TransactionResponseV2> {
	const endPoint = `${PATH}/${transactionId}`
	try {
		const response = await axios.get<TransactionResponseV2>(endPoint)
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

export default getTransactionService
