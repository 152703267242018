import React from 'react'
import { Grid } from '@material-ui/core'
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons'
import styles from './styles'

type Props = {
	children: JSX.Element[]
}

export const GOOD_IMG_COLOR = '#1D8445'
export const BAD_IMG_COLOR = '#C9201D'

const GoodBadPhoto: React.FC<Props> = ({ children }) => {
	const classes = styles()
	const [goodImg, badImg] = children

	const renderImageBox = (color: string, image: JSX.Element, icon: JSX.Element): JSX.Element => (
		<div className={classes.imageBox}>
			{image}
			<div className={classes.iconBox}>
				<span style={{ color }}>{icon}</span>
			</div>
		</div>
	)

	return (
		<>
			<Grid item>{renderImageBox(GOOD_IMG_COLOR, goodImg, <CheckIcon className={classes.icon} />)}</Grid>
			<Grid item>{renderImageBox(BAD_IMG_COLOR, badImg, <CloseIcon className={classes.icon} />)}</Grid>
		</>
	)
}

export default GoodBadPhoto
