import React, { ForwardRefRenderFunction, forwardRef, useState, useImperativeHandle } from 'react'
import { Checkbox, TableCell } from '@material-ui/core'
import useStyles from './styles'

type Props = {
	rowIndex: number
	onChange: () => void
}

export interface TableCheckboxRef {
	rowIndex: number
	checked: boolean
	toggle: () => void
}

const TableCheckbox: ForwardRefRenderFunction<TableCheckboxRef, Props> = ({ rowIndex, onChange }, ref) => {
	const classes = useStyles()
	const [checked, setChecked] = useState(false)
	const name = `checkbox-row-${rowIndex}`

	const handleChange = () => {
		setChecked(!checked)
		setTimeout(() => onChange(), 80)
	}

	useImperativeHandle(ref, () => ({
		rowIndex,
		checked,
		toggle: () => {
			setChecked(!checked)
		},
	}))

	return (
		<TableCell id="row-selection-checkbox" className={classes.cell}>
			<Checkbox
				name={name}
				className={classes.checkbox}
				checked={checked}
				onChange={handleChange}
				inputProps={{
					'aria-label': name,
				}}
				data-checked={checked}
				data-testid={name}
			/>
		</TableCell>
	)
}

export default forwardRef(TableCheckbox)
