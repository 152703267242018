import React, { ReactElement } from 'react'
import { TextField } from '@material-ui/core'
import { Field, FieldProps } from 'formik'
import { Tooltip } from '@hexa-ui/components'
import { Info } from '@hexa-ui/icons'
import { useTranslation } from 'react-i18next'
import InputLabel from '../InputLabel'
import styles from '../styles'
import FieldErrorMessage from '../FieldErrorMessage'

type Props = {
	id: string
	name: string
	label: string
	autoFocus?: boolean
	showError?: boolean
	onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	disabled?: boolean
	helperText?: string
	onError?: (id: string, message: string) => void
	hasTooltip?: boolean
	placeholder?: string
	tooltipTextKey?: string
	setPocIdField?: (pocId: string) => void
}

const InputTextArea: React.FC<Props> = ({
	id,
	name,
	label,
	autoFocus,
	showError,
	disabled,
	helperText,
	onKeyUp,
	onError,
	hasTooltip,
	placeholder = '',
	tooltipTextKey,
	setPocIdField,
}: Props) => {
	const classes = styles({})
	const { t } = useTranslation()

	return (
		<Field name={name} disabled={disabled}>
			{(fieldProps: FieldProps): ReactElement => {
				const shouldShowError =
					!!fieldProps.meta.error && (showError || (showError === undefined && fieldProps.form.submitCount > 0))
				return (
					<div className={classes.box}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<InputLabel
								label={label}
								htmlFor={id}
								error={shouldShowError}
								disabled={disabled}
								bottomSpacing={!helperText}
							/>
							{hasTooltip && tooltipTextKey && (
								<div style={{ marginLeft: '8px' }}>
									<Tooltip placement="right" text={t(tooltipTextKey)}>
										<Info size="large" />
									</Tooltip>
								</div>
							)}
						</div>
						{shouldShowError && <FieldErrorMessage id={id} message={fieldProps.meta.error} onShow={onError} />}
						<TextField
							id={id}
							autoFocus={autoFocus}
							name={fieldProps.field.name}
							value={fieldProps.field.value ?? ''}
							onChange={fieldProps.field.onChange}
							onBlur={fieldProps.field.onBlur}
							onKeyUp={onKeyUp}
							onFocus={() => setPocIdField && setPocIdField('')}
							fullWidth
							multiline
							minRows={3}
							maxRows={6}
							variant="outlined"
							error={shouldShowError}
							helperText=""
							placeholder={placeholder}
							type="text"
							disabled={disabled}
						/>
					</div>
				)
			}}
		</Field>
	)
}

InputTextArea.defaultProps = {
	autoFocus: false,
	hasTooltip: false,
}

export default InputTextArea
