import ProgressiveImage from '@/components/core/ProgressiveImage'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { ItemDescription } from '@/routes/redeemableItems/components/CreateRedeemableItem/content/ItemCell/ItemCell'
import { Typography } from '@material-ui/core'
import React from 'react'
import RedeemableItemsListStyle from '../../../RedeemableItemsListStyle'

export const RedeemableItemProduct = ({
	image,
	itemName,
	fullPackageDescription,
	fullContainerDescription,
}: RedeemableItem) => {
	const css = RedeemableItemsListStyle()

	return (
		<div className={css.productWrapper} data-testid="redeemable-item-product">
			<div className={css.imageContainer}>
				<ProgressiveImage imageSource={image} />
			</div>
			<div className={css.productInfoContainer}>
				<Typography className={css.productName}>{itemName}</Typography>
				<ItemDescription
					fullPackageDescription={fullPackageDescription}
					fullContainerDescription={fullContainerDescription}
				/>
			</div>
		</div>
	)
}
