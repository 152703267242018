import { Program } from '@/domains'
import { Box, Typography, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { useStore, useStoreMap } from 'effector-react'
import { GlobalStore } from '@/stores'
import { useTranslation } from 'react-i18next'
import useStyle from './ProgramConfigurationStyle'

type Props = {
	program: Program | undefined
	handleEdit: () => void
}

const ProgramRow: React.FC<Props> = ({ program, handleEdit }: Props) => {
	const classes = useStyle()
	const { t } = useTranslation()

	const isLoading = useStoreMap({
		store: GlobalStore,
		keys: [],
		fn: (state) => state.isLoading,
	})
	const { selectedProgram } = useStore(GlobalStore)

	const programName = selectedProgram?.name

	return (
		<Grid container>
			<Box className={classes.boxItem}>
				<Grid item className={classes.flexSpaceBetween}>
					<Grid item xs={3}>
						<Typography className={classes.title}>{t('program:LABEL_NAME.PROGRAM_NAME')}</Typography>
					</Grid>
					<Grid item xs={9} className={classes.flexSpaceBetween}>
						<Typography className={classes.content}>
							{isLoading ? (
								<Skeleton data-testid="skeleton" animation="wave" width="130px" height="22px" />
							) : (
								programName
							)}
						</Typography>
						{!isLoading && (
							<Typography
								className={classes.button}
								onClick={handleEdit}
								data-testid="program-configuration-edit-button"
							>
								{t('program:EDIT_DIALOG_BUTTON')}
							</Typography>
						)}
					</Grid>
				</Grid>
				<Grid item className={classes.flexSpaceBetween}>
					<Grid item xs={3}>
						<Typography className={classes.title}>{t('program:LABEL_NAME.INITIAL_BALANCE')}</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography className={classes.content}>
							{isLoading ? (
								<Skeleton data-testid="skeleton" animation="wave" width="50px" height="22px" />
							) : (
								program?.initialBalance
							)}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	)
}

export default ProgramRow
