import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import { ChallengeHistory, Sku } from '@/domains'
import ItemChallenge from '@/domains/challenge/ItemChallenge'
import { ChallengeExecutionMethod, ChallengeStatus, EditDialog } from '@/domains/enums'
import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useStoreMap } from 'effector-react'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import ChallengeHistoryDetail from 'routes/challenges/model/ChallengeHistoryDetail'
import ChallengeHistoryStore from 'stores/challengeHistory/ChallengeHistoryStore'
import parentStyles from '../ChallengeHistoryDetailStyle'
import styles from './styles'

type Props = {
	toggleEditDialog: (successNotification: boolean, activeEditDialog: EditDialog) => void
	challenge: ChallengeHistoryDetail
	challengeHistory: ChallengeHistory | null
}

const DataSectionDetails: React.FC<Props> = ({ toggleEditDialog, challenge, challengeHistory }) => {
	const parentClasses = parentStyles()
	const classes = styles()
	const { t } = useTranslation()

	const challengeCancelled = useStoreMap({
		store: ChallengeHistoryStore,
		keys: [],
		fn: (state) => state.challengeCancelled,
	})

	const isPurchaseChallenge = (executionMethod: ChallengeExecutionMethod) =>
		[ChallengeExecutionMethod.Purchase, ChallengeExecutionMethod.PurchaseMultiple].includes(executionMethod)

	const getItemsData = (items: ItemChallenge[] = []): Sku[] => {
		return items.map((i) => ({ sku: i.vendorItemId, quantity: i.quantity }))
	}

	const getSkuTableData = (historyDetails: ChallengeHistoryDetail): Sku[] => {
		return getItemsData(historyDetails.items)
	}

	const renderEdit = () => {
		const isActiveChallenge = ChallengeStatus.Active === challenge.status
		const enableEdit = ChallengeStatus.Created === challenge.status || isActiveChallenge
		return enableEdit && !challengeCancelled ? (
			<Typography
				id="step3-edit-btn"
				data-testid="step3-edit-btn"
				className={clsx({ [parentClasses.editLink]: true, [parentClasses.inactiveEditLink]: isActiveChallenge })}
				onClick={(): void => {
					if (isActiveChallenge) {
						return
					}
					toggleEditDialog(false, EditDialog.Details)
				}}
			>
				{t('EDIT')}
			</Typography>
		) : undefined
	}

	const renderTable = (data: Sku[]) => {
		return (
			<GridContainer id="table-skus" data-testid="table-skus">
				<GridItem xs={5}>
					<Typography variant="h5" className={classes.label}>
						{t('challenge:HISTORY_DETAIL_REDESIGN.SKU')}
					</Typography>
				</GridItem>
				<GridItem xs={7}>
					<Typography variant="h5" className={clsx(classes.label, classes.quantityLabel)}>
						{t('challenge:HISTORY_DETAIL_REDESIGN.SKU_QTY')}
					</Typography>
				</GridItem>
				{data.map(({ sku, quantity }, index) => {
					const last = index === data.length - 1
					const boxShadow = last ? 'none' : undefined
					return (
						<Fragment key={sku}>
							<GridItem xs={5}>
								<Typography style={{ boxShadow }} className={classes.label}>
									{sku}
								</Typography>
							</GridItem>
							<GridItem xs={7}>
								<Typography style={{ boxShadow }} className={clsx(classes.label, classes.quantityLabel)}>
									{quantity}
								</Typography>
							</GridItem>
						</Fragment>
					)
				})}
			</GridContainer>
		)
	}

	return (
		<Box className={classes.subContainer}>
			<GridContainer>
				<GridItem xs={5}>
					{!challenge.individualTarget && (
						<div style={{ display: 'flex' }}>
							<GridItem xs={5} className={parentClasses.item}>
								<Typography className={parentClasses.label}>{t('challenge:HISTORY_DETAIL_REDESIGN.POINTS')}</Typography>
							</GridItem>
							<GridItem xs={7}>
								<Typography>{challenge.points}</Typography>
							</GridItem>
						</div>
					)}
					{challengeHistory?.skus && challengeHistory?.executionMethod === ChallengeExecutionMethod.VolumeFixed && (
						<GridContainer>
							{!challenge.individualTarget && (
								<>
									<GridItem xs={5} className={parentClasses.item}>
										<Typography className={parentClasses.label}>
											{t('challenge:HISTORY_DETAIL_REDESIGN.SKU_QTY')}
										</Typography>
									</GridItem>
									<GridItem xs={7}>
										<Typography>{challenge.quantityMin}</Typography>
									</GridItem>
								</>
							)}
							<GridItem xs={5}>
								<Typography className={parentClasses.label}>{t('challenge:HISTORY_DETAIL_REDESIGN.SKU')}</Typography>
							</GridItem>
							<GridItem xs={7}>
								<Typography>{challenge.skus?.map((s) => s.sku).join(', ')}</Typography>
							</GridItem>
						</GridContainer>
					)}
					{isPurchaseChallenge(challenge.executionMethod) && challengeHistory?.items && (
						<GridItem xs={12}>
							<Box>{renderTable(getSkuTableData(challenge))}</Box>
						</GridItem>
					)}
				</GridItem>
				<GridItem xs={7}>{renderEdit()}</GridItem>
			</GridContainer>
		</Box>
	)
}

export default DataSectionDetails
