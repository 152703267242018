import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		searchInput: {
			height: '40px',
			width: '400px',
		},
		input: {
			'&::placeholder': {
				opacity: 1,
			},
		},
	}),
)

export default styles
