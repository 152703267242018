import React, { useState } from 'react'
import { Controller, useForm } from 'admin-membership-hookform-resolvers'
import SearchInput from '@/components/hook-form/SearchInput'
import { useTranslation } from 'react-i18next'
import { AccountValidationResult } from '@/domains/enums'
import { PocSearchParams } from '@/domains'
import { hasText } from '@/utils/string'

export type PocSearchProps = {
	searchHandler: (params: PocSearchParams) => Promise<AccountValidationResult>
	isSearching: boolean
	placeholder?: string
	hideIcon?: boolean
}

const PocSearch: React.FC<PocSearchProps> = ({ isSearching, searchHandler, placeholder, hideIcon }: PocSearchProps) => {
	const [validationResult, setValidationResult] = useState<AccountValidationResult>(AccountValidationResult.OK)
	const { t } = useTranslation()

	const { control } = useForm<PocSearchParams>({
		defaultValues: {
			pocId: '',
		},
	})

	const errorText = () => {
		if (!isSearching && validationResult !== AccountValidationResult.OK) {
			return t(`common:POC_VALIDATION.${validationResult}`)
		}
		return ''
	}

	const onSearch = async (text?: string) => {
		const result = await searchHandler({
			pocId: `${text}`.trim(),
		})
		setValidationResult(result)
	}

	return (
		<Controller
			id="pocId"
			name="pocId"
			onSearch={onSearch}
			placeholder={hasText(placeholder) ? placeholder : t('common:POC_SEARCH.SEARCH_ID')}
			isSearching={isSearching}
			control={control}
			errorText={errorText()}
			hideIcon={hideIcon}
			as={SearchInput}
		/>
	)
}

export default PocSearch
