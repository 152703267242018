import { DownloaderFile } from '@/components/core'
import { RulesStore } from '@/stores'
import { useStore } from 'effector-react'
import React, { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useStyles from './styles'
import UploadFile from './UploadFile'

const StepMultipleSkuComponent = ({ hasErrors }: { hasErrors: boolean }) => {
	const { t } = useTranslation()
	const { skuUploadFile } = useStore(RulesStore)
	const skuClasses = useStyles()
	const fileName = skuUploadFile?.[0]?.name ?? ''
	const { setError, clearErrors } = useFormContext()
	const userInfo = useGetUserInfo()

	const handleDownloadCSVClick = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_label: 'Download CSV Template',
			button_name: 'Download CSV Template',
			screen_name: 'Earning Rules - SKUs - Edit - Add - Multiple - Step 3',
			...userInfo,
		})
	}, [userInfo])

	useEffect(() => {
		clearErrors()
		if (hasErrors) {
			setError('uploadFile', {
				type: 'generalError',
				message: `${t('ERROR_MESSAGE.UPLOAD_FAILED', { name: fileName })}`,
			})
		}
	}, [clearErrors, fileName, hasErrors, setError, t])

	return (
		<div className={skuClasses.content}>
			<div className={skuClasses.instructions}>{t('rules:EDIT_SKU_PAGE.UPLOAD_INSTRUCTION')}</div>
			<DownloaderFile
				onClick={handleDownloadCSVClick}
				url="https://cdn-b2b-abi.global.ssl.fastly.net/admin/SKUs_to_Earning_Rule_Example.csv"
				label={t('common:BUTTON.DOWNLOAD_CSV_TEMPLATE')}
			/>

			<UploadFile inputName="uploadFile" fileName={fileName} />
		</div>
	)
}

export default StepMultipleSkuComponent
