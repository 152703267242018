import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

export const useAnalyticsServiceEventLinkClicked = (): {
	sendAnalyticsServiceEventLinkClickedEvent: (screenName: string, linkName: string, linkLabel: string) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceEventLinkClickedEvent = useCallback(
		(screenName: string, linkName: string, linkLabel: string) => {
			AnalyticsService.events.linkClicked({
				screen_name: screenName,
				link_name: linkName,
				link_label: linkLabel,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceEventLinkClickedEvent }
}
