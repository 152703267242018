import { styled } from '@bees/vision-tokens'

const EmptyTableContainer = styled('div', {
	paddingTop: '24px',
	'& div > div > table > tbody > tr': {
		borderBottom: 'none !important',
	},
	'& div > div ': {
		overflow: 'hidden !important',
	},
})

const EmptyMessage = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	fontFamily: 'Work Sans',
	marginTop: '40px',
})

const EmptyMessageText = styled('p', {
	fontSize: '14px',
})

export const ContainerWithButton = styled('div', {
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
	marginTop: '16px',
	marginBottom: '-8px',
	'& > p': {
		marginTop: '8px',
		fontSize: '14px',
		fontWeight: '600',
		'& > span': {
			fontWeight: '400 !important',
			fontSize: '14px',
			color: 'rgba(20,20,20,0.56)',
		},
	},
})

const ProductWrapper = styled('div', {
	display: 'flex',
	gap: '32px',
	alignItems: 'center',
})

const Image = styled('img', {
	height: '48px',
	width: '48px',
	objectFit: 'contain',
})

const ImagePlaceholder = styled('div', {
	height: '48px',
	width: '48px',
})

const SkuRemove = styled('div', {
	display: 'flex',
	justifyContent: 'center',
	'& > SVG': {
		cursor: 'pointer',
	},
})

const Styled = {
	EmptyTableContainer,
	EmptyMessage,
	EmptyMessageText,
	ContainerWithButton,
	ProductWrapper,
	Image,
	ImagePlaceholder,
	SkuRemove,
}

export default Styled
