import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { BASE_ROUTE } from 'utils/constants'
import PermissionList from './components/PermissionList/PermissionList'

const RewardsPermissionRoute: React.FC = () => {
	return (
		<Switch>
			<Route exact path={`${BASE_ROUTE}/permission`}>
				<PermissionList />
			</Route>
		</Switch>
	)
}

export default RewardsPermissionRoute
