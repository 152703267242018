import { ButtonInteraction, RecordType, ScreenName } from '@/domains'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { Trash2 } from '@hexa-ui/icons'
import React, { useState } from 'react'
import { DeleteSkuFromRuleUseCase } from '@/usecase'
import { PageLevelEvents, RulesEvents } from '@/stores'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useTranslation } from 'react-i18next'
import SkuForm from '@/domains/sku/SkuForm'
import { useParams } from 'react-router-dom'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useAuditLogDeleteRuleSku from '@/routes/rules/hooks/useAuditLogDeleteRuleSku'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { GridContainer } from '@/components/templates/GridTemplate'
import { useRule } from '../../../hooks/useRule'
import useStyles from './DeleteSkuIconStyle'
import DeleteSkuIconView from './DeleteSkuIconView'

type DeleteSkuIconProps = {
	sku: SkuForm
}
const DeleteSkuIcon: React.FC<DeleteSkuIconProps> = ({ sku }) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const userInfo = useGetUserInfo()
	const [show, setShow] = useState(false)
	const { selectedTierKey } = useCurrentTier()
	const settings = useCurrentSettings()
	const { ruleId } = useParams<{ ruleId: string }>()
	const rule = useRule(ruleId)
	const { sendAuditLogDeleteRuleSku } = useAuditLogDeleteRuleSku()
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()

	const handleConfirm = async () => {
		sendAnalyticsServiceEventButtonClickedEvent(ScreenName.RemoveSkuModal, ButtonInteraction.Remove, null)

		const deleted = sku
			? await DeleteSkuFromRuleUseCase.execute({
					ruleId,
					categoryId: rule.categoryId,
					selectedTierKey,
					sku,
					settingsId: settings.settingsId,
					level: settings.level,
			  })
			: false

		if (deleted) {
			RulesEvents.removeSku(sku.id)

			PageLevelEvents.showNotification({
				notificationType: 'success',
				message: t('rules:SKUS.NOTIFICATION.REMOVE.SUCCESS'),
			})
			AnalyticsService.events.recordDeleted({
				record_id: sku.id ?? '',
				record_type: RecordType.SkuEarningRule,
				...userInfo,
			})
			sendAuditLogDeleteRuleSku({
				sku: sku.id,
				skuName: sku.name,
				earningRuleId: rule.ruleId,
				earningRuleName: rule.name,
			})
		} else {
			PageLevelEvents.showNotification({
				notificationType: 'error',
				message: t('rules:SKUS.NOTIFICATION.REMOVE.ERROR'),
			})
		}

		setShow(false)
		RulesEvents.hideRemoveSkuDialog()
	}

	return (
		<GridContainer className={classes.deleteSkuIcon}>
			<DeleteSkuIconView show={show} onConfirm={handleConfirm} onCancel={() => setShow(false)} />
			<Trash2
				onClick={() => {
					AnalyticsService.events.recordDeleted({
						record_id: sku.id,
						record_type: RecordType.SkuEarningRule,
						screen_name: ScreenName.EarningRulesSkus,
						...userInfo,
					})
					setShow(true)
				}}
				cursor="pointer"
			/>
		</GridContainer>
	)
}

export default DeleteSkuIcon
