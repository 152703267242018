import * as ProgramService from '@/services/program/ProgramService'

import { ComboFormDataMultiple } from '@/stores'
import { AxiosResponse } from 'axios'

export async function execute(
	programId: string,
	combosBulkUpdateTransaction: ComboFormDataMultiple,
): Promise<AxiosResponse<void>> {
	return ProgramService.bulkCombosUpdate(programId, combosBulkUpdateTransaction.formData)
}
