import React, { useMemo } from 'react'
import { useStoreMap } from 'effector-react'
import { ChallengeStore } from '@/stores/challengeCreation'
import { isNotPurchaseOrVolume } from '@/utils/challenge'
import StepsHeaderView from './StepsHeaderView'

const StepsHeader: React.FC = () => {
	const { currentStep, executionMethod, individualTarget } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => ({
			currentStep: state.currentStep.stepIndex,
			executionMethod: state.challengeSetupFormData?.executionMethod,
			individualTarget: state.challengeFilterFormData?.individualTarget,
		}),
	})

	const thirdStepTitle = useMemo(() => {
		if (individualTarget) return 'SKU'
		if (executionMethod && isNotPurchaseOrVolume({ executionMethod })) return 'POINTS'
		return 'POINTS_AND_SKU'
	}, [executionMethod, individualTarget])

	return <StepsHeaderView thirdStepTitle={thirdStepTitle} currentStep={currentStep} />
}

export default StepsHeader
