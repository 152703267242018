/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */
import * as yup from 'yup'
import { fieldValidationRequired, imageFileValidation } from '@/utils/validationObjects'
import { ChallengeExecutionMethod } from '@/domains/enums'
import i18n from '@/i18n'

const handleIsValidPurchaseVolumeMethod = (executionMethod: ChallengeExecutionMethod) =>
	executionMethod &&
	[
		ChallengeExecutionMethod.Purchase,
		ChallengeExecutionMethod.PurchaseMultiple,
		ChallengeExecutionMethod.VolumeFixed,
	].includes(executionMethod)

const handleIsValidPhotoExecutionMethod = (executionMethod: ChallengeExecutionMethod) =>
	executionMethod && [ChallengeExecutionMethod.TakePhoto].includes(executionMethod)

export const getValidationObject = (languages?: Array<string>, isVisionId?: boolean): yup.ObjectSchema => {
	const optionalLanguages: { [key: string]: yup.MixedSchema | yup.StringSchema } = {}

	languages?.forEach((language, index) => {
		if (index > 0) {
			optionalLanguages[`title-${language}`] = yup.string().notRequired()
			optionalLanguages[`description-${language}`] = yup.string().notRequired()
			optionalLanguages[`image-${language}`] = imageFileValidation({ required: false })
		}
	})

	return yup.object().shape({
		id: !isVisionId
			? yup.mixed().when('executionMethod', {
					is: handleIsValidPurchaseVolumeMethod,
					then: yup.string().notRequired(),
					otherwise: yup.string().trim().required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD')),
			  })
			: yup.string().notRequired(),
		visionId: isVisionId
			? yup.mixed().when('executionMethod', {
					is: handleIsValidPurchaseVolumeMethod,
					then: yup.string().notRequired(),
					otherwise: yup.string().trim().required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD')),
			  })
			: yup.mixed().notRequired(),
		image: imageFileValidation(),
		goodPhoto: yup.mixed().when('executionMethod', {
			is: handleIsValidPhotoExecutionMethod,
			then: imageFileValidation(),
			otherwise: undefined,
		}),
		badPhoto: yup.mixed().when('executionMethod', {
			is: handleIsValidPhotoExecutionMethod,
			then: imageFileValidation(),
			otherwise: undefined,
		}),
		executionMethod: fieldValidationRequired(),
		title: fieldValidationRequired(),
		description: fieldValidationRequired(),
		...optionalLanguages,
	})
}
