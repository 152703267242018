import { RefundOrderItem } from '@/domains'
import React from 'react'
import { ResultListWrapper } from '../ResultListWrapper'
import { RedeemablesListRow } from './RedeemablesListRow'

type Props = {
	redeemables: Array<RefundOrderItem>
	formKey: string
}

export const RedeemablesListView: React.FC<Props> = ({ redeemables, formKey }: Props) => {
	const existFullyRefunded = redeemables.some(({ refundableQuantity }) => !refundableQuantity)
	const allAreFullyRefunded = redeemables.every(({ refundableQuantity }) => !refundableQuantity)

	return (
		<ResultListWrapper existFullyRefunded={existFullyRefunded} data-testid="refund-redeemables-list-wrapper">
			{redeemables.map((redeemable, index) => {
				return (
					<RedeemablesListRow
						key={redeemable.id}
						index={index}
						formKey={formKey}
						existFullyRefunded={existFullyRefunded}
						allAreFullyRefunded={allAreFullyRefunded}
						{...redeemable}
					/>
				)
			})}
		</ResultListWrapper>
	)
}
