import { SKUProcess } from '@/domains/sku/SkuStep'
import { RulesStore } from '@/stores'
import { useStoreMap } from 'effector-react'
import React from 'react'
import StepSkuProvider from './StepSkuProvider'
import StepSingleSkuComponent from './StepSingleSkuComponent/StepSingleSkuComponent'
import StepMultipleSkuComponent from './StepMultipleSkuComponent/StepMultipleSkuComponent'

const StepSKU: React.FC<{ errors: boolean }> = ({ errors }) => {
	const process = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => state.editSkuStepData.process ?? null,
	})

	return (
		<StepSkuProvider>
			{process === SKUProcess.SINGLE_SKU ? <StepSingleSkuComponent /> : <StepMultipleSkuComponent hasErrors={errors} />}
		</StepSkuProvider>
	)
}

export default StepSKU
