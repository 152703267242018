import { getMask, unformat } from '@/utils/currencyHelper'
import { onlyNumbers } from '@/utils/string'
import { Input, InputProps } from '@hexa-ui/components'
import { FormHelperText, InputBaseComponentProps, OutlinedInput, OutlinedInputProps } from '@material-ui/core'
import FieldErrorMessage from 'components/formik/FieldErrorMessage'
import InputLabel from 'components/formik/InputLabel'
import React, { useCallback, useEffect, useState } from 'react'
import MaskedInput, { conformToMask } from 'react-text-mask'
import styles from '../../formik/styles'

export type InputCurrencyProps = {
	id: string
	label: string
	optionalLabel?: string
	helperText?: string
	errorText?: string
} & OutlinedInputProps

interface TextMaskCustomProps {
	inputRef: (ref: HTMLInputElement | null) => void
}

const MIN_CURRENCY_ROWS = 3
const MAX_CURRENCY_ROWS = 6

const TextMaskCustom: React.FC<TextMaskCustomProps> = ({ inputRef, ...other }: TextMaskCustomProps) => {
	return (
		<MaskedInput
			{...other}
			ref={(ref: MaskedInput) => {
				return inputRef(ref ? (ref.inputElement as HTMLInputElement) : null)
			}}
			mask={getMask()}
		/>
	)
}

const useMaskedValue = (initialValue: string) => {
	const [formattedValue, setFormattedValue] = useState(initialValue)

	const maskValue = useCallback((value: string) => {
		const mask = getMask()
		const { conformedValue } = conformToMask(value, mask)

		const isEmpty = !onlyNumbers(value).length
		const maskedValue = isEmpty ? '' : conformedValue.replace(/_/g, '')

		setFormattedValue(maskedValue)

		return isEmpty ? undefined : conformedValue.replace(/_/g, '')
	}, [])

	return { formattedValue, maskValue, setFormattedValue }
}

const InputCurrencyLegacyComponent: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputCurrencyProps> = (
	{ id, label, optionalLabel, helperText, errorText, ...rest },
	ref,
) => {
	const classes = styles({})
	const hasError = !!errorText

	return (
		<div className={classes.box}>
			<InputLabel
				label={label}
				htmlFor={id}
				error={hasError}
				disabled={rest.disabled}
				optionalLabel={optionalLabel}
				bottomSpacing
			/>
			{hasError && <FieldErrorMessage id={id} message={errorText} />}
			<OutlinedInput
				{...rest}
				id={id}
				minRows={MIN_CURRENCY_ROWS}
				maxRows={MAX_CURRENCY_ROWS}
				fullWidth
				inputRef={ref}
				error={hasError}
				inputComponent={TextMaskCustom as React.ElementType<InputBaseComponentProps>}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</div>
	)
}

export const InputCurrency = React.forwardRef(
	({ onChange: onChangeFromProps, ...otherProps }: InputProps, forwardedRef: React.ForwardedRef<HTMLInputElement>) => {
		const { formattedValue, maskValue } = useMaskedValue('')

		const onInputChangedHandler = useCallback(
			(evt: React.ChangeEvent<HTMLInputElement>) => {
				const isControlled = onChangeFromProps && typeof onChangeFromProps === 'function'
				if (isControlled) {
					onChangeFromProps(evt)
				}

				const { value } = evt.target
				maskValue(`${value}`)
			},
			[maskValue, onChangeFromProps],
		)

		const hasError = otherProps.hasError || !!otherProps.errorText

		useEffect(() => {
			if (!unformat(`${otherProps.value}`)) {
				return
			}
			maskValue(`${otherProps.value}`)
		}, [maskValue, otherProps.value])

		return (
			<Input
				{...otherProps}
				ref={forwardedRef}
				value={formattedValue}
				hasError={hasError}
				onChange={onInputChangedHandler}
			/>
		)
	},
)

export const InputCurrencyLegacy = React.forwardRef(InputCurrencyLegacyComponent)
