import { useLogService as logService } from 'admin-portal-shared-services'
import SupportedLanguages from '@/domains/configurations/SupportedLanguages'
import { API_URL } from '@/utils/constants'
import axios from '@/utils/axiosInstance'

const log = logService()

export const getSupportedlanguages = (): Promise<SupportedLanguages> => {
	return axios
		.get<SupportedLanguages>(API_URL.GET_SUPPORTED_LANGUAGE)
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			log.error(`Error endpoint: ${API_URL.GET_SUPPORTED_LANGUAGE}`, error)
			throw error
		})
}
