import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		cancelButton: {
			fontSize: '14px',
			lineHeight: '24px',
			backgroundColor: theme.palette.common.white,
		},
		duplicateButton: {
			marginLeft: '16px',
		},
		buttonsContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		container: {
			marginBottom: '24px',
		},
	}),
)

export default styles
