import { useLogService as logService } from 'admin-portal-shared-services'
import ChallengePatchRequest from 'domains/challenge/patch/ChallengePatchRequest'
import ChallengePatchResponse from 'domains/challenge/patch/ChallengePatchResponse'
import axios from '@/utils/axiosInstance'
import Challenge, { ChallengeContent, ChallengeParam } from '@/domains/Challenge'

const log = logService()

const endPoint = `/v1/rewards-service/challenges`
const endPointV2 = `/rewards-service/v2/challenges`
const endPointBff = `/rewards-admin-service/v1/challenges`

function upsert(challenge: Challenge): Promise<Challenge> {
	return axios
		.put<Challenge>(`${endPointBff}/${challenge.id}`, {
			...challenge,
			type: 'EXECUTION_PTC',
		})
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			log.error(`Error endpoint: ${endPointBff}`, error)
			throw error
		})
}

function loadAll(params: ChallengeParam): Promise<ChallengeContent> {
	return axios
		.get<ChallengeContent>(`${endPointV2}`, { params })
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			log.error(`Error endpoint: ${endPointV2}`, error)
			throw error
		})
}

function load(id: string): Promise<Challenge> {
	return axios
		.get<Challenge>(`${endPoint}/${id}`)
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			log.error(`Error endpoint: ${endPoint}`, error)
			throw error
		})
}

function deleteChallenge(id: string): Promise<void> {
	return axios
		.delete<void>(`${endPoint}/${id}`)
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			log.error(`Error endpoint: ${endPoint}`, error)
			throw error
		})
}

/**
 * Update a Challenge only with fields to be updated
 * @param challenge Challenge object, Id property is Required
 */
function patchChallenge(challenge: ChallengePatchRequest): Promise<ChallengePatchResponse> {
	return axios
		.patch<Challenge>(`${endPoint}/${challenge.id}`, {
			...challenge,
			type: 'EXECUTION_PTC',
		})
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			log.error(`Error endpoint: ${endPoint}`, error)
			throw error
		})
}

export { upsert, loadAll, load, deleteChallenge, patchChallenge }
