import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export type TypeFilterList = {
	value: string
	label: string
}

const useChallengeFilterList = (): TypeFilterList[] => {
	const { t } = useTranslation()

	return useMemo(() => [{ value: 'groupIds', label: t('challenge:FILTER_LIST.GROUP_IDS') }], [t])
}

export default useChallengeFilterList
