import { MAX_INTEGER_SIZE } from '@/utils/constants'
import { FormHelperText, OutlinedInput, OutlinedInputProps, InputBaseComponentProps } from '@material-ui/core'
import FieldErrorMessage from 'components/formik/FieldErrorMessage'
import InputLabel from 'components/formik/InputLabel'
import React from 'react'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'
import styles from '../../formik/styles'

export type InputNumberProps = {
	id: string
	label: string
	optionalLabel?: string
	helperText?: string
	errorText?: string
} & OutlinedInputProps

interface TextMaskCustomProps extends InputBaseComponentProps {
	inputRef: (ref: HTMLInputElement | null) => void
}

const MIN_NUMBER_ROWS = 3
const MAX_NUMBER_ROWS = 6

const TextMaskCustom: React.FC<TextMaskCustomProps> = ({ inputRef, ...other }) => {
	const numberMask = createNumberMask({
		prefix: '',
		includeThousandsSeparator: false,
		allowDecimal: false,
		allowNegative: false,
		allowLeadingZeroes: false,
		integerLimit: MAX_INTEGER_SIZE,
	})

	return (
		<MaskedInput
			{...(other as MaskedInputProps)}
			ref={(ref: MaskedInput) => {
				inputRef(ref ? (ref.inputElement as HTMLInputElement) : null)
			}}
			mask={numberMask}
		/>
	)
}

const InputNumber: React.ForwardRefRenderFunction<HTMLInputElement, InputNumberProps> = (
	{ id, label, optionalLabel, helperText, errorText, ...rest },
	ref,
) => {
	const classes = styles({})
	const hasError = Boolean(errorText)

	return (
		<div className={classes.box}>
			<InputLabel
				label={label}
				htmlFor={id}
				error={hasError}
				disabled={rest.disabled}
				optionalLabel={optionalLabel}
				bottomSpacing
			/>
			{hasError && <FieldErrorMessage id={id} message={errorText} />}
			<OutlinedInput
				{...rest}
				id={id}
				minRows={MIN_NUMBER_ROWS}
				maxRows={MAX_NUMBER_ROWS}
				fullWidth
				inputRef={ref}
				error={hasError}
				inputComponent={TextMaskCustom as React.ElementType<InputBaseComponentProps>}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</div>
	)
}

export default React.forwardRef(InputNumber)
