import { RewardsProgram } from '@/domains'
import RewardsProjection from '@/domains/enums/RewardsProjectionEnum'
import * as RewardsService from '@/services/rewards/RewardsService'

async function execute(accountIds: Array<string>): Promise<Array<RewardsProgram>> {
	return RewardsService.loadAll({
		page: 0,
		pageSize: 100,
		projection: RewardsProjection.DEFAULT,
		accountIds,
	}).then((response) => response.content)
}

export { execute }
