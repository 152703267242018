import React from 'react'
import { useTranslation } from 'react-i18next'
import { ParAddedtolist } from '@admin-portal-shared-components/icons'
import { GridItem } from '@/components/templates/GridTemplate'
import { Button } from '@hexa-ui/components'
import useStyles from './CreateRuleActionsStyle'

type Props = {
	disabled: boolean
	onCancel: () => void
	onSubmit: () => void
}

const FormActionsView: React.FC<Props> = ({ disabled, onCancel, onSubmit }: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<GridItem className={classes.actionsContainer}>
			<Button
				type="button"
				variant="secondary"
				color="primary"
				id="cancel-btn"
				className={classes.button}
				onClick={() => onCancel()}
			>
				{t('BUTTON.CANCEL')}
			</Button>

			<Button
				icon={() => <ParAddedtolist size="large" />}
				leading
				id="submit-bnt"
				size="medium"
				variant="primary"
				disabled={disabled}
				onClick={() => onSubmit()}
			>
				{t('common:BUTTON.SAVE')}
			</Button>
		</GridItem>
	)
}

export default React.memo(FormActionsView)
