import React from 'react'
import AccountGroupFormView from './AccountGroupFormView'

type Props = {
	challengeId?: string | null
}

const AccountGroupForm: React.FC<Props> = (props: Props) => {
	return <AccountGroupFormView {...props} />
}

export default AccountGroupForm
