import { Item } from '@/domains'
import VariantsType from '@/domains/Item/variantsType'
import { RedeemableItemType } from '@/domains/enums'
import CreateRedeemableItemRequest from '@/domains/redeemable/CreateRedeemableItemRequest'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { loadAllItems } from '@/services/item/ItemService'
import { createRedeemable } from '@/services/redeemables/RedeemablesService'
import { RedeemableItemsStoreEvents } from '@/stores'
import { CreateRedeemableItemStoreEvents } from '@/stores/redeemableItems/CreateRedeemableItemStore'
import { useLogService as logService } from 'admin-portal-shared-services'

interface CreateRedeemablePayload {
	redeemables: RedeemableItem[]
	pricePerPoint?: number
}

async function publish(payload: CreateRedeemablePayload): Promise<void> {
	CreateRedeemableItemStoreEvents.onPublishing()
	const log = logService()
	const { pricePerPoint } = payload

	try {
		const requestData: CreateRedeemableItemRequest[] = []
		for (const item of payload.redeemables) {
			const { vendorId, vendorItemId } = item
			const pricePerPointNumber = !pricePerPoint || pricePerPoint === 0 ? undefined : +pricePerPoint
			requestData.push({
				vendorId,
				vendorItemId,
				type: RedeemableItemType.ITEM,
				pricePerPoint: pricePerPointNumber,
			})
			// eslint-disable-next-line no-await-in-loop
		}
		await createRedeemable(requestData)

		RedeemableItemsStoreEvents.reloadPage()
		CreateRedeemableItemStoreEvents.published()
		CreateRedeemableItemStoreEvents.reset()
	} catch (error) {
		log.error('FAILED TO PUBLISH REDEEMABLE ITEM: ', error)
		CreateRedeemableItemStoreEvents.errorOnPublish()
		throw error
	}
}
async function onSearch(value: string): Promise<void> {
	if (!value) return

	const log = logService()
	CreateRedeemableItemStoreEvents.onSearching()

	try {
		const { items } = await loadAllItems({ vendorItemIds: [value], variantsType: VariantsType.ALL })
		if (!items?.length) CreateRedeemableItemStoreEvents.itemNotFound()
		items.forEach((item, index) => {
			const redeemable = items.map(itemMapper)[index]
			if (index === 0) {
				CreateRedeemableItemStoreEvents.setItem(redeemable)
			} else {
				CreateRedeemableItemStoreEvents.setVariants(redeemable)
			}
		})
	} catch (error) {
		log.error('FAILED TO GET ITEMS: ', error)
		CreateRedeemableItemStoreEvents.errorOnItems()
	}
}
const itemMapper = (item: Item): RedeemableItem => {
	const { vendorItemId, vendorId, itemName, itemImage } = item
	return {
		id: '',
		vendorItemId,
		vendorId,
		itemName,
		image: itemImage,
		fullPackageDescription: item.package?.fullPackageDescription,
		fullContainerDescription: item.container?.fullContainerDescription,
	}
}

export { onSearch, publish }
