import { RedeemableStatus } from '@/domains/enums'
import { RedeemablesMap } from '@/domains/redeemable/Redeemable'
import * as RedeemablesService from '@/services/redeemables/RedeemablesService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { createIdMapFromArray } from '@/utils/mappingUtil'
import { convertToRedeemables } from '@/utils/redeemable'

export async function execute(status: Array<RedeemableStatus>): Promise<{
	redeemablesMap: RedeemablesMap
}> {
	const vendorId = getDefaultVendorIdOfCurrentZone()
	const data = await RedeemablesService.loadRedeemablesByStatus({
		vendorId,
		status,
		page: 0,
		pageSize: 900000,
	})
	const redeemables = convertToRedeemables(data.content)
	const redeemablesMap = createIdMapFromArray('redeemableId', redeemables)
	return {
		redeemablesMap,
	}
}
