import React, { ReactNode } from 'react'
import { Typography, Grid, Box } from '@material-ui/core'
import useStyles from './ContainerStyle'

type Props = {
	title: string
	children: ReactNode
}

const ContainerView: React.FC<Props> = ({ title, children }: Props) => {
	const classes = useStyles()

	return (
		<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
			<Typography variant="h2" className={classes.title}>
				{title}
			</Typography>
			<Grid container justifyContent="center">
				<Grid item xl={6} lg={8} md={9} sm={12}>
					<Box mb={6}>{children}</Box>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ContainerView
