import React, { ReactElement, ReactNode } from 'react'
import { Paper as MaterialPaper, Grow } from '@material-ui/core'
import clsx from 'clsx'
import styles from './styles'

type Props = {
	animate?: boolean
	children: ReactNode
	className?: string
	hidePadding?: boolean
}

/**
 * This is a custom Paper derived from Material UI Paper component.
 * @description Specs: variant outlined modified
 */
const Paper: React.FC<Props> = ({ animate, children, className, hidePadding }: Props) => {
	const classes = styles({ hidePadding })

	const component: ReactElement = (
		<MaterialPaper className={clsx(classes.paper, className)} variant="outlined">
			{children}
		</MaterialPaper>
	)

	return animate ? <Grow in={animate}>{component}</Grow> : <>{component}</>
}

Paper.defaultProps = {
	animate: true,
}

export default React.memo(Paper)
