import { ComboType, ComboValidateType } from '@/domains/enums'
import { hasElements } from '@/utils/array'
import * as LoadComboUseCase from './LoadComboUseCase'

async function execute(comboId: string): Promise<ComboValidateType> {
	try {
		const responseCombos = await LoadComboUseCase.executeByVendorComboIds([comboId])
		if (!hasElements(responseCombos)) {
			return ComboValidateType.EnterValidCombo
		}
		const resultedCombo = responseCombos[0]
		if (!resultedCombo) {
			return ComboValidateType.EnterValidCombo
		}
		if (resultedCombo.type !== ComboType.DigitalTrade && resultedCombo.type !== ComboType.DigitalTradeThirdParty) {
			return ComboValidateType.EnterValidCombo
		}
		return ComboValidateType.Valid
	} catch {
		return ComboValidateType.EnterValidCombo
	}
}

export { execute }
