import palette from './palette'

export default {
	fontFamily: 'Work Sans',
	root: {
		color: 'black',
	},
	h1: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '35px',
		letterSpacing: '0',
		lineHeight: '40px',
	},
	h2: {
		fontFamily: 'Barlow',
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '32px',
		letterSpacing: '0',
		lineHeight: '40px',
	},
	h3: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '24px',
		letterSpacing: '0',
		lineHeight: '28px',
	},
	h4: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '20px',
		letterSpacing: '0',
		lineHeight: '24px',
	},
	h5: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '16px',
		letterSpacing: '0',
		lineHeight: '20px',
	},
	h6: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '14px',
		letterSpacing: '0',
		lineHeight: '20px',
	},
	subtitle1: {
		color: palette.text.primary,
		fontSize: '16px',
		letterSpacing: '0',
		lineHeight: '25px',
	},
	subtitle2: {
		color: palette.text.secondary,
		fontWeight: 400,
		fontSize: '12px',
		letterSpacing: '0',
		lineHeight: '16px',
		marginTop: '4px',
	},
	body1: {
		color: palette.text.primary,
		fontSize: '16px',
		letterSpacing: '0',
		lineHeight: '21px',
	},
	body2: {
		fontSize: '14px',
		letterSpacing: '0',
		lineHeight: '20px',
	},
	button: {
		color: palette.text.primary,
		fontSize: '14px',
	},
	caption: {
		color: palette.text.secondary,
		fontSize: '11px',
		letterSpacing: '0',
		lineHeight: '13px',
	},
	overline: {
		color: palette.text.secondary,
		fontSize: '11px',
		fontWeight: 500,
		letterSpacing: '0',
		lineHeight: '13px',
		textTransform: 'uppercase',
	},
}
