import React from 'react'
import CustomHexaInput from '@/components/form/InputText/CustomHexaInput'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ChallengeSetupFormData } from '@/stores/challengeCreation'

type ChallengeVisionIdInputProps = {
	disableIdField: boolean
}
const ChallengeVisionIdInput: React.FC<ChallengeVisionIdInputProps> = ({ disableIdField }) => {
	const { t } = useTranslation()
	const { control } = useFormContext<ChallengeSetupFormData>()

	return (
		<Controller
			control={control}
			name="visionId"
			defaultValue=""
			render={({ field, fieldState: { error } }) => (
				<CustomHexaInput
					id={field.name}
					data-testid="challenge-visionId-input"
					label={t('challenge:SETUP_STEP.VISION_RULE_ID')}
					errorText={error?.message}
					disabled={disableIdField}
					{...field}
				/>
			)}
		/>
	)
}

export default ChallengeVisionIdInput
