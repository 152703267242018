import { Transaction } from '@/routes/transactions/components/TransactionLogV2/TransactionLog.types'
import * as TransactionsService from '@/services/transactions/TransactionsService'

export async function execute(): Promise<Transaction[] | null> {
	try {
		const transactions = await TransactionsService.load()
		return transactions
	} catch (error: any) {
		console.error(`Failed to load transactions. ${error.message}`)
		return null
	}
}
