import { TransactionResponseV2 as Transaction } from '@/domains/transaction/TransactionResponse'
import { useLogService as logService } from 'admin-portal-shared-services'

import getTransaction from '@/services/transaction/getTransactionService'

const log = logService()

async function getTransactionUseCase({ transactionId }: { transactionId: string }): Promise<Transaction | null> {
	try {
		const transaction = await getTransaction(transactionId)
		return transaction
	} catch (error) {
		log.error(`Error getTransactionUseCase: ${transactionId}`, error)
		throw error
	}
}

export default getTransactionUseCase
