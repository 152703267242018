import { useCallback } from 'react'
import { UpdateBulkComboUseCase } from '@/usecase'
import { useStore } from 'effector-react'
import { ComboStore, ProgramCombo } from '@/stores'
import { RecordType, RedeemableRequest, ScreenName } from '@/domains'
import { RedeemableType } from '@/domains/enums'
import { useAnalyticsServiceRecordUpdated } from '@/hooks/analyticsService/useAnalyticsServiceEventsRecordUpdated'
import useAuditLogDTcombos from '@/hooks/useAuditLogInstance/BusinessLogicLayer/combosDT'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'

export type SubmitOneByOneHandler = () => Promise<void>

const useComboSubmitOneByOne = (): SubmitOneByOneHandler => {
	const { formData } = useStore(ComboStore)
	const { sendAnalyticsServiceRecordUpdated } = useAnalyticsServiceRecordUpdated()
	const { sendAuditLogDTCombos } = useAuditLogDTcombos()

	const submitRedeemablesByVendor = useCallback(
		async (combos: Array<ProgramCombo>): Promise<void> => {
			const recordId = combos.map((item: ProgramCombo) => {
				return item.comboId
			})
			const points = combos.map((item: ProgramCombo) => {
				return item.points
			})

			const redeemables: Array<RedeemableRequest> = combos.map((combo) => ({
				points: combo.points.toString(),
				type: RedeemableType.Combo,
				vendorComboId: combo.comboId,
			}))
			await UpdateBulkComboUseCase.execute({
				formData: { redeemables },
			})
			sendAnalyticsServiceRecordUpdated({
				recordId: recordId.toString(),
				recordType: RecordType.DTCombo,
				screenName: ScreenName.DTComboManagementPublishedEditModal,
			})
			sendAuditLogDTCombos({
				recordId: recordId.toString(),
				operation: IAuditOperations.INSERT,
				points: points.toString(),
			})
		},
		[sendAnalyticsServiceRecordUpdated, sendAuditLogDTCombos],
	)

	return useCallback(async (): Promise<void> => {
		if (formData?.combos) {
			return submitRedeemablesByVendor(formData.combos)
		}
		throw new Error('Combos are required!')
	}, [formData?.combos, submitRedeemablesByVendor])
}

export default useComboSubmitOneByOne
