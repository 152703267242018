import { Challenge } from '@/domains'
import ChallengeHistoryDomain from '@/domains/ChallengeHistory'
import { DuplicateChallengePayload } from '@/stores/challengeCreation'

import {
	challengeToChallengeDetailsFormData,
	challengeToChallengeFilterFormData,
	challengeToChallengeSetupFormData,
} from './NewChallengeStateValuesToChallenge'

const newConvertChallengeHistoryToChallenge = (challengeHistory: ChallengeHistoryDomain): DuplicateChallengePayload => {
	const challenge: Challenge = {
		...challengeHistory,
		startDate: challengeHistory.startDate!,
		endDate: challengeHistory.endDate!,
		filter: {
			groupIds: challengeHistory.groupsFilter?.map((group) => group.id),
		},
		type: 'EXECUTION_PTC',
		challengeGoal: '',
	}
	const challengeSetupFormData = challengeToChallengeSetupFormData(challenge)
	challengeSetupFormData.id = ''

	const challengeFilterFormData = challengeToChallengeFilterFormData(challenge)

	delete challengeFilterFormData.startDate
	delete challengeFilterFormData.endDate

	return {
		challengeSetupFormData,
		challengeFilterFormData,
		challengeDetailsFormData: challengeToChallengeDetailsFormData(challenge),
	}
}

export { newConvertChallengeHistoryToChallenge }
