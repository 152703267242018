import { isToggleRefundRedeemableItemsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { Grow } from '@material-ui/core'
import InputTextWithValidation from 'components/formik/InputTextWithValidation'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CombosList from '../CombosList/CombosList'
import OrderSection from './components/OrderSection/index'
import { RedeemablesList } from './components/RedeemablesList/RedeemablesList'

type Props = {
	validateOrderId: (orderId: string) => Promise<string>
	showError: boolean
	onError?: (id: string, message: string) => void
	isContainerVisible?: boolean
	handleOrderIdKeyUp?: () => void
}

const TransactionRefundView: React.FC<Props> = ({
	validateOrderId,
	showError,
	isContainerVisible,
	handleOrderIdKeyUp,
	onError,
}: Props) => {
	const { t } = useTranslation()
	const emptyFieldErrorMessage = t('ERROR_MESSAGE.EMPTY_FIELD')

	return (
		<>
			<InputTextWithValidation
				id="transaction-order-id"
				name="orderId"
				label={t('transaction:ORDER_ID')}
				validate={validateOrderId}
				required={emptyFieldErrorMessage}
				isFormValidating={showError}
				watchFormikValue
				hideErrorOnChange
				onKeyUpFirstKey={handleOrderIdKeyUp}
				onError={onError}
			/>
			{isContainerVisible && (
				<Grow in>
					<>
						<OrderSection />
						{isToggleRefundRedeemableItemsEnabled() ? <RedeemablesList /> : <CombosList />}
					</>
				</Grow>
			)}
		</>
	)
}

export default TransactionRefundView
