import TransparencyTransaction from 'domains/transparency/TransparencyTransaction'
import { loadTransactions } from 'services/transparency/TransparencyService'
import { HTTP_LOCKED } from 'utils/constants'
import { load, loadDone } from 'stores/global/GlobalEvents'

async function execute(transparencyId: string): Promise<TransparencyTransaction[]> {
	load()
	const transparencyTransactions = await loadTransactions(transparencyId)
		.catch(() => ({ content: [] }))
		.finally(() => {
			loadDone()
		})
	return transparencyTransactions.content.filter(({ errorType }) => errorType !== null && errorType !== HTTP_LOCKED)
}

export { execute }
