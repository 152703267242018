import React, { useEffect, useState } from 'react'
import { Toast } from '@hexa-ui/components'
import { ToastEvents, ToastStore } from '@/stores'
import { useStoreMap } from 'effector-react'
import useStyles from './styles'

type Props = {
	width?: string
}

const ToastTemplate: React.FC<Props> = ({ width }) => {
	const [showToast, setShowToast] = useState<boolean>(false)
	const { Provider, Root } = Toast
	const classes = useStyles({ width })

	const { notificationType, notificationMessage } = useStoreMap({
		store: ToastStore,
		keys: [],
		fn: (state) => ({
			notificationType: state.notificationType,
			notificationMessage: state.notificationMessage,
		}),
	})

	useEffect(() => {
		if (notificationType) {
			setShowToast(true)
		}
	}, [notificationType])

	return (
		<div className={classes.toastStyle}>
			<Provider duration={3000} swipeDirection="right">
				<Root
					message={notificationMessage}
					onOpenChange={() => {
						setShowToast(false)
						ToastEvents.toastHideNotification()
					}}
					style={{ width: '100%' }}
					position="bottom"
					showCloseButton
					type={notificationType}
					open={showToast}
				/>
			</Provider>
		</div>
	)
}

export default React.memo(ToastTemplate)
