import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		root: {
			'& .membership-MuiInputBase-root': {
				minHeight: '48px',
				borderRadius: '8px',
				height: 'auto',
			},
		},
		chip: {
			borderRadius: '25px',
			paddingRight: '4px',
			paddingLeft: '4px',
			backgroundColor: 'rgb(233,241,255);',
			'&:focus': {
				backgroundColor: 'rgb(233,241,255);',
			},
		},
		deleteIcon: {
			width: '14px',
			color: 'rgb(3,99,196)',
		},
		inputChip: {
			marginBottom: '2px',
		},
		inputRoot: {
			borderRadius: '8px',
			paddingLeft: '12px',
			paddingBottom: '8px!important',
			paddingTop: '8px!important',
			'& > input': {
				padding: '4px!important',
			},
		},
	}),
)

export default styles
