import TransparencyStatus from 'domains/enums/TransparencyStatusEnum'
import TransparencyHistory from 'domains/transparency/TransparencyHistory'
import React, { useEffect, useState } from 'react'
import {
	LoadTransparencyTransactionsUseCase as loadTransparencyTransactionsUseCase,
	LoadTransparencyLogUseCase as loadTransparencyLogUseCase,
} from '@/usecase'
import AnalyticsService from 'services/analytics/AnalyticsService'
import ScreenName from 'domains/analytics/ScreenName'
import ButtonInteraction from 'domains/analytics/ButtonInteraction'
import * as TransactionEvents from 'stores/transaction/TransactionEvents'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import TransactionLogView from './TransactionLogView'

const TransactionLog: React.FC = () => {
	const [loading, setLoading] = useState<boolean>(true)
	const userInfo = useGetUserInfo()
	const [showError, setShowError] = useState<boolean>(false)
	const [transparencyLog, setTransparencyLog] = useState<Array<TransparencyHistory>>([])

	useEffect(() => {
		AnalyticsService.events.pageViewed({
			screen_name: ScreenName.PointsManagementTransactionLog,
			...userInfo,
		})
		setLoading(true)
		loadTransparencyLogUseCase
			.execute()
			.then((transparencyLogs) => {
				setTransparencyLog(transparencyLogs)
				setLoading(false)
			})
			.catch(() => {
				setLoading(false)
				setShowError(true)
			})
	}, [userInfo])

	const onReviewId = (poc: TransparencyHistory) => {
		AnalyticsService.events.linkClicked({
			link_label: 'Review',
			link_name: ButtonInteraction.ReviewIDs,
			screen_name: ScreenName.PointsManagementTransactionLog,
			...userInfo,
		})

		loadTransparencyTransactionsUseCase.execute(poc.id).then((transactions) => {
			TransactionEvents.showActivityLogDialog({ failedPocs: transactions, description: poc.description, id: poc.id })
		})
	}

	const reviewIdWillRender = (transparency: TransparencyHistory): boolean => {
		return transparency.status.indexOf(TransparencyStatus.Failed) >= 0
	}

	const handleAddNewTransaction = () => {
		AnalyticsService.events.buttonClicked({
			button_label: '+ ADD NEW TRANSACTION',
			button_name: ButtonInteraction.AddNewTransaction,
			screen_name: ScreenName.PointsManagementTransactionLog,
			...userInfo,
		})
	}

	return (
		<TransactionLogView
			transparencyLogs={transparencyLog}
			isLoading={loading}
			showError={showError}
			onReviewId={onReviewId}
			reviewIdWillRender={reviewIdWillRender}
			handleAddNewTransaction={handleAddNewTransaction}
		/>
	)
}

export default TransactionLog
