import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import AccountGroupForm from '@/routes/accountGroup/components/AccountGroupForm/AccountGroupForm'
import { AccountGroupFormData } from '@/domains/AccountGroup'
import { isToggleSpendBasedChallengeEnabled } from '@/utils/featureToggle/featureToggleHelper'
import RedesignFormDialogTemplate from '@/components/templates/FormDialogTemplate/redesignFormDialogTemplate'
import AccountGroupCSVPreview from './AccountGroupCSVPreview'
import { useIsAccountGroupModalVisible } from '../../hooks/useIsAccountGroupModalVisible'
import useGroupOptionsMap from '../../hooks/useGroupOptionsMap'
import { setErrorFile } from './accountGroupFormModalUtil'

type Props = {
	onCancel: () => void
	submitForm: (formData: AccountGroupFormData) => Promise<void>
	challengeId?: string
	exitTransition?: number
	isIndividualTarget: boolean
}

const AccountGroupFormModalView: React.FC<Props> = ({
	onCancel,
	submitForm,
	challengeId,
	exitTransition,
	isIndividualTarget,
}) => {
	const { t } = useTranslation()
	const isAccountGroupModalVisible = useIsAccountGroupModalVisible()
	const isSpendBasedChallenge = isToggleSpendBasedChallengeEnabled()
	const { currentGoal } = useGroupOptionsMap()

	const {
		handleSubmit,
		formState: { errors, isValid },
		setError,
	} = useFormContext()

	const errorMessageMap = {
		INVALID_GOAL: t('ERROR_MESSAGE.INVALID_GOAL', { goal: currentGoal?.toLocaleLowerCase() }),
		INVALID_INDIVIDUAL_TARGET: t('ERROR_MESSAGE.UPLOAD_FILE_ACCOUNT_INDIVIDUAL_GROUP'),
		INVALID_FILE: t('ERROR_MESSAGE.UPLOAD_FILE_ACCOUNT_GROUP'),
	}

	const onFormSubmit = async (formData: AccountGroupFormData) => {
		submitForm(formData).catch((err) => {
			const { error, status } = err

			if (status === 409 && error.code === 20)
				setError('groupName', { type: 'manual', message: t('ERROR_MESSAGE.NAME_ALREADY_EXIST') })
			else {
				setErrorFile({ err: err.message, errorMessageMap, setError, isIndividualTarget })
			}
		})
	}

	const handleClickSubmitButton = () => {
		handleSubmit(onFormSubmit)()
	}

	return (
		<RedesignFormDialogTemplate
			title={t('challenge:FILTERS_STEP.GROUP.CREATE_NEW_GROUP')}
			showDialog={isAccountGroupModalVisible}
			cancelButtonProps={{
				name: t('BUTTON.CANCEL'),
				handleClick: onCancel,
			}}
			submitButtonProps={{
				name: t('BUTTON.CREATE_NEW_GROUP'),
				type: 'button',
				handleClick: handleClickSubmitButton,
				disabled: !!errors.groupName || !!errors.file || !isValid,
				tooltipTitle: t('TOOLTIP_MESSAGE.GROUP_FILE'),
				disabledAutoFocus: true,
			}}
			transitionDuration={{ exit: exitTransition }}
		>
			{isIndividualTarget && (
				<>
					<AccountGroupCSVPreview />
					{isSpendBasedChallenge && <AccountGroupCSVPreview isMoney />}
				</>
			)}
			<AccountGroupForm challengeId={challengeId} />
		</RedesignFormDialogTemplate>
	)
}

export default AccountGroupFormModalView
