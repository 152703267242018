import InputTextWithDelayedValidation from '@/components/form/InputTextWithDelayedValidation'
import { Challenge } from '@/domains'
import { ChallengeSetupFormData, ChallengeStore } from '@/stores/challengeCreation'
import { LoadChallengesUseCase } from '@/usecase'
import { useStoreMap } from 'effector-react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
	disableIdField?: boolean
	isNewDSM?: boolean
}

const ChallengeFormSetupId: React.FC<Props> = ({ disableIdField, isNewDSM }: Props) => {
	const { t } = useTranslation()
	const { challengeId } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => ({
			challengeId: state.challengeSetupFormData?.id,
		}),
	})
	const { setError, clearErrors } = useFormContext<ChallengeSetupFormData>()

	const handleChallengeIdValidation = async (textValue: string): Promise<boolean> => {
		if (textValue === challengeId) {
			return Promise.resolve(true)
		}
		return LoadChallengesUseCase.executeById(textValue).then((challenge: Challenge | null) => {
			if (challenge?.id) {
				setError('id', {
					message: t('ERROR_MESSAGE.CHALLENGE_FOUND_BY_ID'),
				})
			} else {
				clearErrors('id')
			}
			return Promise.resolve(!challenge?.id)
		})
	}

	return (
		<InputTextWithDelayedValidation
			label={t('challenge:SETUP_STEP.CHALLENGE_ID')}
			name="id"
			id="digital-poc-id"
			test-id="challenge-id-input"
			disabled={disableIdField}
			onValidating={async (eventValue) => {
				await handleChallengeIdValidation(eventValue)
			}}
			isNewDSM={isNewDSM}
		/>
	)
}

export default ChallengeFormSetupId
