import React from 'react'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import TablePageTemplate from '@/components/templates/TablePageTemplate'
import { useTranslation } from 'react-i18next'
import Table from './components/Table'

const ZoneConfigurationRoute: React.FC = () => {
	const { t } = useTranslation()

	return (
		<TablePageTemplate title={t('settings:TITLE')}>
			<PageLevelTemplate />
			<Table />
		</TablePageTemplate>
	)
}

export default ZoneConfigurationRoute
