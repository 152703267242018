import { BadgeStatusType } from '@/components/core/BadgeStatus'
import { RedeemableItemStatus } from '@/domains/enums/redeemable'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export type UseRedeemableItemStatusResult = {
	buildStatusProps: (item: RedeemableItem) => RedeemableStatusProps
}

export type RedeemableStatusProps = {
	text: string
	type: BadgeStatusType
	infoMessage?: string
}

export const useRedeemableItemStatus = (): UseRedeemableItemStatusResult => {
	const { t } = useTranslation()

	const buildStatusProps = useCallback(
		(item: RedeemableItem): RedeemableStatusProps => {
			let text = ''
			let type = BadgeStatusType.disabled
			let infoMessage

			switch (item.status) {
				case RedeemableItemStatus.PROCESSING:
					text = t('redeemable:ITEMS_LIST_TABLE.STATUS_PROCESSING')
					type = BadgeStatusType.info
					break
				case RedeemableItemStatus.FAILED:
					text = t('redeemable:ITEMS_LIST_TABLE.STATUS_FAILED')
					type = BadgeStatusType.error
					infoMessage = t('redeemable:ITEMS_LIST_TABLE.STATUS_FAILED_MESSAGE')
					break
				case RedeemableItemStatus.PUBLISHED:
					text = t('redeemable:ITEMS_LIST_TABLE.STATUS_ACTIVE')
					type = BadgeStatusType.success
					break
				default:
					break
			}

			return { text, type, infoMessage }
		},
		[t],
	)

	return {
		buildStatusProps,
	}
}
