import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const PointsRemovalStyle = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontWeight: 500,
			fontSize: '16px',
		},
		value: {
			fontWeight: 400,
			fontSize: '16px',
		},
		valueWithError: {
			fontWeight: 400,
			fontSize: '16px',
			color: theme.palette.error.main,
		},
	}),
)

export default PointsRemovalStyle
