import React, { useEffect } from 'react'
import { PageEnvironmentInfo } from '@/components/core'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'
import { GlobalStore } from '@/stores'
import { useUserMetadata } from 'admin-portal-shared-services'
import useMainContent from './hooks/useMainContent'

type Props = {
	children: React.ReactNode
}

const AppMainContent: React.FC<Props> = ({ children }: Props) => {
	const { selectedProgram, isPageReady } = useStore(GlobalStore)

	const { hasError, init } = useMainContent()
	const { isLoading } = useUserMetadata()
	const { t } = useTranslation()

	useEffect(() => {
		if (!isLoading) init()
	}, [init, isLoading])

	if (hasError) {
		return (
			<div style={{ display: 'flex', padding: '15px 10px', width: '100%' }}>
				<PageEnvironmentInfo>{t('ERROR_MESSAGE.GENERIC_ERROR')}</PageEnvironmentInfo>
			</div>
		)
	}
	if (!isPageReady || !selectedProgram) {
		return null
	}
	return <>{children}</>
}

export default AppMainContent
