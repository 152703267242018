import { createStyles, makeStyles } from '@material-ui/core/styles'

const SkuListStyle = makeStyles(() =>
	createStyles({
		skuListCard: {
			position: 'relative',
			display: 'flex',
			width: '100%',
			maxWidth: 864,
			marginTop: '24px',
		},
	}),
)

export default SkuListStyle
