import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertDialog } from '@/components/core'
import { Typography } from '@material-ui/core'

type Props = {
	show?: boolean
	handleCancel: (buttonClicked: boolean) => void
	handleConfirm: (e: React.MouseEvent) => void
}

const ChallengeFormFilterEditConfirmation: React.FC<Props> = ({ show = false, handleCancel, handleConfirm }) => {
	const { t } = useTranslation()

	return (
		<AlertDialog
			title={t('challenge:CONFIRM_DIALOG.TITLE_POC_ADDITION')}
			show={show}
			handleCancel={handleCancel}
			handleConfirm={handleConfirm}
			buttonConfirmText={t('BUTTON.YES_SAVE')}
		>
			<Typography component="span">{t('challenge:CONFIRM_DIALOG.POC_ADDITION')}</Typography>
		</AlertDialog>
	)
}

export default ChallengeFormFilterEditConfirmation
