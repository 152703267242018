import ComboDetailed from 'domains/ComboDetailed'
import ComboRefundStatus from 'domains/enums/combo/ComboRefundStatus'
import React from 'react'
import { ResultListWrapper } from '../TransactionRefund/components/RedeemablesList/ResultListWrapper'
import ComboListRow from './ComboListRow'

type Props = {
	combos: Array<ComboDetailed>
}
const CombosListView: React.FC<Props> = ({ combos }: Props) => {
	const existFullyRefunded = combos.findIndex((combo) => combo.status === ComboRefundStatus.FullyRefunded) > -1
	const allAreFullyRefunded = combos.findIndex((combo) => combo.status !== ComboRefundStatus.FullyRefunded) === -1

	return (
		<ResultListWrapper existFullyRefunded={existFullyRefunded} data-testid="refund-combos-list-wrapper">
			{combos.map((combo, index) => (
				<ComboListRow
					key={combo.comboId}
					index={index}
					combo={combo}
					existFullyRefunded={existFullyRefunded}
					allAreFullyRefunded={allAreFullyRefunded}
				/>
			))}
		</ResultListWrapper>
	)
}

export default CombosListView
