import { CreateRuleFormData } from '@/domains/rule/Rule'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { IAuditEntities, IAuditLog, IAuditOperations } from '@/hooks/useAdminAuditLog'

interface IAuditLogEarningProps {
	formData: CreateRuleFormData
	categoryId: string
	isEditing: boolean
	entityId: string
}

interface IAuditLogEarningAddSkusProps {
	skus: string
	isMultipleStep: boolean
	entityId: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAdminAuditLogHook = () => {
	const userInfo = useGetUserInfo()

	const generateLogForEarningDescription = (auditProps: IAuditLogEarningProps): IAuditLog => {
		return {
			metadata: {
				description: auditProps.formData.description!,
				amount: auditProps.formData.amountSpent.toString(),
				points: auditProps.formData.points.toString(),
				categoryId: auditProps.categoryId,
				tier: userInfo.tier!,
				vendor: userInfo.vendor!,
			},
			operation: auditProps.isEditing ? IAuditOperations.UPDATE : IAuditOperations.INSERT,
			entity: IAuditEntities.EARNING_RULES,
			entityId: auditProps.entityId,
		}
	}

	const generateLogForEarningSkus = ({ skus, isMultipleStep, entityId }: IAuditLogEarningAddSkusProps): IAuditLog => {
		return {
			metadata: {
				skus,
				method: isMultipleStep ? 'MULTIPLE' : 'SINGLE',
				tier: userInfo.tier!,
				vendor: userInfo.vendor!,
			},
			operation: IAuditOperations.UPDATE,
			entity: IAuditEntities.EARNING_RULES,
			entityId,
		}
	}

	return { generateLogForEarningDescription, generateLogForEarningSkus }
}
