import React from 'react'
import { Grid, ContainerProps } from '@hexa-ui/components'
import useStyle from './styles'

interface GridContainerProps extends ContainerProps {
	children: React.ReactNode
}

const GridContainer: React.FC<GridContainerProps> = ({ children, ...props }) => {
	const classes = useStyle()

	return (
		<Grid.Container className={classes.container} {...props}>
			{children}
		</Grid.Container>
	)
}

export default GridContainer
