import { styled } from '@hexa-ui/theme'

export const NumberInputContainer = styled('div', {
	'input::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
		WebkitAppearance: 'none',
		margin: 0,
	},
	'&[type="number"]': {
		MozAppearance: 'textfield',
	},
})
