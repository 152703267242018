import React from 'react'
import { useTranslation } from 'react-i18next'
import { TransparencyHistory } from '@/domains'
import { TransparencyStatus } from '@/domains/enums'
import { ComboEvents } from '@/stores'
import DataTable from '@/components/tables/DataTable'
import { formatDate } from '@/utils/date'
import { Box, Typography } from '@material-ui/core'
import getStatusCellStyle from '@/utils/transparencyGetStatusCellStyle'
import ReviewItemsDialog from '../ReviewItemsDialog/ReviewItemsDialog'

type Props = {
	loading: boolean
	transparencyLog: TransparencyHistory[]
}

const ComboUploadHistoryView: React.FC<Props> = ({ loading, transparencyLog }) => {
	const { t } = useTranslation()
	const itemsPerPage = [10, 25, 50, 75, 100]

	return (
		<>
			<ReviewItemsDialog />
			<Box my={3}>
				<Typography>{t('combo:UPLOAD_HISTORY.INSTRUCTIONS')}</Typography>
			</Box>
			<DataTable
				id="combo-upload-history"
				columns={[
					{
						label: t('combo:UPLOAD_HISTORY.COLUMNS.FILE_NAME'),
						dataKey: 'fileName',
						align: 'left',
						width: '28%',
					},
					{
						label: t('combo:UPLOAD_HISTORY.COLUMNS.LAST_UPDATE'),
						dataKey: 'executionDate',
						align: 'left',
						width: '28%',
						format: (date): string => formatDate(date as Date, t('DATE_FORMAT_TABLE')),
					},
					{
						label: t('combo:UPLOAD_HISTORY.COLUMNS.STATUS'),
						dataKey: 'status',
						align: 'left',
						width: '28%',
					},
				]}
				data={transparencyLog}
				cellStyle={getStatusCellStyle}
				initialSort={[{ dataKey: 'executionDate', order: 'desc' }]}
				tableActions={[
					{
						type: 'edit',
						label: t('combo:UPLOAD_HISTORY.REVIEW_ACTION'),
						fn: (row) => ComboEvents.selectTransparencyId(row.id),
						willRender: (row) => row.status.indexOf(TransparencyStatus.Failed) >= 0,
					},
				]}
				configs={{
					actionAlign: 'left',
					renderRecordId: 'id',
					itemsPerPageOptions: itemsPerPage,
					style: {
						emptyMessage: {
							showIcon: true,
						},
					},
				}}
				loading={loading}
				emptyMessage={t('combo:UPLOAD_HISTORY.EMPTY_DATA_TABLE')}
			/>
		</>
	)
}

export default ComboUploadHistoryView
