import { DEFAULT_LANGUAGE } from '@/utils/constants'

const currentTimestamp = Math.floor(Date.now() / 1000)
const daysToAdd = 30
const futureTimestamp = currentTimestamp + daysToAdd * 24 * 60 * 60

export const tokenData = {
	iss: 'https://b2biamgbusdev.b2clogin.com/9c89de9e-e8e2-4516-887c-5fec487834e2/v2.0/',
	exp: futureTimestamp,
	nbf: 1617631310,
	aud: '1ff82dfb-fe6d-4558-bb1a-568b966cc95d',
	sub: 'e30fb268-b4f8-4a99-ac3e-3d6e52267900',
	email: 'andrerodrigues@ciandt.com',
	name: 'André Malta',
	given_name: 'André',
	family_name: 'Malta',
	country: 'DO',
	extension_tncaccepteddatetime: 1616419644,
	createdOn: 'adminportal',
	scopes: [
		'Membership.Challenge.Delete',
		'Membership.PointsManagement.Delete',
		'Membership.RewardsOffer.Delete',
		'Membership.Refund.Delete',
		'Membership.PointsTransfer.Delete',
		'Membership.PointsRemoval.Delete',
		'Membership.RewardsOffer.Delete',
		'Membership.DTComboManagement.Delete',
		'Membership.EarningRules.Delete',
		'Membership.TransactionHistory.Delete',
		'Membership.ProgramConfiguration.Delete',
		'Membership.RemoveForServiceUtility.Delete',
		'Membership.RewardsPermission.Delete',
		'Membership.ProgramName.Delete',
		'Membership.ZoneConfiguration.Delete',
		'Membership.CreateEarningRules.Delete',
	],
	supportedCountries: ['BR', 'MX', 'ZA', 'HN'],
	preferredLanguage: DEFAULT_LANGUAGE,
	app: 'adminportal',
	tid: '9c89de9e-e8e2-4516-887c-5fec487834e2',
	nonce: 'defaultNonce',
	azp: '1ff82dfb-fe6d-4558-bb1a-568b966cc95d',
	ver: '1.0',
	iat: 1620737554,
}

export function getToken(): string {
	const payload = JSON.stringify(tokenData)
	return `eyJhbGciOiJIUzI1NiJ9.${btoa(payload)}.rMKeOFuzR2wB4lTwLE075x3cw4POrfwAJFQXJNcNRig`
}
