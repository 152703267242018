import { getVendorEnvKey } from './getEnv'
import { getUserCountry } from './tokenB2CHelper'

const DEFAULT_API_URL = '/api'

const zoneUrlMap: Record<string, string> = {
	BE: '/api/eu',
	GB: '/api/eu',
	ES: '/api/eu',
	ID: '/api/eu',
	NL: '/api/eu',
	PH: '/api/eu',
	AQ: '/api/eu',
	TH: '/api/eu',
}

const isProdOrUat = (env: string): boolean => ['uat', 'prod'].indexOf(env.toLowerCase()) !== -1

const getBaseApiUrl = (): string => {
	const zone = getUserCountry()
	const env = getVendorEnvKey()
	if (!isProdOrUat(env)) {
		return DEFAULT_API_URL
	}
	const zoneKey = zone.toUpperCase()
	return zoneUrlMap[zoneKey] || DEFAULT_API_URL
}

export default getBaseApiUrl
