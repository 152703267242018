import * as GlobalEvents from '@/stores/global/GlobalEvents'

function load(): void {
	GlobalEvents.load()
}

function loadDone(): void {
	GlobalEvents.loadDone()
}

export { load, loadDone }
