import { createStyles, makeStyles } from '@material-ui/core/styles'

interface Colors {
	dataVisualization: string
	success: string
	error: string
	info: string
	warmAccent: string
	neutralAccent: string
}

const colors: Colors = {
	dataVisualization: 'var(--colors-interfaceDatavis10)',
	success: 'var(--colors-semanticSuccessBasis)',
	error: 'var(--colors-semanticErrorText)',
	info: 'var(--colors-semanticInfoBasis)',
	warmAccent: 'var(--colors-brandAccentWarmText)',
	neutralAccent: 'var(--colors-brandAccentNeutralText)',
}

const useStyles = makeStyles(() =>
	createStyles({
		statusBadge: {
			alignItems: 'center',
			display: 'flex',
			fontSize: '12px',
			gap: '8px',
			justifyContent: 'flex-start',
		},
		dot: {
			color: 'inherit',
			height: '4px',
			textAlign: 'center',
			verticalAlign: 'middle',
			width: '4px',
		},
		canceled: {
			color: colors.dataVisualization,
		},
		approved: {
			color: colors.success,
		},
		completed: {
			color: colors.success,
		},
		failed: {
			color: colors.error,
		},
		processing: {
			color: colors.info,
		},
		partially_completed: {
			color: colors.warmAccent,
		},
		pending: {
			color: colors.warmAccent,
		},
		rejected: {
			color: colors.neutralAccent,
		},
	}),
)

export default useStyles
