import CreateRedeemableItemRequest from '@/domains/redeemable/CreateRedeemableItemRequest'
import { EditRedeemableItemRequest } from '@/domains/redeemable/EditRedeemableItemRequest'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import axios from '@/utils/axiosInstance'
import { useLogService as logService } from 'admin-portal-shared-services'
import { UpdateDefaultCpp } from '@/domains/redeemable/UpdateDefaultCpp'

export const baseUrlOneRewardsAdminService = `/one-rewards-admin-service/v1`
const log = logService()

export async function createRedeemable(data: CreateRedeemableItemRequest[]): Promise<RedeemableItem> {
	try {
		const response = await axios.post(`${baseUrlOneRewardsAdminService}/redeemables`, {
			redeemables: data,
		})
		return response?.data
	} catch (error) {
		log.error(`Error endpoint: ${baseUrlOneRewardsAdminService}/redeemables`, error)
		throw error
	}
}

export async function editRedeemableItem(data: EditRedeemableItemRequest): Promise<RedeemableItem> {
	try {
		const response = await axios.post(`${baseUrlOneRewardsAdminService}/cpp-transactions`, { ...data, type: 'SKU' })
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${baseUrlOneRewardsAdminService}/cpp-transactions`, error)
		throw error
	}
}

export async function updateDefaultCpp(data: EditRedeemableItemRequest): Promise<void> {
	try {
		const response = await axios.post(`${baseUrlOneRewardsAdminService}/cpp-transactions`, { ...data, type: 'DEFAULT' })
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${baseUrlOneRewardsAdminService}/cpp-transactions`, error)
		throw error
	}
}

export const deleteApprovalCpp = async (transactionId: string): Promise<void> => {
	const url = `${baseUrlOneRewardsAdminService}/cpp-transactions/${transactionId}`
	return axios
		.delete(url)
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${url}`, error)
			throw error
		})
}

export async function getDefaultCpp(vendorId: string): Promise<UpdateDefaultCpp> {
	const url = `${baseUrlOneRewardsAdminService}/cpp-transactions?page=0&pageSize=1&type=DEFAULT&order=DESC&vendorId=${vendorId}`
	try {
		const response = await axios.get(url)
		return response.data.content[0]
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		throw error
	}
}
