import { PointsRemovalType, RewardsOfferType, TransactionType } from '@/domains/enums'
import { TransactionEvents } from '@/stores'
import { FormikValues } from 'formik'
import useTransactionTypeOptions from '@/hooks/transaction/useTransactionsTypeList'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'

export const useHandleChangeTransactionType = (): {
	handleChangeTransactionType: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
} => {
	const userInfo = useGetUserInfo()
	const transactionTypeOptions = useTransactionTypeOptions()

	const handleChangeTransactionType = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
		const transactionTypeSelected = event.target.value as TransactionType

		TransactionEvents.resetForTransaction()

		const rewardsOfferDefaultSelected =
			transactionTypeSelected === TransactionType.RewardsOffer ? RewardsOfferType.MultiplePoc : null

		let newValues = {
			transactionType: transactionTypeSelected,
		} as FormikValues

		if (transactionTypeSelected === TransactionType.RewardsOffer) {
			Object.assign(newValues, {
				rewardsOfferType: rewardsOfferDefaultSelected,
			})
		}

		if (transactionTypeSelected === TransactionType.PointsRemoval) {
			newValues = { ...newValues, pointsRemovalType: PointsRemovalType.SinglePoc }
		}

		TransactionEvents.resetForm(newValues)

		const getSelectTransactionType = (value: string): string | null =>
			transactionTypeOptions.find((item) => item.value === value)?.label ?? null

		AnalyticsService.events.selectInteraction({
			select_name: 'Transaction Type',
			select_label: transactionTypeSelected,
			selected_value: getSelectTransactionType(event.target.value as string),
			form_name: 'Points Transaction Creation',
			screen_name: 'Points Management - Create Points Transaction',
			...userInfo,
		})
	}

	return { handleChangeTransactionType }
}
