import React from 'react'
import { Button, IconButton, Modal } from '@hexa-ui/components'
import { IStep } from '@/domains/step'
import { X } from '@hexa-ui/icons'
import { useTranslation } from 'react-i18next'
import { ParAddedtolist } from '@admin-portal-shared-components/icons'
import useStyle from './styles'
import Stepper from '../Stepper'

type ActionButton = {
	onClick: () => void
	disabled?: boolean
}

type Props = {
	steps: Array<IStep>
	buttonName: string
	trigger: React.ReactNode
	isOpen?: boolean
	title: string
	activeStepIndex: number
	buttonNext: ActionButton
	buttonPrev: ActionButton
	onSubmit: () => void
}

const ModalStepper: React.FC<Props> = ({
	steps,
	trigger,
	title,
	isOpen,
	activeStepIndex,
	buttonNext,
	buttonPrev,
	onSubmit,
	buttonName,
}) => {
	const { t } = useTranslation()
	const classes = useStyle()
	const isFirstStep = activeStepIndex === 0
	const isLastStep = activeStepIndex === steps.length - 1

	const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		e.stopPropagation()

		if (isLastStep) return onSubmit()

		return buttonNext.onClick()
	}

	const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		e.stopPropagation()
		buttonPrev.onClick()
	}

	return (
		<Modal.Root
			actions={
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Modal.Cancel>
						<Button
							size="medium"
							className={classes.backBtn}
							variant="secondary"
							onClick={handlePrev}
							disabled={isFirstStep || buttonPrev.disabled}
						>
							{t('BUTTON.BACK')}
						</Button>
					</Modal.Cancel>
					<Modal.Action>
						{isLastStep ? (
							<Button
								icon={ParAddedtolist}
								leading
								size="medium"
								variant="primary"
								onClick={handleNext}
								disabled={buttonNext.disabled}
								type="submit"
							>
								{buttonName}
							</Button>
						) : (
							<Button size="medium" variant="primary" onClick={handleNext} disabled={buttonNext.disabled}>
								{t('BUTTON.CONTINUE')}
							</Button>
						)}
					</Modal.Action>
				</div>
			}
			title={
				<div className={classes.titleBox}>
					<div className={classes.header}>{title}</div>
					<div className={classes.closeBtn}>
						<Modal.Cancel>
							<IconButton icon={X} variant="inherit" />
						</Modal.Cancel>
					</div>
				</div>
			}
			trigger={trigger}
			open={isOpen}
		>
			<div className={classes.content}>
				<Stepper steps={steps} activeStepIndex={activeStepIndex} />
			</div>
		</Modal.Root>
	)
}

export default ModalStepper
