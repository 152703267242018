import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

export const useAnalyticsServiceEventButtonClicked = (): {
	sendAnalyticsServiceEventButtonClickedEvent: (
		screenName: string,
		buttonName: string,
		buttonLabel: string | null,
	) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceEventButtonClickedEvent = useCallback(
		(screenName: string, buttonName: string, buttonLabel: string | null) => {
			AnalyticsService.events.buttonClicked({
				screen_name: screenName,
				button_name: buttonName,
				button_label: buttonLabel,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceEventButtonClickedEvent }
}
