import { useAuthorization, useVerifyFeatureToggle } from '@/hooks'
import { sortRoutes } from '@/utils/routesUtil'
import { useMemo } from 'react'
import { RouteInstance } from '../../domains/route/RouteInstance'
import { routes } from '../../routes/routes'
import useCurrentZone from '../zone/useCurrentZone'
import { removeRoutesIfNecessary } from './authorizedRoutesHelper'

const useAuthorizedRoutes = (): Array<RouteInstance> => {
	const { isFeatureAuthorized } = useAuthorization()
	const { verifyFeatureToggle } = useVerifyFeatureToggle()
	const selectedZone = useCurrentZone()

	return useMemo<Array<RouteInstance>>(() => {
		if (!selectedZone) {
			return []
		}
		const authorizedRoutes = sortRoutes(Object.values(routes)).filter((route) => {
			const isEnabled = verifyFeatureToggle(route.authorizationFeature.featureName)

			return isFeatureAuthorized(isEnabled, {
				feature: route.authorizationFeature.feature,
				permissionLevel: route.authorizationFeature.permissionLevel,
			})
		})

		return removeRoutesIfNecessary(authorizedRoutes)
	}, [isFeatureAuthorized, verifyFeatureToggle, selectedZone])
}

export default useAuthorizedRoutes
