import { CreateRuleFormData } from '@/domains/rule/Rule'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { useRule } from './useRule'

export const useSetRules = (): void => {
	const history = useHistory()

	const isEditingRule = history.location.pathname.includes('edit-rule')

	const { ruleId } = useParams<{ ruleId: string }>()
	const rule = useRule(ruleId)

	const { setValue } = useFormContext<CreateRuleFormData>()

	useEffect(() => {
		if (isEditingRule && rule) {
			setValue('description', rule.description)
			setValue('points', rule.points)
			setValue('amountSpent', rule.amountSpent)
			setValue('categoryId', rule.categoryId)
		}
	}, [isEditingRule, rule, setValue])
}
