import React from 'react'
import { useStoreMap } from 'effector-react'
import { ChallengeStore } from '@/stores/challengeCreation'
import { OnSaveDialogFunction } from '@/routes/challenges/model/EditChallengeDialog'
import EditChallengeDialogView from './EditChallengeDialogView'

type Props = {
	onSaveDialog: OnSaveDialogFunction
}

const EditChallengeDialog: React.FC<Props> = ({ onSaveDialog }) => {
	const activeEditDialog = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.activeEditDialog,
	})

	return <EditChallengeDialogView activeEditDialog={activeEditDialog} onSaveDialog={onSaveDialog} />
}

export default EditChallengeDialog
