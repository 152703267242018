import { BadgeStatus } from '@/components/core/BadgeStatus'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import React from 'react'
import { useRedeemableItemStatus } from '../../../useRedeemableItemStatus'

export const RedeemableStatus = (item: RedeemableItem) => {
	const { buildStatusProps } = useRedeemableItemStatus()
	const { text, ...props } = buildStatusProps(item)

	return <BadgeStatus {...props}>{text}</BadgeStatus>
}
