import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			fontSize: '20px',
			fontWeight: 500,
			letterSpacing: '0',
			lineHeight: '24px',
		},
		dialogBox: {
			padding: '16px 8px',
		},
		dialogAction: {
			padding: '8px 24px 16px 24px',
		},
		noContentPadding: {
			padding: '24px',
		},
		content: {
			paddingTop: '0px',
		},
		contentText: {
			color: theme.palette.primary.main,
			fontSize: '14px',
		},
	}),
)

export default styles
