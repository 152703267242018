/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */

import * as yup from 'yup'

declare module 'yup' {
	// tslint:disable-next-line
	interface ObjectSchema<T> {
		uniqueProperty(propertyName: string, message?: string): yup.ObjectSchema<T>
	}
}

/**
 * Add Method on Yup that compares the property value in array,
 * and throw a Message on the last field of the duplicated values.
 */
yup.addMethod<yup.ObjectSchema<any>>(yup.object, 'uniqueProperty', function (propertyName, message) {
	return this.test('unique', message, function (value) {
		if (!value?.[propertyName]) {
			return true
		}

		if (this.parent.filter((v: any) => v !== value).some((v: any) => v[propertyName] === value[propertyName])) {
			throw this.createError({
				path: `${this.path}.${propertyName}`,
			})
		}

		return true
	})
})
