import React from 'react'
import { ButtonInteraction, ScreenName } from '@/domains'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useStoreMap } from 'effector-react'
import { OnSaveDialogFunction } from '@/routes/challenges/model/EditChallengeDialog'
import { ChallengeEvents, ChallengeStore } from '@/stores/challengeCreation'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import ChallengeNameEditDialogView, { ChallengeNameFormData } from './ChallengeNameEditDialogView'

type Props = {
	onSaveDialog: OnSaveDialogFunction
}

const ChallengeNameEditDialog: React.FC<Props> = ({ onSaveDialog }: Props) => {
	const challengeForEditing = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.challengeForEditing,
	})
	const userInfo = useGetUserInfo()

	const handleFormSubmit = (formData: ChallengeNameFormData): void => {
		onSaveDialog({ ...challengeForEditing!, title: formData.name }, {}, true)
	}

	const handleCancelButtonClick = (buttonClicked: boolean): void => {
		if (buttonClicked) {
			AnalyticsService.events.buttonClicked({
				button_label: null,
				button_name: ButtonInteraction.Cancel,
				screen_name: ScreenName.ChallengeSetupModal,
				...userInfo,
			})
		}
		ChallengeEvents.closeEditChallengeDialog()
	}

	return (
		<ChallengeNameEditDialogView
			name={challengeForEditing?.title ?? ''}
			onFormSubmit={handleFormSubmit}
			onCancelButtonClick={handleCancelButtonClick}
		/>
	)
}

export default ChallengeNameEditDialog
