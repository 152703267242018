import React, { ReactNode } from 'react'
import { Grid, Box } from '@material-ui/core'

type Props = {
	children: ReactNode
	className?: string
}

const ContainerView: React.FC<Props> = ({ children, className }: Props) => {
	return (
		<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={className}>
			<Grid container justifyContent="center">
				<Grid item xl={6} lg={8} md={9} sm={12}>
					<Box mb={6}>{children}</Box>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ContainerView
