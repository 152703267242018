import { fieldPointsValidation, fileValidationCsv } from '@/utils/validationObjects'
import * as yup from 'yup'
import '@/utils/yupCustomMethods'
import { ComboFormType } from '@/domains/enums'
import { MAX_SIZE_FILE_5MB } from '@/utils/constants'
import i18n from '@/i18n'

/**
 * Get validation object for Add Combos Form
 * @param t i18next t function
 * @param type ComboFormType
 */
const getValidationObject = (comboFormType: ComboFormType | undefined): yup.ObjectSchema => {
	const addOneByOneValidation = {
		combos: yup.array().of(
			yup
				.object()
				.shape({
					comboId: yup.string().required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD')),
					points: fieldPointsValidation(),
				})
				.uniqueProperty('comboId', i18n.t('combo:ERROR_MESSAGE.DT_COMBO_ALREADY_INCLUDED')),
		),
	}

	return yup.object().shape({
		...(comboFormType === ComboFormType.OneByOne ? addOneByOneValidation : {}),
		...(comboFormType === ComboFormType.Multiple
			? {
					csvFile: fileValidationCsv(
						{
							required: i18n.t('ERROR_MESSAGE.EMPTY_FILE_FIELD'),
							fileSize: i18n.t('ERROR_MESSAGE.MAX_SIZE_5MB'),
							fileType: i18n.t('ERROR_MESSAGE.UNSUPPORTED_CSV'),
						},
						MAX_SIZE_FILE_5MB,
					).test(
						'maxRows',
						i18n.t('ERROR_MESSAGE.EMPTY_FILE_FIELD'),
						(value) => value && value.length > 0 && value[0].size !== 0,
					),
			  }
			: {}),
	})
}

export { getValidationObject }
