import { IStep } from '@/domains/step'
import { Check } from '@admin-portal-shared-components/icons'
import classNames from 'classnames'
import React from 'react'
import useStyle, { StyleParams } from './styles'

type Props = {
	maxSteps: number
	activeStepIndex: number
	stepIndex: number
	stepData: IStep
	styleParams?: Partial<StyleParams>
}

const defaultStyleParams: StyleParams = {
	circleSize: 32,
	numberSize: 16,
	labelSize: 24,
	subLabelSize: 14,
}

const Step: React.FC<Props> = ({ maxSteps, activeStepIndex, stepIndex, stepData, styleParams }) => {
	const classes = useStyle({
		...defaultStyleParams,
		...(styleParams ?? {}),
	})
	const isDone = activeStepIndex > stepIndex
	const isCurrent = activeStepIndex === stepIndex

	return (
		<div className={classes.step} style={{ width: `${100 / maxSteps}%` }}>
			<div
				className={classNames(classes.circle, {
					[classes.current]: isCurrent,
					[classes.done]: isDone,
				})}
			>
				{isDone ? <Check size="medium" /> : <div className={classes.number}>{stepIndex + 1}</div>}
			</div>
			<div className={classes.label}>{stepData.label}</div>
			<div className={classes.sublabel}>{stepData.sublabel}</div>
		</div>
	)
}

export default Step
