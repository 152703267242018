import { Group } from '@/domains'
import { ChallengeExecutionMethod } from '@/domains/enums'
import { createEvent } from 'effector'
import {
	CurrentStep,
	ChallengeSetupFormData,
	ChallengeDetailsFormData,
	ChallengeFilterFormData,
	ChallengePageLevelNotification,
	DuplicateChallengePayload,
	EditChallengePayload,
	IndividualAccountGroup,
} from './ChallengeState'

export const setCurrentStep = createEvent<CurrentStep>('setCurrentStepTitle')

export const setShowSubmitDialog = createEvent<boolean>('setShowSubmitDialog')

export const setShowAccountGroupModal = createEvent<boolean>('setShowAccountGroupModal')

export const setChallengeSetupFormData = createEvent<ChallengeSetupFormData>('setChallengeSetupFormData')

export const setChallengeFilterFormData = createEvent<ChallengeFilterFormData>('setChallengeFilterFormData')

export const setChallengeDetailsFormData = createEvent<ChallengeDetailsFormData>('setChallengeDetailsFormData')

export const showPageLevelNotification = createEvent<ChallengePageLevelNotification>('showPageLevelNotification')

export const hidePageLevelNotification = createEvent('hidePageLevelNotification')

export const setNewlyCreatedGroup = createEvent<Group | null>('setNewlyCreatedGroup')

export const updateGroupOptionsMap = createEvent<Record<string, Group>>('updateGroupOptionsMap')

export const setIsValidatingSku = createEvent<boolean>('setIsValidatingSku')

export const setIsValidatingChallengeId = createEvent<boolean>('setIsValidatingChallengeId')

export const duplicateChallenge = createEvent<DuplicateChallengePayload>('duplicateChallenge')

export const closeChallengeForm = createEvent('closeChallengeForm')

export const editChallenge = createEvent<EditChallengePayload>('editChallenge')

export const closeEditChallengeDialog = createEvent('closeEditChallengeDialog')

export const setSuccessfullySubmitForm = createEvent<boolean>('setSuccessfullySubmitForm')

export const addIndividualAccountGroup = createEvent<IndividualAccountGroup>('addIndividualAccountGroup')

export const appendToGroupOptionsMap = createEvent<Record<string, Group>>('appendToGroupOptionsMap')

export const setChallengeId = createEvent<string>('setChallengeId')

export const clearNextStepsData = createEvent('clearNextStepsData')

export const setChallengeGroupName = createEvent<string>('setChallengeGroupName')

export const setExecutionMethod = createEvent<ChallengeExecutionMethod>('setChallengeGroupName')

export const setIndividualTarget = createEvent<boolean>('setIndividualTarget')

export const setOptionalLanguagesUsed = createEvent<Array<string>>('setOptionalLanguagesUsed')

// * Reset */
export const reset = createEvent('reset')
