import { challengeFilterFormDataToChallenge } from '@/converters/challenge/NewChallengeStateValuesToChallenge'
import { ButtonInteraction, ScreenName } from '@/domains'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useIsActiveChallengeForEditing from '@/routes/challenges/hooks/useIsActiveChallengeForEditing'
import { OnSaveDialogFunction } from '@/routes/challenges/model/EditChallengeDialog'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ChallengeFilterFormData, ChallengeStore, ChallengeEvents } from '@/stores/challengeCreation'
import { hasElements } from '@/utils/array'
import { useStoreMap } from 'effector-react'
import React, { useState } from 'react'
import ChallengeFormFilterEditConfirmation from './ChallengeFormFilterEditConfirmation'

import ChallengeFormFilterEditDialogView from './ChallengeFormFilterEditDialogView'

type Props = {
	onSaveDialog: OnSaveDialogFunction
}

const ChallengeFormFilterEditDialog: React.FC<Props> = ({ onSaveDialog }) => {
	const [formDataAfterSubmit, setFormDataAfterSubmit] = useState<ChallengeFilterFormData | null>(null)
	const isAwaitingForComfimation = !!formDataAfterSubmit
	const isActiveChallenge = useIsActiveChallengeForEditing()
	const userInfo = useGetUserInfo()

	const { challengeForEditing, challengeFilterFormData, showAccountGroupModal } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => ({
			challengeForEditing: state.challengeForEditing,
			challengeFilterFormData: state.challengeFilterFormData,
			showAccountGroupModal: state.showAccountGroupModal,
		}),
	})

	const hasAnyNewGroupIdFilter = (formData: ChallengeFilterFormData) => {
		if (challengeForEditing) {
			const prevGroupIds = challengeForEditing.filter.groupIds || []
			const groupFilter = formData.filters.find(({ filter }) => filter === 'groupIds') || { value: [] }
			const newGroupIds: string[] = groupFilter.value as string[]
			if (hasElements(newGroupIds)) {
				if (!hasElements(prevGroupIds)) {
					return true
				}
				return newGroupIds.some((groupId) => prevGroupIds?.indexOf(groupId) === -1)
			}
		}
		return false
	}

	const triggerOnSaveDialog = (formData: ChallengeFilterFormData): void => {
		onSaveDialog(challengeFilterFormDataToChallenge(formData, challengeForEditing!))
	}

	const handleFormSubmit = (formData: ChallengeFilterFormData): void => {
		if (isActiveChallenge && hasAnyNewGroupIdFilter(formData)) {
			setFormDataAfterSubmit(formData)
		} else {
			triggerOnSaveDialog(formData)
		}
	}

	const handleCancelButtonClick = (buttonClicked: boolean): void => {
		if (buttonClicked) {
			AnalyticsService.events.buttonClicked({
				button_label: null,
				button_name: ButtonInteraction.Cancel,
				screen_name: ScreenName.ChallengeFilterModal,
				...userInfo,
			})
		}
		setFormDataAfterSubmit(null)
		ChallengeEvents.closeEditChallengeDialog()
	}

	const confirmAdditionOfPocsToActiveChallenge = () => {
		if (formDataAfterSubmit) {
			triggerOnSaveDialog(formDataAfterSubmit)
			setFormDataAfterSubmit(null)
		}
		AnalyticsService.events.buttonClicked({
			button_label: null,
			button_name: ButtonInteraction.AddPocsToActiveChallenge,
			screen_name: ScreenName.EditChallengeStep2,

			...userInfo,
		})
	}

	return (
		<>
			<ChallengeFormFilterEditDialogView
				showDialog={!isAwaitingForComfimation}
				challengeFilterFormData={challengeFilterFormData}
				onFormSubmit={handleFormSubmit}
				onCancelButtonClick={handleCancelButtonClick}
				showAccountGroupModal={showAccountGroupModal}
				isActiveChallenge={isActiveChallenge}
			/>
			<ChallengeFormFilterEditConfirmation
				show={isAwaitingForComfimation}
				handleConfirm={confirmAdditionOfPocsToActiveChallenge}
				handleCancel={handleCancelButtonClick}
			/>
		</>
	)
}

export default ChallengeFormFilterEditDialog
