import { DataTableRef } from '@/components/tables/DataTable'
import ButtonInteraction from '@/domains/analytics/ButtonInteraction'
import { RecordType, ScreenName } from '@/domains'
import { useStoreMap } from 'effector-react'
import React from 'react'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ComboEvents, ComboStore, GlobalEvents, PageLevelEvents } from '@/stores'
import { execute } from '@/usecase/program/DeleteComboFromProgramUseCase'
import ProgramCombo from '@/domains/program/ProgramCombo'
import { useTranslation } from 'react-i18next'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import RemoveComboDialogView from './RemoveComboDialogView'

type Props = {
	dataTableRef: React.RefObject<DataTableRef> | null
}

const RemoveComboDialog: React.FC<Props> = ({ dataTableRef }: Props) => {
	const { t } = useTranslation()
	const { show, program, combo, rowIndexToDelete } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			show: state.showRemoveComboDialog,
			program: state.programData,
			combo: state.activeCombo,
			rowIndexToDelete: state.rowIndexToDelete,
		}),
	})
	const userInfo = useGetUserInfo()

	const handleCancel = (buttonClicked: boolean) => {
		/* istanbul ignore else */
		if (buttonClicked) {
			AnalyticsService.events.buttonClicked({
				button_label: null,
				button_name: ButtonInteraction.Cancel,
				screen_name: ScreenName.RemoveDTComboModal,
				...userInfo,
			})
		}
		ComboEvents.setShowRemoveComboDialog(false)
		ComboEvents.setActiveCombo({ activeCombo: undefined })
	}

	const deleteCallback = (deletedCombo?: ProgramCombo[]) => {
		if (program && deletedCombo) {
			const [firstDeletedCombo] = deletedCombo
			ComboEvents.setProgramDataForCombo({
				...program,
				combos: program.combos.filter(({ comboId }) => comboId !== firstDeletedCombo.comboId),
			})
		}
	}

	const handleConfirm = async () => {
		if (rowIndexToDelete !== undefined) {
			ComboEvents.setShowRemoveComboDialog(false)
			GlobalEvents.load()

			const deleted = await execute(program?.id as string, [encodeURI(combo!.comboId)])
			ComboEvents.setActiveCombo({ activeCombo: undefined })

			if (deleted) {
				dataTableRef?.current?.handleDelete(rowIndexToDelete, deleteCallback)
				PageLevelEvents.showNotification({
					route: '/membership/combos',
					notificationType: 'success',
					message: t('combo:NOTIFICATION.REMOVE.SUCCESS'),
				})
				AnalyticsService.events.recordDeleted({
					record_id: combo?.comboId ?? null,
					record_type: RecordType.DTCombo,
					screen_name: null,
					...userInfo,
				})
			} else {
				PageLevelEvents.showNotification({
					route: '/membership/combos',
					notificationType: 'error',
					message: t('combo:NOTIFICATION.REMOVE.ERROR'),
				})
			}

			GlobalEvents.loadDone()
		}
	}

	return <RemoveComboDialogView show={show} onCancel={handleCancel} onConfirm={handleConfirm} />
}

export default RemoveComboDialog
