enum ButtonInteraction {
	Update = 'UPDATE',
	Cancel = 'CANCEL',
	Submit = 'Submit',
	Edit = 'Edit',
	Remove = 'Remove',
	View = 'View',
	AddNewGroup = 'Add New Group',
	AddNewTransaction = 'Add New Transaction',
	Close = 'CLOSE',
	DownloadCsv = 'DOWNLOAD CSV',
	ReviewIDs = 'Review IDs',
	AddComboId = 'ADD COMBO ID',
	RemoveCombo = 'Remove a Combo',
	Duplicate = 'Duplicate',
	AddDtCombo = 'ADD DT COMBO',
	BackToActiveDtCombos = 'Back to Active DT Combos',
	ChangeRule = 'Change Rule',
	AddSkuRule = '+ ADD SKU',
	Add = 'ADD',
	CancelChallenge = 'Cancel Challenge',
	AddPocsToActiveChallenge = 'Add Pocs to active challenge',
	CopyCampaignId = 'Copy Campaign ID',
}

export default ButtonInteraction
