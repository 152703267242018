import { createStyles, makeStyles } from '@material-ui/core/styles'

const ComboFormStyles = makeStyles(() =>
	createStyles({
		displayFlex: {
			display: 'flex',
		},
		container: {
			padding: '24px',
		},
		errorMessage: {
			paddingLeft: '41px',
		},
		selectOptionLabel: {
			display: 'flex',
			marginBottom: '16px',
			fontFamily: 'Barlow',
			fontSize: '20px',
			fontWeight: 500,
			letterSpacing: '0',
			lineHeight: '24px',
		},
		radioButton: {
			padding: '8px 8px 8px 0',
		},
	}),
)

export default ComboFormStyles
