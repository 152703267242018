import { ChallengeEvents, ChallengeFilterFormData, emptyFilterFormData } from '@/stores/challengeCreation'
import { CHALLENGE_STEPS_REGEX } from '@/utils/constants'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useRef } from 'react'
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { getValidationObject } from './validationObject'

const ChallengeFormFilterProvider: React.FC = ({ children }) => {
	const history = useHistory()
	const locationPathname = history.location.pathname
	const formMethodsMemoizedRef = useRef<UseFormReturn<ChallengeFilterFormData>>()

	const formMethods = useForm<ChallengeFilterFormData>({
		defaultValues: {
			filters: [{ ...emptyFilterFormData }],
		},
		mode: 'onBlur',
		resolver: yupResolver(getValidationObject()),
		shouldFocusError: false,
	})

	const hasUserNavigatedToAnotherStep = useCallback(
		() => RegExp(new RegExp(CHALLENGE_STEPS_REGEX)).exec(locationPathname),
		[locationPathname],
	)

	useEffect(() => {
		formMethodsMemoizedRef.current = formMethods
	})

	useEffect(() => {
		return () => {
			if (hasUserNavigatedToAnotherStep() && formMethodsMemoizedRef.current) {
				ChallengeEvents.setChallengeFilterFormData(formMethodsMemoizedRef.current.getValues())
			}
		}
	}, [hasUserNavigatedToAnotherStep])

	return <FormProvider {...formMethods}>{children}</FormProvider>
}

export default ChallengeFormFilterProvider
