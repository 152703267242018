import React, { ReactNode } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStyle from './styles'
import { CancelButtonProps, SubmitButtonProps } from './model/FormDialogOptions'
import SubmitButtonTooltip from './Components/SubmitButtonTooltip'

export type DialogFormProps = {
	showDialog: boolean
	title: string
	cancelButtonProps: CancelButtonProps
	submitButtonProps: SubmitButtonProps
	children: ReactNode
	handleSubmit?: () => void
	hide?: boolean
	dataTestIdBase?: string
} & Pick<DialogProps, 'transitionDuration'>

const FormDialogTemplate: React.FC<DialogFormProps> = ({
	showDialog,
	title,
	cancelButtonProps,
	submitButtonProps,
	children,
	handleSubmit,
	hide,
	transitionDuration,
	dataTestIdBase,
}: DialogFormProps) => {
	const { t } = useTranslation()
	const classes = useStyle()

	return (
		<Dialog
			open={showDialog || false}
			onClose={() => cancelButtonProps.handleClick(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			data-testid={dataTestIdBase ? `${dataTestIdBase}-presentation` : undefined}
			fullWidth
			className={classes.dialog}
			maxWidth="md"
			hideBackdrop={hide}
			PaperProps={{
				style: {
					height: hide ? '0' : undefined,
				},
			}}
			transitionDuration={transitionDuration}
		>
			<div className={classes.dialogContainer}>
				<DialogTitle>
					<Typography id="alert-dialog-title" className={classes.title}>
						{title}
					</Typography>
				</DialogTitle>
				<form onSubmit={handleSubmit}>
					<DialogContent>{children}</DialogContent>
					<DialogActions className={classes.dialogAction}>
						<Button
							id="cancel-btn"
							data-testid={dataTestIdBase ? `${dataTestIdBase}-cancel-button` : undefined}
							onClick={() => cancelButtonProps.handleClick(true)}
							variant="outlined"
							color="primary"
							className={classes.cancelButton}
						>
							{cancelButtonProps.name ?? t('BUTTON.CANCEL')}
						</Button>
						<SubmitButtonTooltip title={submitButtonProps.tooltipTitle} disabled={!submitButtonProps.disabled}>
							<Button
								disabled={submitButtonProps.disabled}
								type={submitButtonProps.type ?? 'submit'}
								data-testid={dataTestIdBase ? `${dataTestIdBase}-confirm-button` : undefined}
								id="save-btn"
								variant="contained"
								color="primary"
								autoFocus={!submitButtonProps.disabledAutoFocus}
								onClick={submitButtonProps.handleClick}
							>
								{submitButtonProps.name ?? t('BUTTON.SAVE')}
							</Button>
						</SubmitButtonTooltip>
					</DialogActions>
				</form>
			</div>
		</Dialog>
	)
}

export default FormDialogTemplate
