import { isCriticalPPP } from '@/usecase/redeemableItems/CriticalPricePerPointUseCase'
import { createStore, createEvent } from 'effector'
import { $pricePerPointConfigurationStore } from './PricePerPointConfigurationStore'

export type CriticalPricePerPointConfiguration = {
	isOpened: boolean
	skuPricePerPoint: number
	submit: () => void
}

const criticalPricePerPointConfigurationInitialState: CriticalPricePerPointConfiguration = {
	isOpened: false,
	skuPricePerPoint: 0,
	submit: () => {},
}

export const $criticalPricePerPointConfigurationStore = createStore(criticalPricePerPointConfigurationInitialState)

// Events

export type CriticalPricePerPointEventProps = {
	submit: () => void
	close: () => void
	skuPricePerPoint: number
}

export const CriticalPricePerPointConfigurationStoreEvents = {
	onOpen: createEvent<CriticalPricePerPointEventProps>(),
	onClose: createEvent('onClose'),
	reset: createEvent('reset'),
}

const validate = (pricePerPoint: number, close: () => void, submit: () => void): boolean => {
	const { pricePerPoint: pricePerPointDefault } = $pricePerPointConfigurationStore.getState()
	submit()

	if (isCriticalPPP(pricePerPoint, pricePerPointDefault)) {
		return true
	}
	close()
	return false
}

const onOpen = (
	state: CriticalPricePerPointConfiguration,
	payload: CriticalPricePerPointEventProps,
): CriticalPricePerPointConfiguration => ({
	...state,
	isOpened: validate(payload.skuPricePerPoint, payload.close, payload.submit),
	skuPricePerPoint: payload.skuPricePerPoint,
	submit: payload.submit,
})

const onClose = (state: CriticalPricePerPointConfiguration): CriticalPricePerPointConfiguration => ({
	...state,
	isOpened: false,
})

// Handlers

$criticalPricePerPointConfigurationStore
	.on(CriticalPricePerPointConfigurationStoreEvents.onOpen, onOpen)
	.on(CriticalPricePerPointConfigurationStoreEvents.onClose, onClose)
	.reset(CriticalPricePerPointConfigurationStoreEvents.reset)
