import SupportedLanguages from '@/domains/configurations/SupportedLanguages'
import { OfferCategory } from '@/domains/offerCategory/offerCategory'
import { IProgram } from '@/domains/program'
import { VendorAndSettingsData } from '@/domains/vendors'
import { createEvent } from 'effector'

export const load = createEvent('load')
export const loadDone = createEvent('loadDone')
export const toggleLogoutDialog = createEvent<null>('toggleLogoutDialog')
export const selectProgram = createEvent<IProgram | null>('selectProgram')
export const selectZone = createEvent<string>('selectZone')
export const selectTier = createEvent<string>('selectTier')

export const setPageReady = createEvent<boolean>('setPageReady')
export const setVendorAndSettings = createEvent<VendorAndSettingsData>('setVendorAndSettings')
export const clearVendorData = createEvent('clearVendorData')
export const selectVendor = createEvent<string>('selectVendor')
export const setSupportLanguage = createEvent<SupportedLanguages>('setSupportLanguage')
export const setOfferCategories = createEvent<OfferCategory[]>('setOfferCategories')
