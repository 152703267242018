import { Paper } from '@/components/core'
import React from 'react'
import styles from './ChallengeFormFilterStyle'

type Props = {
	isEditing?: boolean
	children: Array<false | JSX.Element | Array<JSX.Element> | React.ReactNode>
}

const ChallengeFormFilterBoxPaper: React.FC<Props> = ({ isEditing, children }: Props) => {
	const classes = styles()
	const FilterBoxPaper = isEditing ? React.Fragment : Paper

	return (
		<FilterBoxPaper>
			{isEditing && <hr className={classes.filterBoxLine} />}
			{children}
		</FilterBoxPaper>
	)
}

export default ChallengeFormFilterBoxPaper
