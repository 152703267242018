import { useCallback } from 'react'
import { UpdateBulkComboUseCase } from '@/usecase'
import { useStoreMap } from 'effector-react'
import { ComboStore } from '@/stores'
import { useAnalyticsServiceRecordUpdated } from '@/hooks/analyticsService/useAnalyticsServiceEventsRecordUpdated'
import { RecordType, ScreenName } from '@/domains'
import useAuditLogDTcombos from '@/hooks/useAuditLogInstance/BusinessLogicLayer/combosDT'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'

type SubmitHandler = () => Promise<void>

const useComboSubmitMultiple = (): SubmitHandler => {
	const { sendAnalyticsServiceRecordUpdated } = useAnalyticsServiceRecordUpdated()
	const { sendAuditLogDTCombos } = useAuditLogDTcombos()

	const { formDataMultiple } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			formDataMultiple: state.formDataMultiple,
		}),
	})

	const submitMultiple = useCallback((): Promise<void> => {
		if (formDataMultiple?.formData.redeemables) {
			const recordId = formDataMultiple.formData.redeemables.map((item) => {
				return item.redeemableId
			})
			const points = formDataMultiple.formData.redeemables.map((item) => {
				return item.points
			})

			return UpdateBulkComboUseCase.execute(formDataMultiple).then(() => {
				sendAnalyticsServiceRecordUpdated({
					recordId: recordId.toString(),
					recordType: RecordType.DTCombo,
					screenName: ScreenName.DTComboManagementPublishedEditModal,
				})
				sendAuditLogDTCombos({
					recordId: recordId.toString(),
					operation: IAuditOperations.INSERT,
					points: points.toString(),
				})
			})
		}
		return Promise.reject(new Error('Something went wrong'))
	}, [formDataMultiple, sendAnalyticsServiceRecordUpdated, sendAuditLogDTCombos])

	return submitMultiple
}

export default useComboSubmitMultiple
