import { createEvent, createStore } from 'effector'

// Store

export type PricePerPointConfiguration = {
	isOpen?: boolean
	pricePerPoint?: number
	pricePerPointEnabled?: boolean
	lastModified?: string
	lastChangeRequest?: {
		pricePerPoint?: number
		lastModified?: string
		status?: 'denied' | 'pending'
		reason?: string
	}
}

export const pricePerPointConfigurationInitialState: PricePerPointConfiguration = {
	isOpen: false,
	pricePerPoint: undefined,
	pricePerPointEnabled: undefined,
	lastModified: undefined,
	lastChangeRequest: {
		pricePerPoint: undefined,
		lastModified: undefined,
		status: undefined,
		reason: undefined,
	},
}

export const $pricePerPointConfigurationStore = createStore(pricePerPointConfigurationInitialState)

// Events

export const PricePerPointConfigurationStoreEvents = {
	setSettings: createEvent<PricePerPointConfiguration>(),
	setIsOpen: createEvent<boolean>(),
	valueChangedSuccessfully: createEvent(),
	reset: createEvent('reset'),
}

// Handlers

$pricePerPointConfigurationStore
	.on(PricePerPointConfigurationStoreEvents.setSettings, (state, settings) => {
		const { pricePerPoint, pricePerPointEnabled, lastModified } = settings
		return {
			...state,
			pricePerPoint,
			pricePerPointEnabled,
			lastModified,
		}
	})
	.on(PricePerPointConfigurationStoreEvents.setIsOpen, (state, isOpen) => {
		return {
			...state,
			isOpen,
		}
	})
	.reset(PricePerPointConfigurationStoreEvents.reset)
