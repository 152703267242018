import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ChallengeDetailsFormData } from '@/stores/challengeCreation'
import { Controller, useFormContext } from 'react-hook-form'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { Input } from '@hexa-ui/components'
import { NumberInputContainer } from '../../../../components/core/NumberInputContainer/NumberInputContainer'

const ChallengeFormDetailsQuantity: React.FC = () => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const { control, formState } = useFormContext<ChallengeDetailsFormData>()
	const quantityErrorMessage = formState.errors.quantity?.message

	useEffect(() => {
		if (quantityErrorMessage) {
			AnalyticsService.events.error({
				failure_reason: quantityErrorMessage,
				form_name: 'Challenge Creation - 3rd Step',
				screen_name: 'Challenge Creation - 3rd Step',
				...userInfo,
			})
		}
	}, [quantityErrorMessage, userInfo])

	return (
		<Controller
			control={control}
			name="quantity"
			render={({ field: { value, ...fieldProps }, fieldState: { error } }) => (
				<div style={{ marginTop: '16px' }}>
					<NumberInputContainer>
						<Input
							min={1}
							{...fieldProps}
							id="quantity-value"
							inputMode="numeric"
							type="number"
							value={value ?? ''}
							label={t('challenge:DETAILS_STEP.QUANTITY')}
							errorText={error?.message}
							hasError={!!error?.message}
							width="100%"
							placeholder=""
						/>
					</NumberInputContainer>
				</div>
			)}
		/>
	)
}

export default ChallengeFormDetailsQuantity
