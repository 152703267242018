import { Button, Modal, IconButton } from '@hexa-ui/components'
import { X } from '@hexa-ui/icons'
import React from 'react'
import useStyle from './styles'

interface ButtonLabels {
	cancel: string
	proceed?: string
}

interface Props {
	isOpen: boolean
	yesNoActions?: boolean
	title: string
	children: React.ReactNode
	buttonLabels: ButtonLabels
	headerHeight?: string
	buttonActions: {
		cancel: () => void
		proceed?: () => void
	}
}

const ModalRedesign: React.FC<Props> = ({
	isOpen,
	title,
	children,
	yesNoActions = false,
	buttonLabels,
	buttonActions,
	headerHeight,
}) => {
	const classes = useStyle()
	const { proceed, cancel } = buttonActions
	return (
		<Modal.Root
			headerHeight={headerHeight}
			actions={
				<div className={classes.footer}>
					{yesNoActions ? (
						<>
							<Modal.Action>
								<Button size="medium" variant="secondary" onClick={() => cancel()}>
									{buttonLabels.cancel}
								</Button>
							</Modal.Action>
							<Modal.Action>
								<Button size="medium" variant="primary" onClick={() => proceed && proceed()}>
									{buttonLabels.proceed}
								</Button>
							</Modal.Action>
						</>
					) : (
						<Modal.Action>
							<Button size="medium" variant="primary" onClick={() => cancel()}>
								{buttonLabels.cancel}
							</Button>
						</Modal.Action>
					)}
				</div>
			}
			title={
				<div className={classes.title}>
					<h1>{title}</h1>
					<Modal.Cancel>
						<div className={classes.closeButton}>
							<IconButton
								icon={X}
								variant="inherit"
								onClick={(e) => {
									e.preventDefault()
									cancel()
								}}
							/>
						</div>
					</Modal.Cancel>
				</div>
			}
			open={isOpen}
		>
			<div style={{ width: '470px', marginTop: '10px' }}>{children}</div>
		</Modal.Root>
	)
}

export default ModalRedesign
