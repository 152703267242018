import { AcceptableTypes, FileUpload } from '@/components/form'
import { RedeemableRequest } from '@/domains'
import { RedeemableType } from '@/domains/enums'
import { useCsvParser } from '@/hooks'
import { ComboEvents } from '@/stores'
import React, { useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

type MultipleComboViewProps = {
	onSelectedFile: (components: JSX.Element[] | null) => void
}

const MultipleComboView: React.FC<MultipleComboViewProps> = ({ onSelectedFile }) => {
	const uploadFieldName = 'csvFile'
	const maxFileRows = 5000

	const [files, setFiles] = useState<File[]>([])
	const { t } = useTranslation()

	const { control, setValue, formState, clearErrors, trigger, setError } = useFormContext()

	const transformHeader = (header: string) => {
		return header.toLocaleLowerCase()
	}

	const handleParseResult = (result: Papa.ParseResult<unknown>) => {
		ComboEvents.setIsValidatingFile(true)

		if (result && result.data.length >= maxFileRows) {
			handleRemoveFile()
			ComboEvents.setFormDataMultiple(null)
			const maxRowsErrorMessage = t('combo:ERROR_MESSAGE.UNSUPPORTED_CSV_MAX_ROWS')
			setError(uploadFieldName, {
				type: 'maxRows',
				message: maxRowsErrorMessage,
			})
			ComboEvents.setFileValidationError(maxRowsErrorMessage)
		} else {
			const [singleFile] = files
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const redeemables: RedeemableRequest[] = result.data.map((r: any) => ({
				points: r.points,
				redeemableId: r.combo_id,
				type: RedeemableType.Combo,
			}))
			ComboEvents.setFormDataMultiple({
				formData: { redeemables },
				fileName: singleFile.name,
			})
		}

		ComboEvents.setIsValidatingFile(false)
	}

	const { isLoading, parse } = useCsvParser(handleParseResult, {
		transformHeader,
	})

	useEffect(() => {
		if (files && files.length > 0) {
			const [singleFile] = files
			parse(singleFile)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files])

	useEffect(() => {
		ComboEvents.setIsValidatingFile(isLoading)
	}, [isLoading])

	const handleChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
		ComboEvents.setFileValidationError(null)
		const { files: fileList } = event.currentTarget
		if (fileList && fileList.length > 0) {
			const fileValues = Array.from(fileList).map((file) => {
				return file
			})
			setValue(uploadFieldName, fileValues, {
				shouldValidate: false,
			})

			await trigger(uploadFieldName)
			const errorMessage = formState.errors[uploadFieldName]?.message
			if (errorMessage) {
				handleRemoveFile()
				ComboEvents.setFileValidationError(errorMessage)
			} else {
				setFiles(fileValues)
			}
		}
	}

	const handleRemoveFile = () => {
		setValue(uploadFieldName, null)
		setFiles([])
		clearErrors(uploadFieldName)
		ComboEvents.setFileValidationError(null)
	}

	return (
		<Controller
			control={control}
			name={uploadFieldName}
			render={({ field }) => (
				<FileUpload
					id="csv-combo-multiple"
					buttonText={t('BUTTON.UPLOAD_CSV')}
					fileType={AcceptableTypes.CSV}
					trigger={trigger}
					multiple
					buttonType="secondary"
					name={field.name}
					disableAfterSelectOne={files && files.length > 0}
					onRemove={() => handleRemoveFile()}
					onCallbackSelectedFiles={onSelectedFile}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeFile(event)}
				/>
			)}
		/>
	)
}
export default MultipleComboView
