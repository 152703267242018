import { makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) => ({
	arrow: {
		color: theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
		lineHeight: '14px',
		fontSize: '12px',
		color: '#F2F2F2',
		padding: theme.spacing(1),
		borderRadius: theme.spacing(1),
		textAlign: 'center',
		maxWidth: (props: { maxWidth?: string }): string | undefined => (props.maxWidth ? props.maxWidth : undefined),
	},
}))

export default styles
