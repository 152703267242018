import { Account, IndividualAccountGroup } from '@/stores/challengeCreation'
import { hasElements } from './array'

const removeDuplicatedAccountId = (list: Array<Account>): Array<Account> => {
	const idMap: Record<string, Account> = {}
	list.forEach((item: Account) => {
		if (!idMap[item.accountId]) {
			idMap[item.accountId] = item
		}
	})
	return Object.values(idMap)
}

const getAccountsByIndividualTarget = (
	individualAccountGroup: IndividualAccountGroup,
	groupIds?: Array<string>,
): Array<Account> => {
	if (!hasElements(groupIds)) {
		return []
	}
	let accountsList: Account[] = []
	groupIds!.forEach((groupKey: string) => {
		const item = individualAccountGroup[groupKey]
		if (item) {
			accountsList = [...accountsList, ...item.accounts]
		}
	})
	return removeDuplicatedAccountId(accountsList)
}

export default getAccountsByIndividualTarget
