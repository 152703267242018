import { CircularProgress, TextField } from '@material-ui/core'
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import FieldErrorMessage from '../FieldErrorMessage'
import InputLabel from '../InputLabel'
import styles from '../styles'
import localStyles from './styles'

export type OnValidateFunction = (value: string, name?: string) => string | Promise<string>
export type OnAfterValidateFunction = () => void
export type OnUpdateValueFunction = (value: string, name?: string) => void
export type OnKeyUpFirstKey = () => void
export type InputValue = { current: string; previous: string }
type Props = {
	id: string
	name: string
	label: string
	type?: 'text' | 'number' | 'password'
	autoFocus?: boolean
	disabled?: boolean
	validate: OnValidateFunction
	min?: number
	required?: string
	isFormValidating?: boolean
	showErrorOnValidate?: boolean
	maxLength?: number
	disableValidationOnEnter?: boolean
	onUpdateValue?: OnUpdateValueFunction
	onAfterValidate?: OnAfterValidateFunction
	watchFormikValue?: boolean
	onKeyUpFirstKey?: OnKeyUpFirstKey
	onChange?: () => void
	hideErrorOnChange?: boolean
	helperText?: string
	placeholder?: string
	onError?: (id: string, message: string) => void
	pocIdField?: string
	setPocIdField?: (pocId: string) => void
}

const InputTextWithValidation: React.FC<Props> = ({
	id,
	name,
	label,
	type,
	min,
	autoFocus,
	disabled,
	validate,
	required,
	isFormValidating,
	showErrorOnValidate,
	maxLength,
	disableValidationOnEnter,
	onUpdateValue,
	onAfterValidate,
	watchFormikValue,
	onKeyUpFirstKey,
	onChange,
	hideErrorOnChange,
	helperText,
	placeholder,
	onError,
	pocIdField,
	setPocIdField,
}: Props) => {
	const NO_ERROR = ''

	const classes = styles({})
	const localClasses = localStyles()
	const {
		initialValues: formikInitialValues,
		setFieldError: setFormikFieldError,
		setFieldValue: setFormikFieldValue,
		getFieldMeta,
	} = useFormikContext()
	const [inputValue, setInputValue] = useState<InputValue>({ current: '', previous: '' })
	const [validating, setValidating] = useState<boolean>(false)
	const [didPropagated, setDidPropagated] = useState<boolean>(false)
	const [propagateValue, setPropagateValue] = useState<boolean>(false)
	const [dispatchOnAfterValidate, setDispatchOnAfterValidate] = useState<boolean>(false)
	const fieldMeta = getFieldMeta(name)

	const initPropagation = useCallback(
		(initialValue?: string) => {
			const current = initialValue ?? inputValue.current
			setInputValue({ ...inputValue, current: current.trim() })
			setPropagateValue(true)
		},
		[inputValue],
	)

	const blurHandler = (): void => {
		initPropagation()
		if (onUpdateValue) onUpdateValue(inputValue.current, name)
	}

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setPocIdField && setPocIdField(e.target.name)
		const { value } = e.target
		if (onChange) onChange()
		setInputValue({ ...inputValue, current: value })
		if (hideErrorOnChange) setFormikFieldError(name, '')
	}

	const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		const ENTER_KEY = 'Enter'

		if (!disableValidationOnEnter && e.key === ENTER_KEY) {
			initPropagation()
			if (onUpdateValue) onUpdateValue(inputValue.current, name)
		}
		if (maxLength && e.key.length === 1 && (e.target as HTMLInputElement).value.length === maxLength) e.preventDefault()
		if (type === 'number' && e.key.length === 1 && !RegExp(/^[^e,.+-]+$/).exec(e.key)) e.preventDefault()
	}

	const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		const { value } = e.target as HTMLInputElement
		if (value.length === 1 && onKeyUpFirstKey) {
			onKeyUpFirstKey()
		}
	}

	const fieldValidateHandler = (value: string): string | Promise<string> => {
		if (disabled) {
			return NO_ERROR
		}

		let error: string | Promise<string> = getFieldMeta(name).error ?? NO_ERROR

		const noValue = !value || value.length === 0
		const isNewValue = inputValue.current !== inputValue.previous
		if (noValue) {
			setInputValue({ ...inputValue, previous: inputValue.current })
			error = required ?? NO_ERROR
		} else if ((isNewValue && !validating) || pocIdField === 'pocId') {
			const validationReturn = validate(value, name)

			if (validationReturn instanceof Promise) {
				setValidating(true)
				error = validationReturn.then((validationError) => {
					setValidating(false)
					setInputValue({ ...inputValue, previous: inputValue.current })
					setDispatchOnAfterValidate(true)
					return validationError
				})
			} else {
				setInputValue({ ...inputValue, previous: inputValue.current })
				error = validationReturn
			}
		}

		return error
	}

	const componentDidMount = (): void => {
		const formikInitialValue = (formikInitialValues as FormikValues)[name]
		const initialValue = getFieldMeta(name).value || formikInitialValue || ''
		if (initialValue) {
			initPropagation(initialValue)
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(componentDidMount, [])

	const doPropagateValue = (): void => {
		if (propagateValue) {
			setDidPropagated(true)
			setPropagateValue(false)
			setFormikFieldValue(name, inputValue.current)
		}
	}
	useEffect(doPropagateValue, [propagateValue, inputValue, name, setFormikFieldValue])

	useEffect(() => {
		if (
			!validating &&
			inputValue.current &&
			inputValue.current === inputValue.previous &&
			onAfterValidate &&
			dispatchOnAfterValidate
		) {
			setDispatchOnAfterValidate(false)
			onAfterValidate()
		}
	}, [validating, inputValue, onAfterValidate, dispatchOnAfterValidate])

	const watchFormikValueUpdate = () => {
		if (
			watchFormikValue &&
			inputValue.current &&
			fieldMeta.value !== inputValue.current &&
			inputValue.current === inputValue.previous
		) {
			inputValue.current = fieldMeta.value ? (fieldMeta.value as string) : ''
			inputValue.previous = inputValue.current
			setDidPropagated(false)
		}
	}
	useEffect(watchFormikValueUpdate, [fieldMeta, watchFormikValue, inputValue])

	return (
		<Field name={name} validate={fieldValidateHandler}>
			{(fieldProps: FieldProps): ReactElement => {
				const showError =
					!!fieldProps.meta.error && !validating && (isFormValidating || (showErrorOnValidate && didPropagated))

				return (
					<div className={classes.box}>
						<InputLabel label={label} htmlFor={id} disabled={disabled} error={showError} bottomSpacing={!helperText} />
						{showError && <FieldErrorMessage id={id} message={fieldProps.meta.error} onShow={onError} />}
						<TextField
							autoFocus={autoFocus}
							id={id}
							name={fieldProps.field.name}
							value={inputValue.current}
							onChange={changeHandler}
							onBlur={blurHandler}
							onKeyDown={keyDownHandler}
							onKeyUp={keyUpHandler}
							fullWidth
							disabled={disabled}
							minRows={3}
							maxRows={6}
							variant="outlined"
							classes={{
								root: localClasses.root,
							}}
							error={showError}
							helperText={NO_ERROR}
							placeholder={placeholder}
							type={type}
							InputProps={{
								inputProps: {
									min,
								},
								endAdornment: validating ? <CircularProgress color="inherit" size={20} /> : null,
							}}
						/>
					</div>
				)
			}}
		</Field>
	)
}

InputTextWithValidation.defaultProps = {
	type: 'text',
	disabled: false,
	autoFocus: false,
	isFormValidating: false,
	showErrorOnValidate: true,
	disableValidationOnEnter: false,
	hideErrorOnChange: false,
}

export default InputTextWithValidation
