import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		previousButtonVisibility: {
			'& ul li:first-child': {
				visibility: 'hidden',
			},
		},
		InputSelect: {
			width: '78px',
			height: '40px',
			margin: '0 10px 0 10px ',
		},
	}),
)

export default styles
