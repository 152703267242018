import React from 'react'
import { useFeatureToggleService, useIsFeatureToggleServiceReady } from 'admin-portal-shared-services'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles'
import theme from '@/theme'
import useAnalyticsServiceMembership from '@/hooks/analyticsService/useAnalyticsServiceMembership'
import TestEnvProvider from '@/providers/testEnv/TestEnvProvider'
import ZoneProvider from '@/providers/zone/ZoneProvider'
import { EnvConfig, EnvProvider } from '@/providers/EnvProvider/EnvProvider'
import AppRouter from './AppRouter'
import AppErrorBoundary from './AppErrorBoundary'
import '@/i18n'
import LocaleProvider from '../providers/locale/LocaleProvider'

const generateClassName = createGenerateClassName({
	productionPrefix: 'membership',
	seed: 'membership',
})

const App = (props: Readonly<EnvConfig>): JSX.Element => {
	const { segmentKey } = props
	const featureToggleService = useFeatureToggleService()
	const analyticsServiceMembership = useAnalyticsServiceMembership()
	const isFeatureToggleServiceReady = useIsFeatureToggleServiceReady()

	analyticsServiceMembership.load(segmentKey || '')
	analyticsServiceMembership.identify()
	featureToggleService.loadToggles()

	return isFeatureToggleServiceReady ? (
		<AppErrorBoundary>
			<ThemeProvider theme={theme}>
				<TestEnvProvider>
					<ZoneProvider>
						<EnvProvider env={props}>
							<LocaleProvider>
								<StylesProvider generateClassName={generateClassName}>
									<CssBaseline />
									<AppRouter />
								</StylesProvider>
							</LocaleProvider>
						</EnvProvider>
					</ZoneProvider>
				</TestEnvProvider>
			</ThemeProvider>
		</AppErrorBoundary>
	) : (
		<></>
	)
}

export default App
