import React, { ReactElement, useCallback, useRef, useState } from 'react'
import { Field, FieldProps, useFormikContext } from 'formik'
import { FormControl, Grid, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import * as Papa from 'papaparse'
import FileUploadButton from './FileUploadButton'
import FileUploadSpan from './FileUploadSpan'
import FileUploadLabel from './FileUploadLabel'
import styles from '../styles'
import localStyles from './styles'
import FieldErrorMessage from '../FieldErrorMessage'

type SetFieldValueFunction = (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
type SetFieldErrorFunction = (field: string, message: string) => void

export enum AcceptableTypes {
	Images = 'image/png, image/jpeg',
	CSV = '.csv',
	All = '*',
}

type Props = {
	id: string
	name: string
	label?: string
	labelDescription?: string
	buttonText: string
	buttonType: 'primary' | 'secondary'
	multiple: boolean
	fileType?: AcceptableTypes
	showError?: boolean
	error?: string
	disabled?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onClick?: () => void
	onRemove?: (files: File[]) => void
	onParse?: (results: Papa.ParseResult<unknown>) => { errors?: Array<string> }
	parseTo?: { map: Array<{ from: string; to: string }>; name: string }
	className?: string
	validate?: (value: File[]) => undefined | string | Promise<string>
}

const FileUpload: React.FC<Props> = ({
	id,
	name,
	buttonText,
	buttonType,
	label,
	labelDescription,
	multiple,
	fileType,
	error,
	showError,
	disabled,
	onChange,
	onClick,
	onRemove,
	onParse,
	parseTo,
	className,
	validate,
}: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const fileUploadElement = useRef<HTMLInputElement>(null)
	const classes = styles({})
	const localClasses = localStyles()
	const { submitCount } = useFormikContext()

	React.useEffect(() => {
		if (submitCount === 1 && fileUploadElement.current) {
			fileUploadElement.current.value = ''
		}
	}, [submitCount])

	const parseCompletedHandler = useCallback(
		(
			results: Papa.ParseResult<unknown>,
			files: File[],
			setFieldValue: SetFieldValueFunction,
			setFieldError: SetFieldErrorFunction,
		) => {
			const { errors } = onParse!(results)
			if (errors) {
				setFieldError(name, errors.toString())
				setFieldValue(name, undefined, false)
				if (parseTo) setFieldValue(parseTo.name, undefined, false)
			} else {
				setFieldValue(name, files)
				if (parseTo) setFieldValue(parseTo.name, results.data, false)
			}

			setIsLoading(false)
		},
		[name, onParse, parseTo],
	)

	const transformHeader = useCallback(
		(header: string) => {
			const lowerHeader = header.toLocaleLowerCase()

			if (parseTo) {
				return parseTo.map.find((item) => item.from.toLowerCase() === header.toLowerCase())?.to ?? lowerHeader
			}

			return lowerHeader
		},
		[parseTo],
	)

	const handleParse = React.useCallback(
		(files: File[], setFieldValue: SetFieldValueFunction, setFieldError: SetFieldErrorFunction) => {
			setIsLoading(true)

			setTimeout(
				() =>
					Papa.parse(files[0], {
						header: true,
						skipEmptyLines: true,
						delimiter: ';',
						complete: (results) => parseCompletedHandler(results, files, setFieldValue, setFieldError),
						transformHeader,
					}),
				500,
			)
		},
		[parseCompletedHandler, transformHeader],
	)

	const onChangeHandle = (event: React.ChangeEvent<HTMLInputElement>, fieldProps: FieldProps): void => {
		if (onChange) onChange(event)
		const { files } = event.currentTarget
		if (files && files.length > 0) {
			const filesValues = Array.from(files).map((file) => {
				return file
			})

			if (onParse) {
				handleParse(filesValues, fieldProps.form.setFieldValue, fieldProps.form.setFieldError)
			} else {
				fieldProps.form.setFieldValue(fieldProps.field.name, filesValues)
			}
		}
	}

	const onRemoveHandle = (fieldProps: FieldProps): void => {
		if (onRemove) onRemove(fieldProps.field.value)
		if (parseTo) fieldProps.form.setFieldValue(parseTo.name, undefined, false)
		fieldProps.form.setFieldValue(fieldProps.field.name, undefined)
		// Unreachable piece of code to test
		/* istanbul ignore else */
		if (fileUploadElement?.current) {
			fileUploadElement.current.value = ''
		}
	}

	const renderSelectedFiles = (fieldProps: FieldProps): JSX.Element[] => {
		let selectedFiles: JSX.Element[] = []
		if (fieldProps.field.value) {
			selectedFiles = Array.from(fieldProps.field.value as File[]).map((file) => {
				return (
					<FileUploadSpan
						key={file.name}
						name={file.name}
						onRemove={(): void => onRemoveHandle(fieldProps)}
						disabled={disabled}
					/>
				)
			})
		}
		return selectedFiles
	}

	const isUploadButtonDisabled = (filesCount: number) => !!disabled || filesCount > 0 || isLoading

	return (
		<div className={clsx(classes.box, className)}>
			<Field name={name} disabled={disabled} validate={validate}>
				{(fieldProps: FieldProps): ReactElement => {
					const shouldShowError =
						!!fieldProps.meta.error && (showError || (showError === undefined && fieldProps.form.submitCount > 0))
					const selectedFiles = renderSelectedFiles(fieldProps)
					const uploadDisabled = isUploadButtonDisabled(selectedFiles.length)

					return (
						<>
							<FileUploadLabel
								id={id}
								label={label}
								labelDescription={labelDescription}
								error={shouldShowError}
								disabled={uploadDisabled}
							/>
							<FormControl fullWidth className={localClasses.formcontrol}>
								{shouldShowError && !isLoading && (
									<FieldErrorMessage id={name} message={fieldProps.meta.error ?? error} />
								)}
								<Grid container alignContent="center" alignItems="center">
									<Grid item>
										<FileUploadButton
											id={id}
											label={buttonText}
											acceptableTypes={fileType}
											multiple={multiple}
											buttonType={buttonType}
											onChange={(event): void => {
												onChangeHandle(event, fieldProps)
											}}
											onClick={onClick}
											disabled={uploadDisabled}
											fileUploadElement={fileUploadElement}
										/>
									</Grid>
									<Grid item>
										{isLoading && <CircularProgress size={24} data-testid="loading-file" />}
										{!isLoading && <>{selectedFiles}</>}
									</Grid>
								</Grid>
							</FormControl>
						</>
					)
				}}
			</Field>
		</div>
	)
}

FileUpload.defaultProps = {
	fileType: AcceptableTypes.All,
	disabled: false,
}

export default FileUpload
