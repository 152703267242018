import React from 'react'
import { Box } from '@material-ui/core'

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	isActiveTab: boolean
}

const TabPanel: React.FC<TabPanelProps> = ({ children, isActiveTab, index }: TabPanelProps) => {
	return (
		<div role="tabpanel" hidden={!isActiveTab} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
			{isActiveTab && <Box paddingX={0}>{children}</Box>}
		</div>
	)
}

export default TabPanel
