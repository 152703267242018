import { useCallback } from 'react'
import { useStoreMap } from 'effector-react'
import { ChallengeState, ChallengeStore } from '@/stores/challengeCreation'
import { ChallengeExecutionMethod } from '@/domains/enums'

const useChallenge = () => {
	const { executionMethod, isIndividualTarget, individualAccountGroup } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state: ChallengeState) => ({
			executionMethod: state.challengeSetupFormData?.executionMethod,
			isValidatingSku: state.validations.isValidatingSku,
			isIndividualTarget: !!state.challengeFilterFormData?.individualTarget,
			individualAccountGroup: state.individualAccountGroup,
		}),
	})

	const verifySpendBasedFromCSV = useCallback((): boolean => {
		if (executionMethod !== ChallengeExecutionMethod.VolumeFixed && !isIndividualTarget) return false

		if (individualAccountGroup && isIndividualTarget) {
			const groups = Object.keys(individualAccountGroup)
			if (groups.length > 0) {
				const [group] = groups
				if (individualAccountGroup[group]) {
					const { accounts } = individualAccountGroup[group]
					if (accounts && accounts.length > 0) {
						const [account] = accounts
						const { currencyMin } = account

						return !!currencyMin
					}
				}
			}
		}

		return false
	}, [executionMethod, isIndividualTarget, individualAccountGroup])

	return { verifySpendBasedFromCSV }
}

export default useChallenge
