import ItemChallenge from '@/domains/challenge/ItemChallenge'
import { SkuBox } from '@/stores/challengeCreation'

type ChallengeSkusToItems = {
	items: ItemChallenge[]
	skus: SkuBox[]
}

const challengeSkusToItems = (skus: SkuBox[], items: ItemChallenge[] = []): ChallengeSkusToItems => {
	if (!skus || skus.length <= 0) {
		return {
			items,
			skus: [],
		}
	}

	const newItems = skus.map(({ sku, quantity, vendorId, vendorItemId, itemId }: SkuBox) => {
		if ((!vendorItemId || !vendorId) && items.length > 0) {
			const itemFind = items.find((item) => item.vendorItemId.startsWith(sku))
			if (itemFind) {
				return {
					...itemFind,
					quantity,
				}
			}
			return itemFind
		}
		return {
			itemId,
			quantity,
			vendorId,
			vendorItemId,
		}
	}) as ItemChallenge[]

	const newSkus = skus.map(({ sku, quantity }) => ({
		sku,
		quantity,
	})) as SkuBox[]

	return {
		items: newItems,
		skus: newSkus,
	}
}
export { challengeSkusToItems }
