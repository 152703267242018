/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ForwardRefRenderFunction, forwardRef, useImperativeHandle } from 'react'
import {
	TableBody as MaterialUITableBody,
	TableCell as MaterialUITableCell,
	TableRow,
	Typography,
} from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { useDataTableContext } from '../context/DataTableContext'
import ColumnData from '../model/ColumnData'
import { TableActions } from '../model/TableActions'
import TableCell from '../TableCell'
import useStyles from './styles'
import { TableConfigs } from '../model/TableConfigs'
import TableCheckbox, { TableCheckboxRef } from '../TableCheckbox'

export interface TableBodyRef {
	toggleAll: () => void
	clearSelection: () => void
	getSelectedRows: () => number[]
	getRowsCount: () => number
}

type Props = {
	tableId: string
	columns: ColumnData[]
	tableActions: TableActions[]
	cellStyle?: (cellData: string | number | Date, columnDataKey: string) => CSSProperties
	customConfigs: TableConfigs
	onToggleRow: () => void
	rowSelection?: boolean
	clearSelection: () => void
}
const TableBody: ForwardRefRenderFunction<TableBodyRef, Props> = (
	{ tableId, columns, tableActions, cellStyle, customConfigs, onToggleRow, rowSelection, clearSelection },
	ref,
) => {
	const classes = useStyles()
	const { getCurrentData } = useDataTableContext()
	const { t } = useTranslation()

	/**
	 *  Actions Column  Visible
	 */
	if (!customConfigs?.hideActions && columns[columns.length - 1].dataKey !== 'actionColumn') {
		columns.push({
			label: !customConfigs?.hideActionsLabel ? t('common:ACTIONS') : '',
			dataKey: 'actionColumn',
			align: customConfigs?.actionAlign ?? 'left',
			width: customConfigs?.actionWidth,
		})
	}

	const actionRenderer = (row: any, rowIndex: number, rowId: string) => {
		const actionsKey = `${rowId}-row-${rowIndex}-actions`
		return (
			<MaterialUITableCell key={actionsKey} id={actionsKey} align={customConfigs.actionAlign}>
				{tableActions?.map((action: TableActions) => {
					const key = `${rowId}-action-${action.label.toLowerCase()}`
					const renderContent = !action.willRender || action?.willRender(row)
					if (!renderContent) return <React.Fragment key={key}> </React.Fragment>

					const handleClick = () => action.fn(row, rowIndex)

					const CustomComponent = action.component || null

					return CustomComponent ? (
						<CustomComponent row={row} rowIndex={rowIndex} onChange={handleClick} />
					) : (
						<Typography key={key} id={key} component="span" className={classes.action} onClick={handleClick}>
							{action.label}
						</Typography>
					)
				})}
			</MaterialUITableCell>
		)
	}

	const checkboxesRef = React.useRef<Map<number, TableCheckboxRef>>(new Map<number, TableCheckboxRef>())

	useImperativeHandle(ref, () => ({
		toggleAll: (): void => {
			checkboxesRef.current.forEach((checkboxRef) => {
				checkboxRef.toggle()
			})
		},
		clearSelection: (): void => {
			checkboxesRef.current.forEach((checkboxRef) => {
				if (checkboxRef.checked) {
					checkboxRef.toggle()
				}
			})
		},
		getSelectedRows: (): number[] => {
			return Array.from(checkboxesRef.current.values())
				.filter((checkboxRef) => checkboxRef.checked)
				.map((checkboxRef) => checkboxRef.rowIndex)
		},
		getRowsCount: (): number => {
			return getCurrentData.length
		},
	}))

	const rowRenderer = (row: any, rowIndex: number) => {
		const key = `${tableId}-row-${rowIndex + 1}`

		const cells = columns.map((column, cellIndex) => {
			const columnKey = `${key}-cell-${cellIndex}`
			const { dataKey } = column
			const cellData = row[dataKey]

			return dataKey !== 'actionColumn' ? (
				<TableCell key={columnKey} id={columnKey} cellData={cellData} column={column} cellStyle={cellStyle} />
			) : (
				actionRenderer(row, rowIndex, key)
			)
		})

		const checkbox = (
			<TableCheckbox
				key="row-selection-checkbox"
				rowIndex={rowIndex}
				ref={(checkboxRef) => {
					if (checkboxRef) {
						checkboxesRef.current.set(rowIndex, checkboxRef)
					}
				}}
				onChange={() => onToggleRow()}
			/>
		)

		return (
			<TableRow
				key={key}
				id={key}
				data-id={customConfigs.renderRecordId ? row[customConfigs.renderRecordId] : undefined}
			>
				{rowSelection ? [checkbox, ...cells] : cells}
			</TableRow>
		)
	}

	React.useEffect(() => {
		if (rowSelection) {
			clearSelection()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getCurrentData])

	return (
		<MaterialUITableBody className={classes.root}>
			{getCurrentData.map((row, index) => rowRenderer(row, index))}
		</MaterialUITableBody>
	)
}

export default React.memo(forwardRef(TableBody))
