export const isNonValue = <T>(value: T): boolean => value === null || value === undefined

export const removeUndefinedAttributes = <V>(object: Record<string, V>): Record<string, Exclude<V, undefined>> => {
	const obj = {
		...object,
	}
	Object.keys(object).forEach((key) => {
		if (obj[key] === undefined) {
			delete obj[key]
		}
	})
	return obj as Record<string, Exclude<V, undefined>>
}
