import React, { useEffect, useState } from 'react'
import { TransparencyHistory } from '@/domains'
import { LoadTransparencyLogUseCase } from '@/usecase'
import { TransparencyType } from '@/domains/enums'
import ComboUploadHistoryView from './ComboUploadHistoryView'

const ComboUploadHistory: React.FC = () => {
	const [loading, setLoading] = useState(true)
	const [transparencyLog, setTransparencyLog] = useState<TransparencyHistory[]>([])

	useEffect(() => {
		setLoading(true)
		LoadTransparencyLogUseCase.execute(TransparencyType.RedeemableMultiple)
			.then((data) => setTransparencyLog(data))
			.finally(() => setLoading(false))
	}, [])

	return <ComboUploadHistoryView loading={loading} transparencyLog={transparencyLog} />
}

export default ComboUploadHistory
