import NavigationTabs from '@/components/core/NavigationTabs'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import TablePageTemplate from '@/components/templates/TablePageTemplate'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BASE_ROUTE } from '@/utils/constants'
import useIsCombosBulkUpdateEnabled from '@/hooks/combo/useIsCombosBulkUpdateEnabled'
import useIsCombosBulkSelectionEnabled from '@/hooks/combo/useIsCombosBulkSelectionEnabled'
import ComboPublishedTab from '../ComboPublishedTab/ComboPublishedTab'
import ComboUnpublishedTab from '../ComboUnpublishedTab/ComboUnpublishedTab'
import ComboUploadHistory from '../ComboUploadHistory/ComboUploadHistory'

type Props = {
	handleAddComboButton: () => void
	isEnabledEditAndRemoveMultiplesCombos: boolean
}

const CombosListView: React.FC<Props> = ({ handleAddComboButton, isEnabledEditAndRemoveMultiplesCombos }: Props) => {
	const isCombosBulkUpdateEnabled = useIsCombosBulkUpdateEnabled()
	const comboFormPath = `${BASE_ROUTE}/combo`
	const isCombosBulkSelectionEnabled = useIsCombosBulkSelectionEnabled()
	const isUploadHistoryTabEnabled = isCombosBulkUpdateEnabled && isCombosBulkSelectionEnabled
	const { t } = useTranslation()

	const tabItems = useMemo(() => {
		const items = [
			{
				key: '1',
				label: t('combo:TABS_TITLE.PUBLISHED'),
				render: () => (
					<ComboPublishedTab isEnabledEditAndRemoveMultiplesCombos={isEnabledEditAndRemoveMultiplesCombos} />
				),
			},
			{
				key: '2',
				label: t('combo:TABS_TITLE.UNPUBLISHED'),
				render: () => (
					<ComboUnpublishedTab isEnabledEditAndRemoveMultiplesCombos={isEnabledEditAndRemoveMultiplesCombos} />
				),
			},
		]
		if (isUploadHistoryTabEnabled) {
			items.push({
				key: '3',
				label: t('combo:TABS_TITLE.UPLOAD_HISTORY'),
				render: () => <ComboUploadHistory />,
			})
		}
		return items
	}, [isEnabledEditAndRemoveMultiplesCombos, isUploadHistoryTabEnabled, t])

	return (
		<TablePageTemplate
			buttonProps={{
				id: 'add-dt-combos-btn',
				text: t('combo:LIST.ADD_DT_COMBOS'),
				to: comboFormPath,
				onClick: handleAddComboButton,
			}}
			title={t('combo:LIST.TITLE')}
		>
			<PageLevelTemplate />
			<NavigationTabs tabItems={tabItems} />
		</TablePageTemplate>
	)
}

export default CombosListView
