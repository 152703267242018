import React, { ReactNode } from 'react'
import { Paper } from '@/components/core'

type Props = {
	hideContainer?: boolean
	className: string
	children: ReactNode
}

const PaperContainer: React.FC<Props> = ({ hideContainer, className, children }: Props) => {
	return (
		<>
			{hideContainer ? (
				<div>{children}</div>
			) : (
				<Paper className={className} hidePadding>
					{children}
				</Paper>
			)}
		</>
	)
}

export default PaperContainer
