import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreMap } from 'effector-react'
import { useFormikContext } from 'formik'
import { AccountValidationResult, RewardsOfferType, TransactionType } from '@/domains/enums'
import { TransactionEvents, TransactionStore } from '@/stores'
import { Paragraph } from '@hexa-ui/components'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import useRemoveSuccessMessage from '../../hooks/useRemoveSuccessMessage'
import PocValidationParams from '../../model/PocValidationParams'
import PointsRemovalView from './PointsRemovalView'

type PointsRemovalProps = {
	showError: boolean
	onError?: (id: string, message: string) => void
}

const ErrorMessages: { [key in AccountValidationResult]: string } = {
	[AccountValidationResult.INVALID]: 'transaction:POINTS_REMOVAL.ERROR_MESSAGE.INVALID_POC',
	[AccountValidationResult.NOT_ENROLLED]: 'ERROR_MESSAGE.INVALID_POC_ID',
	[AccountValidationResult.NO_PERMISSION]: 'ERROR_MESSAGE.NO_PERMISSION',
	[AccountValidationResult.OK]: '',
}

type PointsRemovalFormData = {
	removalOfferType: string
	pocId: string
	points: string | null
	currentPoints: number | null
	transactionType: TransactionType
}

const pointsRemovalCurrentEmptyText = 'transaction:POINTS_REMOVAL.CURRENT_POINTS_BALANCE_EMPTY'
const pointsRemovalNewEmptyText = 'transaction:POINTS_REMOVAL.NEW_POINTS_BALANCE_EMPTY'

const PointsRemoval: React.FC<PointsRemovalProps> = ({ showError, onError }) => {
	const removeSuccessMessage = useRemoveSuccessMessage()
	const { t } = useTranslation()

	const { setFieldValue, values, isValid, getFieldMeta } = useFormikContext<PointsRemovalFormData>()
	const hasBulkPointsRemovalPermission = useScopeAuthorization(['Membership.BulkPointsRemoval.Delete'])

	const getParagraph = useCallback(
		(message: string) => (
			<Paragraph colortype="disabled" size="small">
				{t(message)}
			</Paragraph>
		),
		[t],
	)

	const { newPointsBalance, currentPointsBalance } = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => ({
			currentPointsBalance: state.pointsRemoval.currentPointsBalance || getParagraph(pointsRemovalCurrentEmptyText),
			newPointsBalance: state.pointsRemoval.newPointsBalance || getParagraph(pointsRemovalNewEmptyText),
		}),
	})

	useEffect(() => {
		const points = parseInt(currentPointsBalance as string, 10)
		const currentPoints = !Number.isNaN(points) ? points : null
		if (currentPoints) setFieldValue('currentPoints', currentPoints)
	}, [currentPointsBalance, setFieldValue])

	const handleValidateRemovePoints = useCallback(
		async (points: string | null) => {
			if (!points) return
			const pointsToRemove = parseInt(points, 10)

			if (!pointsToRemove || pointsToRemove <= 0) {
				TransactionEvents.setPointsRemoval({
					newPointsBalance: getParagraph(pointsRemovalNewEmptyText),
				})
				return
			}

			const currentPoints = parseInt(currentPointsBalance as string, 10)
			if (Number.isNaN(currentPoints)) {
				TransactionEvents.setPointsRemoval({
					newPointsBalance: getParagraph(pointsRemovalNewEmptyText),
				})
				return
			}

			const newPoints = currentPoints - pointsToRemove
			TransactionEvents.setPointsRemoval({
				newPointsBalance: newPoints >= 0 ? newPoints.toString() : getParagraph(pointsRemovalNewEmptyText),
			})
		},
		[currentPointsBalance, getParagraph],
	)
	useEffect(() => {
		const pocIdField = getFieldMeta('pocId')
		if (!pocIdField.error) handleValidateRemovePoints(values.points)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.points, currentPointsBalance])
	const setConfirmationMessage =
		hasBulkPointsRemovalPermission && values?.removalOfferType === RewardsOfferType.MultiplePoc
			? t('transaction:POINTS_REMOVAL.CONFIRMATION_MESSAGE_MULTIPLE')
			: t('transaction:POINTS_REMOVAL.CONFIRMATION_MESSAGE', {
					points: values.points,
					pocId: values.pocId,
			  })

	useEffect(() => {
		if (isValid) TransactionEvents.setConfirmationMessage(setConfirmationMessage)
	}, [isValid, setConfirmationMessage, t, values])

	const handleChangePoc = async (pocId: string): Promise<void> => {
		if (!pocId) {
			TransactionEvents.setPointsRemoval({
				currentPointsBalance: getParagraph(pointsRemovalCurrentEmptyText),
				newPointsBalance: getParagraph(pointsRemovalNewEmptyText),
			})
		}

		return Promise.resolve()
	}

	const handleValidatePoc = async ({ pocId, balance, validationResult }: PocValidationParams): Promise<string> => {
		const pocIdFormatted = pocId.trim()
		if (pocIdFormatted.length === 1 && removeSuccessMessage) {
			removeSuccessMessage()
		}

		if (AccountValidationResult.OK !== validationResult) {
			TransactionEvents.setPointsRemoval({
				currentPointsBalance: !balance ? getParagraph(pointsRemovalCurrentEmptyText) : balance.toString(),
				newPointsBalance: getParagraph(pointsRemovalNewEmptyText),
			})
			const error = ErrorMessages[validationResult]
			if (!error) {
				return t('ERROR_MESSAGE.INVALID_POINTS_VALUE')
			}
			return t(error)
		}

		if (!balance || balance <= 0) {
			TransactionEvents.setPointsRemoval({
				currentPointsBalance: !balance ? '0' : balance.toString(),
				newPointsBalance: getParagraph(pointsRemovalNewEmptyText),
			})
			return balance === 0
				? t('transaction:POINTS_REMOVAL.ERROR_MESSAGE.POC_BALANCE_INSUFFICIENT')
				: t('transaction:POINTS_REMOVAL.ERROR_MESSAGE.POC_BALANCE_NEGATIVE')
		}

		TransactionEvents.setPointsRemoval({
			currentPointsBalance: balance.toString(),
			newPointsBalance: getParagraph(pointsRemovalNewEmptyText),
		})

		return ''
	}

	return (
		<PointsRemovalView
			showError={showError}
			onPocValidation={handleValidatePoc}
			currentPointsBalance={currentPointsBalance}
			newPointsBalance={newPointsBalance}
			removeSuccessMessage={removeSuccessMessage}
			onChangePoc={handleChangePoc}
			onError={onError}
		/>
	)
}

export default PointsRemoval
