import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		rowSelection: {
			padding: '16px 32px',
			marginBottom: '16px',
			backgroundColor: '#F1F1F1',
		},
		rowSelectionCounter: {
			lineHeight: '20px',
			fontSize: '16px',
			margin: '16px 0',
		},
		rowSelectionActions: {
			padding: '8px 0',
			display: 'flex',
		},
		actionButton: {
			fontSize: '14px',
			lineHeight: '16px',
			backgroundColor: theme.palette.common.white,
			marginLeft: '16px',
		},
	}),
)

export default styles
