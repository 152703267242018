import React from 'react'
import FormActionsView, { BackButtonProps, PrimaryButtonProps } from './FormActionsView'

type Props = {
	primaryButton?: PrimaryButtonProps
	backButton?: BackButtonProps
}

const FormActions: React.FC<Props> = ({ primaryButton, backButton }: Props) => {
	return <FormActionsView primaryButton={primaryButton} backButton={backButton} />
}

export default FormActions
