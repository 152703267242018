import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles({
	dataTitle: {
		padding: '8px 0 0 0',
	},
	dataValue: {
		padding: '8px 0',
		fontSize: '16px',
		lineHeight: '20px',
	},
	subContainer: {
		padding: 0,
	},
	dataContainer: {
		padding: '24px 0',
	},
	column: {
		marginRight: '32px',
		width: '86px',
	},
})
export default styles
