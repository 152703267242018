import React, { ForwardRefRenderFunction, forwardRef, useState, useImperativeHandle } from 'react'
import { Checkbox, TableCell } from '@material-ui/core'
import useStyles from './styles'

type Props = {
	onClick: () => void
}

export interface TableHeaderCheckboxRef {
	setIndeterminate: (indeterminate: boolean) => void
	setChecked: (checked: boolean) => void
}

const TableHeaderCheckbox: ForwardRefRenderFunction<TableHeaderCheckboxRef, Props> = ({ onClick }, ref) => {
	const classes = useStyles()

	const [checked, setChecked] = useState(false)
	const [indeterminate, setIndeterminate] = useState(false)
	const name = 'checkbox-header'

	useImperativeHandle(ref, () => ({
		setIndeterminate: (indeterminateValue) => {
			setChecked(indeterminateValue)
			setIndeterminate(indeterminateValue)
		},
		setChecked: (checkedValue: boolean) => {
			setChecked(checkedValue)
			setIndeterminate(false)
		},
	}))

	const handleClick = () => {
		onClick()
		if (indeterminate) {
			setChecked(false)
			setIndeterminate(false)
		} else {
			setChecked(!checked)
		}
	}

	return (
		<TableCell id="row-selection-header-checkbox" className={classes.cell}>
			<Checkbox
				name={name}
				className={classes.checkbox}
				checked={checked}
				indeterminate={indeterminate}
				onClick={handleClick}
				inputProps={{
					'aria-label': name,
				}}
				data-checked={checked}
				data-testid={name}
			/>
		</TableCell>
	)
}

export default forwardRef(TableHeaderCheckbox)
