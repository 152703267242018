import React from 'react'
import { useStoreMap } from 'effector-react'
import { ChallengeConverters } from '@/converters'
import { ButtonInteraction, ScreenName } from '@/domains'
import { ChallengeDetailsFormData, ChallengeEvents, ChallengeStore } from '@/stores/challengeCreation'
import { OnSaveDialogFunction } from '@/routes/challenges/model/EditChallengeDialog'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { getStatus } from '@/utils/challengeStatus'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import ChallengeFormDetailsEditDialogView from './ChallengeFormDetailsEditDialogView'

type Props = {
	onSaveDialog: OnSaveDialogFunction
}

const ChallengeFormDetailsEditDialog: React.FC<Props> = ({ onSaveDialog }) => {
	const { challengeForEditing, challengeDetailsFormData, isValidatingSku } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => ({
			challengeForEditing: state.challengeForEditing,
			challengeDetailsFormData: state.challengeDetailsFormData,
			isValidatingSku: state.validations.isValidatingSku,
		}),
	})
	const userInfo = useGetUserInfo()

	const handleFormSubmit = (formData: ChallengeDetailsFormData): void => {
		onSaveDialog(ChallengeConverters.challengeDetailsFormDataToChallenge(formData, challengeForEditing!))
	}

	const handleCancelButtonClick = (buttonClicked: boolean): void => {
		if (buttonClicked) {
			AnalyticsService.events.buttonClicked({
				button_label: ButtonInteraction.Cancel,
				button_name: ButtonInteraction.Cancel,
				screen_name: `${ScreenName.EditChallenge} - ${challengeForEditing?.title} - ${challengeForEditing?.executionMethod}`,
				...userInfo,
			})
		}
		ChallengeEvents.closeEditChallengeDialog()
	}

	return (
		<ChallengeFormDetailsEditDialogView
			challengeDetailsFormData={challengeDetailsFormData}
			onFormSubmit={handleFormSubmit}
			onCancelButtonClick={handleCancelButtonClick}
			challengeStatus={challengeForEditing ? getStatus(challengeForEditing) : undefined}
			executionMethod={challengeForEditing ? challengeForEditing.executionMethod : null}
			isValidatingSku={isValidatingSku}
		/>
	)
}

export default ChallengeFormDetailsEditDialog
