import React from 'react'
import { FormattedTransaction } from '@/routes/transactions/components/TransactionLogV2/TransactionLog.types'
import { IconButton } from '@hexa-ui/components'
import { EyeOn } from '@hexa-ui/icons'
import { formatDate } from '@/utils/date'
import TransactionStatusBadge from '@/components/core/TransactionStatusBadge'
import { ILoad } from '@/stores/transactionV2/TransactionStore'
import TransparencyStatusEnum from '@/domains/enums/TransparencyStatusEnum'

const DATE_FORMAT = 'MMMM D, YYYY - HH:mm'

function getTransactionData(data: ILoad[]): FormattedTransaction[] {
	return data.map(transformTransaction).filter(Boolean) as FormattedTransaction[]
}

function transformTransaction(item: ILoad): FormattedTransaction | null {
	try {
		return {
			actions: <IconButton size="small" variant="tertiary" icon={() => <EyeOn fr={1} />} />,
			totalAccounts: item.audience,
			createdAt: formatDate(new Date(item.dateRegistered), DATE_FORMAT),
			id: item.id.toUpperCase(),
			status: <TransactionStatusBadge type={item.status as keyof typeof TransparencyStatusEnum} />,
			type: item.type,
		}
	} catch (error) {
		console.error('Error transforming transaction data:', error)
		return null
	}
}
export default getTransactionData
