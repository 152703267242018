import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormDialogTemplate from '@/components/templates/FormDialogTemplate'
import { fieldValidationRequired } from '@/utils/validationObjects'
import { InputText } from '@/components/form'
import { OnCancelButtonClickFunction } from '@/routes/challenges/model/EditChallengeDialog'

type Props = {
	name: string
	onFormSubmit: SubmitHandler<ChallengeNameFormData>
	onCancelButtonClick: OnCancelButtonClickFunction
}

export interface ChallengeNameFormData {
	name: string
}

const ChallengeNameEditDialogView: React.FC<Props> = ({ name, onFormSubmit, onCancelButtonClick }: Props) => {
	const { t } = useTranslation()

	const schema = useMemo(
		() =>
			yup.object().shape({
				name: fieldValidationRequired(),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t],
	)

	const { handleSubmit, control, formState, reset } = useForm<ChallengeNameFormData>({
		resolver: yupResolver(schema),
		defaultValues: { name: '' },
		mode: 'onBlur',
		shouldFocusError: false,
	})

	React.useEffect(() => {
		reset({ name })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<FormDialogTemplate
			showDialog
			title={t('challenge:CHALLENGE_NAME')}
			cancelButtonProps={{
				handleClick: onCancelButtonClick,
			}}
			submitButtonProps={{ name: t('BUTTON.SAVE'), disabled: !formState.isDirty }}
			handleSubmit={handleSubmit(onFormSubmit)}
		>
			<Controller
				control={control}
				name="name"
				render={({ field, fieldState: { error } }) => (
					<InputText {...field} id="challenge-name" label={t('challenge:CHALLENGE_NAME')} errorText={error?.message} />
				)}
			/>
		</FormDialogTemplate>
	)
}

export default ChallengeNameEditDialogView
