import { styled } from '@hexa-ui/theme'

export const SelectWithOptional = styled('div', {
	label: {
		position: 'relative',
		'&::after': {
			content: '(optional)',
			marginLeft: '4px',
			width: '8px',
			height: '8px',
			borderRadius: '50%',
			color: '#757575',
			fontFamily: 'Work Sans',
			fontSize: '14px',
			fontWeight: '400',
			lineHeight: '20px' /* 142.857% */,
			textTransform: 'lowercase',
		},
	},
})
