import { createStyles, makeStyles } from '@material-ui/core/styles'

export const ItemCellStyle = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			position: 'relative',
			width: '100%',
		},
		mainVariantContainer: {
			backgroundColor: '#F2F2F2',
		},
		productWrapper: {
			display: 'flex',
			alignItems: 'center',
			padding: '16px',
			gap: '16px',
			flex: 1,

			'& *': {
				fontSize: 'var(--fontSizes-2)',
			},
		},
		imageContainer: {
			width: '64px',
			height: '64px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			'& > div': {
				backgroundColor: 'transparent',
			},

			'& img': {
				width: '64px',
				height: '64px',
				objectFit: 'contain',
				margin: '0 auto',
			},
		},
		productInfoContainer: {
			maxWidth: '340px',
		},
		productName: {
			fontWeight: 600,
		},
		description: {
			color: 'var(--colors-interfaceLabelPrimary)',
			fontWeight: 400,
			lineHeight: 'var(--lineHeights-5)',
		},
		inputWrapper: {
			margin: '16px 16px 16px 8px',
			position: 'relative',
			alignSelf: 'center',
			width: '120px',
		},
		pricePerPoint: {
			fontWeight: 600,
			marginBottom: '4px',
		},
	}),
)
