import { RewardsOfferType } from '@/domains/enums'
import TypeList from '@/domains/TypeList'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useRewardsOfferTypeList = (): TypeList[] => {
	const { t } = useTranslation()

	return useMemo(
		() => [
			{ value: RewardsOfferType.MultiplePoc, label: t('transaction:MULTIPLE_POC') },
			{ value: RewardsOfferType.SinglePoc, label: t('transaction:SINGLE_POC') },
		],
		[t],
	)
}

export default useRewardsOfferTypeList
