import React, { ReactNode } from 'react'
import { Typography, Grid, Box, GridProps } from '@material-ui/core'
import { BreadCrumbLink, BreadCrumbLinkProps } from '@/components/core'
import useStyles from './styles'

type SizeProps = Pick<GridProps, 'xl' | 'lg' | 'md' | 'sm'>

type Props = {
	title: string
	breadCrumbLink?: BreadCrumbLinkProps
	size?: SizeProps
	children: ReactNode
}

const Container: React.FC<Props> = ({ title, breadCrumbLink, size, children }: Props) => {
	const classes = useStyles({ breadCrumbLink: !!breadCrumbLink })

	const { xl, lg, md, sm }: SizeProps = {
		xl: size?.xl || 6,
		lg: size?.lg || 8,
		md: size?.md || 9,
		sm: size?.sm || 12,
	}

	return (
		<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
			{breadCrumbLink && <BreadCrumbLink displayName={breadCrumbLink.displayName} onBack={breadCrumbLink.onBack} />}
			<Typography variant="h2" className={classes.title}>
				{title}
			</Typography>
			<Grid container justifyContent="center">
				<Grid item xl={xl} lg={lg} md={md} sm={sm}>
					<Box mb={6}>{children}</Box>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Container
