import { useCallback, useMemo, useState } from 'react'
import { ScreenName } from '@/domains'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useRuleDataTable from '@/routes/rules/hooks/useRuleDataTable'

export const useEarningRulesContentHandlers = () => {
	const userInfo = useGetUserInfo()
	const { dataRules, setRuleFilter } = useRuleDataTable()
	const { goToCreateRule } = useNavigateToRules()

	const [pageData, setPageData] = useState({
		page: 1,
		pageSize: 10,
	})

	const pageDataEventParams = useMemo(
		() => ({
			items_total: `${dataRules.length}`,
			items_per_page: `${pageData.pageSize}`,
			page_number: `${pageData.page}`,
			table_name: ScreenName.EarningRules,
			screen_name: ScreenName.EarningRules,
		}),
		[dataRules.length, pageData.page, pageData.pageSize],
	)

	const handleSearch = useCallback(
		(ev) => {
			setRuleFilter((ev.target as HTMLInputElement).value)
			AnalyticsService.events.tableSearched({
				search_query: (ev.target as HTMLInputElement).value,
				content_type: ScreenName.EarningRules,
				table_name: ScreenName.EarningRules,
				screen_name: ScreenName.EarningRules,
				...userInfo,
			})
		},
		[setRuleFilter, userInfo],
	)

	const handleCreateNewEarningRuleButton = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_name: 'New Earning Rule',
			button_label: 'Create new earning rule',
			screen_name: ScreenName.EarningRules,
			...userInfo,
		})
		goToCreateRule()
	}, [goToCreateRule, userInfo])

	const handlePagination = useCallback(
		(page, pageSize) => {
			if (pageSize !== pageData.pageSize) {
				AnalyticsService.events.tableItemsPerPageChanged({
					...pageDataEventParams,
					...userInfo,
					items_per_page: `${pageSize}`,
				})
			}
			if (page !== pageData.page) {
				AnalyticsService.events.paginationClicked({
					// 'interactionType' cannot be mapped in the current table
					interaction_type: null,
					page_number: `${page}`,
					screen_name: ScreenName.EarningRules,
					...userInfo,
				})
			}
			setPageData({ page, pageSize })
		},
		[pageData.page, pageData.pageSize, pageDataEventParams, userInfo],
	)

	const handleClear = useCallback(() => setRuleFilter(''), [setRuleFilter])

	return {
		dataRules,
		handleClear,
		handleCreateNewEarningRuleButton,
		handlePagination,
		handleSearch,
		pageDataEventParams,
	}
}
