import { useLogService as logService } from 'admin-portal-shared-services'
import Combo, { ComboMultivendor } from '@/domains/combo/Combo'
import axios from '@/utils/axiosInstance'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { hasElements } from '@/utils/array'

const endpoint = `/combos/v2`
const multivendorEndpoint = `/rewards-admin-service/v1/combos`
const log = logService()

interface ComboResponse {
	combos: Array<Combo>
}

interface ComboMultivendorResponse {
	combos: Array<ComboMultivendor>
}

interface Params {
	types: string
	comboIds?: string
	vendorComboIds?: string
	includeDeleted: boolean
	includeDisabled: boolean
	pageSize: number
}

interface ParseProps {
	comboIds?: Array<string>
	vendorComboIds?: Array<string>
	deleted: boolean
	disabled: boolean
}

const parseParams = ({ comboIds, vendorComboIds, deleted, disabled }: ParseProps): Params => ({
	types: ['DT', 'DT3P'].join(','),
	comboIds: comboIds?.join(','),
	vendorComboIds: vendorComboIds?.join(','),
	includeDeleted: deleted,
	includeDisabled: disabled,
	pageSize: 900000,
})

/**
 * Get Combos by Id related to the country
 * @param ids	return by combosId, if empty should return all combos related to the country
 * @param deleted		return deleted combos if true
 * @param disabled	return disabled combos if true
 */
async function loadById(ids?: Array<string>, deleted = false, disabled = false): Promise<Array<Combo>> {
	const response = await axios
		.get<ComboResponse>(`${endpoint}`, { params: parseParams({ comboIds: ids, deleted, disabled }) })
		.catch((error) => {
			log.error(`Error endpoint: ${endpoint}`, error)
			throw error
		})

	return response.data.combos
}

const parseToCombo = (combos: Array<ComboMultivendor>): Array<Combo> => {
	if (!hasElements(combos)) {
		return []
	}
	return combos.map((item: ComboMultivendor) => ({
		id: item.comboId,
		title: item.title,
		type: item.type,
		deleted: item.deleted,
		enabled: item.enabled,
		vendorComboId: item.vendorComboId,
	}))
}

async function loadMultivendorComboById(props: ParseProps): Promise<Array<Combo>> {
	const params = { ...parseParams(props), vendorId: getDefaultVendorIdOfCurrentZone() }

	const response = await axios.get<ComboMultivendorResponse>(`${multivendorEndpoint}`, { params }).catch((error) => {
		log.error(`Error endpoint: ${multivendorEndpoint}`, error)
		throw error
	})

	return parseToCombo(response.data.combos)
}

export { loadById, loadMultivendorComboById }
