enum TransparencyStatus {
	CANCELED = 'Canceled',
	COMPLETED = 'Completed',
	FAILED = 'Failed',
	PROCESSING = 'Processing',
	PARTIALLY_COMPLETED = 'Partially Completed',
	REJECTED = 'Rejected',

	PENDING = 'Pending Approval',
	APPROVED = 'Approved',

	// Deprecated
	Completed = 'Complete',
	Failed = 'Failed', // NOSONAR
}

export default TransparencyStatus
