import React from 'react'
import { useParams } from 'react-router-dom'
import SkuListView from './SkuListView'
import { useRule } from '../../../../hooks/useRule'

const SkuList: React.FC = () => {
	const { ruleId } = useParams<{ ruleId: string }>()
	const rule = useRule(ruleId)

	return <SkuListView rule={rule} />
}

export default SkuList
