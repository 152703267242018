import { ParseResult } from 'papaparse'

export const handleCsvParseResults = (results: ParseResult<unknown>): boolean => {
	const { data, meta, errors: parseErrors } = results
	const hasParseErrors = !!parseErrors?.length
	const MAX_LINES = 1000000

	if (hasParseErrors) {
		return false
	}

	if (data.length === 0 || data.length > MAX_LINES) {
		return false
	}

	if (meta.fields?.length && meta.fields?.length > 3) {
		return false
	}

	if (!(meta.fields?.includes('poc_id') && meta.fields?.includes('points'))) {
		return false
	}

	return true
}
