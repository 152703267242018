import React from 'react'
import { Card } from '@hexa-ui/components'
import Rule from '@/domains/rule/Rule'
import EditRuleType from '@/domains/enums/EditRule'
import EditButton from '../../../Components/EditButton/EditButton'
import useStyles from './SkuListStyle'
import SkuListTable from '../../../Components/SkuListTable/SkuListTable'

type SkuListProps = {
	rule: Rule
}

const SkuListView: React.FC<SkuListProps> = ({ rule }: SkuListProps) => {
	const classes = useStyles()

	return (
		<Card border="large" elevated="small" className={classes.skuListCard}>
			<EditButton rule={rule} editType={EditRuleType.editSku} />
			<SkuListTable />
		</Card>
	)
}

export default SkuListView
