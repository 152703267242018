import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsServicePricePerPoints {
	pricePerPoint: number
	sku: string
	skuName: string
}

export const useAnalyticsServicePricePerPoints = (): {
	sendAnalyticsServicePPP: (pricePerPoints: AnalyticsServicePricePerPoints) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServicePPP = useCallback(
		({ pricePerPoint, sku, skuName }: AnalyticsServicePricePerPoints) => {
			AnalyticsService.events.redeemablePricePerPointUpdated({
				price_per_point: pricePerPoint,
				sku,
				sku_name: skuName,
				screen_name: 'Edit Redeemable Price Per Point',
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServicePPP }
}
