import React from 'react'
import ChipInput, { Props, ChipRendererArgs } from 'material-ui-chip-input'
import InputContainer, { InputContainerProps } from '@/components/form/InputContainer'
import { Clear as ClearIcon } from '@material-ui/icons'
import { Chip } from '@material-ui/core'
import styles from './styles'

export type InputChipProps = InputContainerProps & Props

const InputChip: React.FC<InputChipProps> = ({
	id,
	label,
	optionalLabel,
	errorText,
	helperText,
	disabled,
	className,
	...rest
}) => {
	const classes = styles()

	const hasError = !!errorText

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const chipRenderer = ({ value, isDisabled, handleClick, handleDelete }: ChipRendererArgs, key: any): JSX.Element => {
		return (
			<Chip
				key={key}
				clickable={false}
				disabled={isDisabled}
				label={value}
				onClick={handleClick}
				deleteIcon={<ClearIcon id={`delete-${id}`} className={classes.deleteIcon} data-testid={`delete-${id}`} />}
				onDelete={handleDelete}
				className={classes.chip}
			/>
		)
	}

	return (
		<InputContainer
			id={id}
			label={label}
			optionalLabel={optionalLabel}
			errorText={errorText}
			helperText={helperText}
			disabled={disabled}
			className={className}
		>
			<ChipInput
				id={id}
				fullWidth
				className={(classes.inputChip, classes.root)}
				classes={{
					inputRoot: classes.inputRoot,
				}}
				chipRenderer={chipRenderer}
				variant="outlined"
				error={hasError}
				disabled={disabled}
				{...rest}
			/>
		</InputContainer>
	)
}

export default InputChip
