import { AccountGroup } from '@/domains'
import { AccountGroupCSVList, AccountGroupFormData, AccountGroupParsedItem } from '@/domains/AccountGroup'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ChallengeEvents } from '@/stores/challengeCreation'
import { parseCSVFile } from '@/utils/parseCSVFile'
import { hasText } from '@/utils/string'
import React, { useEffect } from 'react'
import { useChallengeIdFromSetupFormData } from '../../hooks/useChallengeIdFromSetupFormData'
import { useCreateAccountGroup } from '../../hooks/useCreateAccountGroup'
import useGroupOptionsMap from '../../hooks/useGroupOptionsMap'
import AccountGroupFormModalView from './AccountGroupFormModalView'
import {
	convertToAccountsData,
	createAccountGroupData,
	getGoalBased,
	isAccountsDataValid,
	isFieldsValid,
	verifyCsvBased,
} from './accountGroupFormModalUtil'

type Props = {
	isEditing?: boolean
	isIndividualTarget?: boolean
}

const AccountGroupFormContent: React.FC<Props> = ({ isEditing, isIndividualTarget }) => {
	const { challengeId, defineAChallengeId } = useChallengeIdFromSetupFormData()
	const userInfo = useGetUserInfo()
	const { createAccountGroup } = useCreateAccountGroup({
		individualTarget: !!isIndividualTarget,
		challengeId,
	})
	const { groupOptionsMap } = useGroupOptionsMap()

	const addIndividualAccountGroup = (groupId: string, accounts: Array<AccountGroupParsedItem>) => {
		if (isIndividualTarget) {
			ChallengeEvents.addIndividualAccountGroup({
				[groupId]: {
					accounts,
				},
			})
		}
	}

	const triggerGroupCreated = (
		accountGroupData: AccountGroup,
		groupId: string,
		accounts: Array<AccountGroupParsedItem>,
	) => {
		AnalyticsService.events.groupCreated({
			group_name: accountGroupData.groupName,
			group_id: groupId,
			file_name: accountGroupData.file.name,
			file_size: accountGroupData.file.size.toString(),
			challenge_id: challengeId,
			group_size: accounts.length.toString(),
		})
	}

	const submitForm = async (formData: AccountGroupFormData): Promise<void> => {
		const accountGroupData = createAccountGroupData(formData)
		const fileResults = await parseCSVFile<AccountGroupCSVList>(accountGroupData.file)
		const goal = getGoalBased(fileResults.meta.fields)

		if (!isFieldsValid(fileResults.meta.fields, isIndividualTarget)) {
			throw new Error('Invalid Fields!')
		}

		if (!verifyCsvBased(groupOptionsMap, fileResults.meta.fields)) {
			throw new Error('Invalid Goal')
		}

		// @TO-DO put here the logic to check if is individual / money or quantity
		const accountsData = convertToAccountsData(fileResults.data)

		if (!isAccountsDataValid(accountsData)) {
			throw new Error('Invalid data!')
		}
		const group = await createAccountGroup(accountGroupData)
		ChallengeEvents.setNewlyCreatedGroup({ ...group, goal })

		addIndividualAccountGroup(group.id, accountsData)

		triggerGroupCreated(accountGroupData, group.id, accountsData)
		ChallengeEvents.setShowAccountGroupModal(false)
	}

	const handleOnCancel = () => {
		AnalyticsService.events.buttonClicked({
			button_label: null,
			button_name: 'CANCEL',
			screen_name: 'Add Group Modal',
			...userInfo,
		})
		ChallengeEvents.setShowAccountGroupModal(false)
	}

	useEffect(() => {
		if (!hasText(challengeId)) {
			defineAChallengeId()
		}
	}, [challengeId, defineAChallengeId])

	return (
		<AccountGroupFormModalView
			isIndividualTarget={!!isIndividualTarget}
			onCancel={handleOnCancel}
			submitForm={submitForm}
			challengeId={challengeId}
			exitTransition={isEditing ? 0 : undefined}
		/>
	)
}

export default AccountGroupFormContent
