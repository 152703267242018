import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ChallengeDetailsFormData } from '@/stores/challengeCreation'
import { Controller, useFormContext } from 'react-hook-form'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { Input } from '@hexa-ui/components'
import { NumberInputContainer } from '@/components/core/NumberInputContainer/NumberInputContainer'

const ChallengeFormDetailsPoints: React.FC = () => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const { control, formState } = useFormContext<ChallengeDetailsFormData>()
	const pointsErrorMessage = formState.errors.points?.message

	useEffect(() => {
		if (pointsErrorMessage) {
			AnalyticsService.events.error({
				failure_reason: pointsErrorMessage,
				form_name: 'Challenge Creation - 3rd Step',
				screen_name: 'Challenge Creation - 3rd Step',
				...userInfo,
			})
		}
	}, [pointsErrorMessage, userInfo])

	return (
		<Controller
			control={control}
			name="points"
			render={({ field: { value, ...fieldProps }, fieldState: { error } }) => (
				<NumberInputContainer>
					<Input
						min={1}
						width="100%"
						{...fieldProps}
						autoFocus
						id="points-value"
						inputMode="numeric"
						type="number"
						value={value || ''}
						label={t('challenge:DETAILS_STEP.POINTS_VALUE')}
						errorText={error?.message}
						hasError={!!error?.message}
						placeholder=""
						onWheel={(e) => e.currentTarget.blur()}
					/>
				</NumberInputContainer>
			)}
		/>
	)
}

export default ChallengeFormDetailsPoints
