import React from 'react'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { Button, Dialog, Heading, Divider, Paragraph } from '@hexa-ui/components'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import useStyles from './CancelRequestDialogStyle'
import { usePricePerPointConfiguration } from '../../hooks/usePricePerPointConfiguration'

interface CancelRequestDialogProps {
	isOpened: boolean
	// TO DO: Check values of request on BEESAM-22365
	item?: RedeemableItem
	updatedPricePerPoint: number
	// TO DO: Implement logic on BEESAM-22365
	hasError?: boolean
	isPublishing?: boolean
	onClose: () => void
}

export const CancelRequestDialog: React.FC<CancelRequestDialogProps> = ({
	isOpened,
	item,
	updatedPricePerPoint,
	hasError,
	isPublishing = false,
	onClose,
}) => {
	const { t } = useTranslation()
	const css = useStyles()
	const { data } = usePricePerPointConfiguration()
	const defaultPricePerPoint = data?.pricePerPoint

	const formattedPricePerPoint = useFormatCurrency(item ? item?.pricePerPoint : defaultPricePerPoint, '')
	const formattedUpdatedPricePerPoint = useFormatCurrency(updatedPricePerPoint, '')
	const translationContext = item ? 'redeemable:CANCEL_REQUEST.SKU' : 'redeemable:CANCEL_REQUEST.DEFAULT'

	return (
		<div data-testid="cancel-request-item-wrapper" className={css.buttons}>
			<Dialog.Root
				actions={<Actions hasError={hasError} isPublishing={isPublishing} onClose={onClose} />}
				title={
					<div className={css.heading}>
						<Heading size="H3">{t('redeemable:CANCEL_REQUEST.TITLE')}</Heading>
					</div>
				}
				open={isOpened}
				closeButton
				onClose={onClose}
			>
				<div className={css.paragraph}>
					<Paragraph>{t(`${translationContext}.DESCRIPTION`)}</Paragraph>
				</div>
				<div className={css.skuPanel}>
					<div className={css.skuInfo}>
						<Heading size="H5">{t(`${translationContext}.CPP`)}</Heading>
						<Paragraph>{formattedPricePerPoint}</Paragraph>
					</div>
					<div className={css.divider}>
						<Divider orientation="vertical" />
					</div>
					<div className={css.skuInfo}>
						<Heading size="H5">{t(`${translationContext}.UPDATED_CPP`)}</Heading>
						<Paragraph>{formattedUpdatedPricePerPoint}</Paragraph>{' '}
					</div>
				</div>
			</Dialog.Root>
		</div>
	)
}
type ActionsProps = {
	isPublishing: boolean
	hasError?: boolean
	onClose: () => void
}

const Actions = ({ hasError, isPublishing, onClose }: ActionsProps) => {
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const css = useStyles()
	const toast = useToast()
	const { t } = useTranslation()

	const handleCancelClick = () => {
		sendAnalyticsServiceEventButtonClickedEvent(
			'Cancel Request to Update Redeemable Price Per Point',
			'Cancel',
			'Cancel',
		)
		onClose()
	}

	const handleConfirmClick = () => {
		onClose()
		// TO DO: Implement logic on BEESAM-22365
		toast.notify({
			type: TypeToast.SUCCESS,
			message: t('redeemable:CANCEL_REQUEST.SUCCESSFULLY'),
		})
	}

	return (
		<div data-testid="cancel-request-item-actions-wrapper" className={css.buttons}>
			<Dialog.Close>
				<Button
					size="large"
					variant="secondary"
					data-testid="cancel-request-item-cancel-button"
					onClick={handleCancelClick}
				>
					{t('common:BUTTON.GO_BACK')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="cancel-request-confirm-button"
				size="large"
				variant="primary"
				type="submit"
				onClick={handleConfirmClick}
				isLoading={!!isPublishing}
			>
				{hasError ? t('BUTTON.TRY_AGAIN') : t('BUTTON.YES_CANCEL')}
			</Button>
		</div>
	)
}
