import { NumberInputContainer } from '@/components/core/NumberInputContainer/NumberInputContainer'
import { ChallengeDetailsFormData } from '@/stores/challengeCreation'
import { Input } from '@hexa-ui/components'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
	selectedAmount?: string
}
const ChallengeGoalAmount: React.FC<Props> = ({ selectedAmount }) => {
	const { t } = useTranslation()
	const { control } = useFormContext<ChallengeDetailsFormData>()

	return (
		<Controller
			control={control}
			name={selectedAmount === 'AmountOfMoney' ? 'currencyMin' : 'quantity'}
			render={({ field: { value, ...fieldProps }, fieldState: { error } }) => (
				<div style={{ marginTop: '16px' }}>
					<NumberInputContainer>
						<Input
							{...fieldProps}
							min={1}
							placeholder={`${t('challenge:DETAILS_STEP.CHALLENGE_GOAL.AMOUNT_OF_SKU_PLACEHOLDER')} ${
								selectedAmount === 'AmountOfMoney' ? '($)' : 'SKUs'
							}`}
							id="quantity-value"
							inputMode="numeric"
							type="number"
							value={value ?? ''}
							label={
								selectedAmount === 'AmountOfMoney'
									? `${t('challenge:DETAILS_STEP.AMOUNT')} ($) `
									: `${t('challenge:DETAILS_STEP.AMOUNT_QTY')} ${t('challenge:DETAILS_STEP.UNITS')}`
							}
							errorText={error?.message}
							hasError={!!error?.message}
							width="100%"
							prefix={selectedAmount === 'AmountOfMoney' ? '$' : ''}
							onWheel={(e) => e.currentTarget.blur()}
						/>
					</NumberInputContainer>
				</div>
			)}
		/>
	)
}

export default ChallengeGoalAmount
