import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		description: {
			margin: 'auto auto',
			paddingBottom: '4px',
			fontSize: '12px',
			lineHeight: '16px',
		},
		error: {
			color: theme.palette.error.main,
		},
		disabled: {
			color: theme.palette.text.disabled,
			pointerEvents: 'none',
		},
	}),
)

export default styles
