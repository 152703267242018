import { createStyles, makeStyles } from '@material-ui/core/styles'

const reviewItemDialogStyle = makeStyles(() =>
	createStyles({
		dialog: {
			maxWidth: '750px',
			margin: 'auto auto',
		},
		dialogAction: {
			padding: '24px 24px 16px 24px',
		},
	}),
)

export default reviewItemDialogStyle
