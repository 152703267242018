import React, { ReactElement } from 'react'

const MemoInfoIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
	const { fill, height, viewBox } = props
	return (
		<svg
			width={height}
			height={height}
			style={{ minWidth: height, minHeight: height }}
			viewBox={viewBox}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			data-testid="svg-info-icon"
		>
			<title>Icons / Info</title>
			<defs>
				<path
					d="M9.1,14.5 L10.9,14.5 L10.9,9.1 L9.1,9.1 L9.1,14.5 L9.1,14.5 Z M10,1 C5.032,1 1,5.032 1,10 C1,14.968 5.032,19 10,19 C14.968,19 19,14.968 19,10 C19,5.032 14.968,1 10,1 L10,1 Z M10,17.2 C6.031,17.2 2.8,13.969 2.8,10 C2.8,6.031 6.031,2.8 10,2.8 C13.969,2.8 17.2,6.031 17.2,10 C17.2,13.969 13.969,17.2 10,17.2 L10,17.2 Z M9.1,7.3 L10.9,7.3 L10.9,5.5 L9.1,5.5 L9.1,7.3 L9.1,7.3 Z"
					id="path-1"
				/>
			</defs>
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Callout-/-Android-/-Guidance" transform="translate(-9.000000, -9.000000)">
					<g id="Icons-/-Info" transform="translate(8.000000, 8.000000)">
						<polygon id="Bounds" points="0 0 20 0 20 20 0 20" />
						<mask id="mask-2" fill="white">
							<use xlinkHref="#path-1" />
						</mask>
						<use id="Mask" fill={fill} fillRule="nonzero" xlinkHref="#path-1" />
					</g>
				</g>
			</g>
		</svg>
	)
}

const defaultProps: React.SVGProps<SVGSVGElement> = {
	fill: '#4D6EDB',
	height: '20px',
	viewBox: '0 0 20 20',
}

MemoInfoIcon.defaultProps = defaultProps

const InfoIcon = React.memo(MemoInfoIcon)
export default InfoIcon
