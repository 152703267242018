import { createStyles, makeStyles } from '@material-ui/core/styles'

const ProductColumnStyle = makeStyles(() =>
	createStyles({
		productColumn: {
			display: 'flex',
			alignItems: 'center',
		},
		imageContainer: {
			width: '99px',
			height: '64px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'& img': {
				width: '74.25px',
				height: '48px',
				objectFit: 'contain',
				margin: '0 auto',
			},
		},
		productInfoContainer: {
			padding: '12px 16px',
			width: '248px',
			'& p': {
				color: 'rgba(0, 0, 0, 0.9)',
				fontSize: '14px',
				lineHeight: '20px',
				letterSpacing: '0.1px',
			},
		},
		productName: {
			fontFamily: 'Helvetica',
			fontWeight: 800,
		},
		productDescription: {
			fontFamily: 'Work Sans',
		},
	}),
)

export default ProductColumnStyle
