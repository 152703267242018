import SkuForm from '@/domains/sku/SkuForm'
import { createStore } from 'effector'
import * as RulesEvents from './RulesEvents'
import { rulesInitialState } from './RulesState'

const RulesStore = createStore(rulesInitialState)
	.on(RulesEvents.loadRules, (state, data) => ({
		...state,
		...data,
	}))
	.on(RulesEvents.setRuleId, (state, ruleId) => ({
		...state,
		ruleId,
	}))
	.on(RulesEvents.setCategoryId, (state, categoryId) => ({
		...state,
		categoryId,
	}))
	.on(RulesEvents.showChangeRuleDialog, (state) => ({
		...state,
		showChangeRuleDialog: true,
	}))
	.on(RulesEvents.hideChangeRuleDialog, (state) => ({
		...state,
		showChangeRuleDialog: false,
	}))
	.on(RulesEvents.setSku, (state, { sku, rowIndexToDelete }) => ({
		...state,
		sku,
		rowIndexToDelete,
	}))
	.on(RulesEvents.showRemoveSkuDialog, (state) => ({
		...state,
		showRemoveSkuDialog: true,
	}))
	.on(RulesEvents.hideRemoveSkuDialog, (state) => ({
		...state,
		showRemoveSkuDialog: false,
	}))
	.on(RulesEvents.showAddSkuRuleDialog, (state) => ({
		...state,
		showAddSkuRuleDialog: true,
	}))
	.on(RulesEvents.hideAddSkuRuleDialog, (state) => ({
		...state,
		showAddSkuRuleDialog: false,
	}))
	.on(RulesEvents.addSku, (state, sku) => ({
		...state,
		activeSkuList: [sku, ...state.activeSkuList],
	}))
	.on(RulesEvents.removeSku, (state, removedSku) => ({
		...state,
		activeSkuList: state.activeSkuList.filter((sku) => sku.id !== removedSku),
	}))
	.on(RulesEvents.setActiveSkuList, (state, activeSkuList) => ({
		...state,
		activeSkuList,
	}))
	.on(RulesEvents.setPropInEditSkuStepData, (state, prop) => ({
		...state,
		editSkuStepData: {
			...state.editSkuStepData,
			...prop,
		},
	}))
	.on(RulesEvents.setSkuUploadFile, (state, file) => ({
		...state,
		skuUploadFile: {
			...file!,
		},
	}))
	.on(RulesEvents.addSingleSku, (state, singleSku: SkuForm) => ({
		...state,
		singleSku,
		editSkuStepData: {
			...state.editSkuStepData,
			skus: !!singleSku.itemId,
		},
	}))
	.reset(RulesEvents.resetRulesStore)

export default RulesStore
