import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
	createStyles({
		title: {
			fontFamily: 'Work Sans',
			fontSize: '16px',
			fontWeight: 500,
		},
		content: {
			fontSize: '16px',
			color: '#707372',
		},
		inline: {
			padding: 0,
			display: 'flex',
			justifyContent: 'space-between',
		},
	}),
)
