import * as yup from 'yup'
import { fieldStartDateValidation, fieldEndDateValidation } from '@/utils/validationObjects'
import i18n from '@/i18n'

interface FilterItem {
	filter: string
	value: Array<string | undefined> | undefined
}

const createStartDateValidation = (isEditing: boolean, isActiveChallenge: boolean): yup.DateSchema => {
	if (isEditing && isActiveChallenge) {
		return yup.date().required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD')).typeError(i18n.t('ERROR_MESSAGE.INVALID_DATE'))
	}
	return fieldStartDateValidation()
}

const createEndDateValidation = (isActiveChallenge: boolean): yup.DateSchema => {
	return fieldEndDateValidation(isActiveChallenge)
}

const createFiltersValidation = (): yup.NotRequiredArraySchema<FilterItem | undefined> =>
	yup
		.array(
			yup
				.object()
				.shape({
					filter: yup.string().required(),
					value: yup.array(yup.string()).required(),
				})
				.test('has-values', i18n.t('ERROR_MESSAGE.EMPTY_FIELD'), (object: any) => {
					return object?.value && object?.value?.length > 0
				}),
		)
		.min(1)

export const getValidationObject = (isEditing = false, isActiveChallenge = false): yup.ObjectSchema => {
	return yup.object().shape({
		startDate: createStartDateValidation(isEditing, isActiveChallenge),
		endDate: createEndDateValidation(isActiveChallenge),
		hasFilter: yup.bool().required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD')),
		filters: yup.array().when('hasFilter', {
			is: true,
			then: createFiltersValidation(),
			otherwise: yup.array(),
		}),
		individualTarget: yup.bool().transform((value) => !!value),
	})
}
