import { createStyles, makeStyles } from '@material-ui/core/styles'

const EditSkuStyle = makeStyles(() =>
	createStyles({
		editSkuCard: {
			position: 'relative',
			margin: '24px auto',
			width: '100%',
			maxWidth: '864px',
			display: 'flex',
			flexDirection: 'column',
		},
	}),
)

export default EditSkuStyle
