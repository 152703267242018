import { ImageFile } from '@/domains/Challenge'
import { FileUploaderType, FileValidated, FileUploader as HexaFileUploader, Paragraph } from '@hexa-ui/components'
import React, { useEffect, useState } from 'react'

interface IImageUpload extends FileUploaderType {
	name: string
	description: string
	label: string
	onUpload: (event: React.ChangeEvent<HTMLInputElement>, field: string) => void
	onRemove: (field: string, files: File) => void
	currentValue?: ImageFile | null
}

export const FileUploader: React.ForwardRefRenderFunction<React.Ref<HTMLDivElement>, IImageUpload> = ({
	onUpload,
	onRemove,
	name,
	currentValue,
	error,
	...props
}) => {
	const [fileImage, setFileImage] = useState<string>()

	const handleDrop = (filesDropped: FileValidated[]) => {
		const dataTransfer = {
			files: [filesDropped[0].file],
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} as any

		const fileList = dataTransfer.files
		const inputElement: React.ChangeEvent<HTMLInputElement> = {
			currentTarget: {
				files: fileList,
			},
		} as React.ChangeEvent<HTMLInputElement>

		onUpload(inputElement, name)

		const reader = new FileReader()
		reader.onload = (e) => {
			setFileImage(e.target?.result as string)
		}
		reader.readAsDataURL(filesDropped[0].file)
	}

	const handleRemove = (filesRemoved: FileValidated[]) => {
		onRemove(name, filesRemoved[0].file)
		setFileImage('')
	}

	const handleValue = (image?: ImageFile | null): Array<FileValidated> | undefined => {
		if (image?.file) {
			return [
				{
					file: image.file,
					id: '1',
					valid: true,
					errors: [],
				},
			]
		}
		return undefined
	}

	useEffect(() => {
		setFileImage(currentValue?.url ?? '')
	}, [currentValue])

	return (
		<div
			style={{
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				width: '100%',
			}}
		>
			<Paragraph weight="semibold" size="small">
				{props.label}
			</Paragraph>
			<Paragraph colortype="secondary" size="xsmall">
				{props.description}
			</Paragraph>
			<div data-testid="file-uploader">
				<HexaFileUploader
					{...props}
					imageUrl={fileImage ?? ''}
					value={handleValue(currentValue)}
					onDrop={handleDrop}
					maxFiles={1}
					onClean={handleRemove}
					maxFileSize={1000000}
					error={error}
				/>
			</div>
		</div>
	)
}

export default React.forwardRef(FileUploader)
