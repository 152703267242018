import FieldErrorMessage from '@/components/form/FieldErrorMessage'
import { ComboStore } from '@/stores'
import { Box, CircularProgress } from '@material-ui/core'
import { useStore } from 'effector-react'
import React from 'react'

type Props = {
	selectedFile: Array<JSX.Element> | null
}

const FileUploadControls: React.FC<Props> = ({ selectedFile }: Props) => {
	const { fileValidation } = useStore(ComboStore)

	return (
		<Box display="flex" flex="100%" paddingLeft="32px" paddingBottom="16px">
			{fileValidation.isValidating && <CircularProgress size={24} data-testid="loading-csv-file" />}
			{!fileValidation.isValidating && fileValidation.errorMessage ? (
				<FieldErrorMessage id="csvFile" message={fileValidation.errorMessage} />
			) : null}
			{!fileValidation.isValidating && !fileValidation.errorMessage && selectedFile ? selectedFile : null}
		</Box>
	)
}

export default FileUploadControls
