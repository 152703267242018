import Rule from '@/domains/rule/Rule'
import { RulesStore } from '@/stores'
import { useStoreMap } from 'effector-react'
import React, { useMemo, useState } from 'react'
import ButtonView from '../RulesRedesign/Components/ButtonView/ButtonView'
import RuleNameColumn from '../RulesRedesign/Components/RuleNameColumn/RuleNameColumn'

export interface RuleDataTableResult {
	dataRules: Rule[]
	setRuleFilter: React.Dispatch<React.SetStateAction<string>>
}

const useRuleDataTable = (): RuleDataTableResult => {
	const [ruleFilter, setRuleFilter] = useState('')

	const rulesList = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => state.rules,
	})

	const dataRules = useMemo(() => {
		if (rulesList) {
			const rules = rulesList
				?.map((rule: Rule) => ({
					...rule,
					nameColumn: <RuleNameColumn rule={rule} />,
					action: <ButtonView rule={rule} />,
				}))
				.filter((rule) => rule.name.includes(ruleFilter))

			return rules
		}
		return []
	}, [rulesList, ruleFilter])

	return { dataRules, setRuleFilter }
}

export default useRuleDataTable
