import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { RedeemableItemStatus } from '@/domains/enums/redeemable'

import { BadgeStatusType } from '@/components/core/BadgeStatus'

export type UseRedeemableItemStatusResult = {
	buildStatusProps: (item: RedeemableItem) => RedeemableStatusProps
}

export type RedeemableStatusProps = {
	text: string
	type: BadgeStatusType
	infoMessage?: string
}

const statusMapping = {
	[RedeemableItemStatus.PROCESSING]: {
		text: 'redeemable:ITEMS_LIST_TABLE.STATUS_PROCESSING',
		type: BadgeStatusType.info,
		infoMessage: undefined,
	},
	[RedeemableItemStatus.FAILED]: {
		text: 'redeemable:ITEMS_LIST_TABLE.STATUS_FAILED',
		type: BadgeStatusType.error,
		infoMessage: 'redeemable:ITEMS_LIST_TABLE.STATUS_FAILED_MESSAGE',
	},
	[RedeemableItemStatus.PUBLISHED]: {
		text: 'redeemable:ITEMS_LIST_TABLE.STATUS_ACTIVE',
		type: BadgeStatusType.success,
		infoMessage: undefined,
	},
}

export const useRedeemableItemStatus = (): UseRedeemableItemStatusResult => {
	const { t } = useTranslation()

	const buildStatusProps = useCallback(
		(item: RedeemableItem): RedeemableStatusProps => {
			const itemStatus = item.status && statusMapping[item.status]
			const statusInfo = itemStatus || {
				text: '',
				type: BadgeStatusType.disabled,
				infoMessage: undefined,
			}

			const text = t(statusInfo.text || '')
			const infoMessage = statusInfo?.infoMessage ? t(statusInfo.infoMessage) : undefined

			return {
				text,
				type: statusInfo.type,
				infoMessage,
			}
		},
		[t],
	)

	return {
		buildStatusProps,
	}
}
