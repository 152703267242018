import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { BASE_ROUTE } from 'utils/constants'
import TransactionForm from 'routes/transaction/components/TransactionForm/TransactionForm'
import { SkusProvider } from '@/context/SkusContext/SkusContext'
import { SelectedRewardTypeProvider } from '@/context/RewardsTypeSelectedContext/RewardsTypeSelectedContext'

const TransactionRoute: React.FC = () => {
	return (
		<Switch>
			<Route exact path={`${BASE_ROUTE}/transaction`}>
				<SelectedRewardTypeProvider>
					<SkusProvider>
						<TransactionForm />
					</SkusProvider>
				</SelectedRewardTypeProvider>
			</Route>
		</Switch>
	)
}

export default TransactionRoute
