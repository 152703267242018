import React from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import InputContainer, { InputContainerProps } from '@/components/form/InputContainer'

export type InputTextProps = InputContainerProps & TextFieldProps

const InputText: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputTextProps> = (
	{ id, label, optionalLabel, helperText, errorText, infoIconTooltip, customMessageTooltip, maxLength, ...rest },
	ref,
) => {
	const hasError = !!errorText
	const valueLength = `${rest.value}`.length

	return (
		<InputContainer
			id={id}
			label={label}
			optionalLabel={optionalLabel}
			errorText={errorText}
			helperText={helperText}
			disabled={rest.disabled}
			infoIconTooltip={infoIconTooltip}
			customMessageTooltip={customMessageTooltip}
			maxLength={maxLength}
			valueLength={valueLength}
		>
			<TextField
				fullWidth
				minRows={3}
				maxRows={6}
				variant="outlined"
				error={hasError}
				inputRef={ref}
				id={id}
				inputProps={{ maxLength }}
				{...rest}
			/>
		</InputContainer>
	)
}

export default React.forwardRef(InputText)
