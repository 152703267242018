import SupportedLanguages from '@/domains/configurations/SupportedLanguages'
import { OfferCategory } from '@/domains/offerCategory/offerCategory'
import { IProgram } from '@/domains/program'
import { Tier } from '@/domains/tier'
import { VendorOptionsMap } from '@/domains/vendors'
import { MULTI_ZONE_LOCAL_STORAGE_KEY } from '@/utils/constants'
import { getStorageItemString } from '@/utils/storageUtil'

const selectedZone = getStorageItemString(MULTI_ZONE_LOCAL_STORAGE_KEY) ?? null

interface GlobalState {
	isPageReady: boolean
	isLoading: boolean
	selectedProgram: IProgram | null
	selectedZone: string | null
	selectedVendorId: string | null
	vendorOptionsMap: VendorOptionsMap
	selectedTierKey: string | null
	tiersMap: Record<string, Tier>
	supportedLanguages: SupportedLanguages | null
	offerCategories: OfferCategory[] | null
}

export const globalInitialState: GlobalState = {
	isPageReady: false,
	isLoading: false,
	selectedProgram: null,
	selectedZone,
	selectedVendorId: null,
	vendorOptionsMap: {},
	selectedTierKey: null,
	tiersMap: {},
	supportedLanguages: null,
	offerCategories: null,
}

export default GlobalState
