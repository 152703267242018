import InputLabel from '@/components/formik/InputLabel'
import InputText from '@/components/formik/InputText'
import InputTextArea from '@/components/formik/InputTextArea'
import { useSelectedReward } from '@/context/RewardsTypeSelectedContext/RewardsTypeSelectedContext'
import { PointsRemovalType } from '@/domains/enums'
import usePointsRemovalTypeList from '@/hooks/transaction/usePointsRemovalTypeList'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { TransactionEvents } from '@/stores'
import { isPointsRemovalAndCategorizationEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { Paragraph } from '@hexa-ui/components'
import { Box, Grid, Grow } from '@material-ui/core'
import clsx from 'clsx'
import InputRadioGroup from 'components/formik/InputRadioGroup'
import { useField, useFormikContext } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PocValidationParams from '../../model/PocValidationParams'
import PocIdField from '../PocIdField/PocIdField'
import useStyle from './PointsRemovalStyle'
import { CsvUploader } from './components/CsvUploader/CsvUploader'

type PointsRemovalViewProps = {
	showError: boolean
	onPocValidation: (params: PocValidationParams) => Promise<string>
	currentPointsBalance: string | JSX.Element
	newPointsBalance: string | JSX.Element
	removeSuccessMessage: () => void
	onChangePoc: (pocId: string) => void
	onError?: (id: string, message: string) => void
}

const PointsRemovalView: React.FC<PointsRemovalViewProps> = ({
	onPocValidation,
	currentPointsBalance,
	removeSuccessMessage,
	newPointsBalance,
	showError,
	onChangePoc,
	onError,
}) => {
	const { t } = useTranslation()
	const classes = useStyle()
	const { setRewardSelected } = useSelectedReward()
	const [pocBalanceHasError, setPocBalanceHasError] = useState<boolean>(false)
	const isPointsRemovalAndCategorization = isPointsRemovalAndCategorizationEnabled()
	const pointsRemovalOptions = usePointsRemovalTypeList()
	const [removalTypeSelected, setRemovalTypeSelected] = useState<PointsRemovalType>(PointsRemovalType.SinglePoc)
	const hasBulkPointsRemovalPermission = useScopeAuthorization(['Membership.BulkPointsRemoval.Delete'])
	const { setFieldValue, getFieldMeta, validateField } = useFormikContext()
	const [field, meta] = useField('pocId')
	const [pocIdField, setPocIdField] = useState('')
	const pointsField = getFieldMeta('points')

	const rewardsOfferOptionsWithPermission = useMemo(() => {
		if (hasBulkPointsRemovalPermission) {
			return pointsRemovalOptions
		}
		return pointsRemovalOptions.filter((option) => option.value !== PointsRemovalType.MultiplePoc)
	}, [hasBulkPointsRemovalPermission, pointsRemovalOptions])

	useEffect(() => {
		const pointsBalance = parseInt(currentPointsBalance as string, 10)
		setPocBalanceHasError(!Number.isNaN(pointsBalance) && pointsBalance <= 0)
	}, [currentPointsBalance])

	useEffect(() => {
		setRewardSelected(removalTypeSelected)
	}, [removalTypeSelected, setRewardSelected])

	const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		const { value } = e.target as HTMLInputElement
		if (value.length === 1 && removeSuccessMessage) {
			removeSuccessMessage()
		}
	}

	const handleRadioChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const pointsRemovalType = event.target.value as PointsRemovalType
		setRemovalTypeSelected(pointsRemovalType)

		setFieldValue('pointsRemovalType', pointsRemovalType)
		setFieldValue('pocId', '', false)
		setFieldValue('currentPoints', null, false)
		setFieldValue('points', '', false)
		setFieldValue('accounts', undefined, false)

		const validationMap: { [key in PointsRemovalType]?: () => void } = {
			[PointsRemovalType.MultiplePoc]: () => {
				validateField('points')
				validateField('accounts')
			},
		}

		validationMap[pointsRemovalType]?.()

		TransactionEvents.setPointsRemoval({
			currentPointsBalance: (
				<Paragraph colortype="disabled" size="small">
					{t('transaction:POINTS_REMOVAL.CURRENT_POINTS_BALANCE_EMPTY')}
				</Paragraph>
			),
			newPointsBalance: (
				<Paragraph colortype="disabled" size="small">
					{t('transaction:POINTS_REMOVAL.NEW_POINTS_BALANCE_EMPTY')}
				</Paragraph>
			),
		})
	}

	const renderPocIdField = () => {
		return (
			<PocIdField
				id="points-removal-poc-id"
				name="pocId"
				label={t('transaction:POINTS_REMOVAL.POC_ID_LABEL')}
				placeholder={t('transaction:POINTS_REMOVAL.POC_ID_PLACEHOLDER')}
				onPocValidation={onPocValidation}
				onChangePoc={onChangePoc}
				showError={showError}
				onError={onError}
				pocIdField={pocIdField}
				setPocIdField={setPocIdField}
			/>
		)
	}

	const renderCurrentPointsBalance = () => {
		return (
			<Box id="points-balance" mt="8px" mb="24px" style={{ display: 'flex', gap: '8px' }}>
				<span id="points-balance-label" data-testid="points-balance-label" className={classes.label}>
					{`${t('transaction:POINTS_REMOVAL.CURRENT_POINTS_BALANCE_LABEL')} `}
				</span>
				<span
					id="points-balance-value"
					className={clsx({
						[classes.value]: !pocBalanceHasError,
						[classes.valueWithError]: pocBalanceHasError,
					})}
					data-testid="points-balance-value"
				>
					{currentPointsBalance}
				</span>
			</Box>
		)
	}

	const renderRemovalPointsField = () => {
		return (
			<InputText
				id="points-removal-points"
				name="points"
				type="number"
				label={t('transaction:POINTS_REMOVAL.POINTS_LABEL')}
				placeholder={t('transaction:POINTS_REMOVAL.POINTS_PLACEHOLDER')}
				onKeyUp={keyUpHandler}
				showError={showError}
				onError={onError}
				setPocIdField={setPocIdField}
			/>
		)
	}

	const renderNewPointsBalance = () => {
		return (
			<Box id="new-points-balance" mt="24px" style={{ display: 'flex', gap: '8px' }}>
				<span id="new-points-balance-label" data-testid="new-points-balance-label" className={classes.label}>
					{`${t('transaction:POINTS_REMOVAL.NEW_POINTS_BALANCE_LABEL')} `}
				</span>
				<span id="new-points-balance-value" className={classes.value} data-testid="new-points-balance-value">
					{pointsField.value ? (
						newPointsBalance
					) : (
						<Paragraph colortype="disabled" size="small">
							{t('transaction:POINTS_REMOVAL.NEW_POINTS_BALANCE_EMPTY')}
						</Paragraph>
					)}
				</span>
			</Box>
		)
	}

	const renderDescriptionField = (hasTooltip: boolean) => {
		return (
			<InputTextArea
				id="points-removal-description"
				name="description"
				label={t('transaction:POINTS_REMOVAL.DESCRIPTION_LABEL')}
				onKeyUp={keyUpHandler}
				showError={showError}
				onError={onError}
				hasTooltip={hasTooltip}
				tooltipTextKey="transaction:POINTS_REMOVAL.TOOLTIP_DESCRIPTION"
				placeholder={t('transaction:POINTS_REMOVAL.DESCRIPTION_PLACEHOLDER')}
				setPocIdField={setPocIdField}
			/>
		)
	}

	const renderOldPointsRemovalView = () => {
		return (
			<>
				{renderPocIdField()}
				{renderCurrentPointsBalance()}

				<Grid container spacing={1}>
					<Grid item xs={12}>
						{renderRemovalPointsField()}
					</Grid>
					<Grid item xs={12}>
						{renderDescriptionField(false)}
						{renderNewPointsBalance()}
					</Grid>
				</Grid>
			</>
		)
	}

	return (
		<Grow in>
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						{isPointsRemovalAndCategorization && (
							<>
								{renderDescriptionField(true)}
								<Box id="points-removal-offer-type" mt="16px">
									<InputLabel label={t('transaction:REWARDS_OFFER.SELECT_POC')} htmlFor="removal-offer-type" />
									<Grid container alignItems="flex-start" direction="column">
										{rewardsOfferOptionsWithPermission.map((option) => (
											<React.Fragment key={option.value}>
												<InputRadioGroup
													defaultValue={PointsRemovalType.SinglePoc}
													id="removal-offer-type"
													name="removalOfferType"
													options={[option]}
													hasContainerBox={false}
													showError={false}
													onChange={handleRadioChange}
												/>
												{removalTypeSelected === PointsRemovalType.SinglePoc &&
													option.value === PointsRemovalType.SinglePoc && (
														<Grow in>
															<Grid container spacing={1}>
																<Grid item xs={12}>
																	{renderPocIdField()}
																	{renderCurrentPointsBalance()}
																	{!!field?.value && !meta?.error && meta?.error === undefined && (
																		<>
																			<Grid item xs={12}>
																				{renderRemovalPointsField()}
																			</Grid>
																			{renderNewPointsBalance()}
																		</>
																	)}
																</Grid>
															</Grid>
														</Grow>
													)}

												{removalTypeSelected === PointsRemovalType.MultiplePoc &&
													option.value === PointsRemovalType.MultiplePoc && (
														<Grow in>
															<CsvUploader />
														</Grow>
													)}
											</React.Fragment>
										))}
									</Grid>
								</Box>
							</>
						)}

						{!isPointsRemovalAndCategorization && renderOldPointsRemovalView()}
					</Grid>
				</Grid>
			</Box>
		</Grow>
	)
}

export default PointsRemovalView
