import React from 'react'
import { Alert, Button, Dialog } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import * as currencyHelper from '@/utils/currencyHelper'
import { InputCurrency } from '@/components/hook-form/InputCurrency'
import useStyle from './EditDefaultCPPDialogStyle'
import { usePricePerPointConfiguration } from '../../hooks/usePricePerPointConfiguration'

interface ActionsProps {
	onClose: () => void
	onUpdate: () => void
}

interface EditDefaultCppDialogProps extends ActionsProps {
	open: boolean
}

export const EditDefaultCppDialog: React.FC<EditDefaultCppDialogProps> = ({ open, onClose, onUpdate }) => {
	const { t } = useTranslation()
	const css = useStyle()
	const { data } = usePricePerPointConfiguration()

	const title = <h1>{t('redeemable:UPDATE_CPP.TITLE')}</h1>

	const defaultPricePerPoint = data?.pricePerPoint
	const placeholder = currencyHelper.format(defaultPricePerPoint)

	return (
		<Dialog.Root
			closeButton
			title={title}
			open={open}
			onClose={onClose}
			actions={<Actions onClose={onClose} onUpdate={onUpdate} />}
		>
			<p>{t('redeemable:UPDATE_CPP.DESCRIPTION')}</p>
			<InputCurrency name="pricePerPoint" label="Default CPP" placeholder={placeholder} width="100%" />

			<Alert className={css.alert} type="warning" message={t('redeemable:UPDATE_CPP.WARNING')} />
		</Dialog.Root>
	)
}

const Actions = ({ onClose, onUpdate }: ActionsProps) => {
	const { t } = useTranslation()
	const css = useStyle()
	return (
		<div className={css.actionsWrapper} data-testid="ppp-editor-actions-wrapper">
			<Dialog.Close>
				<Button size="large" variant="secondary" onClick={onClose}>
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button data-testid="ppp-editor-save-button" size="large" variant="primary" type="submit" onClick={onUpdate}>
				{t('common:BUTTON.UPDATE')}
			</Button>
		</div>
	)
}
