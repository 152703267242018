import { ScreenName } from '@/domains'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { useIsEditingAllowed } from '@/routes/zoneConfiguration/hooks/useIsEditingAllowed'
import { Card, IconButton, LoadingDots, TextLink, Tooltip } from '@hexa-ui/components'
import { Edit2, Info } from '@hexa-ui/icons'
import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { REDEEMABLE_DEFAULT_CPP } from '@/utils/constants'
import { usePricePerPointConfiguration } from '../../hooks/usePricePerPointConfiguration'
import { PricePerPointEditor } from './components/PricePerPointEditor'

import RedeemableItemsSettingsPanelStyle from './RedeemableItemsSettingsPanelStyle'

enum ViewState {
	LOADING = 'LOADING',
	ERROR = 'ERROR',
	DONE = 'DONE',
}

export const RedeemableItemsSettingsPanel = () => {
	const { data, isLoading, hasError, loadData } = usePricePerPointConfiguration()
	const { isPricePerPointAllowed, isRedeemableCPPPermission } = useIsEditingAllowed()
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const hasDefaultCPPPermission = useScopeAuthorization([REDEEMABLE_DEFAULT_CPP])

	const [viewState, setViewState] = useState<ViewState>(ViewState.LOADING)

	const canChangeDefaultCPP = isRedeemableCPPPermission && isPricePerPointAllowed && hasDefaultCPPPermission

	useEffect(() => {
		if (isLoading) {
			setViewState(ViewState.LOADING)
			return
		}

		if (hasError) {
			setViewState(ViewState.ERROR)
			return
		}

		setViewState(ViewState.DONE)
	}, [isLoading, hasError])

	useEffect(() => {
		loadData()
	}, [loadData])

	const { t } = useTranslation()
	const css = RedeemableItemsSettingsPanelStyle()

	const pricePerPoint = useFormatCurrency(data?.pricePerPoint, t('redeemable:SETTINGS.PPP_MISSING_DEFAULT_VALUE'))

	const handleOnClick = () => {
		sendAnalyticsServiceEventButtonClickedEvent(
			ScreenName.RedeemablesList,
			'Price per point',
			`Default price per point: ${data?.pricePerPoint}`,
		)
	}

	return (
		<Card border="small" elevated="minimal" className={css.settingsPanelWrapper} data-testid="settings-panel">
			{viewState === ViewState.LOADING && <LoadingDots data-testid="settings-panel-loading" />}
			{viewState === ViewState.ERROR && (
				<div data-testid="settings-panel-error">
					<TextLink size="small" data-testid="settings-panel-try-again-button" onClick={loadData}>
						{t('common:ERROR_MESSAGE:GENERIC_ERROR')}
					</TextLink>
				</div>
			)}
			{viewState === ViewState.DONE && (
				<>
					<span className={css.settingsPanelItem_info}>
						<Tooltip placement="bottom" text={t('redeemable:SETTINGS.PPP_TOOLTIP_INFO')}>
							<Info size="medium" />
						</Tooltip>
					</span>
					<div className={css.settingsPanelItem} data-testid="settings-panel-done">
						<Typography>
							<span className={css.settingsPanelItem_title}>{t('redeemable:SETTINGS.PPP_LABEL')}</span>
							<span className={css.settingsPanelItem_value} data-testid="settings-panel-ppp-value">
								{pricePerPoint}
							</span>
						</Typography>
					</div>
					{canChangeDefaultCPP ? <PricePerPointEditor editButton={EditItemPPPButton(handleOnClick)} /> : null}
				</>
			)}
		</Card>
	)
}

const EditItemPPPButton = (handleOnClick: () => void) => {
	const icon = () => <Edit2 size="large" style={{ color: 'var(--colors-semanticInfoBasis)' }} />
	return <IconButton onClick={handleOnClick} variant="inherit" icon={icon} size="small" />
}
