import { styled } from '@hexa-ui/theme'

export const DateSelectContainer = styled('div', {
	display: 'grid',
	gridTemplateColumns: '1fr 1fr',
	marginBottom: '24px',
	marginTop: '16px',
	gap: '24px',
	flex: 1,
})

export const CategorySelectContainer = styled('div', { marginBottom: '16px' })
