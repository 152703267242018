import { createStyles, makeStyles } from '@material-ui/core/styles'

const RedeemableItemsListStyle = makeStyles(() =>
	createStyles({
		mainWrapper: {
			flexDirection: 'column',
			alignItems: 'flex-end',
			width: '100%',
		},
		headerWrapper: {
			justifyContent: 'flex-end',
			width: '100%',
			padding: 0,
			position: 'absolute',
			top: '-70px',

			'& > *': {
				marginLeft: '1rem',
			},

			'& :first-child': {
				marginLeft: '0px',
			},
		},

		actions: {
			display: 'flex',
			gap: '12px',
		},
		removeButton: {
			'& > svg': {
				fill: 'var(--colors-neutral100)',
				marginRight: '4px',
			},
		},
		reloadButtonContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			position: 'relative',
			gap: 'var(--space-6)',
		},
		reloadButton: {
			'& > svg': {
				marginRight: '4px',
				fill: 'var(--colors-neutral100)',
			},
		},
		productWrapper: {
			display: 'flex',
			alignItems: 'center',
			margin: '8px 0',
			gap: '24px',
		},
		imageContainer: {
			width: '40px',
			height: '40px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			'& img': {
				width: '40px',
				height: '40px',
				objectFit: 'contain',
				margin: '0 auto',
			},
		},
		productInfoContainer: {
			'& > *': {
				fontSize: '14px',
			},
		},
		productName: {
			fontWeight: 600,
		},
	}),
)

export default RedeemableItemsListStyle
