import OrderWithRedeemables from '@/domains/OrderWithRedeemables'
import { ValidationError } from '@/domains/ValidationError'
import { load } from '@/services/order/RefundService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'

export enum ValidationErrorType {
	invalidOrderIdMessage = 'ERROR_MESSAGE.INVALID_ORDER_ID',
	noRefundableItemsFoundMessage = 'ERROR_MESSAGE.NO_REFUNDABLE_ITEMS_FOUND',
}

export const execute = async (orderId: string): Promise<OrderWithRedeemables> => {
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const response = await load(orderId, vendorId)

	if (!response?.redeemables) {
		throw new ValidationError(ValidationErrorType.invalidOrderIdMessage)
	}

	if (!response.redeemables.length) {
		throw new ValidationError(ValidationErrorType.noRefundableItemsFoundMessage)
	}

	return {
		...response,
		orderId,
		placementDate: new Date(response.placementDate),
	}
}
