enum ChallengeExecutionMethod {
	TakePhoto = 'TAKE_PHOTO',
	MarkAsComplete = 'MARK_COMPLETE',
	Purchase = 'PURCHASE',
	PurchaseMultiple = 'PURCHASE_MULTIPLE',
	VolumeFixed = 'PURCHASE_MULTIPLE_VOLUME_FIXED',
	SingleVolumeFixed = 'PURCHASE_SINGLE_VOLUME_FIXED',
	SpendBased = 'PURCHASE_MULTIPLE_CURRENCY_FIXED',
}

export default ChallengeExecutionMethod
