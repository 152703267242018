import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { createStore, createEvent } from 'effector'

export type EditRedeemableItemConfiguration = {
	isOpened: boolean
	isPublishing: boolean
	isPublishSuccess: boolean
	hasError: boolean
	item: RedeemableItem
}

export const editRedeemableItemStoreInitialState: EditRedeemableItemConfiguration = {
	isOpened: false,
	isPublishing: false,
	isPublishSuccess: false,
	hasError: false,
	item: { id: '', vendorId: '', vendorItemId: '', itemName: '' },
}

export const $editRedeemableItemStore = createStore(editRedeemableItemStoreInitialState)

// Events

export const EditRedeemableItemStoreEvents = {
	onOpen: createEvent<RedeemableItem>('onOpen'),
	onClose: createEvent('onClose'),
	isPublishing: createEvent('isPublishing'),
	isPublishSuccess: createEvent('isPublishSuccess'),
	hasError: createEvent<boolean>(),
	setItem: createEvent<RedeemableItem>(),
	reset: createEvent('reset'),
}

const onOpen = (state: EditRedeemableItemConfiguration, item: RedeemableItem): EditRedeemableItemConfiguration => ({
	...state,
	isOpened: true,
	item,
})

const onClose = (state: EditRedeemableItemConfiguration): EditRedeemableItemConfiguration => ({
	...state,
	isOpened: false,
})

const isPublishing = (state: EditRedeemableItemConfiguration): EditRedeemableItemConfiguration => ({
	...state,
	isPublishing: true,
	isPublishSuccess: false,
	hasError: false,
})

const isPublishSuccess = (state: EditRedeemableItemConfiguration): EditRedeemableItemConfiguration => ({
	...state,
	isPublishing: false,
	isPublishSuccess: true,
	hasError: false,
})

const hasError = (state: EditRedeemableItemConfiguration, status: boolean): EditRedeemableItemConfiguration => ({
	...state,
	isPublishing: false,
	isPublishSuccess: false,
	hasError: status,
})

// Handlers

$editRedeemableItemStore
	.on(EditRedeemableItemStoreEvents.onOpen, onOpen)
	.on(EditRedeemableItemStoreEvents.onClose, onClose)
	.on(EditRedeemableItemStoreEvents.isPublishing, isPublishing)
	.on(EditRedeemableItemStoreEvents.isPublishSuccess, isPublishSuccess)
	.on(EditRedeemableItemStoreEvents.hasError, hasError)
	.reset(EditRedeemableItemStoreEvents.reset)
