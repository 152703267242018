enum RewardsOfferType {
	SinglePoc = 'SINGLE_POC',
	MultiplePoc = 'MULTIPLE_POC',
}

export enum ProductCategory {
	Beer = 'BEER',
	Nab = 'NAB',
	Marketplace = 'MARKETPLACE',
}

export default RewardsOfferType
