import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useEarningRulesContentColumns = () => {
	const { t } = useTranslation()

	const columns = useMemo(
		() => [
			{
				Header: t('rules:EARNING_RULES_TABLE.RULE'),
				accessor: 'nameColumn',
				disableSortBy: true,
				style: { width: '70%' },
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.CURRENCY_AMOUNT'),
				accessor: 'currencyAmount',
				disableSortBy: true,
				style: { width: '10%' },
			},
			{
				Header: t('common:POINTS'),
				accessor: 'points',
				disableSortBy: true,
				style: { width: '10%' },
			},
			{
				Header: t('common:ACTIONS'),
				accessor: 'action',
				label: t('VIEW'),
				disableSortBy: true,
				style: { width: '10%' },
			},
		],
		[t],
	)

	return columns
}
