import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { BASE_ROUTE } from '@/utils/constants'
import ComboForm from './components/ComboForm/ComboForm'

const ComboRoute: React.FC = () => {
	return (
		<Switch>
			<Route exact path={`${BASE_ROUTE}/combo`}>
				<ComboForm />
			</Route>
		</Switch>
	)
}

export default ComboRoute
