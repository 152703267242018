import React, { useEffect, useState } from 'react'
import AccountValidationResult from 'domains/enums/account/AccountValidationResult'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { TransactionStore, TransactionEvents } from '@/stores'
import { useStoreMap } from 'effector-react'
import PointsTransferView from './PointsTransferView'
import PocValidationParams from '../../model/PocValidationParams'

type Props = {
	showError: boolean
	onError?: (id: string, message: string) => void
}

const INDETERMINATE_BALANCE = '—'
const POC_ID_TO_FIELD_NAME = 'pocIdTo'
const POC_ID_FROM_FIELD_NAME = 'pocIdFrom'

const PointsTransfer: React.FC<Props> = ({ showError, onError }) => {
	const { t } = useTranslation()
	const { isValid, setFieldError, getFieldMeta } = useFormikContext()
	const [pocIds, setPocIds] = useState<{ from?: string; to?: string }>({})

	const originAccountBalance = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => state.originAccountBalance,
	})

	useEffect(() => {
		TransactionEvents.setOriginAccountBalance(INDETERMINATE_BALANCE)
	}, [])

	useEffect(() => {
		TransactionEvents.setIsSubmitEnabled(isValid)
	}, [isValid])

	useEffect(() => {
		// if both fields are set, build confirmation message
		if (pocIds.from && pocIds.to) {
			TransactionEvents.setConfirmationMessage(t('transaction:POINTS_TRANSFER.CONFIRMATION_MESSAGE', pocIds))
		}
	}, [pocIds, t])

	const clearSameOriginDestinationError = (value: string) => {
		// clean "Same Origin and Destination" error message if pocIdFrom is different from pocIdTo
		const sameOriginDestinationError = t('transaction:POINTS_TRANSFER.VALIDATION.POC.SAME_ORIGIN_DESTINATION')
		if (value !== pocIds.to && getFieldMeta(POC_ID_TO_FIELD_NAME).error === sameOriginDestinationError) {
			setFieldError(POC_ID_TO_FIELD_NAME, '')
		}
	}

	const validateAccount = async ({
		pocId: accountId,
		fieldName,
		balance,
		validationResult,
	}: PocValidationParams): Promise<string> => {
		const isPocIdFrom = fieldName === POC_ID_FROM_FIELD_NAME

		setPocIds((current) => ({ ...current, [isPocIdFrom ? 'from' : 'to']: accountId }))

		if (AccountValidationResult.NO_PERMISSION === validationResult) {
			return t('ERROR_MESSAGE.POC_WITHOUT_PERMISSION')
		}

		if (AccountValidationResult.OK !== validationResult) {
			if (isPocIdFrom) {
				TransactionEvents.setOriginAccountBalance(INDETERMINATE_BALANCE)
			}
			return t(`transaction:POINTS_TRANSFER.VALIDATION.POC.${validationResult}`)
		}

		if (isPocIdFrom) {
			TransactionEvents.setOriginAccountBalance(
				balance === undefined ? INDETERMINATE_BALANCE : t(`transaction:POINTS_TRANSFER.BALANCE_VALUE`, { balance }),
			)
			if (balance! <= 0) {
				return t('transaction:POINTS_TRANSFER.VALIDATION.POC.INSUFFICIENT_BALANCE')
			}
		}

		return ''
	}

	const onAccountFromUpdate = (value: string) => {
		// Ensure the balance is reset if Account From is emptied
		if (!value) {
			TransactionEvents.setOriginAccountBalance(INDETERMINATE_BALANCE)
		}
		clearSameOriginDestinationError(value)
	}

	return (
		<PointsTransferView
			showError={showError}
			originAccountBalance={originAccountBalance}
			onAccountFromUpdate={onAccountFromUpdate}
			onPocValidation={validateAccount}
			onError={onError}
		/>
	)
}

export default PointsTransfer
