import React from 'react'
import { useHistory } from 'react-router-dom'
import ChallengeHistoryDomain from 'domains/ChallengeHistory'
import {
	ChallengeStore as ChallengeCreationStore,
	ChallengeEvents as ChallengeCreationEvents,
} from '@/stores/challengeCreation'
import { BASE_ROUTE } from 'utils/constants'
import { useStoreMap } from 'effector-react'
import AnalyticsService from 'services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import ScreenName from 'domains/analytics/ScreenName'
import ChallengeHistoryView from './ChallengeHistoryView'

const ChallengeHistory: React.FC = () => {
	const history = useHistory()
	const userInfo = useGetUserInfo()

	const pageLevelNotificationRedesign = useStoreMap({
		store: ChallengeCreationStore,
		keys: [],
		fn: (state) => state.pageLevelNotification,
	})

	const onRowView = (challenge: ChallengeHistoryDomain): void => {
		AnalyticsService.events.pageViewed({
			screen_name: `${ScreenName.ChallengeDetails} - ${challenge.title} - ${challenge.executionMethod}`,
			...userInfo,
		})
		history.push(`${BASE_ROUTE}/challenges/${challenge.id}`)
	}

	React.useEffect(() => {
		return () => {
			ChallengeCreationEvents.setSuccessfullySubmitForm(false)
			ChallengeCreationEvents.hidePageLevelNotification()
		}
	}, [])

	return <ChallengeHistoryView pageLevelNotification={pageLevelNotificationRedesign} onRowView={onRowView} />
}

export default ChallengeHistory
