import { GlobalStore } from '@/stores'
import { getUserCountry } from '@/utils/tokenB2CHelper'
import { useFeatureToggleService as featureToggleService, useFeatureToggleVerify } from 'admin-portal-shared-services'
import { useStore } from 'effector-react'
import { useCallback } from 'react'

type VerifyFeatureToggleProps = {
	verifyFeatureToggle: (featureName: string) => boolean
}
export const isToggleEnabled = (featureName: string): boolean => {
	const zone = getUserCountry()
	return featureToggleService().isFeatureEnabled(featureName, zone)
}

export const useVerifyFeatureToggle = (): VerifyFeatureToggleProps => {
	const { selectedZone } = useStore(GlobalStore)
	const { verifyToggleV1 } = useFeatureToggleVerify()

	const verifyFeatureToggle = useCallback(
		(featureName: string) => {
			return verifyToggleV1(featureName, selectedZone!)
		},
		[selectedZone, verifyToggleV1],
	)

	return {
		verifyFeatureToggle,
	}
}

export const useFeatureToggle = (featureName: string): boolean => {
	const { verifyFeatureToggle } = useVerifyFeatureToggle()

	return verifyFeatureToggle(featureName)
}
