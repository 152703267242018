import { ChallengeStatus, EditDialog } from '@/domains/enums'
import { Box, Grid, Typography } from '@material-ui/core'
import { useStoreMap } from 'effector-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ChallengeHistoryDetail from 'routes/challenges/model/new/ChallengeHistoryDetail'
import ChallengeHistoryStore from 'stores/challengeHistory/ChallengeHistoryStore'
import parentStyles from '../ChallengeHistoryDetailStyle'

type Props = {
	toggleEditDialog: (successNotification: boolean, activeEditDialog: EditDialog) => void
	challenge: ChallengeHistoryDetail
}

const DataSectionFilters: React.FC<Props> = ({ toggleEditDialog, challenge }) => {
	const parentClasses = parentStyles()
	const { t } = useTranslation()
	const challengeCancelled = useStoreMap({
		store: ChallengeHistoryStore,
		keys: [],
		fn: (state) => state.challengeCancelled,
	})
	const column1Width = 5
	const column2Width = 7

	const renderEdit = () => {
		const enableEdit = ChallengeStatus.Created === challenge.status || ChallengeStatus.Active === challenge.status
		return (
			<>
				{!challengeCancelled && enableEdit && (
					<Typography
						id="step2-edit-btn"
						data-testid="step2-edit-btn"
						className={parentClasses.editLink}
						onClick={() => toggleEditDialog(false, EditDialog.Filter)}
					>
						{t('EDIT')}
					</Typography>
				)}
			</>
		)
	}

	return (
		<Box className={parentClasses.subContainer}>
			<Grid container>
				<Grid item container xs={5}>
					<Grid item xs={column1Width} className={parentClasses.item}>
						<Typography className={parentClasses.label}>{t('challenge:HISTORY_DETAIL_REDESIGN.START_DATE')}</Typography>
					</Grid>
					<Grid item xs={column2Width}>
						<Typography data-testid="start-date-value-filters">{challenge.startDateAsString}</Typography>
					</Grid>
					<Grid item xs={column1Width} className={parentClasses.item}>
						<Typography className={parentClasses.label}>{t('challenge:HISTORY_DETAIL_REDESIGN.END_DATE')}</Typography>
					</Grid>
					<Grid item xs={column2Width}>
						<Typography data-testid="end-date-value-filters">{challenge.endDateAsString}</Typography>
					</Grid>
					{challenge.individualTarget && (
						<Grid container>
							<Grid item xs={column1Width} className={parentClasses.item}>
								<Typography className={parentClasses.label}>
									{t('challenge:HISTORY_DETAIL_REDESIGN.TARGET_TYPE.TITLE')}
								</Typography>
							</Grid>
							<Grid item xs={column2Width} className={parentClasses.item}>
								<Typography>{t('challenge:HISTORY_DETAIL_REDESIGN.TARGET_TYPE.INDIVIDUAL')}</Typography>
							</Grid>
						</Grid>
					)}
					<Grid item xs={column1Width}>
						<Typography className={parentClasses.label}>
							{challenge.individualTarget
								? t('challenge:HISTORY_DETAIL_REDESIGN.GROUP_NAME')
								: t('challenge:HISTORY_DETAIL_REDESIGN.FILTER_VALUES')}
						</Typography>
					</Grid>
					<Grid item xs={column2Width}>
						<Typography>{challenge.filterValues}</Typography>
					</Grid>
				</Grid>
				<Grid item xs={7}>
					{renderEdit()}
				</Grid>
			</Grid>
		</Box>
	)
}

export default DataSectionFilters
