import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles({
	instruction: {
		padding: '0',
		marginBottom: '16px',
	},
})

export default styles
