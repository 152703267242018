import { orderBy } from 'lodash'
import acceptedCountries, { AcceptedCountry } from './acceptedCountries'
import { removeDuplicates } from './array'

type AllZonesParams = {
	supportedCountries?: Array<string> | string
	userCountry: string
}

const getCountries = (supportedCountries?: Array<string> | string): Array<string> => {
	if (!supportedCountries) {
		return []
	}
	if (typeof supportedCountries === 'string') {
		return supportedCountries.split(',')
	}
	return supportedCountries
}

export const getAvailableCountries = ({ supportedCountries, userCountry }: AllZonesParams): Array<string> => {
	const countries = getCountries(supportedCountries)
	const filteredCountries = [...countries, userCountry].filter(
		(country) => acceptedCountries.indexOf(country as AcceptedCountry) !== -1,
	)

	return removeDuplicates(orderBy(filteredCountries, [(country): string => country.toUpperCase()], ['asc']))
}
