import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		root: {
			'& input::-webkit-outer-spin-button': {
				WebkitAppearance: 'none',
			},
			'& input::-webkit-inner-spin-button': {
				WebkitAppearance: 'none',
			},
			'& input[type=number]': {
				MozAppearance: 'textfield',
			},
		},
	}),
)

export default styles
