import { AcceptableTypes } from '@/components/form'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { RulesEvents } from '@/stores'
import { Upload, AlertOctagon } from '@hexa-ui/icons'
import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

interface UploadFileProps {
	fileName?: string | null
	inputName: string
}

const UploadFile: React.FC<UploadFileProps> = ({ fileName = '', inputName }) => {
	const { t } = useTranslation()
	const useGetInfo = useGetUserInfo()
	const classes = useStyles()
	const {
		register,
		trigger,
		setValue,
		clearErrors,
		formState: { errors },
	} = useFormContext()

	const dispatchTracking = useCallback(
		(file: File | null | undefined) => {
			AnalyticsService.events.fileUploaded({
				file_type: 'CSV',
				file_name: file?.name ?? null,
				form_name: 'Earning Rules - SKUs - Edit - Add - Multiple',
				screen_name: 'Earning Rules - SKUs - Edit - Add - Multiple - Step 3',
				...useGetInfo,
			})
		},
		[useGetInfo],
	)

	const handleSubmitFile = useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			const { files } = event.currentTarget
			const file = files?.[0]

			clearErrors()
			setValue(inputName, files)
			RulesEvents.setSkuUploadFile(files as FileList)

			await trigger('uploadFile')
				.then((res) => RulesEvents.setPropInEditSkuStepData({ skus: res }))
				.catch((err) => RulesEvents.setPropInEditSkuStepData({ skus: !!err }))

			dispatchTracking(file)
		},
		[clearErrors, dispatchTracking, inputName, setValue, trigger],
	)

	return (
		<>
			{errors.uploadFile && (
				<span className={classes.errorMessage} data-testid="error-span">
					<AlertOctagon size="tiny" style={{ color: '#C9201D', marginRight: '4px' }} />
					{errors.uploadFile?.type !== 'generalError' && fileName} {errors.uploadFile?.message}
				</span>
			)}
			<div className={classes.uploadForm}>
				<input
					className={classes.inputFileName}
					disabled
					placeholder={t('common:BUTTON.UPLOAD_CSV_FILE')}
					defaultValue={fileName ?? ''}
				/>
				<label htmlFor="file">
					<div className={classes.uploadIcon}>
						<Upload size="medium" />
					</div>
					{t('common:BUTTON.UPLOAD')}
				</label>
				<input
					data-testid="file-upload-input"
					id="file"
					type="file"
					accept={AcceptableTypes.CSV}
					{...register(inputName)}
					onChange={handleSubmitFile}
				/>
			</div>
		</>
	)
}

export default UploadFile
