import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		boxContainer: {
			alignItems: 'stretch',
		},
		row: {
			display: 'flex',
			'& > div:first-child': {
				flex: 1,
				display: 'flex',
				flexFlow: 'column nowrap',
				justifyContent: 'space-between',
			},
		},
		removeContainer: {
			display: 'flex',
			alignItems: 'flex-end',
			paddingBottom: '25px',
		},
		removeButton: {
			cursor: 'pointer',
			paddingLeft: '16px',
			textDecoration: 'underline',
			fontWeight: 500,
			color: theme.palette.info.main,
			'&:hover': {
				filter: 'brightness(60%)',
			},
		},
		inactiveButton: {
			cursor: 'default',
			paddingLeft: '16px',
			textDecoration: 'underline',
			fontWeight: 500,
			color: theme.palette.text.disabled,
		},
	}),
)

export default styles
