import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsErrorProps {
	failureReason: string
	screenName: string
	formName?: string
}

export const useAnalyticsServiceEventError = (): {
	sendAnalyticsServiceEventError: ({ screenName, failureReason, formName }: AnalyticsErrorProps) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceEventError = useCallback(
		({ screenName, failureReason, formName }: AnalyticsErrorProps) => {
			AnalyticsService.events.error({
				screen_name: screenName,
				failure_reason: failureReason,
				form_name: formName ?? '',
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceEventError }
}
