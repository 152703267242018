import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreMap } from 'effector-react'
import { useFormikContext } from 'formik'
import { AccountValidationResult, TransactionType } from '@/domains/enums'
import { TransactionEvents, TransactionStore } from '@/stores'
import useCategoryForServiceUtilityList from '@/hooks/transaction/useCategoryForServiceUtilityList'
import RemovalForServiceUtilityView from './RemovalForServiceUtilityView'
import useRemoveSuccessMessage from '../../hooks/useRemoveSuccessMessage'
import PocValidationParams from '../../model/PocValidationParams'

type RemovalForServiceUtilityProps = {
	showError: boolean
	onError?: (id: string, message: string) => void
}

const INDETERMINATE_BALANCE = '—'

const ErrorMessages: { [key in AccountValidationResult]: string } = {
	[AccountValidationResult.INVALID]: 'transaction:REMOVAL_FOR_SERVICE_UTILITY.ERROR_MESSAGE.INVALID_POC',
	[AccountValidationResult.NOT_ENROLLED]: 'ERROR_MESSAGE.INVALID_POC_ID',
	[AccountValidationResult.NO_PERMISSION]: 'ERROR_MESSAGE.NO_PERMISSION',
	[AccountValidationResult.OK]: '',
}

type RemovalForServiceUtilityFormData = {
	pocId: string
	points: string | null
	currentPoints: number | null
	transactionType: TransactionType
}

const RemovalForServiceUtility: React.FC<RemovalForServiceUtilityProps> = ({ showError, onError }) => {
	const removeSuccessMessage = useRemoveSuccessMessage()
	const { t } = useTranslation()
	const { setFieldValue, values, isValid, getFieldMeta } = useFormikContext<RemovalForServiceUtilityFormData>()
	const AuthorizedCategoriesForServicesList = useCategoryForServiceUtilityList()

	const { newPointsBalance, currentPointsBalance } = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => ({
			currentPointsBalance: state.removalForServiceUtility.currentPointsBalance || INDETERMINATE_BALANCE,
			newPointsBalance: state.removalForServiceUtility.newPointsBalance || INDETERMINATE_BALANCE,
		}),
	})

	useEffect(() => {
		const points = parseInt(currentPointsBalance, 10)
		const currentPoints = !Number.isNaN(points) ? points : null
		if (currentPoints) setFieldValue('currentPoints', currentPoints)
	}, [currentPointsBalance, setFieldValue])

	const handleValidateRemovePoints = useCallback(
		async (points: string | null) => {
			if (!points) return
			const pointsToRemove = parseInt(points, 10)

			if (!pointsToRemove || pointsToRemove <= 0) {
				TransactionEvents.setRemovalForServiceUtility({
					newPointsBalance: INDETERMINATE_BALANCE,
				})
				return
			}

			const currentPoints = parseInt(currentPointsBalance, 10)
			if (Number.isNaN(currentPoints)) {
				TransactionEvents.setRemovalForServiceUtility({
					newPointsBalance: INDETERMINATE_BALANCE,
				})
				return
			}

			const newPoints = currentPoints - pointsToRemove
			TransactionEvents.setRemovalForServiceUtility({
				newPointsBalance: newPoints >= 0 ? newPoints.toString() : INDETERMINATE_BALANCE,
			})
		},
		[currentPointsBalance],
	)

	useEffect(() => {
		const pocIdField = getFieldMeta('pocId')
		if (!pocIdField.error) handleValidateRemovePoints(values.points)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.points, currentPointsBalance])

	useEffect(() => {
		if (isValid)
			TransactionEvents.setConfirmationMessage(
				t('transaction:REMOVAL_FOR_SERVICE_UTILITY.CONFIRMATION_MESSAGE', {
					points: values.points,
				}),
			)
	}, [isValid, t, values])

	const handleValidatePoc = async ({ pocId, balance, validationResult }: PocValidationParams): Promise<string> => {
		if (pocId.length === 1 && removeSuccessMessage) {
			removeSuccessMessage()
		}

		if (AccountValidationResult.OK !== validationResult) {
			TransactionEvents.setRemovalForServiceUtility({
				currentPointsBalance: !balance ? INDETERMINATE_BALANCE : balance.toString(),
				newPointsBalance: INDETERMINATE_BALANCE,
			})
			const error = ErrorMessages[validationResult]
			if (!error) {
				return t('ERROR_MESSAGE.INVALID_POINTS_VALUE')
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return t(error as any)
		}

		if (!balance || balance <= 0) {
			TransactionEvents.setRemovalForServiceUtility({
				currentPointsBalance: !balance ? '0' : balance.toString(),
				newPointsBalance: INDETERMINATE_BALANCE,
			})
			return balance === 0
				? t('transaction:REMOVAL_FOR_SERVICE_UTILITY.ERROR_MESSAGE.POC_BALANCE_INSUFFICIENT')
				: t('transaction:REMOVAL_FOR_SERVICE_UTILITY.ERROR_MESSAGE.POC_BALANCE_NEGATIVE')
		}

		TransactionEvents.setRemovalForServiceUtility({
			currentPointsBalance: balance.toString(),
			newPointsBalance: INDETERMINATE_BALANCE,
		})
		return ''
	}
	return (
		<RemovalForServiceUtilityView
			showError={showError}
			onPocValidation={handleValidatePoc}
			currentPointsBalance={currentPointsBalance}
			newPointsBalance={newPointsBalance}
			removeSuccessMessage={removeSuccessMessage}
			categoryOptions={AuthorizedCategoriesForServicesList}
			onError={onError}
		/>
	)
}

export default RemovalForServiceUtility
