import { createStyles, makeStyles } from '@material-ui/core/styles'

const earningSkuListStyle = makeStyles(() =>
	createStyles({
		skuListCard: {
			margin: '24px auto',
			width: '100%',
			maxWidth: '864px',
			display: 'flex',
			flexDirection: 'column',
		},
		notFound: {
			background: '#F0ECFC',
			marginTop: '24px',
		},
		skuNotFound: {
			lineHeight: '16px',
			fontWeight: 400,
		},
	}),
)

export default earningSkuListStyle
