import React from 'react'
import TransactionLogView from './TransactionLogView'

const TransactionLog = () => {
	return (
		<div data-testid="transaction-log">
			<TransactionLogView />
		</div>
	)
}

export default TransactionLog
