import React, { useState, useEffect } from 'react'
import { Typography, Grid, Button, Box } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import ChallengeHistory from '@/domains/ChallengeHistory'
import { EditDialog } from '@/domains/enums'
import { challengeHistoryToChallengeHistoryDetail } from '@/converters'
import { Paper, BreadCrumbLink, Tooltip } from '@/components/core'
import { OnSaveDialogFunction } from '@/routes/challenges/model/EditChallengeDialog'
import ChallengeHistoryDetail from '@/routes/challenges/model/new/ChallengeHistoryDetail'
import { RewardsProgram } from '@/domains'
import * as FileHelper from '@/utils/fileHelper'
import * as DateHelper from '@/utils/date'
import dayjs from 'dayjs'

import styles from './ChallengeHistoryDetailStyle'
import Setup from './DataSectionSetup'
import Filters from './DataSectionFilters'
import Details from './DataSectionDetails'
import EditChallengeDialog from './EditChallengeDialog/EditChallengeDialog'
import DataSectionHeader from './DataSectionHeader'

export type AcceptedPocItem = Pick<RewardsProgram, 'accountId'>

type Props = {
	challengeHistory: ChallengeHistory | null
	isLoading: boolean
	toggleEditDialog: (successNotification: boolean, activeEditDialog: EditDialog) => void
	onSaveDialog: OnSaveDialogFunction
	onBreadcrumbBack: () => void
	acceptedPocs: AcceptedPocItem[]
}

const ChallengeHistoryDetailView: React.FC<Props> = ({
	challengeHistory,
	isLoading,
	toggleEditDialog,
	onSaveDialog,
	onBreadcrumbBack,
	acceptedPocs,
}: Props) => {
	const classes = styles()
	const { t } = useTranslation()

	const [shouldDisableAcceptedPocsCsv, setShouldDisableAcceptedPocsCsv] = useState(true)

	let challenge: ChallengeHistoryDetail | null = null
	let challengeFound = false
	if (challengeHistory) {
		challengeFound = true
		challenge = challengeHistoryToChallengeHistoryDetail(challengeHistory)
	}

	const handleDownloadAcceptedPocsCsv = () => {
		const fileCsvString = FileHelper.unParse(acceptedPocs, ['accountId'], ['poc_id'])
		const fileDate = dayjs().utcOffset(0, true).toDate()
		const dateNowString = DateHelper.formatDate(fileDate, t('FORMAT_DATE.LONG_FILE'))
		FileHelper.saveAs(fileCsvString, `${challenge!.id}-${dateNowString}.csv`, { type: FileHelper.TEXT_CSV_TYPE })
	}

	useEffect(() => {
		setShouldDisableAcceptedPocsCsv(acceptedPocs.length <= 0)
	}, [acceptedPocs])
	return (
		<>
			<EditChallengeDialog onSaveDialog={onSaveDialog} />
			<div className={classes.container} id="challenge-history-detail">
				<BreadCrumbLink displayName={t('challenge:HISTORY.TITLE_VIEW')} onBack={onBreadcrumbBack} />
				{!isLoading && challengeFound && challenge && (
					<>
						<Grid container justifyContent="center" className={classes.dataContainer}>
							<Grid item xs={12}>
								<Grid container style={{ marginBottom: '24px' }}>
									<Grid item xs={6}>
										<Typography id="title-value" variant="h2">
											{t('challenge:HISTORY.DATA_TITLE')}
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-end">
											<Tooltip
												title={t('challenge:HISTORY_DETAIL_REDESIGN.DOWNLOAD_BUTTON_TOOLTIP')}
												maxWidth="104px"
												displayWhenDisabled
											>
												<Button
													type="button"
													variant="contained"
													color="primary"
													data-testid="accepted-pocs-download"
													onClick={handleDownloadAcceptedPocsCsv}
													disabled={shouldDisableAcceptedPocsCsv}
												>
													{t('BUTTON.DOWNLOAD')}
												</Button>
											</Tooltip>
										</Box>
									</Grid>
								</Grid>
								<Paper animate={false} className={classes.paper}>
									<Grid container>
										<Grid item xs={4}>
											<Typography variant="h5">{t('challenge:HISTORY.POCS_ACCEPTED')}</Typography>
										</Grid>
										<Grid item xs={8}>
											<Typography data-testid="accepted-pocs-length">{acceptedPocs.length}</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
						<Grid container justifyContent="center" className={classes.dataContainer}>
							<Grid item xs={12}>
								<DataSectionHeader challenge={challenge} />
								<Paper animate={false} className={classes.paper}>
									<Setup challenge={challenge} toggleEditDialog={toggleEditDialog} />
									<Filters challenge={challenge} toggleEditDialog={toggleEditDialog} />
									<Details
										challenge={challenge}
										toggleEditDialog={toggleEditDialog}
										challengeHistory={challengeHistory}
									/>
								</Paper>
							</Grid>
						</Grid>
					</>
				)}
				{!isLoading && !challengeFound && (
					<Grid container justifyContent="center">
						<Grid item sm>
							<Paper animate={false} className={classes.notFound}>
								<Typography id="title-value" variant="h6" className={classes.challengeNotFound}>
									{t('ERROR_MESSAGE.NO_CHALLENGE_FOUND_BY_ID')}
								</Typography>
							</Paper>
						</Grid>
					</Grid>
				)}
			</div>
		</>
	)
}

export default ChallengeHistoryDetailView
