import { EditSKUStepData } from '@/domains/sku/SkuStep'
import Rule from 'domains/rule/Rule'
import SkuForm from 'domains/sku/SkuForm'

interface RulesState {
	programId: string | null
	rules: Rule[] | null
	ruleId: string
	categoryId: string
	sku: SkuForm | null
	showChangeRuleDialog: boolean
	showRemoveSkuDialog: boolean
	showAddSkuRuleDialog: boolean
	rowIndexToDelete?: number
	activeSkuList: Array<SkuForm>
	editSkuStepData: EditSKUStepData
	skuUploadFile?: FileList
	singleSku: SkuForm
}

export default RulesState

export const rulesInitialState: RulesState = {
	programId: null,
	rules: null,
	ruleId: '',
	categoryId: '',
	sku: null,
	showChangeRuleDialog: false,
	showAddSkuRuleDialog: false,
	showRemoveSkuDialog: false,
	rowIndexToDelete: undefined,
	activeSkuList: [],
	editSkuStepData: {},
	skuUploadFile: undefined,
	singleSku: {} as SkuForm,
}
