import React from 'react'
import * as Yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { fieldNumberValidationRequiredMin1AndInteger } from '@/utils/validationObjects'
import { CreateRuleFormData } from '@/domains/rule/Rule'

interface CreateRuleProviderProps {
	children: React.ReactNode
}

const CreateRuleProvider: React.FC<CreateRuleProviderProps> = ({ children }) => {
	const formSchema = useForm<CreateRuleFormData>({
		mode: 'onChange',
		defaultValues: {
			points: undefined,
			amountSpent: undefined,
			categoryId: undefined,
		},
		resolver: yupResolver(
			Yup.object().shape({
				points: fieldNumberValidationRequiredMin1AndInteger(),
				amountSpent: fieldNumberValidationRequiredMin1AndInteger(),
				categoryId: Yup.string().required('CategoryId is required'),
			}),
		),
	})
	return <FormProvider {...formSchema}>{children}</FormProvider>
}
export default CreateRuleProvider
