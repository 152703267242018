import React from 'react'
import { Box } from '@material-ui/core'
import { Switch } from '@/components/form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { isRewardsProgramEnabled } from '@/utils/rewardsProgram'
import { RewardsProgram } from '@/domains'
import useStyles from './styles'

export type SwitchActionProps = {
	row: RewardsProgram
	rowIndex: number
	onChange: () => void
}

const SwitchAction: React.FC<SwitchActionProps> = ({ row: rowData, rowIndex, onChange }: SwitchActionProps) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const isChecked: boolean = isRewardsProgramEnabled(rowData)

	return (
		<Box
			display="flex"
			alignContent="center"
			alignItems="center"
			justifyContent="space-between"
			flexDirection="row"
			flexWrap="nowrap"
			style={{
				maxWidth: '131px',
			}}
		>
			<label
				data-testid={`switch-off-${rowIndex}`}
				htmlFor={`switch-${rowIndex}`}
				className={clsx({
					[classes.blackColorText]: !isChecked,
					[classes.greyColorText]: isChecked,
				})}
			>
				{t('permission:LIST.SWITCH_OFF_LABEL')}
			</label>
			<Switch id={`switch-${rowIndex}`} checked={isChecked} onChange={onChange} />
			<label
				htmlFor={`switch-${rowIndex}`}
				className={clsx({
					[classes.blackColorText]: isChecked,
					[classes.greyColorText]: !isChecked,
				})}
			>
				{t('permission:LIST.SWITCH_ON_LABEL')}
			</label>
		</Box>
	)
}

export default SwitchAction
