import { useAuditLog } from 'admin-portal-shared-services'
import { getUserCountry } from '@/utils/tokenB2CHelper'

export enum IAuditEntities {
	ACCOUNTS = 'ACCOUNTS',
	CATEGORIES = 'CATEGORIES',
	REWARDS = 'REWARDS',
	CHALLENGES = 'CHALLENGES',
	POINTS = 'POINTS',
	EARNING_RULES = 'EARNING_RULES',
	ELIGIBILITY = 'ELIGIBILITY',
	REDEEMABLES = 'REDEEMABLES',
	CONFIGURATION = 'CONFIGURATION',
}

export enum IAuditOperations {
	DELETE = 'DELETE',
	INSERT = 'INSERT',
	UPDATE = 'UPDATE',
	VIEW = 'VIEW',
}

export interface IAuditLog {
	metadata: { [key: string]: string }
	operation: string
	entity: string
	entityId: string
	country?: string
}

const useAdminAuditLog = () => {
	const auditLog = useAuditLog('MEMBERSHIP')

	const auditLogInstance = (props: IAuditLog) => {
		const country = getUserCountry()

		return auditLog({
			...props,
			country,
		})
	}

	return { auditLogInstance }
}

export default useAdminAuditLog
