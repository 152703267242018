import React from 'react'
import { SelectOptions } from '@/components/form'
import FilterBoxView from './FilterBoxView'

export type FilterBoxProps = {
	index: number
	filterOptions: SelectOptions[]
	onRemoveLinkClick: (index: number) => void
	defaultValue: { filter: string; value: string[] }
	onFilterSelected: () => void
	disabled?: boolean
	isActiveChallenge?: boolean
	isFilterChangeDisabled?: boolean
}

const FilterBox: React.FC<FilterBoxProps> = ({
	index,
	filterOptions,
	onRemoveLinkClick,
	defaultValue,
	onFilterSelected,
	disabled = false,
	isActiveChallenge = false,
	isFilterChangeDisabled,
}) => {
	return (
		<FilterBoxView
			index={index}
			filterOptions={filterOptions}
			onRemoveLinkClick={onRemoveLinkClick}
			defaultValue={defaultValue}
			onFilterSelected={onFilterSelected}
			disabled={disabled}
			isActiveChallenge={isActiveChallenge}
			isFilterChangeDisabled={isFilterChangeDisabled}
		/>
	)
}

export default React.memo(FilterBox)
