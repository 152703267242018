import { countryConfig } from '@/utils/constants'
import { formatCurrency } from '@/utils/formatCurrency'
import { formatNumber } from '@/utils/formatNumber'
import { isNonValue } from '@/utils/object'
import { getUserCountry } from '@/utils/tokenB2CHelper'
import { useMemo } from 'react'

/**
 * Use this to format decimal numbers or currency by current locale.
 * @param value Number value to be formatted.
 * @param asCurrency Indicates this should be formatted as currency, i.e.: $1,000.00.
 * @param fallback String to be returned when value (or locale) is invalid and could not be formatted. Default ""
 * @returns formatted string of the number value.
 */
export const useFormatNumber = (value?: number, asCurrency?: boolean, fallback = ''): string => {
	const countryCode = getUserCountry()
	const { locale } = countryConfig[countryCode] ?? countryConfig.fallback

	return useMemo(() => {
		if (Number.isNaN(value) || isNonValue(value) || typeof value !== 'number') {
			return fallback
		}

		return asCurrency ? formatCurrency(value, locale) : formatNumber(value, locale)
	}, [value, asCurrency, locale, fallback])
}

/**
 * Shortcut for the useFormatNumber(value, asCurrency: true)
 * @param value Number value to be formatted.
 * @param fallback String to be returned when value (or locale) is invalid and could not be formatted. Default "-"
 * @returns formatted string of the number value.
 */
export const useFormatCurrency = (value?: number, fallback = '-'): string => useFormatNumber(value, true, fallback)
