import axios from '@/utils/axiosInstance'
import { endPointBase } from './Endpoints';

export async function load(
  accountId?: string,
  orderId?: string,
  pagination?: { page?: number; pageSize?: number }
) {
  try {
    const axiosResponse = await axios.get(endPointBase)

    if (axiosResponse.status !== 200) {
      console.error(`HTTP error! status: ${axiosResponse.status}`);
      return [];
    }

    return axiosResponse.data.content;
  } catch (error: any) {
    console.error(`Error loading Transactions: ${error.message}`);

    if (error.status === 404) {
      console.error(`Error endpoint: ${endPointBase} ${error.status}`);
      return [];
    }

    throw error;
  }
}
