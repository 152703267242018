import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		formcontrol: {
			marginTop: '0px',
			'& > p.Mui-error': {
				display: 'block',
			},
		},
	}),
)

export default styles
