import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { BASE_ROUTE } from 'utils/constants'
import CombosList from './components/CombosList/CombosList'

const CombosRoute: React.FC = () => {
	return (
		<Switch>
			<Route exact path={`${BASE_ROUTE}/combos`}>
				<CombosList />
			</Route>
		</Switch>
	)
}

export default CombosRoute
