import ModalRedesign from '@/components/templates/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyle from './styles'

interface Props {
	isOpen?: boolean
	buttonActions: {
		cancel: () => void
		proceed?: () => void
	}
}

const MissingInfoModal = ({ isOpen = false, buttonActions }: Props) => {
	const { t } = useTranslation()
	const classes = useStyle()

	const modalInstructions = t('challenge:MULTI_LANGUAGE.MISSING_INFO.INSTRUCTIONS', {
		returnObjects: true,
	})

	return (
		<ModalRedesign
			isOpen={isOpen}
			buttonActions={buttonActions}
			title={t('challenge:MULTI_LANGUAGE.MISSING_INFO.TITLE')}
			buttonLabels={{
				cancel: t('common:BUTTON.GOT_IT'),
			}}
		>
			<div className={classes.container}>
				<p role="paragraph">{modalInstructions[0]}</p>
				<p role="paragraph">{modalInstructions[1]}</p>
				<p role="paragraph">{modalInstructions[2]}</p>
				<p role="paragraph">{modalInstructions[3]}</p>
				<p role="paragraph">{modalInstructions[4]}</p>
			</div>
		</ModalRedesign>
	)
}

export default MissingInfoModal
