import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const stepsHeaderStyle = makeStyles((theme: Theme) =>
	createStyles({
		tabs: {
			height: '60px',
		},
		number: {
			marginRight: theme.spacing(2),
		},
		title: {
			whiteSpace: 'nowrap',
			lineHeight: '24px',
		},
		items: {
			width: '44px',
			textAlign: 'right',
		},
		circleIconContainer: {
			height: '24px',
			marginRight: theme.spacing(2),
		},
		icon: {
			fontSize: '28.8px',
			marginTop: '-2px',
			color: theme.palette.common.white,
		},
		stepper: {
			marginBottom: '24px',
		},
	}),
)

export default stepsHeaderStyle
