import React, { ReactNode } from 'react'
import { DialogActions, DialogContent, DialogProps } from '@material-ui/core'
import { Button, Dialog, Heading } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import useStyle from './stylesredesign'
import { CancelButtonProps, SubmitButtonProps } from './model/FormDialogOptions'
import SubmitButtonTooltip from './Components/SubmitButtonTooltip'

export type DialogFormProps = {
	showDialog: boolean
	title: string
	cancelButtonProps: CancelButtonProps
	submitButtonProps: SubmitButtonProps
	children: ReactNode
	handleSubmit?: () => void
	dataTestIdBase?: string
} & Pick<DialogProps, 'transitionDuration'>

const RedesignFormDialogTemplate: React.FC<DialogFormProps> = ({
	showDialog,
	title,
	cancelButtonProps,
	submitButtonProps,
	children,
	handleSubmit,
	dataTestIdBase,
}: DialogFormProps) => {
	const { t } = useTranslation()
	const classes = useStyle()

	return (
		<div className={classes.modalAlertWrapper} data-testid="ppp-editor-wrapper">
			<Dialog.Root
				open={showDialog || false}
				onClose={() => cancelButtonProps.handleClick(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				data-testid={dataTestIdBase ? `${dataTestIdBase}-presentation` : undefined}
			>
				<div>
					<Heading size="H2">{title}</Heading>
					<form onSubmit={handleSubmit}>
						<DialogContent className={classes.dialogContent}>{children}</DialogContent>
						<DialogActions className={classes.dialogAction}>
							<Button
								id="cancel-btn"
								data-testid={dataTestIdBase ? `${dataTestIdBase}-cancel-button` : undefined}
								onClick={() => cancelButtonProps.handleClick(true)}
								variant="secondary"
								color="primary"
							>
								{cancelButtonProps.name ?? t('BUTTON.CANCEL')}
							</Button>
							<SubmitButtonTooltip title={submitButtonProps.tooltipTitle} disabled={!submitButtonProps.disabled}>
								<Button
									disabled={submitButtonProps.disabled}
									type={submitButtonProps.type ?? 'submit'}
									data-testid={dataTestIdBase ? `${dataTestIdBase}-confirm-button` : undefined}
									id="save-btn"
									variant="primary"
									color="primary"
									autoFocus={!submitButtonProps.disabledAutoFocus}
									onClick={submitButtonProps.handleClick}
									className={classes.submitButton}
								>
									{submitButtonProps.name ?? t('BUTTON.SAVE')}
								</Button>
							</SubmitButtonTooltip>
						</DialogActions>
					</form>
				</div>
			</Dialog.Root>
		</div>
	)
}

export default RedesignFormDialogTemplate
