/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		root: {
			lineHeight: '20px',
			display: 'table',
			pointerEvents: 'none',
		},
		checkbox: {
			display: 'table-cell',
			paddingRight: '8px',
			width: '24px',
			height: '24px',
			pointerEvents: (props: { disabled?: boolean; checkBoxVisible?: boolean }) => (props.disabled ? 'none' : 'auto'),
			visibility: (props: { disabled?: boolean; checkBoxVisible?: boolean }) =>
				props.checkBoxVisible ? 'hidden' : 'visible',
		},
		label: {
			lineHeight: '20px',
		},
		inputRoot: {
			padding: '0px',
		},
	}),
)

export default styles
