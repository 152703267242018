import React from 'react'
import { useTranslation } from 'react-i18next'
import { ChallengeDetailsFormData, ChallengeEvents } from '@/stores/challengeCreation'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, FormLabel } from '@material-ui/core'
import HexaRadioGroup from '@/components/hexa-form/RadioGroup/HexaRadioGroup'

import { ChallengeExecutionMethod } from '@/domains/enums'
import styles from '../ChallengeFormDetailsStyle'

const ChallengePurchaseType: React.FC = () => {
	const { t } = useTranslation()
	const classes = styles()
	const { control, getValues, setValue, trigger, clearErrors } = useFormContext<ChallengeDetailsFormData>()
	const selectedType = getValues()?.purchaseType

	const radioItems = [
		{
			id: 'single-purchase',
			label: t('challenge:DETAILS_STEP.PURCHASE_TYPE.SINGLE_PURCHASE'),
			sublabel: t('challenge:DETAILS_STEP.PURCHASE_TYPE.SINGLE_PURCHASE_SUBLABEL'),
			value: 'SinglePurchase',
			checked: selectedType === 'SinglePurchase',
		},
		{
			id: 'multiple-purchases',
			label: t('challenge:DETAILS_STEP.PURCHASE_TYPE.MULTIPLE_PURCHASES'),
			sublabel: t('challenge:DETAILS_STEP.PURCHASE_TYPE.MULTIPLE_PURCHASES_SUBLABEL'),
			value: 'MultiplePurchases',
			checked: selectedType === 'MultiplePurchases',
		},
	]

	const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const isSinglePurchase = event.target.value === 'SinglePurchase'
		if (isSinglePurchase) {
			ChallengeEvents.setExecutionMethod(ChallengeExecutionMethod.SingleVolumeFixed)
			setValue('singlePurchase', true)
			clearErrors('singlePurchase')
		} else {
			ChallengeEvents.setExecutionMethod(ChallengeExecutionMethod.VolumeFixed)
			setValue('singlePurchase', false)
			clearErrors('singlePurchase')
		}
		setValue('purchaseType', event.target.value)
		await trigger('purchaseType')
	}

	return (
		<Controller
			control={control}
			name="purchaseType"
			render={() => (
				<FormControl style={{ marginTop: '24px', width: '100%', display: 'flex', flexDirection: 'column' }}>
					<FormLabel id="challenge-purchase-radio-buttons-group-label">
						<div className={classes.label}>{t('challenge:DETAILS_STEP.PURCHASE_TYPE.TITLE')}</div>
					</FormLabel>
					<HexaRadioGroup radioItems={radioItems} handleRadioChange={handleRadioChange} flexDisplay />
					{!!selectedType}
				</FormControl>
			)}
		/>
	)
}

export default ChallengePurchaseType
