import { Download } from '@admin-portal-shared-components/icons'
import React from 'react'
import useStyles from './styles'

interface IDownloaderFileProps {
	url: string
	label: string
	onClick?: () => void
}

const DownloaderFile = ({ url, label, onClick }: IDownloaderFileProps) => {
	const skuClasses = useStyles()
	return (
		<button type="button" className={skuClasses.downloadCSV} onClick={onClick} data-testid="downloader">
			<Download size="medium" className={skuClasses.downloadIcon} />
			<a href={url} download>
				{' '}
				{label}{' '}
			</a>
		</button>
	)
}

export default DownloaderFile
