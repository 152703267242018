import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import GlobalStore from 'stores/global/GlobalStore'
import { useStore } from 'effector-react'
import useStyle from './styles'

const BackDropLoad: React.FC = () => {
	const classes = useStyle()
	const globalState = useStore(GlobalStore)

	if (!globalState.isLoading) {
		return <></>
	}

	return (
		<Backdrop data-testid="backdrop-load" open={globalState.isLoading} className={classes.BackDrop}>
			<CircularProgress color="inherit" />
		</Backdrop>
	)
}
export default React.memo(BackDropLoad)
