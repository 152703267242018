import ModalRedesign from '@/components/templates/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	isOpen: boolean
	yesNoActions?: boolean
	buttonActions: {
		proceed?: () => void
		cancel: () => void
	}
}

const UnfilledOptionalFormModal: React.FC<Props> = ({ isOpen, buttonActions, yesNoActions }) => {
	const { t } = useTranslation()

	return (
		<ModalRedesign
			isOpen={isOpen}
			buttonActions={buttonActions}
			yesNoActions={yesNoActions}
			headerHeight="120px"
			buttonLabels={{
				cancel: t('common:BUTTON.GO_BACK'),
				proceed: t('common:BUTTON.PROCEED'),
			}}
			title={t('challenge:MULTI_LANGUAGE.UNFILLED_FORM.TITLE')}
		>
			<p>{t('challenge:MULTI_LANGUAGE.UNFILLED_FORM.INSTRUCTIONS')}</p>
		</ModalRedesign>
	)
}

export default UnfilledOptionalFormModal
