import { createEvent } from 'effector'
import Program from 'domains/program/Program'
import ProgramCombo from 'domains/program/ProgramCombo'
import { ComboFormType } from '@/domains/enums'
import { Redeemable } from '@/domains'
import { ComboFormData, ComboFormDataMultiple, CombosData, RedeemableEditPoints } from './ComboState'

export const setProgramDataForCombo = createEvent<Program | null>('setProgramData')
export const setShowEditComboDialog = createEvent<boolean>('setShowEditComboDialog')
export const setShowRemoveComboDialog = createEvent<boolean>('setShowRemoveComboDialog')
export const setActiveCombo = createEvent<{ activeCombo?: ProgramCombo; rowIndexToDelete?: number }>('setActiveCombo')
export const setActiveRedeemable =
	createEvent<{ activeRedeemable?: Redeemable[]; rowIndexToDelete?: number[] }>('setActiveRedeemable')
export const setComboPoints = createEvent<{ comboId: string; comboPoints: number }>('setComboPoints')
export const setRedeemablePoints = createEvent<RedeemableEditPoints[]>('setRedeemablePoints')
export const setShowEditComboQuantityDialog = createEvent<boolean>('setShowEditComboQuantityDialog')
export const setIsValidatingComboId = createEvent<boolean>('setIsValidatingComboId')
export const setFormType = createEvent<ComboFormType | undefined>('setFormType')
export const setShowSubmitDialog = createEvent<boolean>('setShowSubmitDialog')
export const setFormData = createEvent<ComboFormData | null>('setFormData')
export const setCombos = createEvent<CombosData>('setCombos')
export const setIsValidatingFile = createEvent<boolean>('setIsValidatingFile')
export const setFileValidationError = createEvent<string | null>('setFileValidationError')
export const selectTransparencyId = createEvent<string | null>('selectTransparencyId')
export const setFormDataMultiple = createEvent<ComboFormDataMultiple | null>('setFormDataMultiple')
export const setIsLoading = createEvent<boolean>('setIsLoading')
export const setCombosPageReady = createEvent<boolean>('setCombosPageReady')
export const showErrorInCombosData = createEvent<void>('showErrorInCombosData')
export const resetForCombo = createEvent('reset')
