import theme from '@/theme'
import { makeStyles } from '@material-ui/styles'

const styles = makeStyles({
	downloadCSV: {
		all: 'unset',
		display: 'flex',
		alignItems: 'center',
		height: '24px',
		color: theme.palette.common.black,
		fontFamily: 'Work Sans',
		fontSize: '16px',
		fontWeight: 500,
		letterSpacing: 0,
		lineHeight: '24px',
		margin: '31px 0px 40px 0px',
		'& a': {
			textDecoration: 'none',
			color: theme.palette.common.black,
		},
	},
	downloadIcon: {
		color: '#0F558A',
		marginRight: '10px',
	},
})

export default styles
