import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { AutoCompleteOptions, InputAutoComplete } from '@/components/form'

type InputGroupViewProps = {
	index: number
	defaultValue: string[]
	options: AutoCompleteOptions[]
	isLoading: boolean
	className: string
	onChange: (_event: React.ChangeEvent<unknown>, selectedOptions: AutoCompleteOptions[]) => void
	onAddGroupClick: () => void
	disabled: boolean
	fixedValues?: string[]
}

const InputGroupView: React.FC<InputGroupViewProps> = ({
	index,
	defaultValue,
	options,
	isLoading,
	className,
	onChange,
	onAddGroupClick,
	disabled,
	fixedValues,
}) => {
	const { t } = useTranslation()
	const { control } = useFormContext()

	const getSelectedOptions = (selectedValues: Array<string>): Array<AutoCompleteOptions> => {
		return options.filter((option) => {
			return selectedValues?.includes(option.value as string)
		})
	}

	return (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		<Controller<any>
			name={`filters.${index}.value` as const}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { value, name, onBlur } }) => (
				<InputAutoComplete
					id={`filter-values-group-${index}`}
					name={name}
					label={t('challenge:FILTERS_STEP.FILTER_VALUES')}
					helperText={t('challenge:FILTERS_STEP.FILTER_INSTRUCTION')}
					options={options}
					isLoading={isLoading}
					className={className}
					disabled={disabled}
					fixedValues={fixedValues}
					action={{
						handleSelect: onAddGroupClick,
						label: t('challenge:FILTERS_STEP.GROUP.ADD_PLUS_NEW_GROUP'),
						position: 'first',
					}}
					value={getSelectedOptions(value)}
					onChange={onChange}
					disableClearable
					onBlur={onBlur}
				/>
			)}
		/>
	)
}

export default InputGroupView
