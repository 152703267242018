/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { TransactionType } from '@/domains/enums'
import { IAuditEntities, IAuditLog, IAuditOperations } from '@/hooks/useAdminAuditLog'
import { isToggleRefundRedeemableItemsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { FormikValues } from 'formik'
import { AxiosResponse } from 'axios'
import { GlobalUseCase } from '@/usecase'
import { normalizeCombosForAuditLog } from './utils/normalizeComboForAuditLog'
import { chunkArray, sendCsvChunks } from './utils/rewardOfferChunkUtils'

export const generateLogPointsRemoval = (formValues: Record<string, string | undefined>): IAuditLog => {
	return {
		metadata: {
			description: formValues.description!,
			originalPocId: formValues.pocId!,
			pointsAdjustment: formValues.points!,
			transactionType: TransactionType.PointsRemoval,
		},
		operation: IAuditOperations.INSERT,
		entity: IAuditEntities.POINTS,
		entityId: formValues.pocId!,
	}
}

export const generateLogPointsTransfer = (formValues: Record<string, string | undefined>): IAuditLog => {
	return {
		metadata: {
			description: formValues.reason!,
			originalPocId: formValues.pocIdFrom!,
			targetPocId: formValues.pocIdTo!,
			pointsAdjustment: formValues.points!,
			transactionType: TransactionType.PointsTransferFrom,
		},
		operation: IAuditOperations.INSERT,
		entity: IAuditEntities.POINTS,
		entityId: formValues.pocIdFrom!,
	}
}

export const generateLogRewardsOffer = async (
	params: { [key: string]: string },
	pocs?: string,
	auditLogInstance?: (props: IAuditLog, pocs?: string) => Promise<AxiosResponse<any, any>>,
): Promise<void> => {
	try {
		GlobalUseCase.load()
		const paramsClone = { ...params }

		let entityId: string

		if (pocs) {
			entityId = pocs
		} else {
			entityId = paramsClone.originalPocId
		}

		const rewardsOfferLog: IAuditLog = {
			metadata: {
				...paramsClone,
				transactionType: TransactionType.RewardsOffer,
			},
			operation: IAuditOperations.INSERT,
			entity: IAuditEntities.POINTS,
			entityId,
		}

		const rowsEntity = paramsClone.pocLists ? JSON.parse(paramsClone.pocLists) : []

		if (rowsEntity.length > 0) {
			const MAX_CHUNK_SIZE = 5000
			const chunks = chunkArray(rowsEntity, MAX_CHUNK_SIZE, rewardsOfferLog)
			await sendCsvChunks(chunks, rewardsOfferLog, auditLogInstance!)
		} else {
			await auditLogInstance!({ ...rewardsOfferLog, entityId })
		}
	} catch (error) {
		throw new Error(`Error in generateLogRewardsOffer: ${error}`)
	} finally {
		delete params.campaignId
		delete params.entityId
	}
	GlobalUseCase.loadDone()
}

export const generateLogServiceUtility = (formValues: Record<string, string | undefined>): IAuditLog => {
	return {
		metadata: {
			description: formValues.description!,
			originalPocId: formValues.pocId!,
			pointsAdjustment: formValues.points!,
			transactionCategory: formValues.category!,
			transactionType: TransactionType.ServiceUtility,
		},
		operation: IAuditOperations.INSERT,
		entity: IAuditEntities.POINTS,
		entityId: formValues.pocId!,
	}
}

export const generateLogRefund = (formValues: FormikValues): IAuditLog => {
	const redeemablesValues = isToggleRefundRedeemableItemsEnabled() ? formValues.redeemables : formValues.combos
	const { combos, pointsAdjustment } = normalizeCombosForAuditLog(redeemablesValues)

	return {
		metadata: {
			description: formValues.description,
			combos,
			order_date: formValues.orderDate.toString(),
			orderId: formValues.orderId,
			order_status: formValues.orderStatus,
			originalPocId: formValues.accountId,
			pointsAdjustment,
			transactionType: TransactionType.Refund,
		},
		operation: IAuditOperations.INSERT,
		entity: IAuditEntities.POINTS,
		entityId: formValues.accountId,
	}
}
