import { createStyles, makeStyles } from '@material-ui/core/styles'

const SkuListTableStyle = makeStyles(() =>
	createStyles({
		skuListContainer: {
			width: '100%',
			display: 'grid',

			'& table': {
				marginBottom: 64,
			},

			'& tr > td:nth-child(2)': {
				padding: '0 16px',
				color: 'rgba(0, 0, 0, 0.9)',
				fontFamily: 'Work Sans',
				letterSpacing: '0.1px',
			},

			'& td': {
				padding: 0,
			},

			'& h5': {
				margin: 0,
			},

			'& ul': {
				paddingInlineStart: '0px',
			},
		},
		skuListTitle: {
			padding: '24px 0 0 24px',
			fontFamily: 'Barlow',
			fontWeight: 600,
		},
		skuListSearch: {
			width: '100%',
			padding: 24,
			'& > div': {
				width: 329.3,
				height: 40,
			},
		},
	}),
)

export default SkuListTableStyle
