import React from 'react'
import { useStoreMap } from 'effector-react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ComboEvents, ComboStore, PageLevelEvents } from '@/stores'
import { BASE_ROUTE } from '@/utils/constants'
import { ComboFormType } from '@/domains/enums'
import ComboSubmitDialogView from './ComboSubmitDialogView'
import useComboSubmitOneByOne from '../../hooks/useComboSubmitOneByOne'
import useComboSubmitMultiple from '../../hooks/useComboSubmitMultiple'

type MessageItem = {
	title: string
	message: string
}

const MESSAGES: Partial<Record<ComboFormType, MessageItem>> = {
	[ComboFormType.OneByOne]: {
		title: 'combo:DIALOG.ONE_BY_ONE.CONFIRMATION_TITLE',
		message: 'combo:DIALOG.ONE_BY_ONE.CONFIRMATION_MESSAGE',
	},
	[ComboFormType.Multiple]: {
		title: 'combo:DIALOG.MULTIPLE.CONFIRMATION_TITLE',
		message: 'combo:DIALOG.MULTIPLE.CONFIRMATION_MESSAGE',
	},
}

const ComboSubmitDialog: React.FC = () => {
	const history = useHistory()
	const { t } = useTranslation()
	const submitOneByOne = useComboSubmitOneByOne()
	const submitMultiple = useComboSubmitMultiple()

	const { showSubmitDialog, formType } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			formType: state.formType,
			showSubmitDialog: state.showSubmitDialog,
		}),
	})

	const handleCancel = React.useCallback(() => {
		ComboEvents.setShowSubmitDialog(false)
	}, [])

	const goToCombosRoute = () => {
		history.push(`${BASE_ROUTE}/combos`)
	}

	const handleAddOneByOne = async () => {
		try {
			await submitOneByOne()
			goToCombosRoute()
			PageLevelEvents.showNotification({
				route: '/membership/combos',
				notificationType: 'success',
				message: t('combo:NOTIFICATION.SUCCESS'),
			})
		} catch {
			PageLevelEvents.showNotification({
				notificationType: 'error',
				message: t('combo:NOTIFICATION.ERROR'),
			})
			ComboEvents.setShowSubmitDialog(false)
		}
	}

	const handleAddMultiple = async () => {
		try {
			await submitMultiple()
			goToCombosRoute()
			PageLevelEvents.showNotification({
				route: '/membership/combos',
				message: t('combo:NOTIFICATION.SUCCESS_MULTIPLE'),
				notificationType: 'info',
			})
		} catch {
			PageLevelEvents.showNotification({
				message: t('ERROR_MESSAGE.GENERIC_ERROR'),
				notificationType: 'error',
			})
			ComboEvents.setShowSubmitDialog(false)
		}
	}

	const handleSubmit = () => {
		if (formType === ComboFormType.OneByOne) {
			handleAddOneByOne()
		} else if (formType === ComboFormType.Multiple) {
			handleAddMultiple()
		}
	}

	if (!formType) {
		return <></>
	}

	return (
		<ComboSubmitDialogView
			showSubmitDialog={showSubmitDialog}
			handleCancel={handleCancel}
			handleSubmit={handleSubmit}
			title={t(MESSAGES[formType]!.title)}
			message={t(MESSAGES[formType]!.message)}
		/>
	)
}

export default ComboSubmitDialog
