import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useAdminAuditLog, { IAuditEntities, IAuditOperations } from '@/hooks/useAdminAuditLog'
import { useCallback } from 'react'

interface IAuditLogDeleteSku {
	sku: string
	skuName: string
	earningRuleId: string
	earningRuleName: string
}

const useAuditLogDeleteRuleSku = (): {
	sendAuditLogDeleteRuleSku: (deleteData: IAuditLogDeleteSku) => void
} => {
	const userInfo = useGetUserInfo()
	const { auditLogInstance } = useAdminAuditLog()

	const sendAuditLogDeleteRuleSku = useCallback(
		({ sku, skuName, earningRuleId, earningRuleName }: IAuditLogDeleteSku) => {
			auditLogInstance({
				metadata: {
					sku,
					sku_name: skuName,
					earning_rule: earningRuleName,
					tier: userInfo.tier ?? '',
					vendor: userInfo.vendor ?? '',
				},
				entity: IAuditEntities.EARNING_RULES,
				entityId: earningRuleId,
				operation: IAuditOperations.DELETE,
			})
		},
		[auditLogInstance, userInfo.tier, userInfo.vendor],
	)

	return { sendAuditLogDeleteRuleSku }
}

export default useAuditLogDeleteRuleSku
