import { createStyles, makeStyles } from '@material-ui/core/styles'

const CombosListStyle = makeStyles(() =>
	createStyles({
		title: {
			padding: '8px 0px 32px',
			lineHeight: '20px',
		},
		cell: {
			fontSize: '14px',
			verticalAlign: 'text-top',
		},
		cellCenter: {
			fontSize: '14px',
		},
		qntAligned: {
			padding: ' 0 0 0 10px',
		},
		pointsAligned: {
			marginBottom: '10px',
			verticalAlign: 'text-top',
		},
		fullyAligned: {
			textAlign: 'right',
		},
	}),
)

export default CombosListStyle
