import React from 'react'
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core'
import { Radio } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { ChallengeFilterFormData } from '@/stores/challengeCreation'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import styles from '../ChallengeFormFilterStyle'

type Props = {
	afterChange: (useFilter: boolean) => void
	disabledRadioFilter?: boolean
}

const SelectUseFilter: React.FC<Props> = ({ afterChange, disabledRadioFilter }: Props) => {
	const { t } = useTranslation()
	const classes = styles()
	const { control, setValue, getValues } = useFormContext<ChallengeFilterFormData>()
	const useGetInfo = useGetUserInfo()

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const hasFilter = event.target.value === 'withFilter'

		setValue('hasFilter', hasFilter)
		if (!hasFilter) setValue('individualTarget', undefined)
		afterChange(hasFilter)

		const filterText = hasFilter
			? t('challenge:FILTERS_STEP.USE_FILTER.WITH_FILTER')
			: t('challenge:FILTERS_STEP.USE_FILTER.NO_FILTER')

		AnalyticsService.events.radioButtonInteraction({
			...useGetInfo,
			radio_button_name: 'Filter',
			radio_button_label: `${filterText}`,
			form_name: 'Challenge Creation - Step 2 - Filter',
			screen_name: 'Challenge Creation - 2nd Step',
		})
	}

	return (
		<Controller
			control={control}
			name="hasFilter"
			render={() => {
				return (
					<FormControl>
						<FormLabel id="challange-radio-buttons-group-label">
							<div className={classes.label}>{t('challenge:FILTER')}</div>
						</FormLabel>
						<Radio.Root
							id="challange-radio-buttons-use-filter"
							aria-labelledby="challange-radio-buttons-use-filter"
							name="radio-buttons-use-filter"
						>
							<Radio.Item
								id="withFilter"
								label={t('challenge:FILTERS_STEP.USE_FILTER.WITH_FILTER')}
								value="withFilter"
								disabled={disabledRadioFilter}
								onClick={(ev) => handleRadioChange(ev as unknown as React.ChangeEvent<HTMLInputElement>)}
								checked={getValues().hasFilter}
							/>
							<FormHelperText className={classes.textHelper}>
								{t('challenge:FILTERS_STEP.USE_FILTER.WITH_FILTER_DSC')}
							</FormHelperText>
							<Radio.Item
								id="noFilter"
								label={t('challenge:FILTERS_STEP.USE_FILTER.NO_FILTER')}
								value="noFilter"
								disabled={disabledRadioFilter}
								onClick={(ev) => handleRadioChange(ev as unknown as React.ChangeEvent<HTMLInputElement>)}
								checked={getValues().hasFilter === false}
							/>
							<FormHelperText className={classes.textHelper}>
								{t('challenge:FILTERS_STEP.USE_FILTER.NO_FILTER_DSC')}
							</FormHelperText>
						</Radio.Root>
					</FormControl>
				)
			}}
		/>
	)
}

export default SelectUseFilter
