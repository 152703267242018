import { useMemo } from 'react'
import { ChallengeExecutionMethod } from '@/domains/enums'
import { useTranslation } from 'react-i18next'
import {
	isToggleMarkCompleteChallengeEnabled,
	isTogglePhotoChallengeEnabled,
	isTogglePurchaseChallengeMultipleEnabled,
	isTogglePurchaseChallengesEnabled,
	isToggleVolumeChallengeEnabled,
} from '@/utils/featureToggle/featureToggleHelper'

export type TypeList = {
	value: string
	label: string
}

const useChallengeTypeList = (): TypeList[] => {
	const { t } = useTranslation()
	const challengeTypeList = useMemo(() => {
		const typeList: TypeList[] = []

		if (isToggleVolumeChallengeEnabled()) {
			typeList.push({
				value: ChallengeExecutionMethod.VolumeFixed,
				label: t('challenge:CHALLENGE_TYPE_VOLUME_FIXED_POINTS'),
			})
		}
		if (isTogglePurchaseChallengesEnabled()) {
			typeList.push({
				value: ChallengeExecutionMethod.Purchase,
				label: t('challenge:CHALLENGE_TYPE_PURCHASE_SINGLE'),
			})
		}
		if (isTogglePurchaseChallengeMultipleEnabled()) {
			typeList.push({
				value: ChallengeExecutionMethod.PurchaseMultiple,
				label: t('challenge:CHALLENGE_TYPE_PURCHASE_MULTIPLE'),
			})
		}
		if (isTogglePhotoChallengeEnabled()) {
			typeList.push({ value: ChallengeExecutionMethod.TakePhoto, label: t('challenge:CHALLENGE_TYPE_1') })
		}
		if (isToggleMarkCompleteChallengeEnabled()) {
			typeList.push({ value: ChallengeExecutionMethod.MarkAsComplete, label: t('challenge:CHALLENGE_TYPE_2') })
		}

		return typeList
	}, [t])

	return challengeTypeList
}

export default useChallengeTypeList
