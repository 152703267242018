import { useEffect, useCallback } from 'react'
import { usePreferredLanguage } from 'admin-portal-shared-services'
import i18n, { defineLocaleData, supportedLanguages } from '@/i18n'
import { DEFAULT_LANGUAGE } from '@/utils/constants'
import { getAValidLanguageKey } from '@/utils/language'

interface LocaleProviderProps {
	children: React.ReactElement
}

const LocaleProvider: React.FC<LocaleProviderProps> = ({ children }) => {
	const preferredLanguage = usePreferredLanguage()

	const defineLocale = useCallback(async () => {
		const validLangKey = getAValidLanguageKey(preferredLanguage)
		const selectedLanguage = supportedLanguages[validLangKey]?.key || DEFAULT_LANGUAGE
		await i18n.changeLanguage(selectedLanguage)
		defineLocaleData(selectedLanguage)
	}, [preferredLanguage])

	useEffect(() => {
		defineLocale()
	}, [defineLocale])

	return children
}

export default LocaleProvider
