import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		searchContainer: {
			marginBottom: '32px',
			padding: '32px 32px 0',
		},
	}),
)

export default styles
