import React from 'react'
import { Trash2 } from '@hexa-ui/icons'
import clsx from 'clsx'
import styles from './FilterBoxStyle'

type Props = {
	index: number
	onRemoveLinkClick: (index: number) => void
	isRemoveLinkDisabled: boolean
}

const FilterBoxRemoveLink: React.FC<Props> = ({ onRemoveLinkClick, isRemoveLinkDisabled, index }: Props) => {
	const classes = styles()

	return (
		<Trash2
			id="remove-link"
			data-testid={`trash-${index}`}
			className={clsx(
				{
					[classes.removeButton]: !isRemoveLinkDisabled,
				},
				classes.trashButton,
			)}
			onClick={() => onRemoveLinkClick(index)}
		/>
	)
}

export default FilterBoxRemoveLink
