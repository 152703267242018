import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

export const useAnalyticsServiceEventDefaultPricePerPointUpdated = (): {
	sendAnalyticsServiceEventDefaultPricePerPointUpdatedEvent: (pricePerPoint: number) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceEventDefaultPricePerPointUpdatedEvent = useCallback(
		(pricePerPoint: number) => {
			AnalyticsService.events.defaultPricePerPointUpdated({
				screen_name: 'Default Price Per Point',
				price_per_point: pricePerPoint,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceEventDefaultPricePerPointUpdatedEvent }
}
