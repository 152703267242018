import { Challenge, Group } from '@/domains'
import * as ChallengeService from '@/services/challenge/ChallengeService'
import * as SegmentationService from '@/services/segmentation/SegmentationService'
import { convertAll } from '@/converters/ChallengeToChallengeHistory'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { ChallengeParamsUseCase } from '@/domains/Challenge'
import ChallengesWithPagination from '@/domains/ChallnegeWithPagination'

export type ChallengeWithGroups = {
	challenge: Challenge
	groups: Group[] | null
}

function execute(params: ChallengeParamsUseCase): Promise<ChallengesWithPagination> {
	const vendorId = getDefaultVendorIdOfCurrentZone()

	return SegmentationService.loadAll()
		.then((groups) => {
			return ChallengeService.loadAll({ ...params, vendorIds: vendorId }).then((challenges) => {
				return {
					data: convertAll(challenges.content, groups),
					pagination: challenges.pagination,
				}
			})
		})
		.catch((error) => {
			throw error
		})
}

function executeById(id: string): Promise<ChallengeWithGroups | null> {
	return ChallengeService.load(id)
		.then((challenge) => {
			if (challenge?.filter?.groupIds?.length) {
				return SegmentationService.loadAll().then((groups) => {
					return { challenge, groups }
				})
			}
			return { challenge, groups: null } as ChallengeWithGroups
		})
		.catch(() => {
			return null
		})
}

export { execute, executeById }
