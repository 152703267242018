import React from 'react'
import { Grid } from '@material-ui/core'
import useStyles from './styles'

const PocSearchBox: React.FC = (props) => {
	const classes = useStyles()

	return (
		<Grid container>
			<Grid {...props} item className={classes.searchBox} />
		</Grid>
	)
}

export default PocSearchBox
