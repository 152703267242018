import { Checkbox, FormControl, FormControlLabel, Typography } from '@material-ui/core'
import { Field, FieldProps } from 'formik'
import React, { ChangeEvent } from 'react'
import useStyle from './style'

type Props = {
	id: string
	name: string
	label: string
	optionalLabel?: string
	disabled?: boolean
	checkBoxVisible?: boolean
	onChange?: (event: ChangeEvent<{ name?: string; value: string }>) => void
}

const InputCheckBox: React.FC<Props> = ({
	id,
	name,
	label,
	optionalLabel,
	disabled,
	checkBoxVisible,
	onChange,
}: Props) => {
	const classes = useStyle({ disabled, checkBoxVisible })

	const renderCheckBox = (fieldProps: FieldProps) => {
		return (
			<div className={classes.checkbox}>
				<Checkbox
					id={id}
					name={fieldProps.field.name}
					onChange={(event: ChangeEvent<{ name?: string; value: string }>): void => {
						fieldProps.field.onChange(event)
						if (onChange) {
							onChange(event)
						}
					}}
					color="primary"
					onBlur={fieldProps.field.onBlur}
					value={fieldProps.field.value ?? false}
					checked={fieldProps.field.value ?? false}
					disabled={disabled}
					classes={{ root: classes.inputRoot }}
				/>
			</div>
		)
	}

	const renderLabel = () => {
		return (
			<Typography component="span" className={classes.label}>
				<Typography variant="body1">{label}</Typography>
				{optionalLabel && <Typography variant="body2">{optionalLabel}</Typography>}
			</Typography>
		)
	}
	return (
		<Field name={name} type="checkbox">
			{(fieldProps: FieldProps) => {
				return (
					<FormControl fullWidth>
						<FormControlLabel control={renderCheckBox(fieldProps)} label={renderLabel()} className={classes.root} />
					</FormControl>
				)
			}}
		</Field>
	)
}

export default InputCheckBox
