import { createStore } from 'effector'
import * as GlobalEvents from './LogoutEvents'
import LogoutState from './LogoutState'

const initialState: LogoutState = {
	logoutDialog: { show: false },
}

const LogoutStore = createStore(initialState).on(GlobalEvents.toggleLogoutDialogForLogout, (state: LogoutState) => ({
	...state,
	logoutDialog: { show: !state.logoutDialog.show },
}))

export default LogoutStore
