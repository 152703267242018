import React, { ReactNode, useEffect } from 'react'
import { useStoreMap } from 'effector-react'
import { ChallengeStore, ChallengeEvents } from '@/stores/challengeCreation'
import FormCardView from './FormCardView'

type Props = {
	children: ReactNode
}

const FormCard: React.FC<Props> = ({ children }: Props) => {
	const pageLevelNotification = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.pageLevelNotification,
	})

	useEffect(() => {
		if (pageLevelNotification.type === 'previousStep') {
			ChallengeEvents.hidePageLevelNotification()
		}
	}, [pageLevelNotification.type])

	return <FormCardView pageLevelNotification={pageLevelNotification}>{children}</FormCardView>
}

export default FormCard
