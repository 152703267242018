import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsServiceRecordedDeleted {
	recordId: string
	recordType: string
	screenName: string
}

export const useAnalyticsServiceRecordDeleted = (): {
	sendAnalyticsServicerecordDeleted: (recordedDeleted: AnalyticsServiceRecordedDeleted) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServicerecordDeleted = useCallback(
		({ recordId, recordType, screenName }: AnalyticsServiceRecordedDeleted) => {
			AnalyticsService.events.recordDeleted({
				record_id: recordId,
				record_type: recordType,
				screen_name: screenName,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServicerecordDeleted }
}
