import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import { useLogService } from 'admin-portal-shared-services'
import App from './bootstrap/App'
import setupLocalEnv from './utils/__mocks__/setupLocal'

setupLocalEnv()

const reactLifeCycles = singleSpaReact({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	React: React as any, // debit: single-spa-react incorrect definition
	ReactDOM,
	rootComponent: App,
	errorBoundary(error: Error) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useLogService().error(error)
		return <></>
	},
})

export const { bootstrap, mount, unmount } = reactLifeCycles
