import { makeStyles } from '@material-ui/styles'

const styles = makeStyles({
	emptySkuContainer: {
		alignItems: 'center',
		backgroundColor: '#FBFAFE',
		display: 'flex',
		flexDirection: 'column',
		height: '178px',
		margin: 'auto',
		width: '328px',
	},
	notFoundSkuContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '178px',
		margin: 'auto',
		width: '328px',
	},
	emptyGraphic: {
		height: '105.54px',
		width: '105.54px',
		marginTop: '16px',
	},
	notFoundedImage: {
		height: '41.13px',
		width: '59.49px',
		marginTop: '16px',
	},
	emptySkuMessage: {
		height: '40px',
		width: '280px',
		color: 'rgba(0,0,0,0.55)',
		fonFamily: 'Barlow',
		fontSize: '16px',
		fonWeight: '600',
		letterSpacing: '0',
		lineHeight: '20px',
		textAlign: 'center',
	},
	itemCard: {
		height: '170px',
		margin: '16px auto',
	},
	loading: {
		display: 'flex',
		height: '170px',
		justifyContent: 'center',
	},
	searchButton: {
		maxWidth: '118px',
		outline: '0.5px solid',
	},
})

export default styles
