import { createStyles, makeStyles } from '@material-ui/core/styles'

const RuleDescriptionStyle = makeStyles(() =>
	createStyles({
		card: {
			position: 'relative',
			display: 'flex',
			width: '100%',
			maxWidth: 864,
			padding: 24,
			gap: 24,
			fontSize: 16,
		},
		title: {
			'& p': {
				fontSize: '16px',
				lineHeight: '24px',
			},
		},
		properties: {
			display: 'flex',
			'& div': {
				width: 155,
			},
			'& p': {
				fontSize: '14px',
				lineHeight: '20px',
			},
		},
		text: {
			margin: '0 0 4px 0',
			color: 'rgba(0,0,0,0.55)',
			letterSpacing: '0.1px',
		},
		typography: {
			color: 'rgba(0,0,0,0.9)',
			fontFamily: 'Barlow',
			fontWeight: 600,
			letterSpacing: 0,
		},
	}),
)

export default RuleDescriptionStyle
