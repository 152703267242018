import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& tr > td:nth-child(2)': {
				paddingLeft: '0',
			},
		},
		action: {
			paddingRight: '16px',
			fontSize: '14px',
			fontWeight: 500,
			letterSpacing: 0,
			lineHeight: '20px',
			color: theme.palette.info.main,
			cursor: 'pointer',
			textDecoration: 'underline',
			'&:hover': {
				filter: 'brightness(60%)',
			},
		},
		rowNoContent: {
			overflowY: 'hidden',
			transition: 'all 0.5s ease-in-out',
			'& td': {
				position: 'fixed',
				zIndex: -1,
				opacity: 0,
			},
		},
	}),
)

export default styles
