export const hasText = (value?: string | null): boolean => {
	if (!value) {
		return false
	}
	return value.trim().length > 0
}

export const camelToSnake = (str: string): string => {
	return str.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`)
}

const TOAST_MAX_CHARS = 44

export const truncate = (value: string, options: { max?: number; endChar?: string } = {}): string => {
	const { max = TOAST_MAX_CHARS, endChar = '...' } = options
	const endString = value.length > max ? endChar : ''
	return `${value.substring(0, max)}${endString}`
}

export const onlyNumbers = (value?: string | null): string => {
	return value?.replace(/\D/g, '') ?? ''
}
