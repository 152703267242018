import { ChallengeHistory } from '@/domains'
import { PaginationInfo } from '@/domains/utils/Pagination'
import { createEvent } from 'effector'

export const toggleCancelDialog = createEvent('toggleCancelDialog')
export const setChallengeAccepted = createEvent<boolean | null>('setChallengeAccepted')
export const setChallengeCancelled = createEvent<boolean>('setChallengeCancelled')
export const setChallenges = createEvent<ChallengeHistory[]>('setChallenges')
export const setPagination = createEvent<PaginationInfo>('setPagination')
export const setLoading = createEvent<boolean>('setLoading')
export const setShowError = createEvent<boolean>('setShowError')
export const setOrderBy = createEvent<string>('setOrderBy')
export const setDirection = createEvent<string>('setDirection')

export const resetForChallengeHistory = createEvent('reset')
