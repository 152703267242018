import React from 'react'
import { FormControl, FormLabel, FormHelperText } from '@material-ui/core'
import { Radio } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { ChallengeEvents, ChallengeFilterFormData } from '@/stores/challengeCreation'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import styles from './ChallengeFormFilterStyle'

type Props = {
	afterChange: (individualTarget: boolean) => void
	disabled?: boolean
}

const ChallengeFormFilterTarget: React.FC<Props> = ({ afterChange, disabled }: Props) => {
	const { t } = useTranslation()
	const classes = styles()
	const { control, setValue, getValues } = useFormContext<ChallengeFilterFormData>()
	const useGetInfo = useGetUserInfo()

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const individualTarget = event.target.value === 'individual'
		if (individualTarget) {
			ChallengeEvents.setChallengeDetailsFormData({ points: 1, quantity: 1 })
		} else {
			ChallengeEvents.setChallengeDetailsFormData({ points: 0, quantity: undefined })
		}
		setValue('individualTarget', individualTarget)
		afterChange(individualTarget)
		ChallengeEvents.setIndividualTarget(individualTarget)
		const targetText = individualTarget
			? t('challenge:FILTERS_STEP.TARGET.INDIVIDUAL')
			: t('challenge:FILTERS_STEP.TARGET.GENERAL')
		AnalyticsService.events.radioButtonInteraction({
			radio_button_name: 'Target Type',
			radio_button_label: `${targetText}`,
			form_name: 'Challenge Creation - Step 2 - Target Type',
			screen_name: 'Challenge Creation - 2nd Step',
			...useGetInfo,
		})
	}

	return (
		<Controller
			control={control}
			name="individualTarget"
			render={() => (
				<FormControl>
					<FormLabel id="challange-radio-buttons-group-label">
						<div className={classes.label}>{t('challenge:FILTERS_STEP.TARGET.TARGET_TYPE')}</div>
					</FormLabel>
					<Radio.Root aria-labelledby="challange-radio-buttons-group-label" name="radio-buttons-group">
						<Radio.Item
							id="individual"
							label={t('challenge:FILTERS_STEP.TARGET.INDIVIDUAL')}
							value="individual"
							onClick={(ev) => handleRadioChange(ev as unknown as React.ChangeEvent<HTMLInputElement>)}
							disabled={disabled}
							checked={getValues().individualTarget}
						/>
						<FormHelperText className={classes.textHelper}>
							{t('challenge:FILTERS_STEP.TARGET.INDIVIDUAL_SUBLABEL')}
						</FormHelperText>
						<Radio.Item
							id="general"
							label={t('challenge:FILTERS_STEP.TARGET.GENERAL')}
							value="general"
							onClick={(ev) => handleRadioChange(ev as unknown as React.ChangeEvent<HTMLInputElement>)}
							disabled={disabled}
							checked={getValues().individualTarget === false}
						/>
						<FormHelperText className={classes.textHelper}>
							{t('challenge:FILTERS_STEP.TARGET.GENERAL_LABEL')}
						</FormHelperText>
					</Radio.Root>
				</FormControl>
			)}
		/>
	)
}

export default ChallengeFormFilterTarget
