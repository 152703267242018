import { useLogService as logService } from 'admin-portal-shared-services'
import { AccountGroupTarget } from '@/domains/AccountGroup'
import { createFormData } from '@/utils/accountGroupUtil'
import axios from '@/utils/axiosInstance'
import { createServerResponseError } from '@/utils/axiosError'
import { AxiosError } from 'axios'
import ServerError from '@/domains/ServerError'
import { removeUndefinedAttributes } from '@/utils/object'
import Response from '../models/Response'

const baseURL = `/rewards-admin-service/v1`
const log = logService()

function getHeader(params: AccountGroupTarget): Record<string, string | boolean> {
	const { groupName, purpose, vendorId, individualTarget, challengeId } = params
	const headers = {
		'Content-Type': 'multipart/form-data',
		challengeId,
		groupName,
		purpose,
		vendorId,
		individualTarget,
	}
	return removeUndefinedAttributes(headers)
}

export async function upsert(params: AccountGroupTarget): Promise<Response<{ groupId: string }>> {
	const formData = createFormData(params)
	const url = `${baseURL}/account-groups`
	try {
		const response = await axios.post<{ groupId: string }>(url, formData, {
			headers: getHeader(params),
		})
		return {
			success: true,
			data: response.data,
		}
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		const axiosError = error as AxiosError<ServerError>
		if (axiosError?.response) {
			return createServerResponseError(axiosError)
		}
		throw error
	}
}
