import {
	$pricePerPointConfigurationStore,
	PricePerPointConfiguration,
	PricePerPointConfigurationStoreEvents,
} from '@/stores'
import { LoadPricePerPointConfigurationUseCase } from '@/usecase'
import { isToggleRedeemableItemsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { useStore } from 'effector-react'
import { useCallback, useState } from 'react'

export type usePricePerPointConfigurationResult = {
	data?: PricePerPointConfiguration
	hasError: boolean
	isLoading: boolean
	loadData: () => void
}

export const usePricePerPointConfiguration = (): usePricePerPointConfigurationResult => {
	const [isLoading, setIsLoading] = useState(false)
	const [hasError, setHasError] = useState(false)
	const data = useStore($pricePerPointConfigurationStore)

	const loadData = useCallback(() => {
		if (!isToggleRedeemableItemsEnabled()) {
			return
		}

		setIsLoading(true)
		setHasError(false)

		LoadPricePerPointConfigurationUseCase.execute()
			.then((result) => {
				PricePerPointConfigurationStoreEvents.setSettings(result)
			})
			.catch(() => {
				setHasError(true)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [])

	return {
		data,
		hasError,
		isLoading,
		loadData,
	}
}
