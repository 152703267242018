import React from 'react'
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch'

export type SwitchPropsComponent = {
	id: string
} & MuiSwitchProps

const SwitchComponent: React.ForwardRefRenderFunction<React.Ref<typeof MuiSwitch>, SwitchPropsComponent> = ({
	id,
	...rest
}) => {
	return <MuiSwitch id={id} {...rest} />
}

export default React.forwardRef(SwitchComponent)
