import { OfferCategory } from '@/domains/offerCategory/offerCategory'
import { GlobalEvents, GlobalStore } from '@/stores'
import { ConfigurationUseCase, LoadOfferCategoriesConfigurationUseCase } from '@/usecase'
import { hasElements, removeDuplicates, toLowerCase } from '@/utils/array'
import { hasText } from '@/utils/string'
import { useAuthenticationService as authenticationService } from 'admin-portal-shared-services'
import { useStore } from 'effector-react'
import React, { useCallback, useEffect, useMemo } from 'react'

type Props = {
	children: React.ReactNode
}

const verifyZone = (currentZone: string): boolean => {
	const supportedCountries = authenticationService().getSupportedCountries()
	const userZone = authenticationService().getCountryB2C()
	const allZones = removeDuplicates(toLowerCase([...supportedCountries, userZone]))
	if (hasElements(allZones)) {
		return allZones.indexOf(currentZone.toLocaleLowerCase()) !== -1
	}
	return false
}

const ZoneProvider: React.FC<Props> = ({ children }) => {
	const { selectedZone } = useStore(GlobalStore)
	const isZoneOK = useMemo(() => {
		if (!hasText(selectedZone)) {
			return false
		}
		return verifyZone(selectedZone!)
	}, [selectedZone])

	const selectZone = useCallback((zone: string) => {
		GlobalEvents.selectZone(zone)
	}, [])

	const getSupportedCountries = useCallback(() => {
		ConfigurationUseCase.execute().then((supportLanguage) => {
			GlobalEvents.setSupportLanguage(supportLanguage!)
		})
	}, [])

	const getOfferCategories = useCallback(() => {
		LoadOfferCategoriesConfigurationUseCase().then((offerCategory: OfferCategory[]) => {
			GlobalEvents.setOfferCategories(offerCategory)
		})
	}, [])

	useEffect(() => {
		getSupportedCountries()
		getOfferCategories()
		if (!isZoneOK) {
			const userZone = authenticationService().getCountryB2C()
			selectZone(userZone)
		}
	}, [getSupportedCountries, getOfferCategories, isZoneOK, selectZone, selectedZone])

	return isZoneOK ? <>{children}</> : null
}

export default ZoneProvider
