import React, { ReactNode } from 'react'
import { Paper } from '@/components/core'
import { Typography } from '@material-ui/core'
import useStyles from './styles'

type Props = {
	subtitle?: string
	children: ReactNode
}
const FormCard: React.FC<Props> = ({ subtitle, children }: Props) => {
	const classes = useStyles()

	return (
		<Paper>
			{subtitle && (
				<Typography variant="h4" className={classes.title}>
					{subtitle}
				</Typography>
			)}
			{children}
		</Paper>
	)
}

export default FormCard
