import { Container, Typography } from '@material-ui/core'
import FormDialogTemplate from 'components/templates/FormDialogTemplate'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import RadioGroup from 'components/hook-form/RadioGroup'
import { useForm, Controller, yupResolver } from 'admin-membership-hookform-resolvers'
import { useStoreMap } from 'effector-react'
import { ChallengeHistoryStore, ChallengeHistoryEvents, PageLevelEvents } from '@/stores'
import AnalyticsService from 'services/analytics/AnalyticsService'
import ScreenName from 'domains/analytics/ScreenName'
import * as yup from 'yup'
import ModalName from 'domains/analytics/ModalName'
import * as CancelChallengeUseCase from 'usecase/challenge/CancelChallengeUseCase'
import ChallengeHistoryDetail from 'routes/challenges/model/ChallengeHistoryDetail'
import ChallengeCancelReason from 'domains/analytics/ChallengeCancelReason'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { ButtonInteraction } from '@/domains'
import styles from './styles'

type Props = {
	challenge: ChallengeHistoryDetail
}

type FormType = {
	reason: string
}

type ReasonValueType = 'TYPO_MISTAKE' | 'STRATEGY_CHANGE' | 'INCORRECT_TARGETING' | 'OTHER'

const REASON_VALUES = ['TYPO_MISTAKE', 'STRATEGY_CHANGE', 'INCORRECT_TARGETING', 'OTHER']

const capitalize = (s: string) => `${s[0].toUpperCase()}${s.toLowerCase().slice(1)}`

const CancelDialog: React.FC<Props> = ({ challenge: { id: challengeId, status, executionMethod, title } }) => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const classes = styles()
	const showDialog = useStoreMap({
		store: ChallengeHistoryStore,
		keys: [],
		fn: (state) => state.cancelDialog.show,
	})

	const validationSchema = yup.object().shape({
		reason: yup.string().required(t('ERROR_MESSAGE.EMPTY_FIELD')),
	})

	const {
		control,
		formState: { isDirty },
		handleSubmit,
	} = useForm<FormType>({ resolver: yupResolver(validationSchema) })

	const options = useMemo(
		() =>
			REASON_VALUES.map((reason) => ({
				value: reason,
				label: t(`challenge:HISTORY.CANCEL_DIALOG.REASON.${reason as ReasonValueType}`),
			})),
		[t],
	)

	const onSubmit = async (values: FormType): Promise<void> => {
		try {
			const response = await CancelChallengeUseCase.execute(challengeId)
			if (response) {
				// challenge successfully cancelled
				AnalyticsService.events.challengeCancelled({
					cancellation_reason: ChallengeCancelReason[values.reason],
					challenge_id: challengeId,
					challenge_status: capitalize(status),
					challenge_type: executionMethod,
					screen_name: `Cancel Challenge - ${title} - ${executionMethod}`,
					...userInfo,
				})
				PageLevelEvents.showNotification({
					notificationType: 'success',
					message: t('challenge:HISTORY.CANCEL_CHALLENGE_SUCCESS_MESSAGE'),
				})
				ChallengeHistoryEvents.setChallengeCancelled(true)
			} else {
				// challenge was not cancelled because it was accepted by a POC
				PageLevelEvents.showNotification({
					notificationType: 'info',
					message: t('challenge:HISTORY_DETAIL_REDESIGN.CANCEL_BUTTON_TOOLTIP'),
				})
				ChallengeHistoryEvents.setChallengeAccepted(true)
			}
		} catch (error) {
			PageLevelEvents.showNotification({
				notificationType: 'error',
				message: t('challenge:HISTORY.CANCEL_CHALLENGE_ERROR_MESSAGE'),
			})
		} finally {
			ChallengeHistoryEvents.toggleCancelDialog()
		}
	}

	function handleCloseButtonClick() {
		AnalyticsService.events.buttonClicked({
			button_name: ButtonInteraction.Close,
			button_label: ButtonInteraction.Close,
			screen_name: `Cancel Challenge - ${title} - ${executionMethod}`,
			...userInfo,
		})
		AnalyticsService.events.modalExited({
			modal_name: ModalName.ChallengeCancel,
			screen_name: ScreenName.ChallengeDetailsPage,
		})
		ChallengeHistoryEvents.toggleCancelDialog()
	}

	return (
		<FormDialogTemplate
			title={t('challenge:HISTORY.CANCEL_DIALOG.TITLE')}
			showDialog={showDialog}
			cancelButtonProps={{
				name: t('BUTTON.CLOSE'),
				handleClick: handleCloseButtonClick,
			}}
			submitButtonProps={{ name: t('challenge:HISTORY.CANCEL_DIALOG.SUBMIT'), disabled: !isDirty }}
			handleSubmit={handleSubmit(onSubmit)}
		>
			<Container className={classes.instruction}>
				<Typography>{t('challenge:HISTORY.CANCEL_DIALOG.INSTRUCTION')}</Typography>
			</Container>
			<Controller control={control} name="reason" id="reason" options={options} defaultValue="" as={RadioGroup} />
		</FormDialogTemplate>
	)
}

export default CancelDialog
