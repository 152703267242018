import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { BASE_ROUTE } from 'utils/constants'
import { isToggleEnabled } from '@/hooks'
import { NEW_TRANSACTION_LOGS } from '@/utils/featureToggle'
import { RedeemableItemsProvider } from '@/context/RedeemableItems/RedeemableItemsContext'
import { RedeemableItemsList as NewRedeemablesPage } from './components/RedeemableItemsListV2/RedeemableItemsList'
import { RedeemableItemsList as OldRedeemablesPage } from './components/RedeemableItemsList/RedeemableItemsList'

const REDEEMABLES_PATH = `${BASE_ROUTE}/redeemable-items`
const RedeemableItemsRoute: React.FC = () => {
	const isNewListEnabled = isToggleEnabled(NEW_TRANSACTION_LOGS)
	const RedeemablesPage = isNewListEnabled ? NewRedeemablesPage : OldRedeemablesPage

	return (
		<RedeemableItemsProvider>
			<Switch>
				<Route exact path={REDEEMABLES_PATH}>
					<RedeemablesPage data-testid="redeemable-items" />
				</Route>
				<Redirect path={`${REDEEMABLES_PATH}/*`} to={REDEEMABLES_PATH} />
			</Switch>
		</RedeemableItemsProvider>
	)
}

export default RedeemableItemsRoute
