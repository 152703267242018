import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsTableSearched {
	interactionType: string
	pageNumber: string
	screenName: string
}

export const useAnalyticsServiceEventPaginationClicked = (): {
	sendAnalyticsServicePaginationClicked: (recordData: AnalyticsTableSearched) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServicePaginationClicked = useCallback(
		({ interactionType, pageNumber, screenName }: AnalyticsTableSearched) => {
			AnalyticsService.events.paginationClicked({
				interaction_type: interactionType,
				page_number: pageNumber,
				screen_name: screenName,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServicePaginationClicked }
}
