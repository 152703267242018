import { RewardsOfferMultiple, RewardsOfferVendorAccountId } from '@/domains'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { FormikValues } from 'formik'

interface IFileAccount {
	accountId: string
	campaignId: string
	points: string
}

const transactionFormValuesToRewardsOfferMultiple = (values: FormikValues): RewardsOfferMultiple => {
	const convertAccountToVendorAccount = values.multiplePocsFileConverted.map((value: IFileAccount) => ({
		campaignId: value.campaignId,
		vendorAccountId: value.accountId,
		points: value.points,
	}))

	return {
		description: values.description,
		accounts: convertAccountToVendorAccount as RewardsOfferVendorAccountId[],
		category: values.category,
		referenceMonth: values.referenceMonth,
		referenceYear: values.referenceYear,
	} as RewardsOfferMultiple
}

export const transactionFormValuesToRewardsOfferMultipleV2 = (values: FormikValues): RewardsOfferMultiple => {
	const convertAccountToVendorAccount = values.multiplePocsFileConverted.map((value: IFileAccount) => ({
		campaignId: value.campaignId,
		vendorAccountId: value.accountId,
		points: value.points,
	}))

	const transformedItems =
		values.skus?.map(
			(item: {
				icon: string
				description: string
				sub_description: string
				value: string
				goal_input_id: string
				vendorId: string
			}) => ({
				brand: item?.sub_description ?? '',
				name: item?.description ?? '',
				imageUrl: item?.icon ?? '',
				sku: item?.value ?? '',
				goalInputId: item?.goal_input_id ?? '',
				vendorId: item?.vendorId ?? '',
			}),
		) || []
	return {
		description: values.description,
		accounts: convertAccountToVendorAccount as RewardsOfferVendorAccountId[],
		category: values.category,
		referenceMonth: values.referenceMonth,
		referenceYear: values.referenceYear,
		singlePOC: values.singlePOC,
		productCategory: values.productCategory,
		vendorId: getDefaultVendorIdOfCurrentZone(),
		quantity: convertAccountToVendorAccount.length,
		items: transformedItems.length > 0 ? transformedItems : undefined,
	} as RewardsOfferMultiple
}

export default transactionFormValuesToRewardsOfferMultiple
