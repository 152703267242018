import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		title: {
			whiteSpace: 'nowrap',
			lineHeight: '24px',
			paddingBottom: '8px',
		},
	}),
)

export default styles
