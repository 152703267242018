import { PointsRemovalTransaction, PointsRemovalTransactionResponse } from '@/domains'
import * as TransactionService from '@/services/transaction/TransactionService'

async function execute(
	accountId: string,
	amount: number,
	description: string,
): Promise<PointsRemovalTransactionResponse> {
	const requestBody = {
		description,
		points: amount,
		preventNegativeBalance: true,
	} as PointsRemovalTransaction

	return TransactionService.pointsRemoval(accountId, requestBody)
}

export { execute }
