import React from 'react'
import PageLevelTemplate from 'components/templates/PageLevelTemplate'
import { Paper } from '@/components/core'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TablePageTemplate from '@/components/templates/TablePageTemplate'
import Program from '@/domains/program/Program'
import useStyle from './ProgramConfigurationStyle'
import EditProgramConfiguration from '../EditProgramConfiguration/EditProgramConfiguration'
import ProgramRow from './ProgramRow'

type Props = {
	program: Program | undefined
	handleEdit: () => void
}

const ProgramConfigurationView: React.FC<Props> = ({ program, handleEdit }: Props) => {
	const { t } = useTranslation()
	const classes = useStyle()

	return (
		<>
			<TablePageTemplate title={t('program:TITLE')}>
				<PageLevelTemplate />
				<Paper className={classes.container}>
					<Grid container>
						<ProgramRow program={program} handleEdit={handleEdit} />
					</Grid>
				</Paper>
			</TablePageTemplate>
			<EditProgramConfiguration />
		</>
	)
}

export default React.memo(ProgramConfigurationView)
