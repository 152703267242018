import PocSearch from '@/components/poc/PocSearch'
import PocSearchBox from '@/components/poc/PocSearchBox'
import DataTable, { DataTableRef } from '@/components/tables/DataTable'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import TablePagePaperTemplate from '@/components/templates/TablePagePaperTemplate'
import { PocSearchParams, ScreenName } from '@/domains'
import { AccountValidationResult } from '@/domains/enums'
import RewardsProgram, { RewardsProgramPermission } from '@/domains/RewardsProgram'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { RewardsPermissionStore } from '@/stores'
import { hasElements } from '@/utils/array'
import { formatDate } from '@/utils/date'
import { useStoreMap } from 'effector-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import EditRewardsPermissionDialog from '../EditRewardsPermission/EditRewardsPermission'
import SwitchActionComponent from '../SwitchAction/SwitchAction'

type Props = {
	handleEdit: (row: RewardsProgram, rowIndex: number) => void
	isSearching: boolean
	searchHandler: (params: PocSearchParams) => Promise<AccountValidationResult>
}

const RewardsPermissionView: React.FC<Props> = ({ handleEdit, isSearching, searchHandler }: Props) => {
	const { t } = useTranslation()
	const dataTableRef = React.useRef<DataTableRef>(null)
	const userInfo = useGetUserInfo()

	const { rewardsData, showError } = useStoreMap({
		store: RewardsPermissionStore,
		keys: [],
		fn: (state) => ({
			rewardsData: state.rewardsData,
			showError: state.showError,
		}),
	})

	useEffect(() => {
		if (rewardsData.length > 0) {
			AnalyticsService.events.tableViewed({
				items_total: '1',
				items_per_page: '1',
				page_number: '1',
				table_name: ScreenName.RewardsPermission,
				screen_name: ScreenName.RewardsPermission,
				...userInfo,
			})
		}
	}, [rewardsData, userInfo])

	const isTableVisible = !isSearching && hasElements(rewardsData)

	return (
		<TablePagePaperTemplate title={t('permission:TITLE')}>
			<EditRewardsPermissionDialog />
			<PageLevelTemplate />
			<PocSearchBox>
				<PocSearch isSearching={isSearching} searchHandler={searchHandler} />
			</PocSearchBox>
			{isTableVisible && (
				<DataTable
					id="rewards-permission"
					columns={[
						{
							label: `${t('permission:LIST.COLUMN_POC_ID')}`,
							dataKey: 'accountId',
							width: '25%',
						},
						{
							label: `${t('permission:LIST.COLUMN_POINTS')}`,
							dataKey: 'balance',
							width: '25%',
						},
						{
							label: `${t('permission:LIST.COLUMN_LAST_UPDATE')}`,
							dataKey: 'permission',
							width: '25%',
							format: ({ updatedAt }: RewardsProgramPermission): string =>
								updatedAt ? formatDate(new Date(updatedAt), t('DATA_NEW_SHORT_FORMAT_TABLE')) : '',
						},
					]}
					data={rewardsData}
					tableActions={[
						{
							fn: handleEdit,
							component(props) {
								return <SwitchActionComponent {...props} />
							},
							label: 'toggle permission',
							type: 'edit',
						},
					]}
					configs={{
						hideContainer: true,
						itemsPerPage: 50,
						customActionLabel: t('permission:LIST.COLUMN_ACTION_LABEL'),
						customActionInfo: t('permission:LIST.ACTION_LABEL_TOOLTIP'),
						actionWidth: '25%',
						actionAlign: 'center',
						style: {
							emptyMessage: {
								showIcon: true,
							},
							tableContainerInnerPadding: '0',
						},
					}}
					ref={dataTableRef}
					loading={false}
					showError={showError}
					emptyMessage={t('permission:NOTIFICATION.EMPTY_MESSAGE')}
				/>
			)}
		</TablePagePaperTemplate>
	)
}

export default React.memo(RewardsPermissionView)
