import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		grid: {
			paddingTop: '3px',
		},
		name: {
			fontWeight: 400,
			lineHeight: '16px',
			color: (props: { disabled: boolean }): string =>
				props.disabled ? theme.palette.text.disabled : theme.palette.text.primary,
			fontSize: '14px',
			letterSpacing: '0',
		},
		removeButton: {
			marginLeft: '4px',
			cursor: 'pointer',
		},
		disabledRemoveButton: {
			marginLeft: '4px',
			pointerEvents: 'none',
		},
		removeButtonIconButton: {
			padding: 0,
			verticalAlign: 'baseline',
		},
	}),
)

export default styles
