import { InputCurrency } from '@/components/hook-form/InputCurrency'
import * as currencyHelper from '@/utils/currencyHelper'
import { Alert, Grid, Paragraph } from '@hexa-ui/components'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { SelectedZoneAndVendorChips } from '../../SelectedZoneAndVendorChips'
import { UsePricePerPointEditorResult } from './usePricePerPointEditor'

import PricePerPointEditorStyle from './PricePerPointEditorStyle'

export const PricePerPointEditorContent = ({ data, tiers, hasSubmitError }: UsePricePerPointEditorResult) => {
	const {
		control,
		formState: { errors },
		setValue,
		reset,
	} = useFormContext()

	const { t } = useTranslation()
	const css = PricePerPointEditorStyle()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()

	useEffect(() => {
		const defaultPricePerPoint = data?.pricePerPoint
		const initialValue = defaultPricePerPoint ? currencyHelper.format(defaultPricePerPoint) : undefined
		setValue('pricePerPoint', initialValue, {
			shouldDirty: false,
		})

		return () => reset()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (errors?.pricePerPoint?.message) {
			sendAnalyticsServiceEventError({
				failureReason: errors?.pricePerPoint?.message,
				screenName: 'Default Price Per Point',
			})
		}
	}, [errors?.pricePerPoint?.message, sendAnalyticsServiceEventError])

	return (
		<div data-testid="ppp-editor-content">
			<div data-testid="ppp-editor-description" className={css.descriptionWrapper}>
				<Paragraph>
					<Trans i18nKey="redeemable:EDIT.DESCRIPTION" />
				</Paragraph>
				<Grid.Item className={css.tiersWrapper}>
					<SelectedZoneAndVendorChips />
				</Grid.Item>
			</div>
			<div className={css.inputWrapper} data-testid="ppp-editor-input-wrapper">
				<Controller
					control={control}
					name="pricePerPoint"
					render={({ field }) => {
						return (
							<InputCurrency
								{...field}
								id="input-ppp"
								data-testid="ppp-editor-input"
								label={t('redeemable:EDIT.PPP_INPUT_LABEL')}
								hint={t('redeemable:EDIT.PPP_INPUT_HINT', { tiers })}
								placeholder={currencyHelper.format(0, false)}
								errorText={t(errors.pricePerPoint?.message)}
							/>
						)
					}}
				/>
			</div>
			{hasSubmitError && (
				<div data-testid="ppp-editor-error-message">
					<Alert type="error" message={t('settings:NOTIFICATION.EDIT.ERROR')} className={css.errorMessage} />
				</div>
			)}
		</div>
	)
}
