import React from 'react'
import { format } from 'date-fns'
import { Select } from '@hexa-ui/components'
import { enUS } from 'date-fns/locale'
import { LOCALES } from './constants'

export function getYearOptions(language: string): JSX.Element[] {
	const selectedLocale = LOCALES[language] ?? enUS

	if (!selectedLocale) {
		throw new Error('Invalid language or language not supported')
	}

	const currentYear = new Date().getFullYear()
	const years = [currentYear - 1, currentYear]
	const yearOptions = []

	for (const year of years) {
		const yearString = format(new Date(year, 0, 1), 'yyyy', { locale: selectedLocale })
		yearOptions.push(
			<Select.Option key={year} value={yearString}>
				{yearString}
			</Select.Option>,
		)
	}

	return yearOptions
}
