import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		title: {
			marginTop: (props: { breadCrumbLink: boolean }): string => (props.breadCrumbLink ? '16px' : '39px'),
			marginBottom: '43px',
		},
	}),
)

export default styles
