import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreMap } from 'effector-react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { Tooltip } from '@/components/core'
import ChallengeStatus from 'domains/enums/ChallengeStatusEnum'
import ChallengeHistoryDetail from 'routes/challenges/model/ChallengeHistoryDetail'
import { ChallengeHistoryStore, ChallengeHistoryEvents, PageLevelEvents } from '@/stores'
import PageLevelTemplate from 'components/templates/PageLevelTemplate'
import AnalyticsService from 'services/analytics/AnalyticsService'
import ScreenName from 'domains/analytics/ScreenName'
import ButtonInteraction from 'domains/analytics/ButtonInteraction'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import parentStyles from '../ChallengeHistoryDetailStyle'
import styles from './styles'
import CancelDialog from '../CancelDialog'
import DuplicateButton from '../DuplicateButton'

export type DataSectionHeaderProps = {
	challenge: ChallengeHistoryDetail
}

const DataSectionHeader: React.FC<DataSectionHeaderProps> = ({ challenge }) => {
	const parentClasses = parentStyles()
	const userInfo = useGetUserInfo()
	const classes = styles()
	const { t } = useTranslation()
	const { challengeAccepted, challengeCancelled } = useStoreMap({
		store: ChallengeHistoryStore,
		keys: [],
		fn: (state) => ({
			challengeAccepted: state.challengeAccepted,
			challengeCancelled: state.challengeCancelled,
		}),
	})

	// if challengeAccepted is null that means server failed when trying to find accounts that accepted a challenge
	// or membership-cancel-challenges toggle is off
	const validStatus = [ChallengeStatus.Active, ChallengeStatus.Created].includes(challenge.status)
	const shouldDisplayCancelButton = !challengeCancelled && challengeAccepted !== null && validStatus
	const shouldDisableCancelButton = challengeAccepted || false

	const cancelButtonClick = () => {
		AnalyticsService.events.buttonClicked({
			button_label: `${t('challenge:HISTORY_DETAIL_REDESIGN.CANCEL_BUTTON')}`,
			button_name: ButtonInteraction.Cancel,
			screen_name: `${ScreenName.ChallengeDetails} - ${challenge.title} - ${challenge.executionMethod}`,
			...userInfo,
		})
		PageLevelEvents.hideNotification()
		ChallengeHistoryEvents.toggleCancelDialog()
	}

	return (
		<div className={classes.container}>
			<CancelDialog challenge={challenge} />
			<Grid container>
				<Grid item xs={6}>
					<Typography id="title-value" variant="h2">
						{t('challenge:HISTORY_DETAIL_REDESIGN.DETAIL_TITLE')}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.buttonsContainer}>
						{shouldDisplayCancelButton && (
							<Tooltip
								title={t('challenge:HISTORY_DETAIL_REDESIGN.CANCEL_BUTTON_TOOLTIP')}
								maxWidth="240px"
								disabled={!shouldDisableCancelButton}
								displayWhenDisabled
							>
								<Button
									type="button"
									variant={shouldDisableCancelButton ? 'contained' : 'outlined'}
									color="primary"
									id="cancelChallenge"
									onClick={cancelButtonClick}
									disabled={shouldDisableCancelButton}
									className={classes.cancelButton}
								>
									{t('challenge:HISTORY_DETAIL_REDESIGN.CANCEL_BUTTON')}
								</Button>
							</Tooltip>
						)}
						<DuplicateButton challenge={challenge} className={classes.duplicateButton} />
					</div>
				</Grid>
			</Grid>
			<Box component="div" className={parentClasses.notificationBox}>
				<PageLevelTemplate bottomSpacing={0} />
			</Box>
		</div>
	)
}

export default DataSectionHeader
