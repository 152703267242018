import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		control: {
			userSelect: 'none',
		},
		link: {
			fontWeight: 500,
			color: theme.palette.info.main,
			textDecoration: 'underline',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		icon: {
			paddingRight: '8px',
			paddingBottom: '1px',
			'&:hover': {
				cursor: 'pointer',
			},
		},
	}),
)

export default styles
