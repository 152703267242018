import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertDialog } from '@/components/core'

type Props = {
	showEditDialog: boolean
	enabled: boolean
	onCancel: () => void
	onSubmit: () => void
}

const EditRewardsPermissionView: React.FC<Props> = ({ enabled, showEditDialog, onSubmit, onCancel }: Props) => {
	const { t } = useTranslation()
	return (
		<AlertDialog
			title={enabled ? t('permission:EDIT_DIALOG.TO_ENABLE.TITLE') : t('permission:EDIT_DIALOG.TO_DISABLE.TITLE')}
			show={showEditDialog}
			handleCancel={onCancel}
			handleConfirm={onSubmit}
			buttonConfirmText={t('BUTTON.SAVE')}
		>
			{enabled ? t('permission:EDIT_DIALOG.TO_ENABLE.MESSAGE') : t('permission:EDIT_DIALOG.TO_DISABLE.MESSAGE')}
		</AlertDialog>
	)
}
export default EditRewardsPermissionView
