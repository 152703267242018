import React from 'react'
import InputTextWithValidation from '@/components/formik/InputTextWithValidation'
import { LoadAccountBalanceUseCase } from '@/usecase'
import { useTranslation } from 'react-i18next'
import { TransactionEvents } from '@/stores'
import { AccountValidationResult } from '@/domains/enums'
import useRemoveSuccessMessage from '../../hooks/useRemoveSuccessMessage'
import PocValidationParams from '../../model/PocValidationParams'

type Props = {
	id: string
	name: string
	label: string
	onPocValidation: (params: PocValidationParams) => Promise<string>
	onChangePoc?: (pocId: string) => void
	showError: boolean
	placeholder?: string
	onError?: (id: string, message: string) => void
	pocIdField?: string
	setPocIdField?: (pocId: string) => void
}

const PocIdField: React.FC<Props> = ({
	id,
	name,
	label,
	onPocValidation,
	onChangePoc,
	showError,
	placeholder = '',
	onError,
	pocIdField,
	setPocIdField,
}: Props) => {
	const { t } = useTranslation()
	const removeSuccessMessage = useRemoveSuccessMessage()

	const handleValidation = async (pocId: string): Promise<string> => {
		const { balance, validationResult, accountIdTranslated } = await LoadAccountBalanceUseCase.execute(pocId)
		if (validationResult === AccountValidationResult.OK) {
			TransactionEvents.addMapToAccountIdTranslationMap({
				[pocId]: accountIdTranslated ?? pocId,
			})
		}

		return onPocValidation({
			pocId,
			accountIdTranslated: accountIdTranslated ?? pocId,
			fieldName: name,
			balance,
			validationResult,
		})
	}

	return (
		<InputTextWithValidation
			id={id}
			name={name}
			label={label}
			validate={handleValidation}
			required={t('ERROR_MESSAGE.EMPTY_FIELD')}
			placeholder={placeholder}
			isFormValidating={showError}
			onKeyUpFirstKey={removeSuccessMessage}
			watchFormikValue
			hideErrorOnChange
			onUpdateValue={onChangePoc}
			onError={onError}
			pocIdField={pocIdField}
			setPocIdField={setPocIdField}
		/>
	)
}

export default PocIdField
