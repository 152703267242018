import React, { useCallback, useEffect, useRef } from 'react'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { CriticalPricePerPointConfigurationStoreEvents } from '@/stores/redeemableItems/CriticalPricePerPointStoreV2'
import { $editRedeemableItemStore, EditRedeemableItemStoreEvents } from '@/stores/'
import { truncate } from '@/utils/string'
import { Alert, Button, Dialog, Heading } from '@hexa-ui/components'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useStore } from 'effector-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import EditRedeemableItemFormProvider from '@/routes/redeemableItems/components/EditRedeemableItem/EditRedeemableItemFormProvider'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { $pricePerPointConfigurationStore } from '@/stores/redeemableItems/PricePerPointConfigurationStore'
import { isCriticalPPPV2, pppValidRange } from '@/usecase/redeemableItems/CriticalPricePerPointUseCase'
import { EditRedeemableItemUseCase } from '@/usecase'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { ScreenName } from '@/domains'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import { MODAL_CHANGE_DELAY } from '@/utils/constants'
import { unformat } from '@/utils/currencyHelper'
import { useAnalyticsServicePricePerPoints } from '@/hooks/analyticsService/useAnalyticsServiceEventPricePerPoints'
import { useRedeemableItems } from '@/context/RedeemableItems/RedeemableItemsContext'
import useAuditLogRedeemables from '../../hooks/useAuditLogRedeemables'
import EditRedeemableItemStyle from './EditRedeemableItemStyle'
import EditRedeemableItemContent from './EditRedeemableItemContent'

export const EditRedeemableItem = () => {
	const css = EditRedeemableItemStyle()
	const { t } = useTranslation()

	const state = useStore($editRedeemableItemStore)
	const { isOpened, item, isPublishing, hasError } = state
	const { pricePerPoint: defaultPricePerPoint } = useStore($pricePerPointConfigurationStore)

	const { minimum, maximum } = pppValidRange(defaultPricePerPoint)
	const minimumPrice = useFormatCurrency(minimum)
	const maximumPrice = useFormatCurrency(maximum)
	const cppRange = `(${minimumPrice} - ${maximumPrice})`

	const onModalOpenChangeHandler = useCallback(
		() => (isOpened ? EditRedeemableItemStoreEvents.reset() : EditRedeemableItemStoreEvents.onOpen(item)),
		[isOpened, item],
	)

	const contentCssValue = {
		display: 'flex',
		flexDirection: 'column',
		margin: 0,
		padding: '16px 24px',
		gap: '16px',
	}

	return (
		<div className={css.modalAlertWrapper} data-testid="edit-redeemable-item-wrapper">
			<EditRedeemableItemFormProvider>
				<Dialog.Root
					actions={<Actions hasError={hasError} isPublishing={isPublishing} item={item} />}
					title={<Heading cellPadding="30">{t('redeemable:EDIT_ITEM.TITLE')}</Heading>}
					open={isOpened}
					onOpenChange={onModalOpenChangeHandler}
					contentCss={contentCssValue}
				>
					<EditRedeemableItemContent item={item} />

					<Alert
						message={t('redeemable:EDIT.ALERT_MESSAGE', { cppRange })}
						type="warning"
						className={css.warningMessage}
					/>
				</Dialog.Root>
			</EditRedeemableItemFormProvider>
		</div>
	)
}

type ActionsProps = {
	item: RedeemableItem
	isPublishing: boolean
	hasError: boolean
}

export const Actions = ({ hasError, isPublishing, item }: ActionsProps) => {
	const css = EditRedeemableItemStyle()
	const { t } = useTranslation()
	const toast = useToast()
	const shouldDisplayWarning = useRef<boolean>(false)
	const { pricePerPoint: defaultPricePerPoint } = useStore($pricePerPointConfigurationStore)

	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const { sendAuditLogRedeemables } = useAuditLogRedeemables()
	const { sendAnalyticsServicePPP } = useAnalyticsServicePricePerPoints()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()

	const { inputValuePPP, valuePPP, isInputEmpty } = useRedeemableItems()

	const { handleSubmit } = useFormContext()

	useEffect(() => {
		shouldDisplayWarning.current = isCriticalPPPV2(valuePPP.current!, defaultPricePerPoint)
	}, [valuePPP.current])

	const onClose = () => {
		sendAnalyticsServiceEventButtonClickedEvent('Edit Redeemable Price Per Point', 'Cancel', 'Cancel')
	}

	const onSubmit = useCallback(
		async (pricePerPoint: number) => {
			valuePPP.current = pricePerPoint

			try {
				await EditRedeemableItemUseCase.execute({ ...item, pricePerPoint: valuePPP.current })
				sendAnalyticsServicePPP({ pricePerPoint: valuePPP.current!, sku: item.vendorItemId, skuName: item.itemName })
				if (item)
					sendAuditLogRedeemables({
						pricePerPoint: valuePPP.current,
						SKU: item.vendorItemId,
						operation: IAuditOperations.UPDATE,
					})
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				sendAnalyticsServiceEventError({ failureReason: error.message, screenName: ScreenName.EditRedeemablePPP })
			}
		},
		[item, sendAnalyticsServiceEventError, sendAnalyticsServicePPP, sendAuditLogRedeemables, valuePPP],
	)

	const displayToast = useCallback(() => {
		if (shouldDisplayWarning.current) {
			toast.notify({
				type: TypeToast.SUCCESS,
				message: t('redeemable:NOTIFICATION.EDIT_ITEM.CPP_OUT_OF_RANGE'),
			})
		} else {
			toast.notify({
				type: TypeToast.SUCCESS,
				message: t('redeemable:NOTIFICATION.EDIT_ITEM.SUCCESS', {
					itemName: truncate(`${item.itemName}`),
				}),
			})
		}
	}, [item, toast, t])

	const buttonAction = useCallback(
		({ pricePerPoint }: { pricePerPoint: string }) => {
			const unformattedPricePerPoint: number = inputValuePPP !== undefined ? inputValuePPP : unformat(pricePerPoint)
			if (item) EditRedeemableItemStoreEvents.setItem({ ...item, pricePerPoint: unformattedPricePerPoint })

			setTimeout(() => {
				CriticalPricePerPointConfigurationStoreEvents.onOpen({
					submit: () => {
						onSubmit(unformattedPricePerPoint)
						displayToast()
					},
					close: () => {
						EditRedeemableItemStoreEvents.onClose()
					},
					skuPricePerPoint: unformattedPricePerPoint,
				})
			}, MODAL_CHANGE_DELAY)
		},
		[inputValuePPP, item, onSubmit, displayToast],
	)

	return (
		<div className={css.actionsWrapper} data-testid="edit-redeemable-item-actions-wrapper">
			<Dialog.Close>
				<Button size="large" variant="secondary" data-testid="edit-redeemable-item-cancel-button" onClick={onClose}>
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="edit-redeemable-item-save-button"
				size="large"
				variant="primary"
				type="submit"
				onClick={handleSubmit(buttonAction)}
				disabled={isInputEmpty || (hasError && !(inputValuePPP === 0))}
				isLoading={!!isPublishing}
			>
				{hasError ? t('BUTTON.TRY_AGAIN') : t('BUTTON.SAVE')}
			</Button>
		</div>
	)
}
