import { Alert } from '@hexa-ui/components'
import { PermissionLevel } from '@/domains/enums'
import { useAuthorization, useFeatureToggleV2 } from '@/hooks'
import { useHideTierInfo } from '@/hooks/tier/useHideTierInfo'
import { GlobalStore } from '@/stores'
import * as FeatureToggle from '@/utils/featureToggle'
import { useStore } from 'effector-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const SelectedInfo = () => {
	const { t } = useTranslation()
	const { isFeatureAuthorized } = useAuthorization()
	const isSelectTiersEnabled = useFeatureToggleV2(FeatureToggle.SELECT_TIERS)

	const shouldHideTierInfo = useHideTierInfo()

	const { selectedProgram, tiersMap, selectedTierKey } = useStore(GlobalStore)
	const featureToggleProgram = useFeatureToggleV2(FeatureToggle.SELECT_PROGRAM)
	const featureToggleTiers = useFeatureToggleV2(FeatureToggle.SELECT_TIERS)

	const isConfigurationAvailable = isFeatureAuthorized(featureToggleProgram! || featureToggleTiers!, {
		feature: 'ProgramConfiguration',
		permissionLevel: PermissionLevel.Delete,
	})

	if (!isConfigurationAvailable || shouldHideTierInfo) {
		return null
	}

	if (isSelectTiersEnabled! && selectedTierKey) {
		return (
			<Alert
				css={{ width: '100%' }}
				message={`${t('common:SELECTED.LABEL_TIER')} ${tiersMap[selectedTierKey].label}`}
			/>
		)
	}

	return (
		<Alert
			css={{ width: '100%' }}
			message={`${t('common:SELECTED.LABEL_PROGRAM')} ${selectedProgram?.name ?? 'none (first available will be used)'}`}
		/>
	)
}

export default SelectedInfo
