import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
	createStyles({
		button: {
			fontSize: '14px',
			fontWeight: 500,
			letterSpacing: 0,
			lineHeight: '20px',
			color: theme.palette.info.main,
			cursor: 'pointer',
			textDecoration: 'underline',
			'&:hover': {
				filter: 'brightness(60%)',
			},
		},
	}),
)
