import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { BASE_ROUTE } from 'utils/constants'
import TransactionHistory from './components/TransactionHistory/TransactionHistory'

const TransactionHistoryRoute: React.FC = () => {
	return (
		<Switch>
			<Route exact path={`${BASE_ROUTE}/history`}>
				<TransactionHistory />
			</Route>
		</Switch>
	)
}

export default TransactionHistoryRoute
