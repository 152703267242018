import { LanguageKey } from '@/domains/language'
import { DEFAULT_LANGUAGE } from './constants'
import { hasText } from './string'

export const isLanguageValid = (lang: string): boolean => {
	if (!hasText(lang)) {
		return false
	}
	const attributes = lang.split('-')
	return attributes.length === 2 && hasText(attributes[0]) && hasText(attributes[1])
}

export const getAValidLanguageKey = (lang: string): LanguageKey => {
	const langText = isLanguageValid(lang) ? lang : DEFAULT_LANGUAGE
	const attributes = langText.split('-')
	const countryCode = attributes[1].toLocaleUpperCase()
	const languageCode = attributes[0].toLocaleLowerCase()

	return `${languageCode}-${countryCode}`
}
