import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { TableStyle } from '../model/TableStyle'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			minHeight: (props: TableStyle): string => (props.emptyMessage?.height ? '0' : '110px'),
			height: (props: TableStyle): string => props.emptyMessage?.height ?? '79vh',
			// if a style prop is empty the default Paper component style will be used
			boxShadow: (props: TableStyle): string => (props.noBoxShadow ? 'none' : ''),
			padding: (props: TableStyle): string => props.emptyMessage?.padding ?? '',
		},
		standardInfo: {
			borderRadius: '4px',
			backgroundColor: '#F0ECFC',
			color: theme.palette.text.primary,
			padding: (props: TableStyle): string => props.emptyMessage?.text?.padding ?? '0px 8px',
			'& p': {
				fontSize: '14px',
				lineHeight: '20px',
			},
		},
		icon: {
			paddingRight: '8px',
			marginRight: '0px',
		},
	}),
)

export default styles
