import React, { useState } from 'react'
import { Tabs, Tab, Box } from '@material-ui/core'
import clsx from 'clsx'
import useStyles from './NavigationTabsStyle'
import TabPanel from './TabPanel'

function allyProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}
interface TabItem {
	key: string
	label: string
	render: () => React.ReactNode
}

export type NavigationTabsProps = {
	tabItems: Array<TabItem>
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ tabItems }: NavigationTabsProps) => {
	const classes = useStyles()
	const [activeTab, setActiveTab] = useState(0)

	const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
		setActiveTab(newValue)
	}

	return (
		<Box flex={1} flexDirection="column">
			<Tabs value={activeTab} onChange={handleChange}>
				{tabItems.map((tabItem, index: number) => (
					<Tab
						className={clsx({ [classes.activeTab]: activeTab === index })}
						key={tabItem.key}
						label={tabItem.label}
						{...allyProps(index)}
					/>
				))}
			</Tabs>
			{tabItems.map((tabItem, index) => (
				<TabPanel key={tabItem.key} isActiveTab={activeTab === index} index={index}>
					{tabItem.render()}
				</TabPanel>
			))}
		</Box>
	)
}

export default NavigationTabs
