import React from 'react'
import { CircularProgress, IconButton, InputAdornment } from '@material-ui/core'
import { Search } from '@material-ui/icons'

type Props = {
	isSearching: boolean
	id: string
	onClick: () => void
}

const EndAdornment: React.FC<Props> = ({ isSearching, id, onClick }: Props) => (
	<InputAdornment position="end">
		{!isSearching ? (
			<IconButton id={`${id}-button`} aria-label={id} onClick={onClick} edge="end">
				<Search />
			</IconButton>
		) : (
			<CircularProgress color="inherit" size={16} />
		)}
	</InputAdornment>
)

export default EndAdornment
