import React from 'react'

function EmptySku() {
	return (
		<svg width="126" height="136" viewBox="0 0 126 136" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M53.6911 6.53316L14.0339 27.908C8.20716 31.0486 4.57422 37.1338 4.57422 43.753L4.57422 90.7608C4.57422 97.2282 8.04383 103.198 13.663 106.4L53.0921 128.867C58.6689 132.044 65.5157 132.012 71.0621 128.782L112.485 104.657C118.022 101.433 121.427 95.5091 121.427 89.1026L121.427 43.9092C121.427 37.2076 117.704 31.0608 111.764 27.9566L70.5691 6.42562C65.274 3.65806 58.9506 3.69835 53.6911 6.53316Z"
				fill="white"
				stroke="#F0ECFC"
				strokeWidth="8"
				strokeLinejoin="round"
			/>
			<mask id="mask0_2959_26011" maskUnits="userSpaceOnUse" x="35" y="36" width="57" height="63">
				<path d="M35.2949 36.6229H91.5572V98.2951H35.2949V36.6229Z" fill="#F0ECFC" />
			</mask>
			<g mask="url(#mask0_2959_26011)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M64.5847 94.551L56.7809 98.198L46.832 80.5298L53.2172 77.5457L64.5847 94.551Z"
					fill="#DDDCDC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M59.9893 91.9166C59.6969 91.2885 58.9516 91.0167 58.3246 91.3097C57.6981 91.602 57.4268 92.3494 57.7192 92.9775C58.0116 93.6055 58.7569 93.8773 59.3834 93.5844C60.0104 93.2915 60.2817 92.5447 59.9893 91.9166Z"
					fill="#424242"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M56.6489 56.0726C55.103 61.2396 49.4667 66.366 44.9077 68.4967L40.6653 70.4791C37.5229 71.9474 36.1633 75.691 37.629 78.8403C39.0941 81.9896 42.8298 83.3524 45.9723 81.8835L50.2146 79.9012C54.7737 77.771 62.3152 76.7387 67.2585 78.8736L78.1642 83.583L60.0589 44.6729L56.6489 56.0726Z"
					fill="#7C74B1"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M67.258 76.2231C62.3148 74.0882 54.7732 75.1206 50.2142 77.2507L45.9718 79.2331C42.8293 80.7019 39.0936 79.3392 37.6285 76.1899C37.4586 75.8252 37.3282 75.452 37.2327 75.0757C36.8891 76.4327 36.992 77.912 37.6285 79.2795C39.0936 82.4289 42.8293 83.7916 45.9718 82.3228L50.2142 80.3404C54.7727 78.2102 62.3148 77.1779 67.258 79.3128L78.1637 84.0223L76.3645 80.1557L67.258 76.2231Z"
					fill="#5F538F"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M76.1243 61.2172C81.2961 72.3324 82.0218 82.9637 79.4948 84.1444C76.9677 85.325 70.7266 77.2715 65.5548 66.1563C60.383 55.0415 58.2386 45.0732 60.7651 43.8925C63.2922 42.7119 70.9525 50.102 76.1243 61.2172Z"
					fill="#DDDCDC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M64.1113 62.9742L67.0601 69.3108L69.8906 67.9882C71.6365 67.1722 72.3918 65.0922 71.578 63.342C70.7636 61.5924 68.6878 60.835 66.9419 61.6515L64.1113 62.9742Z"
					fill="#F0E44C"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M71.5772 63.3422C70.9676 62.0327 69.6518 61.2791 68.2965 61.325C68.6986 61.6496 69.0359 62.0686 69.2687 62.5695C70.083 64.3197 69.3278 66.3997 67.5813 67.2156L66.3516 67.7904L67.0588 69.311L69.8893 67.9878C71.6358 67.1724 72.3916 65.0923 71.5772 63.3422Z"
					fill="#E2D246"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M68.109 64.9627C67.8652 64.437 67.2434 64.2096 66.7204 64.4545L65.1133 65.2055L65.9957 67.1076L67.6029 66.3566C68.1259 66.1122 68.3528 65.4879 68.109 64.9627Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M44.9072 68.4965L41.6855 70.0023C42.4318 74.1977 44.6216 78.5609 47.2548 81.8992L50.6321 80.3211C51.6697 79.836 52.8631 79.4091 54.144 79.0623C51.305 75.5641 49.0097 70.956 48.2871 66.4967C47.1455 67.3063 45.996 67.9877 44.9072 68.4965Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M45.7691 73.9908C44.7024 73.9908 44.3657 72.485 45.3352 72.0322L46.46 71.5065C46.9735 71.2669 47.5836 71.4891 47.8227 72.0031C48.0618 72.5177 47.8401 73.1289 47.3271 73.3685L46.2019 73.8942C46.0615 73.9602 45.9142 73.9908 45.7691 73.9908Z"
					fill="#424242"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M47.8597 78.1872C46.7882 78.1872 46.4579 76.6809 47.4258 76.2286L48.5505 75.7029C49.0635 75.4633 49.6742 75.6855 49.9133 76.1995C50.1524 76.7141 49.9307 77.3253 49.4177 77.5649L48.2924 78.0906C48.1521 78.1561 48.0048 78.1872 47.8597 78.1872Z"
					fill="#424242"
				/>
			</g>
		</svg>
	)
}

export default EmptySku
