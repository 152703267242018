import { createStore } from 'effector'
import * as RewardsPermissionEvents from './RewardsPermissionEvents'
import { RewardsPermissionInitialState } from './RewardsPermissionState'

const RewardsPermissionStore = createStore(RewardsPermissionInitialState)
	.on(RewardsPermissionEvents.setRewardsData, (state, rewardsData) => ({
		...state,
		rewardsData,
	}))
	.on(RewardsPermissionEvents.setShowErrorForRewardsPermission, (state, showError) => ({
		...state,
		showError,
	}))
	.on(RewardsPermissionEvents.setUpdateDialog, (state, { isShowing, enablePermission, accountId, rowIndex }) => ({
		...state,
		updateDialog: {
			...state.updateDialog,
			isShowing,
			enablePermission,
			accountId,
			rowIndex,
		},
	}))

export default RewardsPermissionStore
