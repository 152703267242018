import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		blackColorText: {
			color: theme.palette.text.primary,
		},
		greyColorText: {
			color: '#DFDEDE',
		},
	}),
)

export default styles
