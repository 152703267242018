import { countryConfig } from '@/utils/constants'
import { getYearOptions } from '@/utils/getYears'
import { getUserCountry } from '@/utils/tokenB2CHelper'
import { Select } from '@hexa-ui/components'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function useRewardsOfferYearOptions() {
	const { t } = useTranslation()
	const countryCode = getUserCountry()
	const { locale } = countryConfig[countryCode] ?? countryConfig.fallback
	const langCode = locale.slice(0, 2)
	const [yearOptions, setYearOptions] = useState<JSX.Element[]>([])

	const createOption = (value: string, children: string) => {
		return (
			<Select.Option key={value} value={value}>
				{children}
			</Select.Option>
		)
	}

	const loadYearOptions = useCallback(() => {
		const placeholderOption = createOption('', t('transaction:REWARDS_OFFER.ENTER_YEAR'))
		const offerYear = getYearOptions(langCode)
		setYearOptions([placeholderOption, ...offerYear])
	}, [])

	useEffect(() => {
		loadYearOptions()
	}, [loadYearOptions])

	return yearOptions
}
