import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

type Props = {
	submitButtonText?: string
	handleSubmitClick: () => void
	submitEnabled?: boolean
}
const FormAction: React.FC<Props> = ({ submitButtonText, handleSubmitClick, submitEnabled }: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	return (
		<Grid container justifyContent="flex-end" className={classes.actionsContainer}>
			<Button
				type="button"
				variant="contained"
				color="primary"
				id="submit-btn"
				className={classes.button}
				onClick={handleSubmitClick}
				disabled={submitEnabled !== undefined ? !submitEnabled : false}
			>
				{submitButtonText ?? t('BUTTON.CREATE')}
			</Button>
		</Grid>
	)
}

export default FormAction
