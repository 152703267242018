import { useEffect, useRef } from 'react'

type IsMountedRefResult = {
	current: boolean
}

export const useIsMountedRef = (): IsMountedRefResult => {
	const isMountedRef = useRef(false)

	useEffect(() => {
		isMountedRef.current = true
		return (): void => {
			isMountedRef.current = false
		}
	}, [])

	return isMountedRef
}
