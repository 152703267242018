import { ChallengeEvents, ChallengeStore } from '@/stores/challengeCreation'
import { ChallengeIdUseCase } from '@/usecase'
import { useStoreMap } from 'effector-react'
import { useCallback } from 'react'

export interface ChallengeIdFromSetupFormDataResult {
	challengeId?: string
	defineAChallengeId: () => void
}

export const useChallengeIdFromSetupFormData = (): ChallengeIdFromSetupFormDataResult => {
	const { challengeId } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => ({
			challengeId: state.challengeSetupFormData?.id,
		}),
	})

	const defineAChallengeId = useCallback((): void => {
		const id = ChallengeIdUseCase.generateId()
		ChallengeEvents.setChallengeId(id)
	}, [])

	return {
		challengeId,
		defineAChallengeId,
	}
}
