import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		actionsContainer: {
			'& > *': {
				marginRight: theme.spacing(1),
				'&:last-child': {
					marginRight: 'unset',
				},
			},
		},
		button: {
			width: '91px',
			height: '40px',
		},
	}),
)

export default styles
