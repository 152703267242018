import { SettingsLevel, SettingsType } from '@/domains/enums/settings'
import { LoadDefaultConfigParams, loadSettings } from '@/services/settings/SettingsService'
import { PricePerPointConfiguration } from '@/stores'
import { DEFAULT_TIER_FOR_PPP } from '@/utils/constants'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'

export const execute = async (): Promise<PricePerPointConfiguration> => {
	const params: LoadDefaultConfigParams = {
		levels: SettingsLevel.VENDOR,
		settingIds: getDefaultVendorIdOfCurrentZone(),
		tiers: [DEFAULT_TIER_FOR_PPP],
		types: SettingsType.DEFAULT_CONFIGURATION,
	}

	const response = await loadSettings<PricePerPointConfiguration>(params)
	const result = response[0] || []
	return result
}
