import Rule from '@/domains/rule/Rule'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { RulesEvents, RulesStore } from '@/stores'
import { LoadSkuUseCase } from '@/usecase'
import { useStoreMap } from 'effector-react'
import { useCallback, useState } from 'react'
import { useRule } from './useRule'

type UseLoadSkuListResult = {
	loadSkuList: () => void
	loading: boolean
	ruleNotFound: boolean
	rule: Rule
}

export const useLoadSkuList = (ruleId: string, tier?: string): UseLoadSkuListResult => {
	const [loading, setLoading] = useState<boolean>(true)
	const [ruleNotFound, setRuleNotFound] = useState<boolean>(false)
	const rule = useRule(ruleId)
	const userInfo = useGetUserInfo()

	const { rulesList } = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => ({
			rulesList: state.rules,
		}),
	})

	const loadSkuListUseCase = useCallback(
		async (ruleParams: Rule): Promise<void> => {
			try {
				const res = await LoadSkuUseCase.execute(ruleParams, tier)

				if (res) {
					RulesEvents.setActiveSkuList(res)
				}
			} catch {
				RulesEvents.setActiveSkuList([])
			}
		},
		[tier],
	)

	const loadSkuList = useCallback(() => {
		setLoading(true)
		if (rulesList) {
			if (rulesList?.length === 0) {
				setLoading(false)
				return
			}
			if (rule) {
				RulesEvents.setRuleId(rule.ruleId)
				RulesEvents.setCategoryId(rule.categoryId)
				AnalyticsService.page(rule.name, userInfo)
				loadSkuListUseCase(rule).finally(() => {
					setLoading(false)
				})
			} else {
				setLoading(false)
				setRuleNotFound(true)
			}
		}
	}, [loadSkuListUseCase, rule, rulesList, userInfo])

	return { loadSkuList, loading, ruleNotFound, rule }
}
