import { makeStyles } from '@material-ui/core/styles'

const checkBoxSize = 24

const styles = makeStyles({
	content: {
		width: '100%',
		height: '90px',
		display: 'flex',
		flexDirection: 'row',
		padding: '20px 20px',
		userSelect: 'none',
		cursor: 'pointer',
		background: 'transparent',
		outline: 'none',
		border: 'none',
		margin: 0,
		textAlign: 'left',
		'&:not(:disabled):hover': {
			backgroundColor: 'whitesmoke',
		},
		'&:not(:disabled):active': {
			backgroundColor: '#fbfbfb',
		},
		'&:disabled': {
			cursor: 'not-allowed',
			opacity: '0.3',
		},
	},
	leftBox: {
		width: '50px',
	},
	rightBox: {
		flex: '1',
	},
	checkbox: {
		width: `${checkBoxSize}px`,
		height: `${checkBoxSize}px`,
		borderRadius: '50%',
		border: '1px solid black',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	checked: {
		width: `${checkBoxSize / 2}px`,
		height: `${checkBoxSize / 2}px`,
		borderRadius: '50%',
		backgroundColor: 'black',
	},
	label: {
		color: 'black',
		fontFamily: 'Barlow',
		fontWeight: 600,
		letterSpacing: 0,
		fontSize: '16px',
		paddingBottom: '8px',
	},
	subLabel: {
		color: 'rgba(0,0,0,0.9)',
		fontFamily: 'Work Sans',
		letterSpacing: '0.1px',
		lineHeight: '20px',
		fontSize: '14px',
	},
})

export default styles
