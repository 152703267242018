import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		searchInput: {
			height: '40px',
		},
		input: {
			'&::placeholder': {
				opacity: 1,
			},
		},
		box: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			'& svg': {
				margin: '2px 0',
			},
		},
	}),
)

export default styles
