import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertDialog } from 'components/core'

type Props = {
	show: boolean
	onConfirm: () => void
	onCancel: (buttonClicked: boolean) => void
	singleDeletion: boolean
}

const RemoveRedeemableDialogView: React.FC<Props> = ({ show, onConfirm, onCancel, singleDeletion }: Props) => {
	const { t } = useTranslation()

	const title = t(`combo:REMOVE_REDEEMABLE_TITLE.${singleDeletion ? 'SINGLE' : 'MULTIPLE'}` as const)
	const message = t(`combo:REMOVE_REDEEMABLE_MESSAGE.${singleDeletion ? 'SINGLE' : 'MULTIPLE'}` as const)

	return (
		<AlertDialog
			title={title}
			show={show}
			dataTestIdBase="remove-combo-dialog"
			handleCancel={onCancel}
			handleConfirm={onConfirm}
			buttonConfirmText={t('BUTTON.REMOVE')}
		>
			{message}
		</AlertDialog>
	)
}

export default RemoveRedeemableDialogView
