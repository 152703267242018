import { CreateRuleFormData } from '@/domains/rule/Rule'
import { useAuthorization } from '@/hooks'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import useAdminAuditLog from '@/hooks/useAdminAuditLog'
import { useAdminAuditLogHook } from '@/hooks/useAuditLogInstance'
import useCurrentVendor from '@/hooks/vendor/useCurrentVendor'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { PageLevelEvents } from '@/stores'
import { generateId } from '@/usecase/challenge/ChallengeIdUseCase'
import { CreateRuleParams, execute } from '@/usecase/settings/CreateNewEarningRules'
import { BASE_ROUTE } from '@/utils/constants'
import * as currencyHelper from '@/utils/currencyHelper'
import i18next from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useNavigateToRules } from '../../../hooks/useNavigateToRules'
import { createEarningRuleButtonEnabled } from '../../utils/createEarningRuleButtonEnabled'
import CreateRuleView from './CreateRuleView'

const CreateRule: React.FC = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const { selectedTierKey } = useCurrentTier()
	const { goToEditSku, goToRules } = useNavigateToRules()
	const country = useCurrentZone()
	const vendor = useCurrentVendor()
	const { isFeatureAuthorized } = useAuthorization()
	const createEarningRuleEnabled = createEarningRuleButtonEnabled({ isFeatureAuthorized })
	const hasRuleId = useParams<{ ruleId: string }>()
	const [isEditing, setIsEditing] = useState(false)
	const userInfo = useGetUserInfo()
	const { auditLogInstance } = useAdminAuditLog()
	const { generateLogForEarningDescription } = useAdminAuditLogHook()

	useEffect(() => {
		if (hasRuleId.ruleId) {
			setIsEditing(true)
		}
	}, [hasRuleId.ruleId])

	const redirect = useCallback(
		({ ruleId, name }) => {
			if (isEditing) {
				PageLevelEvents.showNotification({
					notificationType: 'success',
					message: t('rules:EDIT_EARNING_RULES.EARN_RULE_EDITED'),
				})
				goToRules()
			} else goToEditSku({ ruleId, name })
		},
		[isEditing, goToEditSku, goToRules, t],
	)

	const goToRuleListRoute = useCallback(() => {
		history.push(`${BASE_ROUTE}/rules`)
	}, [history])

	const handleCancel = () => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Cancel',
			button_label: 'Cancel New Earning Rule',
			screen_name: 'Earning Rules',
			...userInfo,
		})
		goToRuleListRoute()
	}

	useEffect(() => {
		if (!createEarningRuleEnabled) {
			goToRuleListRoute()
		}
	}, [createEarningRuleEnabled, goToRuleListRoute])

	const createRuleName = (points: number, amountSpent: number) => {
		return i18next.t('rules:EARNING_NAME', {
			point: points,
			amountSpent: currencyHelper.format(amountSpent),
		})
	}

	const handleSubmit = async (formData: CreateRuleFormData) => {
		const ruleId = hasRuleId.ruleId || generateId()
		const params: CreateRuleParams = { ...formData, selectedTierKey, country, vendorId: vendor!.vendorId, ruleId }
		try {
			await execute(params)
			AnalyticsService.events.earningRuleCreated({
				earning_rule_id: ruleId,
				earning_rule_description: params.description!,
				amount: params.amountSpent as unknown as Record<number, unknown>,
				points: params.points.toString(),
				category_id: params.categoryId,
				screen_name: 'Create new earning rules',
				...userInfo,
			})

			const earningLog = generateLogForEarningDescription({
				formData,
				categoryId: params.categoryId,
				isEditing,
				entityId: ruleId,
			})
			auditLogInstance(earningLog)

			const ruleName = createRuleName(params.points, params.amountSpent)
			redirect({ ruleId, name: ruleName })
		} catch {
			PageLevelEvents.showNotification({
				notificationType: 'error',
				message: t('ERROR_MESSAGE.GENERIC_ERROR'),
			})
		}
	}

	return <CreateRuleView handleCancel={handleCancel} onFormSubmit={handleSubmit} />
}

export default CreateRule
