import { LoadOfferCategoriesConfigurationUseCase } from '@/usecase'
import { Select } from '@hexa-ui/components'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function useRewardsOfferCategoriesOptions() {
	const [categoriesOptions, setCategoriesOptions] = useState<JSX.Element[]>([])
	const { t } = useTranslation()

	const createOption = (value: string, label: string) => {
		return (
			<Select.Option key={value} value={value}>
				{label}
			</Select.Option>
		)
	}

	const loadCategoriesOptions = useCallback(async () => {
		const placeholderOption = createOption('', t('transaction:REWARDS_OFFER.SELECT_CATEGORY'))
		const offerCategories = await LoadOfferCategoriesConfigurationUseCase()
		const newOptions = offerCategories.map((category) => createOption(category.id, category.name))
		setCategoriesOptions([placeholderOption, ...newOptions])
	}, [])

	useEffect(() => {
		loadCategoriesOptions()
	}, [loadCategoriesOptions])

	return categoriesOptions
}
