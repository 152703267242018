import { ChallengeExecutionMethod } from '@/domains/enums'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ChallengeState, ChallengeStore } from '@/stores/challengeCreation'
import { useStoreMap } from 'effector-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useSupportedLanguages from '@/hooks/useSupportedLanguages/useSupportedLanguages'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

export type TrackingPlanProps = {
	challengeCreatedTrackingPlan: (challengeId?: string) => void
	triggerChallengeGoal: (value: string) => void
}

const currentDate = new Date().toLocaleDateString()

function getPurchaseTypeValue(type: string | undefined): string | null {
	if (!type) {
		return null
	}
	return type === 'MultiplePurchases' ? 'multiple_purchase' : 'single_purchase'
}

const useChallengeTrackingPlan = (): TrackingPlanProps => {
	const userInfo = useGetUserInfo()
	const { t } = useTranslation()
	const { defaultLanguage } = useSupportedLanguages() ?? {}

	const { challengeSetupFormData, challengeDetailsFormData, challengeFilterFormData, isIndividualTarget } = useStoreMap(
		{
			store: ChallengeStore,
			keys: [],
			fn: (state: ChallengeState) => ({
				challengeSetupFormData: state.challengeSetupFormData,
				challengeDetailsFormData: state.challengeDetailsFormData,
				challengeFilterFormData: state.challengeFilterFormData,
				isIndividualTarget: !!state.challengeFilterFormData?.individualTarget,
			}),
		},
	)

	const challengeCreatedTrackingPlan = useCallback(
		(challengeId?: string) => {
			const challengeGoal = (): string | null => {
				if (challengeSetupFormData?.executionMethod === ChallengeExecutionMethod.VolumeFixed) return 'Units'
				if (challengeSetupFormData?.executionMethod === ChallengeExecutionMethod.SpendBased) return 'Money'
				return null
			}

			const languages = []
			languages.push(defaultLanguage)

			if (challengeSetupFormData?.translations?.length) {
				challengeSetupFormData.translations.forEach((translation) => {
					languages.push(translation.languageId)
				})
			}

			AnalyticsService.events.challengeCreated({
				challenge_id: challengeSetupFormData?.id ?? challengeId,
				challenge_title: challengeSetupFormData!.title,
				challenge_goal: challengeGoal(),
				challenge_description: challengeSetupFormData!.description,
				campaign_id: challengeSetupFormData!.campaignId ?? null,
				creation_date: currentDate,
				challenge_start_date: challengeFilterFormData!.startDate!,
				challenge_language: languages,
				challenge_end_date: challengeFilterFormData!.endDate!,
				points: challengeDetailsFormData!.points.toString(),
				challenge_type: challengeSetupFormData?.executionMethod,
				cover_image: challengeSetupFormData!.image!.url,
				good_image: challengeSetupFormData?.goodPhoto?.url ?? null,
				bad_image: challengeSetupFormData?.badPhoto?.url ?? null,
				target_type: isIndividualTarget ? 'Individual Target' : 'General Target',
				skus: challengeDetailsFormData?.skus || null,
				poc_filter_type: challengeFilterFormData?.filters[0].filter ? challengeFilterFormData?.filters[0].filter : '',
				poc_filter_value: challengeFilterFormData?.filters[0].value
					? challengeFilterFormData?.filters[0].value.toString()
					: null,
				screen_name: 'Challenge Creation - 3rd Step',
				sku_quantity_max: null,
				sku_quantity_min: null,
				...userInfo,
				purchase_type: getPurchaseTypeValue(challengeDetailsFormData?.purchaseType),
			})
		},
		[
			challengeDetailsFormData,
			challengeFilterFormData,
			challengeSetupFormData,
			isIndividualTarget,
			userInfo,
			defaultLanguage,
		],
	)

	const triggerChallengeGoal = useCallback(
		(value: string) => {
			AnalyticsService.events.radioButtonInteraction({
				radio_button_name: 'Challenge Goal',
				radio_button_label:
					value === 'AmountOfMoney'
						? t('challenge:DETAILS_STEP.CHALLENGE_GOAL.AMOUNT_OF_MONEY')
						: t('challenge:DETAILS_STEP.CHALLENGE_GOAL.AMOUNT_OF_UNITS'),
				form_name: 'Challenge Creation - 3rd Step',
				screen_name: 'Challenge Creation - 3rd Step',
				...userInfo,
			})
		},
		[t, userInfo],
	)

	return { challengeCreatedTrackingPlan, triggerChallengeGoal }
}

export default useChallengeTrackingPlan
