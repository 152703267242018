import { Paragraph } from '@hexa-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from '../StepSingleSkuComponentStyle'

const NotFoundedImage: React.FC = () => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<div className={classes.notFoundSkuContainer}>
			<img
				src="https://cdn-b2b-abi.global.ssl.fastly.net/admin/images/Buzz.png"
				alt="box"
				className={classes.notFoundedImage}
			/>
			<Paragraph weight="medium" style={{ margin: '8px 0' }}>
				{t('rules:NO_RESULTS')}
			</Paragraph>
			<Paragraph className={classes.emptySkuMessage} style={{ fontSize: '14px' }}>
				{t('rules:NO_RESULTS_MESSAGE')}
			</Paragraph>
		</div>
	)
}

export default NotFoundedImage
