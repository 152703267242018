import React from 'react'
import { useHistory } from 'react-router-dom'
import { ChallengeEvents as ChallengeCreationEvents } from '@/stores/challengeCreation'
import { newConvertChallengeHistoryToChallenge } from '@/converters/challenge/ChallengeHistoryToDuplicateChallenge'
import { BASE_ROUTE } from '@/utils/constants'
import { Button } from '@material-ui/core'
import ChallengeHistoryDetail from '@/routes/challenges/model/ChallengeHistoryDetail'
import { useTranslation } from 'react-i18next'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { ButtonInteraction, ScreenName } from '@/domains'

type Props = {
	challenge: ChallengeHistoryDetail
	className?: string
}

const DuplicateButton: React.FC<Props> = ({ challenge, className }: Props) => {
	const history = useHistory()
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()

	const onDuplicate = () => {
		const duplicatedChallenge = newConvertChallengeHistoryToChallenge(challenge)
		ChallengeCreationEvents.duplicateChallenge(duplicatedChallenge)
		history.push(`${BASE_ROUTE}/challenge/setup`)
		AnalyticsService.events.buttonClicked({
			button_name: ButtonInteraction.Duplicate,
			button_label: `${t('challenge:HISTORY_DETAIL_REDESIGN.DUPLICATE_BUTTON')}`,
			screen_name: `${ScreenName.ChallengeDetails} - ${challenge.title} - ${challenge.executionMethod}`,
			...userInfo,
		})
	}

	return (
		<Button
			type="button"
			variant="contained"
			color="primary"
			data-testid="duplicate-challenge"
			onClick={onDuplicate}
			className={className}
		>
			{t('challenge:HISTORY_DETAIL_REDESIGN.DUPLICATE_BUTTON')}
		</Button>
	)
}

export default DuplicateButton
