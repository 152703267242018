/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { TableCell } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { AutoSizer, Column, Table, TableCellProps, TableHeaderProps, WindowScroller } from 'react-virtualized'
import ColumnStyle from './model/ColumnStyle'
import VirtualizedColumnData from './model/VirtualizedColumnData'
import VirtualizedTableConfigs from './model/VirtualizedTableConfigs'
import useStyles from './styles'

interface HeaderRendererProps extends TableHeaderProps, Pick<VirtualizedColumnData, 'headerStyle'> {
	columnIndex: number
}

type VirtualizedTableProps = {
	id: string
	columns: VirtualizedColumnData[]
	data: any[]
	configs?: VirtualizedTableConfigs
	scrollElement: any
}

const VirtualizedTable: React.FC<VirtualizedTableProps> = ({
	id,
	columns,
	data,
	scrollElement,
	configs,
}: VirtualizedTableProps) => {
	const classes = useStyles()

	const headerRenderer = ({ label, columnIndex, headerStyle }: HeaderRendererProps) => {
		const { align } = columns[columnIndex]

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.noClick, {
					[classes.tableCellLeft]: align === 'left',
					[classes.tableCellCenter]: align === 'center',
					[classes.tableCellRight]: align === 'right',
				})}
				variant="head"
				style={{ height: configs?.headerHeight, ...headerStyle }}
				align={align ?? 'left'}
			>
				<span>{label}</span>
			</TableCell>
		)
	}

	const cellRenderer = ({ cellData, columnIndex }: TableCellProps, style?: ColumnStyle): ReactNode => {
		const { align, format } = columns[columnIndex]

		let value = ''

		try {
			value = format?.(cellData) ?? cellData.toString()
		} catch (err) {
			value = ''
		}

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: true,
					[classes.tableCellLeft]: align === 'left',
					[classes.tableCellCenter]: align === 'center',
					[classes.tableCellRight]: align === 'right',
				})}
				variant="body"
				style={{ height: configs?.rowHeight, ...style }}
				align={(columnIndex != null && align) || 'left'}
			>
				{value}
			</TableCell>
		)
	}

	const rowGetter = ({ index }) => data[index] || {}

	const renderColumn = ({ dataKey, style, headerStyle, ...other }, index) => (
		<Column
			key={dataKey}
			headerRenderer={(headerProps) => headerRenderer({ ...headerProps, columnIndex: index, headerStyle })}
			className={classes.flexContainer}
			cellRenderer={(props) => cellRenderer(props, style)}
			dataKey={dataKey}
			flexGrow={1}
			{...other}
		/>
	)

	const renderTable = ({ height, isScrolling, scrollTop, onChildScroll, width }) => (
		<Table
			id={id}
			autoHeight
			isScrolling={isScrolling}
			scrollTop={scrollTop}
			onScroll={onChildScroll}
			height={height}
			width={width}
			gridClassName={classes.grid}
			gridStyle={{ overflowY: 'auto' }}
			headerHeight={configs!.headerHeight!}
			rowHeight={configs!.rowHeight!}
			rowCount={data.length}
			rowGetter={rowGetter}
			rowClassName={classes.flexContainer}
			className={classes.table}
		>
			{columns.map(renderColumn)}
		</Table>
	)

	return (
		<WindowScroller scrollElement={scrollElement || window}>
			{({ height, isScrolling, scrollTop, onChildScroll }) => (
				<AutoSizer disableHeight>
					{({ width }) => renderTable({ height, isScrolling, scrollTop, onChildScroll, width })}
				</AutoSizer>
			)}
		</WindowScroller>
	)
}

export default React.memo(VirtualizedTable)
