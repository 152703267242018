import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useStore } from 'effector-react'
import { $pricePerPointConfigurationStore } from '@/stores'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsRedeemableCreated {
	pricePerPoint: number
	SKU: string
	skuName: string
	screenName: string
}

export const useAnalyticsServiceEventRedeemableCreated = (): {
	sendAnalyticsServiceRedeemableCreated: (recordData: AnalyticsRedeemableCreated) => void
} => {
	const userInfo = useGetUserInfo()
	const { pricePerPoint: defaulPricePerPoint } = useStore($pricePerPointConfigurationStore)

	const sendAnalyticsServiceRedeemableCreated = useCallback(
		({ pricePerPoint, SKU, skuName, screenName }: AnalyticsRedeemableCreated) => {
			const actualPricePerpoint = !pricePerPoint || pricePerPoint === 0 ? defaulPricePerPoint : pricePerPoint
			AnalyticsService.events.redeemableCreated({
				price_per_point: Number(actualPricePerpoint),
				sku: SKU,
				sku_name: skuName,
				screen_name: screenName,
				...userInfo,
			})
		},
		[userInfo, defaulPricePerPoint],
	)

	return { sendAnalyticsServiceRedeemableCreated }
}
