import { Vendor, VendorOption } from '@/domains/vendors'
import * as VendorsService from '@/services/vendors/VendorsService'
import { AcceptedCountry } from '@/utils/acceptedCountries'
import { getVendorEnvKey } from '@/utils/getEnv'

export interface MetaVendor {
	id?: string
	country?: string
	displayName?: string
	serviceModel?: string
	governmentId?: string
	abiVendorId?: string
}

const getVendorByCountry = (country: AcceptedCountry, vendors: Array<MetaVendor>): Array<Vendor> => {
	const vendorsByCountry = vendors.filter((vendor) => vendor.country === country)
	return vendorsByCountry.map((vendor) => ({
		vendorName: vendor.displayName ?? '',
		vendorId: vendor.id ?? '',
	}))
}

export async function execute(
	country: AcceptedCountry,
	vendors: Array<MetaVendor> | undefined,
): Promise<Array<VendorOption>> {
	const vendorsAndPrograms = await VendorsService.load({
		country,
		env: getVendorEnvKey(),
	})

	if (vendors) {
		const vendorsByCountry = getVendorByCountry(country, vendors)

		if (vendorsByCountry.length) {
			const listPrograms = vendorsAndPrograms[0]
			const { programs } = listPrograms

			return vendorsByCountry.map((vendorByContry) => ({ ...vendorByContry, programs }))
		}
	}

	return vendorsAndPrograms
}
