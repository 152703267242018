import { ButtonInteraction, ScreenName } from '@/domains'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { useAnalyticsServiceEventRedeemableCreated } from '@/hooks/analyticsService/useAnalyticsServiceEventRedeemableCreated'
import { useAnalyticsServiceEventTableSearched } from '@/hooks/analyticsService/useAnalyticsServiceEventTableSearched'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { CriticalPricePerPointConfigurationStoreEvents } from '@/stores'
import {
	$createRedeemableItemStore,
	CreateRedeemableItemState,
	CreateRedeemableItemStoreEvents,
} from '@/stores/redeemableItems/CreateRedeemableItemStore'
import { CreateRedeemableItemUseCase } from '@/usecase'
import { MODAL_CHANGE_DELAY } from '@/utils/constants'
import { unformat } from '@/utils/currencyHelper'
import { Alert, Button, Dialog, Heading, SearchField } from '@hexa-ui/components'
import { useStore } from 'effector-react'
import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useAuditLogRedeemables from '../../hooks/useAuditLogRedeemables'

import { SelectedZoneAndVendorChips } from '../SelectedZoneAndVendorChips'
import { CreateRedeemableItemContent } from './content/CreateRedeemableItemContent'
import CreateRedeemableItemFormProvider from './content/Form/CreateRedeemableItemFormProvider'
import useStyles from './CreateRedeemableItemStyle'
import VariantContent from './VariantContent/VariantContent'

type CreateRedeemableItemDialogProps = {
	isOpened: boolean
	trigger?: React.ReactNode
}

export const CreateRedeemableItem: React.FC<CreateRedeemableItemDialogProps> = ({ isOpened, trigger }) => {
	const css = useStyles()
	const { t } = useTranslation()
	const state = useStore($createRedeemableItemStore)
	const userInfo = useGetUserInfo()
	const { sendAnalyticsServiceTableSearched } = useAnalyticsServiceEventTableSearched()

	const onModalOpenChangeHandler = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_label: ButtonInteraction.Cancel,
			button_name: ButtonInteraction.Cancel,
			screen_name: ScreenName.CreateRedeemable,
			...userInfo,
		})
		return isOpened ? CreateRedeemableItemStoreEvents.reset() : CreateRedeemableItemStoreEvents.onOpen()
	}, [isOpened, userInfo])

	return (
		<div className={css.container} data-testid="create-redeemable-item">
			<CreateRedeemableItemFormProvider>
				<Dialog.Root
					closeButton
					actions={<Actions {...state} />}
					title={<Heading size="H3">{t('redeemable:CREATE.TITLE')}</Heading>}
					trigger={trigger}
					open={isOpened}
					onOpenChange={onModalOpenChangeHandler}
				>
					<div className={css.chipsWrapper}>
						<SelectedZoneAndVendorChips />
					</div>
					<SearchField.Root
						onSearch={(value, evt) => {
							evt.preventDefault()
							CreateRedeemableItemUseCase.onSearch(value)
							sendAnalyticsServiceTableSearched({
								searchQuery: value,
								contentType: 'Create Redeemable',
								tableName: ' Create Redeemable - SKU',
								screenName: ScreenName.CreateRedeemable,
							})
						}}
						placeholder={t('redeemable:SEARCH_PLACEHOLDER')}
					/>
					<CreateRedeemableItemContent />
					<VariantContent />
					<Alert
						className={css.alert}
						type="error"
						message={t('ERROR_MESSAGE.GENERIC_ERROR')}
						style={{ display: state.errorOnPublish || state.errorOnItems ? 'flex' : 'none' }}
					/>
				</Dialog.Root>
			</CreateRedeemableItemFormProvider>
		</div>
	)
}

const Actions = ({ item, errorOnPublish, isPublishing, variants }: CreateRedeemableItemState) => {
	const { t } = useTranslation()
	const { sendAuditLogRedeemables } = useAuditLogRedeemables()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()
	const { sendAnalyticsServiceRedeemableCreated } = useAnalyticsServiceEventRedeemableCreated()

	const {
		handleSubmit,
		formState: { isDirty, isValid },
	} = useFormContext()

	const invalidPricePerPoint = isDirty && !isValid

	const onSubmit = useCallback(
		async (pricePerPoint: number) => {
			try {
				await CreateRedeemableItemUseCase.publish({ redeemables: [item as RedeemableItem, ...variants], pricePerPoint })
				if (item) {
					sendAuditLogRedeemables({ pricePerPoint, SKU: item.vendorItemId, operation: IAuditOperations.INSERT })
					sendAnalyticsServiceRedeemableCreated({
						pricePerPoint,
						SKU: item?.vendorItemId,
						skuName: item.itemName,
						screenName: ScreenName.CreateRedeemable,
					})
				}
			} catch (error) {
				if (error instanceof Error) {
					sendAnalyticsServiceEventError({
						failureReason: error.message,
						screenName: ScreenName.CreateRedeemable,
					})
				}
			}
		},
		[item, variants, sendAuditLogRedeemables, sendAnalyticsServiceRedeemableCreated, sendAnalyticsServiceEventError],
	)

	const buttonAction = useCallback(
		({ pricePerPoint }: { pricePerPoint: string }) => {
			const unformattedPricePerPoint: number = unformat(pricePerPoint)
			if (item) CreateRedeemableItemStoreEvents.setItem({ ...item, pricePerPoint: unformattedPricePerPoint })

			setTimeout(
				() =>
					CriticalPricePerPointConfigurationStoreEvents.onOpen({
						submit: () => {
							onSubmit(unformattedPricePerPoint)
						},
						back: () => CreateRedeemableItemStoreEvents.onOpen(),
						close: () => CreateRedeemableItemStoreEvents.onClose(),
						skuPricePerPoint: unformattedPricePerPoint,
					}),
				MODAL_CHANGE_DELAY,
			)
		},
		[item, onSubmit],
	)

	return (
		<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
			<Dialog.Close>
				<Button data-testid="create-redeemable-item-cancel-button" size="medium" variant="secondary">
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="create-redeemable-item-publish-button"
				size="medium"
				variant="primary"
				disabled={!item || invalidPricePerPoint}
				onClick={handleSubmit(buttonAction)}
				isLoading={isPublishing}
			>
				{errorOnPublish ? t('BUTTON.TRY_AGAIN') : t('BUTTON.ADD')}
			</Button>
		</div>
	)
}
