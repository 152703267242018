import SupportedLanguages from '@/domains/configurations/SupportedLanguages'
import { GlobalStore } from '@/stores'
import { useStore } from 'effector-react'

const useSupportedLanguages = (): SupportedLanguages | null => {
	const { supportedLanguages } = useStore(GlobalStore)

	if (supportedLanguages?.supportedLanguages) {
		const arrayWithOutDefaultLanguage = supportedLanguages.supportedLanguages?.filter(
			(language) => language !== supportedLanguages.defaultLanguage,
		)
		const orderedLanguages = [supportedLanguages.defaultLanguage, ...arrayWithOutDefaultLanguage]

		return { supportedLanguages: orderedLanguages, defaultLanguage: supportedLanguages.defaultLanguage }
	}
	return null
}

export default useSupportedLanguages
