import { createStore } from 'effector'
import * as ProgramConfigurationEvents from './ProgramConfigurationEvents'
import ProgramConfigurationState from './ProgramConfigurationState'

const initialState: ProgramConfigurationState = {
	programData: undefined,
	showEditDialog: false,
}

const ProgramConfigurationStore = createStore(initialState)
	.on(ProgramConfigurationEvents.setProgramData, (state, programData) => ({
		...state,
		programData,
	}))
	.on(ProgramConfigurationEvents.setShowEditDialog, (state, showEditDialog) => ({
		...state,
		showEditDialog,
	}))

export default ProgramConfigurationStore
