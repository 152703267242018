import { OptionalTranslate } from '@/domains/Challenge'

export const validateOptionalsTranslations = (optionalTranslations?: OptionalTranslate[]): boolean => {
	const validateTranslation = optionalTranslations?.some(
		(translate) => !translate.title?.length || !translate.description?.length || !translate.languageId?.length,
	)

	return !validateTranslation
}

export const createTranslationsArray = (
	formData: any,
	optionalLanguagesUsed?: string[],
): OptionalTranslate[] | undefined => {
	return optionalLanguagesUsed?.map((lang) => {
		return {
			languageId: lang, // required
			title: formData[`title-${lang}`], // required
			description: formData[`description-${lang}`], // required
			image: formData[`image-${lang}`]?.file ? formData[`image-${lang}`] : undefined,
			goodPhotoSample: formData[`goodPhotoSample-${lang}`]?.file ? formData[`goodPhotoSample-${lang}`] : undefined,
			badPhotoSample: formData[`badPhotoSample-${lang}`]?.file ? formData[`badPhotoSample-${lang}`] : undefined,
		}
	})
}
