import React, { ReactElement } from 'react'

const MemoRemoveIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
	const { fill, height, viewBox } = props
	return (
		<svg
			height={height}
			viewBox={viewBox}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<desc>ABI RemoveIcon.</desc>
			<defs>
				<polygon
					id="path-remove-icon"
					points="15.8333333 5.34166667 14.6583333 4.16666667 10 8.825 5.34166667 4.16666667 4.16666667 5.34166667 8.825 10 4.16666667 14.6583333 5.34166667 15.8333333 10 11.175 14.6583333 15.8333333 15.8333333 14.6583333 11.175 10"
				/>
			</defs>
			<g id="page-remove-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="remove-icon" transform="translate(-561.000000, -407.000000)">
					<g id="remove-icon-close" transform="translate(557.000000, 403.000000)">
						<polygon id="bound-remove-icon" points="0 0 20 0 20 20 0 20" />
						<mask id="mask-remove-icon" fill="white">
							<use xlinkHref="#path-remove-icon" />
						</mask>
						<use id="mask-use-remove-icon" fill="#000000" fillRule="evenodd" xlinkHref="#path-remove-icon" />
						<g id="Icons-/-_Color-/-Disabled" mask="url(#mask-remove-icon)" fill={fill} fillRule="evenodd">
							<rect id="color" x="0" y="0" width="20" height="20" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

const defaultProps: React.SVGProps<SVGSVGElement> = {
	fill: '#000000',
	height: '12px',
	className: '',
	viewBox: '0 0 12 12',
}

MemoRemoveIcon.defaultProps = defaultProps

const RemoveIcon = React.memo(MemoRemoveIcon)
export default RemoveIcon
