import { Paragraph } from '@hexa-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from '../StepSingleSkuComponentStyle'

const EmptyItemImage: React.FC = () => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<div className={classes.emptySkuContainer}>
			<img
				src="https://cdn-b2b-abi.global.ssl.fastly.net/admin/images/box.png"
				alt="box"
				className={classes.emptyGraphic}
			/>
			<Paragraph className={classes.emptySkuMessage}>{t('rules:EMPTY_SKU_CONTAINER')}</Paragraph>
		</div>
	)
}

export default EmptyItemImage
