import { PagedResponse } from '@/domains'
import { IPaginationRequest, ISortableRequest } from '@/domains/PagedResponse'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import axios from '@/utils/axiosInstance'
import { API_URL } from '@/utils/constants'
import { useLogService as logService } from 'admin-portal-shared-services'

const log = logService()

export interface ILoadRedeemableItemsServiceParams extends IPaginationRequest, ISortableRequest {
	vendorId: string
	vendorItemId?: string
}

export type LoadRedeemableItemsResponse = PagedResponse<RedeemableItem>

export async function load(params: ILoadRedeemableItemsServiceParams): Promise<LoadRedeemableItemsResponse> {
	const url = API_URL.REDEEMABLE_ITEMS
	try {
		const { data } = await axios.get(url, { params })
		return data
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		throw error
	}
}
