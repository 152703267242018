import { createStyles, makeStyles } from '@material-ui/core/styles'

const EditComboQuantityDialogStyle = makeStyles(() =>
	createStyles({
		infoText: {
			margin: '4px 0px 8px 0px',
		},
	}),
)

export default EditComboQuantityDialogStyle
