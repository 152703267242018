import { createStyles, makeStyles } from '@material-ui/core/styles'

const EditSkuButtonStyles = makeStyles(() =>
	createStyles({
		editskuButton: {
			position: 'absolute',
			top: '64px',
			right: '24px',
			padding: '0 19px 0 8px',
			fontWeight: 500,
			lineHeight: '24px',

			'& div': {
				marginRight: '8px',
			},

			'& svg': {
				padding: '4px',
			},
		},
	}),
)

export default EditSkuButtonStyles
