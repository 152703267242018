import InputNumber from 'components/hook-form/InputNumber'
import * as Yup from 'yup'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm, yupResolver } from 'admin-membership-hookform-resolvers'
import { fieldNumberValidationRequiredMin1 } from 'utils/validationObjects'
import { Typography } from '@material-ui/core'
import FormDialogTemplate from 'components/templates/FormDialogTemplate'
import EditComboQuantityFormInputs from 'routes/combos/models/EditComboQuantityFormInputs'
import useStyles from './EditComboQuantityDialogStyle'

type Props = {
	show: boolean
	redeemLimit: number
	onSubmit: (values: EditComboQuantityFormInputs) => void
	onCancel: (buttonClicked: boolean) => void
}

const EditComboQuantityDialogView: React.FC<Props> = ({ show, redeemLimit, onSubmit, onCancel }: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const schema = useMemo(
		() =>
			Yup.object().shape({
				redeemLimit: fieldNumberValidationRequiredMin1(),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t],
	)

	const defaultValues = {
		redeemLimit,
	}

	const { handleSubmit, control, errors, formState } = useForm({
		resolver: yupResolver(schema),
		defaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldFocusError: true,
	})

	return (
		<FormDialogTemplate
			title={t('combo:EDIT_QUANTITY_DIALOG_TITLE')}
			showDialog={show}
			dataTestIdBase="edit-quantity-dialog"
			cancelButtonProps={{ handleClick: onCancel }}
			submitButtonProps={{ name: t('combo:SUBMIT_TEXT'), disabled: !formState.isDirty }}
			handleSubmit={handleSubmit(onSubmit)}
		>
			<Typography id="combo-name" className={classes.infoText}>
				{t('combo:EDIT_QUANTITY_DIALOG_MESSAGE')}
			</Typography>
			<Controller
				control={control}
				as={InputNumber}
				id="redeemLimit"
				name="redeemLimit"
				label={t('combo:MAX_QUANTITY')}
				errorText={errors?.redeemLimit?.message}
				defaultValue={redeemLimit}
			/>
		</FormDialogTemplate>
	)
}

export default EditComboQuantityDialogView
