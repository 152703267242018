import { PointsRemovalType, RewardsOfferType, TransactionType } from '@/domains/enums'
import { fieldValidationRequired } from 'utils/validationObjects'
import * as Yup from 'yup'
import i18n from '@/i18n'
import {
	isIdentifyDimensionEnabled,
	isPointsRemovalAndCategorizationEnabled,
} from '@/utils/featureToggle/featureToggleHelper'

const fieldPointsValueValidationRequired = (): Yup.NumberSchema =>
	Yup.number().min(1, i18n.t('ERROR_MESSAGE.INVALID_POINTS_VALUE')).required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))

const fieldPocIdToValidation = (): Yup.StringSchema =>
	Yup.string().when('pocIdFrom', {
		is: (pocIdFrom: string) => !pocIdFrom?.trim().length,
		then: fieldValidationRequired(),
		otherwise: fieldValidationRequired().notOneOf(
			[Yup.ref('pocIdFrom')],
			i18n.t('transaction:POINTS_TRANSFER.VALIDATION.POC.SAME_ORIGIN_DESTINATION'),
		),
	})

/**
 *
 * @param transactionType
 * @param rewardsOfferType
 * @param pointsRemovalType
 * @returns
 */
const getValidationObject = (
	transactionType: TransactionType | null = null,
	rewardsOfferType: RewardsOfferType | null = null,
): Record<string, Yup.Schema<unknown>> => {
	const isDimensionEnabled = isIdentifyDimensionEnabled()
	const isPointsRemovalEnabled = isPointsRemovalAndCategorizationEnabled()

	const pointsValidateMessage =
		transactionType === TransactionType.PointsRemoval
			? 'transaction:POINTS_REMOVAL.ERROR_MESSAGE.TOTAL_POINTS_NEGATIVE'
			: 'transaction:REMOVAL_FOR_SERVICE_UTILITY.ERROR_MESSAGE.TOTAL_POINTS_NEGATIVE'

	switch (transactionType) {
		case TransactionType.RewardsOffer:
			return {
				description: fieldValidationRequired(),
				...(isDimensionEnabled
					? { productCategory: Yup.array().required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD')) }
					: undefined),
				...(rewardsOfferType === RewardsOfferType.SinglePoc ? { points: fieldPointsValueValidationRequired() } : {}),
			}
		case TransactionType.PointsTransferFrom:
			return {
				pocIdFrom: fieldValidationRequired(),
				pocIdTo: fieldPocIdToValidation(),
			}

		case TransactionType.PointsRemoval:
			return {
				description: fieldValidationRequired(),
				...(isPointsRemovalEnabled
					? {
							pocId: Yup.mixed().when('pointsRemovalType', {
								is: PointsRemovalType.SinglePoc,
								then: fieldPocIdToValidation(),
								otherwise: Yup.mixed().notRequired(),
							}),
							points: Yup.mixed().when('pointsRemovalType', {
								is: (pointsRemovalType) => pointsRemovalType === PointsRemovalType.SinglePoc,
								then: Yup.number().when(['currentPoints'], {
									is: (currentPoints) => !currentPoints,
									then: fieldPointsValueValidationRequired(),
									otherwise: fieldPointsValueValidationRequired().max(
										Yup.ref('currentPoints'),
										i18n.t(pointsValidateMessage),
									),
								}),
								otherwise: Yup.mixed().notRequired(),
							}),
							accounts: Yup.mixed().when('pointsRemovalType', {
								is: (pointsRemovalType) => {
									return pointsRemovalType === PointsRemovalType.MultiplePoc
								},
								then: () => {
									return Yup.array()
										.of(
											Yup.object().shape({
												poc_id: Yup.string(),
												points: Yup.number(),
											}),
										)
										.required()
								},
								otherwise: () => {
									return Yup.mixed().notRequired()
								},
							}),
					  }
					: {
							pocId: fieldPocIdToValidation(),
							points: Yup.number().when(['currentPoints'], {
								is: (currentPoints) => !currentPoints,
								then: fieldPointsValueValidationRequired(),
								otherwise: fieldPointsValueValidationRequired().max(
									Yup.ref('currentPoints'),
									i18n.t(pointsValidateMessage),
								),
							}),
					  }),
			}

		case TransactionType.ServiceUtility:
			return {
				category: fieldValidationRequired(),
				description: fieldValidationRequired(),
				pocId: fieldPocIdToValidation(),
				points: Yup.number().when(['currentPoints'], {
					is: (currentPoints) => !currentPoints,
					then: fieldPointsValueValidationRequired(),
					otherwise: fieldPointsValueValidationRequired().max(Yup.ref('currentPoints'), i18n.t(pointsValidateMessage)),
				}),
			}
		default:
			return {
				transactionType: fieldValidationRequired(),
			}
	}
}

export { getValidationObject }
