import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChallengeDetailsFormData, ChallengeState, ChallengeStore } from '@/stores/challengeCreation'
import { useStoreMap } from 'effector-react'
import { getChallengeDetailsValidationObject } from './validationObject'

type Props = {
	children: React.ReactNode
}

const ChallengeFormDetailsProvider: React.FC<Props> = ({ children }: Props) => {
	const { executionMethod, isIndividualTarget } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state: ChallengeState) => {
			return {
				executionMethod: state.challengeSetupFormData?.executionMethod ?? null,
				isIndividualTarget: state.challengeFilterFormData?.individualTarget,
			}
		},
	})

	const formMethods = useForm<ChallengeDetailsFormData>({
		resolver: yupResolver(
			getChallengeDetailsValidationObject({
				executionMethod: executionMethod!,
				isIndividualTarget,
			}),
		),
		mode: 'all',
		shouldFocusError: false,
	})

	return <FormProvider {...formMethods}>{children}</FormProvider>
}

export default ChallengeFormDetailsProvider
