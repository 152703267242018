import { useLogService as logService } from 'admin-portal-shared-services'
import ImageUploadResponse from 'services/models/ImageUploadResponse'
import axios from '@/utils/axiosInstance'

const endPoint = `/v1/files/upload`
const log = logService()

function upload(file: File): Promise<ImageUploadResponse> {
	const fileName = file.name.replace(/[^0-9a-zA-Z.-]/g, '')
	const formData = new FormData()
	formData.append('file', file, fileName)

	return axios
		.post<ImageUploadResponse>(`${endPoint}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				purpose: 'rewards-admin',
				title: fileName,
				linkExpirationTime: -1,
			},
		})
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${endPoint}`, error)
			throw error
		})
}

export { upload }
