import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		description: {
			margin: 'auto auto',
			paddingBottom: '4px',
			fontSize: '12px',
			lineHeight: '16px',
		},
		error: {
			color: theme.palette.error.main,
		},
		disabled: {
			color: theme.palette.text.disabled,
			pointerEvents: 'none',
		},
		label: {
			padding: (props: { padding: string | undefined }) => (props.padding ? props.padding : undefined),
			fontSize: '14px',
			fontWeight: 500,
			lineHeight: '20px',
			letterSpacing: 0,
			marginBottom: '4px',
		},
		helperText: {
			fontSize: '12px',
			letterSpacing: '0',
			lineHeight: '14px',
			marginTop: '4px',
		},
	}),
)

export default styles
