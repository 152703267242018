import { Card, EmptyState, Grid, LoadingBuzz } from '@hexa-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CreateRedeemableItem } from '../../../CreateRedeemableItem/CreateRedeemableItem'
import { RedeemableItemsSettingsPanel } from '../../../RedeemableItemsSettingsPanel/RedeemableItemsSettingsPanel'
import { ViewState } from '../../RedeemableItemsListViewState'
import RedeemableItemsEmptyStateStyle from './RedeemableItemsEmptyStateStyle'
import { useRedeemableItemsEmptyStateFlow } from './useRedeemableItemsEmptyStateFlow'

type RedeemableItemsEmptyStateProps = {
	viewState: ViewState
}

export const RedeemableItemsEmptyState = ({ viewState }: RedeemableItemsEmptyStateProps) => {
	const { isCreateModalOpen, onCreateNewButtonClicked } = useRedeemableItemsEmptyStateFlow()

	const isLoading = viewState === ViewState.LOADING

	const { t } = useTranslation()
	const css = RedeemableItemsEmptyStateStyle()

	return (
		<Card border="large" elevated="small" className={css.mainWrapper}>
			{isLoading ? (
				<Grid.Item className={css.emptyContainer}>
					<LoadingBuzz size="large" data-testid="redeemable-items-empty-state-loading" />
				</Grid.Item>
			) : (
				<>
					<RedeemableItemsSettingsPanel />
					<Grid.Item className={css.emptyContainer} data-testid="redeemable-items-empty-state-content">
						<CreateRedeemableItem isOpened={isCreateModalOpen} />
						<Grid.Item className={css.pageLevelWrapper}>
							<EmptyState.PageLevel
								ilustrationName="beesProductLevel"
								title={t('redeemable:EMPTY_STATE.TITLE')}
								description={t('redeemable:EMPTY_STATE.DESCRIPTION')}
								actions={[
									{
										name: t('redeemable:BUTTON.CREATE_NEW'),
										variant: 'primary',
										action: onCreateNewButtonClicked,
									},
								]}
							/>
						</Grid.Item>
					</Grid.Item>
				</>
			)}
		</Card>
	)
}
