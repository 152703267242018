import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreMap } from 'effector-react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TransparencyTransaction } from '@/domains'
import { ComboEvents, ComboStore } from '@/stores'
import { LoadTransparencyTransactionsUseCase } from '@/usecase'
import * as FileHelper from '@/utils/fileHelper'
import * as DateHelper from '@/utils/date'
import converter from '@/converters/transaction/TransparencyTransactionToTransparencyTransactionWithError'
import ReviewItemsDialogView from './ReviewItemsDialogView'

dayjs.extend(utc)

const ReviewItemsDialog: React.FC = () => {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)
	const [transactions, setTransactions] = useState<TransparencyTransaction[]>([])

	const selectedTransparencyId = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => state.selectedTransparencyId,
	})

	const handleClose = () => ComboEvents.selectTransparencyId(null)

	const handleDownloadCsv = () => {
		const columns = ['redeemableId', 'points']
		const headers = ['ID', 'POINTS']
		const fileCsvString = FileHelper.unParse(transactions, columns, headers)
		const fileDate = dayjs().utcOffset(0, true).toDate()
		const dateNowString = DateHelper.formatDate(fileDate, t('FORMAT_DATE.LONG_FILE'))
		FileHelper.saveAs(fileCsvString, `${selectedTransparencyId}-${dateNowString}.csv`, {
			type: FileHelper.TEXT_CSV_TYPE,
		})
	}

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (selectedTransparencyId) {
			LoadTransparencyTransactionsUseCase.execute(selectedTransparencyId).then((data) => {
				setTransactions(converter(data))
				setOpen(true)
			})
		} else {
			setOpen(false)
			return () => {
				setTransactions([])
			}
		}
	}, [selectedTransparencyId])

	return (
		<ReviewItemsDialogView
			open={open}
			transactions={transactions}
			onClose={handleClose}
			onDownloadCsv={handleDownloadCsv}
		/>
	)
}

export default ReviewItemsDialog
