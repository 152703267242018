import InputSelectHexa from '@/components/formik/InputSelectHexa'
import useRewardsOfferTypeList from '@/hooks/transaction/useRewardsOfferTypeList'
import { isIdentifyDimensionEnabled, isIdentifySkuEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { Select } from '@hexa-ui/components'
import { Box, Grid, Grow } from '@material-ui/core'
import FieldErrorMessage from 'components/formik/FieldErrorMessage'
import FileUpload, { AcceptableTypes } from 'components/formik/FileUpload'
import { FileUploadParseTo } from 'components/formik/FileUpload/model/FileUploadParseTo'
import InputLabel from 'components/formik/InputLabel'
import InputRadioGroup from 'components/formik/InputRadioGroup'
import InputText from 'components/formik/InputText'
import InputTextArea from 'components/formik/InputTextArea'
import RewardsOfferType, { ProductCategory } from 'domains/enums/RewardsOfferTypeEnum'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PocValidationParams from '../../model/PocValidationParams'
import PocIdField from '../PocIdField/PocIdField'
import { useRewardsOfferCategoriesOptions } from '../TransactionForm/hooks/useRewardsOfferCategoriesOptions'
import { useRewardsOfferMonthOptions } from '../TransactionForm/hooks/useRewardsOfferMonthOptions'
import { useRewardsOfferYearOptions } from '../TransactionForm/hooks/useRewardsOfferYearOptions'
import rewardsOfferStyle from './RewardsOfferStyle'

import SkusTableWithModal from './components/SkusTableWithModal/SkusTableWithModal'
import { CategorySelectContainer, DateSelectContainer } from './styles'

type Props = {
	selectProductCategoryValue: string[]
	showError: boolean
	rewardsTypeSelected: RewardsOfferType | null
	multiplePocsFileParseTo: Array<FileUploadParseTo>
	multiplePocsFileError?: string
	onPocValidation: (params: PocValidationParams) => Promise<string>
	handleRewardsOfferChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
	handleMultiplePocsFileParse?: (results: Papa.ParseResult<unknown>) => { errors?: Array<string> }
	validateFile: (files: File[]) => string
	showMultiplePocsFields: boolean
	onError?: (id: string, message: string) => void
}

const RewardsOfferView: React.FC<Props> = ({
	selectProductCategoryValue,
	showError,
	rewardsTypeSelected,
	multiplePocsFileParseTo,
	multiplePocsFileError,
	onPocValidation,
	handleRewardsOfferChange,
	handleMultiplePocsFileParse,
	validateFile,
	showMultiplePocsFields,
	onError,
}) => {
	const { setFieldValue } = useFormikContext()
	const { t } = useTranslation()
	const classes = rewardsOfferStyle()
	const rewardsOfferOptions = useRewardsOfferTypeList()
	const monthsNames = useRewardsOfferMonthOptions()
	const years = useRewardsOfferYearOptions()
	const categoriesOptions = useRewardsOfferCategoriesOptions()
	const [pocIdField, setPocIdField] = useState('')

	const PRODUCT_CATEGORIES = [
		{ label: 'Beer', value: ProductCategory.Beer },
		{ label: 'Nab', value: ProductCategory.Nab },
		{ label: 'Marketplace', value: ProductCategory.Marketplace },
	]
	const isDimensionEnabled = isIdentifyDimensionEnabled()
	const isSkuEnabled = isIdentifySkuEnabled()

	return (
		<>
			{showMultiplePocsFields && (
				<Box pt={2} pb={1}>
					{categoriesOptions.length > 1 && (
						<CategorySelectContainer data-testid="Category container">
							<InputSelectHexa
								id="rewards-offer-category"
								label={t('transaction:REWARDS_OFFER.CATEGORY_LABEL')}
								name="category"
								placeholder={t('transaction:REWARDS_OFFER.SELECT_CATEGORY')}
								dataTestId="rewards-offer-category"
							>
								{categoriesOptions}
							</InputSelectHexa>
						</CategorySelectContainer>
					)}
					{!!rewardsTypeSelected && (
						<Grow in>
							<div>
								<InputTextArea
									id="rewards-offer-description"
									name="description"
									label={t('transaction:REWARDS_OFFER.DESCRIPTION')}
									showError={showError}
									onError={onError}
									hasTooltip
									tooltipTextKey="transaction:REWARDS_OFFER.TOOLTIP.DESCRIPTION"
									setPocIdField={setPocIdField}
								/>
							</div>
						</Grow>
					)}
					{categoriesOptions.length > 1 && (
						<DateSelectContainer>
							<div>
								<InputSelectHexa
									id="rewards-offer-month"
									name="referenceMonth"
									placeholder={t('transaction:REWARDS_OFFER.SELECT_MONTH')}
									label={t('transaction:REWARDS_OFFER.REFERENCE_MONTH')}
									dataTestId="rewards-offer-month"
								>
									{monthsNames}
								</InputSelectHexa>
							</div>
							<div>
								<InputSelectHexa
									id="rewards-offer-year"
									name="referenceYear"
									label={t('transaction:REWARDS_OFFER.REFERENCE_YEAR')}
									placeholder={t('transaction:REWARDS_OFFER.ENTER_YEAR')}
									dataTestId="rewards-offer-year"
								>
									{years}
								</InputSelectHexa>
							</div>
						</DateSelectContainer>
					)}

					<InputLabel label={t('transaction:REWARDS_OFFER.SELECT_POC')} htmlFor="rewards-offer-type" />
					{multiplePocsFileError && (
						<Box mt={2} mb={1}>
							<FieldErrorMessage id="rewards-offer-type-error" message={multiplePocsFileError} onShow={onError} />
						</Box>
					)}

					<Grid container alignItems="flex-start">
						<InputRadioGroup
							id="rewards-offer-type"
							name="rewardsOfferType"
							options={rewardsOfferOptions}
							hasContainerBox={false}
							showError={false}
							onChange={handleRewardsOfferChange}
						/>

						{rewardsTypeSelected === RewardsOfferType.MultiplePoc && (
							<Grow in>
								<div>
									<FileUpload
										id="upload-csv-button-multiple"
										name="multiplePocsFile"
										buttonText={t('BUTTON.UPLOAD_CSV')}
										fileType={AcceptableTypes.CSV}
										multiple={false}
										buttonType="secondary"
										onParse={handleMultiplePocsFileParse}
										parseTo={{ map: multiplePocsFileParseTo, name: 'multiplePocsFileConverted' }}
										showError={false}
										className={classes.fileUpload}
										validate={validateFile}
									/>
								</div>
							</Grow>
						)}
					</Grid>
				</Box>
			)}
			{rewardsTypeSelected === RewardsOfferType.SinglePoc && (
				<Grow in>
					<div>
						<Grid container spacing={1} className={classes.fieldGridContainer}>
							<Grid item xs={8} className={classes.fieldGridItem}>
								<PocIdField
									id="rewards-offer-poc-id"
									name="pocId"
									label={t('transaction:REWARDS_OFFER.POC_ID')}
									onPocValidation={onPocValidation}
									showError={showError}
									onError={onError}
									setPocIdField={setPocIdField}
									pocIdField={pocIdField}
								/>
							</Grid>
							<Grid item xs={4} className={classes.fieldGridItem}>
								<InputText
									id="rewards-offer-points"
									name="points"
									type="number"
									label={t('transaction:REWARDS_OFFER.POINTS_VALUE')}
									showError={showError}
									onError={onError}
									setPocIdField={setPocIdField}
								/>
							</Grid>
						</Grid>
						<InputText
							id="rewards-offer-campaign-id"
							name="campaignId"
							label={t('transaction:REWARDS_OFFER.CAMPAIGN_ID')}
							showError={showError}
							optionalLabel={t('OPTIONAL_FIELD')}
							helperText={t('OPTIONAL_FIELD_HELPER_TEXT')}
							onError={onError}
							setPocIdField={setPocIdField}
						/>
					</div>
				</Grow>
			)}

			{isDimensionEnabled && (
				<Grow in>
					<div>
						<Select.Root
							name="productCategory"
							multiple
							displayText={t('transaction:REWARDS_OFFER.CATEGORY_SELECTED_PLURAL')}
							label={t('transaction:REWARDS_OFFER.PRODUCT_CATEGORY')}
							placeholder={t('transaction:REWARDS_OFFER.SELECT_PRODUCT_CATEGORY')}
							onChange={(value) => setFieldValue('productCategory', value)}
							value={selectProductCategoryValue}
							data-testid="select-category"
						>
							{PRODUCT_CATEGORIES.map((category) => (
								<Select.Option key={category.label} value={category.value}>
									{category.value}
								</Select.Option>
							))}
						</Select.Root>
					</div>
				</Grow>
			)}

			{isSkuEnabled && <SkusTableWithModal />}
		</>
	)
}

export default React.memo(RewardsOfferView)
