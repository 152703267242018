import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const challengeHistoryDetailStyle = makeStyles((theme: Theme) =>
	createStyles({
		dataContainer: {
			margin: '32px 0 0 0',
		},
		paper: {
			padding: '32px',
		},
		container: {
			paddingTop: '1px',
		},
		subContainer: {
			padding: 0,
			paddingBottom: theme.spacing(3),
			marginBottom: theme.spacing(3),
			boxShadow: 'inset 0 -1px 0 0 #DFDEDE',
		},
		label: {
			fontWeight: 500,
		},
		notFound: {
			background: '#F0ECFC',
			marginTop: '24px',
		},
		challengeNotFound: {
			lineHeight: '16px',
			fontWeight: 400,
		},
		notificationBox: {
			'& > div': {
				margin: '32px 0 -16px 0',
			},
		},
		editLink: {
			fontSize: '14px',
			fontWeight: 500,
			color: theme.palette.info.main,
			textDecoration: 'underline',
			'&:hover': {
				cursor: 'pointer',
			},
			textAlign: 'right',
		},
		inactiveEditLink: {
			color: theme.palette.text.disabled,
			cursor: 'unset !important',
		},
		item: {
			paddingLeft: 0,
			paddingBottom: theme.spacing(3),
		},
	}),
)

export default challengeHistoryDetailStyle
