import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		pageEnvironmentInfo: {
			fontSize: '14px',
			lineHeight: '20px',
			position: 'relative',
			paddingLeft: '10px',
		},
	}),
)

export default styles
