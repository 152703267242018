import { createStyles, makeStyles } from '@material-ui/core/styles'

const ContainerStyle = makeStyles(() =>
	createStyles({
		title: {
			marginTop: '39px',
			marginBottom: '43px',
		},
	}),
)

export default ContainerStyle
