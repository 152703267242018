import { isToggleEnabled } from '@/hooks'
import { $pricePerPointConfigurationStore } from '@/stores'
import * as FeatureToggle from '@/utils/featureToggle'

export const isToggleMultivendorRedeemablesByVendorEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.DEV_MULTIVENDOR_REDEEMABLES_BY_VENDOR)
}

export const isToggleMultivendorRewardsOfferBulkEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.DEV_MULTIVENDOR_REWARDSOFFER_BULK)
}

export const isToggleSelectTiersEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.SELECT_TIERS)
}

export const isToggleEarningRulesByVendorEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.DEV_MULTIVENDOR_EARNING_RULES_BY_VENDOR)
}

export const isToggleMultivendorValidateAccountEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.DEV_MULTIVENDOR_VALIDATE_ACCOUNT)
}

export const isCombosMultivendorEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.DEV_MULTI_VENDOR_COMBOS)
}

export const isToggleLoadGroupName = (): boolean => {
	return isToggleEnabled(FeatureToggle.CHALLENGE_LOAD_GROUP_NAME)
}

export const isToggleConfigByTier = (): boolean => {
	return isToggleEnabled(FeatureToggle.CONFIG_INITIAL_BALANCE_REDEEM_LIMIT_BY_TIER)
}

export const isToggledCreateEarningRulesEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.CREATE_EARNING_RULES)
}

export const isToggleSettingsConfigByZoneEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.SETTINGS_CONFIG_BY_ZONE)
}

export const isToggleCombos3PDIntegrationEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.COMBOS_3PD_INTEGRATION)
}
export const isTogglePurchaseChallengesEnabled = (): boolean => isToggleEnabled(FeatureToggle.PURCHASE_CHALLENGES)

export const isTogglePurchaseChallengeMultipleEnabled = (): boolean =>
	isToggleEnabled(FeatureToggle.PURCHASE_CHALLENGES_MULTIPLE)

export const isTogglePhotoChallengeEnabled = (): boolean => isToggleEnabled(FeatureToggle.PHOTO_CHALLENGES)

export const isToggleMarkCompleteChallengeEnabled = (): boolean =>
	isToggleEnabled(FeatureToggle.MARK_COMPLETE_CHALLENGES)

export const isToggleVolumeChallengeEnabled = (): boolean => isToggleEnabled(FeatureToggle.VOLUME_CHALLENGES)

export const isToggleRedeemableItemsEnabled = (): boolean => {
	const { pricePerPointEnabled } = $pricePerPointConfigurationStore.getState()
	const isPricePerPointVendorSettingsEnabled = !!pricePerPointEnabled
	const isRedeemableItemsToggleEnabled = !!isToggleEnabled(FeatureToggle.REDEEMABLE_ITEMS_MODULE)

	return isRedeemableItemsToggleEnabled && isPricePerPointVendorSettingsEnabled
}

export const isToggleRedeemableCostPerPointPermission = (): boolean => {
	return isToggleEnabled(FeatureToggle.REDEEMABLE_COST_PER_POINT_PERMISSION)
}

export const isTogglePricePerPointSettingsEnabled = (): boolean => {
	return isToggleRedeemableItemsEnabled() && isToggleEnabled(FeatureToggle.PRICE_PER_POINT_SETTINGS)
}

export const isToggleRedeemableItemsSortByDisabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.REDEEMABLE_ITEMS_SORTBY_DISABLED)
}

export const isToggleRefundRedeemableItemsEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.CREATE_REFUND_REDEEMABLES)
}

export const isToggleSpendBasedChallengeEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.SPEND_BASED_CHALLENGE)
}

export const isToggleSkuCopyPasteEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.COPY_PASTE_SKU)
}

export const isToggleSyncRedeemableEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.REDEEMABLE_SYNC_3PD)
}

export const isToggleChallengeVisionId = (): boolean => {
	return isToggleEnabled(FeatureToggle.DEV_CHALLENGE_VISIONID)
}

export const isToggleVolumeFixedSingle = (): boolean => {
	return isToggleEnabled(FeatureToggle.VOLUME_FIXED_SINGLE)
}

export const isIdentifyDimensionEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.IDENTIFY_DIMENSION)
}

export const isIdentifySkuEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.IDENTIFY_SKU)
}

export const isPointsRemovalAndCategorizationEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.BULK_POINTS_REMOVAL)
}

export const isNewEditRedeemableItemContentEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.NEW_REDEEMABLE_LOGS)
}

export const isNewTransactionsLogsEnabled = (): boolean => {
	return isToggleEnabled(FeatureToggle.NEW_TRANSACTION_LOGS)
}
