import React from 'react'
import { FormHelperText } from '@material-ui/core'
import { AlertOctagon } from '@hexa-ui/icons'
import styles from './styles'

type Props = {
	id: string
	message?: string
}

const FieldErrorMessage: React.FC<Props> = ({ id, message }: Props) => {
	const classes = styles()

	return (
		<div className={classes.errorContainer}>
			<AlertOctagon size="tiny" style={{ color: 'red' }} />
			<FormHelperText error className={classes.helperTextError} id={`${id}-error-message`}>
				{message}
			</FormHelperText>
		</div>
	)
}

export default FieldErrorMessage
