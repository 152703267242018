import { createStyles, makeStyles } from '@material-ui/core/styles'

const RuleNameColumnStyle = makeStyles(() =>
	createStyles({
		ruleName: {
			fontSize: '14px',
			fontWeight: 600,
			lineHeight: '20px',
			letterSpacing: '0.1px',
		},
		ruleDescription: {
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.1px',
		},
	}),
)

export default RuleNameColumnStyle
