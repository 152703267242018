import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Switch, Route } from 'react-router-dom'
import { CHALLENGE_BASE_ROUTE } from 'utils/constants'
import { BreadCrumbLink } from '@/components/core'
import { ChallengeStore } from '@/stores/challengeCreation'
import { useStoreMap } from 'effector-react'
import ChallengeSubmitDialog from '../ChallengeSubmitDialog/ChallengeSubmitDialog'
import Container from '../Container/Container'
import StepsHeader from '../StepsHeader/StepsHeader'
import ChallengeFormSetup from '../ChallengeFormSetup/ChallengeFormSetup'
import ChallengeFormFilter from '../ChallengeFormFilter/ChallengeFormFilter'
import ChallengeFormDetails from '../ChallengeFormDetails/ChallengeFormDetails'

type Props = {
	onClickBreadcrumbBack: () => void
}

const ChallengeFormView: React.FC<Props> = ({ onClickBreadcrumbBack }) => {
	const { t } = useTranslation()
	const { isStepOneOK, isStepTwoOK } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => ({
			isStepOneOK: !!state.challengeSetupFormData,
			isStepTwoOK: !!state.challengeFilterFormData,
		}),
	})

	return (
		<>
			<BreadCrumbLink displayName={t('challenge:HISTORY.TITLE_VIEW')} onBack={onClickBreadcrumbBack} />
			<Container>
				<StepsHeader />
				<ChallengeSubmitDialog />
				<form>
					<Switch>
						<Redirect exact path={CHALLENGE_BASE_ROUTE} to={`${CHALLENGE_BASE_ROUTE}/setup`} />
						<Route path={`${CHALLENGE_BASE_ROUTE}/setup`} component={ChallengeFormSetup} />
						{isStepOneOK && <Route path={`${CHALLENGE_BASE_ROUTE}/filter`} component={ChallengeFormFilter} />}
						{isStepOneOK && isStepTwoOK && (
							<Route path={`${CHALLENGE_BASE_ROUTE}/details`} component={ChallengeFormDetails} />
						)}
						<Redirect exact path={`${CHALLENGE_BASE_ROUTE}/*`} to={`${CHALLENGE_BASE_ROUTE}/setup`} />
					</Switch>
				</form>
			</Container>
		</>
	)
}

export default ChallengeFormView
