import { comboRefund } from '@/domains/combo/Combo'

/* eslint-disable camelcase */
type normalizeCombosForAuditLogResult = {
	combos: string
	pointsAdjustment: string
}

export const normalizeCombosForAuditLog = (combos: Array<comboRefund>): normalizeCombosForAuditLogResult => {
	let pointsAdjustment = 0
	const normalizedCombos = combos
		.map((combo) => {
			const { checked, points, quantity, id } = combo
			if (checked) {
				pointsAdjustment += points * quantity
				return {
					refund_combo_id: id,
					refund_combo_points: points,
					refund_combo_quantity: quantity,
				}
			}
			return undefined
		})
		.filter((value) => value !== undefined)

	return {
		combos: JSON.stringify(normalizedCombos),
		pointsAdjustment: pointsAdjustment.toString(),
	}
}
