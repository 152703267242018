import React, { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ChallengeFilterFormData, emptyFilterFormData } from '@/stores/challengeCreation'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { hasElements } from '@/utils/array'
import BooleanMap from '@/domains/BooleanMap'
import { isNonValue } from '@/utils/object'
import { isEqualToVendorIds, removeVendorIdsFromFilters } from '@/utils/challengeFilterUtil'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import useChallengeFilterList, { TypeFilterList } from '@/hooks/challenge/useChallengeFilterList'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import FilterBox from '../FilterBox/FilterBox'
import FormActions from '../FormActions/FormActions'
import AccountGroupFormModal from '../AccountGroupFormModal/AccountGroupFormModal'
import ChallengeFormWrapper from '../ChallengeFormWrapper/ChallengeFormWrapper'
import ChallengeFormFilterAddButton from './ChallengeFormFilterAddButton'
import ChallengeFormFilterBoxPaper from './ChallengeFormFilterBoxPaper'
import ChallengeFormFilterTarget from './ChallengeFormFilterTarget'
import ChallengeFormFilterStartDate from './ChallengeFormFilterStartDate'
import ChallengeFormFilterEndDate from './ChallengeFormFilterEndDate'
import SelectUseFilter from './SelectUseFilter'
import hasValidFilter from './ChallengeFormFilterHelper/hasValidFilter'

export type ChallengeFormFilterDisabledFields = 'startDate' | 'filter'

const emptyFilter = {
	...emptyFilterFormData,
}

export const groupFilter = { filter: 'groupIds', value: [] }

type Props = {
	onFormSubmit?: (formData: ChallengeFilterFormData) => void
	onClickBackButton?: () => void
	challengeFilterFormData?: ChallengeFilterFormData
	isEditing?: boolean
	isActiveChallenge?: boolean
	disableFields?: Array<ChallengeFormFilterDisabledFields>
	isTargetEnabled?: boolean
}

const ChallengeFormFilterView: React.FC<Props> = ({
	onFormSubmit,
	onClickBackButton,
	challengeFilterFormData,
	isEditing,
	isActiveChallenge,
	disableFields,
	isTargetEnabled,
}: Props) => {
	const challengeFilterList = useChallengeFilterList()
	const [disabledFiltersMap, setDisabledFiltersMap] = useState<BooleanMap>({})
	const [showFilterBox, setShowFilterBox] = useState<boolean>(false)
	const {
		control,
		watch,
		setValue,
		handleSubmit,
		reset,
		trigger,
		formState: { isValid },
	} = useFormContext<ChallengeFilterFormData>()

	const { fields, append } = useFieldArray<ChallengeFilterFormData>({
		control,
		name: 'filters',
	})
	const userInfo = useGetUserInfo()
	const filtersFormData = watch('filters')
	const individualTargetFormData = watch('individualTarget')
	const isFilterDisabled =
		disableFields?.includes('filter') || (isTargetEnabled && isNonValue(individualTargetFormData))
	const isStartDateDisabled = disableFields?.includes('startDate')
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const isAddButtonDisabled = useMemo(() => {
		const selectedFilters = removeVendorIdsFromFilters(filtersFormData)
		if (!hasElements(selectedFilters)) {
			return false
		}
		const lastItem = selectedFilters[selectedFilters.length - 1]
		return selectedFilters.length > 3 || !lastItem?.filter
	}, [filtersFormData])

	const filterList = useMemo(
		() =>
			challengeFilterList.map((option: TypeFilterList) => ({
				...option,
				disabled: !!disabledFiltersMap[option.value],
			})),
		[challengeFilterList, disabledFiltersMap],
	)

	const disableFilterOption = (): void => {
		if (!hasElements(filtersFormData)) {
			setDisabledFiltersMap({})
		}
		const map = filtersFormData.reduce(
			(prevMap, formFilter) => ({
				...prevMap,
				[formFilter.filter]: true,
			}),
			{},
		)
		setDisabledFiltersMap(map)
	}

	const enableFilterOption = (deletedFilter?: string): void => {
		if (deletedFilter) {
			setDisabledFiltersMap((prevMap) => ({
				...prevMap,
				[deletedFilter]: false,
			}))
		} else {
			setDisabledFiltersMap({})
		}
	}
	const isFilterEditGroupVisible = () => {
		if (isEditing && individualTargetFormData) {
			return false
		}
		return true
	}

	const handleRemoveLinkClick = (): void => {
		setValue('filters', [emptyFilter], { shouldDirty: true })
		enableFilterOption(filtersFormData[0]?.filter)
		AnalyticsService.events.linkClicked({
			link_label: 'Remove',
			link_name: 'Remove Group',
			screen_name: 'Challenge Creation - Step 2',
			...userInfo,
		})
	}

	const handleAddFilterButtonClick = () => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Add Filter',
			button_label: 'Add filter',
			screen_name: 'Challenge Creation - 2nd Step',
			...userInfo,
		})
		append({ ...emptyFilter })
	}

	const handleAfterUseFilterChange = (hasFilter: boolean) => {
		if (!hasFilter) {
			if (isEditing) {
				setValue('filters', [{ filter: 'vendorIds', value: [vendorId] }])
			} else {
				setValue('filters', [emptyFilter])
			}
		}
		trigger('hasFilter')
		setShowFilterBox(hasFilter)
	}

	const handleAfterTargetChange = (individualTarget: boolean) => {
		if (individualTarget) {
			setValue('filters', [groupFilter])
		} else {
			setValue('filters', [emptyFilter])
		}
		enableFilterOption()
	}

	useEffect(() => {
		if (challengeFilterFormData) {
			reset(challengeFilterFormData)
		}
		if (hasValidFilter(challengeFilterFormData)) {
			setShowFilterBox(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const disabledRadioFilter = isActiveChallenge || (isTargetEnabled && isEditing)

	useEffect(() => {
		if (isEditing) {
			if (hasValidFilter(challengeFilterFormData)) {
				setValue('hasFilter', true)
			} else {
				setValue('hasFilter', false)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing])

	const handleClickSubmitButton = () => {
		if (onFormSubmit) {
			handleSubmit(onFormSubmit)()
		}
	}

	return (
		<>
			<AccountGroupFormModal isEditing={isEditing} isIndividualTarget={individualTargetFormData} />
			<ChallengeFormWrapper isEditing={isEditing}>
				<ChallengeFormFilterStartDate disabled={isStartDateDisabled} />
				<ChallengeFormFilterEndDate />
			</ChallengeFormWrapper>
			<ChallengeFormWrapper isEditing={isEditing}>
				<SelectUseFilter afterChange={handleAfterUseFilterChange} disabledRadioFilter={disabledRadioFilter} />
			</ChallengeFormWrapper>
			{showFilterBox && (
				<ChallengeFormFilterBoxPaper isEditing={isEditing}>
					{isTargetEnabled && <ChallengeFormFilterTarget afterChange={handleAfterTargetChange} disabled={isEditing} />}
					{isFilterEditGroupVisible() && (
						<>
							{fields.map((field, index) =>
								isEqualToVendorIds(field.filter) ? null : (
									<FilterBox
										key={field.id}
										index={index}
										filterOptions={filterList}
										onRemoveLinkClick={handleRemoveLinkClick}
										onFilterSelected={disableFilterOption}
										defaultValue={field}
										disabled={isFilterDisabled}
										isActiveChallenge={isActiveChallenge}
										isFilterChangeDisabled={!!individualTargetFormData}
									/>
								),
							)}
						</>
					)}
					{!isFilterDisabled && !individualTargetFormData && (
						<ChallengeFormFilterAddButton onClick={handleAddFilterButtonClick} disabled={isAddButtonDisabled} />
					)}
				</ChallengeFormFilterBoxPaper>
			)}
			{!isEditing && onFormSubmit && onClickBackButton && (
				<FormActions
					primaryButton={{ type: 'continue', clickHandler: handleClickSubmitButton, disabled: !isValid }}
					backButton={{ clickHandler: onClickBackButton }}
				/>
			)}
		</>
	)
}

export default ChallengeFormFilterView
