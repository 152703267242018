import React from 'react'
import { Button } from '@material-ui/core'
import styles from './styles'

export type FileUploadButtonProps = {
	id: string
	label: string
	multiple?: boolean
	acceptableTypes?: string
	onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
	onClick?: () => void
	disabled: boolean
	fileUploadElement: React.RefObject<HTMLInputElement>
	buttonType: 'primary' | 'secondary'
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
	id,
	label,
	multiple,
	acceptableTypes,
	buttonType,
	onChange,
	onClick,
	disabled,
	fileUploadElement,
}: FileUploadButtonProps) => {
	const classes = styles()
	const variant = buttonType === 'primary' || disabled ? 'contained' : 'outlined'

	return (
		<>
			<input
				className={classes.inputFile}
				accept={acceptableTypes}
				id={id}
				multiple={multiple}
				type="file"
				onChange={onChange}
				ref={fileUploadElement}
			/>
			<label htmlFor={!disabled ? id : `fake-${id}`}>
				<Button
					variant={variant}
					color="primary"
					component="span"
					disabled={disabled}
					className={classes.button}
					data-testid={`file-upload-button-${id}`}
					onClick={!disabled ? onClick : undefined}
				>
					{label}
				</Button>
			</label>
		</>
	)
}

export default FileUploadButton
