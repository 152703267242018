import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& .membership-MuiInputBase-root': {
				minHeight: '48px',
				borderRadius: '8px',
				height: 'auto',
			},
		},
		endAdornment: {
			'& button': {
				color: theme.palette.common.black,
			},
		},
		chip: {
			borderRadius: '25px',
			paddingRight: '4px',
			paddingLeft: '4px',
			backgroundColor: 'rgb(233,241,255);',
			'&:focus': {
				backgroundColor: 'rgb(233,241,255);',
			},
		},
		chipLabel: {
			padding: '8px 0 8px 8px',
			fontSize: '12px',
			letterSpacing: 0,
			lineHeight: '14px',
		},
		deleteIcon: {
			marginLeft: '4px',
			marginRight: '4px',
			width: '14px',
			height: '14px',
			color: 'rgb(3,99,196);',
		},
	}),
)

export default styles
