import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ChallengeEvents } from '@/stores/challengeCreation'
import { BASE_ROUTE } from '@/utils/constants'
import ChallengeFormView from './ChallengeFormView'

const ChallengeForm: React.FC = () => {
	const history = useHistory()

	const handleBreadcrumbBack = () => {
		history.push(`${BASE_ROUTE}/challenges`)
	}

	useEffect(() => {
		return () => ChallengeEvents.closeChallengeForm()
	}, [])

	return <ChallengeFormView onClickBreadcrumbBack={handleBreadcrumbBack} />
}

export default ChallengeForm
