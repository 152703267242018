import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsTableSearched {
	searchQuery: string
	contentType: string
	tableName: string
	screenName: string
}

export const useAnalyticsServiceEventTableSearched = (): {
	sendAnalyticsServiceTableSearched: (recordData: AnalyticsTableSearched) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceTableSearched = useCallback(
		({ searchQuery, contentType, tableName, screenName }: AnalyticsTableSearched) => {
			AnalyticsService.events.tableSearched({
				search_query: searchQuery,
				content_type: contentType,
				table_name: tableName,
				screen_name: screenName,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceTableSearched }
}
