import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		cell: {
			width: '42px',
			padding: '0',
		},
		checkbox: {
			fontSize: '32px',
			paddingLeft: '0',
			'& svg': {
				width: '28px',
				height: '28px',
			},
		},
	}),
)

export default styles
