import { setPortalConfig, setTogglesMock } from '@/services/host/HostService'
import { useAuthenticationService as authenticationService } from 'admin-portal-shared-services'
import * as FeatureToggle from 'utils/featureToggle'
import { useEffect, useState } from 'react'

type useSetupTestEnvProps = {
	isLoading: boolean
}

const shouldStartServerMock = (): boolean =>
	window.location.hostname.includes('localhost') && !process.env.DISABLE_RW_ADMIN_SERVER_MOCK

const defineTestEnv = async (): Promise<void> => {
	const startServer = await import('../../../server')
	const token = startServer.default()
	setPortalConfig()
	setTogglesMock({
		[FeatureToggle.TRANSACTION_HISTORY_MODULE]: 'DO',
		[FeatureToggle.PROGRAM_CONFIGURATION_MODULE]: 'DO',
		[FeatureToggle.DEV_MULTIVENDOR_EARNING_RULES_BY_VENDOR]: 'HN',
		[FeatureToggle.DEV_MULTIVENDOR_REDEEMABLES_BY_VENDOR]: 'HN',
		[FeatureToggle.CONFIG_INITIAL_BALANCE_REDEEM_LIMIT_BY_TIER]: 'HN',
		[FeatureToggle.REDEEMABLE_ITEMS_MODULE]: 'HN',
	})
	authenticationService().setAuthHeader(`Bearer ${token}`)
}

const useSetupTestEnv = (): useSetupTestEnvProps => {
	const [isLoading, setIsLoading] = useState(shouldStartServerMock())

	useEffect(() => {
		if (shouldStartServerMock()) {
			defineTestEnv().then(() => {
				setIsLoading(false)
			})
		}
	}, [])

	return {
		isLoading,
	}
}

export { useSetupTestEnv }
