import { makeStyles } from '@material-ui/core/styles'

const spaceBetweenCards = 16

const styles = makeStyles({
	root: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
	},
	option: {
		width: '50%',
		paddingBottom: `${spaceBetweenCards}px`,
		'&:nth-child(even)': {
			paddingLeft: `${spaceBetweenCards / 2}px`,
		},
		'&:nth-child(odd)': {
			paddingRight: `${spaceBetweenCards / 2}px`,
		},
	},
})

export default styles
