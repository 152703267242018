import Challenge from 'domains/Challenge'
import { ChallengeMode, ChallengeStatus } from '@/domains/enums'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

/**
 * Get Challenge Status comparing the Date
 * @param startDate startDate of the Challenge
 * @param endDate endDate of the Challenge
 * @returns Calculated Status using the informed Dates
 */
const getStatusByDate = (startDate: Date, endDate: Date): ChallengeStatus => {
	const current = dayjs.utc()

	if (current.isBefore(startDate)) {
		return ChallengeStatus.Created
	}
	if ((current.isSame(startDate) || current.isAfter(startDate)) && current.isBefore(endDate)) {
		return ChallengeStatus.Active
	}

	return ChallengeStatus.Expired
}

/**
 * Get Challenge Status
 * @param startDate [Date]: Challenge Start Date
 * @param endDate [Date]: Challenge End Date
 * @param mode [Function]: Challenge Mode
 */
const getStatus = ({ startDate, endDate, mode }: Challenge): ChallengeStatus => {
	let status: ChallengeStatus

	if (mode === ChallengeMode.Cancelled) {
		status = ChallengeStatus.Cancelled
	} else {
		status = getStatusByDate(startDate, endDate)
	}
	return status
}

export { getStatus }
