import { GlobalStore } from '@/stores'

const getVendorFromGlobalStore = (): string => {
	const state = GlobalStore.getState()
	if (state.selectedVendorId) {
		return state.selectedVendorId
	}
	throw new Error('selectedVendorId is undefined')
}

export const getDefaultVendorIdOfCurrentZone = (): string => {
	return getVendorFromGlobalStore()
}
