import React from 'react'
import EditSku from './RulesRedesign/Pages/EditSku/EditSku'
import RulesProvider from './providers/RulesProvider'

const EditSkuRoute: React.FC = () => {
	return (
		<RulesProvider>
			<EditSku />
		</RulesProvider>
	)
}

export default EditSkuRoute
