import { Paper } from '@/components/core'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import { Paragraph } from '@hexa-ui/components'
import PageLevelTemplate from 'components/templates/PageLevelTemplate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './EarningSkuListStyle'
import RuleDescription from './RuleDescription/RuleDescription'
import SkuList from './SkuList/SkuList'

type Props = {
	isLoading: boolean
	ruleNotFound: boolean
}

const EarningSkuListView: React.FC<Props> = ({ isLoading, ruleNotFound }: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<>
			{!ruleNotFound && (
				<GridContainer className={classes.skuListCard}>
					<PageLevelTemplate />
					<RuleDescription />
					<SkuList />
				</GridContainer>
			)}
			{!isLoading && ruleNotFound && (
				<GridContainer style={{ justifyContent: 'center' }}>
					<GridItem sm="auto">
						<Paper animate={false} className={classes.notFound}>
							<Paragraph size="small">{t('ERROR_MESSAGE.NO_RULE_FOUND_BY_ID')}</Paragraph>
						</Paper>
					</GridItem>
				</GridContainer>
			)}
		</>
	)
}

export default EarningSkuListView
