import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontSize: '14px',
			fontWeight: 'bold',
			lineHeight: '20px',
			letterSpacing: 0,
			transition: 'color ease 0.3s',
			'& label.error': {
				color: theme.palette.error.main,
			},
			'& label.disabled': {
				color: theme.palette.text.disabled,
			},
		},
		optionalLabel: {
			color: theme.palette.text.secondary,
		},
		bottomSpace: {
			marginBottom: '4px',
		},
	}),
)

export default styles
