import { AccountValidationResult, RewardsProjection } from '@/domains/enums'
import * as RewardsService from '@/services/rewards/RewardsService'
import { isRewardsProgramEnabled } from '@/utils/rewardsProgram'
import * as TranslateAccountIdUseCase from '@/usecase/account/TranslateAccountIdUseCase'
import { RewardsProgram } from '@/domains'

export type BalanceWrapper = {
	balance?: number
	validationResult: AccountValidationResult
	permission?: boolean
	accountIdTranslated?: string
}

const createInvalidResult = (): BalanceWrapper => ({ validationResult: AccountValidationResult.INVALID })

const createNotEnrolledResult = (): BalanceWrapper => ({ validationResult: AccountValidationResult.NOT_ENROLLED })

const createNoPermissionResult = (): BalanceWrapper => ({ validationResult: AccountValidationResult.NO_PERMISSION })

const createOKResult = (rewardsProgram: RewardsProgram, accountIdTranslated: string): BalanceWrapper => ({
	balance: rewardsProgram.balance,
	validationResult: AccountValidationResult.OK,
	accountIdTranslated,
})

const getRewardsProgram = (accountIdTranslated: string): Promise<RewardsProgram | undefined> =>
	RewardsService.loadByAccountId(accountIdTranslated, RewardsProjection.DEFAULT).catch(() => undefined)

async function execute(accountIdParam: string): Promise<BalanceWrapper> {
	const accountIdTranslated = await TranslateAccountIdUseCase.execute(accountIdParam).catch(() => null)
	if (!accountIdTranslated) {
		return createInvalidResult()
	}
	const rewardsProgram = await getRewardsProgram(accountIdTranslated)
	if (!rewardsProgram) {
		return createNotEnrolledResult()
	}
	if (!isRewardsProgramEnabled(rewardsProgram)) {
		return createNoPermissionResult()
	}

	return createOKResult(rewardsProgram, accountIdTranslated)
}

export { execute }
