import React from 'react'
import { TableHead, TableRow, TableCell, Box } from '@material-ui/core'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import { Tooltip } from '@/components/core'
import { setDirection, setOrderBy } from '@/stores/challengeHistory/ChallengeHistoryEvents'
import ColumnData from '../model/ColumnData'
import { useDataTableContext } from '../context/DataTableContext'
import TableSortCell from '../TableSortCell'
import useStyles from './styles'
import TableOrderType from '../model/TableOrderType'

type Props = {
	tableId: string
	columns: ColumnData[]
	sortEnabled?: string[] | null
	rowSelectionCheckbox?: React.ReactNode
	onSort?: (columnName: string, orderBy?: TableOrderType) => void
}

const TableHeader: React.FC<Props> = ({ tableId, columns, sortEnabled, rowSelectionCheckbox, onSort }: Props) => {
	const classes = useStyles()

	const { order, orderBy, sortData } = useDataTableContext()

	const handleSort = (dataKey: string, label: string) => {
		const isAsc = orderBy === dataKey && order === 'asc'
		sortData([{ key: dataKey, order: isAsc ? 'desc' : 'asc' }])
		setOrderBy(dataKey)
		setDirection(isAsc ? 'desc' : 'asc')
		if (onSort) {
			onSort(label, order)
		}
	}

	const headerRenderer = (column: ColumnData, columnIndex: number) => {
		const { align, label, width, minWidth, dataKey, info } = column
		const key = `${tableId}-headerCell-${columnIndex}`

		const renderSortHeader = dataKey !== 'actionColumn' && (sortEnabled === null || sortEnabled?.includes(dataKey))

		const infoElement = info ? (
			<Tooltip title={info}>
				<InfoOutlinedIcon className={classes.info} data-testid={`${dataKey}-info-icon`} />
			</Tooltip>
		) : undefined

		if (renderSortHeader) {
			return (
				<TableSortCell
					key={key}
					id={key}
					label={label}
					align={align ?? 'left'}
					width={width ?? ''}
					minWidth={minWidth}
					sortDirection={orderBy === dataKey ? order : false}
					active={orderBy === dataKey}
					dataKey={dataKey}
					direction={orderBy === dataKey ? order : 'asc'}
					onClick={() => handleSort(dataKey, label)}
					infoIcon={infoElement}
				/>
			)
		}

		return (
			<TableCell key={key} id={key} align={align ?? 'left'} style={{ maxWidth: width, width, minWidth }}>
				<Box display="flex">
					<span>{label}</span>
					{infoElement}
				</Box>
			</TableCell>
		)
	}

	const cells = columns.map((column, index) => headerRenderer(column, index))

	return (
		<TableHead id={`${tableId}-headers`} className={classes.root}>
			<TableRow>
				{rowSelectionCheckbox
					? [<React.Fragment key="row-selection-header-checkbox">{rowSelectionCheckbox}</React.Fragment>, ...cells]
					: cells}
			</TableRow>
		</TableHead>
	)
}

export default TableHeader
