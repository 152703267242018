import React, { useMemo } from 'react'
import SmallSelect from '@/components/form/Select/SmallSelect'
import { useStore } from 'effector-react'
import { GlobalStore } from '@/stores'
import useCurrentVendor from '@/hooks/vendor/useCurrentVendor'
import { useGlobalConfig } from '@/hooks/global/useGlobalConfig'
import { hasMoreElementsThan } from '@/utils/array'
import { useLocation } from 'react-router-dom'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import { routes } from '@/routes/routes'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'

const SelectVendor: React.FC = (): JSX.Element | null => {
	const { vendorOptionsMap } = useStore(GlobalStore)
	const currentVendor = useCurrentVendor()
	const { defineCurrentVendor } = useGlobalConfig()
	const { goToRulesList } = useNavigateToRules()
	const location = useLocation()
	const { EARNING_RULES_ROUTE } = routes
	const userInfo = useGetUserInfo()

	const options = useMemo(
		() =>
			Object.values(vendorOptionsMap).map((item) => ({
				label: item.vendorName,
				value: item.vendorId,
			})),
		[vendorOptionsMap],
	)

	const validateCurrentRoute = () => {
		if (EARNING_RULES_ROUTE.isActive(location?.pathname)) goToRulesList()
	}

	const handleTracking = (value: string) => {
		const selectedOption = options.find((item) => item.value === value)

		AnalyticsService.events.selectInteraction({
			...userInfo,
			screen_name: 'Vendor',
			form_name: 'Vendor',
			selected_value: value,
			select_label: selectedOption?.label ?? '',
			select_name: 'Select Vendor',
		})
	}

	const handleChange = (value: string) => {
		defineCurrentVendor(value)
		validateCurrentRoute()
		handleTracking(value)
	}

	return options.length ? (
		<SmallSelect
			options={options}
			onChange={handleChange}
			value={currentVendor?.vendorId}
			disabled={!hasMoreElementsThan(options, 1)}
		/>
	) : null
}

export default SelectVendor
