import React, { useCallback, useEffect, useState } from 'react'
import { Button, Paragraph } from '@hexa-ui/components'
import { Plus } from '@hexa-ui/icons'
import { SkusSelector } from '@dlb-shared-business-components/sku-selector'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { useTranslation } from 'react-i18next'
import { SkuModel } from '@dlb-shared-business-components/sku-selector/dist/declarations/src/lib/domain/skus.types'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { useFormikContext } from 'formik'
import { useSkus } from '@/context/SkusContext/SkusContext'
import { useEnvContext } from '@/providers/EnvProvider/EnvProvider'
import RewardsOfferTable from '../RewardsOfferTable/RewardsOfferTable'
import Styled from '../RewardsOfferTable/RewardsOfferTable.style'

const SkusTableWithModal = () => {
	const { t } = useTranslation()
	const { selectedSkus, setSelectedSkus } = useSkus()
	const selectedZone = useCurrentZone()
	const { enabledEuropeCountries } = useEnvContext()
	const { setFieldValue } = useFormikContext()

	const PlusIcon = useCallback(() => <Plus size="tiny" />, [])
	const [isOpen, setIsOpen] = useState(false)

	const handleSkus = (selectedValues: SkuModel[]) => {
		setSelectedSkus(selectedValues)
	}

	const AddNewSkuRow = () => {
		setIsOpen(true)
	}

	const handleRemoveSku = useCallback(
		(skuToRemove: string) => {
			setSelectedSkus((prevSkus: SkuModel[]) => prevSkus.filter((sku) => sku.value !== skuToRemove))
		},
		[setSelectedSkus],
	)

	useEffect(() => {
		if (selectedSkus.length > 0) {
			setFieldValue('skus', selectedSkus)
		}
	}, [selectedSkus, setFieldValue])

	return (
		<>
			<Styled.ContainerWithButton>
				<Paragraph aria-label="Related SKUs">
					{t('transaction:REWARDS_OFFER.RELATED_SKUS')} <span>({t('transaction:REWARDS_OFFER.OPTIONAL')})</span>
				</Paragraph>
				<Button
					icon={PlusIcon}
					variant="secondary"
					leading
					size="small"
					data-testid="add-new-row-button"
					type="button"
					onClick={AddNewSkuRow}
				>
					{t('transaction:REWARDS_OFFER.ADD_SKUS')}
				</Button>
			</Styled.ContainerWithButton>

			<RewardsOfferTable selectedSkus={selectedSkus} onSkuRemove={handleRemoveSku} />
			<SkusSelector
				isOpened={isOpen}
				onClose={() => setIsOpen(false)}
				values={selectedSkus}
				setSelectedValues={handleSkus}
				country={selectedZone ?? ''}
				isEurope={enabledEuropeCountries?.includes(selectedZone ?? '')}
				vendorId={getDefaultVendorIdOfCurrentZone()}
			/>
		</>
	)
}

export default SkusTableWithModal
