import React from 'react'
import { TextFieldProps } from '@material-ui/core'
import InputContainer, { InputContainerProps } from '@/components/form/InputContainer'
import { Input, InputProps } from '@hexa-ui/components'

export type InputTextProps = InputContainerProps & TextFieldProps

const CustomHexaInput: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputTextProps & InputProps> = ({
	id,
	label,
	optionalLabel,
	helperText,
	errorText,
	infoIconTooltip,
	customMessageTooltip,
	maxLength,
	placeholder = '',
	optionalText = '',
	hintText,
	disabled,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	ref,
	...rest
}) => {
	const valueLength = `${rest.value}`.length

	return (
		<InputContainer
			id={id}
			label={label}
			optionalLabel={optionalLabel}
			errorText={errorText}
			helperText={helperText}
			disabled={disabled}
			infoIconTooltip={infoIconTooltip}
			customMessageTooltip={customMessageTooltip}
			maxLength={maxLength}
			valueLength={valueLength}
			hintText={hintText}
			disableGutters
			disableTopGutter
		>
			<Input id={id} width="100%" placeholder={placeholder} optionalText={optionalText} disabled={disabled} {...rest} />
		</InputContainer>
	)
}

export default CustomHexaInput
