import MembershipFeatures from '@/domains/authorization/MembershipFeatures'
import { PermissionLevel } from '@/domains/enums'
import { isToggledCreateEarningRulesEnabled } from '@/utils/featureToggle/featureToggleHelper'

export type Permission = {
	feature: MembershipFeatures
	permissionLevel: PermissionLevel
}

type Props = {
	isFeatureAuthorized: (featureToggle: boolean, requiredPermission?: Permission) => boolean
}

export const createEarningRuleButtonEnabled = ({ isFeatureAuthorized }: Props): boolean => {
	const createEarningRulesToggle = isToggledCreateEarningRulesEnabled()

	const isEnableCreateEarningRule = isFeatureAuthorized(createEarningRulesToggle, {
		feature: 'CreateEarningRules',
		permissionLevel: PermissionLevel.Delete,
	})

	return isEnableCreateEarningRule
}
