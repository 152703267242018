import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		root: {
			'& .membership-MuiInputBase-root': {
				minHeight: '48px',
				height: 'auto',
				borderRadius: '8px',
				fontFamily: 'Work Sans',
				letterSpacing: '0',
			},
		},
		formcontrol: {
			marginTop: '0px',
			'& > p.Mui-error': {
				display: 'block',
				paddingLeft: '4px',
				margin: '0px !important',
			},
			'& > div': {
				marginTop: '0px',
			},
			'& button': {
				right: '-10px',
			},
		},
	}),
)

export default styles
