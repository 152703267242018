export interface PPPRange {
	minimum?: number
	maximum?: number
}

function isCriticalPPP(pricePerPoint: number, pricePerPointDefault: number | undefined): boolean {
	if (pricePerPoint === 0) return false
	if (!pricePerPointDefault) return true
	const differencePercentage = (Math.abs(pricePerPoint - pricePerPointDefault) / pricePerPointDefault) * 100
	return differencePercentage > 50
}

function isCriticalPPPV2(pricePerPoint: number, pricePerPointDefault: number | undefined): boolean {
	if (!pricePerPointDefault) return true
	const differencePercentage = (Math.abs(pricePerPoint - pricePerPointDefault) / pricePerPointDefault) * 100
	return differencePercentage > 50
}

function pppValidRange(defaultPricePerPoint: number | undefined): PPPRange {
	if (!defaultPricePerPoint) {
		return {}
	}

	const half = defaultPricePerPoint / 2
	const minimum = defaultPricePerPoint - half
	const maximum = defaultPricePerPoint + half

	return { minimum, maximum }
}

export { isCriticalPPP, isCriticalPPPV2, pppValidRange }
