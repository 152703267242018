import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		loadingContainer: {
			display: 'flex',
			alignItems: 'center',
			alignContent: 'center',
			justifyContent: 'center',
			height: '100%',
		},
	}),
)

export default styles
