import { styled } from '@bees/vision-tokens'
import { Download } from '@hexa-ui/icons'

export const CSVUploaderWrapper = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	padding: '8px',
})

export const DownloadLinkWrapper = styled('div', {
	display: 'flex',
	gap: '4px',
	height: '32px',
	padding: '0px 12px 0px 12px',
})

export const DownloadIcon = styled(Download, {
	color: '#0F7FFA',
})
