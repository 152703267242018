import React from 'react'
import { useStoreMap } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { ComboEvents, ComboFormDataMultiple, ComboFormDataSingle, ComboStore, PageLevelEvents } from '@/stores'
import { ButtonInteraction, RecordType, ScreenName } from '@/domains'
import { CombosBulkUpdateUseCase, UpdateBulkComboUseCase } from '@/usecase'
import { ComboBulkUpdateTransaction } from '@/converters'
import { isToggleMultivendorRedeemablesByVendorEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { useAnalyticsServiceRecordUpdated } from '@/hooks/analyticsService/useAnalyticsServiceEventsRecordUpdated'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import useAuditLogDTcombos from '@/hooks/useAuditLogInstance/BusinessLogicLayer/combosDT'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import EditRedeemableDialogView from './EditRedeemableDialogView'
import EditRedeemableFormInputs from '../../models/EditRedeemableFormInputs'
import { updateSingleComboHelper } from './EditRedeemablesDiologHelper'

const EditRedeemableDialog: React.FC = () => {
	const { t } = useTranslation()
	const { sendAnalyticsServiceRecordUpdated } = useAnalyticsServiceRecordUpdated()
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const { sendAuditLogDTCombos } = useAuditLogDTcombos()
	const { show, redeemables, programData } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			show: state.showEditComboDialog,
			redeemables: state.activeRedeemable,
			programData: state.programData,
		}),
	})

	const redeemableByVendorEnabled = isToggleMultivendorRedeemablesByVendorEnabled()

	if (!redeemables?.length) return <></>

	const singleEdit = redeemables.length === 1
	const successMessage = t(`combo:NOTIFICATION.REDEEMABLE_EDIT.SUCCESS.${singleEdit ? 'SINGLE' : 'MULTIPLE'}` as const)

	const handleCancel = (buttonClicked: boolean) => {
		if (buttonClicked) {
			sendAnalyticsServiceEventButtonClickedEvent(ScreenName.EditMaxQuantityModal, ButtonInteraction.Cancel, null)
		}
		ComboEvents.setShowEditComboDialog(false)
		ComboEvents.setActiveCombo({ activeCombo: undefined })
		ComboEvents.setActiveRedeemable({ activeRedeemable: undefined })
	}

	const updateComboList = async (comboBulkUpdateTransaction: ComboFormDataMultiple) => {
		if (redeemableByVendorEnabled && redeemables) {
			const isSingleRedeemables = comboBulkUpdateTransaction.formData.redeemables.length === 1
			if (isSingleRedeemables) {
				return updateSingleComboHelper(comboBulkUpdateTransaction as ComboFormDataSingle)
			}
			return UpdateBulkComboUseCase.execute(comboBulkUpdateTransaction)
		}
		if (programData && redeemables) {
			return CombosBulkUpdateUseCase.execute(programData.id, comboBulkUpdateTransaction)
		}
		throw new Error()
	}

	const handleSubmit = async (values: EditRedeemableFormInputs) => {
		const comboBulkUpdateTransaction = ComboBulkUpdateTransaction(values)
		const { points } = values.redeemables[0]
		const recordId = values.redeemables.map((item) => {
			return item.vendorComboId
		})

		await updateComboList(comboBulkUpdateTransaction)
			.then(() => {
				PageLevelEvents.showNotification({
					route: '/membership/combos',
					notificationType: 'success',
					message: successMessage,
				})
				ComboEvents.setRedeemablePoints(
					values.redeemables.map((item) => ({
						redeemableId: item.redeemableId,
						points: item.points,
					})),
				)
			})
			.catch(() => {
				PageLevelEvents.showNotification({
					route: '/membership/combos',
					notificationType: 'error',
					message: t('combo:NOTIFICATION.REDEEMABLE_EDIT.ERROR'),
				})
			})
			.finally(() => {
				ComboEvents.setActiveCombo({ activeCombo: undefined })
				ComboEvents.setActiveRedeemable({ activeRedeemable: undefined })
				ComboEvents.setShowEditComboDialog(false)
				sendAuditLogDTCombos({
					operation: IAuditOperations.UPDATE,
					points: points.toString(),
					recordId: recordId.toString(),
				})
				sendAnalyticsServiceRecordUpdated({
					recordId: recordId.toString(),
					recordType: RecordType.DTCombo,
					screenName: ScreenName.DTComboManagementPublishedEditModal,
				})
			})
	}

	return (
		<EditRedeemableDialogView
			show={show}
			singleEdit={singleEdit}
			redeemables={redeemables}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
		/>
	)
}

export default EditRedeemableDialog
