import { createStore } from 'effector'
import SingleFormState from './SingleFormState'
import * as SingleFormEvents from './SingleFormEvents'

const initialState: SingleFormState = {
	confirmationDialog: {
		show: false,
	},
}

const SingleFormStore = createStore(initialState)
	.on(SingleFormEvents.toggleConfirmationDialog, (state) => ({
		...state,
		confirmationDialog: {
			show: !state.confirmationDialog.show,
		},
	}))
	.reset(SingleFormEvents.resetForSingleForm)

export default SingleFormStore
