import { TransactionResponseV2 } from '@/domains/transaction/TransactionResponse'
import { formatDate } from '@/utils/date'

export interface Transaction {
	value: string | number | null | undefined
	title: string
}

export const transactionLogViewColumns = [
	{
		Header: 'transaction:LOG:TRANSACTION_ID',
		accessor: 'id',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:TRANSACTION_TYPE',
		accessor: 'type',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:TARGET',
		accessor: 'affectedAccounts',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:REQUESTED_ON',
		accessor: 'createdAt',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:STATUS',
		accessor: 'status',
		disableSortBy: true,
	},
	{
		Header: 'transaction:LOG:ACTIONS',
		accessor: 'actions',
		disableSortBy: true,
	},
]

export const transactionDetailDialogColumns = [
	{
		Header: 'transaction:TRANSACTION_DETAIL.TRANSACTION_STATUS.POCS',
		accessor: 'affectedAccounts',
		disableSortBy: true,
		style: {
			width: '40%',
		},
	},
	{
		Header: 'transaction:TRANSACTION_DETAIL.TRANSACTION_STATUS.STATUS',
		accessor: 'status',
		disableSortBy: true,
		style: {
			width: '40%',
		},
	},
	{
		Header: 'transaction:TRANSACTION_DETAIL.TRANSACTION_STATUS.ACTIONS',
		accessor: 'actions',
		disableSortBy: true,
		style: {
			width: '20%',
		},
	},
]

export const transactionDetailDialogReasons = (
	transaction: TransactionResponseV2 | null | undefined,
): Transaction[] => {
	return [
		{ title: 'transaction:TRANSACTION_DETAIL.REJECTION_REASON', value: transaction?.rejectionReason },
		{ title: 'transaction:TRANSACTION_DETAIL.DENIAL_REASON', value: transaction?.denialReason },
	]
}

const DATE_FORMAT = 'MMMM D, YYYY - HH:mm'

export const transactionDetailDialogFields = (transaction: TransactionResponseV2 | null | undefined): Transaction[] => {
	const transactionAmount = transaction?.amount ?? transaction?.points
	const transactionType =
		transaction?.type === 'OFFER'
			? 'transaction:TRANSACTION_TYPE_REWARDS_OFFER'
			: 'transaction:TRANSACTION_TYPE_POINTS_REMOVAL'

	return [
		{ title: 'transaction:TRANSACTION_DETAIL.TRANSACTION_ID', value: transaction?.id },
		{
			title: 'transaction:TRANSACTION_DETAIL.REQUESTED_ON',
			value: transaction?.createdAt ? formatDate(new Date(transaction.createdAt), DATE_FORMAT) : '',
		},
		{
			title: 'transaction:TRANSACTION_DETAIL.TRANSACTION_TYPE',
			value: transaction?.type ? transactionType : '',
		},
		{ title: 'transaction:TRANSACTION_DETAIL.SINGLE_POC', value: transaction?.pocId },
		{ title: 'transaction:TRANSACTION_DETAIL.MULTIPLE_POC', value: transaction?.totalAccounts },
		{ title: 'transaction:TRANSACTION_DETAIL.AMOUNT', value: transactionAmount },
		{ title: 'transaction:TRANSACTION_DETAIL.OFFER_CATEGORY', value: transaction?.category },
		{ title: 'transaction:TRANSACTION_DETAIL.REFERENCE_MONTH', value: transaction?.referenceMonth },
		{ title: 'transaction:TRANSACTION_DETAIL.REFERENCE_YEAR', value: transaction?.referenceYear },
		{ title: 'transaction:TRANSACTION_DETAIL.REASON', value: transaction?.reason },
		{ title: 'transaction:TRANSACTION_DETAIL.REMOVAL_REASON', value: transaction?.removalReason },
		{ title: 'transaction:TRANSACTION_DETAIL.DESCRIPTION', value: transaction?.description },
		{ title: 'transaction:TRANSACTION_DETAIL.REMOVAL_DESCRIPTION', value: transaction?.removalDescription },
	]
}
