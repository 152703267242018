import { useUserMetadata } from 'admin-portal-shared-services'
import { hasScopePermission } from './hasScopePermission/hasScopePermission'

export default function useScopeAuthorization(scopes: string[]): boolean {
	const userMetadata = useUserMetadata()

	const userScopes = userMetadata.data?.authorization?.scopes

	return hasScopePermission(userScopes, scopes)
}
