import React from 'react'
import RulesProvider from './providers/RulesProvider'
import EarningSkuList from './RulesRedesign/Pages/EarningSkuList/EarningSkuList'

const SkusListRoute: React.FC = () => {
	return (
		<RulesProvider>
			<EarningSkuList />
		</RulesProvider>
	)
}

export default SkusListRoute
