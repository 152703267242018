import { ChallengeEvents, ChallengeSetupFormData } from '@/stores/challengeCreation'
import React, { useCallback, useEffect, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isPurchaseOrVolume } from '@/utils/challenge'
import { ChallengeExecutionMethod } from '@/domains/enums'
import useChallengeTypeList from 'hooks/challenge/useChallengeTypeList'
import { InputSelect } from '@/components/form'

const ChallengeFormSetupExecutionMethod = () => {
	const challengeTypeList = useChallengeTypeList()
	const { t } = useTranslation()
	const { control, watch, setValue, clearErrors } = useFormContext<ChallengeSetupFormData>()
	const executionMethod = watch('executionMethod')
	const previousExecutionMethod = useRef<ChallengeExecutionMethod>(executionMethod)

	const hasExecutionMethodChanged = useCallback(() => {
		if (previousExecutionMethod.current !== executionMethod) {
			previousExecutionMethod.current = executionMethod
			return true
		}
		return false
	}, [executionMethod])

	useEffect(() => {
		if (hasExecutionMethodChanged()) {
			if (isPurchaseOrVolume({ executionMethod })) {
				clearErrors('id')
				setValue('id', '', { shouldValidate: true })
			}
			ChallengeEvents.clearNextStepsData()
		}
	}, [clearErrors, executionMethod, hasExecutionMethodChanged, setValue])

	return (
		<Controller
			control={control}
			name="executionMethod"
			render={({ field: { value, onChange, ...field }, fieldState: { error } }) => {
				return (
					<InputSelect
						id="challenge-type"
						label={t('challenge:SETUP_STEP.CHALLENGE_TYPE')}
						options={challengeTypeList}
						autoFocus
						errorText={error?.message}
						disableGutters
						value={value || ''}
						{...field}
						onChange={(event) => {
							ChallengeEvents.setExecutionMethod(event.target.value as ChallengeExecutionMethod)
							onChange(event)
						}}
					/>
				)
			}}
		/>
	)
}

export default ChallengeFormSetupExecutionMethod
