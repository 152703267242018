import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		container: {
			fontSize: '16px',
			lineHeight: '24px',
			maxWidth: '470px',
			width: '406px',
		},
	}),
)

export default styles
