import ProgressiveImage from '@/components/core/ProgressiveImage'
import { GridItem } from '@/components/templates/GridTemplate'
import SkuForm from '@/domains/sku/SkuForm'
import { Paragraph } from '@hexa-ui/components'
import React from 'react'
import useStyles from './ProductColumnStyle'

type ProductColumnProps = {
	sku: SkuForm
}

const ProductColumn: React.FC<ProductColumnProps> = ({ sku }: ProductColumnProps) => {
	const { productColumn, imageContainer, productInfoContainer, productName, productDescription } = useStyles()
	const { image, name, containerDescription } = sku

	return (
		<GridItem className={productColumn} style={{ padding: 0 }}>
			<div className={imageContainer}>
				<ProgressiveImage imageSource={image} />
			</div>
			<div className={productInfoContainer}>
				<Paragraph className={productName}>{name}</Paragraph>
				<Paragraph className={productDescription}> {containerDescription}</Paragraph>
			</div>
		</GridItem>
	)
}

export default ProductColumn
