import { createStyles, makeStyles } from '@material-ui/core/styles'

const createRedeemableItemStyle = makeStyles(() =>
	createStyles({
		container: {
			'& [role="dialog"]': {
				width: '80%',
				maxWidth: '640px',
			},

			'& > [role="dialog"] > div:first-child': {
				marginBottom: '8px',
			},
		},
		cancelButton: {
			display: 'block',
			marginRight: '16px',
		},
		alert: {
			display: 'flex',
			width: 'auto',
			marginTop: 'var(--space-2)',
		},
		chipsWrapper: {
			marginBottom: '32px',
		},
	}),
)

export default createRedeemableItemStyle
