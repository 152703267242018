import { createStyles, makeStyles } from '@material-ui/core/styles'

const EditDefaultCppCardStyle = makeStyles(() =>
	createStyles({
		container: {
			width: '100%',
			height: '64px',
			marginBottom: '16px',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingRight: '24px',
			paddingLeft: '24px',
		},
		infoWrapper: {
			display: 'flex',
			gap: '16px',
			alignItems: 'center',
		},
		textWrapper: {
			display: 'flex',
			gap: '8px',
		},
		paragraph: {
			background: 'none',
			border: 'none',
			padding: 0,
			font: 'inherit',
			cursor: 'pointer',
			outline: 'inherit',
			color: 'var(--colors-semanticInfoBasis)',
			display: 'flex',
			alignItems: 'center',
			gap: '6px',
			fontWeight: 500,
		},
		sentToApprovalIcon: {
			color: 'var(--colors-semanticInfoBasis)',
		},
		deniedIcon: {
			color: 'var(--colors-semanticErrorBasis)',
		},
	}),
)

export default EditDefaultCppCardStyle
