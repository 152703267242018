import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { $pricePerPointConfigurationStore } from '@/stores/redeemableItems/PricePerPointConfigurationStore'
import { isCriticalPPP, pppValidRange } from '@/usecase/redeemableItems/CriticalPricePerPointUseCase'
import { Tooltip } from '@hexa-ui/components'
import { AlertTriangle } from '@hexa-ui/icons'
import Typography from '@material-ui/core/Typography'
import { useStore } from 'effector-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { styles } from './styles'

export const RedeemableItemPricePerPoint = ({ item }: { item: RedeemableItem }) => {
	const { t } = useTranslation()
	const css = styles()
	const { pricePerPoint } = item
	const pppFormattedValue = useFormatCurrency(pricePerPoint, '')
	const { pricePerPoint: defaultPricePerPoint } = useStore($pricePerPointConfigurationStore)
	const defaultPPPFormatedValue = useFormatCurrency(defaultPricePerPoint)

	const { minimum, maximum } = pppValidRange(defaultPricePerPoint)
	const minimumPrice = useFormatCurrency(minimum)
	const maximumPrice = useFormatCurrency(maximum)

	const shouldShowWarning = !pricePerPoint ? false : isCriticalPPP(pricePerPoint, defaultPricePerPoint)

	return (
		<div>
			{!pricePerPoint ? (
				<Typography
					data-testid="default-price-per-point-value"
					variant="inherit"
					color="primary"
					className={css.defaultPricePerPoint}
				>
					{defaultPPPFormatedValue}
				</Typography>
			) : (
				<div className={css.container}>
					<Typography
						data-testid="default-price-per-point-disabled-value"
						variant="inherit"
						color="primary"
						className={css.disabledDefaultPricePerPoint}
					>
						{defaultPPPFormatedValue}
					</Typography>
					<div className={css.customPricePerPointContainer}>
						<Typography
							data-testid="custom-price-per-point-value"
							variant="h6"
							color="primary"
							className={css.customPricePerPoint}
						>
							{pppFormattedValue}
						</Typography>
						{shouldShowWarning && (
							<Tooltip
								placement="bottom"
								text={t('redeemable:ITEMS_LIST_TABLE.PPP_RANGE_TOOLTIP', { minimumPrice, maximumPrice })}
							>
								<AlertTriangle size="medium" />
							</Tooltip>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
