import RewardsProjection from 'domains/enums/RewardsProjectionEnum'
import * as RewardsService from 'services/rewards/RewardsService'

async function verifyPocEnroll(accountId: string): Promise<boolean> {
	try {
		await RewardsService.loadByAccountId(accountId, RewardsProjection.DEFAULT)
		return true
	} catch (err) {
		return false
	}
}

/**
 * Verify if a challenge has been accepted by any POC
 * @param challengeId Challenge to be verified
 * @returns true if challenge has been accepted
 */
async function verifyChallengeAcceptance(challengeId: string): Promise<boolean> {
	const response = await RewardsService.loadAllByChallenge([challengeId], RewardsProjection.CHALLENGES)
	return response.content.length > 0
}

export { verifyPocEnroll, verifyChallengeAcceptance }
