import { useFormatCurrency } from '@/hooks/useFormatNumber'
import {
	$createRedeemableItemStore,
	$criticalPricePerPointConfigurationStore,
	$editRedeemableItemStore,
	$pricePerPointConfigurationStore,
	CreateRedeemableItemStoreEvents,
	CriticalPricePerPointConfigurationStoreEvents,
	EditRedeemableItemStoreEvents,
} from '@/stores'
import { MODAL_CHANGE_DELAY } from '@/utils/constants'
import { Alert, Button, Dialog, Divider, Heading } from '@hexa-ui/components'
import Typography from '@material-ui/core/Typography'
import { useStore } from 'effector-react'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import criticalPricePerPointStyle from './CriticalPricePerPointStyle'

export const CriticalPricePerPoint: React.FC = () => {
	const { t } = useTranslation()
	const css = criticalPricePerPointStyle()
	const { isOpened, submit, back, skuPricePerPoint } = useStore($criticalPricePerPointConfigurationStore)
	const {
		isPublishing: editIsPublishing,
		isPublishSuccess: editedSuccessfully,
		hasError: editHasError,
	} = useStore($editRedeemableItemStore)
	const {
		errorOnPublish,
		publishedSuccessfully,
		isPublishing: createIsPublishing,
	} = useStore($createRedeemableItemStore)
	const { pricePerPoint: pricePerPointDefault } = useStore($pricePerPointConfigurationStore)

	const reset = useCallback(() => {
		CreateRedeemableItemStoreEvents.reset()
		EditRedeemableItemStoreEvents.reset()
		CriticalPricePerPointConfigurationStoreEvents.reset()
	}, [])

	const onModalOpenChangeHandler = useCallback(() => {
		return isOpened ? reset() : {}
	}, [isOpened, reset])

	useEffect(() => {
		if (publishedSuccessfully || editedSuccessfully) {
			CriticalPricePerPointConfigurationStoreEvents.onClose()
		}
	}, [editedSuccessfully, publishedSuccessfully])

	return (
		<div data-testid="critical-price-per-point-dialog" className={css.container}>
			<Dialog.Root
				destructive="critical"
				closeButton
				onClose={() => CreateRedeemableItemStoreEvents.reset()}
				actions={<Actions submit={submit} back={back} isPublishing={createIsPublishing || editIsPublishing} />}
				title={<Heading size="H3">{t('redeemable:CRITICAL_PPP.TITLE')}</Heading>}
				open={isOpened}
				onOpenChange={onModalOpenChangeHandler}
			>
				<Typography className={css.description}>{t('redeemable:CRITICAL_PPP.DESCRIPTION')}</Typography>
				<Content pricePerPointDefault={pricePerPointDefault} pricePerPointSKU={skuPricePerPoint} />
				<Alert
					className={css.alert}
					data-testid="critical-price-per-point-error-alert"
					type="error"
					message={t('ERROR_MESSAGE.GENERIC_ERROR')}
					style={{ display: errorOnPublish || editHasError ? 'flex' : 'none' }}
				/>
			</Dialog.Root>
		</div>
	)
}

type ContentProps = {
	pricePerPointDefault: number | undefined
	pricePerPointSKU: number
}

const Content = ({ pricePerPointDefault, pricePerPointSKU }: ContentProps) => {
	const { t } = useTranslation()
	const css = criticalPricePerPointStyle()

	return (
		<div className={css.contentContainer}>
			<div className={css.pricePerPointContainer}>
				<Typography>{t('redeemable:CRITICAL_PPP.DEFAULT_PPP')}</Typography>
				<Typography>{useFormatCurrency(pricePerPointDefault)}</Typography>
			</div>
			<div className={css.divider}>
				<Divider orientation="vertical" />
			</div>
			<div className={css.pricePerPointContainer}>
				<Typography>{t('redeemable:CRITICAL_PPP.SKU_PPP')}</Typography>
				<Typography>{useFormatCurrency(pricePerPointSKU)}</Typography>
			</div>
		</div>
	)
}

type ActionsProps = {
	submit: () => void
	back: () => void
	isPublishing: boolean
}

const Actions = ({ submit, back, isPublishing }: ActionsProps) => {
	const { t } = useTranslation()
	const css = criticalPricePerPointStyle()

	const backAction = useCallback(() => {
		CriticalPricePerPointConfigurationStoreEvents.onClose()
		setTimeout(back, MODAL_CHANGE_DELAY)
	}, [back])

	return (
		<div className={css.buttonsContainer}>
			<Button
				isLoading={isPublishing}
				data-testid="critical-price-per-point-continue-button"
				size="medium"
				variant="destructive"
				onClick={submit}
			>
				{t('common:BUTTON.YES_CONTINUE')}
			</Button>
			<Button data-testid="critical-price-per-point-back-button" size="medium" variant="secondary" onClick={backAction}>
				{t('BUTTON.BACK')}
			</Button>
		</div>
	)
}
