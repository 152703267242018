import { VendorOption } from '@/domains/vendors'
import { GlobalStore } from '@/stores'
import { useStore } from 'effector-react'

const useCurrentVendor = (): VendorOption | null => {
	const { selectedVendorId, vendorOptionsMap } = useStore(GlobalStore)
	if (!selectedVendorId) {
		return null
	}
	return vendorOptionsMap[selectedVendorId]
}

export default useCurrentVendor
