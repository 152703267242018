import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import EarningSkuListView from './EarningSkuListView'
import { useLoadSkuList } from '../../../hooks/useLoadSkuList'

const EarningSkuList: React.FC = () => {
	const { ruleId } = useParams<{ ruleId: string }>()
	const { selectedTierKey } = useCurrentTier()

	const { loadSkuList, loading, ruleNotFound, rule } = useLoadSkuList(ruleId, selectedTierKey ?? '')

	useEffect(() => {
		if (!rule) {
			loadSkuList()
		}
	}, [loadSkuList, rule])

	return <EarningSkuListView isLoading={loading} ruleNotFound={ruleNotFound} />
}

export default EarningSkuList
