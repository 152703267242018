import { Input as HexaInput, InputProps } from '@hexa-ui/components'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Input: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputProps> = ({ ref, ...props }) => {
	return (
		<div style={{ flex: 1 }}>
			<HexaInput {...props} width="100%" />
		</div>
	)
}
