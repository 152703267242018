import React from 'react'
import { FormHelperText } from '@material-ui/core'
import clsx from 'clsx'
import styles from './styles'

type Props = {
	id: string
	label?: string
	labelDescription?: string
	padding?: string
	error?: boolean
}

const FileUploadLabel: React.FC<Props> = ({ id, label, labelDescription, error, padding }: Props) => {
	const classes = styles({
		padding,
	})
	return (
		<div className={classes.label}>
			{label && (
				<label
					className={clsx({
						[classes.error]: error,
					})}
					htmlFor={id}
				>
					{label}
				</label>
			)}
			{labelDescription && (
				<FormHelperText id={`${id}-instruction`} className={(classes.helperText, classes.description)}>
					{labelDescription}
				</FormHelperText>
			)}
		</div>
	)
}

export default FileUploadLabel
