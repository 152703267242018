import { RouteInstance, RouteListItem } from '@/domains/route/RouteInstance'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadCrumbConfig } from 'admin-portal-shared-services'
import { BASE_ROUTE } from '@/utils/constants'
import { hasText } from '@/utils/string'
import { generatePath, useLocation } from 'react-router-dom'
import { matchRoutePath } from '@/utils/routesUtil'
import useActiveRoute from './useActiveRoute'

export interface ActiveRouteSettings {
	pageTitle: string
	breadcrumbConfig?: BreadCrumbConfig
}

export const useActiveRouteSettings = (authorizedRoutes: Array<RouteInstance>): ActiveRouteSettings => {
	const { t } = useTranslation()
	const { activeRouteInstance, activeRouteListItem } = useActiveRoute(authorizedRoutes)
	const location = useLocation()

	const getRouteLabel = useCallback(
		(route: RouteListItem, locationParams: Record<string, string>): string => {
			if (hasText(route.textTranslationKey)) {
				return t(route.textTranslationKey!)
			}
			if (route.renderText) {
				return route.renderText(locationParams)
			}
			return ''
		},
		[t],
	)

	const getBreadcrumbItem = useCallback(
		(route: RouteListItem, activeKey: string) => {
			const match = matchRoutePath(location.pathname, route)
			const locationParams = match?.params || {}
			return {
				label: getRouteLabel(route, locationParams),
				path: generatePath(route.path, locationParams),
				isCurrentPage: activeKey === route.key,
			}
		},
		[getRouteLabel, location.pathname],
	)

	const breadcrumbConfig = useMemo<BreadCrumbConfig | undefined>(() => {
		if (!activeRouteInstance) {
			return undefined
		}
		const items = activeRouteListItem?.breadCrumbsFlux.map((index) => {
			const route = activeRouteInstance.routeList[index]
			return getBreadcrumbItem(route, activeRouteListItem.key)
		})
		return {
			homePath: BASE_ROUTE,
			items: items || [],
		}
	}, [activeRouteInstance, activeRouteListItem?.breadCrumbsFlux, activeRouteListItem?.key, getBreadcrumbItem])

	const pageTitle = useMemo(() => {
		if (!breadcrumbConfig?.items) {
			return ''
		}
		const currentPage = breadcrumbConfig.items.find((item) => item.isCurrentPage === true)
		return currentPage?.label ?? ''
	}, [breadcrumbConfig?.items])

	return {
		pageTitle,
		breadcrumbConfig,
	}
}
