import { Transparency, TransparencyHistory } from '@/domains'
import { TransparencyStatus } from '@/domains/enums'

const convert = (log: Transparency): TransparencyHistory => {
	return {
		id: log.id,
		type: log.type,
		executionDate: new Date(log.dateRegistered),
		description: log.description,
		status:
			log.totalFailedTransactions > 0
				? `${log.totalFailedTransactions} ${TransparencyStatus.Failed}`
				: TransparencyStatus.Completed,
		fileName: log.fileName,
	} as TransparencyHistory
}

const convertAll = (logs: Transparency[]): TransparencyHistory[] => {
	return logs?.length >= 1 ? logs.map((log) => convert(log)) : []
}

export { convertAll }

export default convert
