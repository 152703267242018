import React, { ReactElement } from 'react'

const MemoErrorIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
	const { height } = props
	return (
		<svg
			width={height}
			height={height}
			viewBox="0 0 10 10"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			data-testid="svg-error-icon"
		>
			<title>Icons / Error</title>
			<defs>
				<path
					d="M6 1C3.24 1 1 3.24 1 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm.5 7.5h-1v-1h1v1zm0-2h-1v-3h1v3z"
					id="erro-icon"
				/>
			</defs>
			<g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
				<path d="M0 0h12v12H0z" />
				<mask id="prefix__b" fill="#fff">
					<use xlinkHref="#erro-icon" />
				</mask>
				<use fill="#000" xlinkHref="erro-icon" />
				<g mask="url(#prefix__b)" fill="#E02B27" fillRule="nonzero">
					<path d="M0 0h12v12H0z" />
				</g>
			</g>
		</svg>
	)
}

const defaultProps: React.SVGProps<SVGSVGElement> = {
	height: '12px',
}

MemoErrorIcon.defaultProps = defaultProps

const ErrorIcon = React.memo(MemoErrorIcon)
export default ErrorIcon
