/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useCallback } from 'react'
import { IconButton, InputAdornment, OutlinedInput, CircularProgress } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import useDebounce from 'utils/useDebounce'
import useStyles from './styles'
import { useDataTableContext } from '../context/DataTableContext'
import SearchTracking from '../model/SearchTracking'

type Props = {
	id: string
	placeholder: string
	searchBy: string[]
	hasCustomSearch: boolean
	trackProps?: SearchTracking
}
const TableSearchInput: React.FC<Props> = ({ id, placeholder, searchBy, hasCustomSearch, trackProps }: Props) => {
	const classes = useStyles()
	const [query, setQuery] = useState<string>('')
	const [customSearchLoading, setCustomSearchLoading] = useState(false)
	const { searchData, customSearchData, query: defaultQuery } = useDataTableContext()
	const debouncedSearchData = useDebounce(query, 300)

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(e.currentTarget.value)
	}

	const executeSearch = useCallback(
		(searchField: string[], querySearch: string) => {
			if (hasCustomSearch) {
				setCustomSearchLoading(true)
				customSearchData(querySearch).finally(() => {
					setCustomSearchLoading(false)
				})
			} else {
				searchData(searchField, querySearch, trackProps)
			}
		},
		[customSearchData, hasCustomSearch, searchData, trackProps],
	)

	useEffect(() => {
		executeSearch(searchBy, debouncedSearchData)
	}, [debouncedSearchData])

	return (
		<OutlinedInput
			defaultValue={defaultQuery}
			id={id}
			name={id}
			placeholder={placeholder}
			fullWidth
			multiline={false}
			endAdornment={
				<InputAdornment position="end">
					{!customSearchLoading ? (
						<IconButton
							id={`${id}-button`}
							aria-label={id}
							onClick={() => {
								executeSearch(searchBy, debouncedSearchData)
							}}
							edge="end"
						>
							<Search />
						</IconButton>
					) : (
						<CircularProgress color="inherit" size={16} />
					)}
				</InputAdornment>
			}
			onChange={handleSearch}
			className={classes.searchInput}
			inputProps={{ className: classes.input }}
		/>
	)
}

export default React.memo(TableSearchInput)
