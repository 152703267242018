import React from 'react'

import ChallengeHistory from './components/ChallengeHistory/ChallengeHistory'

const ChallengesRoute: React.FC = () => {
	// useEffect(() => {
	// 	const abortController = new AbortController()

	// 	return (): void => {
	// 		ChallengeHistoryEvents.setLoading(true)
	// 		ChallengeHistoryEvents.setChallenges([])
	// 		abortController.abort()
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [history.location.pathname])

	return <ChallengeHistory />
}

export default ChallengesRoute
