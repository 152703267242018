import React, { useEffect } from 'react'
import { LoadProgramUseCase, GlobalUseCase } from '@/usecase'
import Program from '@/domains/program/Program'
import { PageLevelEvents, ProgramConfigurationEvents, ProgramConfigurationStore } from '@/stores'
import { ProgramProjection } from '@/domains/enums'
import { useStoreMap } from 'effector-react'
import ProgramConfigurationView from './ProgramConfigurationView'

const ProgramConfiguration: React.FC = () => {
	const { program } = useStoreMap({
		store: ProgramConfigurationStore,
		keys: [],
		fn: (state) => ({
			program: state.programData || ({} as Program),
		}),
	})

	const loadPrograms = async () => {
		try {
			GlobalUseCase.load()
			const programData = await LoadProgramUseCase.execute(ProgramProjection.Default)
			ProgramConfigurationEvents.setProgramData(programData)
		} catch (error) {
			ProgramConfigurationEvents.setProgramData(undefined)
		} finally {
			GlobalUseCase.loadDone()
		}
	}

	const handleEdit = () => {
		if (program?.id) {
			ProgramConfigurationEvents.setShowEditDialog(true)
			PageLevelEvents.hideNotification()
		}
	}

	useEffect(() => {
		loadPrograms()
	}, [])

	return <ProgramConfigurationView program={program} handleEdit={handleEdit} />
}

export default ProgramConfiguration
