import * as ChallengeService from '@/services/challenge/ChallengeService'
import Challenge from '@/domains/Challenge'

async function executeById(id: string): Promise<Challenge | null> {
	return ChallengeService.load(id).catch(() => {
		return null
	})
}

export { executeById }
