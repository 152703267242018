import {
	$createRedeemableItemStore,
	$pricePerPointConfigurationStore,
	CreateRedeemableItemStoreEvents,
	PricePerPointConfigurationStoreEvents,
} from '@/stores'
import { MODAL_CHANGE_DELAY } from '@/utils/constants'
import { useStore } from 'effector-react'
import { useCallback, useEffect } from 'react'

export type useRedeemableItemsEmptyStateFlowResult = {
	isCreateModalOpen: boolean
	onCreateNewButtonClicked: () => void
}

export const useRedeemableItemsEmptyStateFlow = () => {
	const { isOpened: isCreateModalOpen } = useStore($createRedeemableItemStore)
	const { pricePerPoint } = useStore($pricePerPointConfigurationStore)

	const onCreateNewButtonClicked = useCallback(() => {
		if (!pricePerPoint) {
			PricePerPointConfigurationStoreEvents.setIsOpen(true)
			return
		}
		CreateRedeemableItemStoreEvents.onOpen()
	}, [pricePerPoint])

	useEffect(() => {
		PricePerPointConfigurationStoreEvents.setIsOpen(false)
		CreateRedeemableItemStoreEvents.reset()

		return () => {
			PricePerPointConfigurationStoreEvents.setIsOpen(false)
			CreateRedeemableItemStoreEvents.reset()
		}
	}, [])

	useEffect(() => {
		const unwatchPricePerPoint = PricePerPointConfigurationStoreEvents.valueChangedSuccessfully.watch(() => {
			setTimeout(CreateRedeemableItemStoreEvents.onOpen, MODAL_CHANGE_DELAY)
		})

		return () => {
			unwatchPricePerPoint()
		}
	}, [])

	return {
		isCreateModalOpen,
		onCreateNewButtonClicked,
	}
}
