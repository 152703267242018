import React, { ReactNode } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import styles from './styles'
import ButtonProps from './model/ButtonProps'

export type Props = {
	title: string
	subTitle?: string
	buttonProps?: ButtonProps
	children: ReactNode
}
const TablePageTemplate: React.FC<Props> = ({ title, subTitle, buttonProps, children }) => {
	const classes = styles()

	return (
		<Grid container>
			<Grid item container justifyContent="space-between" className={classes.header}>
				<Grid item>
					<>
						<Typography variant="h2">{title}</Typography>
						{subTitle && (
							<Typography variant="body1" className={classes.subtitle}>
								{subTitle}
							</Typography>
						)}
					</>
				</Grid>
				{buttonProps && (
					<Grid item>
						<Button
							type="button"
							variant="contained"
							color="primary"
							id={buttonProps?.id}
							component={Link}
							to={buttonProps?.to}
							onClick={buttonProps?.onClick}
						>
							{buttonProps?.text}
						</Button>
					</Grid>
				)}
			</Grid>
			{children}
		</Grid>
	)
}

export default TablePageTemplate
