import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		numberRounded: {
			height: '24px',
			width: '24px',
			borderRadius: '12px',
			backgroundColor: theme.palette.common.black,
			textAlign: 'center',
			float: 'left',
		},
		number: {
			lineHeight: '24px',
			cursor: 'default',
			color: theme.palette.common.white,
		},
	}),
)

export default styles
