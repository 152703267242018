import { ChallengeExecutionMethod } from '@/domains/enums'

type ExecutionMethodAssertionFn = ({ executionMethod }: { executionMethod: ChallengeExecutionMethod }) => boolean

export const isPhoto: ExecutionMethodAssertionFn = ({ executionMethod }) =>
	ChallengeExecutionMethod.TakePhoto === executionMethod

export const isPurchaseSingle: ExecutionMethodAssertionFn = ({ executionMethod }) =>
	ChallengeExecutionMethod.Purchase === executionMethod

export const isPurchaseMultiple: ExecutionMethodAssertionFn = ({ executionMethod }) =>
	ChallengeExecutionMethod.PurchaseMultiple === executionMethod

export const isVolume: ExecutionMethodAssertionFn = ({ executionMethod }) =>
	ChallengeExecutionMethod.VolumeFixed === executionMethod ||
	ChallengeExecutionMethod.SpendBased === executionMethod ||
	ChallengeExecutionMethod.SingleVolumeFixed === executionMethod

export const isPurchaseOrVolume: ExecutionMethodAssertionFn = (challenge) =>
	isPurchaseSingle(challenge) || isPurchaseMultiple(challenge) || isVolume(challenge)

export const isNotPurchaseOrVolume: ExecutionMethodAssertionFn = (challenge) => !isPurchaseOrVolume(challenge)
