import React, { ReactNode } from 'react'
import { useFeatureToggle } from 'hooks/toggle/useFeatureToggle'

type Props = {
	featureName: string
	children: ReactNode
	fallbackComponent?: ReactNode | null
}

const FeatureToggle: React.FC<Props> = ({ featureName, children, fallbackComponent }: Props) => {
	const featureToggleOn = useFeatureToggle(featureName)

	return <>{featureToggleOn ? children : fallbackComponent}</>
}

FeatureToggle.defaultProps = {
	fallbackComponent: <></>,
}

export default FeatureToggle
