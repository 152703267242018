import { IAuditEntities, IAuditLog, IAuditOperations } from '@/hooks/useAdminAuditLog'

export type generateCreateRedeemablesProps = {
	pricePerPoint: number
	SKU: string
	operation: IAuditOperations
}

export type generateSettingsRedeemablesProps = {
	defaultPricePerPoint: number
	operation: IAuditOperations
	vendorId: string
}

export const generateLogCreateRedeemables = ({
	pricePerPoint,
	SKU,
	operation,
}: generateCreateRedeemablesProps): IAuditLog => {
	return {
		metadata: {
			price_per_point: pricePerPoint.toString(),
			type: 'redeemable',
		},
		operation,
		entity: IAuditEntities.REDEEMABLES,
		entityId: SKU,
	}
}

export const generateLogSettingsRedeemables = ({
	defaultPricePerPoint,
	operation,
	vendorId,
}: generateSettingsRedeemablesProps): IAuditLog => {
	return {
		metadata: {
			default_price_per_point: defaultPricePerPoint.toString(),
		},
		operation,
		entity: IAuditEntities.REDEEMABLES,
		entityId: vendorId,
	}
}
