import { Challenge } from '@/domains'
import useAdminAuditLog, { IAuditEntities, IAuditOperations } from '@/hooks/useAdminAuditLog'
import { useCallback } from 'react'
import ExecutionMethod from 'domains/enums/ChallengeExecutionMethodEnum'

const useAuditLogChallengeEdited = (): {
	sendAuditLogChallengeEdited: (challenge: Challenge) => void
} => {
	const { auditLogInstance } = useAdminAuditLog()

	const sendAuditLogChallengeEdited = useCallback(
		(challenge: Challenge) => {
			const metaProp = {
				campaign_id: challenge.campaignId ?? '',
				challenge_type: challenge.executionMethod,
				challenge_title: challenge.title,
				challenge_description: challenge.description,
				challenge_start_date: challenge.startDate?.toString(),
				challenge_end_date: challenge.endDate?.toString(),
				challenge_points: challenge.points.toString(),
				challenge_status: 'updated',
				cover_image: challenge.image!,
				good_image: challenge.goodPhotoSample!,
				creation_date: '',
				filter: JSON.stringify(challenge.filter),
			}
			if (challenge.executionMethod === ExecutionMethod.VolumeFixed) {
				Object.assign(metaProp, { target_type: challenge.individualTarget ? 'Individual' : 'General' })
				Object.assign(metaProp, { volume_sku_quantity: challenge.quantityMin?.toString() })
			}
			if (challenge.executionMethod !== ExecutionMethod.VolumeFixed) {
				Object.assign(metaProp, { challenge_points: challenge.points.toString() })
				Object.assign(metaProp, { skus: JSON.stringify(challenge.skus) })
			}

			if (challenge.executionMethod === ExecutionMethod.TakePhoto) {
				Object.assign(metaProp, { good_image: challenge.goodPhotoSample })
				Object.assign(metaProp, { bad_image: challenge.badPhotoSample })
			}

			auditLogInstance({
				metadata: metaProp,
				entity: IAuditEntities.CHALLENGES,
				entityId: challenge.id,
				operation: IAuditOperations.UPDATE,
			})
		},
		[auditLogInstance],
	)

	return { sendAuditLogChallengeEdited }
}

export default useAuditLogChallengeEdited
