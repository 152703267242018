import React from 'react'
import AccountGroupFormProvider from './AccountGroupFormProvider'
import AccountGroupFormContent from './AccountGroupFormContent'
import { useIsAccountGroupModalVisible } from '../../hooks/useIsAccountGroupModalVisible'

type Props = {
	isEditing?: boolean
	isIndividualTarget?: boolean
}

const AccountGroupFormModal: React.FC<Props> = (props) => {
	const isAccountGroupModalVisible = useIsAccountGroupModalVisible()

	if (!isAccountGroupModalVisible) {
		return null
	}
	return (
		<AccountGroupFormProvider>
			<AccountGroupFormContent {...props} />
		</AccountGroupFormProvider>
	)
}

export default AccountGroupFormModal
