import { useCallback } from 'react'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'
import Program from '@/domains/program/Program'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { GlobalStore, PageLevelEvents, ProgramConfigurationEvents } from '@/stores'
import { UpdateInitialBalanceUseCase, UpdateProgramUseCase } from '@/usecase'
import { isToggleConfigByTier } from '@/utils/featureToggle/featureToggleHelper'

export interface UpdateProgramResult {
	updateProgram: (formValues: Program) => Promise<void>
}

export const useUpdateProgram = (): UpdateProgramResult => {
	const { t } = useTranslation()
	const selectedTierKey = useStore(GlobalStore).selectedTierKey ?? ''
	const selectedZone = useCurrentZone() ?? ''

	const handleEventSuccess = useCallback(
		(state: boolean, formValues?: Program) => {
			if (state) {
				ProgramConfigurationEvents.setProgramData(formValues)
				PageLevelEvents.showNotification({
					notificationType: 'success',
					message: t('program:NOTIFICATION.EDIT.SUCCESS'),
				})
			} else {
				PageLevelEvents.showNotification({
					notificationType: 'error',
					message: t('program:NOTIFICATION.EDIT.ERROR'),
				})
			}
		},
		[t],
	)

	const updateProgramData = useCallback(
		async (formValues: Program): Promise<void> => {
			try {
				if (isToggleConfigByTier()) {
					const params = { formValues, selectedTierKey, selectedZone }
					await UpdateInitialBalanceUseCase.execute(params)
				} else {
					const updateProgram = await UpdateProgramUseCase.execute(formValues)
					if (!updateProgram) throw new Error()
				}
				handleEventSuccess(true, formValues)
			} catch (error) {
				handleEventSuccess(false)
			}
			ProgramConfigurationEvents.setShowEditDialog(false)
		},
		[handleEventSuccess, selectedTierKey, selectedZone],
	)

	return {
		updateProgram: updateProgramData,
	}
}
