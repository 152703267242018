import { createStore } from 'effector'
import SettingsConfigurationState from './SettingsConfigurationState'
import * as SettingsConfigurationEvents from './SettingsConfigurationEvents'

export const settingsConfigurationInitialState: SettingsConfigurationState = {
	settingsData: undefined,
}

const SettingsConfigurationStore = createStore(settingsConfigurationInitialState).on(
	SettingsConfigurationEvents.setSettingsData,
	(state, settingsData) => ({
		...state,
		settingsData,
	}),
)

export default SettingsConfigurationStore
