import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const CombosListStyle = makeStyles((theme: Theme) =>
	createStyles({
		quantityLabel: {
			fontWeight: 500,
		},
		quantityValue: {
			margin: '0px 24px 0px 16px',
		},
		link: {
			fontWeight: 500,
			color: theme.palette.info.main,
			textDecoration: 'underline',
			textTransform: 'capitalize',
			marginRight: '8px',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		downloadButton: {
			fontSize: '14px',
			lineHeight: '16px',
			backgroundColor: theme.palette.common.white,
			float: 'right',
		},
	}),
)

export default CombosListStyle
