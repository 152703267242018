import { ChallengeExecutionMethod } from '@/domains/enums'
import { ChallengeDetailsFormData, ChallengeEvents, ChallengeState, ChallengeStore } from '@/stores/challengeCreation'
import { hasElements } from '@/utils/array'
import {
	isToggleSpendBasedChallengeEnabled,
	isToggleVolumeFixedSingle,
} from '@/utils/featureToggle/featureToggleHelper'
import { FormHelperText } from '@material-ui/core'
import { useStoreMap } from 'effector-react'
import React, { useEffect } from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useChallenge from '../../hooks/useChallenge'
import ChallengeFormWrapper from '../ChallengeFormWrapper/ChallengeFormWrapper'
import FormActions from '../FormActions/FormActions'
import SkuBoxContainer from '../SkuBox/SkuBoxContainer'
import ChallengeFormDetailsPoints from './ChallengeFormDetailsPoints'
import ChallengeFormDetailsQuantity from './ChallengeFormDetailsQuantity'
import styles from './ChallengeFormDetailsStyle'
import ChallengeFormDetailsChallengeGoal from './ChallengeGoal/ChallengeFormDetailsChallengeGoal'
import ChallengePurchaseType from './ChallengePurchaseType/ChallengePurchaseType'

type Props = {
	onFormSubmit?: SubmitHandler<ChallengeDetailsFormData>
	onClickBackButton?: SubmitHandler<ChallengeDetailsFormData>
	challengeDetailsFormData?: ChallengeDetailsFormData
	isEditing?: boolean
}

const ChallengeFormDetailsView: React.FC<Props> = ({
	onFormSubmit,
	onClickBackButton,
	isEditing,
	challengeDetailsFormData,
}: Props) => {
	const { t } = useTranslation()
	const classes = styles()
	const {
		handleSubmit,
		getValues,
		reset,
		formState: { isValidating, errors, isValid },
	} = useFormContext<ChallengeDetailsFormData>()

	const { executionMethod, isValidatingSku, isIndividualTarget } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state: ChallengeState) => ({
			executionMethod: state.challengeSetupFormData?.executionMethod,
			isValidatingSku: state.validations.isValidatingSku,
			isIndividualTarget: !!state.challengeFilterFormData?.individualTarget,
		}),
	})
	const { verifySpendBasedFromCSV } = useChallenge()
	const isSpendBasedFromCSV = verifySpendBasedFromCSV()
	const isToggleSpendBasedEnabled = isToggleSpendBasedChallengeEnabled()
	const isTogglePurchaseType = isToggleVolumeFixedSingle()

	const hasErrors = () => hasElements(Object.keys(errors))
	const challengeGoal = getValues()?.challengeGoal

	useEffect(() => {
		if (isSpendBasedFromCSV) {
			ChallengeEvents.setExecutionMethod(ChallengeExecutionMethod.SpendBased)
		}
	}, [isSpendBasedFromCSV])

	const isVolumeFixed = executionMethod === ChallengeExecutionMethod.VolumeFixed
	const isSingleVolumeFixed = executionMethod === ChallengeExecutionMethod.SingleVolumeFixed
	const isPurchase = executionMethod === ChallengeExecutionMethod.Purchase
	const isPurchaseMultiple = executionMethod === ChallengeExecutionMethod.PurchaseMultiple
	const isSpendBased = executionMethod === ChallengeExecutionMethod.SpendBased
	const isSkuVisible = isVolumeFixed || isSingleVolumeFixed || isPurchase || isPurchaseMultiple || isSpendBased
	const isQuantityVisible = (isVolumeFixed || isSingleVolumeFixed) && !isIndividualTarget
	const isPointsVisible = !((isVolumeFixed || isSpendBased || isSingleVolumeFixed) && isIndividualTarget)
	const isChallengeGoalVisible =
		(isVolumeFixed || isSpendBased || isSingleVolumeFixed) && !isIndividualTarget && isToggleSpendBasedEnabled
	const isPurchaseType = !((isVolumeFixed || isSpendBased || isSingleVolumeFixed) && isIndividualTarget)

	useEffect(() => {
		if (challengeDetailsFormData) {
			reset(challengeDetailsFormData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleClickSubmitButton = () => {
		if (onFormSubmit) {
			handleSubmit(onFormSubmit)()
		}
	}

	return (
		<>
			<ChallengeFormWrapper isEditing={isEditing}>
				{isIndividualTarget && (
					<FormHelperText className={classes.textHelper}>{t('challenge:DETAILS_STEP.PARAMS_IN_CSV')}</FormHelperText>
				)}
				{isPointsVisible && <ChallengeFormDetailsPoints />}
				{isChallengeGoalVisible && <ChallengeFormDetailsChallengeGoal />}
				{isQuantityVisible && !isChallengeGoalVisible && <ChallengeFormDetailsQuantity />}
				{isPurchaseType && challengeGoal === 'AmountOfUnits' && isTogglePurchaseType && <ChallengePurchaseType />}
				{isSkuVisible && (
					<SkuBoxContainer
						isEditing={isEditing}
						isEmpty={!challengeDetailsFormData?.skus?.length && !isEditing}
						hideQuantity={isVolumeFixed || isSpendBased || isSingleVolumeFixed}
					/>
				)}
			</ChallengeFormWrapper>
			{!isEditing && onFormSubmit && onClickBackButton && (
				<FormActions
					primaryButton={{
						type: 'submit',
						clickHandler: !hasErrors() ? handleClickSubmitButton : null,
						disabled: isValidating || isValidatingSku || hasErrors() || !isValid,
						submitText: 'challenge:DETAILS_STEP.SUBMIT',
					}}
					backButton={{
						clickHandler: () => onClickBackButton(getValues()),
					}}
				/>
			)}
		</>
	)
}

export default ChallengeFormDetailsView
