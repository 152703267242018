import { RefundOrderResponse } from '@/domains/order/RefundResponse'
import axios from '@/utils/axiosInstance'
import { API_URL } from '@/utils/constants'
import { useLogService as logService } from 'admin-portal-shared-services'

const log = logService()

async function load(orderId: string, vendorId: string): Promise<RefundOrderResponse> {
	const endPoint = API_URL.GET_REFUND.replace(':orderId', orderId)

	try {
		const { data } = await axios.get<RefundOrderResponse>(endPoint, { params: { vendorId } })
		return data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

export { load }
