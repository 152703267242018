import React from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph } from '@hexa-ui/components'
import styles from './AccountGroupCSVPreviewStyle'

interface Props {
	isMoney?: boolean
}
const AccountGroupCSVPreview: React.FC<Props> = ({ isMoney }: Props) => {
	const { t } = useTranslation()
	const classes = styles()

	return (
		<div className={classes.container}>
			<div>
				<Paragraph colortype="primary" size="basis">
					{isMoney
						? t('challenge:FILTERS_STEP.GROUP.AMOUNT_OF_MONEY')
						: t('challenge:FILTERS_STEP.GROUP.AMOUNT_OF_UNITS')}
				</Paragraph>
				<Paragraph colortype="secondary" size="xsmall">
					{isMoney
						? t('challenge:FILTERS_STEP.GROUP.INDIVIDUAL_TARGET_MESSAGE_MONEY')
						: t('challenge:FILTERS_STEP.GROUP.INDIVIDUAL_TARGET_MESSAGE')}
				</Paragraph>
			</div>
			<div className={classes.typography}>
				<table className={classes.table}>
					<thead>
						<tr className={classes.trHead}>
							<th className={classes.column}> </th>
							<th className={classes.column}>column 1</th>
							<th className={classes.column}>column 2</th>
							<th className={classes.column}>column 3</th>
							<th className={classes.column}> </th>
						</tr>
					</thead>
					<tbody>
						<tr className={classes.trBody}>
							<td className={classes.td}>
								<div className={classes.empty} />
							</td>
							<td className={classes.td}>A</td>
							<td className={classes.td}>B</td>
							<td className={classes.td}>C</td>
							<td> </td>
						</tr>
						<tr className={classes.trBody}>
							<td className={classes.td}>1</td>
							<td className={classes.columnName}>POC_ID</td>
							<td className={classes.columnName}>{isMoney ? 'MONEY' : 'QUANTITY'}</td>
							<td className={classes.columnName}>POINTS</td>
							<td className={classes.column}>row title</td>
						</tr>
						<tr className={classes.trBody}>
							<td className={classes.td}>2</td>
							<td className={classes.columnValue}>0000398520</td>
							<td className={classes.columnValue}>200</td>
							<td className={classes.columnValue}>10000</td>
							<td className={classes.column}>row content </td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default AccountGroupCSVPreview
