import { RulesEvents } from '@/stores'
import React, { useEffect, useState } from 'react'
import { useLoadRules } from '../hooks/useLoadRules'

const RulesProvider: React.FC = ({ children }) => {
	const [isReady, setIsReady] = useState(false)
	const { loadRules } = useLoadRules()

	useEffect(() => {
		loadRules()
		setIsReady(true)
		return () => {
			setIsReady(false)
			RulesEvents.resetRulesStore()
		}
	}, [loadRules])

	return isReady ? <>{children}</> : null
}

export default RulesProvider
