import { FormHelperText, TextField } from '@material-ui/core'
import { Field, FieldProps } from 'formik'
import React, { ReactElement, useState } from 'react'
import FieldErrorMessage from '../FieldErrorMessage'
import InputLabel from '../InputLabel'
import styles from '../styles'
import localStyles from './styles'

type Props = {
	id: string
	name: string
	label: string
	multiline?: boolean
	autoFocus?: boolean
	maxLength?: number | null
	type?: 'text' | 'number' | 'password'
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	disabled?: boolean
	showError?: boolean
	optionalLabel?: string
	helperText?: string
	placeholder?: string
	onError?: (id: string, message: string) => void
	setPocIdField?: (pocId: string) => void
}

const InputText: React.FC<Props> = ({
	id,
	name,
	label,
	multiline,
	autoFocus,
	maxLength,
	type,
	onKeyDown,
	onKeyUp,
	disabled,
	showError,
	optionalLabel,
	helperText,
	placeholder = '',
	setPocIdField,
	onError,
}: Props) => {
	const classes = styles({})
	const localClasses = localStyles()
	const [showInputError, setShowInputError] = useState(false)

	const onNumberKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
		if (e.key.length === 1 && !RegExp(/^[^e,.+-]+$/).exec(e.key)) e.preventDefault()
	}

	const maxLengthHandler = (e: React.KeyboardEvent<HTMLDivElement>): void => {
		if (e.key.length === 1 && (e.target as HTMLInputElement).value.length === maxLength) e.preventDefault()
	}

	const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (maxLength) maxLengthHandler(e)
		if (type === 'number') onNumberKeyDown(e)
		if (onKeyDown) onKeyDown(e)
	}

	return (
		<Field name={name} disabled={disabled}>
			{(fieldProps: FieldProps): ReactElement => {
				const shouldShowError =
					!!fieldProps.meta.error &&
					(showInputError || showError || (showError === undefined && fieldProps.form.submitCount > 0))
				return (
					<div className={classes.box}>
						<InputLabel
							label={label}
							htmlFor={id}
							error={shouldShowError}
							disabled={disabled}
							optionalLabel={optionalLabel}
							bottomSpacing={!helperText}
						/>
						{shouldShowError && <FieldErrorMessage id={id} message={fieldProps.meta.error} onShow={onError} />}
						{helperText && <FormHelperText className={localClasses.helperText}>{helperText}</FormHelperText>}
						<TextField
							autoFocus={autoFocus}
							id={id}
							name={fieldProps.field.name}
							value={fieldProps.field.value ?? ''}
							onChange={fieldProps.field.onChange}
							onBlur={() => setShowInputError(true)}
							onKeyDown={onKeyDownHandler}
							onKeyUp={onKeyUp}
							onFocus={() => setPocIdField && setPocIdField('')}
							fullWidth
							multiline={multiline}
							disabled={disabled}
							minRows={3}
							maxRows={6}
							variant="outlined"
							classes={{
								root: localClasses.root,
							}}
							error={shouldShowError}
							helperText=""
							placeholder={placeholder}
							type={type}
						/>
					</div>
				)
			}}
		</Field>
	)
}

InputText.defaultProps = {
	type: 'text',
	disabled: false,
	autoFocus: false,
	maxLength: null,
}

export default InputText
