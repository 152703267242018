export const features = {
	All: 'All',
	Challenge: 'Challenge',
	PointsManagement: 'PointsManagement',
	Refund: 'Refund',
	PointsTransfer: 'PointsTransfer',
	RewardsOffer: 'RewardsOffer',
	DTComboManagement: 'DTComboManagement',
	TransactionHistory: 'TransactionHistory',
	RewardsPermission: 'RewardsPermission',
	EarningRules: 'EarningRules',
	ProgramConfiguration: 'ProgramConfiguration',
	ZoneConfiguration: 'ZoneConfiguration',
	PointsRemoval: 'PointsRemoval',
	RemoveForServiceUtility: 'RemoveForServiceUtility',
	SelectProgram: 'SelectProgram',
	InitialBalance: 'InitialBalance',
	RedeemLimit: 'RedeemLimit',
	ProgramName: 'ProgramName',
	CreateEarningRules: 'CreateEarningRules',
	RedeemablesCPP: 'RedeemablesCPP',
	BulkPointsRemoval: 'BulkPointsRemoval',
}

type MembershipFeatures = keyof typeof features

export default MembershipFeatures
