import React, { ReactElement } from 'react'

const MemoChevronLeftIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
	const { fill, height, viewBox } = props
	return (
		<svg
			height={height}
			viewBox={viewBox}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>ABI Chevron Left</title>
			<defs>
				<polygon
					id="path-chevron-left-icon"
					points="12.8416667 6.175 11.6666667 5 6.66666667 10 11.6666667 15 12.8416667 13.825 9.025 10"
				/>
			</defs>
			<g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Challenge-Detail-Page" transform="translate(-298.000000, -38.000000)">
					<g id="Icons-/-Chevron-Left" transform="translate(292.000000, 33.000000)">
						<polygon id="Bounds" points="0 0 20 0 20 20 0 20" />
						<mask id="mask-chevron-left-icon" fill="white">
							<use xlinkHref="#path-chevron-left-icon" />
						</mask>
						<use id="Mask-ChevronLeftIcon" fill={fill} fillRule="evenodd" xlinkHref="#path-chevron-left-icon" />
						<g id="Icons-/-_Color-/-Black" mask="url(#mask-chevron-left-icon)" fill={fill} fillRule="nonzero">
							<rect id="Rectangle-ChevronLeftIcon" x="0" y="0" width="20" height="20" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

const defaultProps: React.SVGProps<SVGSVGElement> = {
	fill: '#000',
	height: '10',
	className: '',
	viewBox: '0 0 7 10',
}

MemoChevronLeftIcon.defaultProps = defaultProps

const ChevronLeftIcon = React.memo(MemoChevronLeftIcon)
export default ChevronLeftIcon
