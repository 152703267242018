import React from 'react'
import { Select, SelectOption, SelectProps } from '@admin-portal-shared-components/select'

type Props = SelectProps & {
	options: Array<{
		value: string
		label: string
	}>
}

const SmallSelect: React.FC<Props> = ({ options, ...rest }) => {
	return (
		<Select {...rest} size="small" shape="pill" width="max-content">
			{options?.map((option) => {
				return (
					<SelectOption key={option.value} value={option.value}>
						{option.label}
					</SelectOption>
				)
			})}
		</Select>
	)
}

export default SmallSelect
