import React, { useCallback, useEffect } from 'react'
import ProgressiveImage from '@/components/core/ProgressiveImage'
import { InputCurrency } from '@/components/hook-form/InputCurrency'
import { ScreenName } from '@/domains'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { useIsEditingAllowed } from '@/routes/zoneConfiguration/hooks/useIsEditingAllowed'
import { $pricePerPointConfigurationStore } from '@/stores'
import { format, unformat } from '@/utils/currencyHelper'
import Typography from '@material-ui/core/Typography'
import { useStore } from 'effector-react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { ItemCellStyle } from './ItemCellStyle'
import { useRedeemableItems } from '@/context/RedeemableItems/RedeemableItemsContext'

type ItemCellProps = {
	isVariant?: boolean
}

const ItemCell = ({
	image,
	id,
	itemName,
	fullPackageDescription,
	fullContainerDescription,
	vendorItemId,
	pricePerPoint,
	isVariant,
}: RedeemableItem & ItemCellProps) => {
	const { t } = useTranslation()
	const css = ItemCellStyle()
	const { pricePerPoint: defaultPricePerPoint } = useStore($pricePerPointConfigurationStore) as {
		pricePerPoint: number
	}
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()
	const { isRedeemableCPPPermission } = useIsEditingAllowed()
	const pricePerPointDefault = useFormatCurrency(defaultPricePerPoint, '')
	const { setInputValuePPP, setIsInputEmpty } = useRedeemableItems()
	const {
		control,
		setValue,
		formState: { errors },
		reset,
	} = useFormContext()

	useEffect(() => {
		reset()
		const initialValue = pricePerPoint ? format(pricePerPoint) : undefined
		setValue('pricePerPoint', initialValue, {
			shouldDirty: false,
		})
		return () => reset()
	}, [pricePerPoint, reset, setValue])

	const handleChange = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			const eventTarget = event.target as HTMLInputElement
			const targetValue = eventTarget.value
			const parsedValue = unformat(targetValue)
			const isEmpty = targetValue.trim() === ''

			setIsInputEmpty(isEmpty)

			// Set value to parent component
			setInputValuePPP(parsedValue)
		},
		[setIsInputEmpty, setInputValuePPP],
	)

	return (
		<div className={classNames(css.container, { [css.mainVariantContainer]: !isVariant })}>
			<div className={css.productWrapper} data-testid="redeemable-item-product">
				<div className={css.imageContainer}>
					<ProgressiveImage imageSource={image} data-testid="redeemable-item-product-image" />
				</div>
				<div className={css.productInfoContainer}>
					<Typography className={css.productName} data-testid="redeemable-item-product-name">
						{itemName}
					</Typography>
					<ItemDescription
						fullPackageDescription={fullPackageDescription}
						fullContainerDescription={fullContainerDescription}
					/>
					<Typography className={css.description} data-testid="redeemable-item-product-sku">
						SKU: {vendorItemId}
					</Typography>
				</div>
			</div>
			<div className={css.inputWrapper} data-testid="ppp-editor-input-wrapper">
				<Controller
					control={control}
					name={!isVariant ? 'pricePerPoint' : `pricePerPoint-${id}`}
					render={({ field }) => {
						return (
							<InputCurrency
								{...field}
								id="input-ppp"
								required
								placeholder={pricePerPointDefault}
								disabled={!isRedeemableCPPPermission || isVariant}
								data-testid="ppp-editor-input"
								label={t('redeemable:CREATE.PRICE_PER_POINT')}
								errorText={t(errors.pricePerPoint?.message)}
								onChange={(e) => handleChange(e)}
								hint={t('redeemable:EDIT.PPP_INPUT_HINT_TEXT', { defaultPricePerPoint })}
							/>
						)
					}}
				/>
			</div>
		</div>
	)
}

type ItemDescriptionProps = {
	fullContainerDescription?: string
	fullPackageDescription?: string
}

export const ItemDescription = ({ fullPackageDescription, fullContainerDescription }: ItemDescriptionProps) => {
	const css = ItemCellStyle()
	return (
		<Typography className={css.description} data-testid="redeemable-item-product-description">
			{fullContainerDescription} - {fullPackageDescription}
		</Typography>
	)
}

export default React.memo(ItemCell)
