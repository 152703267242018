import { createStyles, makeStyles } from '@material-ui/core/styles'

const EditComboDialogStyle = makeStyles(() =>
	createStyles({
		comboInfoText: {
			fontSize: '16px',
			fontWeight: 500,
			marginBottom: '5px',
			'&:last-of-type': {
				marginBottom: '8px',
			},
		},
	}),
)

export default EditComboDialogStyle
