import { TransactionEvents, TransactionStore } from '@/stores'
import { LoadOrderWithComboUseCase, LoadRefundOrderUseCase } from '@/usecase'
import { isToggleRefundRedeemableItemsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { useStoreMap } from 'effector-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useRemoveSuccessMessage from '../../hooks/useRemoveSuccessMessage'
import TransactionRefundView from './TransactionRefundView'

type Props = {
	showError: boolean
	onError?: (id: string, message: string) => void
}

const TransactionRefund: React.FC<Props> = ({ showError, onError }) => {
	const removeSuccessMessage = useRemoveSuccessMessage()
	const { t } = useTranslation()

	const isContainerVisible = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => state.isSubmitVisible,
	})

	const validateOrderId = async (orderId: string): Promise<string> => {
		try {
			let order
			if (isToggleRefundRedeemableItemsEnabled()) {
				order = await LoadRefundOrderUseCase.execute(orderId)
			} else {
				order = await LoadOrderWithComboUseCase.execute(orderId)
			}

			TransactionEvents.setIsSubmitVisible(true)
			TransactionEvents.selectOrder(order)
			return Promise.resolve('')
		} catch (err) {
			TransactionEvents.setIsSubmitVisible(false)
			TransactionEvents.selectOrder(null)

			const error = err as Error
			const msg = error?.message ?? error?.toString()
			return Promise.resolve(t(`${msg}`))
		}
	}

	return (
		<TransactionRefundView
			validateOrderId={validateOrderId}
			showError={showError}
			onError={onError}
			isContainerVisible={isContainerVisible}
			handleOrderIdKeyUp={removeSuccessMessage}
		/>
	)
}

export default TransactionRefund
