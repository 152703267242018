import { useMemo } from 'react'
import { ChallengeStore } from '@/stores/challengeCreation'
import { useStoreMap } from 'effector-react'
import { Group } from '@/domains'

type result = {
	currentGoal?: string
	groupOptionsMap: Record<string, Group>
}

const useGroupOptionsMap = (): result => {
	const groupOptionsMap = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.groupOptionsMap,
	})

	const currentGoal = useMemo(() => {
		if (Object.values(groupOptionsMap)[0]) {
			const { goal } = Object.values(groupOptionsMap)[0]
			if (goal) return goal
		}
		return undefined
	}, [groupOptionsMap])

	return { currentGoal, groupOptionsMap }
}

export default useGroupOptionsMap
