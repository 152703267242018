import * as yup from 'yup'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { AccountGroupFormData } from '@/domains/AccountGroup'
import { fieldValidationRequired, fileValidationCsv } from '@/utils/validationObjects'

const AccountGroupFormProvider: React.FC = ({ children }) => {
	const { t } = useTranslation()

	const formMethods = useForm<AccountGroupFormData>({
		mode: 'onChange',
		resolver: yupResolver(
			yup.object().shape({
				groupName: fieldValidationRequired(),
				file: fileValidationCsv({
					required: t('ERROR_MESSAGE.EMPTY_FILE_FIELD'),
					fileSize: t('ERROR_MESSAGE.MAX_SIZE_GROUP_FILE_UPLOAD'),
					fileType: t('ERROR_MESSAGE.UPLOAD_FILE_ACCOUNT_GROUP'),
				}),
			}),
		),
	})

	return <FormProvider {...formMethods}>{children}</FormProvider>
}

export default AccountGroupFormProvider
