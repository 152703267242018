import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import ChevronLeftIcon from '../../icons/ChevronLeftIcon'
import styles from './styles'

export type BreadCrumbLinkProps = {
	displayName: string
	onBack(): void
}
const BreadCrumbLink: React.FC<BreadCrumbLinkProps> = ({ displayName, onBack }: BreadCrumbLinkProps) => {
	const classes = styles()
	return (
		<Grid container spacing={0} direction="row" alignItems="center" className={`${classes.control} breadcrumb-link`}>
			<Typography className={classes.icon} onClick={onBack}>
				<ChevronLeftIcon />
			</Typography>
			<Typography variant="h6" id="breadcrumb-back-btn" className={classes.link} onClick={onBack}>
				{displayName}
			</Typography>
		</Grid>
	)
}

export default BreadCrumbLink
