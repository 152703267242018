import { createStyles, fade, makeStyles } from '@material-ui/core/styles'
import { TableStyle } from './model/TableStyle'

const styles = makeStyles(() =>
	createStyles({
		flexContainer: {
			display: 'flex',
			alignItems: 'center',
			boxSizing: 'border-box',
		},
		searchContainer: {
			marginBottom: '32px',
		},
		tableContainer: {
			flex: 1,
			// if a style prop is empty the default Paper component style will be used
			boxShadow: (props: TableStyle): string => (props.noBoxShadow ? 'none' : ''),
			padding: (props: TableStyle): string => props.padding ?? '32px',
			marginTop: '0px',
		},
		tableFullContainer: {
			minHeight: (props: TableStyle): string => (props.emptyMessage?.height ? '0' : '110px'),
			height: (props: TableStyle): string => props.emptyMessage?.height ?? '79vh',
		},
		table: {
			minWidth: 650,
		},
		tableRow: {
			cursor: 'pointer',
		},
		tableRowHover: {
			'&:hover': {
				background: fade('#F8F8F8', 0.95),
				transition: 'all 0.2s',
				cursor: 'pointer',
			},
			'&.active': {
				background: fade('#F8F8F8', 0.95),
				transition: 'all 0.2s',
				cursor: 'pointer',
			},
		},
		rowNoContent: {
			overflowY: 'hidden',
			transition: 'all 0.5s ease-in-out',
			'& td': {
				position: 'fixed',
				zIndex: -1,
				opacity: 0,
			},
		},
		tableCell: {
			flex: 1,
			fontSize: '.8125rem',
		},
		noClick: {
			cursor: 'initial',
		},
		tableInnerContainer: {
			padding: (props: TableStyle): string =>
				props.tableContainerInnerPadding ? props.tableContainerInnerPadding : '0 32px 32px',
			paddingTop: (props: TableStyle): string => (props.tableContainerTopPadding ? '32px' : '0'),
		},
	}),
)

export default styles
