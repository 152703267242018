import React from 'react'
import clsx from 'clsx'
import styles from './styles'

type Props = {
	label: string
	htmlFor: string
	error?: boolean
	disabled?: boolean
	optionalLabel?: string
	bottomSpacing?: boolean
}

const InputLabel: React.FC<Props> = ({ label, htmlFor, error, disabled, optionalLabel, bottomSpacing }: Props) => {
	const classes = styles()

	return (
		<div className={clsx({ [classes.label]: true, [classes.bottomSpace]: bottomSpacing })}>
			<label className={clsx({ error, disabled })} htmlFor={htmlFor}>
				<span>{label}</span>
				{optionalLabel && <span className={classes.optionalLabel}> {optionalLabel}</span>}
			</label>
		</div>
	)
}

InputLabel.defaultProps = {
	error: false,
	disabled: false,
}

export default InputLabel
