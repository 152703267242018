import OrderWithRedeemables from '@/domains/OrderWithRedeemables'
import { RefundItemFormik } from '@/routes/transaction/model/RefundItemFormik'
import { useStoreMap } from 'effector-react'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import TransactionStore from 'stores/transaction/TransactionStore'
import { TransactionFormKey } from '../../../TransactionFields/TransactionFields'
import { RedeemablesListView } from './components/RedeemablesListView'

export const RedeemablesList: React.FC = () => {
	const { selectedOrder } = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => ({
			selectedOrder: state.selectedOrder as OrderWithRedeemables,
		}),
	})

	const { setFieldValue } = useFormikContext()
	const formKey = TransactionFormKey.redeemables

	useEffect(() => {
		const redeemables = selectedOrder?.redeemables
		if (!redeemables) {
			return
		}

		const formState = redeemables.map((redeemable) => {
			return {
				checked: false,
				...redeemable,
			} as RefundItemFormik
		})
		setFieldValue(formKey, formState)
	}, [formKey, selectedOrder, setFieldValue])

	return <RedeemablesListView redeemables={selectedOrder?.redeemables || []} formKey={formKey} />
}
