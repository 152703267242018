import { createStyles, makeStyles } from '@material-ui/core/styles'

interface Props {
	width?: string
	height?: string
}

const styles = makeStyles((props: Props) =>
	createStyles({
		'@keyframes pulse': {
			'0%': {
				backgroundPosition: '0% 0%',
			},
			'100%': {
				backgroundPosition: '-135% 0%',
			},
		},
		container: {
			display: 'flex',
			width: props.width ?? '100%',
			justifyContent: 'center',
			alignContent: 'center',
			flexDirection: 'column',
			height: '100%',
			backgroundColor: '#FFF',
		},
		image: {
			marginTop: '0',
			marginBottom: '0',
			width: props.width ?? '100%',
			height: props.height ?? 'auto',
			objectFit: 'contain',
			borderRadius: '0',
		},
		skeleton: {
			backgroundSize: '400% 400%',
			backgroundImage: 'linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%)',
			animation: '$pulse 1.2s ease-in-out infinite',
		},
	}),
)

export default styles
