/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@hexa-ui/components'
import useStyles from './FormActionsStyle'

export interface PrimaryButtonProps {
	disabled?: boolean
	type: 'continue' | 'submit'
	submitText?: string
	backText?: string
	clickHandler: (() => void) | null
}

export interface BackButtonProps {
	clickHandler: () => void
}

type Props = {
	primaryButton?: PrimaryButtonProps
	backButton?: BackButtonProps
}

const FormActionsView: React.FC<Props> = ({ primaryButton, backButton }: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const submitText: any = primaryButton?.submitText ? primaryButton.submitText : 'BUTTON.SUBMIT'
	const backButtonText: any = primaryButton?.backText ? primaryButton.backText : 'BUTTON.NEXT'

	return (
		<Grid container justifyContent="flex-end" className={classes.actionsContainer}>
			{backButton && (
				<Button
					type="button"
					color="primary"
					id="back-btn"
					variant="secondary"
					className={classes.button}
					onClick={() => backButton.clickHandler()}
				>
					{t('BUTTON.BACK')}
				</Button>
			)}
			{primaryButton && (
				<Button
					type="button"
					color="primary"
					id="continue-btn"
					variant="primary"
					className={classes.button}
					onClick={() => primaryButton.clickHandler && primaryButton.clickHandler()}
					disabled={primaryButton.disabled}
				>
					{primaryButton.type === 'submit' ? t(submitText) : t(backButtonText)}
				</Button>
			)}
		</Grid>
	)
}

export default React.memo(FormActionsView)
