import { appHeaderConfig } from 'admin-portal-shared-services'

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		changeCountry: (country: any) => void
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		changeVendor: (vendor: any) => void
	}
}

const setupLocalEnv = (): void => {
	appHeaderConfig.onChangeSelectedCountry('BR')
	appHeaderConfig.onChangeSelectedVendor('Ambev')
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	window.changeCountry = (country) => {
		appHeaderConfig.onChangeSelectedCountry(country)
	}
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	window.changeVendor = (vendor) => {
		appHeaderConfig.onChangeSelectedVendor(vendor)
	}
}

export default setupLocalEnv
