import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const ChallengeFormDetailsStyle = makeStyles((theme: Theme) =>
	createStyles({
		textHelper: {
			paddingBottom: '16px',
			margin: 0,
		},
		label: {
			fontFamily: 'Work Sans',
			fontSize: '14px',
			fontWeight: 500,
			lineHeight: '20px',
			letterSpacing: 0,
			transition: 'color ease 0.3s',
			'& label.error': {
				color: theme.palette.error.main,
			},
			'& label.disabled': {
				color: theme.palette.text.disabled,
			},
		},
	}),
)

export default ChallengeFormDetailsStyle
