import { CircularProgress, Grid } from '@material-ui/core'
import React from 'react'
import useStyles from './styles'

type Props = {
	tableId: string
}

const TableLoadingIndicator: React.FC<Props> = ({ tableId }: Props) => {
	const classes = useStyles()

	return (
		<Grid
			id={`${tableId}-container-loading-indicator`}
			container
			alignItems="center"
			justifyContent="center"
			direction="column"
			alignContent="center"
			className={classes.root}
		>
			<CircularProgress id={`${tableId}-loading-indicator`} color="inherit" />
		</Grid>
	)
}

export default TableLoadingIndicator
