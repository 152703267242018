import { ChallengeHistory } from '@/domains'
import { formatDate } from '@/utils/date'
import ChallengeHistoryDetail from '@/routes/challenges/model/new/ChallengeHistoryDetail'
import i18next from 'i18next'
import { ChallengeExecutionMethod } from '@/domains/enums'

const executionMethodToString = (executionMethod: ChallengeExecutionMethod): string =>
	({
		[ChallengeExecutionMethod.TakePhoto]: i18next.t('challenge:EXECUTION_METHOD.TAKE_A_PHOTO'),
		[ChallengeExecutionMethod.MarkAsComplete]: i18next.t('challenge:EXECUTION_METHOD.MARK_AS_COMPLETE'),
		[ChallengeExecutionMethod.Purchase]: i18next.t('challenge:EXECUTION_METHOD.PURCHASE_SINGLE_ORDER'),
		[ChallengeExecutionMethod.PurchaseMultiple]: i18next.t('challenge:EXECUTION_METHOD.PURCHASE_MULTIPLE_ORDER'),
		[ChallengeExecutionMethod.VolumeFixed]: i18next.t('challenge:EXECUTION_METHOD.PURCHASE_MULTIPLE_VOLUME_FIXED'),
		[ChallengeExecutionMethod.SpendBased]: i18next.t(
			'challenge:EXECUTION_METHOD.PURCHASE_MULTIPLE_VOLUME_SPEND_BASED_FIXED',
		),
	}[executionMethod])

const challengeHistoryToChallengeHistoryDetail = (challengeHistory: ChallengeHistory): ChallengeHistoryDetail => {
	const historyDetail: ChallengeHistoryDetail = {
		...challengeHistory,
		startDateAsString: challengeHistory.startDate
			? formatDate(challengeHistory.startDate, i18next.t('challenge:HISTORY_DETAIL_REDESIGN.DATE_FORMAT'))
			: '',
		endDateAsString: challengeHistory.endDate
			? formatDate(challengeHistory.endDate, i18next.t('challenge:HISTORY_DETAIL_REDESIGN.DATE_FORMAT'))
			: '',
		executionMethodAsString: executionMethodToString(challengeHistory.executionMethod),
		filterValues: !challengeHistory.groupsFilter
			? ''
			: challengeHistory.groupsFilter.map(({ name }) => name).join(', '),
	}

	if (historyDetail.detailedDescription) {
		historyDetail.description += ` ${historyDetail.detailedDescription}`
	}

	return historyDetail
}

export { challengeHistoryToChallengeHistoryDetail }
