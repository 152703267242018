import React, { useCallback } from 'react'
import { Card } from '@hexa-ui/components'
import { ICardOption } from '@/domains/option'
import useStyles from './styles'

type Props = {
	option: ICardOption
	selected: boolean
	onSelect: (value: string) => void
}

const CardOption: React.FC<Props> = ({ option, onSelect, selected }) => {
	const classes = useStyles()
	const selectAOption = useCallback(() => {
		onSelect(option.value)
	}, [onSelect, option.value])

	return (
		<Card border="medium" elevated="medium">
			<button className={classes.content} type="button" onClick={selectAOption} disabled={option.disabled}>
				<div className={classes.leftBox}>
					<div className={classes.checkbox}>{selected && <div className={classes.checked} />}</div>
				</div>
				<div className={classes.rightBox}>
					<div className={classes.label}>{option.label}</div>
					<div className={classes.subLabel}>{option.subLabel}</div>
				</div>
			</button>
		</Card>
	)
}

export default CardOption
