import React, { useRef, useState } from 'react'
import { Paper } from '@/components/core'
import { Box, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'
import { GlobalEvents, GlobalStore } from '@/stores'
import { useNavigation } from '@/hooks'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import TierSelector from './components/TierSelector/TierSelector'
import useStyles from './styles'

const SelectTierRoute: React.FC = () => {
	const classes = useStyles()
	const { goToHome } = useNavigation()
	const { selectedTierKey } = useStore(GlobalStore)
	const { current: defaultValueMemoized } = useRef(selectedTierKey)
	const [selectedValue, setSelectedValue] = useState<string | null>(defaultValueMemoized)
	const { t } = useTranslation()

	const handleSelect = (key: string) => {
		setSelectedValue(key)
	}

	const handleSubmit = () => {
		if (selectedValue) {
			GlobalEvents.selectTier(selectedValue)
			goToHome()
		}
	}
	return (
		<GridContainer style={{ justifyContent: 'center' }}>
			<GridItem xl={6} lg={8} md={9} sm={12}>
				<Box mb={6}>
					<Paper>
						<TierSelector defaultValue={defaultValueMemoized} onSelect={handleSelect} />
						<div className={classes.buttonsBar}>
							<Button type="button" variant="contained" color="primary" onClick={handleSubmit}>
								{t('BUTTON.SUBMIT')}
							</Button>
						</div>
					</Paper>
				</Box>
			</GridItem>
		</GridContainer>
	)
}

export default SelectTierRoute
