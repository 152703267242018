import { styled } from '@hexa-ui/theme'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		inputFile: {
			position: 'absolute',
			left: 0,
			top: 0,
			opacity: 0,
			zIndex: -1,
		},
		button: {
			lineHeight: '16px',
			marginRight: '8px',
		},
	}),
)

export default styles

export const Container = styled('div', {
	display: 'flex',
	flex: 1,
	flexDirection: 'column',
})

export const ContentLeft = styled('div', {})
export const ContentRight = styled('div', {
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	marginLeft: '15px',
	minWidth: '100px',
	maxWidth: '300px',
})
