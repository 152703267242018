import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsTableItemsPerPageChanged {
	itemsTotal: string
	itemsPerPage: string
	pageNumber: string
	tableName: string
	screenName: string
}

export const useAnalyticsServiceEventTableItemsPerPageChanged = (): {
	sendAnalyticsServiceTableItemsPerPageChanged: (recordData: AnalyticsTableItemsPerPageChanged) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceTableItemsPerPageChanged = useCallback(
		({ itemsTotal, itemsPerPage, pageNumber, tableName, screenName }: AnalyticsTableItemsPerPageChanged) => {
			AnalyticsService.events.tableItemsPerPageChanged({
				items_per_page: itemsPerPage,
				items_total: itemsTotal,
				page_number: pageNumber,
				table_name: tableName,
				screen_name: screenName,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceTableItemsPerPageChanged }
}
