import React from 'react'
import { useLogService as LogService } from 'admin-portal-shared-services'

class AppErrorBoundary extends React.Component<React.ReactNode> {
	public componentDidCatch(e: Error): void {
		const { error } = LogService()
		error(e)
	}

	public render(): React.ReactNode {
		const { children } = this.props
		return children
	}
}

export default AppErrorBoundary
