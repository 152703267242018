import React, { useMemo } from 'react'
import FormDialogTemplate from '@/components/templates/FormDialogTemplate'
import InputNumber from 'components/hook-form/InputNumber'
import * as Yup from 'yup'
import { fieldInitialBalanceValidation, fieldValidationRequired } from '@/utils/validationObjects'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Program from '@/domains/program/Program'
import EditProgramFormInputs from 'routes/programConfiguration/models/EditProgramFormInputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { isNonValue } from '@/utils/object'

type Props = {
	showEditDialog: boolean
	program: Program | undefined
	onCancel: (buttonClicked: boolean) => void
	onSubmit: (values: EditProgramFormInputs) => void
	enabledInitialBalanceInput: boolean
}
const EditProgramConfigurationView: React.FC<Props> = ({
	showEditDialog,
	program,
	onSubmit,
	onCancel,
	enabledInitialBalanceInput,
}: Props) => {
	const { t } = useTranslation()

	const schema = useMemo(
		() =>
			Yup.object().shape({
				initialBalance: fieldInitialBalanceValidation(),
				name: fieldValidationRequired(),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t],
	)
	const { handleSubmit, control, reset, formState } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		shouldFocusError: true,
	})

	React.useEffect(() => {
		// set initial form values
		reset(program)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { isDirty, isValid } = formState
	return (
		<FormDialogTemplate
			showDialog={showEditDialog}
			title={t('program:EDIT_DIALOG_TITLE')}
			cancelButtonProps={{ handleClick: onCancel }}
			submitButtonProps={{ disabled: !isDirty || !isValid }}
			handleSubmit={handleSubmit(onSubmit)}
		>
			<Controller
				control={control}
				name="initialBalance"
				render={({ field, fieldState: { error } }) => (
					<InputNumber
						{...field}
						id="program-initial-balance"
						label={t('program:LABEL_NAME.INITIAL_BALANCE')}
						errorText={error?.message}
						disabled={!enabledInitialBalanceInput}
						value={isNonValue(field.value) ? '' : field.value}
					/>
				)}
			/>
		</FormDialogTemplate>
	)
}
export default EditProgramConfigurationView
