import { createStyles, makeStyles } from '@material-ui/core/styles'

const SelectedZoneAndVendorChipsStyle = makeStyles(() =>
	createStyles({
		chipsWrapper: {
			display: 'flex',
			gap: '8px',
			padding: 0,

			'& > *': {
				backgroundColor: 'var(--colors-semanticInfoBackground)',
			},
		},
	}),
)

export default SelectedZoneAndVendorChipsStyle
