import { COMBOS_BULK_UPDATE } from '@/utils/featureToggle'
import { isToggleEnabled } from '@/hooks/toggle/useFeatureToggle'
import { deleteComboFromProgram, bulkCombosDelete } from '@/services/program/ProgramService'

async function execute(programId: string, comboIds: string[]): Promise<boolean> {
	const comboBulkUpdateToggle = isToggleEnabled(COMBOS_BULK_UPDATE)

	const servicePromise = comboBulkUpdateToggle
		? bulkCombosDelete(programId, comboIds)
		: deleteComboFromProgram(programId, comboIds[0])

	try {
		await servicePromise
		return true
	} catch (e) {
		return false
	}
}

export { execute }
