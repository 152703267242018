import { createStore } from 'effector'
import * as ToastEvents from './ToastEvents'
import ToastState from './ToastState'

const initialState: ToastState = {
	notificationRoute: '',
	notificationType: undefined,
	notificationMessage: '',
}

const ToastStore = createStore(initialState)
	.on(ToastEvents.toastShowNotification, (state, notificationValues) => {
		return {
			...state,
			notificationRoute: notificationValues.route,
			notificationType: notificationValues.notificationType,
			notificationMessage: notificationValues.message,
		}
	})
	.reset(ToastEvents.toastHideNotification)

export default ToastStore
