import ProgressiveImage from '@/components/core/ProgressiveImage'
import { InputCurrency } from '@/components/hook-form/InputCurrency'
import { ScreenName } from '@/domains'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { useIsEditingAllowed } from '@/routes/zoneConfiguration/hooks/useIsEditingAllowed'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { $pricePerPointConfigurationStore } from '@/stores'
import { format } from '@/utils/currencyHelper'
import Typography from '@material-ui/core/Typography'
import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { ItemCellStyle } from './ItemCellStyle'

type ItemCellProps = {
	isVariant?: boolean
}

export const ItemCell = ({
	image,
	id,
	itemName,
	fullPackageDescription,
	fullContainerDescription,
	vendorItemId,
	pricePerPoint,
	isVariant,
}: RedeemableItem & ItemCellProps) => {
	const { pricePerPoint: defaultPricePerPointValue } = useStore($pricePerPointConfigurationStore)

	const { t } = useTranslation()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()
	const { isRedeemableCPPPermission } = useIsEditingAllowed()
	const userInfo = useGetUserInfo()
	const css = ItemCellStyle()
	const {
		control,
		setValue,
		formState: { errors },
		reset,
	} = useFormContext()

	useEffect(() => {
		reset()

		const initialValue = pricePerPoint ? format(pricePerPoint) : undefined
		setValue('pricePerPoint', initialValue, {
			shouldDirty: false,
		})
		return () => reset()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (errors.pricePerPoint?.message && id) {
			const errorMessage = t(errors.pricePerPoint?.message)
			sendAnalyticsServiceEventError({ screenName: ScreenName.EditRedeemablePPP, failureReason: errorMessage })
		}
	}, [errors.pricePerPoint?.message, sendAnalyticsServiceEventError, t, id])

	useEffect(() => {
		if (errors?.pricePerPoint?.message && !id) {
			AnalyticsService.events.error({
				form_name: '',
				failure_reason: t(errors.pricePerPoint?.message),
				screen_name: 'Create Redeemable',
				...userInfo,
			})
		}
	}, [errors?.pricePerPoint?.message, userInfo, id, t])

	const defaultPricePerPoint = useFormatCurrency(defaultPricePerPointValue, '')

	return (
		<div
			className={classNames(css.container, {
				[css.mainVariantContainer]: !isVariant,
			})}
		>
			<div className={css.productWrapper} data-testid="redeemable-item-product">
				<div className={css.imageContainer}>
					<ProgressiveImage imageSource={image} data-testid="redeemable-item-product-image" />
				</div>
				<div className={css.productInfoContainer}>
					<Typography className={css.productName} data-testid="redeemable-item-product-name">
						{itemName}
					</Typography>
					<ItemDescription
						fullPackageDescription={fullPackageDescription}
						fullContainerDescription={fullContainerDescription}
					/>
					<Typography className={css.description} data-testid="redeemable-item-product-sku">
						SKU: {vendorItemId}
					</Typography>
				</div>
			</div>
			<div className={css.inputWrapper} data-testid="ppp-editor-input-wrapper">
				<Controller
					control={control}
					name={!isVariant ? 'pricePerPoint' : `pricePerPoint-${id}`}
					render={({ field }) => {
						return (
							<InputCurrency
								{...field}
								id="input-ppp"
								required
								placeholder={defaultPricePerPoint}
								disabled={!isRedeemableCPPPermission || isVariant}
								data-testid="ppp-editor-input"
								label={t('redeemable:CREATE.PRICE_PER_POINT')}
								errorText={t(errors.pricePerPoint?.message)}
							/>
						)
					}}
				/>
			</div>
		</div>
	)
}

type ItemDescriptionProps = {
	fullContainerDescription?: string
	fullPackageDescription?: string
}

export const ItemDescription = ({ fullPackageDescription, fullContainerDescription }: ItemDescriptionProps) => {
	const css = ItemCellStyle()

	return (
		<Typography className={css.description} data-testid="redeemable-item-product-description">
			{fullContainerDescription} - {fullPackageDescription}
		</Typography>
	)
}
