/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAuditLog } from '@/hooks/useAdminAuditLog'
import { AxiosResponse } from 'axios'

const chunkArray = (
	array: { pocId: string }[],
	size: number,
	rewardsOfferLog?: any,
): { pocId: string; campaignId?: string }[][] => {
	const result = []

	let campaignIds: string[] = []
	try {
		const rawCampaignIds = rewardsOfferLog?.metadata?.campaignId
		campaignIds = rawCampaignIds ? rawCampaignIds.split(',') : []
	} catch (e) {
		campaignIds = []
	}

	for (let i = 0; i < array.length; i += size) {
		const chunk = array.slice(i, i + size).map((item, index) => ({
			...item,
			campaignId: campaignIds.length > 0 ? campaignIds[index % campaignIds.length] || '' : '',
		}))
		result.push(chunk)
	}
	return result
}

const sendCsvChunks = async (
	chunks: { pocId: string; campaignId?: string }[][],
	rewardsOfferLog: IAuditLog,
	auditLogInstance: (props: IAuditLog) => Promise<AxiosResponse<any, any>>,
): Promise<void> => {
	for (const [index, chunk] of chunks.entries()) {
		const pocIdsChunk = chunk.map((item: { pocId: string }) => item.pocId).join(',')
		const csvChunk = JSON.stringify(chunk)

		const chunkFileName =
			chunk.length >= 5000 ? `${index + 1}-${rewardsOfferLog.metadata.fileName}` : rewardsOfferLog.metadata.fileName

		const campaignId = chunk
			.map((item) => item.campaignId)
			.filter(Boolean)
			.join(',')

		const chunkLog = {
			...rewardsOfferLog,
			metadata: {
				...rewardsOfferLog.metadata,
				campaignId: campaignId || '',
				pocLists: csvChunk,
				fileName: chunkFileName,
				fileSize: chunk.length.toString(),
			},
			entityId: pocIdsChunk,
		}
		try {
			// eslint-disable-next-line no-await-in-loop
			await auditLogInstance(chunkLog)
		} catch (error) {
			throw new Error(`Error sending chunk: ${error}`)
		}
		// eslint-disable-next-line no-await-in-loop
		await new Promise((resolve) => {
			setTimeout(resolve, 100)
		})
	}
}

export { chunkArray, sendCsvChunks }
