import { VendorEnvKey } from '@/domains/vendors'
import { useEnvService as envService } from 'admin-portal-shared-services'

const getEnv = (): string => {
	const env: string = envService()?.getEnv() || 'dev'
	return env === 'LOCALHOST' ? 'dev' : env.toLowerCase()
}

export const getVendorEnvKey = (): VendorEnvKey => {
	const currentEnv = getEnv()
	const envMap: Record<string, VendorEnvKey> = {
		qa: 'sit',
		sit: 'sit',
		dev: 'dev',
		uat: 'uat',
		prod: 'prod',
	}
	return envMap[currentEnv] || currentEnv
}

export default getEnv
