import React, { useMemo } from 'react'
import { CardSelect } from '@/components/core'
import { ICardOption } from '@/domains/option'
import { RulesEvents, RulesStore } from '@/stores'
import { useStoreMap } from 'effector-react'
import { SKUAction } from '@/domains/sku/SkuStep'
import { useTranslation } from 'react-i18next'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { ScreenName } from '@/domains'
import useStyles from './styles'

const StepAction: React.FC = () => {
	const { t } = useTranslation()
	const classes = useStyles()
	const useGetInfo = useGetUserInfo()
	const action = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => state.editSkuStepData.action ?? null,
	})

	const options = useMemo<Array<ICardOption>>(
		() => [
			{
				label: t('rules:STEPS.ACTION.ADD.LABEL'),
				subLabel: t('rules:STEPS.ACTION.ADD.SUBLABEL'),
				value: SKUAction.ADD_SKU,
			},
			{
				label: t('rules:STEPS.ACTION.MOVE.LABEL'),
				subLabel: t('rules:STEPS.ACTION.MOVE.SUBLABEL'),
				value: SKUAction.MOVE_SKU,
				disabled: true,
			},
			{
				label: t('rules:STEPS.ACTION.REMOVE.LABEL'),
				subLabel: t('rules:STEPS.ACTION.REMOVE.SUBLABEL'),
				value: SKUAction.REMOVE_SKU,
				disabled: true,
			},
		],
		[t],
	)

	const handleSelection = (value: string) => {
		RulesEvents.setPropInEditSkuStepData({
			action: value,
		})
		AnalyticsService.events.radioButtonInteraction({
			...useGetInfo,
			radio_button_name: 'Add SKU',
			radio_button_label: 'Add SKU',
			form_name: 'Earning Rules - SKUs - Edit - Step 1',
			screen_name: ScreenName.EarningRulesSkuEditStep1,
		})
	}

	return (
		<div className={classes.container}>
			<CardSelect options={options} value={action} onSelect={handleSelection} />
		</div>
	)
}

export default StepAction
