import { BASE_ROUTE } from '@/utils/constants'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export interface NavigationResult {
	goToHome: (timeout?: number) => void
	goToRoutePath: (path: string) => void
}

export const useNavigation = (): NavigationResult => {
	const history = useHistory()

	const goToHome = useCallback(
		(timeout = 300) => {
			setTimeout(() => history.push(BASE_ROUTE), timeout)
		},
		[history],
	)

	const goToRoutePath = useCallback(
		(path: string) => {
			history.push(`${BASE_ROUTE}${path}`)
		},
		[history],
	)

	return {
		goToHome,
		goToRoutePath,
	}
}
