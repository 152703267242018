import { createStyles, makeStyles } from '@material-ui/core/styles'

const EditButtonStyles = makeStyles(() =>
	createStyles({
		editButton: {
			position: 'absolute',
			top: '24px',
			right: '24px',
		},
	}),
)

export default EditButtonStyles
