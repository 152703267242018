enum TransactionType {
	Refund = 'REFUND',
	RewardsOffer = 'REWARDS_OFFER',
	PointsTransferFrom = 'POINTS_TRANSFER_FROM',
	PointsTransferTo = 'POINTS_TRANSFER_TO',
	PointsRemoval = 'POINTS_REMOVAL',
	ServiceUtility = 'SERVICE_UTILITY',
	DigitalCoupons = 'DIGITAL_COUPONS',
	PayWithPoints = 'PAY_WITH_POINTS',
}

export const normalizeTransactionType = {
	REFUND: 'Refund',
	REWARDS_OFFER: 'Rewards Offer',
	POINTS_TRANSFER_FROM: 'Points Transfer',
	POINTS_TRANSFER_TO: 'Points Transfer',
	POINTS_REMOVAL: 'Points Remove',
	SERVICE_UTILITY: 'Remove for Service Utility',
	DIGITAL_COUPONS: 'Digital Cupons',
	PAY_WITH_POINTS: 'Pay with Points',
}

export default TransactionType
