import { makeStyles, createStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			alignItems: 'center',
		},
		steps: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			paddingBottom: '20px',
		},
		content: {
			alignSelf: 'start',
			width: '100%',
		},
	}),
)

export default styles
