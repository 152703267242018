import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreMap } from 'effector-react'
import { ChallengeStore } from '@/stores/challengeCreation'
import ContainerView from './ContainerView'

type Props = {
	children: ReactNode
}

const Container: React.FC<Props> = ({ children }: Props) => {
	const { t } = useTranslation()

	const isDuplicating = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.isDuplicating,
	})

	const title = React.useMemo(() => {
		if (isDuplicating) {
			return t('challenge:FLOW_NAME_DUPLICATE')
		}
		return t('challenge:FLOW_NAME')
	}, [isDuplicating, t])

	return <ContainerView title={title}>{children}</ContainerView>
}

export default Container
