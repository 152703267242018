import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from '@material-ui/core'
import { ChallengeStatus } from '@/domains/enums'

export const ChallengeStatusBgColor: Record<ChallengeStatus, string> = {
	CREATED: '#FFEAD0',
	ACTIVE: '#DDC8FB',
	CANCELLED: '#FFDEDE',
	EXPIRED: '#F2F2F2',
}

type Props = {
	status: ChallengeStatus
}

const ChallengeStatusPill: React.FC<Props> = ({ status }) => {
	const { t } = useTranslation()

	return (
		<Chip
			size="small"
			label={t(`challenge:HISTORY.STATUS.${status}`)}
			style={{ fontWeight: 500, backgroundColor: ChallengeStatusBgColor[status] }}
			data-testid="challenge-status-pill"
		/>
	)
}

export default ChallengeStatusPill
