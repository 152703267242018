import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& input::-webkit-outer-spin-button': {
				WebkitAppearance: 'none',
			},
			'& input::-webkit-inner-spin-button': {
				WebkitAppearance: 'none',
			},
			'& input[type=number]': {
				MozAppearance: 'textfield',
			},
		},
		helperText: {
			fontSize: '12px',
			lineHeight: '16px',
			color: theme.palette.text.secondary,
			margin: '0 0 4px 0',
		},
	}),
)

export default styles
