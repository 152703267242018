import { ComboFormDataSingle } from '@/stores'
import { UpdateComboUseCase } from '@/usecase'

export const updateSingleComboHelper = (comboBulkUpdateTransaction: ComboFormDataSingle) => {
	const [redemables] = comboBulkUpdateTransaction.formData.redeemables
	const { redeemableId, vendorComboId } = redemables
	const points = Number(redemables.points)

	return UpdateComboUseCase.execute({ redeemableId, points, vendorComboId })
}
