import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import SkuListTableView from './SkuListTableView'
import { useLoadSkuList } from '../../../hooks/useLoadSkuList'

type SkuListTableProps = {
	hasDelete?: boolean
}

const SkuListTable: React.FC<SkuListTableProps> = ({ hasDelete }: SkuListTableProps) => {
	const { selectedTierKey } = useCurrentTier()
	const { ruleId } = useParams<{ ruleId: string }>()
	const { loadSkuList, loading } = useLoadSkuList(ruleId, selectedTierKey ?? '')

	useEffect(() => {
		loadSkuList()
	}, [loadSkuList])

	return <SkuListTableView loading={loading} hasDelete={hasDelete} />
}

export default SkuListTable
