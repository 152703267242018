import { createStyles, makeStyles } from '@material-ui/core/styles'

const border = '1px solid #dfdfdf'
const size = 460

const style = makeStyles(() =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			border: '1px solid #dfdede',
			borderRadius: '4px',
			padding: '16px',
			margin: '16px 0px',
		},
		typography: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		table: {
			width: `${size}px`,
			borderCollapse: 'collapse',
			margin: '26px',
		},
		trHead: {},
		trBody: {},
		empty: {
			borderRight: '5px solid #bcbcbc',
			borderBottom: '5px solid #bcbcbc',
			display: 'block',
			width: '100%',
			height: '100%',
		},
		column: {
			color: '#939191',
			fontSize: '12px',
			fontFamily: 'Work Sans',
			lineHeight: '13px',
			letterSpacing: '0',
			fontWeight: 'normal',
			fontStyle: 'italic',
			paddingBottom: '8px',
			'&:last-child': {
				paddingLeft: '8px',
				width: `100px`,
			},
		},
		columnName: {
			textAlign: 'center',
			backgroundColor: 'white',
			fontSize: '13px',
			fontWeight: 'bold',
			borderTop: border,
			borderRight: border,
			width: `${(0.75 * size) / 3}px`,
			'&:not(:last-child)': {
				borderBottom: border,
			},
		},
		columnValue: {
			textAlign: 'right',
			backgroundColor: 'white',
			fontSize: '13px',
			paddingRight: '5px',
			borderTop: border,
			borderRight: border,
			'&:not(:last-child)': {
				borderBottom: border,
			},
		},
		td: {
			textAlign: 'center',
			backgroundColor: '#f8f9fa',
			borderRight: border,
			borderTop: border,
			borderLeft: 'none',
			height: '25px',
			minWidth: '45px',
			padding: '0',
		},
	}),
)

export default style
