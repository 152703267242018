import { useEnvService as envService } from 'admin-portal-shared-services'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PageEnvironmentInfo from '../PageEnvironmentInfo'
import SelectedInfo from './components/SelectedInfo'
import useStyles from './styles'

type Props = {
	children: React.ReactNode
}

const MainContent: React.FC<Props> = ({ children }: Props) => {
	const classes = useStyles()

	return (
		<main
			id="main-content"
			data-testid="page-main-content"
			className={classNames(classes.content, [classes.contentNewLayout])}
		>
			<LowerEnvironmentInfo />
			<SelectedInfo />
			{children}
		</main>
	)
}

const LowerEnvironmentInfo = () => {
	const { t } = useTranslation()
	const env: string = envService()?.getEnv().toString().toUpperCase()

	if (env === 'PROD') {
		return null
	}

	return (
		<div style={{ marginBottom: 'var(--space-2)' }}>
			<PageEnvironmentInfo id="page-environment-info">{t('common:DEV_ENVIRONMENT_MESSAGE')}</PageEnvironmentInfo>
		</div>
	)
}

export default MainContent
export { MainContent }
