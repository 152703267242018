import React from 'react'
import { RouteLeavingGuard } from '@/components/core'
import RouteLeavingGuardOptions from '@/components/templates/SingleFormTemplate/model/RouteLeavingGuardOptions'
import { BASE_ROUTE } from '@/utils/constants'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router'

type ChallengeLeavingGuardProps = {
	whiteList?: string[]
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ChallengeLeavingGuard = ({ whiteList }: ChallengeLeavingGuardProps) => {
	const { t } = useTranslation()
	const history = useHistory()

	const routeLeavingOptions: RouteLeavingGuardOptions = {
		whiteList: whiteList ? [...whiteList] : [BASE_ROUTE],
		alertMessage: t('DIALOG.ROUTE_LEAVING_MESSAGE_2', { name: t('transaction:NAME') }),
		alertTitle: t('DIALOG.ROUTE_LEAVING_HEADER'),
	}

	const {
		formState: { isDirty },
	} = useFormContext()

	return (
		<RouteLeavingGuard
			when={isDirty}
			navigate={(path) => {
				history.push(`${path}`)
			}}
			whiteList={routeLeavingOptions.whiteList}
			alertTitle={routeLeavingOptions.alertTitle}
			alertMessage={routeLeavingOptions.alertMessage}
		/>
	)
}

export default ChallengeLeavingGuard
export { ChallengeLeavingGuard }
