import { Transparency, TransparencyTransaction } from '@/domains'
import axios from 'utils/axiosInstance'
import { useLogService as logService } from 'admin-portal-shared-services'
import PagedResponse from 'domains/PagedResponse'
import { TransparencyType } from '@/domains/enums'

const TRANSPARENCY_PAGE_SIZE = 900000
const TRANSACTIONS_PAGE_SIZE = 5000

const log = logService()
const endPointBase = `/v1/rewards-transparency-service/rewards-transparency`

export async function load(
	type: TransparencyType = TransparencyType.RewardsOfferMultiple,
): Promise<PagedResponse<Transparency>> {
	const response = await axios
		.get<PagedResponse<Transparency>>(endPointBase, {
			params: { pageSize: TRANSPARENCY_PAGE_SIZE, type },
		})
		.catch((error) => {
			log.error(`Error endpoint: ${endPointBase}`, error)
			throw error
		})
	return response.data
}

export async function loadTransactions(transparencyId: string): Promise<PagedResponse<TransparencyTransaction>> {
	const response = await axios
		.get<PagedResponse<TransparencyTransaction>>(`${endPointBase}/${transparencyId}/transactions`, {
			params: { pageSize: TRANSACTIONS_PAGE_SIZE },
		})
		.catch((error) => {
			log.error(`Error endpoint: ${endPointBase}`, error)
			throw error
		})

	return response.data
}
