import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { InputDate } from '@/components/form'
import { useTranslation } from 'react-i18next'
import { ChallengeFilterFormData } from '@/stores/challengeCreation'

type Props = {
	disabled?: boolean
}

const ChallengeFormFilterStartDate: React.FC<Props> = ({ disabled }: Props) => {
	const { t } = useTranslation()
	const { control, setValue, trigger } = useFormContext<ChallengeFilterFormData>()

	return (
		<Controller
			control={control}
			name="startDate"
			render={({ field, fieldState: { error } }) => (
				<InputDate
					id="challenge-start-date"
					label={t('challenge:FILTERS_STEP.START_DATE')}
					errorText={error?.message}
					onChange={(_, inputValue) => setValue(field.name, inputValue ?? undefined, { shouldDirty: true })}
					onBlur={() => {
						field.onBlur()
						trigger(field.name)
					}}
					disabled={disabled}
					value={field.value}
					name={field.name}
					keyboardDataPickerProps={{
						onClose: () => {
							trigger(field.name)
						},
						invalidDateMessage: null,
					}}
				/>
			)}
		/>
	)
}

export default ChallengeFormFilterStartDate
