import * as Yup from 'yup'
import React, { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@material-ui/core'
import { fieldPointsValidation } from '@/utils/validationObjects'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import FormDialogTemplate from '@/components/templates/FormDialogTemplate'
import { Redeemable } from '@/domains'
import InputNumber from '@/components/form/InputNumber'

import { isCombosMultivendorEnabled } from '@/utils/featureToggle/featureToggleHelper'
import useStyles from './EditRedeemableDialogStyle'
import EditRedeemableFormInputs from '../../models/EditRedeemableFormInputs'

type Props = {
	show: boolean
	singleEdit: boolean
	redeemables: Redeemable[]
	onSubmit: (values: EditRedeemableFormInputs) => void
	onCancel: (buttonClicked: boolean) => void
}

const EditRedeemableDialogView: React.FC<Props> = ({ show, singleEdit, redeemables, onSubmit, onCancel }: Props) => {
	const isMultiVendorCombosEnabled = isCombosMultivendorEnabled()
	const { t } = useTranslation()
	const classes = useStyles()

	const schema = useMemo(
		() =>
			Yup.object().shape({
				redeemables: Yup.array().of(
					Yup.object().shape({
						points: fieldPointsValidation(),
					}),
				),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t],
	)

	const {
		handleSubmit,
		control,
		reset,
		formState: { isDirty },
	} = useForm({
		resolver: yupResolver(schema),
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldFocusError: true,
	})

	const { fields } = useFieldArray({
		control,
		name: 'redeemables',
	})

	useEffect(() => {
		reset(
			{
				redeemables,
			},
			{
				keepDirty: false,
			},
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getIdColumnValue = (redeemable: Redeemable) => {
		if (isMultiVendorCombosEnabled) {
			return redeemable.vendorComboId ?? redeemable.redeemableId
		}
		return redeemable.redeemableId
	}

	const title = t(`combo:EDIT_DIALOG_TITLE.${singleEdit ? 'SINGLE' : 'MULTIPLE'}` as const)

	return (
		<FormDialogTemplate
			title={title}
			showDialog={show}
			cancelButtonProps={{ handleClick: onCancel }}
			submitButtonProps={{ name: t('combo:SUBMIT_TEXT'), disabled: !isDirty }}
			handleSubmit={handleSubmit(onSubmit)}
		>
			{fields.map((redeemable, index) => (
				<Grid container key={redeemable.id}>
					<Grid item container xs={9}>
						<Box display="flex" flexDirection="column" flex="100%" justifyContent="center" mt="24px" alignItems="start">
							{redeemables[index].name && (
								<Typography id="combo-name" className={classes.comboInfoText}>
									{`${t('combo:EDIT_DIALOG_NAME_LABEL')}: ${redeemables[index].name}`}
								</Typography>
							)}
							<Typography id="combo-id" className={classes.comboInfoText}>
								{`${t('combo:EDIT_DIALOG_ID_LABEL')}: ${getIdColumnValue(redeemables[index])}`}
							</Typography>
						</Box>
					</Grid>
					<Grid item container xs={3}>
						<Controller
							control={control}
							defaultValue={redeemables[index].points}
							name={`redeemables.${index}.points`}
							render={({ field, fieldState: { error } }) => (
								<InputNumber
									id="combo-points"
									{...field}
									name={field.name}
									errorText={error?.message}
									label={t('combo:POINTS_VALUE')}
								/>
							)}
						/>
					</Grid>
				</Grid>
			))}
		</FormDialogTemplate>
	)
}

export default EditRedeemableDialogView
