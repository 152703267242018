import React, { FC, memo } from 'react'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { Paragraph } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import ItemCell from '../CreateRedeemableItemV2/content/ItemCell/ItemCell'
import EditRedeemableItemStyle from './styles'

type EditRedeemableItemContentProps = {
	item: RedeemableItem
}

const EditRedeemableItemContent: FC<EditRedeemableItemContentProps> = ({ item }) => {
	const { t } = useTranslation()
	const css = EditRedeemableItemStyle()

	return (
		<div className={css.container} data-testid="edit-redeemable-item-content">
			<div data-testid="edit-redeemable-item-description" className={css.descriptionWrapper}>
				<Paragraph>{t('redeemable:EDIT_ITEM.DESCRIPTION')}</Paragraph>
			</div>
			<div className={css.itemCellWrapper}>
				<ItemCell {...item} />
			</div>
		</div>
	)
}

export default memo(EditRedeemableItemContent)
