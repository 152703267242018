import { deleteCombo, deleteComboBulk } from '@/services/redeemables/RedeemablesService'
import { hasElements } from '@/utils/array'

async function execute(redeemableIds: Array<string>): Promise<boolean> {
	try {
		if (!hasElements(redeemableIds)) {
			throw new Error('No items')
		}
		if (redeemableIds.length === 1) {
			await deleteCombo(redeemableIds[0])
			return true
		}
		await deleteComboBulk(redeemableIds)
		return true
	} catch (e) {
		return false
	}
}

export { execute }
