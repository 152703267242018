import { makeStyles } from '@material-ui/core/styles'

const pointsTransferStyle = makeStyles({
	totalPointsBalance: {
		margin: '24px 0',
		lineHeight: '19px',
		fontSize: '16px',
	},
	totalPointsBalanceLabel: {
		fontWeight: 500,
	},
	totalPointsBalanceValue: {
		fontWeight: 400,
	},
	pageLevelErrorMessage: {
		fontSize: '14px',
		lineHeight: '20px',
		position: 'relative',
		paddingLeft: '10px',
	},
})

export default pointsTransferStyle
