import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { GridContainer } from '@/components/templates/GridTemplate'

const TableLoading: React.FC = () => (
	<GridContainer style={{ justifyContent: 'center' }}>
		<CircularProgress color="inherit" />
	</GridContainer>
)

export default TableLoading
