import Rule from '@/domains/rule/Rule'
import { useNavigation } from '@/hooks'
import { useCallback } from 'react'

type RuleParam = Pick<Rule, 'ruleId' | 'name'>

export interface NavigateToRulesResult {
	goToRulesList: () => void
	goToRulesDetails: (rule: RuleParam) => void
	goToEditSku: (rule: RuleParam) => void
	goToCreateRule: () => void
	goToEditRule: (rule: RuleParam) => void
	goToRules: () => void
}

export const useNavigateToRules = (): NavigateToRulesResult => {
	const { goToRoutePath } = useNavigation()

	const goToRulesList = useCallback(() => {
		goToRoutePath(`/rules`)
	}, [goToRoutePath])

	const goToRulesDetails = useCallback(
		(rule: RuleParam) => {
			goToRoutePath(`/rules/${rule.ruleId}/${rule.name.replace('/', '')}`)
		},
		[goToRoutePath],
	)

	const goToEditSku = useCallback(
		(rule: RuleParam) => {
			const parsedRuleName = rule.name.replace('/', '')
			goToRoutePath(`/rules/${rule.ruleId}/${parsedRuleName}/edit-sku`)
		},
		[goToRoutePath],
	)

	const goToEditRule = useCallback(
		(rule: RuleParam) => {
			const parsedRuleName = rule.name.replace('/', '')
			goToRoutePath(`/rules/${rule.ruleId}/${parsedRuleName}/edit-rule`)
		},
		[goToRoutePath],
	)

	const goToCreateRule = useCallback(() => {
		goToRoutePath(`/rules/create-rule`)
	}, [goToRoutePath])

	const goToRules = useCallback(() => {
		goToRoutePath(`/rules`)
	}, [goToRoutePath])

	return {
		goToRulesList,
		goToRulesDetails,
		goToEditSku,
		goToCreateRule,
		goToEditRule,
		goToRules,
	}
}
