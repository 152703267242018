import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { getChallengeFilterName } from '@/lists/analytics/AnalyticsChallengeFilterList'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ChallengeFilterFormData } from '@/stores/challengeCreation'
import { InputSelect, SelectOptions } from '@/components/form'
import { ChallengeFormFilterOption } from '@/domains'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'

type Props = {
	index: number
	defaultValue: ChallengeFormFilterOption
	filterOptions: Array<SelectOptions>
	disabled: boolean
}

const FilterBoxFilterSelect: React.FC<Props> = ({ index, defaultValue, filterOptions, disabled }: Props) => {
	const { t } = useTranslation()
	const { control, setValue, trigger } = useFormContext<ChallengeFilterFormData>()
	const userInfo = useGetUserInfo()

	const handleChangeFilterSelect = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
		setValue(`filters.${index}.value` as const, [])
		trigger(`filters.${index}.value`)
		AnalyticsService.events.selectInteraction({
			select_name: 'Filter',
			select_label: 'Filter',
			selected_value: getChallengeFilterName(event.target.value as string),
			form_name: 'Challenge Creation - Step 2 - Target Type',
			screen_name: 'Challenge Creation - 2nd Step',
			...userInfo,
		})
	}

	return (
		<Controller
			name={`filters.${index}.filter` as const}
			control={control}
			defaultValue={defaultValue.filter}
			render={({ field: { value, onChange, ...field } }) => (
				<InputSelect
					{...field}
					id={`filter-${index}`}
					label={t('challenge:FILTERS_STEP.FILTER')}
					options={filterOptions}
					value={value || ''}
					autoFocus={false}
					disabled={disabled}
					onChange={(event) => {
						handleChangeFilterSelect(event)
						onChange(event)
					}}
					helperText=""
				/>
			)}
		/>
	)
}

export default FilterBoxFilterSelect
