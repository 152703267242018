import { RemovalForServiceUtilityTransaction } from '@/domains'
import { ServiceUtilityCategory } from '@/domains/enums'
import RemovalForServiceUtilityResponse from '@/domains/transaction/RemovalForServiceUtilityResponse'
import * as TransactionService from '@/services/transaction/TransactionService'

async function execute(
	accountId: string,
	amount: number,
	description: string,
	category: string = ServiceUtilityCategory.electricityBill,
): Promise<RemovalForServiceUtilityResponse> {
	const requestBody = {
		description,
		points: amount,
		category,
	} as RemovalForServiceUtilityTransaction

	return TransactionService.removalForServiceUtility(accountId, requestBody)
}

export { execute }
