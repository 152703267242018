import { useLogService as logService } from 'admin-portal-shared-services'
import GroupPurpose from 'domains/enums/segmentation/GroupPurposeEnum'
import axios from '@/utils/axiosInstance'
import Group from '@/domains/Group'

const endPoint = `/v1/segmentation-service/groups`
const log = logService()
const purposes = GroupPurpose.ChallengeFilter

async function loadAll(): Promise<Array<Group>> {
	const response = await axios.get<Array<Group>>(`${endPoint}`, { params: { purposes } }).catch((error) => {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	})

	return response.data
}

export { loadAll }
