import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		dialog: {
			maxWidth: '720px',
			margin: 'auto auto',
		},
		title: {
			fontFamily: 'Barlow',
			fontSize: '20px',
			fontWeight: 500,
			letterSpacing: '0',
			lineHeight: '24px',
		},
		dialogContainer: {
			padding: '16px 8px',
		},
		dialogAction: {
			padding: '8px 24px 16px 24px',
		},
		cancelButton: {
			marginRight: theme.spacing(1),
		},
	}),
)

export default styles
