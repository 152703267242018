import { isCriticalPPP } from '@/usecase/redeemableItems/CriticalPricePerPointUseCase'
import { createStore, createEvent } from 'effector'
import { $pricePerPointConfigurationStore } from './PricePerPointConfigurationStore'

export type CriticalPricePerPointConfiguration = {
	isOpened: boolean
	skuPricePerPoint: number
	submit: () => void
	back: () => void
}

const criticalPricePerPointConfigurationInitialState: CriticalPricePerPointConfiguration = {
	isOpened: false,
	skuPricePerPoint: 0,
	submit: () => undefined,
	back: () => undefined,
}

export const $criticalPricePerPointConfigurationStore = createStore(criticalPricePerPointConfigurationInitialState)

// Events

export type CriticalPricePerPointEventProps = {
	back: () => void
	submit: () => void
	close: () => void
	skuPricePerPoint: number
}

export const CriticalPricePerPointConfigurationStoreEvents = {
	onOpen: createEvent<CriticalPricePerPointEventProps>(),
	onClose: createEvent('onClose'),
	reset: createEvent('reset'),
}

const actionForNonCriticalPPP = (submit: () => void): boolean => {
	submit()
	return false
}

const actionForCriticalPPP = (close: () => void): boolean => {
	close()
	return true
}

const validate = (pricePerPoint: number, close: () => void, submit: () => void): boolean => {
	const { pricePerPoint: pricePerPointDefault } = $pricePerPointConfigurationStore.getState()
	return isCriticalPPP(pricePerPoint, pricePerPointDefault)
		? actionForCriticalPPP(close)
		: actionForNonCriticalPPP(submit)
}

const onOpen = (
	state: CriticalPricePerPointConfiguration,
	payload: CriticalPricePerPointEventProps,
): CriticalPricePerPointConfiguration => ({
	...state,
	isOpened: validate(payload.skuPricePerPoint, payload.close, payload.submit),
	skuPricePerPoint: payload.skuPricePerPoint,
	submit: payload.submit,
	back: payload.back,
})

const onClose = (state: CriticalPricePerPointConfiguration): CriticalPricePerPointConfiguration => ({
	...state,
	isOpened: false,
})

// Handlers

$criticalPricePerPointConfigurationStore
	.on(CriticalPricePerPointConfigurationStoreEvents.onOpen, onOpen)
	.on(CriticalPricePerPointConfigurationStoreEvents.onClose, onClose)
	.reset(CriticalPricePerPointConfigurationStoreEvents.reset)
