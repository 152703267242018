import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsRecordUpdated {
	screenName: string
	recordId: string
	recordType: string
	formName?: string
	recordAdditionalInfo?: string
}

export const useAnalyticsServiceRecordUpdated = (): {
	sendAnalyticsServiceRecordUpdated: (recordData: AnalyticsRecordUpdated) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceRecordUpdated = useCallback(
		({ recordId, recordType, screenName, formName, recordAdditionalInfo }: AnalyticsRecordUpdated) => {
			AnalyticsService.events.recordUpdated({
				screen_name: screenName,
				record_id: recordId,
				record_type: recordType,
				form_name: formName ?? null,
				record_additional_info: recordAdditionalInfo ?? null,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceRecordUpdated }
}
