import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { Paragraph } from '@hexa-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ItemCell } from '../CreateRedeemableItem/content/ItemCell/ItemCell'
import { SelectedZoneAndVendorChips } from '../SelectedZoneAndVendorChips'
import EditRedeemableItemStyle from './EditRedeemableItemStyle'

type EditRedeemableItemContentProps = {
	item: RedeemableItem
}

export const EditRedeemableItemContent = ({ item }: EditRedeemableItemContentProps) => {
	const { t } = useTranslation()
	const css = EditRedeemableItemStyle()

	return (
		<div className={css.container} data-testid="edit-redeemable-item-content">
			<div data-testid="edit-redeemable-item-description" className={css.descriptionWrapper}>
				<Paragraph>{t('redeemable:EDIT_ITEM.DESCRIPTION')}</Paragraph>
			</div>
			<div className={css.chipsWrapper}>
				<SelectedZoneAndVendorChips />
			</div>
			<div className={css.itemCellWrapper}>
				<ItemCell {...item} />
			</div>
		</div>
	)
}
