import palette from './palette'

export default {
	MuiDrawer: {
		paperAnchorDockedLeft: {
			borderRight: 'none',
			boxShadow: '0 0.75rem 1.5rem rgba(18,38,60,.03)',
		},
	},
	MuiButton: {
		root: {
			padding: '8px 16px 8px 16px',
		},
		contained: {
			boxShadow: '0 2px 2px 0 rgba(0,0,0,0.24)',
		},
		outlined: {
			boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
			padding: '8px 16px 8px 16px',
		},
		containedSizeSmall: {
			fontSize: '14px',
			height: '32px',
		},
	},
	MuiTableCell: {
		root: {
			fontSize: '16px',
			lineHeight: '20px',
			letterSpacing: '0',
		},
		head: {
			border: 'none',
			boxShadow: 'none',
			padding: '0px 32px',
			lineHeight: '20px',
			'&:first-child': {
				paddingLeft: '0',
			},
			'&:last-child': {
				paddingRight: '0',
			},
		},
		body: {
			border: 'none',
			boxShadow: 'inset 0 -1px 0 0 #DFDEDE',
			padding: '32px',
			'&:first-child': {
				paddingLeft: '0',
			},
			'&:last-child': {
				paddingRight: '0',
			},
		},
	},
	MuiPaginationItem: {
		page: {
			color: palette.info.main,
			textDecoration: 'underline',
			fontSize: '16px',
			lineHeight: '20px',
			'&$selected': {
				backgroundColor: palette.common.white,
				color: palette.common.black,
				fontWeight: '600',
				textDecoration: 'initial',
				cursor: 'unset',
				'&:hover': {
					backgroundColor: palette.common.white,
				},
				'&:active': {
					backgroundColor: palette.common.white,
				},
			},
			'& svg': {
				color: palette.common.black,
			},
		},
	},
	MuiDialog: {
		paperScrollPaper: {
			overflowY: 'hidden',
		},
	},
	MuiDialogContent: {
		root: {
			overflowY: 'auto',
			maxHeight: 'calc(100vh - 80px - 70px - 72px)', // modal padding, modal header and footer height
			'&:first-child': {
				paddingTop: '0px',
			},
			'&::-webkit-scrollbar': {
				width: '8px',
			},
			'&::-webkit-scrollbar-track': {
				backgroundColor: 'transparent',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#707372',
				borderRadius: '10px',
			},
			'&::-webkit-scrollbar-thumb:hover': {
				backgroundColor: '#555',
			},
		},
	},
	MuiTextField: {
		root: {
			flexDirection: 'column-reverse',
			marginTop: '0px',
			'& p.Mui-error': {
				marginBottom: '4px',
				marginLeft: '16px',
			},
			'& input::-webkit-outer-spin-button': {
				WebkitAppearance: 'none',
			},
			'& input::-webkit-inner-spin-button': {
				WebkitAppearance: 'none',
			},
			'& input[type=number]': {
				MozAppearance: 'textfield',
			},
		},
	},
	MuiFormHelperText: {
		root: {
			fontSize: '12px',
			lineHeight: '16px',
			color: palette.text.secondary,
			margin: '0 0 4px 0',
		},
	},
	MuiRadio: {
		root: {
			color: '#B9BCC1',
		},
		colorSecondary: {
			'&$checked': {
				color: palette.text.primary,
			},
		},
	},
	MuiFormControlLabel: {
		root: {
			marginLeft: '0px',
		},
	},
	MuiCheckbox: {
		root: {
			color: '#B9BCC1',
		},
		colorSecondary: {
			'&$checked': {
				color: '#000000',
			},
		},
	},
	MuiTab: {
		textColorInherit: { color: palette.text.secondary, opacity: '1' },
		root: {
			textTransform: 'none',
			maxWidth: '115px',
			padding: '6px 18px',
			fontSize: '16px',
			'&$selected': {
				color: palette.info.main,
			},
			'&$focus': {
				color: palette.info.main,
			},
		},
	},
	MuiTabs: {
		indicator: {
			backgroundColor: palette.info.main,
			'& > span': {
				maxWidth: '40',
				width: '100%',
				backgroundColor: palette.info.main,
				fontWeight: 700,
			},
		},
		flexContainer: {
			borderBottom: 'solid #e7eaf3',
		},
	},
	MuiSwitch: {
		root: {
			width: '50px',
			height: '30px',
			padding: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		thumb: {
			width: '28px',
			height: '28px',
			padding: 0,
			margin: 2,
		},
		switchBase: {
			color: palette.common.white,
			top: '-10px',
			left: '-10px',
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		colorSecondary: {
			'&$checked': {
				color: palette.common.white,
				transform: 'translateX(20px)',
			},
		},
		track: {
			opacity: 1,
			backgroundColor: '#DFDEDE',
			borderRadius: '20px',
			'$checked$checked + &': {
				opacity: 1,
				backgroundColor: palette.common.black,
			},
		},
	},
}
