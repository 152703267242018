import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import { Card } from '@hexa-ui/components'
import React from 'react'
import SkuListTable from '../../Components/SkuListTable/SkuListTable'
import EditSkuStepper from './EditSkuStepper/EditSkuStepper'
import useStyles from './EditSkuStyle'

const EditSkuView: React.FC = () => {
	const classes = useStyles()

	return (
		<>
			<PageLevelTemplate />
			<Card border="large" elevated="small" className={classes.editSkuCard}>
				<EditSkuStepper />
				<SkuListTable hasDelete />
			</Card>
		</>
	)
}

export default EditSkuView
