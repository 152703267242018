import React from 'react'
import { Tooltip } from '@/components/core'
import { hasText } from '@/utils/string'

type Props = {
	children: React.ReactNode
	title?: string
	disabled?: boolean
}

const SubmitButtonTooltip: React.FC<Props> = ({ children, title, disabled }: Props) => {
	if (!hasText(title)) {
		return <>{children}</>
	}
	return (
		<Tooltip title={title!} disabled={disabled}>
			<span>{children}</span>
		</Tooltip>
	)
}

export default SubmitButtonTooltip
