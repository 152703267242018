/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { PageLevelNotification } from '@/components/core'
import TransparencyTransaction from 'domains/transparency/TransparencyTransaction'
import VirtualizedTable from 'components/tables/VirtualizedTable'
import useDialogStyles from 'components/formik/FormDialog/styles'
import useStyles from './FailedPocsDialogStyle'

type Props = {
	show: boolean
	failedPocs: TransparencyTransaction[]
	handleDownload: () => void
	handleCancel: (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => void
	description: string
}

const FailedPocsDialogView: React.FC<Props> = ({
	show,
	failedPocs,
	handleDownload,
	handleCancel,
	description,
}: Props) => {
	const { t } = useTranslation()
	const dialogClasses = useDialogStyles()
	const classes = useStyles()
	const theme = useTheme()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [scrollElement, setScrollElement] = useState<any>(null)

	return (
		<Dialog
			open={show || false}
			onClose={handleCancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth
			className={classes.dialog}
			maxWidth="lg"
		>
			<div className={dialogClasses.dialogContainer}>
				<DialogTitle>
					<Typography id="alert-dialog-title" className={dialogClasses.title}>
						{t('transaction:REWARDS_OFFER.ACTIVITY_LOG.TITLE')}
					</Typography>
				</DialogTitle>

				<DialogContent ref={(node) => setScrollElement(node)}>
					<Typography id="alert-dialog-description">{description}</Typography>

					<div className={classes.infoContainer}>
						<PageLevelNotification type="info" multiline>
							<p className={classes.infoText}>{t('transaction:REWARDS_OFFER.ACTIVITY_LOG.INFO')}</p>
						</PageLevelNotification>
					</div>

					<VirtualizedTable
						id="log-table"
						scrollElement={scrollElement}
						columns={[
							{
								dataKey: 'accountId',
								label: t('transaction:REWARDS_OFFER.POC_ID'),
								align: 'left',
								width: 200,
								format: (el) => (el ? el.toString() : '—'),
								style: { color: theme.palette.error.dark, fontWeight: 500 },
							},
							{
								dataKey: 'campaignId',
								label: t('transaction:REWARDS_OFFER.CAMPAIGN_ID'),
								align: 'left',
								width: 200,
								format: (el) => (el ? el.toString() : '—'),
							},
							{
								dataKey: 'points',
								label: t('transaction:REWARDS_OFFER.POINTS'),
								align: 'left',
								width: 150,
								format: (el) => (el ? el.toString() : '—'),
							},
							{
								dataKey: 'error',
								label: t('transaction:REWARDS_OFFER.ERROR'),
								align: 'left',
								width: 200,
								format: (el) => (el ? el.toString() : '—'),
							},
						]}
						data={failedPocs}
						configs={{
							rowHeight: 84,
							headerHeight: 34,
						}}
					/>
				</DialogContent>

				<DialogActions className={dialogClasses.dialogAction}>
					<Button
						id="cancel-btn"
						onClick={handleCancel}
						variant="outlined"
						color="primary"
						className={dialogClasses.cancelButton}
					>
						{t('BUTTON.CLOSE')}
					</Button>
					<Button id="download-btn" variant="contained" color="primary" onClick={handleDownload}>
						{t('BUTTON.DOWNLOAD_CSV')}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	)
}

export default FailedPocsDialogView
