import Rule from '@/domains/rule/Rule'
import { RulesStore } from '@/stores'
import { useStoreMap } from 'effector-react'
import { useMemo } from 'react'

export const useRule = (ruleId: string): Rule => {
	const { rulesList } = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => ({
			rulesList: state.rules,
		}),
	})

	return useMemo<Rule>(() => {
		return rulesList?.find((r) => r.ruleId === ruleId) as Rule
	}, [ruleId, rulesList])
}
