import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { InsertDriveFileOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { ButtonInteraction, ScreenName } from '@/domains'
import ChallengeHistoryDetail from 'routes/challenges/model/ChallengeHistoryDetail'
import { Tooltip } from '../index'

type Props = {
	challenge: ChallengeHistoryDetail
	copyText?: string
}

const CopyButton: React.FC<Props> = ({ challenge, copyText = '' }: Props) => {
	const [copyStatus, setCopyStatus] = useState<boolean>(false)
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()

	const handleCopyText = () => {
		navigator.clipboard.writeText(copyText)
		setCopyStatus(true)
		AnalyticsService.events.buttonClicked({
			button_name: ButtonInteraction.CopyCampaignId,
			button_label: `${t('common:COPY')}`,
			screen_name: `${ScreenName.ChallengeDetails} - ${challenge.title} - ${challenge.executionMethod}`,
			...userInfo,
		})
	}

	const handleMouseOver = () => setCopyStatus(false)

	return (
		<Tooltip title={copyStatus ? t('common:COPIED') : t('common:COPY')}>
			<Button
				id="copy-button"
				style={{ padding: '0', minWidth: '5px' }}
				onClick={handleCopyText}
				onMouseOver={handleMouseOver}
			>
				<InsertDriveFileOutlined style={{ pointerEvents: 'none', height: '20px' }} />
			</Button>
		</Tooltip>
	)
}

export default CopyButton
