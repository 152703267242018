import React from 'react'
import { CircularProgress, TableCell, TableCellProps, TableSortLabel, Box } from '@material-ui/core'
import { useDataTableContext } from '../context/DataTableContext'

type Props = {
	id: string
	label: string
	align: TableCellProps['align']
	width: string
	minWidth?: string
	sortDirection: TableCellProps['sortDirection']
	active: boolean
	direction: 'asc' | 'desc'
	onClick: () => void
	dataKey: string
	infoIcon?: JSX.Element
}

const TableSortCell: React.FC<Props> = ({
	id,
	label,
	align,
	width,
	minWidth,
	sortDirection,
	active,
	direction,
	onClick,
	dataKey,
	infoIcon,
}: Props) => {
	const { sortLoadingField } = useDataTableContext()

	return (
		<TableCell id={id} align={align} style={{ maxWidth: width, width, minWidth }} sortDirection={sortDirection}>
			<TableSortLabel active={active} direction={direction} onClick={onClick}>
				<Box display="flex">
					{label}
					{infoIcon}
				</Box>
			</TableSortLabel>
			{sortLoadingField === dataKey && <CircularProgress color="inherit" size={10} />}
		</TableCell>
	)
}

export default React.memo(TableSortCell)
