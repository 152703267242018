import { RedeemablesMap } from '@/domains/redeemable/Redeemable'
import Program from 'domains/program/Program'
import { createStore } from 'effector'
import * as ComboEvents from './ComboEvents'
import { comboInitialState, ComboState, RedeemableEditPoints } from './ComboState'

const updatePoints = (prevRedeemablesMap: RedeemablesMap, redeemableId: string, points: number): RedeemablesMap => {
	const redeemable = prevRedeemablesMap[redeemableId]
	if (redeemable) {
		return {
			...prevRedeemablesMap,
			[redeemable.redeemableId]: {
				...redeemable,
				points,
			},
		}
	}
	return prevRedeemablesMap
}

const onSetRedeemablePoints = (state: ComboState, redeemableEditPoints: Array<RedeemableEditPoints>): ComboState => {
	let redeemablesMapCopy = {
		...state.combosData.redeemablesMap,
	}
	redeemableEditPoints.forEach(({ redeemableId, points }) => {
		redeemablesMapCopy = updatePoints(redeemablesMapCopy, redeemableId, points)
	})
	return {
		...state,
		combosData: {
			...state.combosData,
			redeemablesMap: redeemablesMapCopy,
		},
	}
}

const ComboStore = createStore(comboInitialState)
	.on(ComboEvents.setProgramDataForCombo, (state, programData) => ({
		...state,
		programData,
	}))
	.on(ComboEvents.setShowEditComboDialog, (state, showEditComboDialog) => ({
		...state,
		showEditComboDialog,
	}))
	.on(ComboEvents.setShowRemoveComboDialog, (state, showRemoveComboDialog) => ({
		...state,
		showRemoveComboDialog,
	}))
	.on(ComboEvents.setShowEditComboQuantityDialog, (state, showEditComboQuantityDialog) => ({
		...state,
		showEditComboQuantityDialog,
	}))
	.on(ComboEvents.setActiveCombo, (state, { activeCombo, rowIndexToDelete }) => ({
		...state,
		activeCombo,
		rowIndexToDelete: rowIndexToDelete === undefined ? rowIndexToDelete : [rowIndexToDelete],
	}))
	.on(ComboEvents.setActiveRedeemable, (state, { activeRedeemable, rowIndexToDelete }) => ({
		...state,
		activeRedeemable,
		rowIndexToDelete,
	}))
	.on(ComboEvents.setComboPoints, (state, comboData) => {
		/* istanbul ignore else */
		if (state.programData) {
			const comboProgram = state.programData.combos.find((item) => item.comboId === comboData.comboId)
			/* istanbul ignore else */
			if (comboProgram) comboProgram.points = comboData.comboPoints
		}
		return { ...state, programData: { ...(state.programData || ({} as Program)) } }
	})

	.on(ComboEvents.setRedeemablePoints, onSetRedeemablePoints)
	.on(ComboEvents.setIsValidatingComboId, (state, isValidatingComboId) => ({
		...state,
		isValidatingComboId,
	}))
	.on(ComboEvents.setFormType, (state, formType) => ({
		...state,
		formType,
	}))
	.on(ComboEvents.setShowSubmitDialog, (state, showSubmitDialog) => ({
		...state,
		showSubmitDialog,
	}))
	.on(ComboEvents.setFormData, (state, formData) => ({
		...state,
		formData,
	}))
	.on(ComboEvents.setIsValidatingFile, (state, isValidating) => ({
		...state,
		fileValidation: {
			...state.fileValidation,
			isValidating,
		},
	}))
	.on(ComboEvents.setFileValidationError, (state, errorMessage) => ({
		...state,
		fileValidation: {
			...state.fileValidation,
			errorMessage,
		},
	}))
	.on(ComboEvents.setFormDataMultiple, (state, formDataMultiple) => ({
		...state,
		formDataMultiple,
	}))
	.on(ComboEvents.selectTransparencyId, (state, selectedTransparencyId) => ({
		...state,
		selectedTransparencyId,
	}))
	.on(ComboEvents.setCombos, (state, combosData) => ({
		...state,
		combosData,
	}))
	.on(ComboEvents.showErrorInCombosData, (state) => ({
		...state,
		combosData: {
			redeemablesMap: {},
			showError: true,
		},
	}))
	.on(ComboEvents.setIsLoading, (state, isLoading) => ({
		...state,
		isLoading,
	}))
	.on(ComboEvents.setCombosPageReady, (state, isCombosPageReady) => ({
		...state,
		isCombosPageReady,
	}))
	.reset(ComboEvents.resetForCombo)

export default ComboStore
