import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ChallengeDetailsFormData, SkuBox } from '@/stores/challengeCreation'
import { Input } from '@hexa-ui/components'
import { Trash2 } from '@hexa-ui/icons'
import { Grid, Grow, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NumberInputContainer } from '../../../../../components/core/NumberInputContainer/NumberInputContainer'
import useStyles from './SkuFieldsStyle'
import SkuInputField from './SkuInputField'

type Props = {
	index: number
	disableRemoveButton?: boolean
	disabled?: boolean
	isEditing?: boolean
	handleRemoveItem: (id: number) => void
	handleSkuValidation: (value: string, name: string) => Promise<boolean>
	defaultValue?: SkuBox | null
	hideQuantity?: boolean
}

const SkuFields: React.FC<Props> = ({
	index,
	disabled,
	disableRemoveButton,
	handleSkuValidation,
	handleRemoveItem,
	isEditing,
	defaultValue,
	hideQuantity,
}: Props) => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const classes = useStyles()

	const { control, trigger, formState, getValues } = useFormContext<ChallengeDetailsFormData>()

	const revalidateAllDuplicatedSkus = () => {
		const { skus } = formState.errors
		if (skus) {
			skus.forEach((element, fieldIndex) => {
				if (element?.sku?.message && element.sku.type === 'unique') {
					trigger(`skus.${fieldIndex}.sku`)
				}
			})
		}
	}

	const handleRemoveClick = () => {
		if (!disableRemoveButton) {
			handleRemoveItem(index)
			revalidateAllDuplicatedSkus()
		}
	}

	const removeLink = !disabled && (
		<div className={classes.removeContainer}>
			<div className={classes.buttonContainer} onClick={handleRemoveClick} role="none">
				<Typography
					id="remove-link"
					variant="body2"
					color="primary"
					className={clsx({
						[classes.removeButton]: !disableRemoveButton,
						[classes.inactiveButton]: disableRemoveButton,
					})}
				>
					<Trash2 size="large" />
				</Typography>
			</div>
		</div>
	)

	const getSkuColumnWidth = () => {
		if (hideQuantity) {
			return 12
		}
		return isEditing ? 8 : 7
	}

	return (
		<Grow in>
			<Grid
				container
				spacing={2}
				justifyContent="center"
				alignContent="center"
				id="sku-box"
				className={classes.skuBoxContainer}
			>
				<Grid item xs={getSkuColumnWidth()} className={classes.skuBoxItem}>
					<SkuInputField
						index={index}
						disabled={disabled}
						handleSkuValidation={handleSkuValidation}
						defaultValue={defaultValue}
					/>
					{hideQuantity && removeLink}
				</Grid>
				{!hideQuantity && (
					<Grid item xs={isEditing ? 4 : 5} className={classes.skuBoxItem}>
						<Controller
							name={`skus.${index}.quantity`}
							defaultValue={defaultValue?.quantity}
							control={control}
							render={({ field: { value, name, ...rest }, fieldState: { error } }) => (
								<NumberInputContainer>
									<Input
										min={1}
										{...rest}
										width="100%"
										id={`skuQuantity${index}`}
										label={t('challenge:DETAILS_STEP.SKUS.QUANTITY_FIELD_LABEL')}
										errorText={error?.message}
										hasError={!!error?.message}
										type="number"
										disabled={disabled}
										name={name}
										value={value ?? ''}
										placeholder=""
										onBlur={() => {
											trigger(name)
											if (value && value > 0) {
												AnalyticsService.events.skuQuantityAdded({
													form_name: 'Challenge Creation - 3rd Step',
													screen_name: 'Challenge Creation - 3rd Step',
													sku: getValues(`skus.${index}.sku`) || null,
													sku_quantity: value ? `${value}` : null,
													...userInfo,
												})
											} else {
												AnalyticsService.events.error({
													failure_reason: error?.message ?? t('common:ERROR_MESSAGE.EMPTY_FIELD'),
													form_name: 'Challenge Creation - 3rd Step',
													screen_name: 'Challenge Creation - 3rd Step',
													...userInfo,
												})
											}
										}}
									/>
								</NumberInputContainer>
							)}
						/>
						{removeLink}
					</Grid>
				)}
			</Grid>
		</Grow>
	)
}

export default SkuFields
