enum FailureReason {
	DuplicatedSku = 'Duplicated sku',
	NoSkuFounded = 'Please enter a valid SKU',
	PocNotEnrolled = 'This POC is not enrolled in the Rewards Program',
	TransactionSubmitError = 'Something went wrong: your Transaction was not created. Please try again.',
	RewardsFileUploadError = 'Upload a .csv with maximum of 5,000 lines and columns poc_id, campaign_id, points. Columns separated by semicolon, campaign_id is optional.',
	RewardsOfferEmptyPocId = 'POC ID is empty',
	RefundEmptyOrderId = 'Order ID is empty',
	RefundEmptyComboId = 'Combo ID is empty',
	RefundEmptyQuantity = 'Quantity is empty',
	EmptyPoints = 'Points is empty',
	RewardsOfferEmptyDescription = 'Offer Description is empty',
	RewardsOfferEmptyPoints = 'Points Value is empty',
	EarningRuleEmptyCurrentAmount = 'Current Amount is empty',
	EarningRuleEmptyCategoryID = 'Category ID is empty',
}

export default FailureReason
