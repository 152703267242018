import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertDialog } from '@/components/core'

type Props = {
	show: boolean
	onConfirm: () => void
	onCancel: (buttonClicked: boolean) => void
}

const DeleteSkuIconView: React.FC<Props> = ({ show, onConfirm, onCancel }: Props) => {
	const { t } = useTranslation()

	return (
		<AlertDialog
			title={t('rules:SKUS.REMOVE_DIALOG_TITLE')}
			show={show}
			handleCancel={onCancel}
			handleConfirm={onConfirm}
			buttonConfirmText={t('BUTTON.REMOVE')}
		>
			{t('rules:SKUS.REMOVE_DIALOG_MESSAGE')}
		</AlertDialog>
	)
}

export default DeleteSkuIconView
