import { ProgramRules } from '@/domains'
import * as SettingsService from '@/services/settings/SettingsService'
import { hasElements } from '@/utils/array'

export type LoadSettingsRulesProps = {
	vendorId?: string
}

export async function execute(tierKey: string, params: LoadSettingsRulesProps): Promise<Array<ProgramRules>> {
	const result = await SettingsService.loadEarnRules<ProgramRules>({
		tier: tierKey,
		vendorId: params.vendorId,
	})
	if (!hasElements(result)) {
		throw new Error('No data found!')
	}
	return result
}
