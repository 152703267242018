import React, { useEffect, useState } from 'react'
import { useStoreMap } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { Paragraph, SearchField, Table } from '@hexa-ui/components'
import { RulesStore } from '@/stores'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import { TABLE_PAGINATION_CONFIG } from '@/utils/constants'
import EarningRulesContentStyles from './EarningRulesContent.styles'
import { useEarningRulesContentHandlers } from './hooks/useEarningRulesContentHandlers/useEarningRulesContentHandlers'
import { useEarningRulesContentColumns } from './hooks/useEarningRulesContentColumns/useEarningRulesContentColumns'

const EarningRulesContent: React.FC = () => {
	const userInfo = useGetUserInfo()
	const { t } = useTranslation()
	const classes = EarningRulesContentStyles()
	const [loading, setLoading] = useState<boolean>(true)
	const columns = useEarningRulesContentColumns()

	const { dataRules, handleClear, handlePagination, handleSearch, pageDataEventParams } =
		useEarningRulesContentHandlers()

	const rulesList = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => state.rules,
	})

	useEffect(() => {
		if (rulesList) setLoading(false)
	}, [rulesList])

	useEffect(() => {
		if (!dataRules.length) return

		AnalyticsService.events.tableViewed({
			...pageDataEventParams,
			...userInfo,
		})
	}, [dataRules.length, pageDataEventParams, userInfo])

	return (
		<>
			<Paragraph colortype="secondary" weight="medium">
				{t('rules:EARNING_RULES_TABLE.EARNING_RULES_DESCRIPTION')}
			</Paragraph>
			<div className={classes.mainTable}>
				<GridContainer style={{ padding: '16px', justifyContent: 'space-between' }}>
					<GridItem xs={6} style={{ maxWidth: '628px' }}>
						<SearchField.Root
							size="medium"
							onClear={handleClear}
							placeholder={t('rules:EARNING_RULES_TABLE.SEARCH_BY_RULE_NAME')}
							onChange={handleSearch}
						/>
					</GridItem>
				</GridContainer>
				<Table
					columns={columns}
					loading={loading}
					data={dataRules}
					emptyMessage={t('EMPTY_DATA_TABLE')}
					pagination={{
						...TABLE_PAGINATION_CONFIG,
						onChange: handlePagination,
					}}
				/>
			</div>
		</>
	)
}

export default EarningRulesContent
