import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@material-ui/core'

import DataTable, { DataTableRef, RowSelection } from '@/components/tables/DataTable'
import { Redeemable, ScreenName } from '@/domains'
import { ProgramCombo } from '@/domains/program'
import { formatDate } from '@/utils/date'

import { ComboType } from '@/domains/enums'
import useIsCombosBulkUpdateEnabled from '@/hooks/combo/useIsCombosBulkUpdateEnabled'
import { isCombosMultivendorEnabled, isToggleConfigByTier } from '@/utils/featureToggle/featureToggleHelper'
import { useAnalyticsServiceEventPageViewed } from '@/hooks/analyticsService/useAnalyticsServiceEventPageViewed'
import EditRedeemableDialog from '../EditRedeemableDialog/EditRedeemableDialog'
import RemoveRedeemableDialog from '../RemoveRedeemableDialog/RemoveRedeemableDialog'

import useStyles from '../CombosList/CombosListStyle'
import EditComboQuantityDialog from '../EditComboQuantityDialog/EditComboQuantityDialog'
import EditComboDialog from '../EditComboDialog/EditComboDialog'
import RemoveComboDialog from '../RemoveComboDialog/RemoveComboDialog'
import useSettingsData from '../../hooks/useSettingsData'

type ComboPublishedTabViewProps = {
	onEditCombo: (combo: Redeemable | ProgramCombo) => void
	onEditMultipleCombos: (combo: Redeemable[]) => void
	onRemoveCombo: (selectedCombo: Redeemable | ProgramCombo, rowIndex: number) => void
	onRemoveMultipleCombos: (selectedCombos: Redeemable[], rowIndexes: number[]) => void
	onEditMaxQuantity: () => void
	combos: Redeemable<ComboType>[] | ProgramCombo[]
	onDownloadCsvFile: () => void
	isLoading: boolean
	isDisabledDownloadButton: boolean
	pageSize: number
	showError: boolean
	isEnabledEditAndRemoveMultiplesCombos: boolean
}

const ComboPublishedTabView: React.FC<ComboPublishedTabViewProps> = ({
	onRemoveCombo,
	onRemoveMultipleCombos,
	onEditCombo,
	onDownloadCsvFile,
	onEditMaxQuantity,
	onEditMultipleCombos,
	isDisabledDownloadButton,
	isEnabledEditAndRemoveMultiplesCombos,
	showError,
	isLoading,
	combos,
	pageSize,
}) => {
	const isCombosBulkUpdateEnabled = useIsCombosBulkUpdateEnabled()
	const isMultiVendorCombosEnabled = isCombosMultivendorEnabled()
	const isSettingsConfigByTier = isToggleConfigByTier()
	const dataTableRef = React.useRef<DataTableRef>(null)
	const { sendAnalyticsServiceEventPageViewed } = useAnalyticsServiceEventPageViewed()
	const { t } = useTranslation()
	const classes = useStyles()

	const itemsPerPage = [10, 25, 50, 75, 100]

	const { programData, settingsData } = useSettingsData()

	useEffect(() => {
		sendAnalyticsServiceEventPageViewed(ScreenName.DTComboManagementPublishedTab)
	}, [sendAnalyticsServiceEventPageViewed])

	const customHeader = (
		<Grid container justifyContent="flex-end" alignItems="center">
			<Typography className={classes.quantityLabel}>
				{isCombosBulkUpdateEnabled ? t('combo:LIST.MAX_PURCHASE_QUANTITY') : t('combo:LIST.MAX_QUANTITY')}
			</Typography>
			<Typography className={classes.quantityValue} id="combo-max-quantity">
				{isSettingsConfigByTier ? settingsData?.redeemLimit ?? '-' : programData?.redeemLimit ?? '-'}
			</Typography>
			<Typography variant="button" id="combo-max-quantity-edit" className={classes.link} onClick={onEditMaxQuantity}>
				{t('combo:LIST.ACTION_EDIT_REDEEM_LIMIT')}
			</Typography>
		</Grid>
	)

	const rowSelectionConfig: RowSelection = {
		clearSelectionButton: {
			id: 'clear-selection',
			label: t('combo:ROW_SELECTION.CLEAR_SELECTION'),
		},
		itemsCountLabel: {
			many: t('combo:ROW_SELECTION.COUNT_LABEL.MANY'),
			one: t('combo:ROW_SELECTION.COUNT_LABEL.ONE'),
		},
		actions: [
			{
				id: 'edit-all',
				label: t('combo:ROW_SELECTION.EDIT_ALL'),
				onClick: (rows) => {
					onEditMultipleCombos(rows)
				},
			},
			{
				id: 'remove-all',
				label: t('combo:ROW_SELECTION.REMOVE_ALL'),
				onClick: (rows, indexes) => {
					onRemoveMultipleCombos(rows, indexes)
				},
			},
		],
	}

	const getIdColumnKey = () => {
		if (!isCombosBulkUpdateEnabled) {
			return 'comboId'
		}
		return isMultiVendorCombosEnabled ? 'vendorComboId' : 'redeemableId'
	}

	return (
		<>
			<Grid container alignItems="center">
				<Grid item container xs={isCombosBulkUpdateEnabled ? 8 : 12}>
					<Box paddingY="16px">
						<Typography>{t('combo:DT_COMBOS_TEXT_TAB.PUBLISHED')}</Typography>
					</Box>
				</Grid>
				{isCombosBulkUpdateEnabled && (
					<Grid item container xs={4} justifyContent="flex-end">
						<Box paddingY="16px">
							<Button
								type="button"
								color="primary"
								variant="outlined"
								id="downloadDtCombos"
								onClick={onDownloadCsvFile}
								disabled={isDisabledDownloadButton}
								className={classes.downloadButton}
							>
								{t('combo:DT_COMBOS.DOWNLOAD_DT_COMBO')}
							</Button>
						</Box>
					</Grid>
				)}
			</Grid>
			<EditComboQuantityDialog />
			{isCombosBulkUpdateEnabled ? (
				<>
					<EditRedeemableDialog />
					<RemoveRedeemableDialog dataTableRef={dataTableRef} />
				</>
			) : (
				<>
					<EditComboDialog />
					<RemoveComboDialog dataTableRef={dataTableRef} />
				</>
			)}
			<DataTable
				id="dt-combos"
				searchConfigs={{
					id: 'search-combo',
					placeholder: `${t('combo:LIST.SEARCH_COMBOS.PUBLISHED')}`,
					searchBy: ['name', getIdColumnKey(), 'points'],
				}}
				customHeader={customHeader}
				columns={[
					{
						label: `${t('combo:LIST.COLUMN_NAME')}`,
						dataKey: 'name',
						width: '25%',
					},
					{
						label: `${t('combo:LIST.COLUMN_COMBO_ID')}`,
						dataKey: getIdColumnKey(),
						width: '20%',
					},
					{
						label: `${t('combo:LIST.COLUMN_POINTS')}`,
						dataKey: 'points',
						width: '10%',
					},
					{
						label: `${t('combo:LIST.COLUMN_TYPE')}`,
						dataKey: 'type',
						width: '10%',
					},
					{
						label: `${t('combo:LIST.COLUMN_LAST_UPDATE')}`,
						dataKey: 'lastModified',
						width: '25%',
						format: (date): string => (date ? formatDate(date as Date, t('DATE_FORMAT_TABLE')) : ''),
					},
				]}
				data={combos}
				tableActions={[
					{
						label: `${t('combo:LIST.ACTION_EDIT')}`,
						type: 'edit',
						fn: onEditCombo,
					},
					{
						label: `${t('combo:LIST.ACTION_REMOVE')}`,
						type: 'delete',
						fn: onRemoveCombo,
					},
				]}
				configs={{
					itemsPerPage: pageSize,
					sortEnabled: ['name', getIdColumnKey(), 'points', 'lastModified', 'type'],
					actionWidth: '1%',
					itemsPerPageOptions: itemsPerPage,
					style: {
						emptyMessage: {
							showIcon: true,
						},
					},
				}}
				ref={dataTableRef}
				loading={isLoading}
				showError={showError}
				emptyMessage={t('combo:NOTIFICATION.EMPTY_MESSAGE')}
				rowSelection={isEnabledEditAndRemoveMultiplesCombos ? rowSelectionConfig : undefined}
			/>
		</>
	)
}

export default ComboPublishedTabView
