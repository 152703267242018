import { ControllerDiv, MultiTabHint } from '@/routes/challenge/componentsRedesign/ChallengeFormSetup/styles'
import { TextArea } from '@hexa-ui/components'
import React from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

interface Props<T extends FieldValues> {
	label: string
	name: Path<T>
	control: Control<T>
	isMultiTab?: boolean
	multiTabText?: string
}

function TextAreaController<T extends FieldValues>({
	label,
	name,
	control,
	isMultiTab,
	multiTabText,
}: Readonly<Props<T>>) {
	return (
		<ControllerDiv>
			<Controller
				name={name}
				control={control}
				render={({ field: { value, ...field }, fieldState: { error } }) => (
					<TextArea
						id={field.name}
						label={label}
						errorText={error?.message}
						hasError={!!error?.message}
						value={value || ''}
						placeholder=""
						width="100%"
						{...field}
					/>
				)}
			/>
			{isMultiTab && <MultiTabHint>{multiTabText}</MultiTabHint>}
		</ControllerDiv>
	)
}

export default TextAreaController
