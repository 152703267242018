import React from 'react'
import InputContainer, { InputContainerProps } from '@/components/form/InputContainer'
import DayJsUtils from '@date-io/dayjs'
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'
import { MuiPickersUtilsProviderProps } from '@material-ui/pickers/MuiPickersUtilsProvider'
import { useTranslation } from 'react-i18next'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useTheme } from '@material-ui/core/styles'
import { Calendar } from '@hexa-ui/icons'
import localStyles from './styles'

export type InputDateProps = InputContainerProps & {
	datePickerProps?: Pick<MuiPickersUtilsProviderProps, 'utils' | 'locale'>
	keyboardDataPickerProps?: Omit<
		Pick<KeyboardDatePickerProps, Exclude<keyof KeyboardDatePickerProps, 'onChange'>>,
		'value'
	>
	autoFocus?: boolean
	format?: string
	value?: string
	name?: string
	shouldDisableDate?: (day: MaterialUiPickersDate) => boolean
	disablePast?: boolean
	minDate?: Date
	onChange: (date: MaterialUiPickersDate, value: string | null | undefined) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

const InputDate: React.FC<InputDateProps> = ({
	id,
	label,
	optionalLabel,
	helperText,
	errorText,
	datePickerProps,
	keyboardDataPickerProps,
	shouldDisableDate,
	autoFocus = false,
	disabled = false,
	value,
	name,
	format,
	disablePast = true,
	minDate = undefined,
	onChange,
	onBlur,
}) => {
	const { t } = useTranslation()

	const localClasses = localStyles()
	const theme = useTheme()
	const hasError = !!errorText

	const formatDate = (): string => format ?? t('FORMAT_DATE.SHORT')

	return (
		<InputContainer
			id={id}
			label={label}
			optionalLabel={optionalLabel}
			errorText={errorText}
			helperText={helperText}
			disabled={disabled}
			className={(localClasses.formcontrol, localClasses.root)}
		>
			<MuiPickersUtilsProvider utils={DayJsUtils} {...datePickerProps}>
				<KeyboardDatePicker
					id={id}
					minDateMessage=""
					maxDateMessage=""
					disableToolbar
					autoFocus={autoFocus}
					name={name}
					value={value ?? null}
					PopoverProps={{
						anchorOrigin: { horizontal: 'right', vertical: 'top' },
						transformOrigin: { horizontal: 'right', vertical: 'top' },
					}}
					disablePast={disablePast}
					disabled={disabled}
					onChange={onChange}
					onBlur={onBlur}
					format={formatDate()}
					inputVariant="outlined"
					variant="inline"
					error={hasError}
					shouldDisableDate={shouldDisableDate ?? undefined}
					keyboardIcon={<Calendar size="medium" fill={disabled ? theme.palette.text.disabled : undefined} />}
					KeyboardButtonProps={{
						'aria-label': `${name}-open`,
					}}
					minDate={minDate}
					fullWidth
					autoOk
					{...keyboardDataPickerProps}
				/>
			</MuiPickersUtilsProvider>
		</InputContainer>
	)
}

export default InputDate
