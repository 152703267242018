import { GlobalEvents } from '@/stores'
import { useCallback } from 'react'
import useCurrentVendor from '../vendor/useCurrentVendor'
import useCurrentZone from '../zone/useCurrentZone'

export interface GlobalConfigResult {
	defineCurrentZone: (value: string) => void
	defineCurrentVendor: (value: string) => void
}

export const useGlobalConfig = (): GlobalConfigResult => {
	const currentVendor = useCurrentVendor()
	const currentZone = useCurrentZone()

	const defineCurrentZone = useCallback(
		(value: string) => {
			if (currentZone !== value) {
				GlobalEvents.setPageReady(false)
				GlobalEvents.selectZone(value)
			}
		},
		[currentZone],
	)

	const defineCurrentVendor = useCallback(
		(value: string) => {
			if (currentVendor?.vendorId !== value) {
				GlobalEvents.setPageReady(false)
				GlobalEvents.selectVendor(value)
			}
		},
		[currentVendor?.vendorId],
	)

	return {
		defineCurrentZone,
		defineCurrentVendor,
	}
}
