import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { CircularProgress, FormControl } from '@material-ui/core'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import FieldErrorMessage from '../FieldErrorMessage'
import FileUploadLabel from './FileUploadLabel'
import FileUploadSpan from './FileUploadSpan'
import styles from './styles'
import { RedesignUploadButton, fieldType } from './FileUploadButton/redesignUploadButton'
import FileUploadButton from './FileUploadButton'

export type FileUploadProps = {
	id: string
	name: string
	label?: string
	labelDescription?: string
	buttonText: string
	buttonType: 'primary' | 'secondary'
	multiple: boolean
	fileType?: AcceptableTypes
	errorText?: string
	labelPadding?: string
	disabled?: boolean
	disableAfterSelectOne?: boolean
	currentValue?: File | null
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onClick?: () => void
	onRemove?: (files: File[]) => void
	onCallbackSelectedFiles?: (components: JSX.Element[] | null) => void
	className?: string
	redesignUploadButton?: boolean
	trigger: (file: string) => void
}

export enum AcceptableTypes {
	Images = 'image/png, image/jpeg, image/jpg',
	CSV = '.csv',
	All = '*',
}

const FileUpload: React.FC<FileUploadProps> = ({
	id,
	name,
	buttonText,
	label,
	multiple,
	buttonType,
	fileType,
	currentValue,
	labelDescription,
	labelPadding,
	errorText,
	disabled = false,
	disableAfterSelectOne = true,
	onClick,
	onChange,
	onRemove,
	onCallbackSelectedFiles,
	className,
	redesignUploadButton,
	trigger,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [selectedFiles, setSelectedFiles] = useState<JSX.Element[] | null>(null)
	const fileUploadElement = useRef<HTMLInputElement>(null)

	const classes = styles({})

	const renderSelectedFiles = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(values: any) => {
			if (values) {
				setSelectedFiles(
					Array.from(values as File[]).map((file) => {
						return (
							<FileUploadSpan
								key={file.name}
								name={file.name}
								onRemove={() => {
									if (onRemove) onRemove(values)
									handleRemoveFile()
								}}
								disabled={disabled}
							/>
						)
					}),
				)
			}
			setIsLoading(false)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const handleRemoveFile = () => {
		if (fileUploadElement?.current) {
			fileUploadElement.current.value = ''
		}
		trigger('file')
		setSelectedFiles(null)
	}

	useEffect(() => {
		if (currentValue) renderSelectedFiles([currentValue])
	}, [currentValue, renderSelectedFiles])

	useEffect(() => {
		if (onCallbackSelectedFiles) {
			onCallbackSelectedFiles(selectedFiles)
		}
	}, [onCallbackSelectedFiles, selectedFiles])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!redesignUploadButton) {
			setIsLoading(true)
			const { files } = event.currentTarget
			if (files && files.length > 0) {
				renderSelectedFiles(Array.from(files).map((file) => file))
			}
		}

		if (onChange) {
			onChange(event)
		}
	}

	const hasError = !!errorText
	const uploadDisabled =
		!!disabled || (disableAfterSelectOne && selectedFiles && selectedFiles?.length > 0) || isLoading

	return (
		<div className={clsx(classes.box, className)}>
			<FileUploadLabel
				id={id}
				label={label}
				labelDescription={labelDescription}
				error={hasError}
				padding={labelPadding}
			/>
			<FormControl fullWidth className={classes.formcontrol}>
				{hasError && !isLoading && <FieldErrorMessage id={name} message={errorText} />}
				<GridContainer>
					<GridItem>
						{redesignUploadButton ? (
							<RedesignUploadButton
								label=""
								name={name as fieldType}
								uploadText={buttonText}
								onChange={handleChange}
								onRemove={handleRemoveFile}
								onClick={onClick}
								acceptTypes={AcceptableTypes.CSV}
							/>
						) : (
							<FileUploadButton
								id={id}
								label={buttonText}
								acceptableTypes={fileType}
								multiple={multiple}
								buttonType={buttonType}
								onChange={handleChange}
								onClick={onClick}
								disabled={uploadDisabled}
								fileUploadElement={fileUploadElement}
							/>
						)}
					</GridItem>
					<GridItem>
						<>
							{isLoading && <CircularProgress size={24} data-testid="loading-file" />}
							{!isLoading && !onCallbackSelectedFiles && <>{selectedFiles}</>}
						</>
					</GridItem>
				</GridContainer>
			</FormControl>
		</div>
	)
}

export default FileUpload
