import { OrderWithCombo } from '@/domains'
import { RefundItemFormik } from '@/routes/transaction/model/RefundItemFormik'
import { useStoreMap } from 'effector-react'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import TransactionStore from 'stores/transaction/TransactionStore'
import { TransactionFormKey } from '../TransactionFields/TransactionFields'
import CombosListView from './CombosListView'

const CombosList: React.FC = () => {
	const { selectedOrder } = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => ({
			selectedOrder: state.selectedOrder as OrderWithCombo,
		}),
	})

	const { setFieldValue } = useFormikContext()
	const formKey = TransactionFormKey.combos

	useEffect(() => {
		const combos = selectedOrder?.combos
		if (!combos) {
			return
		}

		const formState = combos.map((combo) => {
			return {
				checked: false,
				id: combo.comboId,
				quantity: combo.quantity,
				points: combo.points,
			} as RefundItemFormik
		})
		setFieldValue(formKey, formState)
	}, [formKey, selectedOrder, setFieldValue])

	return <CombosListView combos={selectedOrder?.combos || []} />
}

export default CombosList
