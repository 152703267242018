import { TransactionHistory } from '@/domains'
import { TransactionContent } from '@/domains/ITransaction'
import { TransactionType } from '@/domains/enums'

const convert = (transaction: TransactionContent): TransactionHistory => ({
	id: transaction.id,
	accountId: transaction.accountId,
	amount: transaction.amount,
	timestamp: new Date(transaction.timestamp),
	transactionType: transaction.type as TransactionType,
	transactionTypeDescription: transaction.title,
	reference: transaction.reference,
})

const convertAll = (transactions: Array<TransactionContent>): Array<TransactionHistory> => transactions.map(convert)

export default convertAll
