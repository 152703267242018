import { ChallengeStore } from '@/stores/challengeCreation'
import { useStoreMap } from 'effector-react'

const useChallengeSetupFormDataId = (): string | null => {
	const challengeId = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.challengeSetupFormData?.id ?? null,
	})

	return challengeId
}

export default useChallengeSetupFormDataId
