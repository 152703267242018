import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grow, Grid } from '@material-ui/core'
import { ChallengeFilterFormData } from '@/stores/challengeCreation'
import { SelectOptions } from '@/components/form'
import { ChallengeFormFilterOption } from '@/domains'
import styles from './FilterBoxStyle'
import InputGroup from '../InputGroup/InputGroup'
import FilterBoxFilterSelect from './FilterBoxFilterSelect'
import FilterBoxRemoveLink from './FilterBoxRemoveLink'
import FilterBoxFilterValueInput from './FilterBoxFilterValueInput'

type Props = {
	index: number
	filterOptions: Array<SelectOptions>
	onRemoveLinkClick: (index: number) => void
	defaultValue: ChallengeFormFilterOption
	onFilterSelected: () => void
	disabled: boolean
	isActiveChallenge?: boolean
	isFilterChangeDisabled?: boolean
}

const FilterBoxView: React.FC<Props> = ({
	index,
	filterOptions,
	onRemoveLinkClick,
	defaultValue,
	onFilterSelected,
	disabled,
	isActiveChallenge,
	isFilterChangeDisabled = false,
}) => {
	const classes = styles()
	const { watch } = useFormContext<ChallengeFilterFormData>()
	const selectedFilter = watch(`filters.${index}.filter` as const)
	const isRemoveLinkDisabled = !selectedFilter || isFilterChangeDisabled
	const isFilterSelectDisabled = disabled || isFilterChangeDisabled
	const isGroupFilterSelected = selectedFilter === 'groupIds'

	useEffect(() => {
		if (selectedFilter) {
			onFilterSelected()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFilter])

	return (
		<Grow in>
			<Grid
				container
				spacing={2}
				justifyContent="center"
				alignContent="center"
				alignItems="flex-end"
				id={`filter-box-${index}`}
			>
				<Grid item xs={4}>
					<FilterBoxFilterSelect
						index={index}
						defaultValue={defaultValue}
						filterOptions={filterOptions}
						disabled={isFilterSelectDisabled}
					/>
				</Grid>
				<Grid item xs={8}>
					<Grid item container justifyContent="center" alignItems="center" alignContent="center">
						{!isGroupFilterSelected ? (
							<FilterBoxFilterValueInput
								index={index}
								defaultValue={defaultValue}
								disabled={disabled}
								isRemoveLinkDisabled={isRemoveLinkDisabled}
							/>
						) : (
							<InputGroup
								fixedValues={isActiveChallenge ? defaultValue.value : []}
								index={index}
								defaultValue={defaultValue.value}
								disabled={disabled && !isActiveChallenge}
								className={classes.input}
							/>
						)}
						{!disabled && (
							<FilterBoxRemoveLink
								onRemoveLinkClick={(itemIndex: number) => {
									if (!isRemoveLinkDisabled) onRemoveLinkClick(itemIndex)
								}}
								isRemoveLinkDisabled={isRemoveLinkDisabled}
								index={index}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grow>
	)
}

export default FilterBoxView
