import { Typography } from '@material-ui/core'
import React from 'react'
import PageLevelNotification from '../PageLevelNotification'
import useStyles from './styles'

type Props = {
	children: React.ReactNode
	id?: string
}

const PageEnvironmentInfo: React.FC<Props> = ({ children, ...props }: Props) => {
	const classes = useStyles()

	return (
		<PageLevelNotification type="info" {...props}>
			<Typography component="span" className={classes.pageEnvironmentInfo}>
				{children}
			</Typography>
		</PageLevelNotification>
	)
}

export default PageEnvironmentInfo
