import { useLogService as logService } from 'admin-portal-shared-services'
import { RewardsProgram, PagedResponse } from '@/domains'
import RewardsProjection from '@/domains/enums/RewardsProjectionEnum'
import axios from '@/utils/axiosInstance'
import { AxiosResponse } from 'axios'
import { IAccount } from '@/domains/account/Account'

const endPoint = `/v1/rewards-service/rewards`
const endPointAdminService = `/rewards-admin-service/v1/rewards`
const log = logService()

export interface LoadAllParams {
	page?: number
	pageSize?: number
	projection?: RewardsProjection
	accountIds?: Array<string>
}
/**
 * Get POC enrollment
 * @param projection: RewardsProjection
 */
async function loadAll({
	page,
	pageSize,
	projection,
	accountIds,
}: LoadAllParams): Promise<PagedResponse<RewardsProgram>> {
	const params = {
		projection,
		page,
		pageSize,
		accountIds: accountIds?.join(','),
	}
	const response = await axios.get<PagedResponse<RewardsProgram>>(endPoint, { params }).catch((error) => {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	})

	return response.data
}

/**
 * Get POC enrollment by accountId
 * @param accountId
 */
async function loadByAccountId(accountId: string, projection?: RewardsProjection): Promise<RewardsProgram> {
	const accountIdFormated = accountId.trim()
	const params = {
		projection,
	}
	const response = await axios.get<RewardsProgram>(`${endPoint}/${accountIdFormated}`, { params }).catch((error) => {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	})

	return response.data
}

async function loadByAccountIdRewards(vendorAccountId: string, vendorId: string): Promise<IAccount> {
	const params = {
		vendorAccountId,
		vendorId,
	}
	const response = await axios.get<IAccount>(`${endPointAdminService}`, { params }).catch((error) => {
		log.error(`Error endpoint: ${endPointAdminService}`, error)
		throw error
	})

	return response.data
}

/**
 * POST to edit permission by accountId
 * @param accountId
 */

async function updatePermissionsByAccountId(accountId: string, enabled: boolean): Promise<AxiosResponse<void>> {
	const accountIdFormated = accountId.trim()
	try {
		const result = await axios.post<void>(`${endPoint}/${accountIdFormated}/permission`, { enabled })
		return result
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

/**
 * Find POC enrollments containing the given challenge ids
 * @param challengeIds Challenge ids to be found
 * @param projection Return data projection
 */
async function loadAllByChallenge(
	challengeIds: Array<string>,
	projection?: RewardsProjection,
	page?: number,
	pageSize?: number,
): Promise<PagedResponse<RewardsProgram>> {
	const params = {
		challengeIds: challengeIds.join(','),
		page,
		pageSize,
		projection,
	}
	const response = await axios.get<PagedResponse<RewardsProgram>>(`${endPoint}`, { params }).catch((error) => {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	})
	return response.data
}

export { loadByAccountId, loadAllByChallenge, loadAll, updatePermissionsByAccountId, loadByAccountIdRewards }
