import React, { useState, useEffect } from 'react'
import { useStoreMap } from 'effector-react'
import { RulesStore } from '@/stores'
import AnalyticsService from 'services/analytics/AnalyticsService'
import ScreenName from 'domains/analytics/ScreenName'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import RulesListRedesignView from './RulesListRedesignView'

const RulesListRedesign: React.FC = () => {
	const userInfo = useGetUserInfo()
	const [loading, setLoading] = useState<boolean>(true)

	const rulesList = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => state.rules,
	})

	useEffect(() => {
		AnalyticsService.events.pageViewed({
			screen_name: ScreenName.EarningRules,
			...userInfo,
		})
	}, [userInfo])

	useEffect(() => {
		if (rulesList) {
			setLoading(false)
		}
	}, [rulesList])

	return <RulesListRedesignView loading={loading} />
}

export default RulesListRedesign
