import { makeStyles } from '@material-ui/core/styles'

const EarningRulesContentStyles = makeStyles({
	mainTable: {
		display: 'block',
		width: '100%',
		marginTop: '24px',
		borderRadius: '24px',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.16)',
		color: 'rgba(0,0,0,0.9)',
		'& h5': {
			margin: '0px',
		},
		'& ul': {
			paddingInlineStart: '0px',
		},
		'& th:nth-child(2) div, th:nth-child(3) div': {
			justifyContent: 'center',
		},
		'& th:nth-child(2) div h5, td:nth-child(2), td:nth-child(3)': {
			textAlign: 'center',
		},
	},
	viewButton: {
		backgroundColor: 'transparent',
		border: 'none',
		color: '#4D6EDB',
		cursor: 'pointer',
		height: '20px',
		width: '33px',
		fontSize: '14px',
		letterSpacing: '0',
		lineheight: '20px',
		textDecoration: 'underline',
	},
	createRuleButton: {
		height: '48px',
		width: '292px',
		borderRadius: '24px',
		backgroundColor: '#000000',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.24)',
		fontSize: '18px',
		padding: '0',
	},
})

export default EarningRulesContentStyles
