import { Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import useStyles from './TransactionFormStyle'

type Props = {
	message: string
	onError?: (id: string, message: string) => void
}

export const TransactionErrorNotification: React.FC<Props> = ({ message, onError }) => {
	const classes = useStyles()

	useEffect(() => {
		if (onError) onError('transaction-notification-error', message)
	}, [message, onError])

	return (
		<Typography component="span" id="transaction-notification-error" className={classes.pageLevelErrorMessage}>
			{message}
		</Typography>
	)
}

export default TransactionErrorNotification
