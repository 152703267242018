import { countryConfig } from '@/utils/constants'
import { getMonthNames } from '@/utils/getMonths'
import { getUserCountry } from '@/utils/tokenB2CHelper'
import { Select } from '@hexa-ui/components'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function useRewardsOfferMonthOptions() {
	const { t } = useTranslation()
	const countryCode = getUserCountry()
	const { locale } = countryConfig[countryCode] ?? countryConfig.fallback
	const langCode = locale.slice(0, 2)
	const [monthOptions, setMonthOptions] = useState<JSX.Element[]>([])

	const createOption = (value: string, children: string) => {
		return (
			<Select.Option key={value} value={value}>
				{children}
			</Select.Option>
		)
	}

	const loadMonthOptions = useCallback(() => {
		const placeholderOption = createOption('', t('transaction:REWARDS_OFFER.SELECT_MONTH'))
		const offerMonth = getMonthNames(langCode)
		setMonthOptions([placeholderOption, ...offerMonth])
	}, [])

	useEffect(() => {
		loadMonthOptions()
	}, [loadMonthOptions])

	return monthOptions
}
