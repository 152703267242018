import React from 'react'
import { Box, Grow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { OnUpdateValueFunction } from 'components/formik/InputTextWithValidation'
import InputTextArea from 'components/formik/InputTextArea'
import style from './PointsTransferStyle'
import PocIdField from '../PocIdField/PocIdField'
import PocValidationParams from '../../model/PocValidationParams'

type Props = {
	showError: boolean
	originAccountBalance: string
	onAccountFromUpdate: OnUpdateValueFunction
	onPocValidation: (params: PocValidationParams) => Promise<string>
	onError?: (id: string, message: string) => void
}

const PointsTransferView: React.FC<Props> = ({
	showError,
	onPocValidation,
	originAccountBalance,
	onAccountFromUpdate,
	onError,
}) => {
	const { t } = useTranslation()
	const classes = style()

	return (
		<Grow in>
			<>
				<PocIdField
					id="pocIdFrom"
					name="pocIdFrom"
					label={t('transaction:POINTS_TRANSFER.FROM_POC_ID')}
					onPocValidation={onPocValidation}
					showError={showError}
					onChangePoc={onAccountFromUpdate}
					onError={onError}
				/>
				<PocIdField
					id="pocIdTo"
					name="pocIdTo"
					label={t('transaction:POINTS_TRANSFER.TO_POC_ID')}
					onPocValidation={onPocValidation}
					showError={showError}
					onError={onError}
				/>
				<Box id="totalPointsBalance" className={classes.totalPointsBalance}>
					<span
						id="totalPointsBalanceLabel"
						data-testid="totalPointsBalanceLabel"
						className={classes.totalPointsBalanceLabel}
					>
						{t('transaction:POINTS_TRANSFER.BALANCE')}
					</span>
					<span
						id="totalPointsBalanceValue"
						className={classes.totalPointsBalanceValue}
						data-testid="totalPointsBalanceValue"
					>
						{` ${originAccountBalance}`}
					</span>
				</Box>

				<InputTextArea id="reason" name="reason" label={t('transaction:POINTS_TRANSFER.REASON')} />
			</>
		</Grow>
	)
}

export default React.memo(PointsTransferView)
