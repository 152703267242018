import React, { useRef, useState } from 'react'
import { Dialog, Heading, IconButton, Paragraph, Table } from '@hexa-ui/components'
import { EyeOn } from '@hexa-ui/icons'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import { useTranslation } from 'react-i18next'
import TransactionStatusBadge from '@/components/core/TransactionStatusBadge'
import * as LoadTransactionUseCase from '@/usecase/transactionV2/LoadTransactionUseCase'
import { TransactionResponseV2 } from '@/domains/transaction/TransactionResponse'
import Dropdown from '@/components/core/Dropdown'
import { TransparencyStatus } from '@/domains/enums'
import {
	transactionDetailDialogColumns,
	transactionDetailDialogFields,
	transactionDetailDialogReasons,
} from '@/utils/transactionsVariablesUtil'
import * as CancelTransactionUseCase from '@/usecase/transactionV2/CancelTransactionUseCase'
import downloadCSV from '@/utils/downloadCSV'
import * as DownloadCsvUseCase from '@/usecase/transactionV2/DownloadCsvUseCase'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { gridContainerStyles, useStyles } from './TransactionDetailDialogStyle'
import ConfirmActionDialog, { IConfirmActionDialogButton } from './ConfirmActionDialog/ConfirmActionDialog'

interface TransactionDetailProps {
	transactionId: string
}

const TransactionDetailDialog: React.FC<TransactionDetailProps> = ({ transactionId }) => {
	const classes = useStyles()
	const toast = useToast()
	const { t } = useTranslation()

	const [transaction, setTransaction] = useState<TransactionResponseV2 | null>(null)
	const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState<string | null>(null)
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
	const [executionStatuses, setExecutionStatuses] = useState<{ [key: string]: string }>({})
	const [temporaryIdToCancel, setTemporaryIdToCancel] = useState<string | null>(null)

	const reasons = transactionDetailDialogReasons(transaction).map((reason) => ({
		...reason,
		title: t(reason.title),
	}))
	const fields = transactionDetailDialogFields(transaction).map((field) => {
		const translatedValue = typeof field.value === 'string' ? t(field.value) : null
		const dataValue = field.title === 'transaction:TRANSACTION_DETAIL.REQUESTED_ON' ? field.value : translatedValue
		return { title: t(field.title), value: dataValue }
	})
	const confirmActionDialogButtons = useRef<IConfirmActionDialogButton[]>()

	const columns = transactionDetailDialogColumns.map((col) => ({
		...col,
		Header: t(col.Header),
	}))

	const fetchTransaction = async () => {
		try {
			const response = await LoadTransactionUseCase.execute(transactionId)
			setTransaction(response)

			const initialStatuses = response?.executions?.reduce(
				(acc, execution, i) => {
					acc[`${response.id}-${execution.status}-${i}`] = execution.status
					return acc
				},
				{} as { [key: string]: string },
			)
			if (initialStatuses) setExecutionStatuses(initialStatuses)
		} catch (error) {
			toast.notify({
				type: TypeToast.ERROR,
				message: t('ERROR_MESSAGE.GENERIC_ERROR'),
			})
			console.error('Failed to load transaction', error)
		}
	}

	const handleCancelRequestClick = (event: React.MouseEvent, temporaryId: string) => {
		event.preventDefault()
		setIsConfirmDialogOpen(true)
		setTemporaryIdToCancel(temporaryId)
	}

	const cancelTransaction = async (): Promise<void> => {
		const cancelTransactionData = await CancelTransactionUseCase.execute(transactionId)

		if (cancelTransactionData === 204 && temporaryIdToCancel) {
			setExecutionStatuses((prevStatuses) => ({
				...prevStatuses,
				[temporaryIdToCancel]: 'CANCELED',
			}))

			setTemporaryIdToCancel(null)
		} else {
			toast.notify({
				type: TypeToast.ERROR,
				message: t('ERROR_MESSAGE.GENERIC_ERROR'),
			})
		}
	}

	const handleDownloadCSV = async (executionStatus: string): Promise<void> => {
		if (!transaction) return
		const { vendorId } = transaction
		const response = (await DownloadCsvUseCase.execute(vendorId, transactionId, executionStatus)) as { data?: Blob }

		if (response?.data) {
			const fileName = `${transactionId}-${executionStatus}`
			downloadCSV(fileName, response.data)
		} else {
			toast.notify({
				type: TypeToast.ERROR,
				message: t('redeemable:NOTIFICATION.EDIT.ERROR_DOWNLOAD'),
			})
			console.error('No data available for download')
		}
	}

	const statusWithActions = transaction?.executions?.map((execution, i) => {
		const temporaryId = `${transaction.id}-${execution.status}-${i}`
		const dropdownButtons = [
			{
				key: `download_${execution?.transparencyId}`,
				buttonText: 'common:BUTTON.DOWNLOAD',
				onClickAction: () => handleDownloadCSV(execution.status),
			},
			{
				key: `cancel-request_${temporaryId}`,
				buttonText: 'common:BUTTON.CANCEL_REQUEST',
				onClickAction: (event: React.MouseEvent) => handleCancelRequestClick(event, temporaryId),
			},
		]

		confirmActionDialogButtons.current = [
			{
				buttonText: 'transaction:TRANSACTION_DETAIL.CANCEL_TRANSACTION.BUTTON.CANCEL',
				key: 'cancel',
				variant: 'destructive',
				onClickAction: () => cancelTransaction(),
			},
			{
				buttonText: 'transaction:TRANSACTION_DETAIL.CANCEL_TRANSACTION.BUTTON.BACK',
				key: 'back',
				variant: 'secondary',
			},
		]

		const iconButtonOnClickAction =
			execution.status === 'PENDING'
				? () => setIsOpenDropdownMenu(isOpenDropdownMenu === temporaryId ? null : temporaryId)
				: () => handleDownloadCSV(execution.status)

		return {
			...execution,
			status: (
				<div className={classes.tableStatus}>
					<TransactionStatusBadge
						type={(executionStatuses[temporaryId] as keyof typeof TransparencyStatus) || execution.status}
					/>
				</div>
			),

			actions: (
				<Dropdown
					isOpenDropdownMenu={isOpenDropdownMenu === temporaryId}
					setIsOpenDropdownMenu={() => setIsOpenDropdownMenu(isOpenDropdownMenu === temporaryId ? null : temporaryId)}
					buttons={dropdownButtons}
					iconButtonProps={{
						status: executionStatuses[temporaryId],
						onClick: iconButtonOnClickAction,
					}}
				/>
			),
		}
	})

	return (
		<div className={classes.dialogRoot}>
			<Dialog.Root
				title={<Heading>{t('transaction:TRANSACTION_DETAIL.TITLE')}</Heading>}
				trigger={
					<IconButton
						data-testid="transaction-detail-dialog-icon-button"
						icon={() => <EyeOn fr={undefined} />}
						variant="tertiary"
					/>
				}
				onOpenChange={(open) => {
					if (!open) {
						setIsOpenDropdownMenu(null)
					} else {
						fetchTransaction()
					}
				}}
			>
				<GridContainer style={gridContainerStyles}>
					{fields
						.filter((field) => field.value)
						.map((field) => {
							const descriptionFields = [
								t('transaction:TRANSACTION_DETAIL.REASON'),
								t('transaction:TRANSACTION_DETAIL.DESCRIPTION'),
							]
							const titleLowerCase = field.title?.toLowerCase()
							const FULL_ROW = 12
							const HALF_ROW = 6

							const isDescriptionField = descriptionFields.some((keyword) =>
								titleLowerCase?.includes(keyword.toLowerCase()),
							)

							const gridColumnSize = isDescriptionField ? FULL_ROW : HALF_ROW

							return (
								<GridItem xs={gridColumnSize} key={field.title}>
									<Heading size="H5" className={classes.itemTitle}>
										{field.title}
									</Heading>
									<Paragraph>{field.value}</Paragraph>
								</GridItem>
							)
						})}
				</GridContainer>
				<div className={classes.tableContainer} data-testid="table-container">
					<Table pagination={false} columns={columns} data={statusWithActions ?? []} />
				</div>
				{reasons.map(
					(reason) =>
						reason.value && (
							<div key={reason.title} className={classes.containerItem}>
								<Heading size="H5" className={classes.rejectionReason}>
									{reason.title}
								</Heading>
								<Paragraph>{reason.value}</Paragraph>
							</div>
						),
				)}
				<ConfirmActionDialog
					open={isConfirmDialogOpen}
					onClose={() => setIsConfirmDialogOpen(false)}
					buttons={confirmActionDialogButtons.current}
				/>
			</Dialog.Root>
		</div>
	)
}

export default TransactionDetailDialog
