import { GridContainer } from '@/components/templates/GridTemplate'
import { ScreenName } from '@/domains'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { TABLE_PAGINATION_CONFIG } from '@/utils/constants'
import { Heading, SearchField, Table } from '@hexa-ui/components'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSkuListDataTable from '../../../hooks/useSkuListDataTable'
import useStyles from './SkuListTableStyle'

type SkuListTableViewProps = {
	loading: boolean
	hasDelete?: boolean
}

const SkuListTableView: React.FC<SkuListTableViewProps> = ({ loading, hasDelete }: SkuListTableViewProps) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { dataSkuList, setSkuListFilter } = useSkuListDataTable(hasDelete)
	const userInfo = useGetUserInfo()
	const [pageData, setPageData] = useState({
		page: 1,
		pageSize: 10,
	})

	const pageDataEventParams = useMemo(
		() => ({
			items_total: `${dataSkuList.length}`,
			items_per_page: `${pageData.pageSize}`,
			page_number: `${pageData.page}`,
			table_name: 'Earning Rules - SKUs',
			screen_name: hasDelete ? ScreenName.EarningRulesSkus : ScreenName.EarningRulesDetails,
		}),
		[dataSkuList.length, hasDelete, pageData.page, pageData.pageSize],
	)

	useEffect(() => {
		if (dataSkuList.length) {
			AnalyticsService.events.tableViewed({
				...pageDataEventParams,
				...userInfo,
			})
		}
	}, [dataSkuList.length, pageDataEventParams, userInfo])

	return (
		<GridContainer className={classes.skuListContainer}>
			<Heading size="H4" className={classes.skuListTitle}>
				{t('rules:SKUS.LIST.SKUS')}
			</Heading>
			<GridContainer className={classes.skuListSearch}>
				<SearchField.Root
					size="medium"
					onClear={() => setSkuListFilter('')}
					placeholder={t('rules:SKUS.LIST.SEARCH')}
					className={classes.skuListSearch}
					onChange={(ev) => {
						setSkuListFilter((ev.target as HTMLInputElement).value)
						AnalyticsService.events.tableSearched({
							search_query: (ev.target as HTMLInputElement).value,
							content_type: 'SKU',
							table_name: `${ScreenName.EarningRules} - SKUs`,
							screen_name: hasDelete ? ScreenName.EarningRulesSkus : ScreenName.EarningRulesDetails,
							...userInfo,
						})
					}}
				/>
			</GridContainer>
			<Table
				columns={[
					{
						Header: t('rules:SKUS.LIST.COLUMN_PRODUCT'),
						accessor: 'product',
						disableSortBy: true,
						style: {
							width: '347px',
						},
					},
					{
						Header: t('rules:SKUS.LIST.COLUMN_SKU'),
						accessor: 'skuId',
						disableSortBy: true,
					},
					{
						Header: '',
						accessor: 'delete',
						disableSortBy: true,
						style: {
							width: '137px',
						},
					},
				]}
				loading={loading}
				data={dataSkuList}
				pagination={{
					...TABLE_PAGINATION_CONFIG,
					onChange: (page, pageSize) => {
						if (pageSize !== pageData.pageSize) {
							AnalyticsService.events.tableItemsPerPageChanged({
								...pageDataEventParams,
								items_per_page: `${pageSize}`,
								...userInfo,
							})
						}
						if (page !== pageData.page) {
							AnalyticsService.events.paginationClicked({
								// 'interactionType' cannot be mapped in the current table
								interaction_type: null,
								page_number: `${page}`,
								screen_name: hasDelete ? ScreenName.EarningRulesSkus : ScreenName.EarningRulesDetails,
								...userInfo,
							})
						}
						setPageData({ page, pageSize })
					},
				}}
			/>
		</GridContainer>
	)
}

export default SkuListTableView
