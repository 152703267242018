import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import useCurrentVendor from '@/hooks/vendor/useCurrentVendor'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { useMemo } from 'react'

export interface UserDataResult {
	tier: string | null
	user: string
	vendor: string | null
	zone: string | null
}

export const useGetUserInfo = (): UserDataResult => {
	const { selectedTierKey } = useCurrentTier()
	const vendor = useCurrentVendor()?.vendorName ?? null
	const zone = useCurrentZone()
	const user = useAuthenticationService().getUserEmailB2C()

	const tier = useMemo(() => {
		switch (selectedTierKey) {
			case 'CLUB_B':
				return 'Club B'
			case 'CLUB_B_BLACK':
				return 'Club B Black'
			case 'CLUB_B_MAX':
				return 'Club B Max'
			default:
				return 'Club B'
		}
	}, [selectedTierKey])

	const userInfo = useMemo(() => ({ tier, user, vendor, zone }), [tier, user, vendor, zone])
	return userInfo
}
