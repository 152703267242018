import { createStyles, makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(() =>
	createStyles({
		container: {
			display: 'inline',
			position: 'relative',
			textAlign: 'left',
		},
		customPricePerPointContainer: {
			display: 'flex',
			gap: 'var(--space-2)',
			alignItems: 'center',

			'& > * > svg': {
				color: '#FFAA33',
				marginTop: '5px',
			},
		},
		defaultPricePerPoint: {
			display: 'flex',
			lineHeight: '20px',
			cursor: 'default',
			fontWeight: 400,
			color: 'var(--colors-neutral40)',
		},
		disabledDefaultPricePerPoint: {
			display: 'flex',
			height: '20px',
			lineHeight: '18px',
			fontSize: '12px',
			cursor: 'default',
			fontWeight: 400,
			color: 'var(--colors-neutral40)',
			textDecorationLine: 'line-through',
		},
		customPricePerPoint: {
			display: 'flex',
			height: '20px',
			lineHeight: '20px',
			cursor: 'default',
			fontWeight: 400,
			color: 'var(--colors-neutral80)',
		},
	}),
)
