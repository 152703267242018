import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TransparencyTransaction } from '@/domains'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useDialogStyles from '@/components/formik/FormDialog/styles'
import VirtualizedTable from '@/components/tables/VirtualizedTable'
import useStyles from './ReviewItemsDialogStyle'

type Props = {
	open: boolean
	transactions: TransparencyTransaction[]
	onClose: () => void
	onDownloadCsv: () => void
}

const ReviewItemsDialogView: React.FC<Props> = ({ open, transactions, onClose, onDownloadCsv }) => {
	const { t } = useTranslation()
	const dialogClasses = useDialogStyles()
	const classes = useStyles()
	const theme = useTheme()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [scrollElement, setScrollElement] = useState<any>(null)

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth
			className={classes.dialog}
			maxWidth="lg"
		>
			<div className={dialogClasses.dialogContainer}>
				<DialogTitle>
					<Typography id="alert-dialog-title" className={dialogClasses.title}>
						{t('combo:UPLOAD_HISTORY.MODAL.TITLE')}
					</Typography>
				</DialogTitle>

				<DialogContent ref={(node) => setScrollElement(node)}>
					<Box marginBottom={5}>
						<Typography id="alert-dialog-description">{t('combo:UPLOAD_HISTORY.MODAL.INSTRUCTIONS')}</Typography>
					</Box>
					<VirtualizedTable
						id="multiple-combos-upload-history-review-items"
						scrollElement={scrollElement}
						columns={[
							{
								dataKey: 'redeemableId',
								label: t('combo:UPLOAD_HISTORY.MODAL.TABLE_COLUMNS.COMBO_ID'),
								align: 'left',
								width: 200,
								format: (el) => (el ? el.toString() : '-'),
							},
							{
								dataKey: 'points',
								label: t('combo:UPLOAD_HISTORY.MODAL.TABLE_COLUMNS.POINTS'),
								align: 'left',
								width: 150,
								format: (el) => (el ? el.toString() : '-'),
							},
							{
								dataKey: 'error',
								label: t('combo:UPLOAD_HISTORY.MODAL.TABLE_COLUMNS.ERROR'),
								align: 'left',
								width: 200,
								style: { color: theme.palette.error.dark, fontWeight: 500 },
								headerStyle: { color: theme.palette.error.dark },
							},
						]}
						data={transactions}
						configs={{
							rowHeight: 84,
							headerHeight: 34,
						}}
					/>
				</DialogContent>

				<DialogActions className={classes.dialogAction}>
					<Button
						id="cancel-btn"
						onClick={onClose}
						variant="outlined"
						color="primary"
						className={dialogClasses.cancelButton}
					>
						{t('BUTTON.CLOSE')}
					</Button>
					<Button id="download-btn" variant="contained" color="primary" onClick={onDownloadCsv}>
						{t('BUTTON.DOWNLOAD_CSV')}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	)
}

export default ReviewItemsDialogView
