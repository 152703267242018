import React, { createContext, useContext, useState, ReactNode, useMemo, useRef } from 'react'

type RedeemableItemsContextType = {
	inputValuePPP: number | undefined
	setInputValuePPP: React.Dispatch<React.SetStateAction<number | undefined>>
	valuePPP: React.MutableRefObject<number | undefined>
	isInputEmpty: boolean | undefined
	setIsInputEmpty: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const RedeemableItemsContext = createContext<RedeemableItemsContextType | undefined>(undefined)

export const RedeemableItemsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const valuePPP = useRef<number | undefined>()
	const [inputValuePPP, setInputValuePPP] = useState<number | undefined>()
	const [isInputEmpty, setIsInputEmpty] = useState<boolean | undefined>()

	const value = useMemo(
		() => ({
			valuePPP,
			inputValuePPP,
			setInputValuePPP,
			isInputEmpty,
			setIsInputEmpty,
		}),
		[valuePPP, inputValuePPP, setInputValuePPP],
	)

	return <RedeemableItemsContext.Provider value={value}>{children}</RedeemableItemsContext.Provider>
}

export const useRedeemableItems = (): RedeemableItemsContextType => {
	const context = useContext(RedeemableItemsContext)
	if (!context) {
		throw new Error('useRedeemableItems must be used within a RedeemableItemsProvider')
	}
	return context
}
