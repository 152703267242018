import React from 'react'
import { fileValidationCsv } from '@/utils/validationObjects'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

interface IUploadFile {
	uploadFile: FileList
}

interface StepSkuProviderProps {
	children: React.ReactNode
}

const StepSkuProvider: React.FC<StepSkuProviderProps> = ({ children }) => {
	const { t } = useTranslation()

	const formSchema = useForm<IUploadFile>({
		mode: 'onChange',
		defaultValues: {
			uploadFile: undefined,
		},
		resolver: yupResolver(
			Yup.object().shape({
				uploadFile: fileValidationCsv({
					required: t('ERROR_MESSAGE.EMPTY_FILE_FIELD'),
					fileSize: t('ERROR_MESSAGE.EXCEEDS_MAX_SIZE_1MB'),
					fileType: t('ERROR_MESSAGE.UNSUPPORTED_FILE_FORMAT'),
				}),
			}),
		),
	})

	return <FormProvider {...formSchema}>{children}</FormProvider>
}

export default StepSkuProvider
