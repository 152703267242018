import { useStoreMap } from 'effector-react'
import React from 'react'
import { ComboEvents, ComboStore, GlobalStore } from '@/stores'
import Program from 'domains/program/Program'
import AnalyticsService from 'services/analytics/AnalyticsService'
import ButtonInteraction from 'domains/analytics/ButtonInteraction'
import ScreenName from 'domains/analytics/ScreenName'
import EditComboQuantityFormInputs from 'routes/combos/models/EditComboQuantityFormInputs'
import { isToggleConfigByTier } from '@/utils/featureToggle/featureToggleHelper'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import EditComboQuantityDialogView from './EditComboQuantityDialogView'
import useSettingsData from '../../hooks/useSettingsData'
import useUpdateRedeemLimit from '../../hooks/useUpdateRedeemLimit'

const EditComboQuantityDialog: React.FC = () => {
	const { settingsData } = useSettingsData()
	const userInfo = useGetUserInfo()
	const isSettingConfigByZoneToggle = isToggleConfigByTier()

	const { updateRedeemLimit, updateProgramUseCase } = useUpdateRedeemLimit()

	const selectedTierKey = useStoreMap({
		store: GlobalStore,
		keys: [],
		fn: (state) => state.selectedTierKey ?? '',
	})
	const vendorId = getDefaultVendorIdOfCurrentZone()
	const country = useCurrentZone()
	const { show, programData } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			show: state.showEditComboQuantityDialog,
			programData: state.programData,
		}),
	})

	const handleCancel = (buttonClicked: boolean) => {
		if (buttonClicked) {
			AnalyticsService.events.buttonClicked({
				button_label: null,
				button_name: ButtonInteraction.Cancel,
				screen_name: ScreenName.EditDTComboModal,
				...userInfo,
			})
		}
		ComboEvents.setShowEditComboQuantityDialog(false)
	}

	const handleSubmit = async (values: EditComboQuantityFormInputs) => {
		const { redeemLimit } = values
		const program = { ...programData, id: programData?.id, redeemLimit } as Program

		if (isToggleConfigByTier()) {
			const params = { redeemLimit, selectedTierKey, vendorId, country }
			updateRedeemLimit(params, program, redeemLimit)
		} else {
			updateProgramUseCase(program, redeemLimit)
		}
	}

	return (
		<EditComboQuantityDialogView
			show={show}
			redeemLimit={
				isSettingConfigByZoneToggle ? (settingsData?.redeemLimit as number) : (programData?.redeemLimit as number)
			}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
		/>
	)
}

export default EditComboQuantityDialog
