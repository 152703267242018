/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { TypeToast, useToast } from 'admin-portal-shared-services'

import { ScreenName } from '@/domains'
import { RedeemableItemStatus } from '@/domains/enums'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'

import { publish } from '@/usecase/redeemableItems/CreateRedeemableItemUseCase'
import { execute } from '@/usecase/redeemableItems/DeleteRedeemableItemUseCase'

import { $createRedeemableItemStore, EditRedeemableItemStoreEvents } from '@/stores'

import { useAnalyticsServiceRecordDeleted } from '@/hooks/analyticsService/useAnalyticsServiceRecordDeleted'
import { useAnalyticsServiceUndoClicked } from '@/hooks/analyticsService/useAnalyticsServiceUndoClicked'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import useGetUserInfo from '@/hooks/useInfo/useGetUserInfo'
import { truncate } from '@/utils/string'
import useAuditLogRedeemables from '../../../hooks/useAuditLogRedeemables'

export type RedeemableItemAction = {
	onReload?: () => Promise<void>
	onDelete: () => Promise<void>
	onEdit: () => void
}

export type UseRedeemableItemActionsResult = {
	buildRedeemableAction: (item: RedeemableItem) => RedeemableItemAction
	shouldDisableActions: (item: RedeemableItem) => boolean
	genericToastError: () => void
}

export const useRedeemableItemActions = (): UseRedeemableItemActionsResult => {
	const { vendor } = useGetUserInfo()
	const { t } = useTranslation()
	const toast = useToast()
	const { publishedSuccessfully, variants } = useStore($createRedeemableItemStore)
	const { sendAnalyticsServicerecordDeleted } = useAnalyticsServiceRecordDeleted()
	const { sendAnalyticsServiceUndoClicked } = useAnalyticsServiceUndoClicked()
	const { sendAuditLogRedeemables } = useAuditLogRedeemables()

	const shouldDisableActions = useCallback((item: RedeemableItem): boolean => {
		return !!item.status && item.status === RedeemableItemStatus.PROCESSING
	}, [])

	const genericToastError = useCallback((): void => {
		toast.notify({
			type: TypeToast.ERROR,
			message: t('common:ERROR_MESSAGE:GENERIC_ERROR'),
		})
	}, [t, toast])

	const redeemableItemDeletedSuccessfully = useCallback((): void => {
		toast.notify({
			type: TypeToast.SUCCESS,
			message: t('redeemable:DELETE:SUCCESSFULLY_V2'),
		})
	}, [t, toast])

	const publishRedeemableFailed = useCallback(
		(item: RedeemableItem): void => {
			toast.notify({
				type: TypeToast.ERROR,
				message: t('redeemable:DELETE:RECREATE_FAILED', {
					itemName: truncate(`${item.itemName}`),
				}),
				actions: [
					{
						name: t('BUTTON.TRY_AGAIN'),
						action: () => {
							;(async () => {
								await republish(item)
							})()
						},
					},
				],
			})
		},
		[t, toast],
	)

	const publishRedeemableSucceeded = useCallback((): void => {
		const itemsLength = variants.length + 1
		toast.notify({
			type: TypeToast.SUCCESS,
			message: t(`redeemable:CREATE:${itemsLength ? 'SUCCESSFULLY_ADDED' : 'SUCCESSFULLY_ADDED_PLURAL'}`, {
				itemsLength,
			}),
		})
	}, [variants.length, t])

	const deleteRedeemable = useCallback(
		async (itemToBeDeleted: RedeemableItem): Promise<void> => {
			try {
				await execute(itemToBeDeleted.id)
				sendAuditLogRedeemables({
					pricePerPoint: itemToBeDeleted.pricePerPoint,
					SKU: itemToBeDeleted.vendorItemId,
					operation: IAuditOperations.DELETE,
				})
				redeemableItemDeletedSuccessfully(itemToBeDeleted)
				sendAnalyticsServicerecordDeleted({
					recordId: itemToBeDeleted.vendorItemId,
					recordType: 'Redeemable',
					screenName: ScreenName.RedeemablesList,
				})
			} catch (error) {
				genericToastError()
				throw error
			}
		},
		[genericToastError, redeemableItemDeletedSuccessfully, sendAnalyticsServicerecordDeleted, sendAuditLogRedeemables],
	)

	const republish = useCallback(
		async (item: RedeemableItem) => {
			try {
				if (!vendor) throw new Error('VendorId CAN NOT be NULL')
				await publish({ redeemables: [{ vendorItemId: item.vendorItemId, vendorId: vendor } as RedeemableItem] })
				publishRedeemableSucceeded()
				sendAnalyticsServiceUndoClicked({
					entityId: item.vendorItemId,
					operation: 'Remove',
					screenName: ScreenName.RedeemablesList,
				})
			} catch (error) {
				publishRedeemableFailed(item)
			}
		},
		[publishRedeemableFailed, publishRedeemableSucceeded, sendAnalyticsServiceUndoClicked, vendor],
	)

	const editItem = (item: RedeemableItem) => {
		EditRedeemableItemStoreEvents.onOpen(item)
	}

	useEffect(() => {
		if (!publishedSuccessfully) return
		const { item } = $createRedeemableItemStore.getState()

		if (item) publishRedeemableSucceeded()
	}, [publishRedeemableSucceeded, publishedSuccessfully])

	const buildRedeemableAction = useCallback(
		(item: RedeemableItem): RedeemableItemAction => {
			const isReloadable = item.status === RedeemableItemStatus.FAILED
			return {
				onDelete: async () => {
					await deleteRedeemable(item)
				},
				onReload: isReloadable
					? async () => {
							await republish(item)
						}
					: undefined,
				onEdit: () => editItem(item),
			}
		},
		[deleteRedeemable, republish],
	)

	return {
		buildRedeemableAction,
		genericToastError,
		shouldDisableActions,
	}
}
