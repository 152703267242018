import { Paper } from '@/components/core'
import { InputText } from '@/components/form'
import Container from '@/components/form/Container/Container'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import { CreateRuleFormData } from '@/domains/rule/Rule'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useSetRules } from '@/routes/rules/hooks/useSetRules'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { hasElements } from '@/utils/array'
import { Typography } from '@material-ui/core'
import React, { useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CreateRuleActions from './CreateRuleActions/CreateRuleActions'
import useStyle from './CreateRuleStyle'

type Props = {
	handleCancel: () => void
	onFormSubmit: (formData: CreateRuleFormData) => void
}

const CreateRuleView: React.FC<Props> = ({ handleCancel, onFormSubmit }: Props) => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	useSetRules()

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useFormContext<CreateRuleFormData>()

	const { title, container, paper, gridContainer } = useStyle()

	const disableSubmitButton = hasElements(Object.keys(errors)) || !isValid

	const dispatchAnalytics = useCallback(
		(failureReason: string): void => {
			AnalyticsService.events.error({
				form_name: 'Create new earning rules',
				screen_name: 'Create new earning rules',
				failure_reason: failureReason,
				...userInfo,
			})
		},
		[userInfo],
	)

	useEffect(() => {
		if (errors?.amountSpent) {
			dispatchAnalytics(errors.amountSpent.message!)
		}
		if (errors?.categoryId) {
			dispatchAnalytics(errors.categoryId.message!)
		}
		if (errors?.points) {
			dispatchAnalytics(errors.points.message!)
		}
	}, [dispatchAnalytics, errors?.amountSpent, errors?.categoryId, errors?.points])

	return (
		<Container className={container}>
			<PageLevelTemplate />
			<Paper className={paper}>
				<Typography id="create-rule-title" className={title}>
					{t('rules:CREATE_EARNING_RULES.TITLE')}
				</Typography>
				<Controller
					control={control}
					name="description"
					defaultValue=""
					render={({ field, fieldState: { error } }) => (
						<InputText
							id={field.name}
							test-id="crete-rule-description-input"
							label={t('rules:CREATE_EARNING_RULES.DESCRIPTION')}
							errorText={error?.message}
							optionalLabel="(Optional)"
							{...field}
						/>
					)}
				/>
				<GridContainer className={gridContainer}>
					<GridItem xs={6}>
						<Controller
							name="points"
							control={control}
							defaultValue={undefined}
							render={({ field: { value, ...field }, fieldState: { error } }) => (
								<InputText
									id={field.name}
									type="number"
									label={t('rules:CREATE_EARNING_RULES.POINTS')}
									errorText={error?.message}
									value={value || ''}
									{...field}
								/>
							)}
						/>
					</GridItem>
					<GridItem xs={6}>
						<Controller
							name="amountSpent"
							control={control}
							defaultValue={undefined}
							render={({ field: { value, ...field }, fieldState: { error } }) => (
								<InputText
									id={field.name}
									type="number"
									label={t('rules:CREATE_EARNING_RULES.DOLLAR_AMOUNT')}
									errorText={error?.message}
									value={value || ''}
									{...field}
								/>
							)}
						/>
					</GridItem>
				</GridContainer>
				<Controller
					name="categoryId"
					defaultValue=""
					control={control}
					render={({ field: { value, ...field }, fieldState: { error } }) => (
						<InputText
							id={field.name}
							label={t('rules:CREATE_EARNING_RULES.CATEGORY_ID')}
							errorText={error?.message}
							value={value || ''}
							{...field}
						/>
					)}
				/>
			</Paper>
			<CreateRuleActions disabled={disableSubmitButton} onCancel={handleCancel} onSubmit={handleSubmit(onFormSubmit)} />
		</Container>
	)
}

export default CreateRuleView
