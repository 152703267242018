import { GlobalStore } from '@/stores'
import { hasElements } from '@/utils/array'
import ProgramProjection from '@/domains/enums/program/ProgramProjection'
import Program from '@/domains/program/Program'
import { load } from '@/services/program/ProgramService'

async function execute(projection?: ProgramProjection): Promise<Program | undefined> {
	const { selectedProgram } = GlobalStore.getState()

	return load(projection).then((programs) => {
		if (!selectedProgram || !hasElements(programs)) {
			return undefined
		}
		return programs.find(({ id }) => id === selectedProgram.id)
	})
}

export { execute }
