import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const FormActionsStyle = makeStyles((theme: Theme) =>
	createStyles({
		actionsContainer: {
			'& > *': {
				marginRight: theme.spacing(1),
				'&:last-child': {
					marginRight: 'unset',
				},
			},
		},
		button: {
			height: '48px',
			minWidth: '89px',
			padding: '0px, 24px, 0px, 24px',
		},
	}),
)

export default FormActionsStyle
