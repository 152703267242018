import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { InputDate } from '@/components/form'
import { useTranslation } from 'react-i18next'
import { ChallengeFilterFormData } from '@/stores/challengeCreation'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const ChallengeFormFilterEndDate: React.FC = () => {
	const { t } = useTranslation()
	const { control, watch, setValue, trigger } = useFormContext<ChallengeFilterFormData>()
	const startDateValue = watch('startDate')

	const shouldDisableEndDate = (day: MaterialUiPickersDate) => {
		const startDate = startDateValue ? new Date(startDateValue) : undefined
		return !day || !startDate ? false : day.isBefore(startDate)
	}

	return (
		<Controller
			control={control}
			name="endDate"
			render={({ field, fieldState: { error } }) => (
				<InputDate
					id="challenge-end-date"
					label={t('challenge:FILTERS_STEP.END_DATE')}
					errorText={error?.message}
					onChange={(_, inputValue) => setValue(field.name, inputValue ?? undefined, { shouldDirty: true })}
					shouldDisableDate={shouldDisableEndDate}
					onBlur={() => {
						field.onBlur()
						trigger(field.name)
					}}
					value={field.value}
					name={field.name}
					keyboardDataPickerProps={{
						onClose: () => {
							trigger(field.name)
						},
						invalidDateMessage: null,
					}}
				/>
			)}
		/>
	)
}

export default ChallengeFormFilterEndDate
