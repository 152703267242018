import { Card, Paragraph, Tooltip } from '@hexa-ui/components'
import React, { useEffect, useState } from 'react'
import { Clock, Edit2, XCircle } from '@hexa-ui/icons'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { useTranslation } from 'react-i18next'
import { DEFAULT_LANGUAGE } from '@/utils/constants'
import { usePricePerPointConfiguration } from '../../hooks/usePricePerPointConfiguration'
import useStyles from './EditDefaultCppCardStyle'

interface EditDefaultCppCardProps {
	onEdit: () => void
	isPending?: boolean
}

export const EditDefaultCppCard: React.FC<EditDefaultCppCardProps> = ({ onEdit, isPending }) => {
	const { t } = useTranslation()
	const css = useStyles()
	const { data } = usePricePerPointConfiguration()
	const pricePerPoint = useFormatCurrency(data?.pricePerPoint, t('redeemable:SETTINGS.PPP_MISSING_DEFAULT_VALUE'))
	const [shouldDeniedIconBeVisible, setShouldDeniedIconBeVisible] = useState(false)

	const sentToApprovalText = t('redeemable:SETTINGS.PPP_SENT_TO_APPROVAL', { ppp_sent_to_approval: pricePerPoint })
	const deniedText = data?.lastChangeRequest?.reason
	const status = data?.lastChangeRequest?.status

	function formatDate(date: string | undefined): string {
		if (!date) {
			return ''
		}
		const options: Intl.DateTimeFormatOptions = {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		}

		return new Date(date).toLocaleDateString(DEFAULT_LANGUAGE, options)
	}
	const lastUpdated = formatDate(data?.lastModified)

	const handleShouldDeniedIconBeVisible = () => {
		const deniedTimestamp = 48 * 60 * 60 * 1000 // 48 hours in milliseconds
		const twoDaysLater = new Date(new Date(data?.lastModified).getTime() + deniedTimestamp)

		if (Date.now() >= twoDaysLater.getTime()) {
			return false
		}
		return true
	}

	useEffect(() => {
		setShouldDeniedIconBeVisible(handleShouldDeniedIconBeVisible())
	}, [data?.lastModified])

	return (
		<Card
			border="medium"
			elevated="medium"
			className={css.container}
			data-testid="redeemable-items-edit-default-cpp-card"
		>
			<div className={css.infoWrapper}>
				<div className={css.textWrapper}>
					<Paragraph weight="semibold">{t('redeemable:SETTINGS.PPP_LABEL')}</Paragraph>
					<Paragraph weight="medium">{pricePerPoint}</Paragraph>
					{status === 'denied' && shouldDeniedIconBeVisible && (
						<Tooltip placement="bottom" text={deniedText}>
							<XCircle size="medium" className={css.deniedIcon} />
						</Tooltip>
					)}
					{isPending && (
						<Tooltip placement="bottom" text={sentToApprovalText}>
							<Clock size="medium" className={css.sentToApprovalIcon} />
						</Tooltip>
					)}
				</div>
				<div>
					<button
						type="button"
						className={css.paragraph}
						onClick={onEdit}
						data-testid="redeemable-items-edit-default-cpp-card-button"
					>
						<Edit2 size="medium" /> {t('redeemable:EDIT.PPP_UPDATE_BUTTON')}
					</button>
				</div>
			</div>
			<div>
				{lastUpdated && (
					<Paragraph colortype="secondary">{t('redeemable:EDIT.PPP_UPDATED_ON', { lastUpdated })}</Paragraph>
				)}
			</div>
		</Card>
	)
}
