import Program from 'domains/program/Program'
import * as ProgramService from 'services/program/ProgramService'

/**
 * Updates Program Combos
 *
 * @param program Program to be update
 */
async function execute(program: Program): Promise<boolean> {
	try {
		const combos = program.combos.map(({ comboId, points }) => ({ comboId, points }))
		await ProgramService.updateCombos(program.id, combos)
		return true
	} catch (e) {
		return false
	}
}

export { execute }
