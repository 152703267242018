import React, { useMemo } from 'react'
import SmallSelect from '@/components/form/Select/SmallSelect'
import { useAuthenticationService, useUserMetadata } from 'admin-portal-shared-services'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { getAvailableCountries } from '@/utils/countryUtil'
import { useGlobalConfig } from '@/hooks/global/useGlobalConfig'
import { hasMoreElementsThan } from '@/utils/array'
import { useTranslation } from 'react-i18next'

interface ICountry {
	label: string
	value: string
}

const SelectZone: React.FC = (): JSX.Element | null => {
	const { t } = useTranslation()
	const selectedZone = useCurrentZone()
	const userCountry = useAuthenticationService().getCountryB2C()
	const { data } = useUserMetadata()
	const { defineCurrentZone } = useGlobalConfig()

	const options = useMemo(
		() =>
			getAvailableCountries({
				supportedCountries: data?.supportedCountries,
				userCountry,
			})
				.map((country: string) => ({
					label: t(`COUNTRIES.${country}`),
					value: country,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[data?.supportedCountries, userCountry, t],
	)

	const showSelectCountries = hasMoreElementsThan<ICountry>(options, 1)

	return showSelectCountries ? (
		<SmallSelect value={selectedZone ?? undefined} options={options} onChange={defineCurrentZone} />
	) : null
}

export default SelectZone
