import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const programConfigurationStyle = makeStyles((theme: Theme) =>
	createStyles({
		boxItem: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
		flexSpaceBetween: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		title: {
			marginBottom: '16px',
			fontFamily: 'Work Sans',
			fontSize: '16px',
			fontWeight: 500,
		},
		content: {
			marginBottom: '16px',
			fontSize: '16px',
			color: '#707372',
		},
		container: {
			padding: '32px 32px 16px',
			flex: 1,
		},
		button: {
			fontSize: '14px',
			fontWeight: 500,
			letterSpacing: 0,
			lineHeight: '20px',
			color: theme.palette.info.main,
			cursor: 'pointer',
			textDecoration: 'underline',
			'&:hover': {
				filter: 'brightness(60%)',
			},
		},
	}),
)

export default programConfigurationStyle
