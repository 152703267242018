import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		root: {
			padding: '8px',
		},
	}),
)

export default styles
