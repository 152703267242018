import { $createRedeemableItemStore } from '@/stores/redeemableItems/CreateRedeemableItemStore'
import { EmptyState } from '@hexa-ui/components'
import { useStore } from 'effector-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CreateRedeemableItemContentStyle } from './CreateRedeemableItemContentStyle'
import { ItemCell } from './ItemCell/ItemCell'
import { ItemCellLoader } from './Loader/ItemCellLoader'

type EmptyStateProps = {
	description: string
	ilustrationName?: 'beesSectionLevel' | 'beesAlert'
}

export const CreateRedeemableItemContent: React.FC = () => {
	const { atInitialState, item, isSearching } = useStore($createRedeemableItemStore)

	const { t } = useTranslation()
	const css = CreateRedeemableItemContentStyle()

	const noAction: EmptyStateProps = {
		description: t('redeemable:CREATE.EMPTY_STATE.DESCRIPTION'),
		ilustrationName: 'beesSectionLevel',
	}

	const itemNotFound: EmptyStateProps = {
		description: t('redeemable:CREATE.ITEM_NOT_FOUND.DESCRIPTION'),
		ilustrationName: 'beesAlert',
	}

	const emptyState = atInitialState ? noAction : itemNotFound

	return (
		<div className={css.container} data-testid="create-redeemable-item-content">
			{isSearching && <ItemCellLoader />}
			{!isSearching &&
				(!item ? (
					<div className={css.emptyStateContainer}>
						<EmptyState.SectionLevel {...emptyState} />
					</div>
				) : (
					<ItemCell {...item} pricePerPoint={item.pricePerPoint} />
				))}
		</div>
	)
}
