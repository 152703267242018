import { ScreenName } from '@/domains'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { useAnalyticsServicePricePerPoints } from '@/hooks/analyticsService/useAnalyticsServiceEventPricePerPoints'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import {
	$editRedeemableItemStore,
	CriticalPricePerPointConfigurationStoreEvents,
	EditRedeemableItemStoreEvents,
} from '@/stores'
import { EditRedeemableItemUseCase } from '@/usecase'
import { MODAL_CHANGE_DELAY } from '@/utils/constants'
import { unformat } from '@/utils/currencyHelper'
import { truncate } from '@/utils/string'
import { Alert, Button, Dialog, Heading } from '@hexa-ui/components'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useStore } from 'effector-react'
import React, { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useAuditLogRedeemables from '../../hooks/useAuditLogRedeemables'
import { usePricePerPointChanged } from '../../hooks/usePricePerPointChanged'
import { EditRedeemableItemContent } from './EditRedeemableItemContent'
import EditRedeemableItemFormProvider from './EditRedeemableItemFormProvider'
import EditRedeemableItemStyle from './EditRedeemableItemStyle'

export const EditRedeemableItem = () => {
	const { isOpened, item, isPublishSuccess, isPublishing, hasError } = useStore($editRedeemableItemStore)

	const toast = useToast()

	const { t } = useTranslation()
	const css = EditRedeemableItemStyle()

	useEffect(() => {
		if (!isPublishSuccess) return

		toast.notify({
			type: TypeToast.SUCCESS,
			message: t('redeemable:NOTIFICATION.EDIT_ITEM.SUCCESS', {
				itemName: truncate(`${item.itemName}`),
			}),
		})
	}, [t, toast, item.itemName, isPublishSuccess])

	const onModalOpenChangeHandler = useCallback(
		() => (isOpened ? EditRedeemableItemStoreEvents.reset() : EditRedeemableItemStoreEvents.onOpen(item)),
		[isOpened, item],
	)

	return (
		<div className={css.modalAlertWrapper} data-testid="edit-redeemable-item-wrapper">
			<EditRedeemableItemFormProvider>
				<Dialog.Root
					actions={<Actions hasError={hasError} isPublishing={isPublishing} item={item} />}
					title={<Heading>{t('redeemable:EDIT_ITEM.TITLE')}</Heading>}
					open={isOpened}
					onOpenChange={onModalOpenChangeHandler}
				>
					<EditRedeemableItemContent item={item} />

					{hasError && (
						<div data-testid="edit-redeemable-item-error-message">
							<Alert type="error" message={t('settings:NOTIFICATION.EDIT.ERROR')} className={css.errorMessage} />
						</div>
					)}
				</Dialog.Root>
			</EditRedeemableItemFormProvider>
		</div>
	)
}

type ActionsProps = {
	item: RedeemableItem
	isPublishing: boolean
	hasError?: boolean
}

const Actions = ({ hasError, item, isPublishing }: ActionsProps) => {
	const { sendAuditLogRedeemables } = useAuditLogRedeemables()
	const { sendAnalyticsServicePPP } = useAnalyticsServicePricePerPoints()
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()

	const {
		handleSubmit,
		formState: { isDirty, isValid },
	} = useFormContext()

	const { t } = useTranslation()
	const css = EditRedeemableItemStyle()

	const onSubmit = useCallback(
		async (pricePerPoint: number) => {
			try {
				await EditRedeemableItemUseCase.execute({ ...item, pricePerPoint })
				sendAnalyticsServicePPP({ pricePerPoint, sku: item.vendorItemId, skuName: item.itemName })
				if (item) sendAuditLogRedeemables({ pricePerPoint, SKU: item.vendorItemId, operation: IAuditOperations.UPDATE })
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				sendAnalyticsServiceEventError({ failureReason: error.message, screenName: ScreenName.EditRedeemablePPP })
			}
		},
		[item, sendAnalyticsServiceEventError, sendAnalyticsServicePPP, sendAuditLogRedeemables],
	)

	const onClose = () => {
		sendAnalyticsServiceEventButtonClickedEvent('Edit Redeemable Price Per Point', 'Cancel', 'Cancel')
	}

	const buttonAction = useCallback(
		({ pricePerPoint }: { pricePerPoint: string }) => {
			const unformattedPricePerPoint: number = unformat(pricePerPoint)
			if (item) EditRedeemableItemStoreEvents.setItem({ ...item, pricePerPoint: unformattedPricePerPoint })

			setTimeout(
				() =>
					CriticalPricePerPointConfigurationStoreEvents.onOpen({
						submit: () => {
							onSubmit(unformattedPricePerPoint)
						},
						back: () => EditRedeemableItemStoreEvents.onOpen(item),
						close: () => EditRedeemableItemStoreEvents.onClose(),
						skuPricePerPoint: unformattedPricePerPoint,
					}),
				MODAL_CHANGE_DELAY,
			)
		},
		[item, onSubmit],
	)

	const isValueChanged = usePricePerPointChanged()

	const isSubmitDisabled = !isValueChanged || !isDirty || !isValid

	return (
		<div className={css.actionsWrapper} data-testid="edit-redeemable-item-actions-wrapper">
			<Dialog.Close>
				<Button size="large" variant="secondary" data-testid="edit-redeemable-item-cancel-button" onClick={onClose}>
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="edit-redeemable-item-save-button"
				size="large"
				variant="primary"
				type="submit"
				onClick={handleSubmit(buttonAction)}
				disabled={isSubmitDisabled}
				isLoading={!!isPublishing}
			>
				{hasError ? t('BUTTON.TRY_AGAIN') : t('BUTTON.SAVE')}
			</Button>
		</div>
	)
}
