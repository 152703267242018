import { DEFAULT_IMAGE_FALLBACK_URL } from '@/utils/constants'
import clsx from 'clsx'
import React, { ImgHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import styles from './styles'

interface ProgressiveImageProps extends ImgHTMLAttributes<HTMLImageElement> {
	imageSource?: string
	placeholder?: string
	width?: string
	height?: string
}

/**
 * @param imageSource the image source. Required
 * @param placeholder the placeholder image in case of failure. Default: {DEFAULT_IMAGE_FALLBACK_URL}
 * @param width to set image width. Default: 100%
 * @param height to set image height. Default: auto
 */

const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
	imageSource,
	placeholder = DEFAULT_IMAGE_FALLBACK_URL,
	width,
	height,
	...rest
}) => {
	const classes = styles({})
	const [isLoaded, setIsLoaded] = useState(false)
	const imageRef = useRef<HTMLImageElement>(null)

	const onLoadError = useCallback(() => {
		const imageObject = imageRef.current as HTMLImageElement
		if (imageObject) {
			imageObject.src = placeholder
			imageObject.style.objectFit = 'scale-down'
			imageObject.style.background = '#fbfafe'
			imageObject.onerror = null
		}
	}, [placeholder])

	useEffect(() => {
		const imageObject = imageRef.current as HTMLImageElement
		if (!imageSource) {
			imageObject.src = placeholder
		} else if (imageObject) {
			imageObject.src = imageSource
		}
	}, [imageSource, placeholder])

	return (
		<div className={clsx(classes.container, [width, height])}>
			<img
				className={clsx(classes.image, [width, height], !isLoaded && classes.skeleton)}
				ref={imageRef}
				width={width}
				height={height}
				onError={onLoadError}
				alt=""
				onLoad={() => {
					setIsLoaded(true)
				}}
				{...rest}
			/>
		</div>
	)
}

export default ProgressiveImage
