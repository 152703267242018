import { createStore } from 'effector'
import ChallengeHistoryState from './ChallengeHistoryState'
import * as ChallengeHistoryEvents from './ChallengeHistoryEvents'

export const ChallengeHistoryInitialState: ChallengeHistoryState = {
	cancelDialog: {
		show: false,
	},
	challengeAccepted: null,
	challengeCancelled: false,
	challenges: [],
	pagination: {
		page: 0,
		pageSize: 50,
		totalElements: 0,
		totalPages: 0,
	},
	isLoading: false,
	showError: false,
	direction: 'desc',
	orderBy: 'lastModify',
}

const ChallengeHistoryStore = createStore(ChallengeHistoryInitialState)
	.on(ChallengeHistoryEvents.toggleCancelDialog, (state) => ({
		...state,
		cancelDialog: {
			show: !state.cancelDialog.show,
		},
	}))
	.on(ChallengeHistoryEvents.setChallengeAccepted, (state, challengeAccepted) => ({
		...state,
		challengeAccepted,
	}))
	.on(ChallengeHistoryEvents.setChallengeCancelled, (state, challengeCancelled) => ({
		...state,
		challengeCancelled,
	}))
	.on(ChallengeHistoryEvents.setChallenges, (state, challenges) => ({
		...state,
		challenges,
	}))
	.on(ChallengeHistoryEvents.setPagination, (state, pagination) => ({
		...state,
		pagination,
	}))
	.on(ChallengeHistoryEvents.setLoading, (state, isLoading) => ({
		...state,
		isLoading,
	}))
	.on(ChallengeHistoryEvents.setShowError, (state, showError) => ({
		...state,
		showError,
	}))
	.on(ChallengeHistoryEvents.setOrderBy, (state, orderBy) => ({
		...state,
		orderBy,
	}))
	.on(ChallengeHistoryEvents.setDirection, (state, direction) => ({
		...state,
		direction,
	}))
	.reset(ChallengeHistoryEvents.resetForChallengeHistory)

export default ChallengeHistoryStore
