import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Table } from '@hexa-ui/components'
import * as LoadTransactionListUseCase from '@/usecase/transactions/LoadTransactionListUseCase'
import getTransactionData from '@/utils/getTransactionData'
import { useTranslation } from 'react-i18next'
import { columns } from '@/utils/transactionsVariablesUtil'
import { FormattedTransaction, Column, TranslatedTransaction } from './TransactionLog.types'
import TransactionDetailDialog from '../TransactionDetailDialog/TransactionDetailDialog'

const getColumns = (t: (text: string) => string): Column[] =>
	columns.map((column) => ({
		...column,
		Header: t(column.Header),
	}))

const TransactionLogView = () => {
	const [data, setData] = useState<FormattedTransaction[]>([])
	const { t } = useTranslation()
	const translatedColumns = useMemo(() => getColumns(t), [t, columns])

	const fetchData = useCallback(async () => {
		try {
			const response = await LoadTransactionListUseCase.execute()
			if (response !== null) {
				const translatedTransaction: TranslatedTransaction[] = response.map((item) => {
					const type = t(`transaction:TYPE:${item.type}`)
					const target =
						item.totalAccounts > 1 ? t('transaction:TARGET:TARGET_MULTIPLE') : t('transaction:TARGET:TARGET_SINGLE')
					return {
						...item,
						type,
						totalAccounts: target,
					}
				})
				const formattedData = getTransactionData(translatedTransaction)
				const dataWithActions = formattedData.map((item) => ({
					...item,
					actions: <TransactionDetailDialog transactionId={item.id} />,
				}))
				setData(dataWithActions)
			}
		} catch (error) {
			console.error('Error:', error)
		}
	}, [])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	return (
		<div data-testid="transaction-table">
			<Table columns={translatedColumns} data={data} cellHeight="cozy" data-testid="transaction-table--component" />
		</div>
	)
}

export default TransactionLogView
