import { hasElements } from './array'

export const isEqualToVendorIds = (filterKey: string): boolean => filterKey === 'vendorIds'

export const removeVendorIdsFromFilters = <TItem extends { filter: string }>(list: Array<TItem>): Array<TItem> => {
	if (!hasElements(list)) {
		return []
	}
	return list.filter((item: TItem) => !isEqualToVendorIds(item.filter))
}
