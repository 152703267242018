import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useStoreMap } from 'effector-react'
import { Group, ScreenName } from '@/domains'
import ButtonInteraction from '@/domains/analytics/ButtonInteraction'
import { ChallengeFilterFormData, ChallengeStore, ChallengeEvents } from '@/stores/challengeCreation'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { LoadGroupUseCase } from '@/usecase'
import { AutoCompleteOptions } from '@/components/form'
import { hasElements } from '@/utils/array'
import { createIdMapFromArray } from '@/utils/mappingUtil'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import InputGroupView from './InputGroupView'
import useIndividualAccountGroup from '../../hooks/useIndividualAccountGroup'

export type InputGroupProps = {
	index: number
	defaultValue: Array<string>
	className: string
	disabled: boolean
	fixedValues?: Array<string>
}
const InputGroup: React.FC<InputGroupProps> = ({ index, defaultValue, className, disabled, fixedValues }) => {
	const individualAccountGroup = useIndividualAccountGroup()
	const { setValue, getValues, watch, trigger } = useFormContext<ChallengeFilterFormData>()
	const isIndividualTarget = !!watch('individualTarget')
	const userInfo = useGetUserInfo()
	const [isLoading, setIsLoading] = useState(false)

	const { newlyCreatedGroupId, groupOptionsMap } = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => ({
			challengeSetupFormData: state.challengeSetupFormData || null,
			newlyCreatedGroupId: state.newlyCreatedGroupId,
			groupOptionsMap: state.groupOptionsMap,
		}),
	})

	const options = useMemo<Array<AutoCompleteOptions>>(() => {
		const list = Object.values(groupOptionsMap).map((group: Group) => ({
			value: group.id,
			label: group.name,
			selectable: true,
		}))
		if (isIndividualTarget) {
			return list.filter(({ value }) => !!individualAccountGroup[value])
		}
		return list
	}, [groupOptionsMap, isIndividualTarget, individualAccountGroup])

	const setFilterValues = useCallback(
		(values: Array<string>): void => {
			setValue(`filters.${index}.value` as const, values, { shouldDirty: true })
			trigger(`filters.${index}.value`)
		},
		[index, setValue, trigger],
	)

	const handleChange = (_: React.ChangeEvent<unknown>, selectedOptions: Array<AutoCompleteOptions>): void => {
		const selectedValues = selectedOptions.map(({ value }) => value as string)
		checkRemovedValues(selectedValues)
		setFilterValues(selectedValues)
		if (selectedValues.length) {
			const newFilterValue = selectedOptions[selectedOptions.length - 1].label
			AnalyticsService.events.selectInteraction({
				select_name: 'Filter Values',
				select_label: 'Filter Values',
				selected_value: newFilterValue,
				form_name: 'Challenge Creation - Step 2 - Target Type',
				screen_name: 'Challenge Creation - 2nd Step',
				...userInfo,
			})
		}
	}

	const updateOptionfromDropList = (valuesId: string[]) => {
		const newGroupOptions: Record<string, Group> = {}
		valuesId.forEach((id) => {
			newGroupOptions[id] = groupOptionsMap[id]
		})
		ChallengeEvents.updateGroupOptionsMap(newGroupOptions)
	}

	const checkRemovedValues = (values: string[]) => {
		const oldValues = getValues(`filters.${index}.value`)
		updateOptionfromDropList(values)
		oldValues.forEach((item) => {
			if (!values.includes(item)) {
				AnalyticsService.events.selectInteraction({
					select_name: 'Remove Group - General',
					select_label: 'Remove',
					selected_value: String(options.find((obj) => obj.value === item && obj)?.label),
					form_name: 'Challenge Creation - Step 2 - Target Type',
					screen_name: 'Challenge Creation - 2nd Step',
					...userInfo,
				})
			}
		})
	}

	const handleActionClick = (): void => {
		AnalyticsService.events.buttonClicked({
			button_label: 'Add Filter',
			button_name: ButtonInteraction.AddNewGroup,
			screen_name: ScreenName.CreateChallenge2ndStepFiltersDropdown,
			...userInfo,
		})
		ChallengeEvents.setShowAccountGroupModal(true)
	}

	const loadAllOptions = useCallback(async (): Promise<void> => {
		setIsLoading(true)
		try {
			const list = await LoadGroupUseCase.execute()
			if (hasElements(list)) {
				const idMap = createIdMapFromArray('id', list)
				ChallengeEvents.appendToGroupOptionsMap(idMap)
			}
		} finally {
			setIsLoading(false)
		}
	}, [])

	const selectTheNewGroup = useCallback(
		(newGroupId: string) => {
			const groupIds = getValues().filters[index].value || []
			const newGroupIds = [...groupIds, newGroupId]
			setFilterValues(newGroupIds)
			ChallengeEvents.setNewlyCreatedGroup(null)
		},
		[getValues, index, setFilterValues],
	)
	useEffect(() => {
		loadAllOptions().finally(() => {
			if (newlyCreatedGroupId) {
				selectTheNewGroup(newlyCreatedGroupId)
			}
		})
	}, [loadAllOptions, newlyCreatedGroupId, selectTheNewGroup])

	return (
		<InputGroupView
			index={index}
			defaultValue={defaultValue}
			options={options}
			isLoading={isLoading}
			className={className}
			disabled={disabled}
			fixedValues={fixedValues}
			onChange={handleChange}
			onAddGroupClick={handleActionClick}
		/>
	)
}

export default InputGroup
