import { createStyles, makeStyles } from '@material-ui/core/styles'

const PricePerPointEditorStyle = makeStyles(() =>
	createStyles({
		modalAlertWrapper: {
			'& [role="alertdialog"]': {
				maxWidth: '640px',

				'& > div:first-child': {
					height: 'auto',
				},
			},
		},
		headerImage: {
			justifyContent: 'center',

			'& *': {
				background: 'none',
			},

			'& img': {
				width: '150px',
				height: '150px',
				objectFit: 'contain',
				margin: '32px auto 0 auto',
			},
		},
		descriptionWrapper: {
			marginBottom: '32px',
		},
		tiersWrapper: {
			marginTop: '16px',
		},
		inputWrapper: {
			marginBottom: '8px',

			'& > div': {
				width: '100%',
			},
		},
		actionsWrapper: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: '24px',
		},
		errorMessage: {
			width: '100%',
		},
	}),
)

export default PricePerPointEditorStyle
