import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		root: {
			'& tr > th:nth-child(2)': {
				paddingLeft: '0',
			},
		},
		info: {
			margin: '1px 0 0 8px',
			fontSize: '18px',
		},
	}),
)

export default styles
