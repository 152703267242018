import React, { ReactNode, ReactElement, useEffect } from 'react'
import { Paper } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { CheckCircle } from '@material-ui/icons'
import ErrorIcon from 'components/icons/ErrorIcon'
import InfoIcon from 'components/icons/InfoIcon'
import { PageLevelEvents } from '@/stores'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import styles from './styles'

export type BottomSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

type Props = {
	type: 'success' | 'error' | 'info'
	children: ReactNode
	bottomSpacing?: BottomSpacing
	multiline?: boolean
	toast?: boolean
	id?: string
}

const PageLevelNotification: React.FC<Props> = ({ type, children, bottomSpacing, multiline, id, toast }: Props) => {
	const classes = styles({ multiline: !!multiline })
	const theme = useTheme()

	useEffect(() => {
		setTimeout(() => {
			PageLevelEvents.hideNotification()
		}, 5000)
	}, [])

	const renderIcon = (backgroundColor: string, icon: ReactNode): ReactElement => {
		return (
			<Paper
				className={classes.container}
				elevation={0}
				style={{ backgroundColor, alignItems: multiline ? 'flex-start' : 'center' }}
			>
				{icon}
				{children}
			</Paper>
		)
	}

	return (
		<GridContainer
			style={{
				paddingBottom: theme.spacing(bottomSpacing ?? 0),
			}}
			id={id}
		>
			<GridItem xs={toast ? 4 : 12}>
				{
					{
						error: renderIcon(theme.palette.error.light, <ErrorIcon />),
						success: renderIcon(
							theme.palette.success.light,
							<CheckCircle className={classes.icon} style={{ color: theme.palette.success.dark }} />,
						),
						info: renderIcon(theme.palette.info.light, <InfoIcon />),
					}[type]
				}
			</GridItem>
		</GridContainer>
	)
}

export default PageLevelNotification
