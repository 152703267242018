import ProgramCombo from 'domains/program/ProgramCombo'
import * as Yup from 'yup'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { fieldPointsValidation } from '@/utils/validationObjects'
import { yupResolver, Controller, useForm } from 'admin-membership-hookform-resolvers'
import InputNumber from 'components/hook-form/InputNumber'
import FormDialogTemplate from 'components/templates/FormDialogTemplate'
import EditComboFormInputs from 'routes/combos/models/EditComboFormInputs'
import useStyles from './EditComboDialogStyle'

type Props = {
	show: boolean
	combo: ProgramCombo
	onSubmit: (values: EditComboFormInputs) => void
	onCancel: (buttonClicked: boolean) => void
}

const EditComboDialogView: React.FC<Props> = ({ show, combo, onSubmit, onCancel }: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const schema = useMemo(
		() =>
			Yup.object().shape({
				points: fieldPointsValidation(),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t],
	)

	const defaultValues = {
		points: combo?.points,
	}

	const { handleSubmit, control, errors, formState } = useForm({
		resolver: yupResolver(schema),
		defaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldFocusError: true,
	})

	return (
		<FormDialogTemplate
			title={t('combo:EDIT_DIALOG_TITLE')}
			showDialog={show}
			cancelButtonProps={{ handleClick: onCancel }}
			submitButtonProps={{ name: t('combo:SUBMIT_TEXT'), disabled: !formState.isDirty }}
			handleSubmit={handleSubmit(onSubmit)}
		>
			<Typography id="combo-name" data-testid="combo-name" className={classes.comboInfoText}>
				{`${t('combo:EDIT_DIALOG_NAME_LABEL')}: ${combo?.name}`}
			</Typography>
			<Typography id="combo-id" data-testid="combo-id" className={classes.comboInfoText}>
				{`${t('combo:EDIT_DIALOG_ID_LABEL')}: ${combo?.comboId}`}
			</Typography>
			<Controller
				control={control}
				as={InputNumber}
				id="combo-points"
				name="points"
				label={t('combo:POINTS_VALUE')}
				errorText={errors?.points?.message}
				defaultValue={combo?.points}
			/>
		</FormDialogTemplate>
	)
}

export default EditComboDialogView
