import React, { ForwardRefRenderFunction, useState, useImperativeHandle, forwardRef } from 'react'
import { Button, Grid } from '@material-ui/core'
import { RowSelection } from '../model/RowSelectionConfig'
import useStyles from './styles'
import { useDataTableContext } from '../context/DataTableContext'

type Props = {
	rowSelection: RowSelection
	clearSelection: () => void
	getSelectedRows: () => number[]
}

export interface TableRowSelectionActionsRef {
	updateCounter: (rowsCount: number) => void
}

const TableRowSelectionActions: ForwardRefRenderFunction<TableRowSelectionActionsRef, Props> = (
	{ rowSelection: { clearSelectionButton, itemsCountLabel, actions }, clearSelection, getSelectedRows },
	ref,
) => {
	const classes = useStyles()
	const [counter, setCounter] = useState('')
	const [hidden, setHidden] = useState(true)
	const { getCurrentData: currentData } = useDataTableContext()

	const updateCounter = (rowsCount: number) => {
		setHidden(rowsCount === 0)

		let innerText = ''
		if (rowsCount === 1) {
			innerText = `${rowsCount} ${itemsCountLabel.one}`
		} else if (rowsCount) {
			innerText = `${rowsCount} ${itemsCountLabel.many}`
		}
		setCounter(innerText)
	}

	useImperativeHandle(ref, () => ({
		updateCounter,
	}))

	return (
		<div className={classes.rowSelection} hidden={hidden} data-testid="row-selection-actions">
			<Grid container>
				<Grid item xs={6}>
					<p className={classes.rowSelectionCounter} data-testid="row-selection-counter">
						{counter}
					</p>
				</Grid>
				<Grid item justifyContent="flex-end" xs={6} className={classes.rowSelectionActions}>
					<Button
						variant="outlined"
						color="primary"
						className={classes.actionButton}
						onClick={clearSelection}
						id={clearSelectionButton.id}
					>
						{clearSelectionButton.label}
					</Button>
					{actions.map(({ id: actionId, label, onClick }) => (
						<Button
							key={actionId}
							variant="outlined"
							color="primary"
							className={classes.actionButton}
							id={actionId}
							onClick={() => {
								const selectedRows = getSelectedRows()
								onClick(
									currentData.filter((_, index) => selectedRows.includes(index)),
									selectedRows,
								)
							}}
						>
							{label}
						</Button>
					))}
				</Grid>
			</Grid>
		</div>
	)
}

export default forwardRef(TableRowSelectionActions)
