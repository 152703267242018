import { getUserCountry } from '@/utils/tokenB2CHelper'
import storeSymbols from 'configs/currencySymbol.json'
import currency from 'currency.js'
import { Mask } from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'
import { countryConfig, MAX_DECIMAL_SIZE, MAX_INTEGER_SIZE, CUSTOM_COUNTRIES } from './constants'

const symbols: Record<string, string> = storeSymbols

export const format = (
	value?: number,
	removeZeroDecimal = true,
	fallback = '-',
	countryCode = getUserCountry(),
): string => {
	if (Number.isNaN(value) || value === undefined) {
		return fallback
	}

	const config = getCurrencyConfig(countryCode)
	const money = currency(value, {
		...config,
		pattern: `!${config.literal}#`,
		precision: MAX_DECIMAL_SIZE,
		decimal: config.decimal !== '' ? config.decimal : undefined,
	})

	const formatedValue = reRemoveTrailingZeros(money.format()).replace(/([.|,])$/, removeZeroDecimal ? '' : '$100')

	return formatedValue
}

export const getSymbol = (countryCode = getUserCountry()): string => {
	const { symbol } = getCurrencyConfig(countryCode)
	return symbol
}

const reRemoveTrailingZeros = (value: string): string => {
	const re = new RegExp(`0{2,${MAX_DECIMAL_SIZE}}$`, 'g')
	return value.replace(re, '')
}

export const unformat = (value: string, countryCode = getUserCountry()): number => {
	const config = getCurrencyConfig(countryCode)
	const money = currency(value, {
		...config,
		pattern: `!${config.literal}#`,
		precision: MAX_DECIMAL_SIZE,
		decimal: config.decimal !== '' ? config.decimal : undefined,
	})
	const moneyValue = money.value

	return moneyValue
}

type GetCurrencyConfigResult = {
	symbol: string
	symbolLiteral: string
	separator: string
	literal: string
	decimal: string
}

const getCustomLanguage = (countryCode: string) => {
	return CUSTOM_COUNTRIES[countryCode as keyof typeof CUSTOM_COUNTRIES]
}

export const getCurrencyConfig = (countryCode: string): GetCurrencyConfigResult => {
	const { locale, currency: countryCurrency } = countryConfig[countryCode] ?? countryConfig.fallback

	const customLanguage = getCustomLanguage(countryCode)

	const auxLocale = customLanguage?.locale ?? locale
	const auxCountryCurrency = customLanguage?.currency ?? countryCurrency

	const formatter = Intl.NumberFormat(auxLocale, { style: 'currency', currency: auxCountryCurrency })
	const parts = formatter.formatToParts(1000)

	const symbol = symbols[countryCode] ?? parts.filter((opt) => opt.type === 'currency')[0].value ?? 'ERROR'
	const literal = parts.filter((opt) => opt.type === 'literal')[0]?.value ?? ''
	const symbolLiteral = `${symbol}${literal}`
	const separator = parts.filter((opt) => opt.type === 'group')[0]?.value ?? ''
	const decimal = parts.filter((opt) => opt.type === 'decimal')[0]?.value ?? ''

	return { symbol, symbolLiteral, literal, separator, decimal }
}

export const getMask = (countryCode = getUserCountry()): Mask => {
	const config = getCurrencyConfig(countryCode)
	const mask = createNumberMask({
		prefix: config.symbolLiteral,
		thousandsSeparatorSymbol: config.separator,
		decimalSymbol: config.decimal,
		includeThousandsSeparator: true,
		allowDecimal: true,
		allowNegative: false,
		allowLeadingZeroes: true,
		integerLimit: MAX_INTEGER_SIZE,
		decimalLimit: MAX_DECIMAL_SIZE,
	})
	return mask
}
