import { BottomSpacing, PageLevelNotification } from '@/components/core'
import { Typography } from '@material-ui/core'
import { useStoreMap } from 'effector-react'
import React from 'react'
import PageLevelStore from 'stores/pageLevel/PageLevelStore'
import useStyle from './styles'

type Props = {
	bottomSpacing?: BottomSpacing
	toast?: boolean
}

const PageLevelTemplate: React.FC<Props> = ({ bottomSpacing, toast }) => {
	const classes = useStyle()
	const { notificationType, notificationMessage } = useStoreMap({
		store: PageLevelStore,
		keys: [],
		fn: (state) => ({
			notificationType: state.notificationType,
			notificationMessage: state.notificationMessage,
		}),
	})

	return (
		<>
			{notificationType && (
				<PageLevelNotification type={notificationType} bottomSpacing={bottomSpacing ?? 1} toast={toast}>
					{notificationType === 'success' ? (
						<Typography variant="h5" id="transaction-notification-success">
							{notificationMessage}
						</Typography>
					) : (
						<Typography component="span" id="transaction-notification-error" className={classes.pageLevelErrorMessage}>
							{notificationMessage}
						</Typography>
					)}
				</PageLevelNotification>
			)}
		</>
	)
}

export default React.memo(PageLevelTemplate)
