import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
	createStyles({
		container: {
			padding: '32px 32px',
			flex: 1,
		},
		boxItem: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
	}),
)
