import React, { ReactNode, useState } from 'react'
import * as Yup from 'yup'
import Container from 'components/templates/old/SingleFormTemplate/Container'
import { Formik, Form, FormikValues, FormikErrors } from 'formik'
import { RouteLeavingGuard, AlertDialog, BreadCrumbLinkProps } from '@/components/core'
import { useHistory } from 'react-router-dom'
import AnalyticsService from 'services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import ButtonInteraction from 'domains/analytics/ButtonInteraction'
import { TransactionType } from '@/domains/enums'
import { normalizeTransactionType } from '@/domains/enums/TransactionTypeEnum'
import FormCard from './FormCard'
import FormAction from './FormAction'
import RouteLeavingGuardOptions from './model/RouteLeavingGuardOptions'
import SubmitOptions from './model/SubmitOptions'

type SubmitDialogProps = {
	title: string
	message: string
	buttonCancelText?: string
	buttonConfirmText?: string
}

export type TrackErrorToSegmentFunction = (
	fieldName: string,
	error: string | { key: string; value: string }[],
) => { screenName: string; failureReason: string } | undefined

type Props = {
	title: string
	subtitle?: string
	breadCrumbLink?: BreadCrumbLinkProps
	initialValues: FormikValues
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	validationObject?: Yup.Lazy | Yup.ObjectSchema<any>
	routeLeavingOptions: RouteLeavingGuardOptions
	submitDialogProps?: SubmitDialogProps
	submitOptions: SubmitOptions
	trackErrorToSegment?: TrackErrorToSegmentFunction
	children: ReactNode
	transactionType?: TransactionType
}

const SingleFormTemplate: React.FC<Props> = ({
	title,
	subtitle,
	breadCrumbLink,
	initialValues,
	validationObject,
	routeLeavingOptions,
	submitDialogProps,
	submitOptions,
	trackErrorToSegment,
	children,
	transactionType = TransactionType.Refund,
}: Props) => {
	const history = useHistory()
	const userInfo = useGetUserInfo()
	const [showSubmitDialog, setShowSubmitDialog] = useState(false)

	const trackErrorEvent = (errors: FormikErrors<FormikValues>) => {
		if (!trackErrorToSegment) {
			return
		}
		Object.keys(errors).forEach((fieldName) => {
			const error = errors[fieldName]
			const trackingProps = error
				? trackErrorToSegment(fieldName, error as string | { key: string; value: string }[])
				: undefined
			if (trackingProps) {
				AnalyticsService.events.error({
					form_name: null,
					screen_name: trackingProps.screenName,
					failure_reason: trackingProps.failureReason,
					...userInfo,
				})
			}
		})
	}

	// Formik pass any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSubmitClick = (validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>) => {
		submitOptions.onSubmitButtonClick()
		validateForm().then((errors) => {
			if (!errors || Object.keys(errors).length === 0) {
				setShowSubmitDialog(true)
			}
			trackErrorEvent(errors)
		})
	}

	return (
		<Container title={title} breadCrumbLink={breadCrumbLink}>
			<Formik
				onSubmit={submitOptions.onSubmitForm}
				validateOnBlur={false}
				initialValues={initialValues}
				validationSchema={validationObject}
				validateOnChange
			>
				{({ isValid, dirty, submitCount, submitForm, validateForm }) => (
					<Form>
						{submitDialogProps && (
							<AlertDialog
								show={showSubmitDialog}
								title={submitDialogProps.title}
								handleCancel={() => {
									setShowSubmitDialog(false)
									AnalyticsService.events.buttonClicked({
										button_name: 'Cancel',
										button_label: ButtonInteraction.Cancel,
										screen_name: `Points Management - Confirmation Modal - ${normalizeTransactionType[transactionType]}`,
										...userInfo,
									})
								}}
								handleConfirm={() => {
									submitForm().then(() => setShowSubmitDialog(false))
								}}
								buttonCancelText={submitDialogProps.buttonCancelText}
								buttonConfirmText={submitDialogProps.buttonConfirmText}
							>
								{submitDialogProps.message}
							</AlertDialog>
						)}
						<RouteLeavingGuard
							when={dirty && submitCount === 0}
							navigate={(path) => {
								history.push(`${path}`)
							}}
							whiteList={routeLeavingOptions.whiteList}
							alertTitle={routeLeavingOptions.alertTitle}
							alertMessage={routeLeavingOptions.alertMessage}
						/>
						<FormCard subtitle={subtitle}>{children}</FormCard>
						{submitOptions.submitButtonVisible && (
							<FormAction
								submitButtonText={submitOptions.submitButtonText}
								handleSubmitClick={() => handleSubmitClick(validateForm)}
								submitEnabled={isValid && dirty && submitOptions.submitButtonEnabled}
							/>
						)}
					</Form>
				)}
			</Formik>
		</Container>
	)
}

export default React.memo(SingleFormTemplate)
