import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const GAP_SIZE = '16px'

export const RedeemableItemCellStyle = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			padding: '16px 0',
			gap: GAP_SIZE,
		},
		mainVariantContainer: {
			backgroundColor: theme.palette.background.default,
		},
		productWrapper: {
			display: 'flex',
			alignItems: 'center',
			padding: '0 16px',
			gap: GAP_SIZE,
			flex: 1,
		},
		inputWrapper: {
			margin: '0 16px',

			'& > div': {
				width: '100%',
				display: 'flex',
				flexDirection: 'column',

				'& div:has(svg)': {
					order: 1,
				},

				'& div:has(input)': {
					'& div': {
						color: 'var(--colors-interfaceLabelPrimary)',
					},
				},
			},
		},
	}),
)
