import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	DialogContentText,
} from '@material-ui/core'
import styles from './styles'

type Props = {
	id?: string
	title: string
	show?: boolean
	buttonCancelText?: string
	buttonConfirmText?: string
	handleCancel?: (buttonClicked: boolean) => void
	handleConfirm?: (e: React.MouseEvent) => void
	dataTestIdBase?: string
	children?: ReactNode
}
const AlertDialog: React.FC<Props> = ({
	id,
	title,
	show,
	dataTestIdBase,
	buttonCancelText,
	buttonConfirmText,
	handleCancel,
	handleConfirm,
	children,
}: Props) => {
	const { t } = useTranslation()
	const classes = styles()

	return (
		<Dialog
			id={id}
			open={!!show}
			onClose={() => {
				/* istanbul ignore else */
				if (handleCancel) {
					handleCancel(false)
				}
			}}
			data-testid={dataTestIdBase ? `${dataTestIdBase}-presentation` : undefined}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth
			maxWidth="sm"
		>
			<div className={classes.dialogBox}>
				<DialogTitle>
					<Typography id="alert-dialog-title" className={classes.title}>
						{title}
					</Typography>
				</DialogTitle>
				<DialogContent className={classes.content}>
					<DialogContentText className={children ? classes.contentText : classes.noContentPadding}>
						{children}
					</DialogContentText>
				</DialogContent>
				<DialogActions className={classes.dialogAction}>
					<Button
						id="cancel-btn"
						data-testid={dataTestIdBase ? `${dataTestIdBase}-cancel-button` : undefined}
						onClick={() => {
							/* istanbul ignore else */
							if (handleCancel) {
								handleCancel(true)
							}
						}}
						variant="outlined"
						color="primary"
					>
						{buttonCancelText ?? t('BUTTON.CANCEL')}
					</Button>
					<Button
						id="confirm-btn"
						data-testid={dataTestIdBase ? `${dataTestIdBase}-confirm-button` : undefined}
						onClick={handleConfirm}
						variant="contained"
						color="primary"
						autoFocus
					>
						{buttonConfirmText ?? t('BUTTON.CONFIRM')}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	)
}

AlertDialog.defaultProps = {
	show: false,
}

export default AlertDialog
