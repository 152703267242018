import { SettingsLevel } from '@/domains/enums/settings'
import { ChangeConfigParams, updateConfiguration } from '@/services/settings/SettingsService'
import { DEFAULT_TIER_FOR_PPP } from '@/utils/constants'
import { AxiosResponse } from 'axios'

export type UpdatePricePerPointParams = {
	pricePerPoint: number
	vendorId: string
}

export async function execute({ pricePerPoint, vendorId }: UpdatePricePerPointParams): Promise<AxiosResponse> {
	const body = {
		pricePerPoint,
	}

	const params: ChangeConfigParams = {
		settingsId: vendorId,
		level: SettingsLevel.VENDOR,
		tiers: [DEFAULT_TIER_FOR_PPP],
	}
	return updateConfiguration(params, body)
}
