import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const skuFieldsStyle = makeStyles((theme: Theme) =>
	createStyles({
		skuBoxContainer: {
			alignItems: 'flex-end',
			marginTop: '6px',
		},
		skuBoxItem: {
			display: 'flex',

			'& > div:first-child': {
				flex: 1,
				display: 'flex',
				flexFlow: 'column nowrap',
				justifyContent: 'space-between',
				paddingTop: '10px',
			},
		},

		removeContainer: {
			display: 'flex',
			alignItems: 'flex-end',
		},
		buttonContainer: {
			height: '60px',
			display: 'flex',
			alignItems: 'center',
			paddingTop: '10px',
		},
		removeButton: {
			cursor: 'pointer',
			paddingLeft: '28px',
			textDecoration: 'underline',
			fontWeight: 500,
			color: 'black',
			'&:hover': {
				filter: 'brightness(60%)',
			},
		},
		inactiveButton: {
			cursor: 'default',
			paddingLeft: '28px',
			textDecoration: 'underline',
			color: theme.palette.text.disabled,
			fontWeight: 500,
		},
	}),
)

export default skuFieldsStyle
