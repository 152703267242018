import { RouteInstance, RouteListItem } from '@/domains/route/RouteInstance'
import { match, matchPath } from 'react-router-dom'

export const sortRoutes = (list: Array<RouteInstance>): Array<RouteInstance> =>
	[...list].sort((routeA, routeB) => routeA.sortIndex - routeB.sortIndex)

export const matchRoutePath = <TParams = Record<string, string>>(
	pathname: string,
	route: RouteListItem,
): match<TParams> | null =>
	matchPath(pathname, {
		path: route.path,
		exact: route.exact ?? true,
	})
