import { Tier } from '@/domains/tier'
import { VendorByZoneAttributes, VendorOption } from '@/domains/vendors'
import { GlobalEvents, GlobalStore, PricePerPointConfigurationStoreEvents } from '@/stores'
import { LoadPricePerPointConfigurationUseCase, LoadSettingsUseCase, LoadVendorsUseCase } from '@/usecase'
import { AcceptedCountry } from '@/utils/acceptedCountries'
import { hasElements } from '@/utils/array'
import { MULTI_VENDOR_ATTRIBUTES_STORAGE_KEY } from '@/utils/constants'
import { createIdMapFromArray } from '@/utils/mappingUtil'
import { getStorageItemParsed } from '@/utils/storageUtil'
import { useUserMetadata } from 'admin-portal-shared-services'
import { useStore } from 'effector-react'
import { useCallback, useEffect, useRef } from 'react'

export type LoadSelectedVendorResult = {
	defineSelectedVendorAndSettings: () => Promise<void>
}

const getPrevVendorId = (zone: string | null): string | null => {
	const attributes = getStorageItemParsed<VendorByZoneAttributes>(MULTI_VENDOR_ATTRIBUTES_STORAGE_KEY)
	if (zone && attributes) {
		return attributes[zone]?.vendorId || null
	}
	return null
}

const getSelectedVendor = (vendorOptions: Array<VendorOption>, zone: string | null): VendorOption => {
	const prevVendorId = getPrevVendorId(zone)
	if (prevVendorId) {
		const selectedOption = vendorOptions.find((opt) => opt.vendorId === prevVendorId)
		if (selectedOption) {
			return selectedOption
		}
	}
	return vendorOptions[0]
}

export const useLoadSelectedVendor = (): LoadSelectedVendorResult => {
	const { selectedZone, selectedVendorId } = useStore(GlobalStore)
	const prevSelectedZone = useRef(selectedZone)
	const { data } = useUserMetadata()

	const hasAnyParamChanged = useCallback(() => {
		const hasZoneChanged = selectedZone !== prevSelectedZone.current
		if (hasZoneChanged || !selectedVendorId) {
			prevSelectedZone.current = selectedZone
			return true
		}
		return false
	}, [selectedVendorId, selectedZone])

	const setVendorAndSettings = useCallback(
		(vendorOptions: Array<VendorOption>, tiers: Tier[], selectedTierKey: string) => {
			if (hasElements(vendorOptions) && hasElements(tiers)) {
				const selectedVendor = getSelectedVendor(vendorOptions, prevSelectedZone.current)
				GlobalEvents.setVendorAndSettings({
					selectedVendorId: selectedVendor.vendorId,
					selectedProgram: selectedVendor.programs[0],
					selectedTierKey,
					vendorOptionsMap: createIdMapFromArray('vendorId', vendorOptions),
					tiersMap: createIdMapFromArray('tier', tiers),
				})
			} else {
				throw new Error('No vendor selected')
			}
		},
		[],
	)

	const defineSelectedVendorAndSettings = useCallback(async (): Promise<void> => {
		if (!hasAnyParamChanged()) {
			const pppSettingsResult = await LoadPricePerPointConfigurationUseCase.execute()
			PricePerPointConfigurationStoreEvents.setSettings(pppSettingsResult)
			return
		}

		try {
			const [vendorsResult, tiersResult] = await Promise.all([
				LoadVendorsUseCase.execute(selectedZone as AcceptedCountry, data?.vendors).catch(() => []),
				LoadSettingsUseCase.execute(),
			])

			const selectedTierKey = tiersResult[0].tier
			setVendorAndSettings(vendorsResult, tiersResult, selectedTierKey)
		} catch (error) {
			GlobalEvents.clearVendorData()
			throw error
		}
	}, [hasAnyParamChanged, selectedZone, setVendorAndSettings, data?.vendors])

	useEffect(() => {
		return () => {
			prevSelectedZone.current = selectedZone
		}
	}, [selectedZone])

	return {
		defineSelectedVendorAndSettings,
	}
}
