import { TransparencyStatus } from '@/domains/enums'
import theme from '@/theme'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

const transparencyGetStatusCellStyle = (cellData: string | number | Date, columnDataKey: string): CSSProperties => {
	if (columnDataKey === 'status' && cellData.toString().indexOf(TransparencyStatus.Failed) >= 0) {
		return { color: theme.palette.error.dark }
	}
	return {}
}

export default transparencyGetStatusCellStyle
