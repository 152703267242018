import React from 'react'
import { Tooltip as MuiTooltip } from '@material-ui/core'
import styles from './styles'

type Props = {
	title: string
	disabled?: boolean
	maxWidth?: string
	displayWhenDisabled?: boolean
	children: JSX.Element
}

const Tooltip: React.FC<Props> = ({ title, disabled = false, maxWidth, displayWhenDisabled = false, children }) => {
	const classes = styles({ maxWidth })

	if (disabled) {
		return children
	}

	return (
		<MuiTooltip
			arrow
			title={title}
			classes={{
				arrow: classes.arrow,
				tooltip: classes.tooltip,
			}}
		>
			{displayWhenDisabled ? <span>{children}</span> : children}
		</MuiTooltip>
	)
}

export default Tooltip
