import React from 'react'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { Tooltip } from '@/components/core'
import styles from './styles'

type Props = {
	label: string
	htmlFor: string
	error?: boolean
	disabled?: boolean
	optionalLabel?: string
	bottomSpacing?: boolean
	infoIconTooltip?: boolean
	customMessageTooltip?: string
}

const InputLabel: React.FC<Props> = ({
	label,
	htmlFor,
	error,
	disabled,
	optionalLabel,
	bottomSpacing,
	infoIconTooltip,
	customMessageTooltip,
}: Props) => {
	const classes = styles()

	return (
		<div className={clsx({ [classes.label]: true, [classes.bottomSpace]: bottomSpacing })}>
			<label className={clsx({ error, disabled })} htmlFor={htmlFor}>
				<span>{label}</span>
				{infoIconTooltip && (
					<Tooltip title={customMessageTooltip ?? ''}>
						<InfoOutlinedIcon className={classes.infoIcon} />
					</Tooltip>
				)}
				{optionalLabel && <span className={classes.optionalLabel}> {optionalLabel}</span>}
			</label>
		</div>
	)
}

InputLabel.defaultProps = {
	error: false,
	disabled: false,
}

export default InputLabel
