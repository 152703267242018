import { createStyles, makeStyles } from '@material-ui/core/styles'

const RedeemableItemsSettingsPanelStyle = makeStyles(() =>
	createStyles({
		settingsPanelWrapper: {
			backgroundColor: 'transparent',
			boxShadow: 'none',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			minHeight: '56px',
		},
		settingsPanelItem: {
			display: 'flex',
			flexDirection: 'column',

			'& > *': {
				color: 'var(--colors-neutral80)',
			},
		},
		settingsPanelItem_info: {
			marginRight: 'var(--space-2)',

			'& > *': {
				display: 'flex',
				color: 'var(--colors-neutral80)',
			},
		},
		settingsPanelItem_title: {
			fontSize: 'var(--fontSizes-2)',
			fontWeight: 500,
			marginRight: 'var(--space-2)',
		},
		settingsPanelItem_value: {
			fontWeight: 500,
			fontSize: 'var(--fontSizes-4)',
			marginRight: 'var(--space-1)',
		},
	}),
)

export default RedeemableItemsSettingsPanelStyle
