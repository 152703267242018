import { ComboFormDataMultiple } from '@/stores'
import EditRedeemableFormInputs from '@/routes/combos/models/EditRedeemableFormInputs'
import { RedeemableType } from '@/domains/enums'

const ComboBulkUpdateTransaction = ({ redeemables }: EditRedeemableFormInputs): ComboFormDataMultiple => {
	return {
		formData: {
			redeemables: redeemables.map(({ points, redeemableId, vendorComboId }) => ({
				points: points.toString(),
				type: RedeemableType.Combo,
				redeemableId,
				vendorComboId,
			})),
		},
	}
}

export { ComboBulkUpdateTransaction }
export default ComboBulkUpdateTransaction
