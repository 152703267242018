import { isIdentifyDimensionEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import RewardsOfferMultiple from 'domains/transaction/RewardsOfferMultiple'
import * as TransactionService from 'services/transaction/TransactionService'
import { addPoints } from 'services/transaction/TransactionService'
import requestTraceId from 'utils/requestTraceId'

async function newFileName(file: File, id: string): Promise<{ file: File; fileName: string }> {
	const fileName = `${id}-${file.name.replace(/\.csv$/i, '.txt')}`
	const newFile = new File([file], fileName, {
		type: 'text/plain',
		lastModified: file.lastModified,
	})
	return { file: newFile, fileName }
}

async function completeRewardsOffer(
	rewardsOfferMultiple: RewardsOfferMultiple,
	fileName: string,
): Promise<RewardsOfferMultiple> {
	const newRewardsOfferMultiple: RewardsOfferMultiple = {
		description: rewardsOfferMultiple.description,
		accounts: rewardsOfferMultiple.accounts,
		quantity: rewardsOfferMultiple.accounts.length,
		vendorId: getDefaultVendorIdOfCurrentZone(),
		category: rewardsOfferMultiple.category,
		referenceMonth: rewardsOfferMultiple.referenceMonth,
		referenceYear: rewardsOfferMultiple.referenceYear,
		singlePOC: rewardsOfferMultiple.singlePOC,
		productCategory: rewardsOfferMultiple.productCategory,
		fileName,
	}

	return newRewardsOfferMultiple
}

async function execute(multiplePocsFile: File, rewardsOfferMultipleTransaction: RewardsOfferMultiple): Promise<string> {
	const id = requestTraceId()
	const { fileName } = await newFileName(multiplePocsFile, id)
	const rewardsOfferMultipleVendorId = isIdentifyDimensionEnabled()
		? await addPoints(rewardsOfferMultipleTransaction, fileName)
		: await completeRewardsOffer(rewardsOfferMultipleTransaction, fileName)

	if (!isIdentifyDimensionEnabled()) {
		return TransactionService.rewardsOffersMultiple(id, rewardsOfferMultipleVendorId)
	}
	return ''
}

export { execute }
