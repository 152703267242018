import { GlobalStore } from '@/stores'
import { useStore } from 'effector-react'
import { Tier } from '@/domains/tier'

export interface CurrentTierResult {
	selectedTierKey: string | null
	currentTier: Tier | null
}

export const useCurrentTier = (): CurrentTierResult => {
	const { tiersMap, selectedTierKey } = useStore(GlobalStore)
	const currentTier = selectedTierKey ? tiersMap[selectedTierKey] : null

	return {
		selectedTierKey,
		currentTier,
	}
}
