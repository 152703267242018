import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		box: {
			paddingTop: (props: { disableGutters?: boolean; disableTopGutter?: boolean } = {}): number =>
				props.disableGutters || props.disableTopGutter ? 0 : theme.spacing(1),
			paddingBottom: (props: { disableGutters?: boolean; disableTopGutter?: boolean } = {}): number =>
				props.disableGutters ? 0 : theme.spacing(1),
		},
	}),
)

export default styles
