import ProgramRules from 'domains/program/ProgramRules'
import Rule from 'domains/rule/Rule'
import i18next from 'i18next'
import * as currencyHelper from '@/utils/currencyHelper'
import { sortBy } from '@/utils/sort'
import { ProgramRuleItem } from '@/domains'
import { hasElements } from '@/utils/array'

const mapItemsToSku = (items?: Array<ProgramRuleItem>): Array<string> => {
	if (!hasElements(items)) {
		return []
	}
	return items!.map(({ vendorItemId }) => vendorItemId)
}

export const convert = (rule: ProgramRules, shouldMapItemsToSkus: boolean): Rule => ({
	ruleId: rule.ruleId,
	name: i18next.t('rules:EARNING_NAME', {
		point: rule.points,
		amountSpent: currencyHelper.format(rule.amountSpent),
		count: rule.points,
	}),
	skus: shouldMapItemsToSkus ? mapItemsToSku(rule.items) : rule.skus,
	amountSpent: rule.amountSpent,
	currencyAmount: i18next.t('rules:EARNING_RULES_TABLE.AMOUNT', {
		amountSpent: currencyHelper.format(rule.amountSpent),
	}),
	points: rule.points,
	lastModified: rule.lastModified,
	items: rule.items,
	categoryId: rule.categoryId,
	description: rule.description,
})

export const convertAndSortRules = (rules: Array<ProgramRules>, shouldMapItemsToSkus: boolean): Array<Rule> => {
	return sortBy(
		rules.map((rule) => convert(rule, shouldMapItemsToSkus)),
		[{ key: 'points' }],
	)
}
