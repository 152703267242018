import React from 'react'
import { Button, Paragraph } from '@hexa-ui/components'
import { AcceptableTypes } from '@/components/formik/FileUpload'
import { X } from '@hexa-ui/icons'
import { Content, ContentLeft, ContentRight } from './styles'

interface HexaUploadButtonProps {
	uploadText: string
	name: string
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleRemove: () => void
	handleClick: () => void
	acceptTypes: AcceptableTypes
	files?: File | null
	disabled?: boolean
	hiddenFileInput: React.Ref<HTMLInputElement> | null
}

const HexaUploadButton: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, HexaUploadButtonProps> = ({
	uploadText,
	name,
	handleChange,
	handleRemove,
	handleClick,
	acceptTypes,
	files,
	disabled,
	hiddenFileInput,
}) => {
	return (
		<Content>
			<ContentLeft>
				<Button
					data-testid={`content-button-left-${name}`}
					onClick={handleClick}
					type="button"
					variant="secondary"
					style={{ width: '100px' }}
				>
					{uploadText}
				</Button>
				<input
					data-testid={`content-input-left-${name}`}
					type="file"
					ref={hiddenFileInput}
					onChange={handleChange}
					disabled={disabled}
					style={{ display: 'none' }}
					accept={acceptTypes}
				/>
			</ContentLeft>
			{files && (
				<ContentRight data-testid={`content-right-${name}`}>
					<Paragraph colortype="primary" weight="semibold" size="small" style={{ width: 'auto' }}>
						{files?.name}
					</Paragraph>
					<X size="medium" onClick={handleRemove} style={{ marginLeft: '10px' }} aria-label="REMOVE" />
				</ContentRight>
			)}
		</Content>
	)
}

export default HexaUploadButton
