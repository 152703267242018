import React from 'react'

import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { Dot } from '@hexa-ui/icons'
import { Grid } from '@hexa-ui/components'
import { useRedeemableItemStatus } from '../../../../hooks/useRedeemableItemStatus'

import styles from './styles'

export const RedeemableStatus = (item: RedeemableItem) => {
	const { buildStatusProps } = useRedeemableItemStatus()
	const { text, type } = buildStatusProps(item)
	const css = styles()

	return (
		<Grid.Item className={`${css.container} ${type}`}>
			<span data-testid="badge-status-indicator">
				<Dot size="tiny" className={`${css.indicator} ${type}`} fr={undefined} />
			</span>

			<span className={`${css.label} ${type}`} data-testid="badge-status-label">
				{text}
			</span>
		</Grid.Item>
	)
}
