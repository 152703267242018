import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: theme.spacing(1),
			height: (props: { multiline: boolean }): string => (props.multiline ? 'auto' : '36px'),
			display: 'flex',
			alignItems: 'center',
			marginBottom: 'var(--space-2)',
		},
		icon: {
			height: '16px',
			width: '16px',
			lineHeight: '20px',
			margin: '0 var(--space-2) 0 0',
		},
	}),
)

export default styles
