import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { TextButton, Tooltip } from '@hexa-ui/components'
import { Edit2, RotateCw, Trash2 } from '@hexa-ui/icons'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import RedeemableItemsListStyle from '../../../../RedeemableItemsListStyle'
import { useRedeemableItemActions } from '../../../../hooks/useRedeemableItemActions'

export const RedeemableItemActions = ({ item }: { item: RedeemableItem }) => {
	const { t } = useTranslation()
	const css = RedeemableItemsListStyle()
	const { buildRedeemableAction, shouldDisableActions: shouldDisabledPPP } = useRedeemableItemActions()

	const actions = buildRedeemableAction(item)
	const disabled = shouldDisabledPPP(item)

	return (
		<div className={css.actions} data-testid="redeemable-item-actions">
			{actions.onReload ? (
				<ReloadItemButton action={actions.onReload} disabled={disabled} tooltipText={t('BUTTON.RELOAD')} />
			) : (
				<EditItemButton action={actions.onEdit} disabled={disabled} tooltipText={t('BUTTON.EDIT')} />
			)}
			<DeleteItemButton action={actions.onDelete} disabled={disabled} tooltipText={t('BUTTON.DELETE')} />
		</div>
	)
}

type ButtonProps = {
	action: () => void
	tooltipText: string
	disabled: boolean
}

const DeleteItemButton = ({ action, disabled, tooltipText }: ButtonProps) => {
	const css = RedeemableItemsListStyle()

	const icon = useCallback(() => <Trash2 size="medium" />, [])

	return (
		<Tooltip placement="bottom" text={tooltipText}>
			<TextButton
				data-testid="delete-redeemable-item"
				disabled={disabled}
				className={css.removeButton}
				iconPosition="leading"
				size="medium"
				icon={icon}
				onClick={action}
			/>
		</Tooltip>
	)
}

const EditItemButton = ({ action, disabled, tooltipText }: ButtonProps) => {
	const css = RedeemableItemsListStyle()

	const icon = useCallback(() => <Edit2 size="medium" />, [])

	return (
		<Tooltip placement="bottom" text={tooltipText}>
			<TextButton
				data-testid="edit-redeemable-item"
				disabled={disabled}
				className={css.removeButton}
				iconPosition="leading"
				size="medium"
				icon={icon}
				onClick={action}
			/>
		</Tooltip>
	)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReloadItemButton = ({ action, tooltipText, disabled }: ButtonProps) => {
	const css = RedeemableItemsListStyle()

	const icon = useCallback(() => <RotateCw size="medium" />, [])

	return (
		<Tooltip placement="bottom" text={tooltipText}>
			<TextButton
				data-testid="reload-redeemable-item"
				className={css.reloadButton}
				iconPosition="leading"
				size="medium"
				icon={icon}
				onClick={action}
			/>
		</Tooltip>
	)
}
