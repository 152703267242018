import {
	ILoadRedeemableItemsServiceParams,
	load,
	LoadRedeemableItemsResponse,
} from '@/services/redeemableItems/RedeemableItemsService'
import { redeemableItemsInitialState } from '@/stores'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'

export interface ILoadRedeemableItemsUseCaseParams extends Omit<ILoadRedeemableItemsServiceParams, 'vendorId'> {
	vendorItemId?: string
}

export const execute = async (params?: ILoadRedeemableItemsUseCaseParams): Promise<LoadRedeemableItemsResponse> => {
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const isSearching = !!params?.vendorItemId?.length

	const { page: defaultPage, pageSize: defaultPageSize } = redeemableItemsInitialState.pagination

	const result = await load({
		...params,
		page: isSearching ? 0 : params?.page ?? defaultPage,
		pageSize: params?.pageSize ?? defaultPageSize,
		vendorId,
	})
	return result
}
