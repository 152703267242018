import React from 'react'
import TablePageTemplate from 'components/templates/TablePageTemplate'
import { useTranslation } from 'react-i18next'
import { BASE_ROUTE } from 'utils/constants'
import DataTable from 'components/tables/DataTable'
import { formatDate } from 'utils/date'
import TransparencyHistory from 'domains/transparency/TransparencyHistory'
import getStatusCellStyle from '@/utils/transparencyGetStatusCellStyle'
import FailedPocsDialog from '../FailedPocsDialog/FailedPocsDialog'

type Props = {
	transparencyLogs: TransparencyHistory[]
	isLoading: boolean
	showError: boolean
	onReviewId: (poc: TransparencyHistory) => void
	reviewIdWillRender: (poc: TransparencyHistory) => boolean
	handleAddNewTransaction: () => void
}

const TransactionLogView: React.FC<Props> = ({
	transparencyLogs,
	isLoading,
	showError,
	onReviewId,
	reviewIdWillRender,
	handleAddNewTransaction,
}) => {
	const { t } = useTranslation()
	const transactionPath = `${BASE_ROUTE}/transaction`

	return (
		<TablePageTemplate
			buttonProps={{
				id: 'new-transaction-btn',
				text: t('transaction:LOG.NEW_TRANSACTION_BTN'),
				to: transactionPath,
				onClick: handleAddNewTransaction,
			}}
			title={t('transaction:LOG.TITLE_VIEW')}
		>
			<FailedPocsDialog />
			<DataTable
				id="transaction-log"
				columns={[
					{
						label: t('transaction:TRANSACTION_TYPE'),
						dataKey: 'type',
						align: 'left',
						width: '30%',
						format: (): string => t('transaction:TYPE.REWARDS_OFFER_MULTIPLE'),
					},
					{
						label: t('transaction:LOG.EXECUTION_DATE'),
						dataKey: 'executionDate',
						align: 'left',
						width: '26%',
						format: (date): string => (date ? formatDate(date as Date, t('DATE_FORMAT_TABLE')) : ''),
					},
					{
						label: t('transaction:LOG.STATUS'),
						dataKey: 'status',
						align: 'left',
						width: '26%',
					},
				]}
				data={transparencyLogs}
				cellStyle={getStatusCellStyle}
				initialSort={[{ dataKey: 'executionDate', order: 'desc' }]}
				tableActions={[
					{
						type: 'edit',
						label: t('REVIEW_ID'),
						fn: onReviewId,
						willRender: reviewIdWillRender,
					},
				]}
				configs={{
					actionWidth: '18%',
					actionAlign: 'right',
					hideActionsLabel: true,
					renderRecordId: 'id',
					style: {
						emptyMessage: {
							showIcon: true,
						},
					},
				}}
				loading={isLoading}
				showError={showError}
				emptyMessage={t('transaction:LOG.EMPTY_DATA_TABLE')}
				data-testid="transaction-table"
			/>
		</TablePageTemplate>
	)
}

export default TransactionLogView
