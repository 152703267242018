import { styled } from '@hexa-ui/theme'

export const Container = styled('div', {
	display: 'flex',
	flex: 1,
	flexDirection: 'column',
})

export const Content = styled('div', { display: 'flex', marginTop: '20px' })
export const ContentLeft = styled('div', {})
export const ContentRight = styled('div', {
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	marginLeft: '15px',
	minWidth: '100px',
	maxWidth: '300px',
})
