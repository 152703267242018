import React from 'react'

export function SKUWithoutImage() {
	return (
		<svg
			data-testid="rewards-offer-empty-image"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="25.9 33.4 55.6 55.6"
		>
			<path
				d="M76.7999 89H30.4999C27.9999 89 25.8999 86.9 25.8999 84.4V38C25.8999 35.5 27.9999 33.4 30.4999 33.4H76.7999C79.2999 33.4 81.3999 35.5 81.3999 38V84.3C81.3999 86.9 79.3999 89 76.7999 89Z"
				fill="#7C74B1"
			/>
			<path
				d="M53.7 33.4H76.9C79.4 33.4 81.5 35.5 81.5 38V84.3C81.5 86.8 79.4 88.9 76.9 88.9H53.7V33.4Z"
				fill="#5F538F"
			/>
			<path
				d="M52.8999 74.5C53.0999 74.5 53.2999 74.7 53.2999 74.9V76.3C53.2999 76.5 53.0999 76.7 52.8999 76.7H46.9999V74.9C46.9999 74.7 47.1999 74.5 47.3999 74.5H52.8999ZM52.8999 81.2H46.9999V83C46.9999 83.2 47.1999 83.4 47.3999 83.4H52.8999C53.0999 83.4 53.2999 83.2 53.2999 83V81.6C53.2999 81.4 53.0999 81.2 52.8999 81.2ZM52.1999 79.7V78.3C52.1999 78.1 51.9999 77.9 51.7999 77.9H46.9999V80.1H51.7999C51.9999 80.1 52.1999 79.9 52.1999 79.7ZM60.2999 74.5H54.7999C54.5999 74.5 54.3999 74.7 54.3999 74.9V76.7H60.2999C60.4999 76.7 60.6999 76.5 60.6999 76.3V74.9C60.6999 74.7 60.4999 74.5 60.2999 74.5ZM60.2999 81.2H54.3999V83C54.3999 83.2 54.5999 83.4 54.7999 83.4H60.2999C60.4999 83.4 60.6999 83.2 60.6999 83V81.6C60.6999 81.4 60.4999 81.2 60.2999 81.2ZM59.5999 79.7V78.3C59.5999 78.1 59.3999 77.9 59.1999 77.9H54.3999V80.1H59.1999C59.3999 80.1 59.5999 79.9 59.5999 79.7ZM38.9999 83C38.9999 83.2 39.1999 83.4 39.3999 83.4H43.0999C44.3999 83.4 45.5999 82.4 45.7999 81.2H38.8999V83H38.9999ZM61.7999 81.6V83C61.7999 83.2 61.9999 83.4 62.1999 83.4H65.5999C66.8999 83.4 68.0999 82.4 68.2999 81.2H62.1999C61.9999 81.2 61.7999 81.4 61.7999 81.6ZM67.8999 74.5H64.3999C63.0999 74.5 61.8999 75.5 61.6999 76.7H67.9999C68.1999 76.7 68.3999 76.5 68.3999 76.3V74.9C68.2999 74.7 68.0999 74.5 67.8999 74.5ZM43.0999 77.9H38.9999V80.1H45.8999C45.5999 78.8 44.4999 77.9 43.0999 77.9ZM65.5999 77.9H61.5999C61.8999 79.2 62.9999 80.1 64.2999 80.1H68.2999C68.0999 78.8 66.8999 77.9 65.5999 77.9ZM42.5999 74.5H39.4999C39.2999 74.5 39.0999 74.7 39.0999 74.9V76.7H44.7999C44.7999 75.5 43.7999 74.5 42.5999 74.5Z"
				fill="white"
			/>
			<path
				d="M58.7999 55.7L54.2 52.7C53.8 52.5 53.3 52.5 53 52.7L48.4 55.7C47.5 56.3 46.2 55.6 46.2 54.5V33.4H60.9V54.5C61 55.7 59.7999 56.4 58.7999 55.7Z"
				fill="#F0E44C"
			/>
		</svg>
	)
}
