import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'nowrap',
		maxWidth: '568px',
	},
	imageBox: {
		display: 'flex',
		flexFlow: 'column',
	},
	iconBox: {
		backgroundColor: theme.palette.common.white,
		textAlign: 'center',
	},
	icon: {
		fontSize: '26px',
		marginTop: '0.125em',
	},
}))

export default styles
