import * as RewardsService from '@/services/rewards/RewardsService'
import { RewardsProjection } from '@/domains/enums'
import { RewardsProgram } from '@/domains'

type ChallengeAcceptedPocsResponse = Pick<RewardsProgram, 'accountId'>

async function execute(challengeId: string): Promise<Array<ChallengeAcceptedPocsResponse>> {
	return RewardsService.loadAllByChallenge([challengeId], RewardsProjection.DEFAULT, 0, 9999999)
		.then(({ content }) => {
			if (content.length > 0) {
				return content.map(({ accountId }) => ({ accountId }))
			}
			return []
		})
		.catch(() => {
			return []
		})
}

export { execute }
