import { InputSelect } from '@/components/form'
import TypeList from '@/domains/TypeList'
import { ChallengeEvents as ChallengeCreationEvents } from '@/stores/challengeCreation'
import { Box, Grid, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import React, { ChangeEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { hideNotification as hideAllNotification } from 'stores/pageLevel/PageLevelEvents'
import { useDataTableContext } from '../context/DataTableContext'
import useStyles from './styles'

type Props = {
	tableId: string
	itemsPerPage: number
	itemsPerPageOptions?: number[]
	onChangeItemsPerPageOptions?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
	onPageChange?: (currentPage: number, regexFilter: string, pageNumber: number) => void
	hidePaginationCount?: boolean
	totalElements?: number
}

const TablePagination: React.FC<Props> = ({
	tableId,
	itemsPerPage,
	itemsPerPageOptions,
	onChangeItemsPerPageOptions,
	onPageChange,
	hidePaginationCount,
	totalElements,
}: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { data, currentPage, setCurrentPage, query } = useDataTableContext()

	const totalRows = totalElements ?? (data?.length || 0)
	const totalPages = useMemo(() => Math.ceil(totalRows / itemsPerPage), [totalRows, itemsPerPage])

	const showingInitialCount = useMemo(() => currentPage * itemsPerPage, [currentPage, itemsPerPage])
	const showingNextPageCount = useMemo(() => (currentPage - 1) * itemsPerPage + 1, [currentPage, itemsPerPage])
	const toCount = currentPage === totalPages ? totalRows : showingInitialCount
	const showingCountNumber = showingInitialCount < totalRows ? showingInitialCount : totalRows

	const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
		hideAllNotification()
		ChallengeCreationEvents.hidePageLevelNotification()
		setCurrentPage(value)
		window.scrollTo({ behavior: 'smooth', top: 0 })
	}

	const marginPagination = totalPages > 0 ? 2 : 4
	const itemsPerPageType: TypeList[] | undefined = itemsPerPageOptions
		? itemsPerPageOptions.map((option) => {
				return { value: option.toString(), label: option.toString() }
			})
		: undefined
	const smPaginationItems = itemsPerPageType ? undefined : 4
	const alignPaginationItems = itemsPerPageType ? 'flex-start' : 'center'
	const smPageItems = itemsPerPageType ? 7 : 12

	return (
		<Box mt={marginPagination}>
			<Grid id={`${tableId}-container-pagination`} container>
				<Grid item sm={smPageItems}>
					<Grid container alignItems="center">
						<Grid item sm={smPaginationItems}>
							<Box>
								{!hidePaginationCount && (
									<Typography component="span" variant="body2" color="inherit" id={`${tableId}-pagination-counter`}>
										{currentPage === 1
											? t('TABLE_PAGINATION_INITIAL_COUNT', {
													showingCount: showingCountNumber,
													totalRows,
												})
											: t('TABLE_PAGINATION_COUNT', {
													showingCount: showingNextPageCount,
													toCount,
													totalRows,
												})}
									</Typography>
								)}
							</Box>
						</Grid>
						{totalPages > 1 && (
							<Grid item sm={smPaginationItems}>
								<Grid container justify={alignPaginationItems} alignItems="center">
									<Pagination
										size="small"
										id={`${tableId}-pagination`}
										count={totalPages}
										page={currentPage}
										className={clsx({ [classes.previousButtonVisibility]: currentPage === 1 })}
										onChange={(event, page) => {
											handleChangePage(event, page)
											if (onPageChange) {
												onPageChange(page, query, currentPage)
											}
										}}
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
				{itemsPerPageType && (
					<Grid item alignItems="center" sm={5}>
						<Grid item justifyContent="flex-end">
							<Grid container justifyContent="flex-end" alignItems="center">
								<Typography component="span" variant="body2" color="inherit">
									{t('TABLE_PAGINATION_INPUT_SHOW')}
								</Typography>
								<InputSelect
									className={classes.InputSelect}
									id="input-select-table"
									options={itemsPerPageType}
									onChange={onChangeItemsPerPageOptions}
									defaultValue={itemsPerPage}
								/>
								<Typography component="span" variant="body2" color="inherit">
									{t('TABLE_PAGINATION_INPUT_PER_PAGE')}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Box>
	)
}

export default React.memo(TablePagination)
