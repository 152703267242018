import i18n, { Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'
import numeral from 'numeral'
import 'numeral/locales'
import ptBrLocale from 'dayjs/locale/pt-br'
import esMxLocale from 'dayjs/locale/es-mx'
import enLocale from 'dayjs/locale/en'
import frLocale from 'dayjs/locale/fr'
import nlLocale from 'dayjs/locale/nl'
import nlBeLocale from 'dayjs/locale/nl-be'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import UpdateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import { isLanguageValid } from '@/utils/language'
import { LanguageKey, SupportedLanguagesSettings } from '@/domains/language'
import enResource from './en'
import ptResource from './pt'
import esResource from './es'
import nlResource from './nl'
import frResource from './fr'

export const supportedLanguages: SupportedLanguagesSettings = {
	'pt-BR': {
		key: 'pt-BR',
		resourceLanguage: ptResource,
		dayjsLocale: ptBrLocale,
		numeralLocale: 'pt-BR',
	},
	'en-US': {
		key: 'en-US',
		resourceLanguage: enResource,
		dayjsLocale: enLocale,
		numeralLocale: 'en',
	},
	'en-ZA': {
		key: 'en-ZA',
		resourceLanguage: enResource,
		dayjsLocale: enLocale,
		numeralLocale: 'en',
	},
	'es-MX': {
		key: 'es-MX',
		resourceLanguage: esResource,
		dayjsLocale: esMxLocale,
		numeralLocale: 'es',
	},
	'es-419': {
		key: 'es-419',
		resourceLanguage: esResource,
		dayjsLocale: esMxLocale,
		numeralLocale: 'es',
	},
	'nl-BE': {
		key: 'nl-BE',
		resourceLanguage: nlResource,
		dayjsLocale: nlBeLocale,
		numeralLocale: 'nl'
	},
	'nl-NL': {
		key: 'nl-NL',
		resourceLanguage: nlResource,
		dayjsLocale: nlLocale,
		numeralLocale: 'nl'
	},
	'fr-BE': {
		key: 'fr-BE',
		resourceLanguage: frResource,
		dayjsLocale: frLocale,
		numeralLocale: 'fr'
	}
}

export const resources: Resource = Object.values(supportedLanguages).reduce(
	(prev, item) => ({
		...prev,
		[item.key]: {
			...item.resourceLanguage,
		},
	}),
	{},
)

export const defineLocaleData = (langKey: LanguageKey): void => {
	if (!isLanguageValid(langKey)) {
		return
	}
	const languageData = supportedLanguages[langKey]
	numeral.locale(languageData.numeralLocale)

	dayjs.extend(LocalizedFormat)
	dayjs.extend(UpdateLocale)
	dayjs.updateLocale(langKey, {
		formats: {
			...languageData.dayjsLocale.formats,
		},
	})
	dayjs.extend(utc)
}

i18n.use(initReactI18next).init({
	fallbackLng: 'en',
	lng: 'en',
	interpolation: {
		escapeValue: false,
		format(value, format) {
			if (format) {
				if (format === 'balanceNumber') return numeral(value).format('0,0')
				if (value instanceof Date) return value ? dayjs(value).format(format) : 'Invalid date'
				if (typeof value === 'number') return numeral(value).format(format)
			}

			return value
		},
	},
	debug: false,
	react: {
		useSuspense: false,
		wait: true,
	},
	resources,
	defaultNS: 'common',
})

export default i18n
