import { TransactionHistory as History, PocSearchParams, ScreenName } from '@/domains'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useTheme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import AccountValidationResult from 'domains/enums/account/AccountValidationResult'
import React, { useCallback, useState } from 'react'
import * as LoadTransactionHistoryUseCase from 'usecase/transaction/LoadTransactionHistoryUseCase'
import TransactionHistoryView from './TransactionHistoryView'

const TransactionHistory: React.FC = () => {
	const theme = useTheme()
	const [isSearching, setIsSearching] = useState(false)
	const [isSearchOK, setIsSearchOK] = useState(false)
	const [transactionHistoryData, setTransactionHistoryData] = useState<Array<History>>([])
	const userInfo = useGetUserInfo()

	const handleSearch = useCallback(
		async ({ pocId }: PocSearchParams): Promise<AccountValidationResult> => {
			setIsSearching(true)
			try {
				const { data, validationResult } = await LoadTransactionHistoryUseCase.execute(pocId)
				AnalyticsService.events.tableSearched({
					...userInfo,
					table_name: 'Transaction History',
					screen_name: ScreenName.TransactionHistory,
					content_type: 'Transaction History',
					search_query: pocId,
				})
				if (validationResult === AccountValidationResult.OK) {
					setTransactionHistoryData(data)

					setIsSearchOK(true)
				} else {
					setTransactionHistoryData([])
					setIsSearchOK(false)
				}
				return validationResult
			} catch {
				setIsSearchOK(false)
				return AccountValidationResult.INVALID
			} finally {
				setIsSearching(false)
			}
		},
		[userInfo],
	)

	const handleCellStyle = useCallback(
		(cellData: string | number | Date, columnDataKey: string): CSSProperties => {
			if (columnDataKey === 'amount') {
				if (cellData > 0) return { color: '#05853E' }
				return { color: theme.palette.error.dark }
			}
			return {}
		},
		[theme.palette.error.dark],
	)

	return (
		<TransactionHistoryView
			handleSearch={handleSearch}
			isSearching={isSearching}
			isSearchOK={isSearchOK}
			transactionHistoryData={transactionHistoryData}
			handleCellStyle={handleCellStyle}
		/>
	)
}

export default TransactionHistory
