import { RedeemableType } from '@/domains/enums'
import { RedeemableV2 } from '@/domains/redeemable/Redeemable'
import * as RedeemablesService from '@/services/redeemables/RedeemablesService'
import { AxiosResponse } from 'axios'

export interface IEditRedeemables {
	redeemableId: string
	points: number
	vendorComboId: string
}

const defaultParams = { type: RedeemableType.Combo }

const execute = (props: IEditRedeemables): Promise<AxiosResponse<RedeemableV2>> => {
	const updateParams = {
		...props,
	}

	return RedeemablesService.updateCombo({ ...updateParams, ...defaultParams })
}

export { execute }
