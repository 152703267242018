import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		container: {
			width: '100%',
		},
		item: {
			padding: 0,
			display: 'block',
		},
	}),
)

export default styles
