import { useState } from 'react'
import * as Papa from 'papaparse'

type useCsvParserResponse = {
	isLoading: boolean
	parse: (file: File) => void
}

const useCsvParser = (
	onCompleteParse: (results: Papa.ParseResult<unknown>) => void,
	config?: Papa.ParseConfig<unknown>,
): useCsvParserResponse => {
	const [isLoading, setIsLoading] = useState(false)

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleFinishParse = (results: Papa.ParseResult<unknown>) => {
		onCompleteParse(results)
		setIsLoading(false)
	}

	const parse = (file: File): void => {
		/* istanbul ignore else */
		if (file) {
			setIsLoading(true)
			setTimeout(() => {
				Papa.parse<File>(file, {
					header: true,
					skipEmptyLines: true,
					delimiter: ';',
					...(config || {}),
					complete: (results) => handleFinishParse(results),
				})
			}, 500)
		}
	}

	return {
		isLoading,
		parse,
	}
}

export default useCsvParser
