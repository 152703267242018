import * as SegmentationService from '@/services/segmentation/SegmentationService'
import Group from '@/domains/Group'
import { isToggleLoadGroupName } from '@/utils/featureToggle/featureToggleHelper'

async function execute(): Promise<Array<Group>> {
	const toggleLoadGroupName = isToggleLoadGroupName()
	if (toggleLoadGroupName) {
		return SegmentationService.loadAll().catch(() => {
			return []
		})
	}
	return []
}

export { execute }
