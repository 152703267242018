import React from 'react'
import { useTranslation } from 'react-i18next'
import InputTextWithDelayedValidation from '@/components/form/InputTextWithDelayedValidation'
import { ComboEvents } from '@/stores'

type Props = {
	index: string | number
	handleValidation: (value: string, name: string) => Promise<boolean>
}

const SingleComboId: React.FC<Props> = ({ index, handleValidation }: Props) => {
	const { t } = useTranslation()
	const name = `combos[${index}].comboId`

	return (
		<InputTextWithDelayedValidation
			label={t('combo:DT_COMBOS.ID')}
			name={name}
			id={`comboId${index}`}
			onValidating={async (eventValue) => {
				await handleValidation(eventValue, name)
			}}
			onLoading={ComboEvents.setIsValidatingComboId}
		/>
	)
}

export default SingleComboId
