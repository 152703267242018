import Combo from '@/domains/combo/Combo'
import { loadById, loadMultivendorComboById } from '@/services/combo/ComboService'
import { DEV_MULTI_VENDOR_COMBOS } from '@/utils/featureToggle'
import { isToggleEnabled } from '@/hooks'

export interface LoadCombosParams {
	comboIds?: Array<string>
	vendorComboIds?: Array<string>
}

export async function executeByVendorComboIds(vendorComboIds?: Array<string>): Promise<Array<Combo>> {
	if (isToggleEnabled(DEV_MULTI_VENDOR_COMBOS)) {
		return loadMultivendorComboById({
			vendorComboIds,
			deleted: false,
			disabled: false,
		})
	}
	return loadById(vendorComboIds)
}

export async function executeByComboIds(comboIds?: Array<string>): Promise<Array<Combo>> {
	if (isToggleEnabled(DEV_MULTI_VENDOR_COMBOS)) {
		return loadMultivendorComboById({
			comboIds,
			deleted: false,
			disabled: false,
		})
	}
	return loadById(comboIds)
}
