import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsTableViewed {
	itemsTotal: string
	itemsPerPage: string
	pageNumber: string
	tableName: string
	screenName: string
}

export const useAnalyticsServiceEventTableViewed = (): {
	sendAnalyticsServiceTableViewed: (recordData: AnalyticsTableViewed) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceTableViewed = useCallback(
		({ itemsTotal, itemsPerPage, pageNumber, tableName, screenName }: AnalyticsTableViewed) => {
			AnalyticsService.events.tableViewed({
				items_per_page: itemsPerPage,
				items_total: itemsTotal,
				page_number: pageNumber,
				table_name: tableName,
				screen_name: screenName,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceTableViewed }
}
