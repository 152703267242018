import i18n from '@/i18n'
import * as Yup from 'yup'
import { hasElements } from './array'
import { MAX_SIZE_FILE_1MB, SUPPORTED_FILE_EXTENSION_CSV, SUPPORTED_FILE_TYPE_IMAGE } from './constants'
import { unformat } from './currencyHelper'
import { onlyNumbers } from './string'

/**
 * Yup string validation (Empty field)
 * @description Rules: Required
 * @param t [Function]: i18n
 */
const fieldValidationRequired = (): Yup.StringSchema =>
	Yup.string().trim().required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))

/**
 * Yup number validation (Number field)
 * @description Rules: Required
 * @param t [Function]: i18n
 */
const fieldNumberValidationRequired = (): Yup.NumberSchema =>
	Yup.number().min(0, i18n.t('ERROR_MESSAGE.INVALID_NUMBER_VALUE')).required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))

/**
 * Yup decimal number validation (Number field)
 * @description Rules: Required and greather than 0
 * @param t [Function]: i18n
 */
const fieldDecimalValidationRequired = (): Yup.NumberSchema =>
	Yup.number()
		.transform((value) => (Number.isNaN(value) ? undefined : value))
		.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
		.positive(i18n.t('ERROR_MESSAGE.INVALID_NUMBER_VALUE'))

/**
 * Yup currency pattern validation (String field)
 * @description Rules: Required and greather than 0
 * @param t [Function]: i18n
 */
const fieldCurrencyValidationRequired = (): Yup.StringSchema =>
	Yup.string()
		.trim()
		.transform((value) => (!onlyNumbers(value).length ? undefined : value))
		.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
		.test('positive', i18n.t('ERROR_MESSAGE.INVALID_NUMBER_VALUE'), (value) => {
			return !value ? false : unformat(value) > 0
		})

/**
 * Yup currency pattern validation (String field)
 * @description Rules: Required and greather or equals to 0
 * @param t [Function]: i18n
 */
const fieldCurrencyValidationNotRequired = (): Yup.StringSchema =>
	Yup.string()
		.trim()
		.test('positive', i18n.t('ERROR_MESSAGE.INVALID_NUMBER_VALUE'), (value) => {
			const isEmpty = !onlyNumbers(value).length
			return isEmpty ? true : unformat(value!) > 0
		})

/**
 * Yup number validation (Number field)
 * @description Rules: Required and Min Value is 1
 * @param t [Function]: i18n
 */
const fieldNumberValidationRequiredMin1 = (): Yup.NumberSchema =>
	Yup.number()
		.transform((value) => (Number.isNaN(value) ? undefined : value))
		.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
		.min(1, i18n.t('ERROR_MESSAGE.MAX_GREATER_THAN_0'))
/**
 * Yup number validation (Number field)
 * @description Rules: Required and Min Value is 1 and the number is integer
 * @param t [Function]: i18n
 */
const fieldNumberValidationRequiredMin1AndInteger = (): Yup.NumberSchema =>
	Yup.number()
		.transform((value) => (Number.isNaN(value) ? undefined : value))
		.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
		.min(1, i18n.t('ERROR_MESSAGE.SKU_QUANTITY_INVALID'))
		.integer(i18n.t('ERROR_MESSAGE.INVALID_NUMBER_VALUE_TYPE'))
/**
 * Yup points number validation (Number field)
 * @description Rules: Required and Min Value is 1
 * @param t [Function]: i18n
 */
const fieldPointsValidation = (): Yup.NumberSchema =>
	Yup.number()
		.transform((value) => (Number.isNaN(value) ? undefined : value))
		.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
		.min(1, i18n.t('ERROR_MESSAGE.INVALID_POINTS_VALUE'))
		.integer(i18n.t('ERROR_MESSAGE.INVALID_NUMBER_VALUE_TYPE'))

const fieldInitialBalanceValidation = (): Yup.NumberSchema =>
	Yup.number()
		.transform((value) => (Number.isNaN(value) ? undefined : value))
		.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
/**
 * Yup mixed validation (Image)
 * @description Rules: Required, 1mb Size and Only Jpg, Jpeg and Png Files
 * @param t [Function]: i18n
 * @param required [boolean]: true if field is required
 */
const fileValidation1mbImage = (required = true): Yup.MixedSchema => {
	return (required ? Yup.mixed().required(i18n.t('ERROR_MESSAGE.EMPTY_FILE_FIELD')) : Yup.mixed())
		.test('fileSize', i18n.t('ERROR_MESSAGE.UNSUPPORTED_FILE'), (value) => {
			if (value) return value[0].size <= MAX_SIZE_FILE_1MB
			return !required
		})
		.test('fileType', i18n.t('ERROR_MESSAGE.UNSUPPORTED_FILE'), (value) => {
			if (value) return SUPPORTED_FILE_TYPE_IMAGE.includes(value[0].type)
			return !required
		})
}

/**
 * Yup mixed validation (Image)
 * @description Validade your image files using options
 * @param t [Function]: i18n
 * @param options [ImageFileValidationOptions]: { maxSizeFile, supportedFileType, required }
 */
type ImageFileValidationOptions = {
	maxSizeFile?: number
	supportedFileType?: string[]
	required?: boolean
}
const imageFileValidation = (options: ImageFileValidationOptions = {}): Yup.MixedSchema => {
	const { required, maxSizeFile, supportedFileType } = {
		maxSizeFile: MAX_SIZE_FILE_1MB,
		supportedFileType: SUPPORTED_FILE_TYPE_IMAGE,
		required: true,
		...options,
	}
	return (required ? Yup.mixed().required(i18n.t('ERROR_MESSAGE.EMPTY_FILE_FIELD')) : Yup.mixed())
		.test('fileSize', i18n.t('ERROR_MESSAGE.UNSUPPORTED_FILE'), (value) => {
			if (value?.file) return value.file.size <= maxSizeFile
			return !required
		})
		.test('fileType', i18n.t('ERROR_MESSAGE.UNSUPPORTED_FILE'), (value) => {
			if (value?.file) return supportedFileType.includes(value.file.type)
			return !required
		})
}

/**
 * Yup mixed validation (Csv File)
 * @description Rules: Required, Max File Size and Only CSV Files
 * @param errorMessage [FileValidationErrorMessage]: Pass required, fileSize and fileType error messages
 * @param maxSizeFile [number]: Default max size is 1MB
 */
export interface FileValidationErrorMessage {
	required: string
	fileSize: string
	fileType: string
}
const fileValidationCsv = (
	errorMessage: FileValidationErrorMessage,
	maxSizeFile = MAX_SIZE_FILE_1MB,
): Yup.MixedSchema =>
	Yup.mixed()
		.test('required', errorMessage.required, (value) => hasElements(value))
		.test('fileSize', errorMessage.fileSize, (value) => hasElements(value) && value[0].size <= maxSizeFile)
		.test(
			'fileType',
			errorMessage.fileType,
			(value) =>
				hasElements(value) && SUPPORTED_FILE_EXTENSION_CSV.includes(value[0].name.toLowerCase().split('.').pop()),
		)

/**
 * Yup start date validation
 * @description Rules: Required, Valid date and newer or equal than actual day
 * @param t [Function]: i18n
 */
const fieldStartDateValidation = (): Yup.DateSchema =>
	Yup.date()
		.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
		.min(new Date(new Date().setDate(new Date().getDate() - 1)), i18n.t('ERROR_MESSAGE.INVALID_START_DATE'))
		.typeError(i18n.t('ERROR_MESSAGE.INVALID_DATE'))

/**
 * Yup end date validation
 * @description Rules: Required, Valid date and greater than start date
 * @param t [Function]: i18n
 * @isActiveChallenge
 */
const fieldEndDateValidation = (isActiveChallenge: boolean): Yup.DateSchema =>
	Yup.date()
		.required(i18n.t('ERROR_MESSAGE.EMPTY_FIELD'))
		.when('startDate', (startDate: Date, schema: Yup.DateSchema<Date>): Yup.DateSchema<Date> => {
			if (!!startDate && !Number.isNaN(startDate.getTime())) {
				return isActiveChallenge
					? schema.min(new Date(new Date().setDate(new Date().getDate() - 1)), i18n.t('ERROR_MESSAGE.PAST_END_DATE'))
					: schema.min(startDate, i18n.t('ERROR_MESSAGE.INVALID_END_DATE'))
			}
			return schema
		})
		.typeError(i18n.t('ERROR_MESSAGE.INVALID_DATE'))

export {
	fieldCurrencyValidationRequired,
	fieldCurrencyValidationNotRequired,
	fieldDecimalValidationRequired,
	fieldNumberValidationRequiredMin1AndInteger,
	fieldValidationRequired,
	fieldNumberValidationRequired,
	fieldNumberValidationRequiredMin1,
	fieldInitialBalanceValidation,
	fieldPointsValidation,
	fileValidation1mbImage,
	fileValidationCsv,
	fieldStartDateValidation,
	fieldEndDateValidation,
	imageFileValidation,
}
