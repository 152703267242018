import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		box: {},
		label: {
			fontSize: '14px',
			fontWeight: 500,
			lineHeight: '20px',
			letterSpacing: 0,
			marginBottom: '4px',
		},
		helperText: {
			fontSize: '12px',
			letterSpacing: '0',
			lineHeight: '14px',
			marginTop: '4px',
		},
		helperTextError: {
			display: 'block',
			paddingLeft: '4px',
			marginTop: '0px',
		},
		errorContainer: {
			display: 'flex',
			flexDirection: 'row',
			marginBottom: '4px',
			marginTop: '3px',
		},
		formcontrol: {
			marginTop: '0px',
			'& > p.Mui-error': {
				display: 'block',
			},
		},
	}),
)

export default styles
