import { useLogService as logService } from 'admin-portal-shared-services'
import OrderAccount from '@/domains/OrderAccount'
import axios from '@/utils/axiosInstance'

const endPoint = `/rewards-admin-service/v1/orders`
const log = logService()

async function load(orderId: string, vendorId: string): Promise<OrderAccount> {
	const URL = `${endPoint}/${orderId}`

	try {
		const { data } = await axios.get<OrderAccount>(URL, { params: { vendorId } })
		return data
	} catch (error) {
		log.error(`Error endpoint: ${endPoint}`, error)
		throw error
	}
}

export { load }
