/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer, useCallback, useMemo } from 'react'
import { sortBy, SortedConfig } from 'utils/sort'
import AnalyticsService from 'services/analytics/AnalyticsService'
import TableOrderType from '../model/TableOrderType'
import { CustomSortFunction } from '../model/CustomSortFunction'
import { CustomSearchFunction } from '../model/CustomSearchFunction'
import SearchTracking from '../model/SearchTracking'

/**
 * Actions
 */
const ActionType = {
	setCurrentPage: 'SET_CURRENT_PAGE',
	setData: 'SET_DATA',
	setLoading: 'SET_LOADING',
	setSortLoadingField: 'SET_SORT_LOADING_FIELD',
	setOrder: 'SET_ORDER',
	setOrderBy: 'SET_ORDER_BY',
	sortData: 'SORT_DATA',
	searchData: 'SEARCH_DATA',
	setItemsPerPage: 'SET_ITEMS_PER_PAGE',
	setQuery: 'SET_QUERY',
}

type Action = {
	type: string
	fieldName?: string
	payload?: any
}

/**
 * States
 */
export interface DataTableState {
	query: string
	data: any[]
	originalData: any[]
	loading: boolean
	currentPage: number
	order: TableOrderType
	orderBy?: string
	sortLoadingField: string | false
	isInitialSort?: boolean
	itemsPerPage?: number
	interactionType: 'First' | 'Last' | 'Next' | 'Previous' | null
}

export interface ContextDataTable extends DataTableState {
	setCurrentPage: (currentPage: number) => void
	setData: (data: any[]) => void
	setLoading: (loading: boolean) => void
	setSortLoadingField: (field: string | false) => void
	setOrder: (order: TableOrderType) => void
	setOrderBy: (orderBy?: string) => void
	sortData: (sortConfig: SortedConfig<any>[], initialData?: any[]) => void
	searchData: (searchBy: string[], query: string, trackProps?: SearchTracking) => void
	customSearchData: (query: string) => Promise<void>
	getCurrentData: any[]
	setItemsPerPage: (pageSize: number) => void
	setCurrentQuery: (query: string) => void
}

export const initialDataTableState: DataTableState = {
	query: '',
	data: [],
	originalData: [],
	loading: true,
	currentPage: 1,
	order: 'asc',
	sortLoadingField: false,
	interactionType: null,
}

/**
 *  Reducer
 */
const reducer = (state: DataTableState, action: Action): DataTableState => {
	switch (action.type) {
		case ActionType.setCurrentPage:
			return { ...state, currentPage: action.payload }
		case ActionType.setData:
			return { ...state, data: action.payload, originalData: action.payload }
		case ActionType.setLoading:
			return { ...state, loading: action.payload }
		case ActionType.setSortLoadingField:
			return { ...state, sortLoadingField: action.payload }
		case ActionType.setOrder:
			return { ...state, order: action.payload, isInitialSort: false }
		case ActionType.setOrderBy:
			return { ...state, orderBy: action.payload, isInitialSort: false }
		case ActionType.sortData: {
			const { data, isInitialSort, order, orderBy } = action.payload
			if (isInitialSort) {
				return { ...state, data, isInitialSort, sortLoadingField: false, loading: false, order, orderBy }
			}

			return { ...state, data, isInitialSort, sortLoadingField: false, order, orderBy }
		}
		case ActionType.searchData: {
			const { data, query } = action.payload
			if (query) {
				return { ...state, data, currentPage: 1, order: 'asc', orderBy: undefined }
			}

			return { ...state, data: state.originalData, currentPage: 1, order: 'asc', orderBy: undefined }
		}
		case ActionType.setItemsPerPage:
			return { ...state, itemsPerPage: action.payload, currentPage: 1 }
		case ActionType.setQuery: {
			return { ...state, query: action.payload }
		}
		default:
			return state
	}
}

type DataTableParams = {
	defaultItemsPerPage: number
	customSort?: CustomSortFunction
	customSearch?: CustomSearchFunction
	newPagination?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDataTable = ({ defaultItemsPerPage, customSort, customSearch, newPagination }: DataTableParams) => {
	const [state, dispatch] = useReducer(reducer, initialDataTableState)

	const setCurrentPage = useCallback(
		(currentPage: number) => dispatch({ type: ActionType.setCurrentPage, payload: currentPage }),
		[],
	)
	const setData = useCallback((data: any[]) => {
		return dispatch({ type: ActionType.setData, payload: data })
	}, [])

	const setLoading = useCallback((loading: boolean) => dispatch({ type: ActionType.setLoading, payload: loading }), [])

	const setSortLoadingField = useCallback(
		(field = false) => dispatch({ type: ActionType.setSortLoadingField, payload: field }),
		[],
	)

	const setOrder = useCallback((order: TableOrderType) => dispatch({ type: ActionType.setOrder, payload: order }), [])

	const setOrderBy = useCallback((orderBy?: string) => dispatch({ type: ActionType.setOrderBy, payload: orderBy }), [])

	const sortData = useCallback(
		(sortConfig: SortedConfig<typeof state.data>[], initialData?: any[]): void => {
			dispatch({ type: ActionType.setSortLoadingField, payload: sortConfig[0].key.toString() })

			setTimeout(async () => {
				const hasInitialData = initialData && initialData.length > 0

				if (hasInitialData) {
					const sortedData = customSort
						? await customSort(state.data, state.currentPage, sortConfig)
						: sortBy<any>(initialData, sortConfig)
					dispatch({
						type: ActionType.sortData,
						payload: { data: sortedData, isInitialSort: true, orderBy: sortConfig[0].key, order: sortConfig[0].order },
					})
				} else if (!hasInitialData) {
					const sortedData = customSort
						? await customSort(state.data, state.currentPage, sortConfig)
						: sortBy<any>(state.data, sortConfig)
					dispatch({
						type: ActionType.sortData,
						payload: { data: sortedData, isInitialSort: false, orderBy: sortConfig[0].key, order: sortConfig[0].order },
					})
				}
			}, 0)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state.data, state.currentPage],
	)

	const searchData = useCallback(
		(searchBy: string[], query: string, trackProps?: SearchTracking): void => {
			const resultedData = state.originalData.filter((item) => {
				let dataExist = false
				searchBy.forEach((element) => {
					if (item[element]?.toString().toUpperCase().includes(query.toUpperCase()) === true) {
						dataExist = true
					}
				})
				return dataExist
			})

			if (state.originalData.length === resultedData.length && state.orderBy) {
				sortData([{ key: state.orderBy, order: state.order }], resultedData)
				return
			}

			if (trackProps && query.length > 0) {
				AnalyticsService.events.contentSearched({
					record_type: trackProps.recordType,
					screen_name: trackProps.screenName,
					search_query: query,
				})
			}
			dispatch({ type: ActionType.searchData, payload: { data: resultedData, query } })
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state.originalData],
	)

	const customSearchData = async (query: string): Promise<void> => {
		if (customSearch) {
			const data = await customSearch(query)
			dispatch({ type: ActionType.searchData, payload: { data: data || [], query } })
		}
		dispatch({ type: ActionType.setQuery, payload: query })
	}
	const setItemsPerPage = useCallback(
		(pageSize: number) => dispatch({ type: ActionType.setItemsPerPage, payload: pageSize }),
		[],
	)

	const getCurrentData = useMemo(() => {
		const pageSize = state.itemsPerPage ?? defaultItemsPerPage
		const begin = newPagination ? 0 : (state.currentPage - 1) * pageSize
		const end = begin + pageSize
		return state.data?.slice(begin, end)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.data, state.currentPage, state.isInitialSort, state.order, state.orderBy, state.itemsPerPage])

	const setCurrentQuery = (query: string) => {
		dispatch({ type: ActionType.setQuery, payload: query })
	}

	return {
		...state,
		setCurrentPage,
		setData,
		setLoading,
		setSortLoadingField,
		setOrder,
		setOrderBy,
		sortData,
		searchData,
		customSearchData,
		getCurrentData,
		setItemsPerPage,
		setCurrentQuery,
	}
}

export default useDataTable
