import React, { useMemo } from 'react'
import { PaginationProps, Table } from '@hexa-ui/components'
import getTransactionData from '@/utils/getTransactionData'
import { useTranslation } from 'react-i18next'
import { transactionLogViewColumns } from '@/utils/transactionsVariablesUtil'
import { useStoreMap } from 'effector-react'
import { $transactionStore } from '@/stores/transactionV2/TransactionStore'
import { TABLE_PAGE_SIZE_OPTIONS } from '@/utils/constants'
import { Column } from './TransactionLog.types'
import TransactionDetailDialog from '../TransactionDetailDialog/TransactionDetailDialog'
import { useTransactionLogList } from './useTransactionLogList'

const getColumns = (t: (text: string) => string): Column[] =>
	transactionLogViewColumns.map((column) => ({
		...column,
		Header: t(column.Header),
	}))

const TransactionLogView = () => {
	const { t } = useTranslation()
	const { isLoading, data, onPaginationChanged } = useTransactionLogList()
	const translatedColumns = useMemo(() => getColumns(t), [t])

	const pagination = useStoreMap({
		store: $transactionStore,
		keys: [onPaginationChanged],
		fn: ({ pagination: storePagination }): Partial<PaginationProps> => {
			return {
				// The initial page on the View (<Table> component) is 1 and on the ms it's 0 based.
				current: 1 + storePagination.page,
				pageSize: storePagination.pageSize,
				total: storePagination.totalElements,
				pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS.DEFAULT,
				showQuantityIndicator: !isLoading,
				showPageSizeSelector: !isLoading,
				onChange: onPaginationChanged,
				pageSizeOptionsIntl: (option) => {
					return t('common:TABLE_PAGINATION_SIZE_OPTIONS', {
						option,
					})
				},
			}
		},
	})

	const translatedTransaction = () => {
		const transactions = data.map((item) => {
			const type = t(`transaction:TYPE:${item.type}`)

			const target =
				item.audience === 'MULTIPLE' ? t('transaction:TARGET:TARGET_MULTIPLE') : t('transaction:TARGET:TARGET_SINGLE')

			return {
				...item,
				type,
				audience: target,
			}
		})

		const formattedData = getTransactionData(transactions)
		const dataWithActions = formattedData.map((item) => ({
			...item,
			actions: <TransactionDetailDialog transactionId={item.id} />,
		}))
		return dataWithActions
	}

	return (
		<div data-testid="transaction-table">
			<Table
				columns={translatedColumns}
				data={translatedTransaction()}
				loading={isLoading}
				pagination={pagination}
				cellHeight="cozy"
				data-testid="transaction-table--component"
			/>
		</div>
	)
}

export default TransactionLogView
