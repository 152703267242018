import ProgramPatchRequest from '@/domains/program/ProgramPatchRequest'
import Program from 'domains/program/Program'
import { update } from 'services/program/ProgramService'

async function execute(program: Program): Promise<Program | undefined> {
	const programPatchRequest: ProgramPatchRequest = {
		redeemLimit: program.redeemLimit,
		name: program.name,
		initialBalance: program.initialBalance,
	}
	return update(program.id, programPatchRequest)
		.then((updatedProgram) => {
			return updatedProgram
		})
		.catch(() => {
			return undefined
		})
}

export { execute }
