import { UserDataResult } from '@/hooks/getUserInfo/useGetUserInfo'
import typewriter from 'analytics'

enum SegmentKey {
	MEMBERSHIP = 'MEMBERSHIP_SEGMENT_KEY',
}

class AnalyticsService {
	public static events: typeof typewriter = typewriter

	public static SegmentKey = SegmentKey

	public static page(name: string, userInfo: UserDataResult): void {
		try {
			this.events.pageViewed({
				screen_name: name,
				...userInfo,
			})
		} catch (err) {
			//
		}
	}
}

export default AnalyticsService
