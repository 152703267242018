import { useStoreMap } from 'effector-react'
import { ChallengeStore, IndividualAccountGroup } from '@/stores/challengeCreation'

const useIndividualAccountGroup = (): IndividualAccountGroup => {
	const individualAccountGroup = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.individualAccountGroup || {},
	})

	return individualAccountGroup
}

export default useIndividualAccountGroup
