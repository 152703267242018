import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		helperTextError: {
			display: 'block',
			paddingLeft: '4px',
			marginTop: '0px',
			marginBottom: '0px',
			flex: '1',
			fontSize: '12px',
		},
		errorContainer: {
			display: 'flex',
			flexDirection: 'row',
			marginBottom: '4px',
			marginTop: '0px',
			'& svg': {
				minWidth: '12px',
			},
		},
	}),
)

export default styles
