import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressTracker } from '@hexa-ui/components'
import useStyles from './StepsHeaderStyle'

export type StepsHeaderViewProps = {
	thirdStepTitle: string
	currentStep: number
}

const StepsHeaderView: React.FC<StepsHeaderViewProps> = ({ thirdStepTitle, currentStep }: StepsHeaderViewProps) => {
	const classes = useStyles()
	const { t } = useTranslation()

	const challengeSteps = useMemo(
		() => [
			{
				key: 'SETUP',
				label: t('challenge:SETUP_STEP.TAB_TITLE'),
				detail: t('challenge:SETUP_STEP.TAB_SUB_TITLE'),
			},
			{
				key: 'PROCESS',
				label: t('challenge:FILTERS_STEP.TAB_TITLE'),
				detail: t('challenge:FILTERS_STEP.TAB_SUB_TITLE'),
			},
			{
				key: 'SKUS',
				label: t(`challenge:DETAILS_STEP.TAB_TITLE.${thirdStepTitle}`),
				detail: t(`challenge:DETAILS_STEP.TAB_SUB_TITLE.${thirdStepTitle}`),
			},
		],
		[t, thirdStepTitle],
	)

	return (
		<div className={classes.stepper}>
			<ProgressTracker.Root orientation="horizontal" currentStep={currentStep}>
				<ProgressTracker.Header>
					{challengeSteps.map((item, index) => {
						return (
							<ProgressTracker.Step index={index} key={item.key} completed={currentStep > index}>
								<ProgressTracker.StepLabel label={item.label} detail={item.detail} />
							</ProgressTracker.Step>
						)
					})}
				</ProgressTracker.Header>
			</ProgressTracker.Root>
		</div>
	)
}

export default React.memo(StepsHeaderView)
