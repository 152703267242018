import { VendorEnvKey, IEnv, IVendor, IVendors, VendorOption } from '@/domains/vendors'
import { AcceptedCountry } from '@/utils/acceptedCountries'

interface LoadParams {
	country: AcceptedCountry
	env: VendorEnvKey
}

const removeEmptyEnvs = (list: Array<IVendor>, env: VendorEnvKey): Array<IVendor> =>
	list.filter((item) => !!item.envs[env])

const mapToVenderOption = (list: Array<IVendor>, env: VendorEnvKey): Array<VendorOption> =>
	list.map((item: IVendor) => {
		const envData: IEnv = item.envs[env]!
		return {
			vendorName: item.vendorName,
			vendorId: envData.vendorId,
			programs: envData.programs,
		}
	})

export async function load({ country, env }: LoadParams): Promise<Array<VendorOption>> {
	const data: IVendors = (await import('@/configs/vendors.json')).default
	const dataCountry = data[country]
	if (!dataCountry) {
		return []
	}
	const list = removeEmptyEnvs(dataCountry, env)
	return mapToVenderOption(list, env)
}
