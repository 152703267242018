export enum RedeemableStatus {
	PUBLISHED = 'PUBLISHED',
	UNPUBLISHED = 'UNPUBLISHED',
}

export enum RedeemableItemType {
	ITEM = 'ITEM',
}

export enum RedeemableItemStatus {
	PROCESSING = 'PROCESSING',
	FAILED = 'FAILED',
	PUBLISHED = 'PUBLISHED',
}
