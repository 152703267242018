import React, { useMemo } from 'react'
import { $createRedeemableItemStore } from '@/stores'
import { useStore } from 'effector-react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemCell } from '../content/ItemCell/ItemCell'
import { CreateRedeemableItemContentStyle } from '../content/CreateRedeemableItemContentStyle'

const VariantContent = () => {
	const { isSearching, variants } = useStore($createRedeemableItemStore)
	const css = CreateRedeemableItemContentStyle()
	const { t } = useTranslation()

	const variantsLength = useMemo(() => variants.length, [variants])

	return (
		<div className={css.variantContainer}>
			{!isSearching && variantsLength > 0 && (
				<>
					<Typography variant="h5">
						{variantsLength === 1
							? t(`redeemable:CREATE.PRODUCT_HAS_VARIANT`, { variantsLength })
							: t(`redeemable:CREATE.PRODUCT_HAS_VARIANTS`, { variantsLength })}
					</Typography>
					<Typography style={{ fontSize: '12px' }}>
						{t(`redeemable:CREATE.PRODUCT_HAS_VARIANTS_SUBTITLE`, { variantsLength: variants.length })}
					</Typography>
					{variants.map((variant) => (
						<ItemCell {...variant} pricePerPoint={variant.pricePerPoint} key={variant.id} isVariant />
					))}
				</>
			)}
		</div>
	)
}

export default VariantContent
