import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ChallengeDetailsFormData } from '@/stores/challengeCreation'
import SkuBoxContainerView from './SkuBoxContainerView'

type Props = {
	disabled?: boolean
	isEditing?: boolean
	isEmpty: boolean
	hideQuantity?: boolean
}

const SkuBoxContainer: React.FC<Props> = (props: Props) => {
	const { getValues } = useFormContext<ChallengeDetailsFormData>()

	const { skus } = getValues()

	return <SkuBoxContainerView listSkus={skus} {...props} />
}

export default SkuBoxContainer
