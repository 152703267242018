import { ChallengeSetupFormData } from '@/stores/challengeCreation'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputText } from '..'
import CustomHexaInput from '../InputText/CustomHexaInput'

interface IIputProps {
	isNewDSM?: boolean
}

const InputChallengeCampaignId: React.FC<IIputProps> = ({ isNewDSM }: IIputProps) => {
	const { t } = useTranslation()
	const { control } = useFormContext<ChallengeSetupFormData>()

	const InputHexa = useMemo(() => {
		return (
			<Controller
				control={control}
				name="campaignId"
				defaultValue=""
				render={({ field, fieldState: { error } }) => (
					<CustomHexaInput
						id={field.name}
						data-testid="challenge-campaignId-input"
						label={t('challenge:SETUP_STEP.CAMPAIGN_ID')}
						optionalLabel={t('OPTIONAL_FIELD')}
						errorText={error?.message}
						infoIconTooltip
						customMessageTooltip={t('challenge:SETUP_STEP.CAMPAIGN_ID_TOOLTIP')}
						{...field}
					/>
				)}
			/>
		)
	}, [control, t])

	const defaultInput = useMemo(() => {
		return (
			<Controller
				control={control}
				name="campaignId"
				defaultValue=""
				render={({ field, fieldState: { error } }) => (
					<InputText
						data-testid="challenge-campaignId-input"
						id={field.name}
						label={t('challenge:SETUP_STEP.CAMPAIGN_ID')}
						optionalLabel={t('OPTIONAL_FIELD')}
						errorText={error?.message}
						infoIconTooltip
						customMessageTooltip={t('challenge:SETUP_STEP.CAMPAIGN_ID_TOOLTIP')}
						{...field}
					/>
				)}
			/>
		)
	}, [control, t])

	return <>{isNewDSM ? InputHexa : defaultInput}</>
}

export default InputChallengeCampaignId
