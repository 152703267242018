import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		inputFile: {
			position: 'absolute',
			left: 0,
			top: 0,
			opacity: 0,
			zIndex: -1,
		},
		button: {
			lineHeight: '16px',
			marginRight: '8px',
		},
	}),
)

export default styles
