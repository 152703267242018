import FormCard from '@/routes/challenge/componentsRedesign/FormCard/FormCard'
import React, { ReactNode } from 'react'

export type ChallengeFormWrapperProps = { isEditing?: boolean; children: ReactNode }

const ChallengeFormWrapperView: React.FC<ChallengeFormWrapperProps> = ({ isEditing, children, ...props }) => {
	return isEditing ? <>{children}</> : <FormCard {...props}>{children}</FormCard>
}

export default ChallengeFormWrapperView
