import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertDialog } from 'components/core'

type Props = {
	show: boolean
	onConfirm: () => void
	onCancel: (buttonClicked: boolean) => void
}

const RemoveComboDialogView: React.FC<Props> = ({ show, onConfirm, onCancel }: Props) => {
	const { t } = useTranslation()

	return (
		<AlertDialog
			title={t('combo:REMOVE_DIALOG_TITLE')}
			show={show}
			dataTestIdBase="remove-combo-dialog"
			handleCancel={onCancel}
			handleConfirm={onConfirm}
			buttonConfirmText={t('BUTTON.REMOVE')}
		>
			{t('combo:REMOVE_DIALOG_MESSAGE')}
		</AlertDialog>
	)
}

export default RemoveComboDialogView
