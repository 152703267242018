import React from 'react'
import { EditDialog } from '@/domains/enums'
import { OnSaveDialogFunction } from '@/routes/challenges/model/EditChallengeDialog'
import ChallengeFormSetupEditDialog from '../ChallengeFormSetupEditDialog/ChallengeFormSetupEditDialog'
import ChallengeFormFilterEditDialog from '../ChallengeFormFilterEditDialog/ChallengeFormFilterEditDialog'
import ChallengeFormDetailsEditDialog from '../ChallengeFormDetailsEditDialog/ChallengeFormDetailsEditDialog'
import ChallengeNameEditDialog from '../ChallengeNameEditDialog/ChallengeNameEditDialog'

type Props = {
	activeEditDialog: EditDialog
	onSaveDialog: OnSaveDialogFunction
}

const EditChallengeDialogView: React.FC<Props> = ({ activeEditDialog, onSaveDialog }) => {
	return {
		[EditDialog.Setup]: <ChallengeFormSetupEditDialog onSaveDialog={onSaveDialog} />,
		[EditDialog.Filter]: <ChallengeFormFilterEditDialog onSaveDialog={onSaveDialog} />,
		[EditDialog.Details]: <ChallengeFormDetailsEditDialog onSaveDialog={onSaveDialog} />,
		[EditDialog.Name]: <ChallengeNameEditDialog onSaveDialog={onSaveDialog} />,
		[EditDialog.None]: null,
	}[activeEditDialog]
}

export default EditChallengeDialogView
