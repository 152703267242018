import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		paper: {
			marginBottom: '16px',
			padding: (props: { hidePadding?: boolean }): string => (props.hidePadding ? '0' : '24px'),
			borderRadius: '16px',
			boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
			border: 'none',
		},
	}),
)

export default styles
