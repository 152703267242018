/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface BackButtonClicked {
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Nome of the screen user is navigating to when clicking the CONTINUE button. Samples: Screen 2: Apply Filters
	 */
	destination_screen?: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
}
export interface ProductCategoryItem {
	/**
	 * The strategy, or dimension, related to the transaction being created. Examples BEER, NAB, and/or Marketplace.
	 */
	dimension: string | null
}
export interface SkusItem {
	/**
	 * The ID of the inputted sku
	 */
	sku: string | null
}
export interface BulkPointsTransactionCreated {
	/**
	 * The ID of the campaign. Samples: BRZ8662
	 */
	campaign_id: string | null
	/**
	 * The description of the Offer. Samples: 'Points awarded regarding Braze campaign #BRZ8662'
	 */
	description: string | null
	/**
	 * Name of the file being uploaded. Samples: file.csv
	 */
	file_name: string | null
	/**
	 * Size of the file being uploaded. Samples: 65KB
	 */
	file_size: string | null
	/**
	 * Number of records inside the file uploaded. Samples: 3500, 2000
	 */
	poc_list: number | null
	/**
	 * Amount of points that was awarded or removed in that transaction. Samples: 4000, 250, -4000
	 */
	points_adjustment: number | null
	/**
	 * The array of product category or dimesions. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	product_category: ProductCategoryItem[] | null
	/**
	 * The name of the Campaign
	 */
	reference_category: string | null
	/**
	 * The year of the campaign
	 */
	reference_month: string | null
	/**
	 * The month of the campaign
	 */
	reference_year: string | null
	/**
	 * An array of the skus that are contained in the challenge or transaction.
	 */
	skus: SkusItem[] | null
	/**
	 * Id of the transparency collection available only for Multiple POCs operations. Samples: 123456789
	 */
	submitted_id: string | null
	/**
	 * The category of the points transaction. Available for service utility. Samples: Electricity Bill, Aster TV
	 */
	transaction_category: string | null
	/**
	 * The date when the points transaction was created. Samples: 10/21/2020 14:32
	 */
	transaction_date: string | null
	/**
	 * Identification of the transaction created. Samples: Refund
	 */
	transaction_type: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface ButtonClicked {
	/**
	 * Label of the button, e.g., Challenge History
	 */
	button_label: string | null
	/**
	 * Name of the button clicked. Samples: Add Filter
	 */
	button_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface ChallengeCancelled {
	/**
	 * The reason why the user journey was cancelled. Samples: 'Strategy change'
	 */
	cancellation_reason: string | null
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Status of the challenge (from Admin perspective, not from front-end user perspective). Samples: Created / Expired
	 */
	challenge_status?: string | null
	/**
	 * The type of the challenge in the given context
	 */
	challenge_type?: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface SkusItem1 {
	/**
	 * The ID of the inputted sku
	 */
	sku: string | null
}
export interface ChallengeCreated {
	/**
	 * The bad image example that is displayed with the challenge
	 */
	bad_image: string | null
	/**
	 * The ID of the campaign. Samples: BRZ8662
	 */
	campaign_id: string | null
	/**
	 * The description of a challenge concateneting "description + detailedDescription" fields in case the challenge has both fields filled. Samples: Purchase 5 unities of Coronita Extra 210ml within a single order and earn 1,200 points
	 */
	challenge_description?: string | null
	/**
	 * Date in which the challenge ends. Samples: 9/30/2020
	 */
	challenge_end_date?: string | null
	/**
	 * Challenge radio button for choosing amount of units or amount of money
	 */
	challenge_goal: string | null
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Number of points to complete the challenge. Samples: 1,500
	 */
	challenge_points?: number | null
	/**
	 * Date in which the challenge starts. Samples: 8/1/2020
	 */
	challenge_start_date?: string | null
	/**
	 * Status of the challenge (from Admin perspective, not from front-end user perspective). Samples: Created / Expired
	 */
	challenge_status?: string | null
	/**
	 * Title of the challenge. Samples: Desafío Coronita
	 */
	challenge_title?: string | null
	/**
	 * The type of the challenge in the given context
	 */
	challenge_type?: string | null
	/**
	 * The cover image of the challenge
	 */
	cover_image: string | null
	/**
	 * Creation date of the challenge formatted as mm/dd/yyyy
	 */
	creation_date: string
	/**
	 * Also known as "type of challenge". Samples: TAKE_PHOTO
	 */
	execution_method?: string | null
	/**
	 * The good image example that is displayed with the challenge
	 */
	good_image: string | null
	/**
	 * URL in which challenge image is stored. Samples: https://b2bstaticwebsagbprod.blob.core.windows.net/challenge/DO/images/ChallengeCoverPhotoForPresidentePTC.jpg
	 */
	image_url?: string | null
	/**
	 * The type of filter that is selected for the challenge, e.g., DDC, Segment...
	 */
	poc_filter_type: string
	/**
	 * The value related to the filter type, e.g., group_id, groups, data imputted in the Filter Values field
	 */
	poc_filter_value: string | null
	/**
	 * Quantity of points
	 */
	points: string | null
	/**
	 * Only for volume-fixed challenges, it defines if the user needs to achieve the challenge in one or more purchases
	 */
	purchase_type: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Maximum quantity of skus that must be purchased in the Volume Purchase - Variable Points challenge
	 */
	sku_quantity_max: string | null
	/**
	 * Minimum quantity of skus that must be purchased in the Volume Purchase - Fixed Points and Volume Purchase - Variable Points challenge
	 */
	sku_quantity_min: string | null
	/**
	 * An array of the skus that are contained in the challenge or transaction.
	 */
	skus: SkusItem1[] | null
	/**
	 * The type of target that is selected for the challenge, e.g., Individual or General
	 */
	target_type: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface ChallengeCreationStarted {
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
}
export interface ChallengeDetailsViewed {
	/**
	 * The description of a challenge concateneting "description + detailedDescription" fields in case the challenge has both fields filled. Samples: Purchase 5 unities of Coronita Extra 210ml within a single order and earn 1,200 points
	 */
	challenge_description?: string | null
	/**
	 * Date in which the challenge ends. Samples: 9/30/2020
	 */
	challenge_end_date?: string | null
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Number of points to complete the challenge. Samples: 1,500
	 */
	challenge_points?: number | null
	/**
	 * Date in which the challenge starts. Samples: 8/1/2020
	 */
	challenge_start_date?: string | null
	/**
	 * Status of the challenge (from Admin perspective, not from front-end user perspective). Samples: Created / Expired
	 */
	challenge_status?: string | null
	/**
	 * Title of the challenge. Samples: Desafío Coronita
	 */
	challenge_title?: string | null
	/**
	 * Also known as "type of challenge". Samples: TAKE_PHOTO
	 */
	execution_method?: string | null
	/**
	 * URL in which challenge image is stored. Samples: https://b2bstaticwebsagbprod.blob.core.windows.net/challenge/DO/images/ChallengeCoverPhotoForPresidentePTC.jpg
	 */
	image_url?: string | null
}
export interface CheckboxInteraction {
	/**
	 * The label displayed on the checkbox
	 */
	checkbox_label: string | null
	/**
	 * The name of the displayed checkbox
	 */
	checkbox_name: string | null
	/**
	 * The logic status of the checkbox
	 */
	is_checked: string
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface ContentSearched {
	/**
	 * Type of the record in the given context, for example a challenge. Samples: Purchase Challenge - Single, DT COMBO
	 */
	record_type: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The exact search text input by the user in native language
	 */
	search_query?: string | null
}
export interface ContinueButtonClicked {
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Nome of the screen user is navigating to when clicking the CONTINUE button. Samples: Screen 2: Apply Filters
	 */
	destination_screen?: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
}
export interface DefaultPricePerPointUpdated {
	/**
	 * The price per point
	 */
	price_per_point: number | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface EarningRuleCreated {
	/**
	 * The amount of currency required for the points multipler, e.g., 1x, 2x, 3x
	 */
	amount: Record<string, any> | null
	/**
	 * The id of the Category that is created on Category Admin
	 */
	category_id: string | null
	/**
	 * Description added to the earning rule, e.g., Black Friday, Christmas, 10x Bud
	 */
	earning_rule_description: string | null
	/**
	 * The id given to the earning rule after creation
	 */
	earning_rule_id: string | null
	/**
	 * Quantity of points
	 */
	points: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface SkusItem2 {
	/**
	 * The ID of the inputted sku
	 */
	sku: string | null
}
export interface EarningRuleSkUsUpdated {
	/**
	 * Id of the earning rule that contains the skus before the changes
	 */
	current_earning_rule_id: string | null
	/**
	 * Name of the file being uploaded. Samples: file.csv
	 */
	file_name: string | null
	/**
	 * Size of the file being uploaded. Samples: 65KB
	 */
	file_size: string | null
	/**
	 * The type of operation done, e.g., Move, Remove or Add
	 */
	operation_type: string
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * An array of the skus that are contained in the challenge or transaction.
	 */
	skus: SkusItem2[] | null
	/**
	 * Quantity of skus
	 */
	skus_quantity: string | null
	/**
	 * Id of the earning rule that will contain the skus after the changes
	 */
	target_earning_rule_id: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface EarningRuleUpdated {
	/**
	 * The amount of currency required for the points multipler, e.g., 1x, 2x, 3x
	 */
	amount: Record<string, any> | null
	/**
	 * The id of the Category that is created on Category Admin
	 */
	category_id: string | null
	/**
	 * Description added to the earning rule, e.g., Black Friday, Christmas, 10x Bud
	 */
	earning_rule_description: string | null
	/**
	 * The id given to the earning rule after creation
	 */
	earning_rule_id: string | null
	/**
	 * Quantity of points
	 */
	points: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface ElementClicked {
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_display_name: string
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id: string
	/**
	 * The element text on the front end with localization.
	 */
	element_label: string | null
	/**
	 * The category of the object that anchors the button.
	 */
	element_location_type: string
	/**
	 * The element name on the back end.
	 */
	element_name: string | null
	/**
	 * The element type.
	 */
	element_type: string
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event: boolean | null
	/**
	 * The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization.
	 */
	page_label: string | null
	/**
	 * The name of the page where the event was triggered.
	 */
	page_name: string
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url: string | null
	/**
	 * Number of POCs involved in the transaction.
	 */
	poc_count: number | null
	/**
	 * Quantity of points
	 */
	points: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * The date when the points transaction was created. Samples: 10/21/2020 14:32
	 */
	transaction_date: string | null
	/**
	 * The detailed status of the transaction at the time the action was taken (e.g. CSV download).
	 */
	transaction_details_status: string | null
	/**
	 * Unique identifier for the transaction.
	 */
	transaction_id: string | null
	/**
	 * The current status of the transaction.
	 */
	transaction_status: string | null
	/**
	 * Identification of the transaction created. Samples: Refund
	 */
	transaction_type: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country).
	 */
	user_country: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email: string | null
	/**
	 * The unique id of the user that performed or caused this action.
	 */
	user_id: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
}
export interface Error {
	/**
	 * Reason why the action failed. Samples: Invalid username or password
	 */
	failure_reason?: string | null
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface FileProcessed {
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_display_name: string
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id: string
	/**
	 * This specifies the format or type of the file. It should be filled with standard file format abbreviations that indicate the nature and structure of the file's content.
	 */
	file_format: string
	/**
	 * When the file is tabular (CSV, XLS), this indicates if it has headers.
	 */
	file_has_headers: boolean | null
	/**
	 * When the file is tabular (CSV, XLS), this indicates the number of columns.
	 */
	file_number_of_columns: number | null
	/**
	 * When the file is tabular (CSV, XLS), this indicates the number of rows (headers included).
	 */
	file_number_of_rows: number | null
	/**
	 * This identifies rather the process is a Download (starting point) or an Upload (conclusion point).
	 */
	file_process_type: string
	/**
	 * Size of the file being uploaded. Samples: 65KB
	 */
	file_size: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event: boolean | null
	/**
	 * The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization.
	 */
	page_label: string | null
	/**
	 * The name of the page where the event was triggered.
	 */
	page_name: string
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country).
	 */
	user_country: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email: string | null
	/**
	 * The unique id of the user that performed or caused this action.
	 */
	user_id: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
}
export interface FileRemoved {
	/**
	 * Name of the file being uploaded. Samples: file.csv
	 */
	file_name: string | null
	/**
	 * The type of the file that was removed, e.g., CSV
	 */
	file_type: string
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface FileUploaded {
	/**
	 * Name of the file being uploaded. Samples: file.csv
	 */
	file_name: string | null
	/**
	 * The type of the file that was removed, e.g., CSV
	 */
	file_type: string
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface FilterValueAdded {
	/**
	 * Name of the filter. Samples: DDC
	 */
	challenge_filter_name?: string | null
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Value typed by the user in the field. Samples: Caxias
	 */
	value_added?: string | null
}
export interface GroupCreated {
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Name of the file being uploaded. Samples: file.csv
	 */
	file_name: string | null
	/**
	 * Size of the file being uploaded. Samples: 65KB
	 */
	file_size: string | null
	/**
	 * ID generated for the group created. Samples: 1596202772628
	 */
	group_id?: string | null
	/**
	 * Name provided by the user to the group. Samples: POCs Northeast Region
	 */
	group_name?: string | null
	/**
	 * Number of records inside the file uploaded. Samples: 325
	 */
	group_size?: string | null
}
export interface GroupUploaded {
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Name of the file being uploaded. Samples: file.csv
	 */
	file_name: string | null
	/**
	 * Size of the file being uploaded. Samples: 65KB
	 */
	file_size: string | null
	/**
	 * Name provided by the user to the group. Samples: POCs Northeast Region
	 */
	group_name?: string | null
}
export interface ImageDeleted {
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Also known as "type of challenge". Samples: TAKE_PHOTO
	 */
	execution_method?: string | null
	/**
	 * Indicates what the image refers to. Samples: Challenge Image / Bad Photo / Good Photo
	 */
	image_description?: string | null
	/**
	 * Name of the file uploaded. Samples: image.jpg
	 */
	image_name?: string | null
	/**
	 * Size of the file uploaded. Samples: 826KB
	 */
	image_size?: string | null
	/**
	 * File extension. Samples: .JPG
	 */
	image_type?: string | null
}
export interface ImageUploaded {
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * Also known as "type of challenge". Samples: TAKE_PHOTO
	 */
	execution_method?: string | null
	/**
	 * Indicates what the image refers to. Samples: Challenge Image / Bad Photo / Good Photo
	 */
	image_description?: string | null
	/**
	 * Name of the file uploaded. Samples: image.jpg
	 */
	image_name?: string | null
	/**
	 * Size of the file uploaded. Samples: 826KB
	 */
	image_size?: string | null
	/**
	 * File extension. Samples: .JPG
	 */
	image_type?: string | null
	/**
	 * URL in which challenge image is stored. Samples: https://b2bstaticwebsagbprod.blob.core.windows.net/challenge/DO/images/ChallengeCoverPhotoForPresidentePTC.jpg
	 */
	image_url?: string | null
}
export interface LinkClicked {
	/**
	 * The labeled displayed for the clicked link
	 */
	link_label: string | null
	/**
	 * The name of the clicked link
	 */
	link_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface CombosItem {
	/**
	 * Id of the combo in the Refund operation. Samples: DT001
	 */
	refund_combo_id: string | null
	/**
	 * Points refunded for that combo. Samples: 1500
	 */
	refund_combo_points: number | null
	/**
	 * Quantity refunded for that combo. Samples: 3
	 */
	refund_combo_quantity: number | null
}
export interface ManualRefundCreated {
	/**
	 * Array of combos
	 */
	combos: CombosItem[] | null
	/**
	 * The date the order was created. It's a date
	 */
	order_date: string | null
	/**
	 * ID of the order. Samples: 20006886
	 */
	order_id: string | null
	/**
	 * The status of the order. Samples: 'CONFIRMED', 'CANCELLED'
	 */
	order_status: string | null
	/**
	 * ID of the POC. Samples: 11544668000135
	 */
	poc_id: string | null
	/**
	 * Identification of the transaction created. Samples: Refund
	 */
	transaction_type: string | null
}
export interface CombosItem1 {
	/**
	 * Id of the combo in the Refund operation. Samples: DT001
	 */
	refund_combo_id: string | null
	/**
	 * Points refunded for that combo. Samples: 1500
	 */
	refund_combo_points: number | null
	/**
	 * Quantity refunded for that combo. Samples: 3
	 */
	refund_combo_quantity: number | null
}
export interface ManualRefundTransactionCreated {
	/**
	 * Array of combos
	 */
	combos: CombosItem1[] | null
	/**
	 * The date the order was created. It's a date
	 */
	order_date: string | null
	/**
	 * ID of the order. Samples: 20006886
	 */
	order_id: string | null
	/**
	 * The status of the order. Samples: 'CONFIRMED', 'CANCELLED'
	 */
	order_status: string | null
	/**
	 * ID of the POC that is the target of the points transaction. Samples: 11544668000135
	 */
	original_poc_id: string | null
	/**
	 * The date when the points transaction was created. Samples: 10/21/2020 14:32
	 */
	transaction_date: string | null
	/**
	 * Identification of the transaction created. Samples: Refund
	 */
	transaction_type: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface MenuInteraction {
	/**
	 * Options in the left side nav bar that represent features or group of features. Samples: Create a Challenge / Points Management
	 */
	menu_category?: string | null
	/**
	 * Options in the left side nav bar that usually represent features being grouped inside a main category. Samples: Create Points Transaction
	 */
	menu_subcategory?: string | null
}
export interface ModalExited {
	/**
	 * Name of the modal. Samples: 'Review IDs', 'Rewards Offer'
	 */
	modal_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
}
export interface PageRowChanged {
	/**
	 * The number of rows per page selected
	 */
	rows_per_page: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface PageViewed {
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface PaginationClicked {
	/**
	 * The type of interaction the user made, e.g., going back to a previous page, advancing to next, first page, last page
	 */
	interaction_type: string | null
	/**
	 * The number of the page when interacted with
	 */
	page_number: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface CombosItem2 {
	/**
	 * Id of the combo in the Refund operation. Samples: DT001
	 */
	refund_combo_id: string | null
	/**
	 * Points refunded for that combo. Samples: 1500
	 */
	refund_combo_points: number | null
	/**
	 * Quantity refunded for that combo. Samples: 3
	 */
	refund_combo_quantity: number | null
}
export interface PocListsItem {
	/**
	 * The ID of the POC
	 */
	poc_id: string | null
}
export interface ProductCategoryItem1 {
	/**
	 * The strategy, or dimension, related to the transaction being created. Examples BEER, NAB, and/or Marketplace.
	 */
	dimension: string | null
}
export interface SkusItem3 {
	/**
	 * The ID of the inputted sku
	 */
	sku: string | null
}
export interface PointsTransactionCreated {
	/**
	 * The ID of the campaign. Samples: BRZ8662
	 */
	campaign_id: string | null
	/**
	 * Array of combos
	 */
	combos: CombosItem2[] | null
	/**
	 * The description of the Offer. Samples: 'Points awarded regarding Braze campaign #BRZ8662'
	 */
	description: string | null
	/**
	 * Name of the file being uploaded. Samples: file.csv
	 */
	file_name: string | null
	/**
	 * Size of the file being uploaded. Samples: 65KB
	 */
	file_size: string | null
	/**
	 * Options in the left side nav bar that represent features or group of features. Samples: Create a Challenge / Points Management
	 */
	menu_category?: string | null
	/**
	 * The date the order was created. It's a date
	 */
	order_date: string | null
	/**
	 * ID of the order. Samples: 20006886
	 */
	order_id: string | null
	/**
	 * The status of the order. Samples: 'CONFIRMED', 'CANCELLED'
	 */
	order_status: string | null
	/**
	 * ID of the POC that is the target of the points transaction. Samples: 11544668000135
	 */
	original_poc_id: string | null
	/**
	 * Identify whether the user makes adjustments to a single_poc or for multiple_poc
	 */
	poc_amount: string | null
	/**
	 * The array of pocs contained in the file that was used for the points transaction
	 */
	poc_lists: PocListsItem[] | null
	/**
	 * Amount of points that was awarded or removed in that transaction. Samples: 4000, 250, -4000
	 */
	points_adjustment: number | null
	/**
	 * The Description of the points transaction
	 */
	points_transaction_description: string | null
	/**
	 * The array of product category or dimesions. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	product_category: ProductCategoryItem1[] | null
	/**
	 * The year of the campaign
	 */
	reference_month: string | null
	/**
	 * The month of the campaign
	 */
	reference_year: string | null
	/**
	 * It specifies the reason for removing the points, example Suspicious activity, Points added incorrectly, System error correction, Refund error correction, Expired points, and Other
	 */
	removal_reason: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * An array of the skus that are contained in the challenge or transaction.
	 */
	skus: SkusItem3[] | null
	/**
	 * The ID of the POC that is receiving the transfered points
	 */
	target_poc_id: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * The category of the points transaction. Available for service utility. Samples: Electricity Bill, Aster TV
	 */
	transaction_category: string | null
	/**
	 * The date when the points transaction was created. Samples: 10/21/2020 14:32
	 */
	transaction_date: string | null
	/**
	 * Identification of the transaction created. Samples: Refund
	 */
	transaction_type: string | null
	/**
	 * ID of the POC who is receiving transfered points. Samples: 11544668000135
	 */
	transfered_poc_id: string | null
	/**
	 * The ID created by the Transparency
	 */
	transparency_id: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface ProgramCombosItem {
	/**
	 * ID of the DT  program Combo. Samples: DT000068864
	 */
	program_combo_id: string | null
	/**
	 * Quantity of points linked to the DT Combo. Samples: 850, 900
	 */
	program_combo_points: number | null
}
export interface ProgramCombosAdded {
	/**
	 * Array to accommodate the combos of Rewards Program
	 */
	program_combos: ProgramCombosItem[] | null
	/**
	 * ID of the program. Samples: 1, 5
	 */
	program_id: string | null
}
export interface QuantityAdded {
	/**
	 * Name of the interacted field that contains the quantity, e.g., Sku Quantity
	 */
	field_name: string | null
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * The number that was imputted by the user in the quantity field
	 */
	quantity: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface RadioButtonInteraction {
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * Label of the radio button that has been interacted with, e.g., General
	 */
	radio_button_label: string | null
	/**
	 * Name of the radio button that has been interacted with, e.g., General Target
	 */
	radio_button_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface ItemsItem {
	/**
	 * Id of the DT Combo
	 */
	item_id: string | null
	/**
	 * Name of the group being added
	 */
	item_name: string | null
	/**
	 * Quantity of points
	 */
	points: string | null
}
export interface RecordAdded {
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * Array of items
	 */
	items?: ItemsItem[] | null
	/**
	 * The sum of items added in the event
	 */
	items_quantity: string | null
	/**
	 * Type of the record in the given context, for example a challenge. Samples: Purchase Challenge - Single, DT COMBO
	 */
	record_type: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface RecordDeleted {
	/**
	 * The ID of the record. For example the Challenge ID. Samples: 1535
	 */
	record_id: string | null
	/**
	 * Type of the record in the given context, for example a challenge. Samples: Purchase Challenge - Single, DT COMBO
	 */
	record_type: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface RecordUpdated {
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * Additional information used to identify what screen or section was the record related to. Samples: Challenge Setup
	 */
	record_additional_info: string | null
	/**
	 * The ID of the record. For example the Challenge ID. Samples: 1535
	 */
	record_id: string | null
	/**
	 * Type of the record in the given context, for example a challenge. Samples: Purchase Challenge - Single, DT COMBO
	 */
	record_type: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface RedeemableCreated {
	/**
	 * The price per point
	 */
	price_per_point: number | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The ID of the inputted sku
	 */
	sku: string | null
	/**
	 * The name of SKU
	 */
	sku_name: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface RedeemablePricePerPointUpdated {
	/**
	 * The price per point
	 */
	price_per_point: number | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The ID of the inputted sku
	 */
	sku: string | null
	/**
	 * The name of SKU
	 */
	sku_name: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface RewardsOfferCreated {
	/**
	 * Amount of points awarded in that transaction. Samples: 4000, 2500
	 */
	awarded_points: number | null
	/**
	 * The ID of the campaign. Samples: BRZ8662
	 */
	campaign_id: string | null
	/**
	 * Name of the file being uploaded. Samples: file.csv
	 */
	file_name: string | null
	/**
	 * Size of the file being uploaded. Samples: 65KB
	 */
	file_size: string | null
	/**
	 * The description of the Offer. Samples: 'Points awarded regarding Braze campaign #BRZ8662'
	 */
	offer_description: string | null
	/**
	 * ID of the POC. Samples: 11544668000135
	 */
	poc_id: string | null
	/**
	 * Number of records inside the file uploaded. Samples: 3500, 2000
	 */
	poc_list: number | null
	/**
	 * Id of the transparency collection available only for Multiple POCs operations. Samples: 123456789
	 */
	submitted_id: string | null
	/**
	 * Identification of the transaction created. Samples: Refund
	 */
	transaction_type: string | null
}
export interface SelectFilter {
	/**
	 * Name of the filter. Samples: DDC
	 */
	challenge_filter_name?: string | null
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
}
export interface SelectInteraction {
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Label of the select element that has been interacted with, e.g.,+ Add New Group
	 */
	select_label: string | null
	/**
	 * Name of the select element that has been interacted with, e.g., Add New Group - Individual Target
	 */
	select_name: string | null
	/**
	 * Name of the selected item inside the select element, e.g., + Add New Group
	 */
	selected_value: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface SignInFailed {
	/**
	 * Reason why the action failed. Samples: Invalid username or password
	 */
	failure_reason?: string | null
}
export interface SkuAdded {
	/**
	 * SKU provided by the user. Samples: 0020531
	 */
	challenge_sku?: string | null
	/**
	 * SKU added by the user to an Earning Rule. Samples: 0020531
	 */
	earning_sku: string | null
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The ID of the inputted sku
	 */
	sku: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface SkuQuantityAdded {
	/**
	 * SKU provided by the user. Samples: 0020531
	 */
	challenge_sku?: string | null
	/**
	 * Quantity for the SKU provided by the user. Samples: 15
	 */
	challenge_sku_quantity?: string | null
	/**
	 * Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
	 */
	form_name: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The ID of the inputted sku
	 */
	sku: string | null
	/**
	 * The quantity required for the inputted sku
	 */
	sku_quantity: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface SubmitButtonClicked {
	/**
	 * Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
	 */
	challenge_id?: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface FilterOptionsItem {
	/**
	 * Name of the filter option used for filtering, e.g., Photo Challenge, Volume Challenge - Fixed Points
	 */
	filter_option: string | null
}
export interface TableFiltered {
	/**
	 * The category of filter used for filtering
	 */
	filter_category: string
	/**
	 * The options available for filtering, e.g., Photo Challenge, Volume Challenge - Fixed Points
	 */
	filter_options: FilterOptionsItem[] | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The name of the table containing the displayed data
	 */
	table_name: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface TableItemsPerPageChanged {
	/**
	 * The number of items displayed per page
	 */
	items_per_page: string | null
	/**
	 * The total number of items inside the table
	 */
	items_total: string | null
	/**
	 * The number of the page when interacted with
	 */
	page_number: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The name of the table containing the displayed data
	 */
	table_name: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface TableSearched {
	/**
	 * The type of the updated record, e.g., Earning Rule
	 */
	content_type: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The exact search text input by the user in native language
	 */
	search_query?: string | null
	/**
	 * The name of the table containing the displayed data
	 */
	table_name: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface TableSorted {
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The name of the column that was used for filtering the results
	 */
	sort_criteria: string | null
	/**
	 * The type of filter appled, e.g., Ascending, Descending
	 */
	sort_type: string | null
	/**
	 * The name of the table containing the displayed data
	 */
	table_name: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface TableViewed {
	/**
	 * The number of items displayed per page
	 */
	items_per_page: string | null
	/**
	 * The total number of items inside the table
	 */
	items_total: string | null
	/**
	 * The number of the page when interacted with
	 */
	page_number: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * The name of the table containing the displayed data
	 */
	table_name: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface TierConfigurationUpdated {
	/**
	 * The monthly earning limit of points for the tier
	 */
	earn_limit: string | null
	/**
	 * The initial balance set for the tier
	 */
	initial_balance: string | null
	/**
	 * The maximum quantity of DT Combos that can be redeemed each time for the tier
	 */
	redeem_limit: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}
export interface UndoClicked {
	/**
	 * The type of the entity, e.g Challenge. Volume Purchase...
	 */
	entity: string | null
	/**
	 * Entity ID (e.g. SKU, POC_ID)
	 */
	entity_id: string | null
	/**
	 * The type of operation done, e.g., Move, Edit, Remove or Add
	 */
	operation: string | null
	/**
	 * Name of the screen in which the button is placed. Samples: Apply Filters
	 */
	screen_name?: string | null
	/**
	 * Name of the selected tier
	 */
	tier: string | null
	/**
	 * E-mail of the user that triggered the event
	 */
	user: string | null
	/**
	 * Name of the selected vendor
	 */
	vendor: string | null
	/**
	 * The zone where the points transaction was created
	 */
	zone: string | null
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef BackButtonClicked
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {string | null} [destination_screen] - Nome of the screen user is navigating to when clicking the CONTINUE button. Samples: Screen 2: Apply Filters
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 */
/**
 * @typedef ProductCategoryItem
 * @property {string | null} dimension - The strategy, or dimension, related to the transaction being created. Examples BEER, NAB, and/or Marketplace.
 */
/**
 * @typedef SkusItem
 * @property {string | null} sku - The ID of the inputted sku
 */
/**
 * @typedef BulkPointsTransactionCreated
 * @property {string | null} campaign_id - The ID of the campaign. Samples: BRZ8662
 * @property {string | null} description - The description of the Offer. Samples: 'Points awarded regarding Braze campaign #BRZ8662'
 * @property {string | null} file_name - Name of the file being uploaded. Samples: file.csv
 * @property {string | null} file_size - Size of the file being uploaded. Samples: 65KB
 * @property {number | null} poc_list - Number of records inside the file uploaded. Samples: 3500, 2000
 * @property {number | null} points_adjustment - Amount of points that was awarded or removed in that transaction. Samples: 4000, 250, -4000
 * @property {ProductCategoryItem[] | null} product_category - The array of product category or dimesions. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} reference_category - The name of the Campaign
 * @property {string | null} reference_month - The year of the campaign
 * @property {string | null} reference_year - The month of the campaign
 * @property {SkusItem[] | null} skus - An array of the skus that are contained in the challenge or transaction.
 * @property {string | null} submitted_id - Id of the transparency collection available only for Multiple POCs operations. Samples: 123456789
 * @property {string | null} transaction_category - The category of the points transaction. Available for service utility. Samples: Electricity Bill, Aster TV
 * @property {string | null} transaction_date - The date when the points transaction was created. Samples: 10/21/2020 14:32
 * @property {string | null} transaction_type - Identification of the transaction created. Samples: Refund
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef ButtonClicked
 * @property {string | null} button_label - Label of the button, e.g., Challenge History
 * @property {string | null} button_name - Name of the button clicked. Samples: Add Filter
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef ChallengeCancelled
 * @property {string | null} cancellation_reason - The reason why the user journey was cancelled. Samples: 'Strategy change'
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {string | null} [challenge_status] - Status of the challenge (from Admin perspective, not from front-end user perspective). Samples: Created / Expired
 * @property {string | null} [challenge_type] - The type of the challenge in the given context
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef SkusItem1
 * @property {string | null} sku - The ID of the inputted sku
 */
/**
 * @typedef ChallengeCreated
 * @property {string | null} bad_image - The bad image example that is displayed with the challenge
 * @property {string | null} campaign_id - The ID of the campaign. Samples: BRZ8662
 * @property {string | null} [challenge_description] - The description of a challenge concateneting "description + detailedDescription" fields in case the challenge has both fields filled. Samples: Purchase 5 unities of Coronita Extra 210ml within a single order and earn 1,200 points
 * @property {string | null} [challenge_end_date] - Date in which the challenge ends. Samples: 9/30/2020
 * @property {string | null} challenge_goal - Challenge radio button for choosing amount of units or amount of money
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {number | null} [challenge_points] - Number of points to complete the challenge. Samples: 1,500
 * @property {string | null} [challenge_start_date] - Date in which the challenge starts. Samples: 8/1/2020
 * @property {string | null} [challenge_status] - Status of the challenge (from Admin perspective, not from front-end user perspective). Samples: Created / Expired
 * @property {string | null} [challenge_title] - Title of the challenge. Samples: Desafío Coronita
 * @property {string | null} [challenge_type] - The type of the challenge in the given context
 * @property {string | null} cover_image - The cover image of the challenge
 * @property {string} creation_date - Creation date of the challenge formatted as mm/dd/yyyy
 * @property {string | null} [execution_method] - Also known as "type of challenge". Samples: TAKE_PHOTO
 * @property {string | null} good_image - The good image example that is displayed with the challenge
 * @property {string | null} [image_url] - URL in which challenge image is stored. Samples: https://b2bstaticwebsagbprod.blob.core.windows.net/challenge/DO/images/ChallengeCoverPhotoForPresidentePTC.jpg
 * @property {string} poc_filter_type - The type of filter that is selected for the challenge, e.g., DDC, Segment...
 * @property {string | null} poc_filter_value - The value related to the filter type, e.g., group_id, groups, data imputted in the Filter Values field
 * @property {string | null} points - Quantity of points
 * @property {string | null} purchase_type - Only for volume-fixed challenges, it defines if the user needs to achieve the challenge in one or more purchases
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} sku_quantity_max - Maximum quantity of skus that must be purchased in the Volume Purchase - Variable Points challenge
 * @property {string | null} sku_quantity_min - Minimum quantity of skus that must be purchased in the Volume Purchase - Fixed Points and Volume Purchase - Variable Points challenge
 * @property {SkusItem1[] | null} skus - An array of the skus that are contained in the challenge or transaction.
 * @property {string | null} target_type - The type of target that is selected for the challenge, e.g., Individual or General
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef ChallengeCreationStarted
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 */
/**
 * @typedef ChallengeDetailsViewed
 * @property {string | null} [challenge_description] - The description of a challenge concateneting "description + detailedDescription" fields in case the challenge has both fields filled. Samples: Purchase 5 unities of Coronita Extra 210ml within a single order and earn 1,200 points
 * @property {string | null} [challenge_end_date] - Date in which the challenge ends. Samples: 9/30/2020
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {number | null} [challenge_points] - Number of points to complete the challenge. Samples: 1,500
 * @property {string | null} [challenge_start_date] - Date in which the challenge starts. Samples: 8/1/2020
 * @property {string | null} [challenge_status] - Status of the challenge (from Admin perspective, not from front-end user perspective). Samples: Created / Expired
 * @property {string | null} [challenge_title] - Title of the challenge. Samples: Desafío Coronita
 * @property {string | null} [execution_method] - Also known as "type of challenge". Samples: TAKE_PHOTO
 * @property {string | null} [image_url] - URL in which challenge image is stored. Samples: https://b2bstaticwebsagbprod.blob.core.windows.net/challenge/DO/images/ChallengeCoverPhotoForPresidentePTC.jpg
 */
/**
 * @typedef CheckboxInteraction
 * @property {string | null} checkbox_label - The label displayed on the checkbox
 * @property {string | null} checkbox_name - The name of the displayed checkbox
 * @property {string} is_checked - The logic status of the checkbox
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef ContentSearched
 * @property {string | null} record_type - Type of the record in the given context, for example a challenge. Samples: Purchase Challenge - Single, DT COMBO
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} [search_query] - The exact search text input by the user in native language
 */
/**
 * @typedef ContinueButtonClicked
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {string | null} [destination_screen] - Nome of the screen user is navigating to when clicking the CONTINUE button. Samples: Screen 2: Apply Filters
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 */
/**
 * @typedef DefaultPricePerPointUpdated
 * @property {number | null} price_per_point - The price per point
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef EarningRuleCreated
 * @property {Record<string, any> | null} amount - The amount of currency required for the points multipler, e.g., 1x, 2x, 3x
 * @property {string | null} category_id - The id of the Category that is created on Category Admin
 * @property {string | null} earning_rule_description - Description added to the earning rule, e.g., Black Friday, Christmas, 10x Bud
 * @property {string | null} earning_rule_id - The id given to the earning rule after creation
 * @property {string | null} points - Quantity of points
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef SkusItem2
 * @property {string | null} sku - The ID of the inputted sku
 */
/**
 * @typedef EarningRuleSkUsUpdated
 * @property {string | null} current_earning_rule_id - Id of the earning rule that contains the skus before the changes
 * @property {string | null} file_name - Name of the file being uploaded. Samples: file.csv
 * @property {string | null} file_size - Size of the file being uploaded. Samples: 65KB
 * @property {string} operation_type - The type of operation done, e.g., Move, Remove or Add
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {SkusItem2[] | null} skus - An array of the skus that are contained in the challenge or transaction.
 * @property {string | null} skus_quantity - Quantity of skus
 * @property {string | null} target_earning_rule_id - Id of the earning rule that will contain the skus after the changes
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef EarningRuleUpdated
 * @property {Record<string, any> | null} amount - The amount of currency required for the points multipler, e.g., 1x, 2x, 3x
 * @property {string | null} category_id - The id of the Category that is created on Category Admin
 * @property {string | null} earning_rule_description - Description added to the earning rule, e.g., Black Friday, Christmas, 10x Bud
 * @property {string | null} earning_rule_id - The id given to the earning rule after creation
 * @property {string | null} points - Quantity of points
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef ElementClicked
 * @property {string} app_display_name - The current name of the app where the event was triggered.
 * @property {string} app_id - The unique id of the app where the event was triggered
 * @property {string | null} element_label - The element text on the front end with localization.
 * @property {string} element_location_type - The category of the object that anchors the button.
 * @property {string | null} element_name - The element name on the back end.
 * @property {string} element_type - The element type.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} manufacturer_country - The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization.
 * @property {string} page_name - The name of the page where the event was triggered.
 * @property {string | null} page_url - The URL of the page where the event was triggered
 * @property {number | null} poc_count - Number of POCs involved in the transaction.
 * @property {string | null} points - Quantity of points
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} transaction_date - The date when the points transaction was created. Samples: 10/21/2020 14:32
 * @property {string | null} transaction_details_status - The detailed status of the transaction at the time the action was taken (e.g. CSV download).
 * @property {string | null} transaction_id - Unique identifier for the transaction.
 * @property {string | null} transaction_status - The current status of the transaction.
 * @property {string | null} transaction_type - Identification of the transaction created. Samples: Refund
 * @property {string | null} user_country - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country).
 * @property {string | null} user_email - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} user_id - The unique id of the user that performed or caused this action.
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 */
/**
 * @typedef Error
 * @property {string | null} [failure_reason] - Reason why the action failed. Samples: Invalid username or password
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef FileProcessed
 * @property {string} app_display_name - The current name of the app where the event was triggered.
 * @property {string} app_id - The unique id of the app where the event was triggered
 * @property {string} file_format - This specifies the format or type of the file. It should be filled with standard file format abbreviations that indicate the nature and structure of the file's content.
 * @property {boolean | null} file_has_headers - When the file is tabular (CSV, XLS), this indicates if it has headers.
 * @property {number | null} file_number_of_columns - When the file is tabular (CSV, XLS), this indicates the number of columns.
 * @property {number | null} file_number_of_rows - When the file is tabular (CSV, XLS), this indicates the number of rows (headers included).
 * @property {string} file_process_type - This identifies rather the process is a Download (starting point) or an Upload (conclusion point).
 * @property {string | null} file_size - Size of the file being uploaded. Samples: 65KB
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} manufacturer_country - The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization.
 * @property {string} page_name - The name of the page where the event was triggered.
 * @property {string | null} page_url - The URL of the page where the event was triggered
 * @property {string | null} user_country - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country).
 * @property {string | null} user_email - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} user_id - The unique id of the user that performed or caused this action.
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 */
/**
 * @typedef FileRemoved
 * @property {string | null} file_name - Name of the file being uploaded. Samples: file.csv
 * @property {string} file_type - The type of the file that was removed, e.g., CSV
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef FileUploaded
 * @property {string | null} file_name - Name of the file being uploaded. Samples: file.csv
 * @property {string} file_type - The type of the file that was removed, e.g., CSV
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef FilterValueAdded
 * @property {string | null} [challenge_filter_name] - Name of the filter. Samples: DDC
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {string | null} [value_added] - Value typed by the user in the field. Samples: Caxias
 */
/**
 * @typedef GroupCreated
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {string | null} file_name - Name of the file being uploaded. Samples: file.csv
 * @property {string | null} file_size - Size of the file being uploaded. Samples: 65KB
 * @property {string | null} [group_id] - ID generated for the group created. Samples: 1596202772628
 * @property {string | null} [group_name] - Name provided by the user to the group. Samples: POCs Northeast Region
 * @property {string | null} [group_size] - Number of records inside the file uploaded. Samples: 325
 */
/**
 * @typedef GroupUploaded
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {string | null} file_name - Name of the file being uploaded. Samples: file.csv
 * @property {string | null} file_size - Size of the file being uploaded. Samples: 65KB
 * @property {string | null} [group_name] - Name provided by the user to the group. Samples: POCs Northeast Region
 */
/**
 * @typedef ImageDeleted
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {string | null} [execution_method] - Also known as "type of challenge". Samples: TAKE_PHOTO
 * @property {string | null} [image_description] - Indicates what the image refers to. Samples: Challenge Image / Bad Photo / Good Photo
 * @property {string | null} [image_name] - Name of the file uploaded. Samples: image.jpg
 * @property {string | null} [image_size] - Size of the file uploaded. Samples: 826KB
 * @property {string | null} [image_type] - File extension. Samples: .JPG
 */
/**
 * @typedef ImageUploaded
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {string | null} [execution_method] - Also known as "type of challenge". Samples: TAKE_PHOTO
 * @property {string | null} [image_description] - Indicates what the image refers to. Samples: Challenge Image / Bad Photo / Good Photo
 * @property {string | null} [image_name] - Name of the file uploaded. Samples: image.jpg
 * @property {string | null} [image_size] - Size of the file uploaded. Samples: 826KB
 * @property {string | null} [image_type] - File extension. Samples: .JPG
 * @property {string | null} [image_url] - URL in which challenge image is stored. Samples: https://b2bstaticwebsagbprod.blob.core.windows.net/challenge/DO/images/ChallengeCoverPhotoForPresidentePTC.jpg
 */
/**
 * @typedef LinkClicked
 * @property {string | null} link_label - The labeled displayed for the clicked link
 * @property {string | null} link_name - The name of the clicked link
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef CombosItem
 * @property {string | null} refund_combo_id - Id of the combo in the Refund operation. Samples: DT001
 * @property {number | null} refund_combo_points - Points refunded for that combo. Samples: 1500
 * @property {number | null} refund_combo_quantity - Quantity refunded for that combo. Samples: 3
 */
/**
 * @typedef ManualRefundCreated
 * @property {CombosItem[] | null} combos - Array of combos
 * @property {string | null} order_date - The date the order was created. It's a date
 * @property {string | null} order_id - ID of the order. Samples: 20006886
 * @property {string | null} order_status - The status of the order. Samples: 'CONFIRMED', 'CANCELLED'
 * @property {string | null} poc_id - ID of the POC. Samples: 11544668000135
 * @property {string | null} transaction_type - Identification of the transaction created. Samples: Refund
 */
/**
 * @typedef CombosItem1
 * @property {string | null} refund_combo_id - Id of the combo in the Refund operation. Samples: DT001
 * @property {number | null} refund_combo_points - Points refunded for that combo. Samples: 1500
 * @property {number | null} refund_combo_quantity - Quantity refunded for that combo. Samples: 3
 */
/**
 * @typedef ManualRefundTransactionCreated
 * @property {CombosItem1[] | null} combos - Array of combos
 * @property {string | null} order_date - The date the order was created. It's a date
 * @property {string | null} order_id - ID of the order. Samples: 20006886
 * @property {string | null} order_status - The status of the order. Samples: 'CONFIRMED', 'CANCELLED'
 * @property {string | null} original_poc_id - ID of the POC that is the target of the points transaction. Samples: 11544668000135
 * @property {string | null} transaction_date - The date when the points transaction was created. Samples: 10/21/2020 14:32
 * @property {string | null} transaction_type - Identification of the transaction created. Samples: Refund
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef MenuInteraction
 * @property {string | null} [menu_category] - Options in the left side nav bar that represent features or group of features. Samples: Create a Challenge / Points Management
 * @property {string | null} [menu_subcategory] - Options in the left side nav bar that usually represent features being grouped inside a main category. Samples: Create Points Transaction
 */
/**
 * @typedef ModalExited
 * @property {string | null} modal_name - Name of the modal. Samples: 'Review IDs', 'Rewards Offer'
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 */
/**
 * @typedef PageRowChanged
 * @property {string | null} rows_per_page - The number of rows per page selected
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef PageViewed
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef PaginationClicked
 * @property {string | null} interaction_type - The type of interaction the user made, e.g., going back to a previous page, advancing to next, first page, last page
 * @property {string | null} page_number - The number of the page when interacted with
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef CombosItem2
 * @property {string | null} refund_combo_id - Id of the combo in the Refund operation. Samples: DT001
 * @property {number | null} refund_combo_points - Points refunded for that combo. Samples: 1500
 * @property {number | null} refund_combo_quantity - Quantity refunded for that combo. Samples: 3
 */
/**
 * @typedef PocListsItem
 * @property {string | null} poc_id - The ID of the POC
 */
/**
 * @typedef ProductCategoryItem1
 * @property {string | null} dimension - The strategy, or dimension, related to the transaction being created. Examples BEER, NAB, and/or Marketplace.
 */
/**
 * @typedef SkusItem3
 * @property {string | null} sku - The ID of the inputted sku
 */
/**
 * @typedef PointsTransactionCreated
 * @property {string | null} campaign_id - The ID of the campaign. Samples: BRZ8662
 * @property {CombosItem2[] | null} combos - Array of combos
 * @property {string | null} description - The description of the Offer. Samples: 'Points awarded regarding Braze campaign #BRZ8662'
 * @property {string | null} file_name - Name of the file being uploaded. Samples: file.csv
 * @property {string | null} file_size - Size of the file being uploaded. Samples: 65KB
 * @property {string | null} [menu_category] - Options in the left side nav bar that represent features or group of features. Samples: Create a Challenge / Points Management
 * @property {string | null} order_date - The date the order was created. It's a date
 * @property {string | null} order_id - ID of the order. Samples: 20006886
 * @property {string | null} order_status - The status of the order. Samples: 'CONFIRMED', 'CANCELLED'
 * @property {string | null} original_poc_id - ID of the POC that is the target of the points transaction. Samples: 11544668000135
 * @property {string | null} poc_amount - Identify whether the user makes adjustments to a single_poc or for multiple_poc
 * @property {PocListsItem[] | null} poc_lists - The array of pocs contained in the file that was used for the points transaction
 * @property {number | null} points_adjustment - Amount of points that was awarded or removed in that transaction. Samples: 4000, 250, -4000
 * @property {string | null} points_transaction_description - The Description of the points transaction
 * @property {ProductCategoryItem1[] | null} product_category - The array of product category or dimesions. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} reference_month - The year of the campaign
 * @property {string | null} reference_year - The month of the campaign
 * @property {string | null} removal_reason - It specifies the reason for removing the points, example Suspicious activity, Points added incorrectly, System error correction, Refund error correction, Expired points, and Other
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {SkusItem3[] | null} skus - An array of the skus that are contained in the challenge or transaction.
 * @property {string | null} target_poc_id - The ID of the POC that is receiving the transfered points
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} transaction_category - The category of the points transaction. Available for service utility. Samples: Electricity Bill, Aster TV
 * @property {string | null} transaction_date - The date when the points transaction was created. Samples: 10/21/2020 14:32
 * @property {string | null} transaction_type - Identification of the transaction created. Samples: Refund
 * @property {string | null} transfered_poc_id - ID of the POC who is receiving transfered points. Samples: 11544668000135
 * @property {string | null} transparency_id - The ID created by the Transparency
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef ProgramCombosItem
 * @property {string | null} program_combo_id - ID of the DT  program Combo. Samples: DT000068864
 * @property {number | null} program_combo_points - Quantity of points linked to the DT Combo. Samples: 850, 900
 */
/**
 * @typedef ProgramCombosAdded
 * @property {ProgramCombosItem[] | null} program_combos - Array to accommodate the combos of Rewards Program
 * @property {string | null} program_id - ID of the program. Samples: 1, 5
 */
/**
 * @typedef QuantityAdded
 * @property {string | null} field_name - Name of the interacted field that contains the quantity, e.g., Sku Quantity
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {string | null} quantity - The number that was imputted by the user in the quantity field
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef RadioButtonInteraction
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {string | null} radio_button_label - Label of the radio button that has been interacted with, e.g., General
 * @property {string | null} radio_button_name - Name of the radio button that has been interacted with, e.g., General Target
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef ItemsItem
 * @property {string | null} item_id - Id of the DT Combo
 * @property {string | null} item_name - Name of the group being added
 * @property {string | null} points - Quantity of points
 */
/**
 * @typedef RecordAdded
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {ItemsItem[] | null} [items] - Array of items
 * @property {string | null} items_quantity - The sum of items added in the event
 * @property {string | null} record_type - Type of the record in the given context, for example a challenge. Samples: Purchase Challenge - Single, DT COMBO
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef RecordDeleted
 * @property {string | null} record_id - The ID of the record. For example the Challenge ID. Samples: 1535
 * @property {string | null} record_type - Type of the record in the given context, for example a challenge. Samples: Purchase Challenge - Single, DT COMBO
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef RecordUpdated
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {string | null} record_additional_info - Additional information used to identify what screen or section was the record related to. Samples: Challenge Setup
 * @property {string | null} record_id - The ID of the record. For example the Challenge ID. Samples: 1535
 * @property {string | null} record_type - Type of the record in the given context, for example a challenge. Samples: Purchase Challenge - Single, DT COMBO
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef RedeemableCreated
 * @property {number | null} price_per_point - The price per point
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} sku - The ID of the inputted sku
 * @property {string | null} sku_name - The name of SKU
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef RedeemablePricePerPointUpdated
 * @property {number | null} price_per_point - The price per point
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} sku - The ID of the inputted sku
 * @property {string | null} sku_name - The name of SKU
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef RewardsOfferCreated
 * @property {number | null} awarded_points - Amount of points awarded in that transaction. Samples: 4000, 2500
 * @property {string | null} campaign_id - The ID of the campaign. Samples: BRZ8662
 * @property {string | null} file_name - Name of the file being uploaded. Samples: file.csv
 * @property {string | null} file_size - Size of the file being uploaded. Samples: 65KB
 * @property {string | null} offer_description - The description of the Offer. Samples: 'Points awarded regarding Braze campaign #BRZ8662'
 * @property {string | null} poc_id - ID of the POC. Samples: 11544668000135
 * @property {number | null} poc_list - Number of records inside the file uploaded. Samples: 3500, 2000
 * @property {string | null} submitted_id - Id of the transparency collection available only for Multiple POCs operations. Samples: 123456789
 * @property {string | null} transaction_type - Identification of the transaction created. Samples: Refund
 */
/**
 * @typedef SelectFilter
 * @property {string | null} [challenge_filter_name] - Name of the filter. Samples: DDC
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 */
/**
 * @typedef SelectInteraction
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} select_label - Label of the select element that has been interacted with, e.g.,+ Add New Group
 * @property {string | null} select_name - Name of the select element that has been interacted with, e.g., Add New Group - Individual Target
 * @property {string | null} selected_value - Name of the selected item inside the select element, e.g., + Add New Group
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef SignInFailed
 * @property {string | null} [failure_reason] - Reason why the action failed. Samples: Invalid username or password
 */
/**
 * @typedef SkuAdded
 * @property {string | null} [challenge_sku] - SKU provided by the user. Samples: 0020531
 * @property {string | null} earning_sku - SKU added by the user to an Earning Rule. Samples: 0020531
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} sku - The ID of the inputted sku
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef SkuQuantityAdded
 * @property {string | null} [challenge_sku] - SKU provided by the user. Samples: 0020531
 * @property {string | null} [challenge_sku_quantity] - Quantity for the SKU provided by the user. Samples: 15
 * @property {string | null} form_name - Name of the displayed form, e.g., Challenge Creation - Step 2 - Filters
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} sku - The ID of the inputted sku
 * @property {string | null} sku_quantity - The quantity required for the inputted sku
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef SubmitButtonClicked
 * @property {string | null} [challenge_id] - Identification of the challenge either generated automatically by the application or provided by the user in case of challenges integrated to Digital POC. Samples: 1596202772628 / 6835
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef FilterOptionsItem
 * @property {string | null} filter_option - Name of the filter option used for filtering, e.g., Photo Challenge, Volume Challenge - Fixed Points
 */
/**
 * @typedef TableFiltered
 * @property {string} filter_category - The category of filter used for filtering
 * @property {FilterOptionsItem[] | null} filter_options - The options available for filtering, e.g., Photo Challenge, Volume Challenge - Fixed Points
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} table_name - The name of the table containing the displayed data
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef TableItemsPerPageChanged
 * @property {string | null} items_per_page - The number of items displayed per page
 * @property {string | null} items_total - The total number of items inside the table
 * @property {string | null} page_number - The number of the page when interacted with
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} table_name - The name of the table containing the displayed data
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef TableSearched
 * @property {string | null} content_type - The type of the updated record, e.g., Earning Rule
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} [search_query] - The exact search text input by the user in native language
 * @property {string | null} table_name - The name of the table containing the displayed data
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef TableSorted
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} sort_criteria - The name of the column that was used for filtering the results
 * @property {string | null} sort_type - The type of filter appled, e.g., Ascending, Descending
 * @property {string | null} table_name - The name of the table containing the displayed data
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef TableViewed
 * @property {string | null} items_per_page - The number of items displayed per page
 * @property {string | null} items_total - The total number of items inside the table
 * @property {string | null} page_number - The number of the page when interacted with
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} table_name - The name of the table containing the displayed data
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef TierConfigurationUpdated
 * @property {string | null} earn_limit - The monthly earning limit of points for the tier
 * @property {string | null} initial_balance - The initial balance set for the tier
 * @property {string | null} redeem_limit - The maximum quantity of DT Combos that can be redeemed each time for the tier
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */
/**
 * @typedef UndoClicked
 * @property {string | null} entity - The type of the entity, e.g Challenge. Volume Purchase...
 * @property {string | null} entity_id - Entity ID (e.g. SKU, POC_ID)
 * @property {string | null} operation - The type of operation done, e.g., Move, Edit, Remove or Add
 * @property {string | null} [screen_name] - Name of the screen in which the button is placed. Samples: Apply Filters
 * @property {string | null} tier - Name of the selected tier
 * @property {string | null} user - E-mail of the user that triggered the event
 * @property {string | null} vendor - Name of the selected vendor
 * @property {string | null} zone - The zone where the points transaction was created
 */

/**
 * User clicks “BACK” button to navigate between challenge creation process steps
 *
 * @param {BackButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function backButtonClicked(
	props: BackButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Back Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when a bulk points transaction is created inside Points Management
 *
 * @param {BulkPointsTransactionCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bulkPointsTransactionCreated(
	props: BulkPointsTransactionCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bulk Points Transaction Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks a button
 *
 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonClicked(
	props: ButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the ser successfully cancels the challenge
 *
 * @param {ChallengeCancelled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function challengeCancelled(
	props: ChallengeCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Challenge Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks “CONFIRM” in the confirmation modal to confirm challenge creation
 *
 * @param {ChallengeCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function challengeCreated(
	props: ChallengeCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Challenge Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks “+ CREATE NEW CHALLENGE” button
 *
 * @param {ChallengeCreationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function challengeCreationStarted(
	props: ChallengeCreationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Challenge Creation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks “View” to see the details of any Challenge
 *
 * @param {ChallengeDetailsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function challengeDetailsViewed(
	props: ChallengeDetailsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Challenge Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the user interacts with a checkbox
 *
 * @param {CheckboxInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkboxInteraction(
	props: CheckboxInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkbox Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User searches for a content using a search box
 *
 * @param {ContentSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentSearched(
	props: ContentSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks “CONTINUE” button to navigate between challenge creation process steps
 *
 * @param {ContinueButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function continueButtonClicked(
	props: ContinueButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Continue Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be triggered when the price per point is updated
 *
 * @param {DefaultPricePerPointUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function defaultPricePerPointUpdated(
	props: DefaultPricePerPointUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Default Price Per Point Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when a Earning Rule is created. It contains all information used in it's creation.
 *
 * @param {EarningRuleCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function earningRuleCreated(
	props: EarningRuleCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Earning Rule Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when a Earning Rule has a change in it's skus
 *
 * @param {EarningRuleSkUsUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function earningRuleSkUsUpdated(
	props: EarningRuleSkUsUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Earning Rule SKUs Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the earning rule data is modified
 *
 * @param {EarningRuleUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function earningRuleUpdated(
	props: EarningRuleUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Earning Rule Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when a user clicks in na element to view details or perform actions.
 *
 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementClicked(
	props: ElementClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Element Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * An error occurs across the entire application when trying to perform any action
 *
 * @param {Error} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function error(
	props: Error,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Error', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Event triggered when a file is processed. This could be a file download or a file upload.
 *
 * @param {FileProcessed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileProcessed(
	props: FileProcessed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'File Processed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be triggered whenever the user removes a file from a form
 *
 * @param {FileRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileRemoved(
	props: FileRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'File Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be triggered whenever the user uploads a file to a form
 *
 * @param {FileUploaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileUploaded(
	props: FileUploaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'File Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A new value is added to the field “Filter Values” corresponding to a filter
 *
 * @param {FilterValueAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filterValueAdded(
	props: FilterValueAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Filter Value Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The group of POCs is successfully created by the service during challenge setup
 *
 * @param {GroupCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function groupCreated(
	props: GroupCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Group Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User user selects a file to upload a group of POCs during challenge setup
 *
 * @param {GroupUploaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function groupUploaded(
	props: GroupUploaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Group Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the “X” button to remove the main challenge image
 *
 * @param {ImageDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function imageDeleted(
	props: ImageDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Image Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * We receive success confirmation from the file-management service to upload the image
 *
 * @param {ImageUploaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function imageUploaded(
	props: ImageUploaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Image Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when a link is clicked
 *
 * @param {LinkClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function linkClicked(
	props: LinkClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Link Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User successfully logs into Membership Admin
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function login(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Login', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User creates a new transaction of Refund type
 *
 * @param {ManualRefundCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function manualRefundCreated(
	props: ManualRefundCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Manual Refund Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when a manual refund transaction is created inside Points Management
 *
 * @param {ManualRefundTransactionCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function manualRefundTransactionCreated(
	props: ManualRefundTransactionCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Manual Refund Transaction Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks any main option in the left side navigation bar
 *
 * @param {MenuInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteraction(
	props: MenuInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User as a modal open and clicks out of it to close the modal
 *
 * @param {ModalExited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modalExited(
	props: ModalExited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Modal Exited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the user selects a new number of results displayed per page
 *
 * @param {PageRowChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageRowChanged(
	props: PageRowChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Row Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User lands to Challenge History screen after logging-in
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the user interacts with the pagination
 *
 * @param {PaginationClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paginationClicked(
	props: PaginationClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pagination Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when a single points transaction is created inside Points Management
 *
 * @param {PointsTransactionCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pointsTransactionCreated(
	props: PointsTransactionCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Points Transaction Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * New DT Combos are successfully added to the Rewards Program
 *
 * @param {ProgramCombosAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programCombosAdded(
	props: ProgramCombosAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Program Combos Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the user fills a quantity field
 *
 * @param {QuantityAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function quantityAdded(
	props: QuantityAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Quantity Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when a radio button is clicked by the user
 *
 * @param {RadioButtonInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function radioButtonInteraction(
	props: RadioButtonInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Radio Button Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the user adds records to DT Combos or Groups
 *
 * @param {RecordAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recordAdded(
	props: RecordAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Record Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Any record is removed, for example a challenge or a DT combo
 *
 * @param {RecordDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recordDeleted(
	props: RecordDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Record Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates any record, for example a challenge or a DT Combo
 *
 * @param {RecordUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recordUpdated(
	props: RecordUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Record Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be triggered when the redeemable is created
 *
 * @param {RedeemableCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function redeemableCreated(
	props: RedeemableCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Redeemable Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be triggered when the redeemable price per point is updated
 *
 * @param {RedeemablePricePerPointUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function redeemablePricePerPointUpdated(
	props: RedeemablePricePerPointUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Redeemable Price Per Point Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User creates a new transaction of Rewards Offer type
 *
 * @param {RewardsOfferCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rewardsOfferCreated(
	props: RewardsOfferCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Rewards Offer Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects a value in “Filter” dropdown within 2nd step of challenge creation
 *
 * @param {SelectFilter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selectFilter(
	props: SelectFilter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Select Filter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when a select is interacted with the user
 *
 * @param {SelectInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selectInteraction(
	props: SelectInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Select Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User fails to login and an error happens
 *
 * @param {SignInFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signInFailed(
	props: SignInFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sign In Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User adds a SKU for Purchase Challenge in the 3rd step of challenge creation
 *
 * @param {SkuAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuAdded(
	props: SkuAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Sku Added', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Event that is triggered when an sku has a quantity filled
 *
 * @param {SkuQuantityAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuQuantityAdded(
	props: SkuQuantityAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sku Quantity Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks “SUBMIT” button to complete a challenge creation
 *
 * @param {SubmitButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function submitButtonClicked(
	props: SubmitButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Submit Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the user filter a table
 *
 * @param {TableFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tableFiltered(
	props: TableFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Table Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the number of items per page is changed
 *
 * @param {TableItemsPerPageChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tableItemsPerPageChanged(
	props: TableItemsPerPageChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Table Items per Page Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the user makes a search in a table
 *
 * @param {TableSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tableSearched(
	props: TableSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Table Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the user sort a table
 *
 * @param {TableSorted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tableSorted(
	props: TableSorted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Table Sorted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when a table is displayed
 *
 * @param {TableViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tableViewed(
	props: TableViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Table Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when the tier configuration is changed
 *
 * @param {TierConfigurationUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tierConfigurationUpdated(
	props: TierConfigurationUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tier Configuration Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event that is triggered when a the undo button is clicked
 *
 * @param {UndoClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function undoClicked(
	props: UndoClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Undo Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * User clicks “BACK” button to navigate between challenge creation process steps
	 *
	 * @param {BackButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	backButtonClicked,
	/**
	 * Event triggered when a bulk points transaction is created inside Points Management
	 *
	 * @param {BulkPointsTransactionCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bulkPointsTransactionCreated,
	/**
	 * User clicks a button
	 *
	 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	buttonClicked,
	/**
	 * When the ser successfully cancels the challenge
	 *
	 * @param {ChallengeCancelled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	challengeCancelled,
	/**
	 * User clicks “CONFIRM” in the confirmation modal to confirm challenge creation
	 *
	 * @param {ChallengeCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	challengeCreated,
	/**
	 * User clicks “+ CREATE NEW CHALLENGE” button
	 *
	 * @param {ChallengeCreationStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	challengeCreationStarted,
	/**
	 * User clicks “View” to see the details of any Challenge
	 *
	 * @param {ChallengeDetailsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	challengeDetailsViewed,
	/**
	 * Event that is triggered when the user interacts with a checkbox
	 *
	 * @param {CheckboxInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkboxInteraction,
	/**
	 * User searches for a content using a search box
	 *
	 * @param {ContentSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentSearched,
	/**
	 * User clicks “CONTINUE” button to navigate between challenge creation process steps
	 *
	 * @param {ContinueButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	continueButtonClicked,
	/**
	 * This event should be triggered when the price per point is updated
	 *
	 * @param {DefaultPricePerPointUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	defaultPricePerPointUpdated,
	/**
	 * Event that is triggered when a Earning Rule is created. It contains all information used in it's creation.
	 *
	 * @param {EarningRuleCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	earningRuleCreated,
	/**
	 * Event that is triggered when a Earning Rule has a change in it's skus
	 *
	 * @param {EarningRuleSkUsUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	earningRuleSkUsUpdated,
	/**
	 * Event that is triggered when the earning rule data is modified
	 *
	 * @param {EarningRuleUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	earningRuleUpdated,
	/**
	 * Event triggered when a user clicks in na element to view details or perform actions.
	 *
	 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	elementClicked,
	/**
	 * An error occurs across the entire application when trying to perform any action
	 *
	 * @param {Error} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	error,
	/**
	 * Event triggered when a file is processed. This could be a file download or a file upload.
	 *
	 * @param {FileProcessed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileProcessed,
	/**
	 * This event should be triggered whenever the user removes a file from a form
	 *
	 * @param {FileRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileRemoved,
	/**
	 * This event should be triggered whenever the user uploads a file to a form
	 *
	 * @param {FileUploaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileUploaded,
	/**
	 * A new value is added to the field “Filter Values” corresponding to a filter
	 *
	 * @param {FilterValueAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filterValueAdded,
	/**
	 * The group of POCs is successfully created by the service during challenge setup
	 *
	 * @param {GroupCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	groupCreated,
	/**
	 * User user selects a file to upload a group of POCs during challenge setup
	 *
	 * @param {GroupUploaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	groupUploaded,
	/**
	 * User clicks the “X” button to remove the main challenge image
	 *
	 * @param {ImageDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	imageDeleted,
	/**
	 * We receive success confirmation from the file-management service to upload the image
	 *
	 * @param {ImageUploaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	imageUploaded,
	/**
	 * Event that is triggered when a link is clicked
	 *
	 * @param {LinkClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	linkClicked,
	/**
	 * User successfully logs into Membership Admin
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	login,
	/**
	 * User creates a new transaction of Refund type
	 *
	 * @param {ManualRefundCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	manualRefundCreated,
	/**
	 * Event triggered when a manual refund transaction is created inside Points Management
	 *
	 * @param {ManualRefundTransactionCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	manualRefundTransactionCreated,
	/**
	 * User clicks any main option in the left side navigation bar
	 *
	 * @param {MenuInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteraction,
	/**
	 * User as a modal open and clicks out of it to close the modal
	 *
	 * @param {ModalExited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	modalExited,
	/**
	 * Event that is triggered when the user selects a new number of results displayed per page
	 *
	 * @param {PageRowChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageRowChanged,
	/**
	 * User lands to Challenge History screen after logging-in
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Event that is triggered when the user interacts with the pagination
	 *
	 * @param {PaginationClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paginationClicked,
	/**
	 * Event triggered when a single points transaction is created inside Points Management
	 *
	 * @param {PointsTransactionCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pointsTransactionCreated,
	/**
	 * New DT Combos are successfully added to the Rewards Program
	 *
	 * @param {ProgramCombosAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	programCombosAdded,
	/**
	 * Event that is triggered when the user fills a quantity field
	 *
	 * @param {QuantityAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	quantityAdded,
	/**
	 * Event that is triggered when a radio button is clicked by the user
	 *
	 * @param {RadioButtonInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	radioButtonInteraction,
	/**
	 * Event that is triggered when the user adds records to DT Combos or Groups
	 *
	 * @param {RecordAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recordAdded,
	/**
	 * Any record is removed, for example a challenge or a DT combo
	 *
	 * @param {RecordDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recordDeleted,
	/**
	 * User updates any record, for example a challenge or a DT Combo
	 *
	 * @param {RecordUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recordUpdated,
	/**
	 * This event should be triggered when the redeemable is created
	 *
	 * @param {RedeemableCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	redeemableCreated,
	/**
	 * This event should be triggered when the redeemable price per point is updated
	 *
	 * @param {RedeemablePricePerPointUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	redeemablePricePerPointUpdated,
	/**
	 * User creates a new transaction of Rewards Offer type
	 *
	 * @param {RewardsOfferCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rewardsOfferCreated,
	/**
	 * User selects a value in “Filter” dropdown within 2nd step of challenge creation
	 *
	 * @param {SelectFilter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selectFilter,
	/**
	 * Event that is triggered when a select is interacted with the user
	 *
	 * @param {SelectInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selectInteraction,
	/**
	 * User fails to login and an error happens
	 *
	 * @param {SignInFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signInFailed,
	/**
	 * User adds a SKU for Purchase Challenge in the 3rd step of challenge creation
	 *
	 * @param {SkuAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuAdded,
	/**
	 * Event that is triggered when an sku has a quantity filled
	 *
	 * @param {SkuQuantityAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuQuantityAdded,
	/**
	 * User clicks “SUBMIT” button to complete a challenge creation
	 *
	 * @param {SubmitButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	submitButtonClicked,
	/**
	 * Event that is triggered when the user filter a table
	 *
	 * @param {TableFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tableFiltered,
	/**
	 * Event that is triggered when the number of items per page is changed
	 *
	 * @param {TableItemsPerPageChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tableItemsPerPageChanged,
	/**
	 * Event that is triggered when the user makes a search in a table
	 *
	 * @param {TableSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tableSearched,
	/**
	 * Event that is triggered when the user sort a table
	 *
	 * @param {TableSorted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tableSorted,
	/**
	 * Event that is triggered when a table is displayed
	 *
	 * @param {TableViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tableViewed,
	/**
	 * Event that is triggered when the tier configuration is changed
	 *
	 * @param {TierConfigurationUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tierConfigurationUpdated,
	/**
	 * Event that is triggered when a the undo button is clicked
	 *
	 * @param {UndoClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	undoClicked,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_1g6cvSqejCGnhxYjNkYKaXuDH9S`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
