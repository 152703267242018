import { Grid } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { SearchConfigs } from '../model/SearchConfigs'
import TableSearchInput from '../TableSearchInput'
import styles from './styles'

type Props = {
	customHeader: ReactNode
	searchConfigs?: SearchConfigs
}

const TableSearchInputContainer: React.FC<Props> = ({ customHeader, searchConfigs }) => {
	const classes = styles()

	return (
		<Grid container spacing={2} className={classes.searchContainer} alignItems="center">
			{searchConfigs && (
				<Grid item xs={5}>
					<TableSearchInput
						id={searchConfigs?.id}
						placeholder={searchConfigs?.placeholder}
						searchBy={searchConfigs?.searchBy}
						hasCustomSearch={searchConfigs && !!searchConfigs.customSearch}
						trackProps={searchConfigs?.trackProps}
					/>
				</Grid>
			)}
			{customHeader && (
				<Grid item xs={7}>
					{customHeader}
				</Grid>
			)}
		</Grid>
	)
}

export default TableSearchInputContainer
