import React from 'react'
import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@material-ui/core/RadioGroup'
import { Radio, FormControlLabel, FormHelperText } from '@material-ui/core'
import InputLabel from 'components/formik/InputLabel'
import FieldErrorMessage from 'components/formik/FieldErrorMessage'
import styles from '../../formik/styles'

export type RadioGroupProps = {
	id: string
	label?: string
	helperText?: string
	errorText?: string
	options: Array<{ value: string; label: string }>
	disabled?: boolean
} & MuiRadioGroupProps

const RadioGroup: React.ForwardRefRenderFunction<React.Ref<typeof MuiRadioGroup>, RadioGroupProps> = (
	{ id, label, helperText, errorText, options, disabled, ...rest },
	ref,
) => {
	const classes = styles({})
	const hasError = !!errorText

	return (
		<div className={classes.box}>
			{label && <InputLabel label={label} htmlFor={id} error={hasError} bottomSpacing disabled={disabled} />}
			{hasError && <FieldErrorMessage id={id} message={errorText} />}
			<MuiRadioGroup id={id} ref={ref} {...rest}>
				{options.map(({ value: optValue, label: optLabel }) => (
					<FormControlLabel key={optValue} value={optValue} control={<Radio />} label={optLabel} disabled={disabled} />
				))}
			</MuiRadioGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</div>
	)
}

export default React.forwardRef(RadioGroup)
