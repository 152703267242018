/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Papa from 'papaparse'
import FileSaver from 'file-saver'
import { SUPPORTED_FILE_TYPE_IMAGE } from './constants'

/**
 * CSV default delimiter
 * */
export const CSV_DELIMITER = ';'

/**
 * CSV default empty line
 */
export const CSV_NEW_LINE = '\r\n'

/**
 * TYPE CSV TEXT FILE
 */
export const TEXT_CSV_TYPE = 'text/csv'

/**
 * @description Unparse JSON to CSV and get the file string
 * @param objectToParse Pass an array of objects
 * @param extractColumns Use this parameter to get only the columns that you want in the file
 * @param headers Use this parameter only when you want to rewrite the headers. Example: ['POC_ID', 'CAMPAIGN_ID', 'POINTS']
 */
const unParse = (objectToParse: any[], extractColumns?: string[], headers?: string[]): string => {
	let fileString = Papa.unparse(objectToParse, {
		header: !headers?.length,
		skipEmptyLines: true,
		columns: extractColumns,
		delimiter: CSV_DELIMITER,
		newline: CSV_NEW_LINE,
	})

	const newHeaders = headers?.join(CSV_DELIMITER)
	if (newHeaders) fileString = `${newHeaders}${CSV_NEW_LINE}${fileString}`

	return fileString
}

/**
 * @description Download file
 * @param fileString Object to convert to a file to be downloaded
 * @param fileName String parameter to define the name of the file. Example: pocId.csv
 * @param fileType Type of the file to be downloaded: Example: { type: 'text/csv' }
 */
const saveAs = (fileString: string, fileName: string, fileType: { type: string }): void => {
	const blob = new Blob([fileString], fileType)

	FileSaver.saveAs(blob, fileName)
}

/**
 * Create a File instance with name, extension and type extracted from a given url
 * @param url
 * @returns File instance
 */
const getFileFromUrl = (url: string | undefined): File | undefined => {
	if (url) {
		const imageUrl = url.split('/')
		const regex = `.${SUPPORTED_FILE_TYPE_IMAGE.map((sup) => sup.split('/')[1]).join('|.')}`
		const endIndex = imageUrl[imageUrl.length - 1].search(new RegExp(regex))
		const fileName = imageUrl[imageUrl.length - 1].slice(0, endIndex + 4)

		return new File([], fileName, { type: 'image/jpg' })
	}

	return undefined
}

/**
 * Create an array with a File instances with name, extension and type extracted from a given url
 * @param url
 * @returns File array
 */
const getFileArrayFromUrl = (url: string | undefined): File[] | undefined => {
	const file = getFileFromUrl(url)
	return file ? [file] : undefined
}

export { unParse, saveAs, getFileFromUrl, getFileArrayFromUrl }
