import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const CreateRuleActionsStyle = makeStyles((theme: Theme) =>
	createStyles({
		actionsContainer: {
			justifyContent: 'flex-end',
			padding: '0px',
			'& > *': {
				marginRight: theme.spacing(1),
				'&:last-child': {
					marginRight: 'unset',
				},
			},
		},
		button: {
			width: '110px',
			height: '40px',
		},
	}),
)

export default CreateRuleActionsStyle
