import { SettingsLevel } from '@/domains/enums/settings'
import { ChangeConfigParams, updateConfiguration } from '@/services/settings/SettingsService'
import { isToggleSettingsConfigByZoneEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { AxiosResponse } from 'axios'

export type UpdateRedeemParams = {
	redeemLimit: number
	selectedTierKey: string
	vendorId: string
	country: string | null
}

export async function execute({
	redeemLimit,
	selectedTierKey,
	vendorId,
	country,
}: UpdateRedeemParams): Promise<AxiosResponse> {
	const body = {
		redeemLimit,
	}

	const level = isToggleSettingsConfigByZoneEnabled() ? SettingsLevel.ZONE : SettingsLevel.VENDOR
	const params: ChangeConfigParams = {
		settingsId: isToggleSettingsConfigByZoneEnabled() ? country! : vendorId,
		level,
		tiers: [selectedTierKey],
	}
	return updateConfiguration(params, body)
}
