import React, { useState, useMemo } from 'react'
import SkuForm from '@/domains/sku/SkuForm'
import { useStoreMap } from 'effector-react'
import { RulesStore } from '@/stores'
import ProductColumn from '../RulesRedesign/Components/ProductColumn/ProductColumn'
import DeleteSkuIcon from '../RulesRedesign/Components/DeleteSkuIcon/DeleteSkuIcon'

export interface SkuListDataTableResult {
	dataSkuList: SkuForm[]
	setSkuListFilter: React.Dispatch<React.SetStateAction<string>>
}

const useSkuListDataTable = (hasDelete = false): SkuListDataTableResult => {
	const [skuListFilter, setSkuListFilter] = useState('')

	const activeSkuList = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => state.activeSkuList,
	})

	const dataSkuList = useMemo(() => {
		if (activeSkuList) {
			const skus = activeSkuList
				?.map((sku: SkuForm) => ({
					...sku,
					product: <ProductColumn sku={sku} />,
					delete: hasDelete && sku.name ? <DeleteSkuIcon sku={sku} /> : null,
					skuId: sku.id,
				}))
				.filter((sku) => sku.name.includes(skuListFilter) || sku.id.includes(skuListFilter))

			return skus
		}
		return []
	}, [activeSkuList, skuListFilter, hasDelete])

	return { dataSkuList, setSkuListFilter }
}

export default useSkuListDataTable
