import { usePricePerPointChanged } from '@/routes/redeemableItems/hooks/usePricePerPointChanged'
import { $pricePerPointConfigurationStore, PricePerPointConfigurationStoreEvents } from '@/stores'
import { Button, Dialog, Grid, Heading, Image } from '@hexa-ui/components'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useStore } from 'effector-react'
import React, { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { PricePerPointEditorContent } from './PricePerPointEditorContent'
import PricePerPointEditorFormProvider from './PricePerPointEditorFormProvider'
import { usePricePerPointEditor, UsePricePerPointEditorResult } from './usePricePerPointEditor'

import PricePerPointEditorStyle from './PricePerPointEditorStyle'

type PricePerPointEditorProps = {
	editButton: React.ReactNode
}

export const PricePerPointEditor: React.FC<PricePerPointEditorProps> = ({ editButton }) => {
	const pppHookProvider = usePricePerPointEditor()
	const { isOpen } = useStore($pricePerPointConfigurationStore)
	const toast = useToast()

	const { t } = useTranslation()
	const css = PricePerPointEditorStyle()

	useEffect(() => {
		const pppListener = PricePerPointConfigurationStoreEvents.valueChangedSuccessfully.watch(() => {
			PricePerPointConfigurationStoreEvents.setIsOpen(false)

			toast.notify({
				type: TypeToast.SUCCESS,
				message: t('redeemable:NOTIFICATION.EDIT.SUCCESS'),
			})
		})

		return () => pppListener()
	}, [t, toast])

	const onModalOpenChangeHandler = useCallback(() => {
		PricePerPointConfigurationStoreEvents.setIsOpen(!isOpen)
	}, [isOpen])

	return (
		<div className={css.modalAlertWrapper} data-testid="ppp-editor-wrapper">
			<PricePerPointEditorFormProvider>
				<Dialog.Root
					headerImage={<Header />}
					actions={<Actions {...pppHookProvider} />}
					title={<Heading>{t('redeemable:EDIT.TITLE')}</Heading>}
					trigger={editButton}
					open={isOpen}
					onOpenChange={onModalOpenChangeHandler}
				>
					<PricePerPointEditorContent {...pppHookProvider} />
				</Dialog.Root>
			</PricePerPointEditorFormProvider>
		</div>
	)
}

const Header = () => {
	const css = PricePerPointEditorStyle()

	return (
		<Grid.Item className={css.headerImage} data-testid="ppp-editor-header-img">
			<Image src="https://cdn-b2b-abi.global.ssl.fastly.net/admin/images/BEES-Rewards-PPP-gift.svg" />
		</Grid.Item>
	)
}

const Actions = ({ onSubmit, isSubmitting }: UsePricePerPointEditorResult) => {
	const {
		handleSubmit,
		formState: { isDirty, isValid },
	} = useFormContext()

	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()

	const handleClickOnClose = () => {
		sendAnalyticsServiceEventButtonClickedEvent('Default Price Per Point', 'Cancel', 'Cancel')
	}

	const isValueChanged = usePricePerPointChanged()

	const { t } = useTranslation()
	const css = PricePerPointEditorStyle()

	const isSubmitDisabled = !isValueChanged || !isDirty || !isValid

	return (
		<div className={css.actionsWrapper} data-testid="ppp-editor-actions-wrapper">
			<Dialog.Close>
				<Button size="large" variant="secondary" onClick={handleClickOnClose}>
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="ppp-editor-save-button"
				size="large"
				variant="primary"
				type="submit"
				onClick={handleSubmit(onSubmit)}
				disabled={isSubmitDisabled}
				isLoading={!!isSubmitting}
			>
				{t('common:BUTTON.SAVE')}
			</Button>
		</div>
	)
}
