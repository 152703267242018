import React, { useMemo, ElementType } from 'react'
import { RouteInstance } from '@/domains/route/RouteInstance'
import { useSidebar } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'

export type SideBarAdminProps = {
	authorizedRoutes: Array<RouteInstance>
}

const SideBarAdmin: React.FC<SideBarAdminProps> = ({ authorizedRoutes }: SideBarAdminProps) => {
	const { t } = useTranslation()

	const sideBarProps = useMemo(() => {
		const items = authorizedRoutes.map((route) => ({
			id: route.id,
			title: t(route.textTranslationKey),
			icon: route.renderIcon as ElementType,
			path: route.to,
		}))

		return {
			items,
			utils: [],
		}
	}, [authorizedRoutes, t])

	useSidebar(sideBarProps)

	return null
}

export default SideBarAdmin
