import { TransactionStore, TransactionEvents } from '@/stores'
import { useStore } from 'effector-react'
import { useCallback } from 'react'

type HookResult = () => void

const useRemoveSuccessMessage = (): HookResult => {
	const { pageLevelNotificationType } = useStore(TransactionStore)

	const removeSuccessMessage = useCallback((): void => {
		if (pageLevelNotificationType !== 'error') {
			TransactionEvents.showNotificationForTransaction(null)
		}
	}, [pageLevelNotificationType])

	return removeSuccessMessage
}

export default useRemoveSuccessMessage
