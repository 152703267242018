/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table, TableContainer } from '@material-ui/core'
import React, { useEffect, useMemo, useImperativeHandle } from 'react'
import clsx from 'clsx'
import { ClassNameMap, CSSProperties } from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { SortedConfig } from '@/utils/sort'
import TableBody from './TableBody'
import { TableConfigs } from './model/TableConfigs'
import ColumnData from './model/ColumnData'
import { SearchConfigs } from './model/SearchConfigs'
import { TableActions } from './model/TableActions'
import { RowSelection } from './model/RowSelectionConfig'
import TableLoadingIndicator from './TableLoadingIndicator'
import TablePagination from './TablePagination'
import { DataTableProvider } from './context/DataTableContext'
import TableHeader from './TableHeader'
import TableInitialSort from './model/TableInitialSort'
import TableEmptyMessage from './TableEmpty'
import useDataTable from './hooks/useDataTable'
import useStyles from './styles'
import PaperContainer from './PaperContainer'
import TableSearchInputContainer from './TableSearchInputContainer'
import TableHeaderCheckbox from './TableHeaderCheckbox'
import useRowSelection from './hooks/useRowSelection'
import TableRowSelectionActions from './TableRowSelectionActions'
import SearchTracking from './model/SearchTracking'
import TableOrderType from './model/TableOrderType'

const DEFAULT_ITEMS_PER_PAGE = 50

const loadDefaultConfigs = (configs?: TableConfigs): TableConfigs => ({
	hideContainer: configs?.hideContainer || false,
	hidePagination: configs?.hidePagination || false,
	hidePaginationCount: configs?.hidePaginationCount || false,
	hideActions: configs?.hideActions || false,
	hideActionsLabel: configs?.hideActionsLabel || false,
	customActionLabel: configs?.customActionLabel ?? null,
	customActionInfo: configs?.customActionInfo,
	actionAlign: configs?.actionAlign ?? 'left',
	actionWidth: configs?.actionWidth,
	itemsPerPage: configs?.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
	itemsPerPageOptions: configs?.itemsPerPageOptions,
	sortEnabled: configs?.sortEnabled || null,
	onPageChange: configs?.onPageChange,
	customSort: configs?.customSort,
	renderRecordId: configs?.renderRecordId,
	onSort: configs?.onSort,
})

export type DataTableRef = {
	handleDelete: (rowIndexes: number[], callback?: (rows: any[]) => void) => void
}

type InteractionType = 'First' | 'Last' | 'Next' | 'Previous' | null

type DataTableProps = {
	id: string
	columns: ColumnData[]
	data: any[]
	loading: boolean
	initialSort?: TableInitialSort[]
	tableActions?: TableActions[]
	configs?: TableConfigs
	searchConfigs?: SearchConfigs
	customHeader?: React.ReactNode
	emptyMessage?: string
	showError?: boolean
	forceShowEmptyTable?: boolean
	rowSelection?: RowSelection
	totalElements?: number
	cellStyle?: (cellData: string | number | Date, columnDataKey: string) => CSSProperties
	newPagination?: boolean
}

const handleDataUpdate = (
	data: string | any[],
	loading: boolean,
	initialSort: any[] | undefined,
	dataTableState: {
		setCurrentPage?: (currentPage: number) => void
		setData: any
		setLoading: any
		setSortLoadingField?: (field?: any) => void
		setOrder?: (order: TableOrderType) => void
		setOrderBy?: (orderBy?: string) => void
		sortData: any
		searchData?: (searchBy: string[], query: string, trackProps?: SearchTracking) => void
		customSearchData?: (query: string) => Promise<void>
		getCurrentData?: any[]
		setItemsPerPage?: (pageSize: number) => void
		setCurrentQuery?: (query: string) => void
		query?: string
		data?: any[]
		originalData?: any[]
		loading?: boolean
		currentPage?: number
		order?: TableOrderType
		orderBy?: string
		sortLoadingField?: string | false
		isInitialSort?: boolean
		itemsPerPage?: number
		interactionType?: InteractionType
	},
) => {
	const hasData = data.length > 0
	const setloadingDataTable = () => loading && dataTableState.setLoading(true)

	if (hasData) {
		dataTableState.setData(data)
		setloadingDataTable()
	} else {
		dataTableState.setData([])
		setloadingDataTable()
	}

	if (initialSort) {
		dataTableState.sortData(
			initialSort.map((item: { dataKey: any; order: any }) => ({ key: item.dataKey, order: item.order })),
			data,
		)
	}

	if (!loading) {
		dataTableState.setLoading(false)
	}
}

const handleItemsPerPageOptions = (
	event: React.ChangeEvent<{ name?: string; value: unknown }>,
	dataTableState: {
		setCurrentPage?: (currentPage: number) => void
		setData?: (data: any[]) => void
		setLoading?: (loading: boolean) => void
		setSortLoadingField?: (field?: any) => void
		setOrder?: (order: TableOrderType) => void
		setOrderBy?: (orderBy?: string) => void
		sortData?: (sortConfig: SortedConfig<any[]>[], initialData?: any[]) => void
		searchData?: (searchBy: string[], query: string, trackProps?: SearchTracking) => void
		customSearchData?: (query: string) => Promise<void>
		getCurrentData?: any[]
		setItemsPerPage: any
		setCurrentQuery?: (query: string) => void
		query?: string
		data?: any[]
		originalData?: any[]
		loading?: boolean
		currentPage?: number
		order?: TableOrderType
		orderBy?: string
		sortLoadingField?: string | false
		isInitialSort?: boolean
		itemsPerPage?: number
		interactionType?: 'First' | 'Last' | 'Next' | 'Previous' | null
	},
) => {
	dataTableState.setItemsPerPage(Number.parseInt(event.target.value as string, 10))
}

const handleDelete = (
	rowIndexes: number[],
	callback: ((rows: any[]) => void) | undefined,
	dataTableState: {
		setCurrentPage?: (currentPage: number) => void
		setData: any
		setLoading?: (loading: boolean) => void
		setSortLoadingField?: (field?: any) => void
		setOrder?: (order: TableOrderType) => void
		setOrderBy?: (orderBy?: string) => void
		sortData?: (sortConfig: SortedConfig<any[]>[], initialData?: any[]) => void
		searchData?: (searchBy: string[], query: string, trackProps?: SearchTracking) => void
		customSearchData?: (query: string) => Promise<void>
		getCurrentData?: any[]
		setItemsPerPage?: (pageSize: number) => void
		setCurrentQuery?: (query: string) => void
		query?: string
		data: any
		originalData?: any[]
		loading?: boolean
		currentPage?: number
		order?: TableOrderType
		orderBy?: string
		sortLoadingField?: string | false
		isInitialSort?: boolean
		itemsPerPage?: number
		interactionType?: 'First' | 'Last' | 'Next' | 'Previous' | null
	},
	tableRef: React.MutableRefObject<HTMLTableElement | null>,
	classes: ClassNameMap<
		| 'rowNoContent'
		| 'table'
		| 'flexContainer'
		| 'searchContainer'
		| 'tableContainer'
		| 'tableFullContainer'
		| 'tableRow'
		| 'tableRowHover'
		| 'tableCell'
		| 'noClick'
		| 'tableInnerContainer'
	>,
	data: string | any[],
) => {
	if (rowIndexes.length && dataTableState.data?.length) {
		const rows = rowIndexes.map((i) => (tableRef.current as HTMLTableElement).rows[i + 1])

		rows.forEach((rowToDelete) => {
			rowToDelete.style.height = `${rowToDelete.scrollHeight}px`
		})

		setTimeout(() => {
			rows.forEach((rowToDelete) => {
				rowToDelete.classList.add(classes.rowNoContent)
				rowToDelete.style.height = '0px'
			})

			setTimeout(() => {
				rows.forEach((rowToDelete) => {
					rowToDelete.classList.remove(classes.rowNoContent)
				})

				if (data.length === 1) {
					dataTableState.setData([])
				} else {
					dataTableState.setData(dataTableState.data.filter((_item: any, index: number) => rowIndexes.includes(index)))
				}

				if (callback) {
					callback(rowIndexes.map((rowIndex) => dataTableState.data[rowIndex]))
				}
			}, 500)
		}, 500)
	}
}

const DataTable: React.ForwardRefRenderFunction<DataTableRef, DataTableProps> = (
	{
		id,
		columns,
		data,
		loading,
		initialSort,
		tableActions,
		configs,
		searchConfigs,
		customHeader,
		emptyMessage,
		showError,
		forceShowEmptyTable,
		rowSelection,
		cellStyle,
		totalElements,
		newPagination,
	}: DataTableProps,
	ref,
) => {
	const tableId = `table-${id}`
	const customConfigs = useMemo(() => loadDefaultConfigs(configs), [configs])
	const dataTableState = useDataTable({
		defaultItemsPerPage: customConfigs.itemsPerPage!,
		customSort: customConfigs?.customSort,
		customSearch: searchConfigs?.customSearch,
		newPagination,
	})
	const tableRef = React.useRef<HTMLTableElement | null>(null)
	const { tableBodyRef, headerCheckboxRef, actionsRef, clearSelection, toggleRow, toggleHeader, getSelectedRows } =
		useRowSelection()
	const { t } = useTranslation()

	useEffect(() => {
		handleDataUpdate(data, loading, initialSort, dataTableState)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, loading])

	/**
	 *  Actions Column  Visible
	 */
	if (!customConfigs?.hideActions && columns[columns.length - 1].dataKey !== 'actionColumn') {
		const label = customConfigs.customActionLabel ? customConfigs.customActionLabel : t('common:ACTIONS')
		columns.push({
			label: !customConfigs?.hideActionsLabel ? label : '',
			dataKey: 'actionColumn',
			info: customConfigs.customActionInfo,
			align: customConfigs?.actionAlign ?? 'left',
			width: customConfigs?.actionWidth,
		})
	}

	useImperativeHandle(ref, () => ({
		handleDelete: (rowIndexes, callback) => handleDelete(rowIndexes, callback, dataTableState, tableRef, classes, data),
	}))

	const showEmptyMessage =
		!dataTableState.loading &&
		dataTableState.data?.length === 0 &&
		dataTableState.originalData.length === 0 &&
		!forceShowEmptyTable &&
		!showError
	const showTable = !dataTableState.loading && (dataTableState.originalData?.length !== 0 || forceShowEmptyTable)
	const showPagination = !customConfigs?.hidePagination && showTable
	const showErrorMessage = !dataTableState.loading && showError
	const showSearchContainer = (searchConfigs || customHeader) && showTable

	const classes = useStyles({ ...configs?.style, tableContainerTopPadding: !showSearchContainer })

	return (
		<PaperContainer
			hideContainer={customConfigs.hideContainer}
			className={clsx({ [classes.tableContainer]: true, [classes.tableFullContainer]: !showTable })}
		>
			<React.Suspense fallback={<TableLoadingIndicator tableId={tableId} />}>
				<DataTableProvider value={dataTableState}>
					<TableSearchInputContainer searchConfigs={searchConfigs} customHeader={customHeader} />
					{rowSelection && (
						<TableRowSelectionActions
							rowSelection={rowSelection}
							ref={actionsRef}
							clearSelection={clearSelection}
							getSelectedRows={getSelectedRows}
						/>
					)}
					<TableContainer className={classes.tableInnerContainer}>
						<Table id={`table-${id}`} aria-label="table" ref={tableRef}>
							{showTable && (
								<>
									<TableHeader
										tableId={tableId}
										columns={columns}
										sortEnabled={customConfigs?.sortEnabled}
										rowSelectionCheckbox={
											rowSelection ? <TableHeaderCheckbox onClick={toggleHeader} ref={headerCheckboxRef} /> : undefined
										}
										onSort={customConfigs?.onSort}
									/>
									<TableBody
										tableId={tableId}
										columns={columns}
										tableActions={tableActions!}
										cellStyle={cellStyle}
										customConfigs={customConfigs}
										ref={tableBodyRef}
										onToggleRow={toggleRow}
										rowSelection={!!rowSelection}
										clearSelection={clearSelection}
									/>
								</>
							)}
						</Table>
						{showEmptyMessage && <TableEmptyMessage message={emptyMessage} style={configs?.style} />}
						{showErrorMessage && (
							<TableEmptyMessage
								message={t('ERROR_MESSAGE.GENERIC_ERROR')}
								style={{
									emptyMessage: {
										showIcon: true,
									},
								}}
							/>
						)}
						{dataTableState.loading && <TableLoadingIndicator tableId={tableId} />}
						{showPagination && (
							<TablePagination
								tableId={tableId}
								itemsPerPage={dataTableState.itemsPerPage ?? customConfigs.itemsPerPage!}
								itemsPerPageOptions={customConfigs.itemsPerPageOptions}
								onChangeItemsPerPageOptions={(event) => handleItemsPerPageOptions(event, dataTableState)}
								onPageChange={customConfigs.onPageChange}
								hidePaginationCount={customConfigs.hidePaginationCount}
								totalElements={totalElements}
							/>
						)}
					</TableContainer>
				</DataTableProvider>
			</React.Suspense>
		</PaperContainer>
	)
}

export type { RowSelection } from './model/RowSelectionConfig'

export default React.memo(React.forwardRef(DataTable))
