import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextButton } from '@hexa-ui/text-button'
import { Edit2 } from '@hexa-ui/icons'
import Rule from '@/domains/rule/Rule'
import { RulesEvents } from '@/stores'
import EditRuleType from '@/domains/enums/EditRule'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useStyles from './EditButtonStyle'
import { useNavigateToRules } from '../../../hooks/useNavigateToRules'
import { useLoadRules } from '../../../hooks/useLoadRules'

type EditButtonProps = {
	rule?: Rule
	editType?: EditRuleType
}

const EditButton: React.FC<EditButtonProps> = ({ rule, editType }: EditButtonProps) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { loadRules: reloadRules } = useLoadRules()
	const { goToEditSku, goToEditRule } = useNavigateToRules()
	const userInfo = useGetUserInfo()
	const handleTableActionEdit = async () => {
		RulesEvents.setRuleId(rule!.ruleId)
		if (editType === EditRuleType.editRule) {
			AnalyticsService.events.buttonClicked({
				button_name: 'Edit Earning Rule',
				button_label: 'Edit',
				screen_name: 'Earning Rules - Details',
				...userInfo,
			})
			goToEditRule(rule!)
		} else {
			AnalyticsService.events.buttonClicked({
				button_name: 'Edit Earning Rule SKUs',
				button_label: 'Edit',
				screen_name: 'Earning Rules - Details',
				...userInfo,
			})
			await reloadRules()
			goToEditSku(rule!)
		}
	}

	return (
		<TextButton icon={Edit2} iconPosition="leading" className={classes.editButton} onClick={handleTableActionEdit}>
			{t('common:EDIT')}
		</TextButton>
	)
}

export default EditButton
