import { BadgeStatusType } from '@/components/core/BadgeStatus'
import { RedeemableStatusBadge } from '@/routes/redeemableItems/components/RedeemableItemsListV2/components/RedeemableItemsTable/components/RedeemableStatus'
import { formatDate } from '@/utils/date'
import { Paragraph, Table } from '@hexa-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'

const makeListData = (n: number) => {
	return Array.from({ length: n }, (_, index) => ({
		ruleId: `${index + 1}`,
		audienceName: `Audience name ${index + 1}`,
		points: 10 + index,
		updatedAt: '2023-01-01',
		skus: [],
		categoryId: `categoryId ${index + 1}`,
		amountSpent: 1,
		items: [],
		description: `Rule personal description ${index + 1}`,
		status: 'Active',
		vendorId: '4321860c-3fab-4c8d-bbd9-a1792baae31c',
		audiences: 'audience-id',
		filters: {}, // Mapping not necessary
	}))
}

const RenderStatusColumn = (text: string) => {
	return <RedeemableStatusBadge text={text} type={BadgeStatusType.success} />
}

export function ExclusionListContent() {
	const { t } = useTranslation()

	const data = makeListData(100)

	const columns = [
		{
			Header: t('rules:EARNING_RULES_TABLE.RULE_DESCRIPTION'),
			accessor: 'description',
			disableSortBy: false,
			style: {
				width: '30%',
			},
		},
		{
			Header: t('rules:EARNING_RULES_TABLE.AUDIENCE'),
			accessor: 'audienceName',
			disableSortBy: false,
			style: {
				width: '20%',
			},
		},
		{
			Header: t('rules:EARNING_RULES_TABLE.EDITED_ON'),
			accessor: 'updatedAt',
			disableSortBy: false,
			Cell: (date: { value: Date }) => formatDate(date.value, 'DD MMMM, YYYY'),
			style: {
				width: '20%',
			},
		},
		{
			Header: t('rules:EARNING_RULES_TABLE.STATUS'),
			accessor: 'status',
			disableSortBy: true,
			customRender: RenderStatusColumn,
			style: {
				width: '20%',
			},
		},
	]

	return (
		<>
			<Paragraph colortype="secondary" weight="medium" style={{ marginBottom: 24 }}>
				{t('rules:EARNING_RULES_TABLE.EXCLUSION_LIST_DESCRIPTION')}
			</Paragraph>
			<Table
				data-testid="exclusion-list"
				columns={columns}
				data={data}
				pagination={{
					onChange: function noRefCheck() {},
					pageSize: 10,
					pageSizeOptions: [5, 10, 20],
					showPageSizeSelector: false,
				}}
				initialSortBy={[
					{
						desc: false,
						id: 'audienceName',
					},
				]}
				search
				searchWidth="500px"
			/>
		</>
	)
}

export default ExclusionListContent
