import InputContainer, { InputContainerProps } from '@/components/form/InputContainer'
import { MAX_INTEGER_SIZE } from '@/utils/constants'
import { InputBaseComponentProps, OutlinedInput, OutlinedInputProps } from '@material-ui/core'
import React from 'react'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

export type InputNumberProps = InputContainerProps & OutlinedInputProps

type InputMaskedCustomProps = {
	inputRef: (ref: HTMLInputElement | null) => void
}

const InputMaskedCustom: React.FC<InputMaskedCustomProps> = ({ inputRef, ...rest }) => {
	const numberMask = createNumberMask({
		prefix: '',
		includeThousandsSeparator: false,
		allowDecimal: false,
		allowNegative: false,
		allowLeadingZeroes: false,
		integerLimit: MAX_INTEGER_SIZE,
	})

	return (
		<MaskedInput
			{...rest}
			ref={(ref: MaskedInput) => inputRef(ref ? (ref.inputElement as HTMLInputElement) : null)}
			mask={numberMask}
		/>
	)
}

const InputNumber: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputNumberProps> = (
	{ id, label, optionalLabel, helperText, errorText, disabled, ...rest },
	ref,
) => {
	const hasError = !!errorText

	return (
		<InputContainer
			id={id}
			label={label}
			optionalLabel={optionalLabel}
			errorText={errorText}
			helperText={helperText}
			disabled={disabled}
		>
			<OutlinedInput
				{...rest}
				id={id}
				fullWidth
				inputRef={ref}
				error={hasError}
				inputComponent={InputMaskedCustom as React.ElementType<InputBaseComponentProps>}
			/>
		</InputContainer>
	)
}

export default React.forwardRef(InputNumber)
