import React, { ReactNode } from 'react'
import { Typography, Grid, Box } from '@material-ui/core'
import { BreadCrumbLink, BreadCrumbLinkProps } from '@/components/core'
import useStyles from './styles'

type Props = {
	title: string
	breadCrumbLink?: BreadCrumbLinkProps
	children: ReactNode
}

const Container: React.FC<Props> = ({ title, breadCrumbLink, children }: Props) => {
	const classes = useStyles({ breadCrumbLink: !!breadCrumbLink })

	return (
		<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
			{breadCrumbLink && <BreadCrumbLink displayName={breadCrumbLink.displayName} onBack={breadCrumbLink.onBack} />}
			<Typography variant="h2" className={classes.title}>
				{title}
			</Typography>
			<Grid container justifyContent="center">
				<Grid item xl={6} lg={8} md={9} sm={12}>
					<Box mb={6}>{children}</Box>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Container
