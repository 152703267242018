import { useMemo } from 'react'
import { IProgram } from '@/domains'
import { useStore } from 'effector-react'
import { GlobalStore } from '@/stores'

export type AvailableProgramsResult = {
	programs: Array<IProgram>
	isLoadingPrograms: boolean
}

export const useAvailablePrograms = (): AvailableProgramsResult => {
	const { selectedVendorId, vendorOptionsMap } = useStore(GlobalStore)

	const programs = useMemo<Array<IProgram>>(() => {
		if (!selectedVendorId) {
			return []
		}
		const selectedVendor = vendorOptionsMap[selectedVendorId]
		return selectedVendor.programs
	}, [selectedVendorId, vendorOptionsMap])

	return {
		programs,
		isLoadingPrograms: false,
	}
}
