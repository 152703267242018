import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useStoreMap } from 'effector-react'
import { yupResolver } from '@hookform/resolvers/yup'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ButtonInteraction, ScreenName } from '@/domains'
import { ComboFormData } from '@/stores/combo/ComboState'
import { ComboStore } from '@/stores'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { getValidationObject } from './validationObject'
import ComboFormView from './ComboFormView'

const ComboForm: React.FC = () => {
	const history = useHistory()
	const userInfo = useGetUserInfo()

	const { formType } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			formType: state.formType ?? undefined,
		}),
	})

	const handleBreadCrumbBack = () => {
		history.push('combos')
		AnalyticsService.events.buttonClicked({
			button_label: null,
			button_name: ButtonInteraction.BackToActiveDtCombos,
			screen_name: ScreenName.AddDTCombo,
			...userInfo,
		})
	}

	const formMethods = useForm<ComboFormData>({
		resolver: yupResolver(getValidationObject(formType)),
		mode: 'all',
		shouldFocusError: false,
	})

	return (
		<FormProvider {...formMethods}>
			<ComboFormView onClickBreadcrumbBack={handleBreadCrumbBack} />
		</FormProvider>
	)
}
export default ComboForm
