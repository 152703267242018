import * as TransactionServiceV2 from '@/services/transactionV2/TransactionService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'

type AccountItem = {
	poc_id: string
	points: string
	campaignId: string
}

async function execute(values: Record<string, unknown>): Promise<void> {
	const { points, pocId, accounts, description, removeReason, file } = values
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const formattedMultipleAccounts = (accounts as AccountItem[])?.map((item) => ({
		vendorAccountId: item.poc_id,
		points: item.points,
		campaignId: '',
	}))

	const formattedAccounts = [{ vendorAccountId: pocId as string, points: points as string, campaignId: '' }]

	const newAccounts = formattedMultipleAccounts?.length > 0 ? formattedMultipleAccounts : formattedAccounts

	const pointsRemovalData = {
		vendorId,
		accounts: newAccounts,
		quantity: newAccounts.length,
		description: description as string,
		category: removeReason as string,
	}

	const pointsRemoval = TransactionServiceV2.pointsRemoval(pointsRemovalData, file as File)

	return pointsRemoval
}

export { execute }
