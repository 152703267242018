import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ChallengeStatus from 'domains/enums/ChallengeStatusEnum'
import ChallengeHistory from 'domains/ChallengeHistory'
import { formatDate } from 'utils/date'
import DataTable, { DataTableRef } from 'components/tables/DataTable'
import { BASE_ROUTE } from 'utils/constants'
import { PageLevelNotification, ChallengeStatusPill } from '@/components/core'
import AnalyticsService from 'services/analytics/AnalyticsService'
import TablePageTemplate from 'components/templates/TablePageTemplate'
import { ChallengePageLevelNotification } from '@/stores/challengeCreation'
import { ChallengeParamsUseCase } from '@/domains/Challenge'
import ChallengeHistoryDomain from '@/domains/ChallengeHistory'
import { LoadChallengesWithGroupsUseCase } from '@/usecase'
import { PaginationInfo } from '@/domains/utils/Pagination'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useStoreMap } from 'effector-react'
import ChallengeHistoryStore from '@/stores/challengeHistory/ChallengeHistoryStore'
import TableOrderType from '@/components/tables/DataTable/model/TableOrderType'

type Props = {
	pageLevelNotification: ChallengePageLevelNotification
	onRowView: (challenge: ChallengeHistory) => void
}

const ChallengeHistoryView: React.FC<Props> = ({ pageLevelNotification, onRowView }: Props) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [showError, setShowError] = useState<boolean>(false)
	const userInfo = useGetUserInfo()
	const [challenges, setChallenges] = useState([] as ChallengeHistoryDomain[])
	const [paginationState, setPaginationState] = useState({} as PaginationInfo)
	const { t } = useTranslation()
	const challengeFormPath = `${BASE_ROUTE}/challenge/setup`
	const dataTableRef = React.useRef<DataTableRef>(null)
	const [regexFilterState, setRegexFilterState] = useState('')
	const { orderBy = '', direction = '' } = useStoreMap({
		store: ChallengeHistoryStore,
		keys: [],
		fn: (state) => ({
			orderBy: state.orderBy,
			direction: state.direction,
		}),
	})

	const orderByProps: { [key: string]: string } = {
		id: 'ID',
		title: 'TITLE',
		startDate: 'START_DATE',
		endDate: 'END_DATE',
		status: 'MODE',
		lastModify: 'LAST_MODIFIED',
	}

	const directionProps: { [key: string]: string } = {
		asc: 'ASC',
		desc: 'DESC',
	}

	const handleCreateChallengeClick = (): void => {
		AnalyticsService.events.buttonClicked({
			button_label: 'Create New Challenge',
			button_name: 'New Challenge',
			screen_name: 'Challenge History',
			...userInfo,
		})
	}

	const loadChallenges = ({ page, pageSize, regexFilter }: ChallengeParamsUseCase): void => {
		setLoading(true)
		LoadChallengesWithGroupsUseCase.execute({
			page,
			pageSize,
			regexFilter,
			orderBy: orderByProps[orderBy],
			direction: directionProps[direction],
		})
			.then((response) => {
				setChallenges(response.data)
				setPaginationState(response.pagination)
				setLoading(false)
			})
			.catch(() => {
				setLoading(false)
				setShowError(true)
			})
	}

	useEffect(() => {
		loadChallenges({ page: 0, pageSize: 50, regexFilter: regexFilterState })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderBy, direction, regexFilterState])

	useEffect(() => {
		AnalyticsService.events.pageViewed({ ...userInfo, screen_name: 'Challenge History' })
	}, [userInfo])

	return (
		<TablePageTemplate
			buttonProps={{
				text: t('challenge:HISTORY.NEW_CHALLENGE_BTN'),
				id: 'new-challenge-btn',
				to: challengeFormPath,
				onClick: handleCreateChallengeClick,
			}}
			title={t('challenge:HISTORY.TITLE')}
		>
			{pageLevelNotification.type === 'success' && (
				<PageLevelNotification type="success" bottomSpacing={2}>
					<Typography id="challenge-creation-message" variant="h5">
						{pageLevelNotification.message}
					</Typography>
				</PageLevelNotification>
			)}
			<Grid item container>
				<Grid item sm>
					<DataTable
						newPagination
						id="challenge-history"
						columns={[
							{
								label: `${t('challenge:CHALLENGE_NAME')}`,
								dataKey: 'title',
								width: '24%',
							},
							{
								label: `${t('challenge:CHALLENGE_ID')}`,
								dataKey: 'id',
								width: '18%',
								minWidth: '165px',
							},
							{
								label: `${t('challenge:START_DATE')}`,
								dataKey: 'startDate',
								width: '17%',
								format: (date): string => (date ? formatDate(date as Date, t('challenge:HISTORY.DATE_FORMAT')) : ''),
							},
							{
								label: `${t('challenge:END_DATE')}`,
								dataKey: 'endDate',
								width: '17%',
								format: (date): string => (date ? formatDate(date as Date, t('challenge:HISTORY.DATE_FORMAT')) : ''),
							},
							{
								label: `${t('challenge:STATUS')}`,
								dataKey: 'status',
								width: '11%',
								format: (status) => <ChallengeStatusPill status={status as ChallengeStatus} />,
							},
						]}
						data={challenges}
						tableActions={[
							{
								label: t('SHOW'),
								fn: onRowView,
							},
						]}
						totalElements={paginationState?.totalElements || 0}
						configs={{
							itemsPerPage: 50,
							actionWidth: '13%',
							style: {
								emptyMessage: {
									showIcon: true,
								},
							},
							onPageChange: (currentPage, regexFilter) => {
								setLoading(true)
								loadChallenges({ page: currentPage - 1, pageSize: 50, regexFilter })
								AnalyticsService.events.paginationClicked({
									screen_name: 'Challenge History',
									interaction_type: null,
									page_number: String(currentPage),
									...userInfo,
								})
							},
						}}
						initialSort={[
							{ dataKey: orderBy, order: direction as TableOrderType },
							{ dataKey: 'title', order: 'asc' },
						]}
						ref={dataTableRef}
						loading={loading}
						showError={showError}
						emptyMessage={t('challenge:HISTORY.EMPTY_DATA_TABLE')}
						searchConfigs={{
							id: 'search-challenge',
							placeholder: `${t('challenge:FILTER_LIST.SEARCH_CHALLENGE')}`,
							searchBy: ['title', 'id'],
							customSearch: async (regexFilter) => {
								setRegexFilterState(regexFilter)
								const { data, pagination } = await LoadChallengesWithGroupsUseCase.execute({
									page: 0,
									pageSize: 50,
									regexFilter,
									orderBy: orderByProps[orderBy],
									direction: directionProps[direction],
								})
								if (data !== challenges) setChallenges(data)
								setPaginationState(pagination)
								return data
							},
						}}
					/>
				</Grid>
			</Grid>
		</TablePageTemplate>
	)
}

export default ChallengeHistoryView
