import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const rewardsOfferStyle = makeStyles((theme: Theme) =>
	createStyles({
		fieldGridContainer: {
			alignItems: 'stretch',
		},
		fieldGridItem: {
			display: 'flex',
			'& > div:first-child': {
				flex: 1,
				display: 'flex',
				flexFlow: 'column nowrap',
				justifyContent: 'space-between',
				paddingRight: theme.spacing(1),
			},
		},
		fileUpload: {
			paddingTop: 0,
		},
	}),
)

export default rewardsOfferStyle
