import { useMemo } from 'react'
import { PermissionLevel, TransactionType } from '@/domains/enums'
import { useTranslation } from 'react-i18next'
import * as FeatureToggle from 'utils/featureToggle'
import { isToggleEnabled, useAuthorization } from '..'

type TypeList = {
	value: string
	label: string
}

const useTransactionTypeOptions = (toggles: {
	createRefundFeature: boolean
	createRewardsOfferFeature: boolean
	createPointsTransferFeature: boolean
	createPointsRemovalFeature: boolean
	createPointsRemovalForServiceUtility: boolean
}): TypeList[] => {
	const { t } = useTranslation()
	const typeListOptions = useMemo(() => {
		const typeList = [] as TypeList[]

		if (toggles.createPointsRemovalFeature) {
			typeList.push({
				value: TransactionType.PointsRemoval,
				label: t('transaction:TRANSACTION_TYPE_POINTS_REMOVAL'),
			})
		}

		if (toggles.createPointsRemovalForServiceUtility) {
			typeList.push({
				value: TransactionType.ServiceUtility,
				label: t('transaction:TRANSACTION_TYPE_POINTS_REMOVAL_FOR_SERVICE_UTILITY'),
			})
		}

		if (toggles.createRefundFeature) {
			typeList.push({ value: TransactionType.Refund, label: t('transaction:TRANSACTION_TYPE_REFUND') })
		}

		if (toggles.createRewardsOfferFeature) {
			typeList.push({ value: TransactionType.RewardsOffer, label: t('transaction:TRANSACTION_TYPE_REWARDS_OFFER') })
		}

		if (toggles.createPointsTransferFeature) {
			typeList.push({
				value: TransactionType.PointsTransferFrom,
				label: t('transaction:TRANSACTION_TYPE_POINTS_TRANSFER'),
			})
		}

		return typeList
	}, [
		t,
		toggles.createPointsRemovalFeature,
		toggles.createPointsRemovalForServiceUtility,
		toggles.createPointsTransferFeature,
		toggles.createRefundFeature,
		toggles.createRewardsOfferFeature,
	])

	return typeListOptions
}

const useTransactionTypeList = () => {
	const { isFeatureAuthorized } = useAuthorization()

	const createRefundFeature = isFeatureAuthorized(isToggleEnabled(FeatureToggle.CREATE_REFUND), {
		feature: 'Refund',
		permissionLevel: PermissionLevel.Write,
	})

	const createRewardsOfferFeature = isFeatureAuthorized(isToggleEnabled(FeatureToggle.CREATE_REWARDS_OFFER), {
		feature: 'RewardsOffer',
		permissionLevel: PermissionLevel.Write,
	})
	const createPointsTransferFeature = isFeatureAuthorized(isToggleEnabled(FeatureToggle.CREATE_POINTS_TRANSFER), {
		feature: 'PointsTransfer',
		permissionLevel: PermissionLevel.Write,
	})
	const createPointsRemovalFeature = isFeatureAuthorized(isToggleEnabled(FeatureToggle.CREATE_POINTS_REMOVAL), {
		feature: 'PointsRemoval',
		permissionLevel: PermissionLevel.Write,
	})

	const createPointsRemovalForServiceUtility = isFeatureAuthorized(
		isToggleEnabled(FeatureToggle.CREATE_REMOVAL_FOR_SERVICE_UTILITY),
		{
			feature: 'RemoveForServiceUtility',
			permissionLevel: PermissionLevel.Write,
		},
	)

	return useTransactionTypeOptions({
		createRefundFeature,
		createRewardsOfferFeature,
		createPointsTransferFeature,
		createPointsRemovalFeature,
		createPointsRemovalForServiceUtility,
	})
}

export default useTransactionTypeList
