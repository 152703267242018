import { RewardsProgram } from '@/domains'

interface RewardsPermissionState {
	rewardsData: RewardsProgram[]
	showError: boolean
	updateDialog: {
		isShowing: boolean
		accountId?: string
		rowIndex?: number
		enablePermission?: boolean
	}
}

export const RewardsPermissionInitialState: RewardsPermissionState = {
	rewardsData: [],
	showError: false,
	updateDialog: {
		isShowing: false,
		rowIndex: undefined,
	},
}

export default RewardsPermissionState
