import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import FormDialogTemplate from '@/components/templates/FormDialogTemplate'
import useIsTargetForCreationEnabled from '@/hooks/challenge/useIsTargetForCreationEnabled'
import ChallengeFormFilterView from '@/routes/challenge/componentsRedesign/ChallengeFormFilter/ChallengeFormFilterView'
import { OnCancelButtonClickFunction } from '@/routes/challenges/model/EditChallengeDialog'
import { ChallengeFilterFormData } from '@/stores/challengeCreation'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidationObject } from '@/routes/challenge/componentsRedesign/ChallengeFormFilter/validationObject'

type Props = {
	challengeFilterFormData?: ChallengeFilterFormData
	onFormSubmit: SubmitHandler<ChallengeFilterFormData>
	onCancelButtonClick: OnCancelButtonClickFunction
	showAccountGroupModal: boolean
	isActiveChallenge: boolean
	showDialog: boolean
}

const ChallengeFormFilterEditDialogView: React.FC<Props> = ({
	challengeFilterFormData,
	onFormSubmit,
	onCancelButtonClick,
	showAccountGroupModal,
	isActiveChallenge,
	showDialog,
}) => {
	const { t } = useTranslation()

	const formMethods = useForm({
		resolver: yupResolver(getValidationObject(true, isActiveChallenge)),
		mode: 'onBlur',
		shouldFocusError: false,
	})
	const isTargetEnabled = useIsTargetForCreationEnabled()

	useEffect(() => {
		formMethods.reset(challengeFilterFormData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<FormDialogTemplate
			showDialog={showDialog}
			title={t('challenge:EDIT_DIALOG.TITLE')}
			cancelButtonProps={{
				handleClick: onCancelButtonClick,
			}}
			submitButtonProps={{
				disabled: !formMethods.formState.isDirty,
			}}
			handleSubmit={formMethods.handleSubmit(onFormSubmit)}
			hide={showAccountGroupModal}
			transitionDuration={{ appear: 0 }}
		>
			<FormProvider {...formMethods}>
				<ChallengeFormFilterView
					challengeFilterFormData={challengeFilterFormData}
					isEditing
					isTargetEnabled={isTargetEnabled}
					isActiveChallenge={isActiveChallenge}
					disableFields={isActiveChallenge ? ['filter', 'startDate'] : undefined}
				/>
			</FormProvider>
		</FormDialogTemplate>
	)
}

export default ChallengeFormFilterEditDialogView
