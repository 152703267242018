import SkuForm from '@/domains/sku/SkuForm'
import { deleteSkuFromRule } from '@/services/settings/SettingsService'

export type DeleteSkuUseCaseProps = {
	ruleId: string
	categoryId: string | null
	selectedTierKey: string | null
	sku: SkuForm
	settingsId: string
	level: string
}

export async function execute(params: DeleteSkuUseCaseProps): Promise<boolean> {
	try {
		await deleteSkuFromRule({
			ruleId: params.ruleId,
			settingId: params.settingsId,
			categoryId: params.categoryId,
			tier: params.selectedTierKey,
			itemId: params.sku.itemId,
			level: params.level,
		})
		return true
	} catch {
		return false
	}
}
