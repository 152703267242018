import { PointsTransferTransactionResponse as PointsTransferResponse, TransactionResponse } from '@/domains'
import { RewardsOfferType, TransactionType } from '@/domains/enums'
import { UserDataResult } from '@/hooks/getUserInfo/useGetUserInfo'
import { RefundItemFormik } from '@/routes/transaction/model/RefundItemFormik'
import * as RewardsOfferMultipleUseCase from '@/usecase/transaction/RewardsOfferMultipleUseCase'
import {
	isIdentifyDimensionEnabled,
	isToggleRefundRedeemableItemsEnabled,
} from '@/utils/featureToggle/featureToggleHelper'
import transactionFormValuesToRefund from 'converters/TransactionFormValuesToRefund'
import transactionFormValuesToRewardsOffer, {
	transactionFormValuesToRewardsOfferV2,
} from 'converters/TransactionFormValuesToRewardsOffer'
import transactionFormValuesToRewardsOfferMultiple, {
	transactionFormValuesToRewardsOfferMultipleV2,
} from 'converters/transaction/TransactionFormValuesToRewardsOfferMultiple'
import FailureReason from 'domains/analytics/FailureReason'
import ScreenName from 'domains/analytics/ScreenName'
import AnalyticsService from 'services/analytics/AnalyticsService'
import * as TransactionEvents from 'stores/transaction/TransactionEvents'
import * as GlobalUseCase from 'usecase/global/GlobalUseCase'
import * as LoadOrderWithComboUseCase from 'usecase/order/LoadOrderWithComboUseCase'
import * as PointsTransferUseCase from 'usecase/transaction/PointsTransferUseCase'
import * as RefundTransactionUseCase from 'usecase/transaction/RefundTransactionUseCase'
import * as RewardsOfferTransactionUseCase from 'usecase/transaction/RewardsOfferTransactionUseCase'
import { LoadRefundOrderUseCase } from '../order'
import * as PointsRemovalUseCase from './PointsRemovalUseCase'
import * as RemovalForServiceUtilityUseCase from './RemovalForServiceUtilityUseCase'

async function executeUseCase(
	transactionType: TransactionType,
	rewardsOfferType: RewardsOfferType | null,
	values: Record<string, unknown>,
	analyticsProps: UserDataResult,
): Promise<TransactionResponse | PointsTransferResponse[] | string> {
	if (transactionType === TransactionType.Refund) {
		return RefundTransactionUseCase.execute(values.accountId as string, transactionFormValuesToRefund(values)).catch(
			(reason) => {
				AnalyticsService.events.error({
					screen_name: ScreenName.RefundCreatePointsTransaction,
					failure_reason: FailureReason.TransactionSubmitError,
					form_name: null,
					...analyticsProps,
				})
				throw reason
			},
		)
	}
	if (transactionType === TransactionType.RewardsOffer && rewardsOfferType === RewardsOfferType.SinglePoc) {
		return RewardsOfferTransactionUseCase.execute(
			values.pocId as string,
			isIdentifyDimensionEnabled()
				? transactionFormValuesToRewardsOfferV2({
						singlePOC: true,
						...values,
				  })
				: transactionFormValuesToRewardsOffer(values),
		)
	}
	if (transactionType === TransactionType.RewardsOffer && rewardsOfferType === RewardsOfferType.MultiplePoc) {
		const multiplePocsFile = values.multiplePocsFile as Array<File>
		return RewardsOfferMultipleUseCase.execute(
			multiplePocsFile[0],
			isIdentifyDimensionEnabled()
				? transactionFormValuesToRewardsOfferMultipleV2({
						singlePOC: false,
						...values,
				  })
				: transactionFormValuesToRewardsOfferMultiple(values),
		)
	}
	if (transactionType === TransactionType.PointsTransferFrom) {
		return PointsTransferUseCase.execute(values.pocIdFrom as string, values.pocIdTo as string, values.reason as string)
	}
	if (transactionType === TransactionType.PointsRemoval) {
		return PointsRemovalUseCase.execute(
			values.pocId as string,
			parseInt(values.points as string, 10),
			values.description as string,
		)
	}
	if (transactionType === TransactionType.ServiceUtility) {
		return RemovalForServiceUtilityUseCase.execute(
			values.pocId as string,
			parseInt(values.points as string, 10),
			values.description as string,
			values.category as string,
		)
	}
	// There is no use case related to this transactionType
	return Promise.reject(new Error('Transaction does not exist'))
}

async function reLoadOrder(orderId: string): Promise<string> {
	try {
		let order
		if (isToggleRefundRedeemableItemsEnabled()) {
			order = await LoadRefundOrderUseCase.execute(orderId)
		} else {
			order = await LoadOrderWithComboUseCase.execute(orderId)
		}

		TransactionEvents.selectOrder(order)
	} catch (err) {
		TransactionEvents.selectOrder(null)
	}

	return Promise.resolve('')
}

async function execute(
	transactionType: TransactionType,
	rewardsOfferType: RewardsOfferType | null,
	values: Record<string, unknown>,
	analyticsProps: UserDataResult,
): Promise<TransactionResponse | PointsTransferResponse[] | string> {
	GlobalUseCase.load()
	return executeUseCase(transactionType, rewardsOfferType, values, analyticsProps)
		.then(async (result) => {
			if (transactionType === TransactionType.Refund) {
				const trackValues = values as unknown as {
					accountId: string
					orderId: string
					combos: Array<RefundItemFormik>
					orderDate: string
					orderStatus: string
				}

				await reLoadOrder(trackValues.orderId)
			}
			TransactionEvents.showNotificationForTransaction('success')
			return result
		})
		.catch((reason) => {
			TransactionEvents.showNotificationForTransaction('error')
			throw reason
		})
		.finally(() => {
			if (transactionType === TransactionType.RewardsOffer) {
				TransactionEvents.resetForm({
					transactionType,
					rewardsOfferType,
				})
			}
			GlobalUseCase.loadDone()
			TransactionEvents.setShowErrorForTransaction(false)
		})
}

export { execute }
