import { SettingDefaultConfiguration } from '@/domains/settings'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { SettingsConfigurationEvents, SettingsConfigurationStore } from '@/stores'
import { LoadDefaultConfigurationUseCase } from '@/usecase'
import { useStore } from 'effector-react'
import { useCallback, useEffect, useState } from 'react'

export type UseZoneConfigurationResult = {
	isLoading: boolean
	hasError: boolean
	settingsData?: SettingDefaultConfiguration
	loadData: () => void
}

export const useZoneConfiguration = (): UseZoneConfigurationResult => {
	const [isLoading, setIsLoading] = useState(false)
	const [hasError, setHasError] = useState(false)

	const { selectedTierKey } = useCurrentTier()
	const { settingsData } = useStore(SettingsConfigurationStore)

	const loadData = useCallback(() => {
		try {
			setIsLoading(true)
			setHasError(false)

			if (selectedTierKey) {
				LoadDefaultConfigurationUseCase.execute(selectedTierKey)
					.then((result) => {
						SettingsConfigurationEvents.setSettingsData(result)
					})
					.catch(() => {
						setHasError(true)
					})
					.finally(() => {
						setIsLoading(false)
					})
			}
		} catch (error) {
			setHasError(true)
			setIsLoading(false)
		}
	}, [selectedTierKey])

	useEffect(() => {
		loadData()
	}, [loadData])

	return {
		isLoading,
		hasError,
		settingsData,
		loadData,
	}
}
