import { hasText } from './string'

export const setStorageItem = <TData>(key: string, data: TData): void => {
	const itemValue = typeof data === 'string' ? data : JSON.stringify(data)
	localStorage.setItem(key, itemValue)
}

export const getStorageItemString = (key: string): string | null => localStorage.getItem(key) ?? null

export const getStorageItemParsed = <TData>(key: string): TData | null => {
	try {
		const itemValue = localStorage.getItem(key)
		if (!hasText(itemValue)) {
			return null
		}
		return JSON.parse(itemValue!)
	} catch {
		return null
	}
}
