import { EditRedeemableItemRequest } from '@/domains'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import * as RedeemablesService from '@/services/redeemables/RedeemablesService'
import { EditRedeemableItemStoreEvents, RedeemableItemsStoreEvents } from '@/stores'
import { useLogService as logService } from 'admin-portal-shared-services'

async function execute(item: RedeemableItem): Promise<void> {
	const log = logService()
	EditRedeemableItemStoreEvents.isPublishing()

	try {
		const { pricePerPoint = 0, id } = item

		const requestData: EditRedeemableItemRequest = {
			pricePerPoint: +pricePerPoint,
		}
		await RedeemablesService.editRedeemableItem(requestData, id)
		EditRedeemableItemStoreEvents.isPublishSuccess()
		RedeemableItemsStoreEvents.reloadPage()
		EditRedeemableItemStoreEvents.reset()
	} catch (error) {
		log.error('FAILED TO EDIT REDEEMABLE ITEM: ', error)
		EditRedeemableItemStoreEvents.hasError(true)
		throw error
	}
}

export { execute }
