import { cancelTransaction } from '@/services/transactionV2/TransactionService'

export const execute = async (transactionId: string): Promise<number | null | unknown> => {
	try {
		const canceledTransaction = await cancelTransaction(transactionId)
		return canceledTransaction
	} catch (error) {
		console.error(`Failed to cancel transaction. ${error}`)
		return null
	}
}
