import React, { useEffect } from 'react'
import TablePagePaperTemplate from '@/components/templates/TablePagePaperTemplate'
import { useTranslation } from 'react-i18next'
import DataTable from '@/components/tables/DataTable'
import { formatDate } from '@/utils/date'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import AccountValidationResult from '@/domains/enums/account/AccountValidationResult'
import PocSearch from '@/components/poc/PocSearch'
import { PocSearchParams, ScreenName, TransactionHistory } from '@/domains'
import PocSearchBox from '@/components/poc/PocSearchBox'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import TableOrderType from '@/components/tables/DataTable/model/TableOrderType'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'

type Props = {
	handleSearch: (data: PocSearchParams) => Promise<AccountValidationResult>
	isSearching: boolean
	isSearchOK: boolean
	transactionHistoryData: Array<TransactionHistory>
	handleCellStyle?: (cellData: string | number | Date, columnDataKey: string) => CSSProperties
}

const TransactionHistoryView: React.FC<Props> = ({
	handleSearch,
	isSearching,
	isSearchOK,
	transactionHistoryData,
	handleCellStyle,
}: Props) => {
	const userInfo = useGetUserInfo()
	const { t } = useTranslation()
	const isTableVisible = isSearchOK && !isSearching

	useEffect(() => {
		AnalyticsService.events.pageViewed({
			screen_name: ScreenName.TransactionHistory,
			...userInfo,
		})
	}, [userInfo])

	const onSort = (columnName: string, order?: TableOrderType) => {
		const isAsc = order && order === 'asc'
		AnalyticsService.events.tableSorted({
			sort_criteria: columnName,
			sort_type: isAsc ? 'Ascending' : 'Descending',
			table_name: ScreenName.TransactionHistory,
			screen_name: ScreenName.TransactionHistory,
			...userInfo,
		})
	}

	return (
		<TablePagePaperTemplate title={t('history:TITLE')} subTitle={t('history:SUBTITLE')}>
			<PocSearchBox>
				<PocSearch isSearching={isSearching} searchHandler={handleSearch} />
			</PocSearchBox>
			{isTableVisible && (
				<DataTable
					id="transaction-history"
					columns={[
						{
							label: `${t('history:TABLE_COLUMN.DATE')}`,
							dataKey: 'timestamp',
							width: '20%',
							format: (date): string => (date ? formatDate(date as Date, t('DATA_NEW_FORMAT_TABLE')) : ''),
						},
						{
							label: `${t('history:TABLE_COLUMN.TRANSACTION_TYPE')}`,
							dataKey: 'transactionTypeDescription',
							width: '18%',
						},
						{
							label: `${t('history:TABLE_COLUMN.POC_ID')}`,
							dataKey: 'accountId',
							width: '18%',
						},
						{
							label: `${t('history:TABLE_COLUMN.REFERENCE')}`,
							dataKey: 'reference',
							width: '33%',
						},
						{
							label: `${t('history:TABLE_COLUMN.POINTS')}`,
							dataKey: 'amount',
							width: '11%',
							format: (amount): string =>
								amount > 0 ? t(`history:AMOUNT_FIELD.POSITIVE_AMOUNT`, { amount }) : amount.toString(),
						},
					]}
					data={transactionHistoryData}
					cellStyle={handleCellStyle}
					configs={{
						hideActions: true,
						itemsPerPage: 50,
						hideContainer: true,
						sortEnabled: ['timestamp', 'transactionTypeDescription', 'amount'],
						style: { tableContainerInnerPadding: '0' },
						onPageChange: (currentPage) => {
							AnalyticsService.events.paginationClicked({
								screen_name: ScreenName.TransactionHistory,
								interaction_type: 'Page Change',
								page_number: String(currentPage),
								...userInfo,
							})
						},
						onSort,
					}}
					initialSort={[{ dataKey: 'timestamp', order: 'desc' }]}
					loading={false}
					forceShowEmptyTable
				/>
			)}
		</TablePagePaperTemplate>
	)
}

export default TransactionHistoryView
