import { useCallback } from 'react'
import dayjs from 'dayjs'
import { formatDate } from '@/utils/date'
import { TEXT_CSV_TYPE, unParse, saveAs } from '@/utils/fileHelper'
import { CombosListType } from '@/domains/combo/Combo'
import { useTranslation } from 'react-i18next'
import { Redeemable } from '@/domains'
import { RedeemableStatus } from '@/domains/enums'

export interface UseDownloadCombosResult {
	downloadCombos: () => void
}

type CSVSettingsByStatus = Record<
	RedeemableStatus,
	{
		name: string
		columns: Array<keyof Redeemable>
		headers: Array<string>
	}
>

const settingsByStatus: CSVSettingsByStatus = {
	[RedeemableStatus.PUBLISHED]: {
		name: 'published',
		columns: ['name', 'vendorComboId', 'points', 'lastModified'],
		headers: ['NAME', 'COMBO_ID', 'POINTS', 'LAST_UPDATE'],
	},
	[RedeemableStatus.UNPUBLISHED]: {
		name: 'unpublished',
		columns: ['redeemableId', 'points', 'lastModified'],
		headers: ['COMBO_ID', 'POINTS', 'LAST_UPDATE'],
	},
}

const getUtcDate = (): Date => dayjs().utcOffset(0, true).toDate()

const getFileName = (prefix: string, dateFormat: string): string => {
	const dateNowString = formatDate(getUtcDate(), dateFormat)
	return `${prefix}-${dateNowString}.csv`
}

const downloadCsv = (fileString: string, fileName: string): void => {
	saveAs(fileString, fileName, { type: TEXT_CSV_TYPE })
}

const filterCombos = (combos: CombosListType, status: RedeemableStatus): CombosListType => {
	const list = [...combos].filter((combo) => {
		const comboStatus = (combo as unknown as Redeemable).status
		if (comboStatus) {
			return comboStatus === status
		}
		return status === RedeemableStatus.PUBLISHED ? !!combo.name : !combo.name
	})
	return list as CombosListType
}

export const useDownloadCombos = (combos: CombosListType, status: RedeemableStatus): UseDownloadCombosResult => {
	const { t } = useTranslation()

	const downloadCombos = useCallback(() => {
		const settings = settingsByStatus[status]
		const fileName = getFileName(settings.name, t('FORMAT_DATE.LONG_FILE'))
		const list = filterCombos(combos, status)
		const fileDataBlob = unParse(list, settings.columns, settings.headers)
		downloadCsv(fileDataBlob, fileName)
	}, [t, combos, status])

	return {
		downloadCombos,
	}
}
