import { SkusItem1 } from '@/analytics'
import ModalStepper from '@/components/core/ModalStepper'
import { ScreenName } from '@/domains'
import SkuForm from '@/domains/sku/SkuForm'
import { EditSKUStepData, SKUProcess } from '@/domains/sku/SkuStep'
import { IStep } from '@/domains/step'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import useAdminAuditLog from '@/hooks/useAdminAuditLog'
import { useAdminAuditLogHook } from '@/hooks/useAuditLogInstance'
import { useLoadSkuList } from '@/routes/rules/hooks/useLoadSkuList'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { AddItemsInBulkParams } from '@/services/settings/SettingsService'
import { PageLevelEvents, RulesEvents, RulesStore } from '@/stores'
import { ToastEvents } from '@/stores/toast'
import { UpdateProgramRuleUseCase } from '@/usecase'
import { AddRulesInBulkUseCase, UpdateSettingRuleUseCase } from '@/usecase/settings'
import { BASE_ROUTE } from '@/utils/constants'
import { isToggleEarningRulesByVendorEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { hasText } from '@/utils/string'
import { Button } from '@hexa-ui/components'
import { Edit2 } from '@hexa-ui/icons'
import { useStoreMap } from 'effector-react'
import Papa from 'papaparse'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import useStyles from './EditSkuStepperStyle'
import StepAction from './StepAction'
import StepProcess from './StepProcess'
import StepSKU from './StepSKU'

const EditSkuStepper: React.FC = () => {
	const { t } = useTranslation()
	const classes = useStyles()
	const settings = useCurrentSettings()
	const { selectedTierKey } = useCurrentTier()
	const [activeStepIndex, setActiveStepIndex] = useState(0)
	const [formErrors, setFormErrors] = useState<boolean>(false)
	const [isOpen, setIsOpen] = useState<boolean | undefined>()
	const history = useHistory()
	const { ruleId, ruleName } = useParams<{ ruleId: string; ruleName: string }>()
	const { loadSkuList } = useLoadSkuList(ruleId, selectedTierKey ?? '')
	const userInfo = useGetUserInfo()
	const { editSkuStepData, categoryId, skuUploadFile, singleSku, programId } = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => ({
			editSkuStepData: state.editSkuStepData,
			categoryId: state.categoryId,
			skuUploadFile: state.skuUploadFile,
			singleSku: state.singleSku,
			programId: state.programId,
		}),
	})
	const { auditLogInstance } = useAdminAuditLog()
	const { generateLogForEarningSkus } = useAdminAuditLogHook()

	const stepsName = [
		ScreenName.EarningRulesSkuEditStep1,
		ScreenName.EarningRulesSkuEditStep2,
		editSkuStepData.process === SKUProcess.MULTIPLE_SKU
			? ScreenName.EarningRulesSkuEditStep3Multiple
			: ScreenName.EarningRulesSkuEditStep3Single,
	]

	const steps = useMemo<Array<IStep & { validate: (params: EditSKUStepData) => boolean }>>(
		() => [
			{
				key: 'ACTION',
				label: t('rules:STEPS.ACTION.LABEL'),
				sublabel: t('rules:STEPS.ACTION.SUBLABEL'),
				render: () => <StepAction />,
				validate: (params) => hasText(params.action),
			},
			{
				key: 'PROCESS',
				label: t('rules:STEPS.PROCESS.LABEL'),
				sublabel: t('rules:STEPS.PROCESS.SUBLABEL'),
				render: () => <StepProcess />,
				validate: (params) => hasText(params.process),
			},
			{
				key: 'SKUS',
				label: t('rules:STEPS.SKUS.LABEL'),
				sublabel: t('rules:STEPS.SKUS.SUBLABEL'),
				render: () => <StepSKU errors={formErrors} />,
				validate: (params) => !!params.skus,
			},
		],
		[t, formErrors],
	)

	const currentStep = steps[activeStepIndex]

	const validateCurrentStep = () => {
		return currentStep.validate(editSkuStepData)
	}

	const handleContinue = () => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Continue',
			button_label: 'Continue',
			screen_name: stepsName[activeStepIndex],
			...userInfo,
		})
		setActiveStepIndex((prev) => prev + 1)
	}

	const handleBack = () => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Back',
			button_label: 'Back',
			screen_name: stepsName[activeStepIndex],
			...userInfo,
		})
		setActiveStepIndex((prev) => prev - 1)
	}

	const reset = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Edit Earning Rules SKUs',
			button_label: t('rules:SKUS.LIST.EDIT_SKU_BUTTON'),
			screen_name: ScreenName.EarningRulesSkus,
			...userInfo,
		})
		setActiveStepIndex(0)
		setFormErrors(false)
		RulesEvents.setSkuUploadFile(undefined)
		RulesEvents.setPropInEditSkuStepData({
			action: undefined,
			process: undefined,
			skus: false,
		})
	}, [t, userInfo])

	const submitFile = useCallback(
		(fileName: string, searchIds: string[]) => {
			const params: AddItemsInBulkParams = {
				level: settings.level,
				ruleId,
				settingId: settings.settingsId,
				tier: selectedTierKey!,
				categoryId,
				fileName,
				searchIds,
			}

			AddRulesInBulkUseCase.execute(params)

				.then(() => {
					reset()
					setIsOpen(false)
					history.push(`${BASE_ROUTE}/rules/${ruleId}/${ruleName}`)
					PageLevelEvents.showNotification({
						route: `/membership/rules/${ruleId}/${ruleName}`,
						message: t('rules:SKUS.NOTIFICATION.SUCCESS_EDIT_RULE'),
						notificationType: 'success',
					})
				})
				.catch(() => {
					RulesEvents.setPropInEditSkuStepData({ skus: false })
					setFormErrors(true)
					setIsOpen(true)
				})
		},
		[categoryId, history, reset, ruleId, ruleName, selectedTierKey, settings.level, settings.settingsId, t],
	)

	const parseCompletedHandler = (fileName: string, results: Papa.ParseResult<unknown>) => {
		const { data, meta } = results
		const vendorItemIds = data.map((item) => (item as { sku: string }).sku)
		const errorsConditions =
			(data.length === 0 && data.length > 5000) || meta.fields?.length !== 1 || !meta.fields?.includes('sku')

		if (!errorsConditions) {
			submitFile(fileName, vendorItemIds)
		} else {
			RulesEvents.setPropInEditSkuStepData({ skus: false })
			setFormErrors(true)
		}
	}

	const sendAuditLog = (sku: string) => {
		auditLogInstance(
			generateLogForEarningSkus({
				skus: sku,
				isMultipleStep: editSkuStepData.process === SKUProcess.MULTIPLE_SKU,
				entityId: ruleId,
			}),
		)
	}

	const handleSubmit = () => {
		if (!editSkuStepData.skus) return

		if (skuUploadFile) {
			Papa.parse(skuUploadFile[0], {
				header: true,
				skipEmptyLines: true,
				delimiter: ';',
				complete: (results) => {
					setFormErrors(false)
					parseCompletedHandler(skuUploadFile[0].name, results)
					const skus = results?.data.map((item) => (item as { sku: SkusItem1 }).sku)
					AnalyticsService.events.earningRuleSkUsUpdated({
						current_earning_rule_id: ruleId,
						file_name: skuUploadFile[0].name,
						file_size: skuUploadFile[0].size.toString(),
						operation_type: 'Add',
						skus: skus || null,
						skus_quantity: skus?.length.toString() || '',
						target_earning_rule_id: ruleId,
						screen_name: 'Earning Rules - SKUs - Edit - Add - Multiple - Step 3',
						...userInfo,
					})
					sendAuditLog(skus.toString())
				},
				transformHeader: (header) => header.toLowerCase(),
			})
		}
	}

	const handleAddSku = async () => {
		const result = isToggleEarningRulesByVendorEnabled()
			? await UpdateSettingRuleUseCase.execute({
					selectedTierKey: selectedTierKey!,
					ruleId,
					categoryId,
					skuFormValues: singleSku,
					settingsId: settings.settingsId,
					level: settings.level,
					vendorId: settings.vendorId,
			  })
			: await UpdateProgramRuleUseCase.execute(programId!, ruleId, singleSku).catch(() => false)

		if (result) {
			ToastEvents.toastShowNotification({
				notificationType: 'success',
				message: t('rules:SKUS.NOTIFICATION.ADD_SKU.SUCCESS'),
			})
			AnalyticsService.events.earningRuleSkUsUpdated({
				current_earning_rule_id: ruleId,
				file_name: null,
				file_size: null,
				operation_type: 'Add',
				skus: [{ sku: singleSku.id }],
				skus_quantity: null,
				target_earning_rule_id: ruleId,
				screen_name: 'Earning Rules - SKUs - Edit - Add - Single - Step 3',
				...userInfo,
			})
			sendAuditLog(singleSku.id)
			loadSkuList()
		} else {
			ToastEvents.toastShowNotification({
				notificationType: 'error',
				message: t('rules:SKUS.NOTIFICATION.ADD_SKU.ERROR'),
			})
		}
		RulesEvents.addSingleSku({} as SkuForm)
	}

	return (
		<ModalStepper
			steps={steps}
			activeStepIndex={activeStepIndex}
			title="Add SKU"
			buttonNext={{
				onClick: handleContinue,
				disabled: !validateCurrentStep(),
			}}
			buttonPrev={{
				onClick: handleBack,
			}}
			buttonName={editSkuStepData.process === SKUProcess.MULTIPLE_SKU ? t('BUTTON.SUBMIT') : t('BUTTON.ADD')}
			isOpen={isOpen}
			onSubmit={editSkuStepData.process === SKUProcess.MULTIPLE_SKU ? handleSubmit : handleAddSku}
			trigger={
				<Button
					icon={Edit2}
					leading
					size="medium"
					variant="secondary"
					className={classes.editskuButton}
					onClick={reset}
				>
					{t('rules:SKUS.LIST.EDIT_SKU_BUTTON')}
				</Button>
			}
		/>
	)
}

export default EditSkuStepper
