import { BackDropLoad, InitialRedirect } from '@/components/core'
import AppHeader from '@/components/core/AppHeader'
import { MainContent } from '@/components/core/MainContent'
import RootContainer from '@/components/core/RootContainer'
import SideBarAdmin from '@/components/core/SideBarAdmin'
import useAuthorizedRoutes from '@/hooks/authorizedRoute/useAuthorizedRoutes'
import React, { useMemo } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import AppMainContent from './AppMainContent'

const AppRouter: React.FC = () => {
	const authorizedRoutes = useAuthorizedRoutes()

	const routeNodes = useMemo<Array<React.ReactNode>>(
		() =>
			[...authorizedRoutes]
				.sort((routeA, routeB) => routeA.to.localeCompare(routeB.to))
				.map((route) => route.renderRoute()),
		[authorizedRoutes],
	)

	return (
		<BrowserRouter>
			<BackDropLoad />
			<RootContainer>
				<>
					<SideBarAdmin authorizedRoutes={authorizedRoutes} />
					<AppHeader authorizedRoutes={authorizedRoutes} />
				</>
				<AppMainContent>
					<MainContent>
						<Switch>
							{routeNodes}
							<InitialRedirect authorizedRoutes={authorizedRoutes} />
						</Switch>
					</MainContent>
				</AppMainContent>
			</RootContainer>
		</BrowserRouter>
	)
}

export default AppRouter
