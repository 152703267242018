import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		header: {
			marginTop: '44px',
			marginBottom: '32px',
		},
		subtitle: {
			paddingTop: '8px',
			lineHeight: '20px',
		},
	}),
)

export default styles
