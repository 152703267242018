import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			color: theme.palette.common.black,
			right: '12px',
		},
		select: {
			'&:focus': {
				backgroundColor: '#fff!important',
			},
			'&:disabled': {
				color: theme.palette.text.disabled,
			},
		},
		helperText: {
			display: 'block',
			paddingLeft: '14px',
			color: theme.palette.error.dark,
		},
		formcontrol: {
			marginTop: '0px',
			'& p.Mui-error': {
				display: 'block',
				paddingLeft: '4px',
				margin: '0px !important',
				marginTop: '3px',
			},
		},
		errorContainer: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'bottom',
			marginBottom: '4px',
			marginTop: '3px',
		},
	}),
)

export default styles
