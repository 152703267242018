import { ScreenName } from '@/domains'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback } from 'react'
import { useGetUserInfo } from '../getUserInfo/useGetUserInfo'

interface AnalyticsServiceUndoClicked {
	entityId: string
	operation: string
	screenName: string
}

export const useAnalyticsServiceUndoClicked = (): {
	sendAnalyticsServiceUndoClicked: (recordedDeleted: AnalyticsServiceUndoClicked) => void
} => {
	const userInfo = useGetUserInfo()

	const sendAnalyticsServiceUndoClicked = useCallback(
		({ entityId, operation, screenName }: AnalyticsServiceUndoClicked) => {
			AnalyticsService.events.undoClicked({
				entity: ScreenName.RedeemablesList,
				entity_id: entityId,
				operation,
				screen_name: screenName,
				...userInfo,
			})
		},
		[userInfo],
	)

	return { sendAnalyticsServiceUndoClicked }
}
