import React from 'react'
import { TableCell as MaterialUITableCell } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import ColumnData from '../model/ColumnData'
import useStyles from './styles'

type TableCellProps = {
	id: string
	cellData: string | number | Date
	column: ColumnData
	cellStyle?: (cellData: string | number | Date, columnDataKey: string) => CSSProperties
}

const TableCell: React.FC<TableCellProps> = ({ id, cellData, column, cellStyle }: TableCellProps) => {
	const { align, format, dataKey } = column

	const customStyleProps = cellStyle ? cellStyle(cellData, dataKey) : {}
	const classes = useStyles(customStyleProps)

	let value
	try {
		value = cellData || cellData === 0 ? format?.(cellData) || cellData.toString() : ''
	} catch (err) {
		value = ''
	}

	return (
		<MaterialUITableCell id={id} variant="body" align={align ?? 'left'} className={classes.cell}>
			{value}
		</MaterialUITableCell>
	)
}

export default TableCell
