import { TransparencyTransaction } from '@/domains'
import i18next from 'i18next'

const getErrorMessage = (transaction: TransparencyTransaction): string | undefined => {
	if (!transaction.redeemableId) {
		return i18next.t('combo:UPLOAD_HISTORY.MODAL.ERROR_MESSAGE.INVALID_COMBO_ID')
	}
	const points = parseInt(transaction.points, 10)
	if (Number.isNaN(points) || points <= 0) {
		return i18next.t('combo:UPLOAD_HISTORY.MODAL.ERROR_MESSAGE.INVALID_POINTS')
	}
	return undefined
}

const TransparencyTransactionToTransparencyTransactionWithError = (
	transactions: TransparencyTransaction[],
): TransparencyTransaction[] => {
	return transactions.map((transaction) => ({
		...transaction,
		error: getErrorMessage(transaction),
	}))
}

export default TransparencyTransactionToTransparencyTransactionWithError
