import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		cell: (customStyleProps) => ({
			...customStyleProps,
			wordBreak: 'break-all',
		}),
	}),
)

export default styles
