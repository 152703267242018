import InputCheckBox from '@/components/formik/InputCheckBox'
import InputSelect, { SelectOptions } from '@/components/formik/InputSelect'
import { RefundOrderItem } from '@/domains'
import arrayOfNumber from '@/utils/arrayOfNumber'
import { TableCell, TableRow, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useField } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useStyles from '../../../../CombosList/CombosListStyle'

type RedeemablesListRowProps = RefundOrderItem & {
	index: number
	formKey: string
	existFullyRefunded: boolean
	allAreFullyRefunded: boolean
}

export const RedeemablesListRow = ({
	id,
	name,
	quantity,
	refundableQuantity,
	points,
	index,
	formKey,
	existFullyRefunded,
	allAreFullyRefunded,
}: RedeemablesListRowProps) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const isFullyRefunded = !refundableQuantity
	const initialQuantity = isFullyRefunded ? quantity : refundableQuantity
	const formFieldKey = isFullyRefunded ? 'quantity' : 'refundableQuantity'

	const [selectedQuantity, setSelectedQuantity] = useState(initialQuantity)
	const [redeemableChecked] = useField<boolean>(`${formKey}[${index}].checked`)

	const quantityDisabled = useMemo(() => {
		return !redeemableChecked.value
	}, [redeemableChecked])

	const redeemablePoints = useMemo(() => {
		return points * selectedQuantity
	}, [points, selectedQuantity])

	const getQuantitySelectOptions = (): Array<SelectOptions> => {
		return arrayOfNumber(quantity).map((value) => {
			return {
				label: value,
				value,
				disabled: value > refundableQuantity,
			} as SelectOptions
		})
	}

	useEffect(() => {
		handleChangeQuantity(initialQuantity)
	}, [initialQuantity])

	const handleChangeQuantity = (value: number) => {
		setSelectedQuantity(value)
	}

	return (
		<TableRow key={id} id={id} data-testid="refund-redeemables-list-row">
			<TableCell className={clsx({ [classes.cell]: isFullyRefunded, [classes.cellCenter]: !isFullyRefunded })}>
				{allAreFullyRefunded && (
					<Typography component="span">
						<Typography variant="body1">{name}</Typography>
						<Typography variant="body2">{id}</Typography>
					</Typography>
				)}
				{!allAreFullyRefunded && (
					<InputCheckBox
						id={`${formKey}${index}checked`}
						name={`${formKey}.${index}.checked`}
						label={name}
						optionalLabel={id}
						disabled={isFullyRefunded}
						checkBoxVisible={isFullyRefunded}
					/>
				)}
			</TableCell>
			<TableCell className={classes.cell}>
				{isFullyRefunded && <div className={classes.qntAligned}>{quantity}</div>}
				{!isFullyRefunded && (
					<InputSelect
						id={`${formKey}${index}quantity`}
						name={`${formKey}.${index}.${formFieldKey}`}
						label=""
						options={getQuantitySelectOptions()}
						onChange={(event) => handleChangeQuantity(event.target.value as number)}
						disabled={quantityDisabled}
						disableGutters
					/>
				)}
			</TableCell>
			<TableCell className={clsx({ [classes.cell]: isFullyRefunded, [classes.cellCenter]: !isFullyRefunded })}>
				<div className={classes.pointsAligned}>{`${redeemablePoints}${t('transaction:REFUND.POINTS_ABBREV')}`}</div>
			</TableCell>
			{existFullyRefunded && (
				<TableCell className={classes.cell}>
					<div className={classes.fullyAligned}>{isFullyRefunded && t('transaction:REFUND.FULLY_REFUNDED')}</div>
				</TableCell>
			)}
		</TableRow>
	)
}
