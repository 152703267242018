import React from 'react'
import Rule from '@/domains/rule/Rule'
import { Paragraph } from '@hexa-ui/components'
import { GridItem } from '@/components/templates/GridTemplate'
import useStyles from './RuleNameColumnStyle'

type RuleNameColumnProps = {
	rule: Rule
}

const RuleNameColumn: React.FC<RuleNameColumnProps> = ({ rule }: RuleNameColumnProps) => {
	const { ruleName, ruleDescription } = useStyles()
	const { name, description } = rule

	return (
		<GridItem>
			<Paragraph className={ruleName}>{name}</Paragraph>
			<Paragraph className={ruleDescription}>{description}</Paragraph>
		</GridItem>
	)
}

export default RuleNameColumn
