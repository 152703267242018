import { createStyles, makeStyles } from '@material-ui/core/styles'

const FilterBoxStyle = makeStyles(() =>
	createStyles({
		input: {
			flexGrow: 1,
			flex: 1,
			borderRadius: '8px',
		},
		removeButton: {
			cursor: 'pointer',
		},
		trashButton: {
			marginBottom: '-40px',
			marginLeft: '15px',
		},
	}),
)

export default FilterBoxStyle
