import { Tier } from '@/domains/tier'
import { useAnalyticsServiceEventDefaultPricePerPointUpdated } from '@/hooks/analyticsService/useAnalyticsServiceDefaultPricePerPointUpdated'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import useAuditLogRedeemables from '@/routes/redeemableItems/hooks/useAuditLogRedeemables'
import { usePricePerPointConfiguration } from '@/routes/redeemableItems/hooks/usePricePerPointConfiguration'
import { useZoneConfigurationEdit } from '@/routes/zoneConfiguration/hooks/useZoneConfigurationEdit'
import { GlobalStore, PricePerPointConfiguration } from '@/stores'
import { unformat } from '@/utils/currencyHelper'
import { useStoreMap } from 'effector-react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export type UsePricePerPointEditorResult = {
	data?: PricePerPointConfiguration
	tiers: string
	onSubmit: (formData: PricePerPointConfiguration) => Promise<void>
	isSubmitting: boolean
	hasSubmitError: boolean
}

export const usePricePerPointEditor = (): UsePricePerPointEditorResult => {
	const { data, loadData } = usePricePerPointConfiguration()
	const { sendSettingsAuditLogRedeemables } = useAuditLogRedeemables()
	const { sendAnalyticsServiceEventDefaultPricePerPointUpdatedEvent } =
		useAnalyticsServiceEventDefaultPricePerPointUpdated()

	const { updateData, isLoading: isSubmitting, hasError: hasSubmitError } = useZoneConfigurationEdit()
	const { t } = useTranslation()

	const tiers = useStoreMap({
		store: GlobalStore,
		keys: [],
		fn: ({ tiersMap }) => {
			const values = Object.values(tiersMap).map((tier: Tier) => tier.label)
			const conjunction = values.length > 1 ? t('redeemable:EDIT.PPP_INPUT_HINT_TIERS_CONJUNCTION') : ''
			return values.join(', ').replace(/,([^,]*)$/, `${conjunction}$1`)
		},
	})

	const onSubmit = useCallback(
		async (formData: PricePerPointConfiguration): Promise<void> => {
			await updateData(formData).then(loadData)
			sendSettingsAuditLogRedeemables({
				defaultPricePerPoint: unformat(String(formData.pricePerPoint!)),
				operation: IAuditOperations.UPDATE,
			})
			sendAnalyticsServiceEventDefaultPricePerPointUpdatedEvent(formData.pricePerPoint!)
		},
		[updateData, loadData, sendSettingsAuditLogRedeemables, sendAnalyticsServiceEventDefaultPricePerPointUpdatedEvent],
	)

	return useMemo(() => {
		return {
			data,
			tiers,
			onSubmit,
			isSubmitting,
			hasSubmitError,
		}
	}, [data, tiers, onSubmit, isSubmitting, hasSubmitError])
}
