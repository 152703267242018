import { MenuInteraction } from '@/domains'
import MembershipFeatures from '@/domains/authorization/MembershipFeatures'
import { PermissionLevel } from '@/domains/enums'
import React from 'react'
import { Route } from 'react-router'

export interface AuthorizationFeature {
	featureName: string
	feature: MembershipFeatures
	permissionLevel: PermissionLevel
	featureEnabledByVendor?: () => boolean
}

export interface RouteListItem {
	component: React.ComponentType
	key: string
	path: string
	textTranslationKey?: string
	renderText?: (params: Record<string, string>) => string
	breadCrumbsFlux: Array<number>
	exact?: boolean
	hideTierInfo?: boolean
}

export interface RouteData {
	id: string
	textTranslationKey: string
	subRouteRegExp: RegExp
	mainRouteIndex: number
	analyticsCategory?: MenuInteraction
	routeList: Array<RouteListItem>
	authorizationFeature: AuthorizationFeature
	sortIndex: number
	renderIcon: () => React.ReactNode
}

export class RouteInstance {
	id: string

	textTranslationKey: string

	subRouteRegExp: RegExp

	mainRouteIndex: number

	analyticsCategory?: MenuInteraction

	routeList: Array<RouteListItem>

	authorizationFeature: AuthorizationFeature

	sortIndex: number

	renderIcon: () => React.ReactNode

	constructor(params: RouteData) {
		this.id = params.id
		this.textTranslationKey = params.textTranslationKey
		this.subRouteRegExp = params.subRouteRegExp
		this.mainRouteIndex = params.mainRouteIndex
		this.analyticsCategory = params.analyticsCategory
		this.routeList = params.routeList
		this.authorizationFeature = params.authorizationFeature
		this.sortIndex = params.sortIndex
		this.renderIcon = params.renderIcon
	}

	get to() {
		return this.getMainSubRoute().path
	}

	getMainSubRoute() {
		return this.routeList[this.mainRouteIndex || 0]
	}

	isActive(pathname: string): boolean {
		if (this.subRouteRegExp) {
			return this.subRouteRegExp.test(pathname)
		}
		return false
	}

	renderRoute(): React.ReactNode {
		return this.routeList.map((item) => <Route key={item.key} path={item.path} component={item.component} />)
	}
}
