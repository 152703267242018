import { createStyles, makeStyles } from '@material-ui/core/styles'

type Props = {
	width?: string
}

const styles = makeStyles(() =>
	createStyles({
		toastStyle: {
			'& ol': {
				width: (props: Props) => props.width ?? '390px',
			},
		},
	}),
)

export default styles
