import React from 'react'
import { Grid, ItemProps } from '@hexa-ui/components'
import useStyle from './styles'

interface GridItemProps extends ItemProps {
	children: React.ReactNode
}

const GridItem: React.FC<GridItemProps> = ({ children, ...props }) => {
	const classes = useStyle()

	return (
		<Grid.Item className={classes.item} {...props}>
			{children}
		</Grid.Item>
	)
}

export default GridItem
