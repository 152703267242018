import React, { useEffect } from 'react'
import ErrorIcon from 'components/icons/ErrorIcon'
import { FormHelperText } from '@material-ui/core'
import useStyles from './styles'

type Props = {
	id: string
	message?: string
	onShow?: (id: string, message: string) => void
}

const FieldErrorMessage: React.FC<Props> = ({ id, message = '', onShow }: Props) => {
	const classes = useStyles()

	useEffect(() => {
		if (onShow) onShow(id, message)
	}, [id, message, onShow])

	return (
		<div className={classes.errorContainer}>
			<ErrorIcon />
			<FormHelperText error className={classes.helperTextError} id={`${id}-error-message`}>
				{message}
			</FormHelperText>
		</div>
	)
}

export default FieldErrorMessage
