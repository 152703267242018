import React, { ReactNode } from 'react'
import ContainerView from './ContainerView'

type Props = {
	children: ReactNode
	className?: string
}

const Container: React.FC<Props> = ({ children, className }: Props) => {
	return <ContainerView className={className}>{children}</ContainerView>
}

export default Container
