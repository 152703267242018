import React, { useEffect, useState } from 'react'
import { Dialog, Heading, IconButton, Paragraph, Table } from '@hexa-ui/components'
import { Download, EyeOn, MoreHorizontal } from '@hexa-ui/icons'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import { useTranslation } from 'react-i18next'
import TransactionStatusBadge from '@/components/core/TransactionStatusBadge'
import getTransactionUseCase from '@/usecase/transaction/getTransactionUseCase'
import { TransactionResponseV2 } from '@/domains/transaction/TransactionResponse'
import { formatDate } from '@/utils/date'
import { gridContainerStyles, useStyles } from './TransactionDetailDialogStyle'

const DATE_FORMAT = 'MMMM D, YYYY - HH:mm'

interface TransactionDetailProps {
	transactionId: string
}

const TransactionDetailDialog: React.FC<TransactionDetailProps> = ({ transactionId }) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const [transaction, setTransaction] = useState<TransactionResponseV2 | null>()

	useEffect(() => {
		const fetchTransaction = async () => {
			try {
				const response = await getTransactionUseCase({ transactionId })
				setTransaction(response)
			} catch (error) {
				console.error('Failed to load transaction', error)
			}
		}

		fetchTransaction()
	}, [transactionId])

	const statusWithActions = transaction?.executions?.map((execution) => {
		const icon = execution.status === 'PENDING' ? <MoreHorizontal fr={undefined} /> : <Download fr={undefined} />

		return {
			...execution,
			status: (
				<div className={classes.tableStatus}>
					<TransactionStatusBadge type={execution.status} />
				</div>
			),
			actions: (
				<IconButton
					icon={() => icon}
					variant="tertiary"
					data-testid={execution.status === 'PENDING' ? 'pending-icon' : 'approved-icon'}
				/>
			),
		}
	})

	const transactionAmount = transaction?.amount ?? transaction?.points
	const transactionType =
		transaction?.type === 'OFFER' ? 'TRANSACTION_TYPE_REWARDS_OFFER' : 'TRANSACTION_TYPE_POINTS_REMOVAL'

	const fields = [
		{ title: t('transaction:TRANSACTION_DETAIL.TRANSACTION_ID'), value: transaction?.id },
		{
			title: t('transaction:TRANSACTION_DETAIL.REQUESTED_ON'),
			value: transaction?.createdAt ? formatDate(new Date(transaction.createdAt), DATE_FORMAT) : '',
		},
		{ title: t('transaction:TRANSACTION_DETAIL.TRANSACTION_TYPE'), value: t(`transaction:${transactionType}`) },
		{ title: t('transaction:TRANSACTION_DETAIL.SINGLE_POC'), value: transaction?.pocId },
		{ title: t('transaction:TRANSACTION_DETAIL.MULTIPLE_POC'), value: transaction?.totalAccounts },
		{ title: t('transaction:TRANSACTION_DETAIL.AMOUNT'), value: transactionAmount },
		{ title: t('transaction:TRANSACTION_DETAIL.OFFER_CATEGORY'), value: transaction?.category },
		{ title: t('transaction:TRANSACTION_DETAIL.REFERENCE_MONTH'), value: transaction?.referenceMonth },
		{ title: t('transaction:TRANSACTION_DETAIL.REFERENCE_YEAR'), value: transaction?.referenceYear },
		{ title: t('transaction:TRANSACTION_DETAIL.REASON'), value: transaction?.reason },
		{ title: t('transaction:TRANSACTION_DETAIL.REMOVAL_REASON'), value: transaction?.removalReason },
		{ title: t('transaction:TRANSACTION_DETAIL.DESCRIPTION'), value: transaction?.description },
		{ title: t('transaction:TRANSACTION_DETAIL.REMOVAL_DESCRIPTION'), value: transaction?.removalDescription },
	]

	const reasons = [
		{ title: t('transaction:TRANSACTION_DETAIL.REJECTION_REASON'), value: transaction?.rejectionReason },
		{ title: t('transaction:TRANSACTION_DETAIL.DENIAL_REASON'), value: transaction?.denialReason },
	]

	return (
		<Dialog.Root
			title={<Heading>{t('transaction:TRANSACTION_DETAIL.TITLE')}</Heading>}
			trigger={<IconButton icon={EyeOn} variant="tertiary" />}
		>
			<GridContainer style={gridContainerStyles}>
				{fields
					.filter((field) => field.value)
					.map((field) => {
						const descriptionFields = [
							t('transaction:TRANSACTION_DETAIL.REASON'),
							t('transaction:TRANSACTION_DETAIL.DESCRIPTION'),
						]
						const titleLowerCase = field.title.toLowerCase()
						const FULL_ROW = 12
						const HALF_ROW = 6

						const isDescriptionField = descriptionFields.some((keyword) =>
							titleLowerCase.includes(keyword.toLowerCase()),
						)

						const gridColumnSize = isDescriptionField ? FULL_ROW : HALF_ROW

						return (
							<GridItem xs={gridColumnSize} key={field.title}>
								<Heading size="H5" className={classes.itemTitle}>
									{field.title}
								</Heading>
								<Paragraph>{field.value}</Paragraph>
							</GridItem>
						)
					})}
			</GridContainer>
			<Table
				pagination={false}
				columns={[
					{
						Header: t('transaction:TRANSACTION_DETAIL.TRANSACTION_STATUS.POCS'),
						accessor: 'totalAccounts',
						disableSortBy: true,
						style: {
							width: '40%',
						},
					},
					{
						Header: t('transaction:TRANSACTION_DETAIL.TRANSACTION_STATUS.STATUS'),
						accessor: 'status',
						disableSortBy: true,
						style: {
							width: '40%',
						},
					},
					{
						Header: t('transaction:TRANSACTION_DETAIL.TRANSACTION_STATUS.ACTIONS'),
						accessor: 'actions',
						disableSortBy: true,
						style: {
							width: '20%',
						},
					},
				]}
				data={statusWithActions ?? []}
			/>
			{reasons?.map((reason) =>
				reason.value && (
					<div key={reason.title} className={classes.containerItem}>
						<Heading size="H5" className={classes.rejectionReason}>
							{reason.title}
						</Heading>
						<Paragraph>{reason.value}</Paragraph>
					</div>
				),
			)}
		</Dialog.Root>
	)
}

export default TransactionDetailDialog

