import PagedResponse, { IPaginationResponse } from '@/domains/PagedResponse'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { TABLE_PAGE_SIZE_OPTIONS } from '@/utils/constants'
import { createEvent, createStore } from 'effector'

// Store

type RedeemableItemsState = PagedResponse<RedeemableItem> & {
	reloadPageAtTime: number
}

export const redeemableItemsInitialState: RedeemableItemsState = {
	content: [],
	pagination: {
		page: 0,
		pageSize: TABLE_PAGE_SIZE_OPTIONS.REDEEMABLE_ITEMS[0],
		totalElements: 0,
		totalPages: 0,
	},
	reloadPageAtTime: Date.now(),
}

// Events

export const RedeemableItemsStoreEvents = {
	setContent: createEvent<RedeemableItem[]>(),
	setPagination: createEvent<Partial<IPaginationResponse>>(),
	reloadPage: createEvent(),
	reset: createEvent('reset'),
}

// Handlers

export const $redeemableItemsStore = createStore(redeemableItemsInitialState).reset(RedeemableItemsStoreEvents.reset)

$redeemableItemsStore
	.on(RedeemableItemsStoreEvents.setContent, (state, content) => {
		return {
			...state,
			content,
		}
	})
	.on(RedeemableItemsStoreEvents.setPagination, (state, payload) => {
		return {
			...state,
			pagination: {
				...state.pagination,
				...payload,
			},
		}
	})
	.on(RedeemableItemsStoreEvents.reloadPage, (state) => {
		return {
			...state,
			reloadPageAtTime: Date.now(),
		}
	})
