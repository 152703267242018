import React, { useEffect, useState } from 'react'
import { Box, Grid, Grow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import InputText from '@/components/formik/InputText'
import InputTextArea from '@/components/formik/InputTextArea'
import InputSelect from '@/components/formik/InputSelect'
import useStyle from './RemovalForServiceUtilityStyle'
import PocIdField from '../PocIdField/PocIdField'
import PocValidationParams from '../../model/PocValidationParams'

type RemovalForServiceUtilityViewProps = {
	showError: boolean
	onPocValidation: (params: PocValidationParams) => Promise<string>
	currentPointsBalance: string
	newPointsBalance: string
	removeSuccessMessage: () => void
	categoryOptions: {
		value: string
		label: string
	}[]
	onError?: (id: string, message: string) => void
}

const RemovalForServiceUtilityView: React.FC<RemovalForServiceUtilityViewProps> = ({
	onPocValidation,
	currentPointsBalance,
	removeSuccessMessage,
	newPointsBalance,
	showError,
	categoryOptions,
	onError,
}) => {
	const { t } = useTranslation()
	const classes = useStyle()
	const [pocBalanceHasError, setPocBalanceHasError] = useState<boolean>(false)

	useEffect(() => {
		const pointsBalance = parseInt(currentPointsBalance, 10)
		setPocBalanceHasError(!Number.isNaN(pointsBalance) && pointsBalance <= 0)
	}, [currentPointsBalance])

	const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		const { value } = e.target as HTMLInputElement
		if (value.length === 1 && removeSuccessMessage) {
			removeSuccessMessage()
		}
	}

	return (
		<Grow in>
			<Box>
				<Grid container spacing={0}>
					<Grid
						item
						xs={12}
						style={{
							padding: '8px 0',
						}}
					>
						<InputSelect
							id="category"
							name="category"
							data-testid="category-select"
							label={t('transaction:REMOVAL_FOR_SERVICE_UTILITY.CATEGORY_LABEL')}
							options={categoryOptions}
							showError={showError}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<PocIdField
							id="points-removal-poc-id"
							name="pocId"
							label={t('transaction:REMOVAL_FOR_SERVICE_UTILITY.POC_ID_LABEL')}
							onPocValidation={onPocValidation}
							showError={showError}
							onError={onError}
						/>
						<Box id="points-balance" mt="8px" mb="24px">
							<span id="points-balance-label" data-testid="points-balance-label" className={classes.label}>
								{`${t('transaction:REMOVAL_FOR_SERVICE_UTILITY.CURRENT_POINTS_BALANCE_LABEL')} `}
							</span>
							<span
								id="points-balance-value"
								className={clsx({
									[classes.value]: !pocBalanceHasError,
									[classes.valueWithError]: pocBalanceHasError,
								})}
								data-testid="points-balance-value"
							>
								{` ${currentPointsBalance}`}
							</span>
						</Box>
					</Grid>
				</Grid>

				<Grid container spacing={1}>
					<Grid item xs={12}>
						<InputText
							id="points-removal-points"
							name="points"
							type="number"
							label={t('transaction:REMOVAL_FOR_SERVICE_UTILITY.POINTS_LABEL')}
							onKeyUp={keyUpHandler}
							showError={showError}
							onError={onError}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputTextArea
							id="points-removal-description"
							name="description"
							label={t('transaction:REMOVAL_FOR_SERVICE_UTILITY.DESCRIPTION_LABEL')}
							onKeyUp={keyUpHandler}
							showError={showError}
							onError={onError}
						/>

						<Box id="new-points-balance" mt="24px">
							<span id="new-points-balance-label" data-testid="new-points-balance-label" className={classes.label}>
								{`${t('transaction:REMOVAL_FOR_SERVICE_UTILITY.NEW_POINTS_BALANCE_LABEL')} `}
							</span>
							<span id="new-points-balance-value" className={classes.value} data-testid="new-points-balance-value">
								{` ${newPointsBalance}`}
							</span>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Grow>
	)
}

export default RemovalForServiceUtilityView
