import { unformat } from '@/utils/currencyHelper'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

export const usePricePerPointChanged = (): boolean => {
	const { watch, getValues } = useFormContext()

	const [isValueChanged, setIsValueChanged] = useState(false)

	useEffect(() => {
		const initialValue = unformat(getValues().pricePerPoint)
		const onFieldChange = watch(({ pricePerPoint }) => {
			const isChanged = unformat(pricePerPoint) !== initialValue
			setIsValueChanged(isChanged)
		})

		return () => onFieldChange.unsubscribe()
	}, [getValues, watch])

	return isValueChanged
}
