import { createStore } from 'effector'
import PageLevelState from './PageLevelState'
import * as PageLevelEvents from './PageLevelEvents'

const initialState: PageLevelState = {
	notificationRoute: '',
	notificationType: null,
	notificationMessage: '',
}

const PageLevelStore = createStore(initialState)
	.on(PageLevelEvents.showNotification, (state, notificationValues) => {
		return {
			...state,
			notificationRoute: notificationValues.route,
			notificationType: notificationValues.notificationType,
			notificationMessage: notificationValues.message,
		}
	})
	.reset(PageLevelEvents.hideNotification)

export default PageLevelStore
