import { GlobalStore } from '@/stores'
import { useAuthenticationService as authenticationService } from 'admin-portal-shared-services'
import acceptedCountries, { AcceptedCountry } from './acceptedCountries'

/**
 * Read the user token payload and return the supportedCountries array filtered by Admin accepted countries.
 * @param userCountry country found on user token payload
 * @returns countries code array
 */
const getUserSupportedCountries = (userCountry: string): string[] => {
	let supportedCountries: string[] = []
	const payload = authenticationService().parseJwt() as unknown as { supportedCountries: string[] } | null
	if (payload?.supportedCountries?.length) {
		supportedCountries = payload.supportedCountries.filter((country) =>
			acceptedCountries.includes(country as AcceptedCountry),
		)
		supportedCountries.push(userCountry)
	}
	return [...new Set(supportedCountries)]
}

/**
 * Get the zone selected by the user if it is set (not null) otherwise return the country found in the user token payload.
 * @returns country code
 */
const getUserCountry = (): string => {
	const { selectedZone } = GlobalStore.getState()
	return selectedZone!
}

/**
 * Get the email of the user.
 * @returns user email
 */
const getUserEmail = (): string => {
	const userEmail = authenticationService().getUserEmailB2C()
	return userEmail
}

export { getUserCountry, getUserEmail, getUserSupportedCountries }
