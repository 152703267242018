import { ChallengeFilterFormData } from '@/stores/challengeCreation'

const hasValidFilter = (challengeFilterFormData: ChallengeFilterFormData | undefined): boolean => {
	if (!challengeFilterFormData) {
		return false
	}

	if (challengeFilterFormData?.hasFilter) return true

	return challengeFilterFormData?.filters.some(({ filter }) => filter === 'groupIds')
}

export default hasValidFilter
