import { AppHeaderConfigPayload, useAppHeader } from 'admin-portal-shared-services'
import { useEffect } from 'react'
import { useActiveRouteSettings } from '../activeRoute/useActiveRouteSettings'
import { useSetAppHeaderOptionalButtonsProps } from './useSetAppHeaderOptionalButtons.types'
// eslint-disable-next-line import/no-cycle
import useAuthorizedRoutes from '../authorizedRoute/useAuthorizedRoutes'

function useSetAppHeaderOptionalButtons({ primaryButton, secondaryButton }: useSetAppHeaderOptionalButtonsProps): void {
	const authorizedRoutes = useAuthorizedRoutes()

	const { pageTitle, breadcrumbConfig } = useActiveRouteSettings(authorizedRoutes)

	const [, setAppHeaderConfig] = useAppHeader()

	useEffect(() => {
		const newAppHeaderConfig: AppHeaderConfigPayload = {
			pageTitle,
			vendorSelect: false,
			countrySelect: false,
			breadcrumbConfig,
			optionalButtons: [primaryButton, secondaryButton].filter(
				(button) => button !== undefined,
			) as unknown as AppHeaderConfigPayload['optionalButtons'],
		}

		setAppHeaderConfig(newAppHeaderConfig)

		const optionalButtonsCleanUp = (): void =>
			setAppHeaderConfig({
				...newAppHeaderConfig,
				optionalButtons: [],
			})

		return optionalButtonsCleanUp
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}

export { useSetAppHeaderOptionalButtons }
