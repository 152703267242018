import { SkeletonLoader } from '@hexa-ui/components'
import { styled } from '@hexa-ui/theme'
import React from 'react'

export const ItemCellLoader = () => {
	return (
		<ItemCellLoaderContent data-testid="create-redeemable-item-cell-loader">
			<ItemCellLoaderImage>
				<SkeletonLoader width="40px" height="40px" variant="image" imageShape="circle" />
			</ItemCellLoaderImage>
			<ItemCellLoaderDetails>
				<SkeletonLoader width="70%" height="22px" variant="body" />
				<SkeletonLoader width="100%" height="12px" variant="body" />
				<SkeletonLoader width="100%" height="12px" variant="body" />
			</ItemCellLoaderDetails>
			<ItemCellLoaderPPP>
				<SkeletonLoader width="100%" height="22px" variant="body" />
				<SkeletonLoader width="100%" height="32px" variant="body" />
			</ItemCellLoaderPPP>
		</ItemCellLoaderContent>
	)
}

// Style

export const ItemCellLoaderContent = styled('div', {
	display: 'flex',
	justifyContent: 'space-between',
	position: 'relative',
	padding: '0px 18px',
	gap: '24px',
	width: '590px',
	'& + &': {
		marginTop: '$4',
	},
	'& *': {
		marginBottom: '$2',
	},
})

export const ItemCellLoaderImage = styled('div', {
	alignSelf: 'center',
})

export const ItemCellLoaderDetails = styled('div', {
	alignSelf: 'center',
	width: '60%',
})

export const ItemCellLoaderPPP = styled('div', {
	alignSelf: 'center',
	width: '25%',
})
