import { makeStyles, createStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		content: {
			width: '900px',
		},
		titleBox: {
			display: 'flex',
			justifyContent: 'space-between',
			position: 'relative',
		},
		header: {
			fontSize: '24px',
			color: 'black',
			fontFamily: 'Barlow',
			fontWeight: 600,
			letterSpacing: 0,
		},
		backBtn: {
			marginRight: '15px',
		},
		closeBtn: {
			position: 'relative',
			left: 'var(--space-8)',
		},
	}),
)

export default styles
