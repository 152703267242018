import { createStyles, makeStyles } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/withStyles' {
	// Augment the BaseCSSProperties so that we can control jss-rtl
	interface BaseCSSProperties {
		flip?: boolean
	}
}

const styles = makeStyles(() =>
	createStyles({
		flexContainer: {
			display: 'flex',
			alignItems: 'center',
			boxSizing: 'border-box',
		},
		grid: {
			direction: 'inherit',
			outline: 'none',
		},
		table: {
			outline: 'none!important',
			'& .ReactVirtualized__Table__headerRow': {
				flip: false,
			},
		},
		tableCell: {
			flex: 1,
			fontSize: '16px',
			border: 'none',
		},
		tableCellLeft: {
			justifyContent: 'flex-start',
		},
		tableCellCenter: {
			justifyContent: 'center',
		},
		tableCellRight: {
			justifyContent: 'flex-end',
		},
		noClick: {
			cursor: 'initial',
		},
	}),
)

export default styles
