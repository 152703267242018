import { createStyles, makeStyles } from '@material-ui/core/styles'

export const CreateRedeemableItemContentStyle = makeStyles(() =>
	createStyles({
		container: {
			backgroundColor: 'var(--colors-neutral10)',
			borderRadius: '8px',
			margin: '24px 0',
			width: 'inherit',
			justifyContent: 'center',
			display: 'flex',
		},
		emptyStateContainer: {
			margin: '24px',
		},
		variantContainer: {
			justifyContent: 'center',
			display: 'flex',
			flexDirection: 'column',
		},
	}),
)
