import SkuForm from 'domains/sku/SkuForm'
import * as ItemService from '@/services/item/ItemService'
import Rule from '@/domains/rule/Rule'
import { loadAllItemsByRule } from '@/services/settings/SettingsService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { isToggleEarningRulesByVendorEnabled } from '@/utils/featureToggle/featureToggleHelper'
import VariantsType from '@/domains/Item/variantsType'

async function execute(rule: Rule, tier?: string, variantsType?: VariantsType): Promise<SkuForm[] | []> {
	const multiVendorEarningRulesToggle = isToggleEarningRulesByVendorEnabled()
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const params = {
		ruleId: rule.ruleId,
		vendorId: vendorId || '',
		tier: tier ?? '',
	}

	const skus = rule.items ? rule.items.map(({ vendorItemId }) => vendorItemId) : []

	const itemsResponse = multiVendorEarningRulesToggle
		? await loadAllItemsByRule(params)
		: await ItemService.loadAllItems({ vendorItemIds: skus, variantsType })

	if (!itemsResponse?.items) {
		return []
	}

	return itemsResponse?.items?.map((item) => {
		return {
			id: item.sku,
			name: item.itemName ?? '',
			itemId: item?.id,
			image: item?.itemImage,
			containerDescription: item?.container.fullContainerDescription,
		} as SkuForm
	})
}

export { execute }
