import Rule from '@/domains/rule/Rule'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { RulesEvents } from '@/stores'
import { TextButton } from '@hexa-ui/components'
import ButtonInteraction from 'domains/analytics/ButtonInteraction'
import ScreenName from 'domains/analytics/ScreenName'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLoadRules } from '../../../hooks/useLoadRules'
import { useNavigateToRules } from '../../../hooks/useNavigateToRules'

type ButtonViewProps = {
	rule: Rule
}

const ButtonView: React.FC<ButtonViewProps> = ({ rule }: ButtonViewProps) => {
	const userInfo = useGetUserInfo()
	const { t } = useTranslation()
	const { loadRules: reloadRules } = useLoadRules()
	const { goToRulesDetails } = useNavigateToRules()

	const handleTableActionView = async ({ ruleId }: Rule) => {
		RulesEvents.setRuleId(ruleId)
		await reloadRules()
		AnalyticsService.events.buttonClicked({
			button_label: null,
			button_name: ButtonInteraction.View,
			screen_name: ScreenName.EarningRules,
			...userInfo,
		})
		goToRulesDetails(rule)
	}

	return (
		<TextButton iconPosition="leading" onClick={() => handleTableActionView(rule)}>
			{t('VIEW')}
		</TextButton>
	)
}

export default ButtonView
