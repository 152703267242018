import { createStyles, makeStyles } from '@material-ui/core/styles'

const EditDefaultCppDialogStyle = makeStyles(() =>
	createStyles({
		alert: {
			display: 'flex',
			width: 'auto',
			marginTop: 'var(--space-4)',
		},
		actionsWrapper: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: '24px',
		},
	}),
)

export default EditDefaultCppDialogStyle
