import React, { useMemo } from 'react'
import { Select, MenuItem } from '@material-ui/core'
import { GlobalStore } from '@/stores'
import { useStore } from 'effector-react'
import { Tier } from '@/domains/tier'

type Props = {
	defaultValue: string | null
	onSelect: (key: string) => void
}

const TierSelector: React.FC<Props> = ({ defaultValue, onSelect }) => {
	const { tiersMap } = useStore(GlobalStore)
	const options = useMemo<Array<Tier>>(() => Object.values(tiersMap), [tiersMap])

	return (
		<Select
			data-testid="TierSelector"
			displayEmpty
			autoFocus
			name="TierSelector"
			defaultValue={defaultValue}
			onChange={(event) => onSelect(`${event.target.value}`)}
			fullWidth
			variant="outlined"
			MenuProps={{
				getContentAnchorEl: null,
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left',
				},
			}}
		>
			{options.map((item) => (
				<MenuItem key={item.tier} id={`tier-${item.tier}`} value={item.tier}>
					{item.label}
				</MenuItem>
			))}
		</Select>
	)
}

export default TierSelector
