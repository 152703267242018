import { createStyles, makeStyles } from '@material-ui/core/styles'

const addRedeemableItemStyle = makeStyles(() =>
	createStyles({
		container: {
			'& [role="dialog"]': {
				width: '80%',
				maxWidth: '640px',
			},

			'& > [role="dialog"] > div:first-child': {
				marginBottom: '8px',
			},
		},
		emptyStateWrapper: {
			display: 'flex',
			justifyContent: 'center',
		},
		noResultWrapper: {
			margin: '24px',
		},
		skuItemWrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: '16px',
			marginBottom: '16px',
		},
		actionContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: '16px',
		},
		wrapperItem: {
			marginTop: '16px',
		},
		warning: {
			marginTop: '16px',
			width: '100%',
		},
		alert: {
			display: 'flex',
			width: 'auto',
			marginTop: 'var(--space-2)',
		},
	}),
)

export default addRedeemableItemStyle
