import { RewardsOfferTransaction, TransactionResponse } from '@/domains'
import { addPoints, rewardsOffers } from '@/services/transaction/TransactionService'
import { isIdentifyDimensionEnabled } from '@/utils/featureToggle/featureToggleHelper'

async function execute(
	accountId: string,
	rewardsOfferTransaction: RewardsOfferTransaction,
): Promise<TransactionResponse> {
	return isIdentifyDimensionEnabled()
		? addPoints(rewardsOfferTransaction)
		: rewardsOffers(accountId, rewardsOfferTransaction)
}

export { execute }
