import React from 'react'
import { Button } from '@material-ui/core'
import { ComboFormType } from '@/domains/enums'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { ComboFormData } from '@/stores/combo/ComboState'
import { ComboEvents } from '@/stores'

type Props = {
	disabled: boolean
	formType: ComboFormType
}

const ComboFormSubmitButton: React.FC<Props> = ({ disabled, formType }) => {
	const { t } = useTranslation()

	const handleSubmitClick = (values: ComboFormData) => {
		ComboEvents.setFormData(values)
		ComboEvents.setShowSubmitDialog(true)
	}

	const {
		handleSubmit,
		formState: { errors },
	} = useFormContext()

	return (
		<Button
			type="button"
			variant="contained"
			color="primary"
			id="submit-btn"
			disabled={disabled}
			onClick={Object.keys(errors).length === 0 ? handleSubmit(handleSubmitClick) : undefined}
		>
			{!formType || formType === ComboFormType.Multiple ? t('combo:SUBMIT_TEXT_MULTIPLE') : t('combo:SUBMIT_TEXT')}
		</Button>
	)
}

export default ComboFormSubmitButton
