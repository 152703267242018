import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
	createStyles({
		container: {
			flex: 1,
		},
	}),
)

export default styles
